import React from "react";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	list: {
		height: 240,
		overflowY: "scroll",
	},
}));

interface IUserListProps {
	list: string[];
	handleToggle(name: string): void;
	isReadOnly: boolean;
}

const UserList: React.FC<IUserListProps> = ({
	list,
	handleToggle,
	isReadOnly,
}) => {
	const classes = useStyles();
	return (
		<List dense component="div" role="list" className={classes.list}>
			{list.length > 0 ? (
				list.map((user) => (
					<ListItem
						style={{ width: "100%" }}
						key={user}
						role="listitem"
						onClick={() => handleToggle(user)}
						button
						disabled={isReadOnly}
					>
						<ListItemText id={user} primary={user} />
					</ListItem>
				))
			) : (
				<ListItem>
					<ListItemText id="nousers" primary="No users found." />
				</ListItem>
			)}
		</List>
	);
};

export default UserList;
