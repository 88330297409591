import { InputAdornment, TextField } from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";

type Props = {
	onChange: (searchValue: string) => void;
	value?: string;
	placeholder?: string;
};

const SearchInput = ({ placeholder, onChange, value }: Props) => {
	const handleChange = (searchValue: string) => {
		onChange(searchValue);
	};

	return (
		<TextField
			fullWidth
			value={value ?? ""}
			placeholder={placeholder}
			margin="none"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<Clear
							style={{
								cursor: "pointer",
								display: value ? "block" : "none",
							}}
							onClick={() => handleChange("")}
						/>
					</InputAdornment>
				),
			}}
			onChange={(e) => {
				if (e.target.value !== value) handleChange(e.target.value);
			}}
		/>
	);
};

export default SearchInput;
