import regexParse from "regex-parser";

const validateMinLength = <T>(value: T, min: number) =>
	String(value).length >= min;
const validateMaxLength = <T>(value: T, max: number) =>
	String(value).length <= max;
const validateRegex = <T>(value: T, pattern: string) =>
	regexParse(pattern).test(String(value));
const validateMinimum = <T>(value: T, min: number) => Number(value) >= min;
const validateMaximum = <T>(value: T, max: number) => Number(value) <= max;
const validateExclusiveMinimum = <T>(value: T, min: number) =>
	Number(value) > min;
const validateExclusiveMaximum = <T>(value: T, max: number) =>
	Number(value) < max;
const validateMultipleOf = <T>(value: T, multipleOf: number) =>
	Number(value) % multipleOf === 0;

export const errorsInBody = (schemaProperties: any, body: any) => {
	const errors: {
		[key: string]: string;
	} = {};
	if (schemaProperties && body) {
		Object.keys(body).forEach((key) => {
			const unsafeProperty = body[key];
			const schema = schemaProperties[key];
			if (schema && unsafeProperty) {
				const property =
					typeof unsafeProperty === "string"
						? unsafeProperty.trim()
						: unsafeProperty;

				if (
					schema.minLength &&
					!validateMinLength(property, schema.minLength)
				) {
					errors[
						key
					] = `${key} must be at least ${schema.minLength} characters long.`;
				}
				if (
					schema.maxLength &&
					!validateMaxLength(property, schema.maxLength)
				) {
					errors[
						key
					] = `${key} must be at most ${schema.maxLength} characters long.`;
				}
				if (
					schema.pattern &&
					!validateRegex(property, schema.pattern)
				) {
					errors[key] = `Invalid pattern value.`;
				}
				if (
					schema.minimum &&
					!validateMinimum(property, schema.minimum)
				) {
					errors[key] = `${key} must be at least ${schema.minimum}.`;
				}
				if (
					schema.maximum &&
					!validateMaximum(property, schema.maximum)
				) {
					errors[key] = `${key} must be at most ${schema.maximum}.`;
				}
				if (
					schema.exclusiveMinimum &&
					!validateExclusiveMinimum(property, schema.exclusiveMinimum)
				) {
					errors[
						key
					] = `${key} must be greater than ${schema.exclusiveMinimum}.`;
				}
				if (
					schema.exclusiveMaximum &&
					!validateExclusiveMaximum(property, schema.exclusiveMaximum)
				) {
					errors[
						key
					] = `${key} must be less than ${schema.exclusiveMaximum}.`;
				}
				if (
					schema.multipleOf &&
					!validateMultipleOf(property, schema.multipleOf)
				) {
					errors[
						key
					] = `${key} must be a multiple of ${schema.multipleOf}.`;
				}
			}
		});
	}

	return errors;
};
