import React from "react";
import {
	Box,
	CircularProgress,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { THEME } from "../../../config";
import DetailItem from "./DetailItem";
import { ISubject } from "../../../store/Subjects/types";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { IElasticHit, MatchProps } from "../../../store/Events/types";
import clsx from "clsx";

import useMetadata from "../../../hooks/useMetadata";
import { IMetadata } from "../../../store/Schemas/types";

const useStyles = makeStyles(() => ({
	root: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: THEME.palette.background.default,
		padding: 6,
		flex: "auto",
		minWidth: "33%",
	},
	detailsBox: {
		display: "flex",
		justifyContent: "center",
		gap: 4,
		padding: "4px 0",
		flex: 1,
	},
	icons: {
		height: 20,
		width: 20,
	},
	attribute: { display: "flex", gap: 2, alignItems: "center" },
	dividerRight: {
		borderRight: `1px solid ${THEME.palette.grey[500]}`,
		paddingRight: 4,
	},
	boldText: {
		fontWeight: "bold",
	},
	iconButton: {
		padding: 0,
		cursor: "pointer",
	},
	title: {
		borderBottom: `1px solid ${THEME.palette.grey[500]}`,
		color: THEME.palette.secondary.main,
		fontWeight: "bold",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
	},
	matchPagination: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "0.8rem",
		width: "fit-content",
	},
	disabled: {
		pointerEvents: "none",
		color: THEME.palette.grey[500],
	},
}));

const MatchDetails: React.FC<{
	match?: ISubject;
	matchProps: MatchProps | null;
	currentPage?: number;
	matchesCount: number;
	handleMatchPageChange?: (
		event: IElasticHit["_source"],
		page: number,
	) => void;
	event?: IElasticHit["_source"];
}> = ({
	match,
	matchProps,
	currentPage,
	matchesCount,
	handleMatchPageChange,
	event,
}) => {
	const classes = useStyles();
	const metadata = useMetadata({
		watchlistId: matchProps?.watchlist,
		secretId: matchProps?.secret,
		metadata: match?.metadata,
	});

	return (
		<Box className={classes.root}>
			<Typography variant="h6" className={classes.title}>
				Match {match?.name ? `"${match.name}"` : ""} Metadata
				{matchesCount > 1 && (
					<Box className={classes.matchPagination}>
						<ArrowLeftIcon
							className={clsx(
								classes.iconButton,
								(!match || currentPage === 0) &&
									classes.disabled,
							)}
							onClick={() => {
								if (event && handleMatchPageChange)
									handleMatchPageChange(
										event,
										(currentPage ?? 1) - 1,
									);
							}}
						/>
						{(currentPage ?? 0) + 1}/{matchesCount}
						<ArrowRightIcon
							className={clsx(
								classes.iconButton,
								(!match || currentPage === matchesCount - 1) &&
									classes.disabled,
							)}
							onClick={() => {
								if (event && handleMatchPageChange)
									handleMatchPageChange(
										event,
										(currentPage ?? 1) + 1,
									);
							}}
						/>
					</Box>
				)}
			</Typography>
			{metadata ? (
				<MetadataItem metadata={metadata} />
			) : (
				<Box flex={1} display="flex" justifyContent="center" p={8}>
					<CircularProgress />
				</Box>
			)}
		</Box>
	);
};

export const MetadataItem = ({
	metadata,
	depth = 0,
}: {
	metadata: IMetadata | undefined;
	depth?: number;
}) => {
	if (!metadata) return null;
	return (
		<>
			{Object.keys(metadata ?? {}).map((key) => {
				const metadataValue = metadata[key];
				if (typeof metadataValue === "object") {
					return (
						<div key={key}>
							<DetailItem title={`${String(key)}:`} value="" />
							<div
								style={{
									marginLeft: `${Math.min(depth, 4)}rem`,
								}}
							>
								<MetadataItem metadata={metadataValue} />
							</div>
						</div>
					);
				}
				return (
					<DetailItem
						key={key}
						title={key}
						value={metadata[key]}
						rootStyle={{
							marginLeft: `${Math.min(depth, 4)}rem`,
						}}
						wrap
					/>
				);
			})}
		</>
	);
};

export default MatchDetails;
