import Axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IMetadataSchema, SCHEMAS } from "./types";

export type SCHEMA = "SCHEMA";

export type SchemasAction = AsyncAction<SCHEMA, IMetadataSchema, SCHEMAS>;

export const getSchema = async (schema: SCHEMAS) => {
	const result = await Axios.get<IMetadataSchema>(
		API_BASE_PREFIX + `watchlists/schema/${schema}`,
	);
	return result.data;
};

export function getSchemaAction(schema: SCHEMAS) {
	return async("SCHEMA", getSchema, schema, schema);
}
