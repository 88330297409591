import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import PrivateRoute from "./components/Route/PrivateRoute";
import SecretsRoute from "./components/Route/SecretsRoute";
import LoginContainer from "./containers/LoginPage";
import UsersContainer from "./containers/Users/UsersContainer";
import Logout from "./containers/LogoutPage";
import configAxios from "./config/axios";
import Layout from "./containers/Layout/Layout";
import VMSContainer from "./containers/VMS/VMSContainer";
import { Info } from "./containers/Info";
import TasksContainer from "./containers/Tasks/TasksContainer";
import SecretsContainer from "./containers/Secrets/SecretsContainer";
import WatchlistsContainer from "./containers/Watchlists/WatchlistsContainer";
import LogsContainer from "./containers/Logs/LogsContainer";
import EventsContainer from "./containers/Events/EventsContainer";
import PermissionsRoute from "./components/Route/PermissionsRoute";
import {
	ConfigurationItem,
	UsersItem,
	VMSItem,
	WatchlistItem,
	LogsItem,
	ReportsItem,
} from "./config/MenuItems";
import ConfigurationContainer from "./containers/Configuration/ConfigurationContainer";
import ImageSearchContainer from "./containers/ImageSearch/ImageSearchContainer";
import SearchResultContainer from "./containers/SearchResult/SearchResultContainer";
import ReportsContainer from "./containers/Reports/ReportsContainer";
import BackwardSearchResultView from "./containers/BackwardSearchResult/BackwardSearchResultView";
import BackwardSearchView from "./containers/BackwardSearch/BackwardSearchView";
import LiveEventsView from "./containers/LiveEvents/LiveEventsView";
import { useDispatch } from "react-redux";
import { setSdkIsLoadedAction } from "./store/LiveCameras/action";

const { createBrowserHistory } = require("history");

configAxios();

export const history = createBrowserHistory();

const App: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		XPMobileSDK.onLoad = () => dispatch(setSdkIsLoadedAction());
		if (XPMobileSDK.isLoaded()) dispatch(setSdkIsLoadedAction());

		class CalmResizeObserver extends ResizeObserver {
			constructor(callback: ResizeObserverCallback) {
				super((entries, observer) => {
					requestAnimationFrame(() => {
						callback(entries, observer);
					});
				});
			}
		}

		window.ResizeObserver = CalmResizeObserver;
	}, [dispatch]);

	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/login" component={LoginContainer} />
				<Route exact path="/info" component={Info} />
				<Layout>
					<Switch>
						<PermissionsRoute
							path="/vms"
							exact
							redirect="/watchlists"
							permissions={VMSItem.permissions}
							component={VMSContainer}
						/>
						<PrivateRoute
							path="/events"
							exact
							component={EventsContainer}
						/>
						<PrivateRoute
							path="/live"
							exact
							component={LiveEventsView}
						/>
						<PermissionsRoute
							path="/backwardSearch"
							exact
							component={BackwardSearchView}
							permissions={WatchlistItem.permissions}
							redirect="/vms"
						/>
						<PermissionsRoute
							path="/backwardSearch/:id"
							exact
							component={BackwardSearchResultView}
							permissions={WatchlistItem.permissions}
							redirect="/vms"
						/>
						<PermissionsRoute
							path="/watchlists"
							exact
							redirect="/secrets"
							permissions={WatchlistItem.permissions}
							component={WatchlistsContainer}
						></PermissionsRoute>
						<PermissionsRoute
							path="/watchlists/search"
							exact
							component={ImageSearchContainer}
							permissions={WatchlistItem.permissions}
							redirect="/vms"
						/>
						<PermissionsRoute
							path="/watchlists/search/:id"
							exact
							component={SearchResultContainer}
							permissions={WatchlistItem.permissions}
							redirect="/vms"
						/>
						<SecretsRoute
							path="/secrets"
							exact
							component={SecretsContainer}
							history={history}
						/>
						<PrivateRoute
							path="/tasks"
							exact
							component={TasksContainer}
						/>
						<PermissionsRoute
							path="/users"
							exact
							redirect="/vms"
							permissions={UsersItem.permissions}
							component={UsersContainer}
						/>
						<PermissionsRoute
							path="/logs"
							exact
							redirect="/vms"
							permissions={LogsItem.permissions}
							component={LogsContainer}
						/>
						<PermissionsRoute
							path="/config"
							redirect="/vms"
							permissions={ConfigurationItem.permissions}
							component={ConfigurationContainer}
						></PermissionsRoute>
						<PermissionsRoute
							path="/reports"
							redirect="/vms"
							permissions={ReportsItem.permissions}
							component={ReportsContainer}
						></PermissionsRoute>
						<PrivateRoute
							path="/logout"
							exact
							component={Logout}
						></PrivateRoute>
						<Route>
							<Redirect to="/vms" />
						</Route>
					</Switch>
				</Layout>
			</Switch>
		</Router>
	);
};

export default App;
