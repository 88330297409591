import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useForwardRef from "../../hooks/useForwardRef";
import {
	ArrowForwardIos as ArrowIcon,
	Add,
	Remove,
	ZoomOutMapRounded,
} from "@material-ui/icons";
import { THEME } from "../../config";
import { isEqual } from "lodash";
import clsx from "clsx";

export type TImagePos =
	| {
			top: number;
			left: number;
			right: number;
			bottom: number;
	  }
	| undefined;

type Props = {
	lastImage: HTMLImageElement | null;
	setImagePos: React.Dispatch<React.SetStateAction<TImagePos>>;
	imagePos: TImagePos;
	drawMinimap: (image: HTMLImageElement, pos: TImagePos) => void;
	dragRect: (
		image: HTMLImageElement,
		dragPos: { x: number; y: number },
		cursorPos: { x: number; y: number },
	) => void;
	dragging: boolean;
	setDragging: React.Dispatch<React.SetStateAction<boolean>>;
	dragPos: { x: number; y: number };
	setDragPos: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
	cursorPos: { x: number; y: number };
	setCursorPos: React.Dispatch<
		React.SetStateAction<{ x: number; y: number }>
	>;
	zoomLevel: number;
	setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
	drawRects?: (pos: TImagePos) => void;
	setMaximized?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
		maxWidth: "100%",
		maxHeight: "100%",
		display: "flex",
		justifyContent: "center",
	},
	wrapper: {
		position: "absolute",
		top: 0,
		height: "100%",
		maxWidth: "100%",
		maxHeight: "100%",
	},
	controls: {
		position: "absolute",
		bottom: 0,
		left: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: "50%",
		backgroundColor: "rgba(70, 70, 70, 0.8)",
		width: "6rem",
		height: "6rem",
		margin: 10,
		padding: 3,
		transition: "opacity 0.1s ease-in-out",
	},
	icon: {
		width: 16,
		height: 16,
		color: THEME.palette.success.main,
		cursor: "pointer",
		transition: "color 0.2s ease-in-out",
		"&:hover": {
			color: THEME.palette.success.light,
		},
		"&:active": {
			scale: 0.9,
		},
	},
	zoomOutWrapper: {
		display: "flex",
		position: "absolute",
		top: -11,
		left: -4,
		backgroundColor: "rgba(70, 70, 70)",
		borderRadius: "50%",
		padding: 4,
		transition: "opacity 0.2s ease-in-out",
	},
	zoomOutMapIcon: {
		width: 24,
		height: 24,
	},
	zoomInOutIcon: {
		color: THEME.palette.grey[300],
		cursor: "pointer",
		transition: "color 0.2s ease-in-out",
		"&:hover": {
			color: THEME.palette.grey[500],
		},
		"&:active": {
			scale: 0.9,
		},
	},
}));

const zoomStep = 0.1;

const ZoomOverlay = React.forwardRef<HTMLCanvasElement, Props>(
	(
		{
			lastImage,
			setImagePos,
			imagePos,
			drawMinimap,
			dragRect,
			dragPos,
			setDragPos,
			dragging,
			setDragging,
			cursorPos,
			setCursorPos,
			zoomLevel,
			setZoomLevel,
			drawRects,
			setMaximized,
		},
		ref,
	) => {
		const classes = useStyles();
		const canvasRef = useForwardRef(ref);
		const context = useRef<CanvasRenderingContext2D | null>(null);
		const intervalRef = useRef<NodeJS.Timeout | null>(null);
		const hideZoomTimeoutRef = useRef<NodeJS.Timeout | null>(null);

		const [showZoomControls, setShowZoomControls] = useState(false);
		const usedCursorPos = useRef<undefined | { x: number; y: number }>();

		const drawImage = useCallback(
			(pos: TImagePos) => {
				const canvas = canvasRef?.current;
				if (!context.current)
					context.current = canvas?.getContext("2d");

				const ctx = context.current;
				if (ctx && lastImage && pos) {
					ctx.drawImage(
						lastImage,
						pos.left,
						pos.top,
						pos.right - pos.left,
						pos.bottom - pos.top,
						0,
						0,
						canvas.width,
						canvas.height,
					);
					drawMinimap(lastImage, pos);
					if (drawRects) drawRects(pos);
				}
			},
			[canvasRef, lastImage, drawMinimap, drawRects],
		);

		const handleAutoHideControls = useCallback(() => {
			if (!showZoomControls) setShowZoomControls(true);
			const zoomTimeout = hideZoomTimeoutRef.current;
			if (zoomTimeout) clearTimeout(zoomTimeout);
			hideZoomTimeoutRef.current = setTimeout(
				() => setShowZoomControls(false),
				2000,
			);
		}, [showZoomControls]);

		const handleZoom = useCallback(
			(value: number, pos: TImagePos) => {
				value = Number(value.toFixed(1));
				if (value < 1 || value > 1.9) return;
				const canvas = canvasRef?.current;
				setImagePos(pos);
				setZoomLevel((prev) => {
					if (canvas && lastImage) {
						if (pos) drawImage(pos);
						return value;
					} else return prev;
				});
				handleAutoHideControls();
			},
			[
				canvasRef,
				lastImage,
				setImagePos,
				drawImage,
				setZoomLevel,
				handleAutoHideControls,
			],
		);

		const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
			e.persist();
			const canvas = canvasRef?.current;
			if (canvas && lastImage) {
				const { width, height } = lastImage;
				const direction = e.deltaY < 0 ? "in" : "out";
				const newZoom = Number(
					(
						zoomLevel + (direction === "in" ? zoomStep : -zoomStep)
					).toFixed(1),
				);

				if (newZoom < 1 || newZoom > 1.9) return;

				const wDiff = width * newZoom - width;
				const hDiff = height * newZoom - height;

				const cPos =
					direction === "in"
						? cursorPos
						: usedCursorPos.current ?? cursorPos;

				const pos = {
					top: hDiff * cPos.y,
					left: wDiff * cPos.x,
					right: width - wDiff * (1 - cPos.x),
					bottom: height - hDiff * (1 - cPos.y),
				};
				usedCursorPos.current = cPos;
				handleZoom(newZoom, pos);
			}
		};

		const getPos = useCallback(
			(zoom: number) => {
				if (lastImage) {
					const { width, height } = lastImage;
					const wDiff = width * zoom - width;
					const hDiff = height * zoom - height;
					const cPos = usedCursorPos.current ?? {
						x: 0.5,
						y: 0.5,
					};
					const pos = {
						top: hDiff * cPos.y,
						left: wDiff * cPos.x,
						right: width - wDiff * (1 - cPos.x),
						bottom: height - hDiff * (1 - cPos.y),
					};
					return pos;
				}
			},
			[lastImage, usedCursorPos],
		);

		const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
			e.preventDefault();
			if (e.button !== 0) return;
			setDragging(true);
			const { clientX, clientY } = e;
			const { left, top, width, height } =
				e.currentTarget.getBoundingClientRect();

			const x = (clientX - left) / width;
			const y = (clientY - top) / height;
			setDragPos({
				x: Math.max(Math.min(Number(x.toFixed(2)), 1), 0),
				y: Math.max(Math.min(Number(y.toFixed(2)), 1), 0),
			});
		};

		const handleDragEnd = useCallback(
			(e: Event | React.MouseEvent) => {
				e.preventDefault();
				if (dragging) {
					setDragging(false);
					let centerPos: { x: number; y: number } | undefined,
						zoom: number | undefined,
						pos: TImagePos | undefined;
					setDragPos((prev) => {
						if (
							zoomLevel === 1 &&
							lastImage &&
							!isEqual(prev, cursorPos)
						) {
							const { width, height } = lastImage;
							const aspectRatio = width / height;
							const newWidth =
								width * Math.abs(prev.x - cursorPos.x);
							const newHeight =
								height * Math.abs(prev.y - cursorPos.y);
							zoom = Math.min(
								1.9,
								Number(
									(1 + (1 - newHeight / height)).toFixed(1),
								),
								Number((1 + (1 - newWidth / width)).toFixed(1)),
							);

							const startX = Math.min(prev.x, cursorPos.x);
							const startY = Math.min(prev.y, cursorPos.y);
							const top = height * startY;
							const left = width * startX;
							const right = left + (1 - (zoom - 1)) * width;
							const bottom = (right - left) / aspectRatio + top;
							pos = { top, left, right, bottom };

							if (right > width) {
								pos.right = width;
								pos.left = left - (right - width);
							}
							if (bottom > height) {
								pos.bottom = height;
								pos.top = top - (bottom - height);
							}

							centerPos = {
								x: (prev.x + cursorPos.x) / 2,
								y: (prev.y + cursorPos.y) / 2,
							};
						}
						if (centerPos) usedCursorPos.current = centerPos;
						if (zoom && pos) handleZoom(zoom, pos);
						return { x: 0, y: 0 };
					});
				}
			},
			[
				cursorPos,
				zoomLevel,
				lastImage,
				dragging,
				handleZoom,
				setDragging,
				setDragPos,
			],
		);

		const move = useCallback(
			(to: "top" | "left" | "right" | "bottom", amount: number) =>
				setImagePos((prev) => {
					const canvas = canvasRef.current;
					if (canvas && lastImage && prev) {
						const { width, height } = lastImage;
						const pos = {
							left:
								to === "left"
									? prev.left - amount
									: to === "right"
									? prev.left + amount
									: prev.left,
							right:
								to === "left"
									? prev.right - amount
									: to === "right"
									? prev.right + amount
									: prev.right,
							top:
								to === "top"
									? prev.top - amount
									: to === "bottom"
									? prev.top + amount
									: prev.top,
							bottom:
								to === "top"
									? prev.bottom - amount
									: to === "bottom"
									? prev.bottom + amount
									: prev.bottom,
						};
						if (
							pos.left < 0 ||
							pos.right > width ||
							pos.top < 0 ||
							pos.bottom > height
						)
							return prev;
						handleZoom(zoomLevel, pos);
						usedCursorPos.current = {
							x: (pos.right + pos.left) / 2 / width,
							y: (pos.bottom + pos.top) / 2 / height,
						};
						return pos;
					}
					return prev;
				}),
			[canvasRef, lastImage, zoomLevel, setImagePos, handleZoom],
		);

		const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
			if (!canvasRef || !canvasRef?.current || !lastImage) return;
			e.persist();
			const imgPos = imagePos ?? {
				left: 0,
				right: lastImage.width,
				top: 0,
				bottom: lastImage.height,
			};
			const clientWidth = e.currentTarget.getBoundingClientRect().width;
			const clientHeight = e.currentTarget.getBoundingClientRect().height;
			const widthRatio = lastImage.width / clientWidth;
			const heightRatio = lastImage.height / clientHeight;

			const left = imgPos.left / widthRatio;
			const top = imgPos.top / heightRatio;
			const right = imgPos.right / widthRatio;
			const bottom = imgPos.bottom / heightRatio;

			const mouseX =
				e.clientX - e.currentTarget.getBoundingClientRect().left;
			const mouseY =
				e.clientY - e.currentTarget.getBoundingClientRect().top;

			const x = mouseX / clientWidth;
			const y = mouseY / clientHeight;
			const xFrom = left / clientWidth;
			const xTo = right / clientWidth;
			const yFrom = top / clientHeight;
			const yTo = bottom / clientHeight;
			const newX = xFrom + (xTo - xFrom) * x;
			const newY = yFrom + (yTo - yFrom) * y;

			const pos = {
				x: Math.max(Math.min(Number(newX.toFixed(2)), 1), 0),
				y: Math.max(Math.min(Number(newY.toFixed(2)), 1), 0),
			};

			setCursorPos(pos);
			if (dragging && zoomLevel > 1) {
				if (e.movementX < 0) move("right", -e.movementX / zoomLevel);
				if (e.movementX > 0) move("left", e.movementX / zoomLevel);
				if (e.movementY < 0) move("bottom", -e.movementY / zoomLevel);
				if (e.movementY > 0) move("top", e.movementY / zoomLevel);
			} else if (dragging) {
				const pos = {
					top: 0,
					left: 0,
					right: lastImage.width,
					bottom: lastImage.height,
				};
				drawImage(pos);
				dragRect(lastImage, dragPos, cursorPos);
			}
		};

		const onMouseDown = (e?: EventTarget) => {
			if (!e) return;
			intervalRef.current = setInterval(() => {
				e.dispatchEvent(
					new MouseEvent("click", {
						view: window,
						bubbles: true,
						cancelable: true,
					}),
				);
			}, 50);
		};

		const onMouseUp = () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
			intervalRef.current = null;
			handleDragEnd(new MouseEvent("mouseup"));
		};

		const handleArrowKey = useCallback(
			(direction: "top" | "left" | "right" | "bottom") => {
				const amount = 20;
				move(direction, amount);
			},
			[move],
		);

		const handleZoomKey = useCallback(
			(key: "+" | "-") => {
				const newZoom =
					zoomLevel + (key === "+" ? zoomStep : -zoomStep);
				handleZoom(newZoom, getPos(newZoom));
			},
			[zoomLevel, handleZoom, getPos],
		);

		useEffect(() => {
			const handleKeyDown = (e: KeyboardEvent) => {
				switch (e.key) {
					case "ArrowUp":
						handleArrowKey("top");
						break;
					case "ArrowDown":
						handleArrowKey("bottom");
						break;
					case "ArrowLeft":
						handleArrowKey("left");
						break;
					case "ArrowRight":
						handleArrowKey("right");
						break;
					case "+":
						handleZoomKey("+");
						break;
					case "-":
						handleZoomKey("-");
						break;
					default:
						break;
				}
			};
			document.addEventListener("keydown", handleKeyDown);
			return () => {
				document.removeEventListener("keydown", handleKeyDown);
			};
		}, [handleArrowKey, handleZoomKey, handleDragEnd]);

		useEffect(() => {
			return () => {
				if (hideZoomTimeoutRef.current)
					clearTimeout(hideZoomTimeoutRef.current);
			};
		}, []);

		return (
			<div
				className={classes.root}
				onMouseMove={handleAutoHideControls}
				onMouseLeave={handleDragEnd}
				onMouseUp={handleDragEnd}
				onDoubleClick={(e) => {
					e.stopPropagation();
					const tag = (e.target as HTMLElement).tagName;
					if (tag === "svg" || tag === "path") return;
					setMaximized?.((prev) => !prev);
				}}
			>
				<div
					className={classes.wrapper}
					onWheel={handleWheel}
					onDragStart={handleDragStart}
					onMouseMove={handleMouseMove}
					draggable
					style={{
						aspectRatio: lastImage
							? `${lastImage.width}/${lastImage.height}`
							: "unset",
						width: lastImage ? "unset" : "100%",
						cursor:
							dragging && zoomLevel > 1
								? "grabbing"
								: zoomLevel > 1
								? "grab"
								: "auto",
					}}
				/>
				<Box
					className={classes.controls}
					visibility={showZoomControls ? "visible" : "hidden"}
					style={{ opacity: showZoomControls ? 1 : 0 }}
				>
					<Box
						className={classes.zoomOutWrapper}
						style={{
							opacity: zoomLevel > 1 ? 1 : 0,
						}}
					>
						<ZoomOutMapRounded
							className={clsx(
								classes.icon,
								classes.zoomOutMapIcon,
							)}
							onClick={() =>
								handleZoom(1, {
									top: 0,
									left: 0,
									right: lastImage?.width ?? 0,
									bottom: lastImage?.height ?? 0,
								})
							}
						/>
					</Box>
					<Box display="flex" justifyContent="center">
						<ArrowIcon
							style={{ transform: "rotate(-90deg)" }}
							className={classes.icon}
							onClick={() => move("top", 15)}
							onMouseDown={(e) => onMouseDown(e.target)}
							onMouseLeave={onMouseUp}
							onMouseUp={onMouseUp}
						/>
					</Box>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<ArrowIcon
							style={{ transform: "rotate(180deg)" }}
							className={classes.icon}
							onClick={() => move("left", 15)}
							onMouseDown={(e) => onMouseDown(e.target)}
							onMouseLeave={onMouseUp}
							onMouseUp={onMouseUp}
						/>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="space-evenly"
							width="60%"
							style={{
								backgroundColor: "rgba(0,0,0,0.6)",
								borderRadius: "50%",
								aspectRatio: "1/1",
							}}
						>
							<Add
								fontSize="small"
								className={classes.zoomInOutIcon}
								onClick={() => {
									const newZoom = zoomLevel + zoomStep;
									handleZoom(newZoom, getPos(newZoom));
								}}
							/>
							<span
								style={{
									width: "100%",
									borderBottom: `1px solid ${THEME.palette.grey[600]}`,
								}}
							/>
							<Remove
								fontSize="small"
								className={classes.zoomInOutIcon}
								onClick={() => {
									const newZoom = zoomLevel - zoomStep;
									handleZoom(newZoom, getPos(newZoom));
								}}
							/>
						</Box>
						<ArrowIcon
							className={classes.icon}
							onClick={() => move("right", 15)}
							onMouseDown={(e) => onMouseDown(e.target)}
							onMouseLeave={onMouseUp}
							onMouseUp={onMouseUp}
						/>
					</Box>
					<Box display="flex" justifyContent="center">
						<ArrowIcon
							style={{ transform: "rotate(90deg)" }}
							className={classes.icon}
							onClick={() => move("bottom", 15)}
							onMouseDown={(e) => onMouseDown(e.target)}
							onMouseLeave={onMouseUp}
							onMouseUp={onMouseUp}
						/>
					</Box>
				</Box>
			</div>
		);
	},
);

export default ZoomOverlay;
