import moment from "moment";

export class AuthError extends Error {
	constructor(message?: string) {
		super(message);
		Object.setPrototypeOf(this, new.target.prototype);
		this.name = AuthError.name;
	}
}

export class AuthenticationData {
	accessToken: string;
	tokenType: string;
	refreshToken: string;
	validUntil: moment.Moment;
	user?: User;
	constructor(
		accessToken: string,
		tokenType: string,
		refreshToken: string,
		validUntil: moment.Moment,
		user?: User,
	) {
		this.accessToken = accessToken;
		this.tokenType = tokenType;
		this.refreshToken = refreshToken;
		this.validUntil = validUntil;
		this.user = user;
	}
}

export class User {
	id: number;
	userName: string;
	email: string;
	authorities: string[];
	constructor(
		id: number,
		userName: string,
		email: string,
		authorities: string[],
	) {
		this.id = id;
		this.userName = userName;
		this.email = email;
		this.authorities = authorities;
	}
}
