import React from "react";
import { Typography, Tooltip, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";

interface IRulesFieldProps {
	property: string;
	field: string | number;
	tooltipTitle?: React.ReactNode;
	className?: string;
}

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: "#585858",
		maxWidth: "60vw",
	},
	row: {
		display: "flex",
		padding: "0 4px",
	},
	property: {
		minWidth: "11ch",
	},
}));

const RulesField: React.FC<IRulesFieldProps> = ({
	property,
	field,
	tooltipTitle,
	className,
}) => {
	const classes = useStyles();
	return (
		<Tooltip
			title={tooltipTitle ?? ""}
			placement="top-start"
			enterTouchDelay={1000}
			enterNextDelay={1250}
			classes={{ tooltip: classes.tooltip }}
		>
			<Box className={clsx(classes.row, className)}>
				<Typography className={classes.property}>{property}</Typography>
				<Typography>
					{field?.toString() ? (
						field
					) : (
						<Skeleton variant="text" width={50} />
					)}
				</Typography>
			</Box>
		</Tooltip>
	);
};
export default RulesField;
