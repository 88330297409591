import { makeStyles, Box, Typography } from "@material-ui/core";
import { EventsFilter } from "../../../store/Events/types";
import FavoriteCard from "./FavoriteCard";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import {
	deleteConfigAction,
	updateConfigAction,
} from "../../../store/UserConfig/action";
import { isEqual } from "lodash";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { IConfigBase, IPreference } from "../../../store/UserConfig/types";

const useStyles = makeStyles(() => ({
	favorites: {
		margin: 0,
		padding: "6px 0",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		gap: 18,
	},
	showFiltersText: {
		color: "#0047AB",
		cursor: "pointer",
		userSelect: "none",
		width: "100%",
		paddingTop: 8,
		textAlign: "center",
	},
}));

type Props = {
	currentFilter: EventsFilter | undefined;
	onFilter: (filter: EventsFilter) => void;
};

export interface IFavorite extends IConfigBase {
	filter: EventsFilter;
}

let configDebounce: NodeJS.Timeout | null = null;

const Favorites = ({ currentFilter, onFilter }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const userConfig = useSelector((state: AppState) => state.userConfig.data);
	const favoritePreferences = useMemo(
		() =>
			userConfig.preference.favorites?.length
				? userConfig.preference.favorites
				: undefined,
		[userConfig.preference.favorites],
	);

	const [showFilters, setShowFilters] = useState<boolean>(
		userConfig.preference.showFavoriteFilters ?? true,
	);
	const [favoriteBoxes, setFavoriteBoxes] = useState<{ id: string }[]>([
		...(favoritePreferences
			? favoritePreferences
			: userConfig.favorite.map((f) => ({
					id: f.id,
			  }))),
	]);

	const editFavorite = (
		favorite: IFavorite,
		callback: (err: string | null) => void,
		oldName?: string,
	) => {
		if (
			oldName &&
			oldName !== favorite.name &&
			userConfig.favorite.some((f) => f.name === favorite.name)
		) {
			return callback("Favorite name already exists");
		}
		dispatch(
			updateConfigAction({
				variant: "favorite",
				config: favorite,
			}),
		);
		callback(null);
	};

	const deleteFavorite = (favorite: IFavorite) => {
		dispatch(
			deleteConfigAction({
				variant: "favorite",
				config: favorite,
			}),
		);
		dispatch(
			updateConfigAction({
				variant: "preference",
				config: {
					...userConfig.preference,
					favorites: userConfig.preference.favorites?.filter(
						(f) => f.id !== favorite.id,
					),
				},
			}),
		);
	};

	const updatePreferences = (preferences: IPreference) => {
		if (configDebounce) clearTimeout(configDebounce);
		configDebounce = setTimeout(() => {
			dispatch(
				updateConfigAction({
					variant: "preference",
					config: preferences,
				}),
			);
		}, 3000);
	};

	const handleShowFilters = (show: boolean) => {
		updatePreferences({
			...userConfig.preference,
			showFavoriteFilters: show,
		});
		setShowFilters(show);
	};

	const selectFavorite = (favorite: IFavorite) => {
		onFilter(favorite.filter);
	};

	const selectedFavorite = userConfig.favorite.find((f) =>
		isEqual(f.filter, currentFilter),
	);

	const handleDrop = (result: DropResult) => {
		if (!result.destination) return;
		const items = [...favoriteBoxes];
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setFavoriteBoxes(items);
		updatePreferences({
			...userConfig.preference,
			favorites: items,
		});
	};

	useEffect(() => {
		setFavoriteBoxes([
			...(favoritePreferences
				? favoritePreferences
				: userConfig.favorite.map((f) => ({
						id: f.id,
						showFilters: true,
				  }))),
		]);
	}, [favoritePreferences, userConfig.favorite]);

	return (
		<Box className={classes.favorites}>
			{userConfig.favorite?.length === 0 && (
				<Typography variant="body1">No favorites yet</Typography>
			)}
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId="list-container">
					{(provided) => (
						<div
							className={clsx(
								"list-container",
								classes.container,
							)}
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{favoriteBoxes.map((box, i) => {
								const favorite = userConfig.favorite.find(
									(f) => f.id === box.id,
								);
								if (!favorite) return null;
								return (
									<Draggable
										key={box.id}
										draggableId={box.id}
										index={i}
									>
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
											>
												<div
													{...provided.dragHandleProps}
												>
													<FavoriteCard
														key={i}
														favorite={favorite}
														editFavorite={
															editFavorite
														}
														deleteFavorite={
															deleteFavorite
														}
														selectFavorite={
															selectFavorite
														}
														selected={
															selectedFavorite?.id ===
															favorite.id
														}
														showFilters={
															showFilters
														}
													/>
												</div>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{(userConfig.favorite?.length ?? 0) > 0 && (
				<Typography
					onClick={() => handleShowFilters(!showFilters)}
					className={classes.showFiltersText}
				>
					{showFilters ? "Hide" : "Show"} filters
				</Typography>
			)}
		</Box>
	);
};

export default Favorites;
