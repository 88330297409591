import {
	Box,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import "../../components/Events/resizableStyles.css";
import { AppState } from "../../store";
import {
	IElasticSource,
	IElasticSourceObjects,
	MatchProps,
} from "../../store/Events/types";
import {
	selectCameraAction,
	selectLiveEventAction,
	setDraggingViewAction,
	setTemporaryCameraAction,
} from "../../store/LiveCameras/action";
import { ILiveEvent } from "../../store/LiveCameras/types";
import { handleCopy } from "../Events/EventsList";
import LiveEvent from "./LiveEvent";
import LiveSettingsPopover from "./LiveSettingsPopover";
import { DEFAULT_MAX_EVENTS_LIMIT } from "../../store/LiveCameras/reducer";
import { updateConfigLocallyAction } from "../../store/UserConfig/action";
import { ticksToDate } from "../../helpers/Utils";
import moment from "moment";
import {
	EventsViewSide,
	IEventsViewConfig,
} from "../../store/UserConfig/types";
import {
	ArrowBackRounded,
	ArrowUpwardRounded,
	DragIndicator,
	FilterListRounded,
	OpenInNewRounded,
} from "@material-ui/icons";
import usePointerPos from "../../hooks/usePointerPos";
import theme from "../../config/Theme";
import { isPredefinedLayout } from "./Controls";
import { colsWidthKey } from "../../store/UserConfig/reducer";
import { CLICKABLE_TYPOGRAPHY_COLOR } from "../UI/ClickableTypography";
import { Link } from "react-router-dom";
import _ from "lodash";
import { HotspotType } from "./HotspotList";
import useBackwardSearch from "../../hooks/useBackwardSearch";
import useBackwardSearchResult from "../../hooks/useBackwardSearchResult";
import ResultsList from "../BackwardSearch/ResultsList";
import BackwardSearchDialog from "../Dialogs/BackwardSearchDialog";
import { FILTER_BUTTON_ID } from "../../containers/BackwardSearchResult/BackwardSearchResultView";
import BackwardFilterPopover from "../BackwardSearch/BackwardFilterPopover";

export const itemWidth = 240;
const itemHeight = 300;
const itemPadding = 4;
const maxPercentageOfWindow = 0.7;
const defaultColsCount = 1;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		paddingBottom: 8,
		transition: "opacity 0.2s",
	},
	controls: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0 2px",
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	title: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontWeight: "bold",
		fontSize: "1.1rem",
	},
	button: {
		color: "black",
		width: 34,
		height: 34,
		borderRadius: 10,
	},
	counter: {
		padding: 2,
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		cursor: "pointer",
		"& > svg": {
			fontSize: "1rem",
		},
	},
	searchHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));

type Props = {
	loadMatch: (match: MatchProps) => Promise<void>;
	eventsView: IEventsViewConfig;
	viewsCount: number;
	side: EventsViewSide;
	setBackwardEvents: Dispatch<
		SetStateAction<(IElasticSource & IElasticSourceObjects)[]>
	>;
};

const LiveEventsList = ({
	loadMatch,
	setBackwardEvents,
	eventsView,
	viewsCount,
	side,
}: Props) => {
	const classes = useStyles();
	const ref = useRef<VirtuosoHandle>(null);
	const scrollRef = useRef<HTMLElement | null>(null);
	const viewRef = useRef<HTMLDivElement>(null);

	const draggingView = useSelector(
		(state: AppState) => state.live.draggingView,
	);
	usePointerPos(({ x, y }) => {
		if (!viewRef.current || !draggingView) return;
		viewRef.current.style.left = `${x - 8}px`;
		viewRef.current.style.top = `${y - 14}px`;
	});

	const dispatch = useDispatch();

	const savedWidth = JSON.parse(localStorage.getItem(colsWidthKey) ?? "{}")[
		eventsView.id
	];
	const maxWidth = Math.max(
		Math.floor(
			(window.innerWidth * maxPercentageOfWindow) /
				viewsCount /
				itemWidth,
		) * itemWidth,
		itemWidth,
	);

	const itemsFitsToWidth = Math.floor(maxWidth / itemWidth) || 1;

	const [newEventsCounter, setNewEventsCounter] = useState(0);
	const [loadingMatches, setLoadingMatches] = useState(false);
	const [size, setSize] = useState({
		maxWidth,
		minWidth: itemWidth,
		width: Math.min(
			savedWidth ??
				(itemsFitsToWidth > defaultColsCount
					? defaultColsCount
					: itemsFitsToWidth) * itemWidth,
			maxWidth,
		),
		height: window.innerHeight,
		itemsFitsToWidth,
	});

	const [settingsAnchor, setSettingsAnchor] = useState<HTMLElement | null>(
		null,
	);

	const { isMqttConfigured, mqttConfigurationChecked } = useSelector(
		(state: AppState) => state.live,
	);

	const [searchDialogIsOpen, setSearchDialogIsOpen] = useState(false);
	const [searchEvent, setSearchEvent] = useState<
		| {
				faceImg: string;
				subjectId: string;
		  }
		| undefined
	>();
	const [searchId, setSearchId] = useState("");
	const [isSearchLoading, setIsSearchLoading] = useState(false);
	const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);

	const { uploadProgress, canSeeSecrets, handleSearchRequest } =
		useBackwardSearch({
			isDialogOpen: searchDialogIsOpen,
			onComplete: (id) => {
				setSearchId(id);
				setIsSearchLoading(false);
			},
			setOpenDialog: setSearchDialogIsOpen,
		});
	const {
		events: backwardEvents,
		isEventsLoading,
		isResultLoading,
		filter: backwardFilter,
		results,
		allSources,
		setFilter,
	} = useBackwardSearchResult({
		id: searchId,
		selectedResultIndex: 0,
	});

	const sourceKeys = useSelector((state: AppState) => state.sources.keys);
	const subjects = useSelector((state: AppState) => state.subjects);
	const [cachedView, setCachedView] = useState(eventsView);
	const events = useSelector((state: AppState) => {
		const view = eventsView.saved === false ? cachedView : eventsView;
		const eventsList = [
			...(eventsView.separateEvents
				? state.live.separatedEvents
				: state.live.events),
		];
		const filteredEvents: ILiveEvent[] = eventsList.filter((evt) => {
			const topic = evt.topic.split("/").slice(2);
			const [vms, ruleName, sourceId] = topic;
			const isVmsSubscribed = view.enabledVms?.includes(vms);
			const isSourceSubscribed = view.enabledSources?.includes(
				`${vms}/${sourceId}`,
			);
			const isRuleSubscribed = view.enabledRules?.includes(
				`${vms}/${ruleName}`,
			);
			return (
				view.enabledAll ||
				(isVmsSubscribed && isSourceSubscribed && isRuleSubscribed)
			);
		});

		if (eventsView.saved !== false && !_.isEqual(view, cachedView))
			setCachedView(view);
		return filteredEvents;
	});
	const selectedEventIds = useSelector(
		(state: AppState) => state.live.selectedEventIds,
	);

	const layouts = useSelector(
		(state: AppState) => state.userConfig.data.layouts,
	);
	const selectedLayoutId = useSelector(
		(state: AppState) => state.userConfig.data.live?.selectedLayoutId,
	);

	const selectedLayout = layouts.find((l) => l.id === selectedLayoutId);

	const eventsByRow = useMemo(
		() =>
			events.reduce((acc, event, i) => {
				const index = Math.floor(
					i / Math.floor(size.width / itemWidth),
				);
				if (!acc[index]) acc[index] = [];
				acc[index].push(event as ILiveEvent);
				return acc;
			}, [] as ILiveEvent[][]),
		[events, size.width],
	);

	const eventsByRowLimited = useMemo(() => {
		const eventArr: (ILiveEvent | null)[] = [...events];
		const addEmptyElementsCount =
			Math.floor(size.width / itemWidth) -
			(eventArr.length % Math.floor(size.width / itemWidth));

		if (
			addEmptyElementsCount > 0 &&
			addEmptyElementsCount !== Math.floor(size.width / itemWidth)
		) {
			for (let i = 0; i < addEmptyElementsCount; i++) {
				eventArr.unshift(null);
			}
		}

		return eventArr
			.reduce((acc, event, i) => {
				const index = Math.floor(
					i / Math.floor(size.width / itemWidth),
				);
				if (!acc[index]) acc[index] = [];
				acc[index].push(event as ILiveEvent);

				return acc;
			}, [] as (ILiveEvent | null)[][])
			.slice(
				0,
				Math.ceil(
					(eventsView.maxEventsLimit ?? DEFAULT_MAX_EVENTS_LIMIT) /
						Math.floor(size.width / itemWidth),
				),
			);
	}, [events, size.width, eventsView.maxEventsLimit]);

	const displayCamera = (
		event:
			| (ILiveEvent & { "@timestamp"?: string })
			| (IElasticSource & IElasticSourceObjects),
	) => {
		if (!selectedLayout) return;
		const newLayout = { ...selectedLayout };
		const playbackHotspotIndex = newLayout.cameras.findIndex(
			(c) => c?.hotspotType === HotspotType.Playback,
		);
		if (playbackHotspotIndex >= 0) return;

		const currentCameraIndex = newLayout.cameras.findIndex(
			(c) => c?.sourceId === event.sourceId && c?.vmsName === event.vms,
		);

		const timestamp = event.timeStamp
			? moment(ticksToDate(Number(event.timeStamp))).valueOf()
			: event["@timestamp"]
			? moment(event["@timestamp"]).valueOf()
			: undefined;

		if (currentCameraIndex < 0)
			return dispatch(
				setTemporaryCameraAction({
					isLive: false,
					sourceId: event.sourceId,
					sourceName:
						sourceKeys[event.vms]?.content.find(
							(s) => s.id === event.sourceId,
						)?.displayName ?? "",
					vmsName: event.vms,
					timestamp,
				}),
			);

		const camera = newLayout.cameras[currentCameraIndex];
		if (!camera) return;

		newLayout.cameras[currentCameraIndex] = {
			...camera,
			isLive: false,
			timestamp,
			revertToLiveOnChange: true,
		};

		dispatch(
			updateConfigLocallyAction({
				variant: "layouts",
				config: newLayout,
			}),
		);
	};

	const handleDragStart = (view: IEventsViewConfig) => {
		dispatch(setDraggingViewAction(view));
	};

	const handleEventClick = (event: ILiveEvent | null) => {
		if (
			!event ||
			(event?.id === selectedEventIds?.eventId &&
				eventsView.id === selectedEventIds?.viewId)
		)
			return;

		if (selectedLayout?.cameras?.some((c) => c?.revertToLiveOnChange))
			dispatch(
				updateConfigLocallyAction({
					variant: "layouts",
					config: {
						...selectedLayout,
						cameras: selectedLayout.cameras.map((c) => {
							if (c?.revertToLiveOnChange)
								return {
									...c,
									revertToLiveOnChange: false,
									isLive: true,
								};
							return c;
						}),
					},
				}),
			);

		dispatch(
			selectLiveEventAction({
				eventId: event.id,
				viewId: eventsView.id,
			}),
		);

		dispatch(
			selectCameraAction({
				vmsName: event.vms,
				sourceId: event.sourceId,
			}),
		);
		dispatch(setTemporaryCameraAction(null));
	};

	useEffect(() => {
		if (backwardEvents.length > 0)
			setBackwardEvents(
				backwardEvents.map((e) => ({
					...e._source,
					id: e._id,
				})),
			);
	}, [backwardEvents, setBackwardEvents]);

	useEffect(() => {
		const handleResize = () => {
			setSize((prev) => ({
				...prev,
				maxWidth: Math.max(
					itemWidth,
					Math.floor(
						(window.innerWidth * maxPercentageOfWindow) /
							viewsCount /
							itemWidth,
					) * itemWidth,
				),
				width: Math.min(
					(Math.floor(prev.width / itemWidth) || 1) * itemWidth,
					prev.maxWidth,
				),
				height: window.innerHeight,
				itemsFitsToWidth: Math.floor(prev.maxWidth / itemWidth) || 1,
			}));
		};

		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [viewsCount]);

	useEffect(() => {
		if (loadingMatches) return;
		const matchesToLoad: MatchProps[] = [];

		const eventsWithMatches =
			events.filter((event) => (event?.best?.matches?.length ?? 0) > 0) ??
			[];

		if (eventsWithMatches.length === 0) return;

		eventsWithMatches.forEach((event) => {
			const matches: MatchProps[] =
				event?.best?.matches?.slice(0, 3) ?? [];
			if (!matches) return;

			matches.forEach((match) => {
				const matchIsLoading = matchesToLoad.find(
					(m) => m.id === match.id && m.watchlist === match.watchlist,
				);
				const matchIsLoaded = subjects.keys[
					match.watchlist
				]?.content.find((s) => s.name === match.id);
				if (matchIsLoading || matchIsLoaded) return;

				matchesToLoad.push(match);
			});
		});

		if (matchesToLoad.length === 0) return;

		setLoadingMatches(true);
		Promise.all(matchesToLoad.map(loadMatch)).finally(() =>
			setLoadingMatches(false),
		);
	}, [events, loadMatch, subjects.keys, loadingMatches, eventsView.id]);

	useEffect(() => {
		if (selectedEventIds?.viewId !== eventsView.id) return;
		const selectedEvent = events.find(
			(event) => event.id === selectedEventIds?.eventId,
		);

		window.addEventListener("keydown", (e) => handleCopy(e, selectedEvent));
		return () => {
			window.removeEventListener("keydown", (e) =>
				handleCopy(e, selectedEvent),
			);
		};
	}, [selectedEventIds, events, eventsView.id]);

	useEffect(() => {
		if (scrollRef.current) {
			const scrollTop = scrollRef.current.scrollTop;
			if (scrollTop === 0) {
				setNewEventsCounter(0);
				return;
			} else setNewEventsCounter((prev) => prev + 1);

			scrollRef.current.scrollBy({
				top: itemHeight,
			});
		}
	}, [eventsByRow.length]);

	useEffect(() => {
		if (!selectedLayout || isPredefinedLayout(selectedLayout?.id)) return;

		const colsWidth = JSON.parse(
			localStorage.getItem(colsWidthKey) ?? "null",
		);
		localStorage.setItem(
			colsWidthKey,
			JSON.stringify({ ...colsWidth, [eventsView.id]: size.width }),
		);
	}, [selectedLayout, size, eventsView.id]);

	return (
		<Box display="flex">
			{(searchId || isSearchLoading) && (
				<Box>
					<Box className={classes.searchHeader}>
						<IconButton
							size="small"
							onClick={() => {
								setIsSearchLoading(false);
								setSearchId("");
							}}
						>
							<ArrowBackRounded />
						</IconButton>
						<Typography
							variant="subtitle1"
							style={{ fontWeight: "bold" }}
						>
							Search hits
						</Typography>
						<Box display="flex" gridGap={4} alignItems="center">
							<Tooltip title="Filter">
								<Box>
									<IconButton
										aria-describedby={FILTER_BUTTON_ID}
										color="inherit"
										size="small"
										disabled={!results}
										onClick={(e) =>
											setFilterAnchor(e.currentTarget)
										}
									>
										<FilterListRounded />
									</IconButton>
								</Box>
							</Tooltip>
							<Tooltip title="Open search">
								<Link
									to={`/backwardSearch/${searchId}?index=0`}
								>
									<IconButton size="small">
										<OpenInNewRounded />
									</IconButton>
								</Link>
							</Tooltip>
						</Box>
					</Box>
					<ResultsList
						events={backwardEvents}
						filter={backwardFilter}
						id={searchId}
						index={0}
						isEventsLoading={isEventsLoading}
						isResultsLoading={isResultLoading || isSearchLoading}
						selectEvent={(event) =>
							handleEventClick({
								...event._source,
								id: event._id,
							} as unknown as ILiveEvent)
						}
						selectedEvent={backwardEvents.find(
							(e) => e._id === selectedEventIds?.eventId,
						)}
						resizableWidth={size.width}
						onDoubleClick={(event) => {
							if (event) displayCamera(event);
						}}
					/>
				</Box>
			)}
			{draggingView && draggingView.id === eventsView.id && (
				<Box width={size.width} height={size.height} />
			)}
			<div
				ref={viewRef}
				className={classes.root}
				style={
					draggingView && draggingView.id === eventsView.id
						? {
								position: "absolute",
								zIndex: 9999,
								pointerEvents: "none",
								background: theme.palette.background.default,
								opacity: 0.6,
						  }
						: searchId || isSearchLoading
						? {
								display: "none",
						  }
						: {}
				}
			>
				<Box className={classes.controls} width={size.width}>
					<Box display="flex" alignItems="center" gridGap={4}>
						<Box
							display="flex"
							draggable
							style={{ cursor: "grab" }}
							onDragStart={() => handleDragStart(eventsView)}
						>
							<DragIndicator fontSize="small" />
						</Box>
						<Typography className={classes.title}>
							{eventsView.listTitle}
						</Typography>
					</Box>
					<IconButton
						aria-describedby="settingsButton"
						className={classes.button}
						disabled={!isMqttConfigured}
						onClick={(e) => setSettingsAnchor(e.currentTarget)}
					>
						<SettingsIcon />
					</IconButton>
					{isMqttConfigured && (
						<LiveSettingsPopover
							key={selectedLayoutId}
							anchorEl={settingsAnchor}
							onClose={() => setSettingsAnchor(null)}
							eventsView={eventsView}
							isLastView={viewsCount <= 1}
						/>
					)}
				</Box>
				<Typography
					variant="subtitle2"
					className={classes.counter}
					color="primary"
					style={{
						visibility: newEventsCounter > 0 ? "visible" : "hidden",
					}}
					onClick={() => {
						scrollRef.current?.scrollTo({ top: 0 });
						setNewEventsCounter(0);
					}}
				>
					<ArrowUpwardRounded
						color="primary"
						fontSize="small"
						style={{ paddingBottom: 2 }}
					/>
					{newEventsCounter} new
				</Typography>
				<ResizableBox
					resizeHandles={
						side === EventsViewSide.LEFT ? ["se"] : ["sw"]
					}
					width={size.width}
					height={size.height}
					minConstraints={[size.minWidth, size.height]}
					maxConstraints={[size.maxWidth, size.height]}
					onResize={(_, data) => {
						setSize((prev) => ({
							...prev,
							width: Math.min(data.size.width, prev.maxWidth),
							itemsFitsToWidth:
								Math.floor(prev.maxWidth / itemWidth) || 1,
						}));
					}}
					onResizeStop={() => {
						setSize((prev) => ({
							...prev,
							width: Math.min(
								(Math.floor(prev.width / itemWidth) || 1) *
									itemWidth,
								prev.maxWidth,
							),
						}));
					}}
				>
					<Box
						height="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{!isMqttConfigured && mqttConfigurationChecked ? (
							<Link
								to="/vms?tab=1"
								style={{
									color: CLICKABLE_TYPOGRAPHY_COLOR,
								}}
							>
								<Typography>
									Configure rules to send MQTT events
								</Typography>
							</Link>
						) : events.length === 0 ? (
							<Typography>No events</Typography>
						) : (
							<Virtuoso
								atTopStateChange={(isAtTop) => {
									if (isAtTop) setNewEventsCounter(0);
								}}
								ref={ref}
								scrollerRef={(sRef) => {
									if (sRef instanceof HTMLElement)
										scrollRef.current = sRef;
								}}
								style={{
									width: "100%",
									height: "100%",
								}}
								data={eventsByRowLimited}
								overscan={100}
								fixedItemHeight={itemHeight}
								defaultItemHeight={itemHeight}
								itemContent={(_, item) => {
									return (
										<Box
											display="flex"
											gridGap={8}
											p={itemPadding + "px"}
											height={itemHeight}
										>
											{item.map((event, i) => (
												<LiveEvent
													key={i}
													event={event}
													isSelected={
														event?.id ===
															selectedEventIds?.eventId &&
														eventsView.id ===
															selectedEventIds?.viewId
													}
													sourceName={
														event
															? sourceKeys[
																	event.vms
															  ]?.content.find(
																	(s) =>
																		s.id ===
																		event.sourceId,
															  )?.displayName ??
															  ""
															: ""
													}
													onClick={() =>
														handleEventClick(event)
													}
													onDoubleClick={() => {
														if (event)
															displayCamera(
																event,
															);
													}}
													isLoadingMatches={
														loadingMatches
													}
													loadMatch={loadMatch}
													view={cachedView}
													openSearchDialog={(
														image,
													) => {
														setSearchEvent({
															faceImg: image,
															subjectId:
																event?.subjectId ??
																"",
														});
														setSearchDialogIsOpen(
															true,
														);
													}}
												/>
											))}
										</Box>
									);
								}}
							/>
						)}
					</Box>
				</ResizableBox>
			</div>
			<BackwardSearchDialog
				open={searchDialogIsOpen}
				close={() => setSearchDialogIsOpen(false)}
				onSubmit={async (request) => {
					setIsSearchLoading(true);
					handleSearchRequest(request);
				}}
				loading={uploadProgress.total > uploadProgress.loaded}
				canSeeSecrets={canSeeSecrets}
				draggedImages={searchEvent ? [searchEvent.faceImg] : []}
				uploadProgress={uploadProgress}
				subjectId={searchEvent?.subjectId}
			/>
			{results && (
				<BackwardFilterPopover
					results={results}
					filter={backwardFilter}
					onFilter={(f) => setFilter(f)}
					anchorEl={filterAnchor}
					onClose={() => setFilterAnchor(null)}
					allSources={allSources}
				/>
			)}
		</Box>
	);
};

export default LiveEventsList;
