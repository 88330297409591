import {
	makeStyles,
	Box,
	Typography,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	TextField,
	Collapse,
} from "@material-ui/core";
import { DeleteRounded, Edit } from "@material-ui/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { THEME } from "../../../config";
import { IFavorite } from "./Favorites";
import _, { isEqual } from "lodash";
import { hiddenFilterKeys } from "./FiltersBar";
import { EventsFilter } from "../../../store/Events/types";
import Chips from "./Chips";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		width: "100%",
		gap: 4,
		padding: 5,
		paddingBottom: 0,
		borderRadius: 4,
		border: `2px solid ${THEME.palette.secondary.light}`,
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.primary.dark,
	},
	paragraph: {
		margin: 0,
	},
	controls: {
		display: "flex",
		justifyContent: "space-between",
	},
	useBtn: {
		backgroundColor: THEME.palette.secondary.light,
		color: THEME.palette.grey[200],
		padding: "2px 12px",
		minWidth: "unset",
		"&:hover": {
			backgroundColor: THEME.palette.primary.light,
		},
		fontSize: 12,
	},
	deleteIcon: {
		transition: "all 0.1s linear",
		color: "#B60000",
		"&:hover": {
			color: "#d12c2c",
		},
	},
	editIcon: {
		color: THEME.palette.primary.dark,
		transition: "all 0.1s linear",
		"&:hover": {
			color: THEME.palette.primary.main,
		},
	},
	chips: {
		paddingTop: 8,
		borderTop: `1px solid ${THEME.palette.primary.main}`,
		display: "flex",
		justifyContent: "center",
		gap: 4,
		flexWrap: "wrap",
	},
	content: {
		borderTop: `1px solid ${THEME.palette.primary.main}`,
		display: "flex",
		flexDirection: "column",
		gap: 10,
		paddingTop: 14,
		marginTop: 4,
	},
	selected: {
		"& $useBtn": {
			backgroundColor: THEME.palette.grey[500],
			color: THEME.palette.grey[300],
		},
		border: `2px solid ${THEME.palette.success.main}`,
	},
	main: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
}));

type Props = {
	favorite: IFavorite;
	editFavorite: (
		favorite: IFavorite,
		callback: (err: string | null) => void,
		oldName?: string,
	) => void;
	deleteFavorite: (favorite: IFavorite) => void;
	selectFavorite: (favorite: IFavorite) => void;
	selected: boolean;
	showFilters: boolean;
};

const FavoriteCard = ({
	favorite,
	editFavorite,
	deleteFavorite,
	selectFavorite,
	selected,
	showFilters,
}: Props) => {
	const classes = useStyles();
	const [editableFavorite, setEditableFavorite] = useState(favorite);
	const [isDialogOpen, setIsDialogOpen] = useState({
		edit: false,
		delete: false,
	});
	const [error, setError] = useState("");
	const ref = useRef<HTMLSpanElement | null>(null);

	const handleDialog = useCallback(
		(name: keyof typeof isDialogOpen) => {
			setIsDialogOpen({ ...isDialogOpen, [name]: !isDialogOpen[name] });
		},
		[isDialogOpen],
	);

	useEffect(() => {
		setEditableFavorite(favorite);
	}, [favorite]);

	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (e.key === "Enter" && isDialogOpen.delete) {
				e.preventDefault();
				if (isDialogOpen.delete) {
					deleteFavorite(favorite);
					handleDialog("delete");
				}
			}
		};
		window.addEventListener("keydown", listener);
		return () => window.removeEventListener("keydown", listener);
	}, [isDialogOpen, deleteFavorite, favorite, handleDialog]);

	return (
		<Box className={`${classes.root} ${selected ? classes.selected : ""}`}>
			<Box className={classes.main}>
				<Typography
					ref={ref}
					variant="body1"
					style={{
						whiteSpace: "normal",
						overflowWrap: "anywhere",
						fontWeight: 500,
					}}
				>
					{favorite.name}
				</Typography>
				<Box className={classes.controls}>
					<Button
						size="small"
						className={classes.useBtn}
						onClick={() => selectFavorite(favorite)}
						disabled={selected}
					>
						Use
					</Button>
					<Box display="flex">
						<IconButton
							size="small"
							className={classes.deleteIcon}
							onClick={() => handleDialog("delete")}
						>
							<DeleteRounded />
						</IconButton>
						<IconButton
							size="small"
							className={classes.editIcon}
							onClick={() => {
								handleDialog("edit");
							}}
						>
							<Edit />
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Box className={classes.chips}>
				{Object.keys(_.omit(editableFavorite.filter, hiddenFilterKeys))
					.length === 0 ? (
					<Typography variant="body1">No filters applied</Typography>
				) : (
					<Collapse
						in={showFilters || isDialogOpen.edit}
						style={{ width: "100%" }}
					>
						<Chips
							disablePortal
							filter={editableFavorite.filter}
							onClear={
								isDialogOpen.edit
									? (key, value) => {
											let newFilter: EventsFilter;
											if (
												value &&
												editableFavorite.filter
													?.moreFilters?.[key]
											) {
												newFilter = {
													...editableFavorite.filter,
													moreFilters: {
														...editableFavorite
															.filter
															?.moreFilters,
														[key]: {
															...editableFavorite
																.filter
																?.moreFilters?.[
																key
															],
															values: editableFavorite.filter?.moreFilters?.[
																key
															].values.filter(
																(v) =>
																	v !== value,
															),
														},
													},
												};
											} else {
												newFilter = {
													..._.omit(
														editableFavorite.filter,
														key,
													),
													moreFilters: _.omit(
														editableFavorite.filter
															?.moreFilters ?? {},
														key,
													),
												};
											}
											setEditableFavorite({
												...editableFavorite,
												filter: newFilter,
											});
									  }
									: undefined
							}
							chipStyles={{
								width: "48%",
							}}
						/>
					</Collapse>
				)}
			</Box>
			<Collapse in={isDialogOpen.edit}>
				<Box className={classes.content}>
					<TextField
						value={editableFavorite.name}
						variant="outlined"
						size="small"
						label="Name"
						error={Boolean(error)}
						helperText={error}
						onChange={(e) => {
							if (error) setError("");
							setEditableFavorite({
								...editableFavorite,
								name: e.target.value,
							});
						}}
					/>
					<Box display="flex" justifyContent="flex-end">
						<Button
							onClick={() => {
								if (isEqual(favorite, editableFavorite))
									return handleDialog("edit");
								editFavorite(
									editableFavorite,
									(err) => {
										if (err) setError(err);
										else handleDialog("edit");
									},
									editableFavorite.name === favorite.name
										? undefined
										: favorite.name,
								);
							}}
						>
							Save
						</Button>
						<Button
							onClick={() => {
								handleDialog("edit");
								setTimeout(
									() => setEditableFavorite(favorite),
									200,
								);
							}}
						>
							Cancel
						</Button>
					</Box>
				</Box>
			</Collapse>
			<Dialog
				open={isDialogOpen.delete}
				onClose={() => handleDialog("delete")}
			>
				<DialogTitle style={{ minWidth: 300 }}>
					Unfavor{" "}
					<span style={{ color: THEME.palette.text.secondary }}>
						{editableFavorite.name}
					</span>
					?
				</DialogTitle>
				<DialogActions>
					<Button
						onClick={() => {
							handleDialog("delete");
							deleteFavorite(favorite);
						}}
						style={{ color: THEME.palette.error.main }}
					>
						Delete
					</Button>
					<Button onClick={() => handleDialog("delete")}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default FavoriteCard;
