import Authentication from "../store/Authentication/AuthenticationStore";

const host = window.location.host;
const protocol = window.location.protocol;
const wsProtocol = protocol === "https:" ? "wss:" : "ws:";
const wsUrl = process.env.REACT_APP_WEBSOCKET_ADDRESS
	? process.env.REACT_APP_WEBSOCKET_ADDRESS + "/socket"
	: `${wsProtocol}//${host}/socket`;

export const vmsSocketUrl = `${wsUrl}/vms`;
export const taskSocketUrl = `${wsUrl}/task`;

export const handleOpen = (socket: WebSocket) => {
	if (socket.readyState === socket.OPEN && Authentication.isAuthenticated()) {
		socket.send(
			JSON.stringify({
				MessageType: "AuthMessage",
				Token: `Bearer ${Authentication.getAccessToken()}`,
			}),
		);
	}
};
