import { IVMS } from "../../store/VMS/types";
import { Box, Divider, Tooltip, Typography, debounce } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { useEffect, useMemo, useRef } from "react";
import { getSourceAction } from "../../store/Sources/action";
import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import clsx from "clsx";
import {
	Info,
	Error,
	PauseCircleFilled,
	PlayCircleFilled,
	Videocam,
} from "@material-ui/icons";
import { ISource } from "../../store/Sources/types";
import { setDraggingCameraAction } from "../../store/LiveCameras/action";

type Props = {
	vms: IVMS;
	searchValue: string;
};

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: THEME.palette.grey[200],
		borderRadius: 6,
	},
	titleWrapper: {
		display: "flex",
		alignItems: "center",
		gap: 6,
		padding: "2px 6px",
		backgroundColor: THEME.palette.secondary.main,
		color: THEME.palette.grey[300],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
	title: {
		fontWeight: 500,
	},
	source: {
		padding: "4px 4px",
		borderBottom: `2px solid transparent`,
		"&:hover": {
			cursor: "grab",
			backgroundColor: THEME.palette.grey[300],
		},
		userSelect: "none",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	borderBottom: {
		borderBottom: `2px solid ${THEME.palette.grey[300]}`,
	},
	divider: {
		backgroundColor: THEME.palette.grey[400],
	},
	tooltip: {
		display: "flex",
		"& svg": {
			fontSize: "1.3rem",
		},
	},
}));

const ListItem = ({ vms, searchValue }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const sources = useSelector(
		(state: AppState) => state.sources.keys[vms.name]?.content,
	);

	const filteredSources = useMemo(
		() =>
			sources?.filter((v) =>
				v.displayName
					.toLocaleLowerCase()
					.includes(searchValue.toLocaleLowerCase()),
			),
		[searchValue, sources],
	);

	const draggingCamera = useSelector(
		(state: AppState) => state.live.draggingCamera,
	);

	const handleDragStart = (
		_: React.DragEvent<HTMLElement>,
		source: ISource,
	) => {
		dispatch(
			setDraggingCameraAction({
				vmsName: vms.name,
				sourceName: source.displayName,
				sourceId: source.id,
				isLive: true,
			}),
		);
	};

	const fetchData = (value: string) => {
		dispatch(getSourceAction(vms.name, { name: value }));
	};

	const debouncedSearch = useRef(debounce(fetchData, 500));

	useEffect(() => {
		debouncedSearch.current(searchValue);
	}, [searchValue]);

	if ((!filteredSources || filteredSources.length === 0) && searchValue)
		return null;

	return (
		<Box className={classes.root}>
			<Box className={classes.titleWrapper}>
				<Videocam fontSize="small" />
				<Divider
					orientation="vertical"
					flexItem
					classes={{ root: classes.divider }}
				/>
				<Tooltip title={vms.name}>
					<Typography
						variant="subtitle1"
						noWrap
						className={classes.title}
					>
						{vms.name}
					</Typography>
				</Tooltip>
			</Box>
			{filteredSources && filteredSources.length > 0 ? (
				filteredSources.map((source, i) => {
					const status = source.tasks?.[0]?.status;
					return (
						<Box
							key={i}
							style={{
								pointerEvents: draggingCamera ? "none" : "auto",
							}}
							draggable
							onDragStart={(e) => handleDragStart(e, source)}
							className={clsx(
								classes.source,
								i !== filteredSources.length - 1 &&
									classes.borderBottom,
							)}
						>
							<Tooltip title={source.displayName}>
								<Typography variant="body2" noWrap>
									{source.displayName}
								</Typography>
							</Tooltip>
							<Tooltip
								title={`Camera status: ${
									status ? status : "UNKNOWN"
								}`}
							>
								<Box className={classes.tooltip}>
									{status === "RUNNING" ? (
										<PlayCircleFilled
											style={{
												color: THEME.palette.success
													.light,
											}}
										/>
									) : status === "STOPPED" ? (
										<PauseCircleFilled
											style={{
												color: THEME.palette.warning
													.light,
											}}
										/>
									) : status === "ERROR" ? (
										<Error
											style={{
												color: THEME.palette.error
													.light,
											}}
										/>
									) : (
										<Info
											style={{
												visibility: status
													? "visible"
													: "hidden",
												color: THEME.palette.info.main,
											}}
										/>
									)}
								</Box>
							</Tooltip>
						</Box>
					);
				})
			) : (
				<Typography
					variant="body1"
					color="textSecondary"
					align="center"
					style={{ fontWeight: "bold", padding: 4 }}
				>
					No sources found
				</Typography>
			)}
		</Box>
	);
};

export default ListItem;
