import Axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import {
	IgetSecretsRequest,
	ISecret,
	ICreateSecret,
	IUpdateSecret,
} from "./types";
import { IResponse, PagedContent } from "../types";

export const Secrets = "Secrets";
export type SecretsAction = AsyncAction<
	typeof Secrets,
	PagedContent<ISecret[]> | ISecret,
	string
>;

export const getSecret = async (secretId?: string) => {
	try {
		const response = await Axios.get<ISecret>(
			API_BASE_PREFIX + `secrets/${secretId}`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getSecrets = async (requestParams: IgetSecretsRequest) => {
	try {
		const response = await Axios.get(API_BASE_PREFIX + `secrets`, {
			params: requestParams,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createSecret = async (secret: ICreateSecret) => {
	try {
		const response = await Axios.post<IResponse>(
			API_BASE_PREFIX + "secrets",
			secret,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deleteSecret = async (secretId: string) => {
	try {
		const response = await Axios.delete<IResponse>(
			API_BASE_PREFIX + `secrets/${secretId}`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateSecret = async (secretId: string, secret: IUpdateSecret) => {
	try {
		const response = await Axios.put<IResponse>(
			API_BASE_PREFIX + `secrets/${secretId}`,
			secret,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const checkSecrets = async () => {
	try {
		const secrets = await getSecrets({
			page: undefined,
			size: undefined,
			search: undefined,
		});
		if (secrets.content.length > 0) {
			return true;
		}
		return false;
	} catch (e) {
		return true;
	}
};

export function getSecretsAction(requestParams: IgetSecretsRequest) {
	return async(Secrets, getSecrets, "", requestParams);
}

export function getSecretAction(secretId: string) {
	return async(Secrets, getSecret, secretId, secretId);
}
