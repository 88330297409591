import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import { Trigger } from "../../../../store/Rules/types";
import { ISource } from "../../../../store/Sources/types";
import { getWatchlistsAction } from "../../../../store/Watchlists/action";
import {
	IgetWatchlistsRequest,
	IWatchlist,
} from "../../../../store/Watchlists/types";
import { getSourceAction } from "../../../../store/Sources/action";
import MultiTrigger from "./Common/MultiTrigger";
import { Grid, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

interface ITriggersOwnProps {
	onTrigger(trigger: Trigger | undefined): void;
	triggers: Trigger | null;
	vmsName: string;
	secret?: string;
	isReadOnly: boolean;
	showOnly?: boolean;
}

interface ITriggersStateProps {
	watchlists: { [key: string]: IWatchlist };
	sources: ISource[];
}

interface ITriggersDispatchProps {
	loadWatchlists(request: IgetWatchlistsRequest): void;
	loadSources(vmsName: string): void;
}

interface ITriggersProps
	extends ITriggersOwnProps,
		ITriggersStateProps,
		ITriggersDispatchProps {}

const filterWatchlists = (
	watchlists: {
		[key: string]: IWatchlist;
	},
	givenSecret?: string,
): { [key: string]: IWatchlist } => {
	const newWatchlists = { ...watchlists };
	if (givenSecret) {
		for (const [key, value] of Object.entries(watchlists)) {
			if (value.secret && value.secret !== givenSecret) {
				delete newWatchlists[key];
			}
		}
	} else {
		for (const [key, value] of Object.entries(watchlists)) {
			if (value.secret) {
				delete newWatchlists[key];
			}
		}
	}
	return newWatchlists;
};

const _Triggers: React.FC<ITriggersProps> = ({
	onTrigger,
	triggers,
	vmsName,
	secret,
	watchlists,
	sources,
	loadWatchlists,
	loadSources,
	isReadOnly,
	showOnly,
}) => {
	React.useEffect(() => {
		loadWatchlists({
			page: 0,
			size: 100,
			secret: secret,
			find: undefined,
			type: undefined,
		});
		loadSources(vmsName);
	}, [loadWatchlists, loadSources, secret, vmsName]);

	const handleTriggerUpdate = (triggers: Trigger | undefined) => {
		onTrigger(triggers);
	};

	const filteredWatchlists = filterWatchlists(watchlists, secret);

	return (
		<Grid container>
			{!showOnly && (
				<Grid container item xs={12} justifyContent="flex-end">
					<Tooltip title="Triggers allow to select events based on specific criteria">
						<HelpIcon style={{ marginBottom: 10 }} />
					</Tooltip>
				</Grid>
			)}
			<Grid item xs={12}>
				<MultiTrigger
					watchlists={filteredWatchlists}
					sources={sources}
					trigger={triggers}
					onTriggers={handleTriggerUpdate}
					vmsName={vmsName}
					layer={0}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (
	store: AppState,
	ownProps: ITriggersOwnProps,
): ITriggersStateProps => {
	let sources: ISource[] = [];
	if (store.sources.keys[ownProps.vmsName])
		sources = store.sources.keys[ownProps.vmsName].content;
	return {
		watchlists: store.watchlists.keys,
		sources: sources,
	};
};

const mapDispatchToProps = (dispatch: any): ITriggersDispatchProps => {
	return {
		loadWatchlists: (request: IgetWatchlistsRequest) =>
			getWatchlistsAction(request)(dispatch),
		loadSources: (vmsName: string) => getSourceAction(vmsName)(dispatch),
	};
};

const Triggers = connect(mapStateToProps, mapDispatchToProps)(_Triggers);
export default Triggers;
