import { connect } from "react-redux";

import { AppState } from "../../store";
import { getReportsAction } from "../../store/Reports/action";
import {
	addConfigAction,
	getConfigAction,
	updateConfigAction,
} from "../../store/UserConfig/action";
import { IConfig } from "../../store/UserConfig/types";
import ReportsView, {
	IReportsViewPropsToDispatch,
	IReportsViewPropsToState,
} from "./ReportsView";

const mapStateToProps = (state: AppState): IReportsViewPropsToState => {
	return {
		reports: state.reports.data.content,
		userConfig: state.userConfig,
		status: state.reports.status,
	};
};

const mapDispatchToProps = (dispatch: any): IReportsViewPropsToDispatch => {
	return {
		loadReports: () => getReportsAction()(dispatch),
		loadUserConfig: () => getConfigAction()(dispatch),
		updateConfig: (data: IConfig, updateName?: boolean | undefined) =>
			updateConfigAction(data, updateName)(dispatch),
		addConfig: (data: IConfig) => addConfigAction(data)(dispatch),
	};
};

const ReportsContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReportsView);
export default ReportsContainer;
