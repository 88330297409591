import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	DialogActions,
	DialogContentText,
	CircularProgress,
} from "@material-ui/core";

interface IConfirmDialogProps {
	open: boolean;
	onClose?(): void;
	onConfirm?(): void;
	loading?: boolean;
	onLoading?(): void;
	children?: React.ReactNode;
	fullWidth?: boolean;
}

class ConfirmDialog extends React.Component<IConfirmDialogProps> {
	handleConfirm = () => {
		if (this.props.onLoading) this.props.onLoading();
		if (this.props.onConfirm) this.props.onConfirm();
	};

	render() {
		const { props } = this;
		return (
			<Dialog
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				open={props.open}
				fullWidth={props.fullWidth ?? true}
			>
				<DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.children}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{this.props.loading ? <CircularProgress /> : null}
					<Button
						onClick={this.handleConfirm}
						color="primary"
						autoFocus
						disabled={this.props.loading}
					>
						Yes
					</Button>
					<Button onClick={props.onClose} color="default">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConfirmDialog;
