import React from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import BounceTextField from "../../../UI/BounceTextField";
import UserList from "./UserList";
import SecretPermissions from "./SecretPermissions";
import { ISecretPermission } from "../../../../store/Secrets/types";
// import ListButtons from "./ListButtons"; if not needed delete file
import { ICurrentUser } from "../../../../store/Users/types";

const useStyles = makeStyles(() => ({
	listName: {
		fontWeight: "bold",
		padding: "0 16px",
		marginBottom: 10,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
export const filterSelected = (users: string[], perms: ISecretPermission[]) => {
	const nUsers = [...users];
	if (perms.length > 0) {
		perms.forEach((perm) => {
			const index = nUsers.findIndex(
				(userName) => userName === perm.user,
			);
			if (index !== -1) {
				nUsers.splice(index, 1);
			}
		});
	}
	return nUsers;
};

interface IPermissionManagementProps {
	onUserSearch(value: string): void;
	currentUser: ICurrentUser;
	perms: ISecretPermission[];
	setPerms(perms: ISecretPermission[]): void;
	isReadOnly: boolean;
	users: string[];
}

const PermissionManagement: React.FC<IPermissionManagementProps> = ({
	onUserSearch,
	currentUser,
	perms,
	setPerms,
	isReadOnly,
	users,
}) => {
	const classes = useStyles();
	const [availableUsers, setAvailableUsers] = React.useState<string[]>([]);

	React.useEffect(() => {
		setAvailableUsers(users);
	}, [users]);

	const handleSelect = (value: string) => {
		const currentPerms = [...perms];
		const currentUsers = filterSelected(availableUsers, perms);
		if (currentUsers.indexOf(value) !== -1) {
			currentPerms.push({
				user: value,
				manage: false,
				write: false,
			});
			setAvailableUsers(currentUsers.filter((user) => user !== value));
			setPerms(currentPerms);
		} else {
			setAvailableUsers([...currentUsers, value]);
			setPerms(currentPerms.filter((perm) => perm.user !== value));
		}
	};

	const onSelectAll = () => {
		const currentUsers = filterSelected(availableUsers, perms);
		const currentPerms = [...perms];
		currentUsers.forEach((name) => {
			currentPerms.push({
				user: name,
				manage: false,
				write: false,
			});
		});
		setAvailableUsers([]);
		setPerms(currentPerms);
	};

	const onDiselectAll = () => {
		const currentUsers = filterSelected(availableUsers, perms);
		const currentPerms = [...perms];
		for (let i = currentPerms.length - 1; i >= 0; i--) {
			currentUsers.unshift(currentPerms[i].user);
		}
		setPerms(perms.filter((user) => user.user === currentUser.userName));
		setAvailableUsers(currentUsers);
	};

	const handlePermissionClick = (
		index: number,
		permission: string,
		flag: boolean,
	) => {
		const newArr = [...perms];
		const updatePerm = newArr[index];
		switch (permission) {
			case "write": {
				updatePerm.write = !flag;
				break;
			}
			case "manage": {
				updatePerm.manage = !flag;
				break;
			}
		}
		newArr[index] = updatePerm;
		setPerms(newArr);
	};

	return (
		<Grid container style={{ marginTop: 20 }}>
			<Grid item xs={6} className={classes.listName}>
				Users
				<Button onClick={onSelectAll}>Select All</Button>
			</Grid>
			<Grid item xs={6} className={classes.listName}>
				Selected Users
				<Button onClick={onDiselectAll}>Diselect All</Button>
			</Grid>
			<Grid item xs={6} style={{ padding: "0 16px" }}>
				<BounceTextField
					style={{ width: "100%" }}
					onChange={onUserSearch}
					placeholder="Search"
				/>
			</Grid>
			<Grid container style={{ marginTop: 5 }}>
				<Grid
					item
					xs={6}
					style={{
						borderRight: "1px solid #DCDCE0",
					}}
				>
					<UserList
						list={filterSelected(availableUsers, perms)}
						handleToggle={handleSelect}
						isReadOnly={isReadOnly}
					/>
				</Grid>
				<Grid item xs={6}>
					<SecretPermissions
						currentUser={currentUser}
						perms={perms}
						handleToggle={handleSelect}
						handlePermissionClick={handlePermissionClick}
						isReadOnly={isReadOnly}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PermissionManagement;
