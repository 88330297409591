import {
	Box,
	Button,
	Paper,
	TableContainer,
	TablePagination,
} from "@material-ui/core";
import React from "react";
import {
	IUser,
	IUserRequest,
	UserSort,
	UsersPagedContent,
} from "../../store/Users/types";
import EnhancedTable, { IColumn, Order } from "../UI/EnhancedTable";
import UsersItem from "./UsersItem";

interface IUsersProps {
	onFilter(newFilter: IUserRequest): void;
	filter: IUserRequest;
	users: UsersPagedContent;
	onAddUser(): void;
	onEdit(user: IUser): void;
	handleEnableDisable(
		id: number,
		name: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	): void;
	onDisable(
		id: number,
		name: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	): void;
}

const Users: React.FC<IUsersProps> = ({
	onFilter,
	filter,
	users,
	onAddUser,
	onEdit,
	handleEnableDisable,
	onDisable,
}) => {
	const handleSortChange = (column: IColumn | null, order: Order) => {
		if (column === null) {
			return;
		}
		const sort = column.key as UserSort;
		const reverse = order === "desc";
		onFilter({
			...filter,
			sort,
			reverse,
		});
	};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		page: number,
	) => {
		onFilter({ ...filter, page });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<{
			value: unknown;
		}>,
	) => {
		onFilter({
			...filter,
			size: Number(event.target.value),
		});
	};

	return (
		<Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Button
					style={{
						marginTop: "5px",
					}}
					color="secondary"
					aria-label="add"
					onClick={onAddUser}
				>
					New user
				</Button>
			</Box>
			<Paper
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					overflowY: "auto",
				}}
			>
				<TableContainer style={{ flex: 1 }}>
					<EnhancedTable
						onChange={handleSortChange}
						columns={[
							{ key: "id", name: "#", sortable: true },
							{
								key: "userName",
								name: "Username",
								sortable: true,
							},
							{
								key: "email",
								name: "Email",
								sortable: true,
							},
							{
								key: "authorities",
								name: "Roles",
								sortable: false,
							},
							{
								key: "enabled",
								name: "State",
								sortable: true,
							},
							{
								key: "on/off",
								name: "On/Off",
								sortable: false,
							},
							{
								key: "actions",
								name: "",
								sortable: false,
							},
						]}
					>
						{users !== null && users.content.length > 0
							? users.content.map((user, i) => (
									<UsersItem
										key={i}
										user={user}
										onSelect={(user) => onEdit(user)}
										handleEnableDisable={
											handleEnableDisable
										}
										onDisable={onDisable}
									/>
							  ))
							: null}
					</EnhancedTable>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 25]}
					count={users !== null ? users.totalElements : 0}
					rowsPerPage={filter.size}
					page={filter.page}
					backIconButtonProps={{
						"aria-label": "previous page",
					}}
					nextIconButtonProps={{
						"aria-label": "next page",
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					component="div"
				/>
			</Paper>
		</Box>
	);
};

export default Users;
