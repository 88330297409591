import React, { useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";

interface IBounceTextField {
	onChange: (value: string) => void;
	bounceTime?: number;
	style?: React.CSSProperties;
	disabled?: boolean;
	placeholder?: string;
	value?: string;
	autofocus?: boolean;
}

const BounceTextField: React.FC<IBounceTextField> = (props) => {
	const [value, setValue] = React.useState<string>(props.value || "");
	const bounceTime = props.bounceTime ? props.bounceTime : 250;
	const handleDebounce = (nValue: string) => {
		props.onChange(nValue);
	};
	const [debounce] = React.useState(() =>
		_.debounce(handleDebounce, bounceTime),
	);
	useEffect(() => debounce(value), [value, debounce]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setValue(event.target.value);
	return (
		<TextField
			autoFocus={props.autofocus ?? false}
			onChange={handleChange}
			value={value}
			style={props.style}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			disabled={props.disabled}
			placeholder={props.placeholder ? props.placeholder : ""}
		/>
	);
};

export default BounceTextField;
