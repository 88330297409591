import ClientOauth2 from "client-oauth2";
import { authenticate, loadUser, createToken } from "./action";
import { AuthError, AuthenticationData } from "./types";
import { history } from "../../App";
import moment from "moment";
import TokenRefreshHook from "./TokenRefreshHook";
import { mqttClient } from "../../services/mqtt";

class AuthenticationClass {
	authentication: AuthenticationData | null;
	refreshHook: TokenRefreshHook | null;
	authDataKey = "ssAuthData";
	isTokenRefreshing = false;

	constructor() {
		this.authentication = this.loadAuthentication();
		this.refreshHook = this.loadRefreshHook();
	}

	saveAuthentication(authentication: AuthenticationData) {
		const serializedAuthentication = JSON.stringify(authentication);
		sessionStorage.setItem(this.authDataKey, serializedAuthentication);
	}

	loadRefreshHook() {
		if (
			this.authentication &&
			moment().isBefore(this.authentication.validUntil)
		) {
			const timeDiff =
				moment(this.authentication.validUntil).diff(moment()) / 1000;
			const token = createToken(
				this.authentication.accessToken,
				this.authentication.refreshToken,
				this.authentication.tokenType,
				timeDiff,
			);
			this.createRefreshHook(token);
		}
		return null;
	}

	loadAuthentication() {
		const unserializedAuthentication = sessionStorage.getItem(
			this.authDataKey,
		);
		if (unserializedAuthentication) {
			const authentication: AuthenticationData = JSON.parse(
				unserializedAuthentication,
			);
			return authentication;
		}
		return null;
	}

	clearAuthentication() {
		this.authentication = null;
		if (this.refreshHook) {
			this.refreshHook.destroy();
		}
		sessionStorage.removeItem(this.authDataKey);
		document.cookie.split(";")?.forEach(function (c) {
			document.cookie = c
				.replace(/^ +/, "")
				.replace(
					/=.*/,
					"=;expires=" + new Date().toUTCString() + ";path=/",
				);
		});
	}

	isAuthenticated() {
		return (
			this.authentication != null &&
			moment().isBefore(this.authentication.validUntil)
		);
	}

	isAuthority(role: string) {
		if (
			this &&
			this.authentication &&
			this.authentication.user &&
			this.authentication.user.authorities.indexOf(role) > -1
		) {
			return true;
		}
		return false;
	}

	getUserRoles() {
		if (this && this.authentication && this.authentication.user)
			return this.authentication.user.authorities;
		return [];
	}

	getUserName() {
		if (this.authentication && this.authentication.user) {
			return this.authentication.user.userName;
		} else {
			return null;
		}
	}

	getAccessToken() {
		if (this.authentication) {
			return this.authentication.accessToken;
		}
		return null;
	}

	getTokenType() {
		if (this.authentication) {
			return this.authentication.tokenType;
		}
		return null;
	}

	async createAuthentication(token: ClientOauth2.Token) {
		await this.createAuthenticationData(token);
		this.createRefreshHook(token);
	}

	async createAuthenticationData(token: ClientOauth2.Token) {
		const authenticationData: AuthenticationData = {
			accessToken: token.accessToken,
			tokenType: token.tokenType,
			refreshToken: token.refreshToken,
			validUntil: moment().add(token.data.expires_in, "s"),
		};
		this.authentication = authenticationData;
		const user = await loadUser();
		authenticationData.user = user;
		this.saveAuthentication(authenticationData);
		mqttClient.reconnectWithNewToken(authenticationData.accessToken);
		return { ...authenticationData };
	}

	createRefreshHook(token: ClientOauth2.Token) {
		this.refreshHook?.destroy();
		this.refreshHook = null;
		const refreshHook = new TokenRefreshHook();
		refreshHook.refreshToken(token).then((newToken) => {
			if (newToken) {
				this.createAuthentication(newToken);
			} else {
				history.push("/login");
			}
		});
		this.refreshHook = refreshHook;
	}

	async refreshToken() {
		try {
			if (this.authentication) {
				this.isTokenRefreshing = true;

				const token = createToken(
					this.authentication.accessToken,
					this.authentication.refreshToken,
					this.authentication.tokenType,
					moment(this.authentication.validUntil).diff(moment()) /
						1000,
				);
				const newToken = await token.refresh();
				await this.createAuthentication(newToken);
				return newToken;
			}
		} catch (err) {
			return;
		} finally {
			this.isTokenRefreshing = false;
		}
	}

	async authenticate(userName: string, password: string) {
		try {
			var token = await authenticate(userName, password);
			await this.createAuthentication(token);
		} catch (error) {
			if (error instanceof AuthError) {
				throw error;
			}
			throw new AuthError(
				(error as any).body.error_description ||
					(error as any).body.error,
			);
		}
	}
}

const Authentication = new AuthenticationClass();
export default Authentication;
