import { Box, Button, Snackbar, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageSearches from "../../components/ImageSearch/ImageSearches";
import Alert from "../../components/UI/Alert";
import { HttpError } from "../../config/types";
import {
	IGetImageSearchRequest,
	ImageSearch,
	ImageSearchContainer,
	ImageSearchRequest,
} from "../../store/ImageSearch/types";
import ImageSearchDialog from "../../components/Dialogs/ImageSearchDialog";
import { searchImage } from "../../store/ImageSearch/action";
import Authentication from "../../store/Authentication/AuthenticationStore";
import { handleOpen, taskSocketUrl } from "../../services/wsConnection";
import moment from "moment";
import { useHistory } from "react-router";
import useCanSeeSecrets from "../../hooks/useCanSeeSecrets";
import { RequestTaskStatus } from "../../store/BackwardSearch/types";

const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 8,
		padding: "10px 0",
	},
}));

export interface ImageSearchViewDispatchProps {
	loadImageSearches: (
		request: IGetImageSearchRequest,
		controller?: AbortController,
	) => Promise<void>;
	setImageSearchFilter: (filter: IGetImageSearchRequest) => void;
	updateSearchRequest: (data: ImageSearch) => Promise<void>;
}

export interface ImageSearchViewStateProps {
	imageSearches: ImageSearchContainer;
	filter: IGetImageSearchRequest;
	error?: HttpError;
}

type ImageSearchViewProps = ImageSearchViewDispatchProps &
	ImageSearchViewStateProps;

const ImageSearchView: React.FC<ImageSearchViewProps> = ({
	imageSearches,
	filter,
	error,
	loadImageSearches,
	setImageSearchFilter,
	updateSearchRequest,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const ws = useRef<WebSocket | null>(null);
	const [controller, setController] = useState<AbortController | null>(null);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState({
		loaded: 0,
		total: 0,
	});

	const canSeeSecrets = useCanSeeSecrets();

	const [draggedImages, setDraggedImages] = useState<string[]>([]);
	const [lastSearch, setLastSearch] = useState({
		id: "",
		time: moment(),
	});

	const isAuthenticated = Authentication.isAuthenticated();

	const getNewController = useCallback(() => {
		const abortController = new AbortController();
		return abortController;
	}, []);

	const loadSearchesInBg = useCallback(
		async (
			filter: IGetImageSearchRequest,
			abortController?: AbortController,
		) => {
			if (abortController)
				setController((prev) => {
					if (prev) prev.abort();
					return abortController;
				});
			return await loadImageSearches(filter, abortController).finally(
				() => {
					if (abortController?.signal.aborted) return;
					setLoading(false);
				},
			);
		},
		[loadImageSearches],
	);

	const loadSearches = useCallback(
		(filter: IGetImageSearchRequest, abortController?: AbortController) => {
			setLoading(true);
			loadSearchesInBg(filter, abortController).finally(() => {
				if (abortController?.signal.aborted) return;
				setLoading(false);
			});
		},
		[loadSearchesInBg],
	);

	const handleChangePage = (_: unknown, newPage: number) => {
		setImageSearchFilter({ ...filter, page: newPage });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (controller) controller.abort();
		setImageSearchFilter({
			...filter,
			page: 0,
			size: parseInt(event.target.value, 10),
		});
	};

	const handleCheckboxChange = () => {
		if (controller) controller.abort();
		setImageSearchFilter({
			...filter,
			page: 0,
			mineOnly: !filter.mineOnly,
		});
	};

	const handleSearchRequest = async (
		request: ImageSearchRequest & { images: string[] },
		secret?: string,
		multiple?: boolean,
	) => {
		try {
			setUploadProgress((prev) => ({
				...prev,
				total: prev.total + request.images.length,
			}));

			let isOpen = openDialog;
			const batchSize = 5;

			for (let i = 0; i < request.images.length; i += batchSize) {
				const batchImages = request.images.slice(i, i + batchSize);

				const searchPromises = batchImages.map((image) =>
					searchImage({ ...request, image }, secret).then((res) => {
						if (!multiple) {
							const time = moment(res.timestamp);
							updateSearchRequest({
								...res,
								...request,
								status: RequestTaskStatus.scheduled,
								thumb: image,
							});
							setLastSearch({
								id: res.correlationId ?? "",
								time,
							});
						}
					}),
				);

				setOpenDialog((prev) => {
					if (!prev) isOpen = false;
					return prev;
				});

				if (!isOpen) {
					setUploadProgress({ loaded: 0, total: 0 });
					break;
				}

				await Promise.all(searchPromises);

				setUploadProgress({
					loaded: i + batchImages.length,
					total: request.images.length,
				});
			}

			setUploadProgress({
				loaded: 0,
				total: 0,
			});
			handleCloseDialog();
		} catch (error) {
			handleCloseDialog();
		}
	};

	const handleCloseDialog = () => {
		setLoading(false);
		setOpenDialog(false);
	};

	const handleSocketMsg = useCallback(
		(event: MessageEvent) => {
			const data = JSON.parse(event?.data);

			if (Array.isArray(data) && data[0]?.action === "SubjectSearch") {
				const time = moment(data[0]?.timestamp).add(
					moment().utcOffset(),
					"minutes",
				);

				updateSearchRequest(data[0]);

				if (
					data[0]?.status === "Complete" ||
					data[0]?.status === "BadRequest"
				) {
					const isLoadingOtherSearches = imageSearches.content.some(
						(search) =>
							search.correlationId !== data[0]?.correlationId &&
							(search.status === RequestTaskStatus.scheduled ||
								search.status ===
									RequestTaskStatus.processing ||
								search.status ===
									RequestTaskStatus.preparingResults),
					);
					if (!isLoadingOtherSearches)
						loadSearchesInBg(filter, getNewController());

					if (
						data[0]?.correlationId === lastSearch.id &&
						moment(time).isBefore(lastSearch.time.add(5, "seconds"))
					) {
						history.push(
							`/watchlists/search/${data[0]?.correlationId}`,
						);
					}
				}
			}
		},
		[
			filter,
			updateSearchRequest,
			history,
			lastSearch,
			loadSearchesInBg,
			getNewController,
			imageSearches.content,
		],
	);

	const handleDrag = (e: React.DragEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setDraggedImages([]);
		setOpenDialog(true);

		const files = e.dataTransfer.files;
		if (files) {
			for (const image of files) {
				const reader = new FileReader();
				reader.onload = () => {
					const res = reader.result as string;
					setDraggedImages((prev) => [...prev, btoa(res)]);
				};
				reader.readAsBinaryString(image);
			}
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		loadSearches(filter, abortController);
	}, [filter, loadSearches]);

	useEffect(() => {
		if (!openDialog && draggedImages.length > 0) setDraggedImages([]);
	}, [openDialog, draggedImages]);

	useEffect(() => {
		if (Authentication.isAuthenticated()) {
			const socket = ws.current
				? ws.current
				: new WebSocket(taskSocketUrl);
			socket.onopen = () => handleOpen(socket);
			socket.onmessage = handleSocketMsg;
			ws.current = socket;
		}
	}, [isAuthenticated, handleSocketMsg]);

	useEffect(() => {
		return () => ws.current?.close();
	}, []);

	useEffect(() => {
		const state = history.location.state as
			| { [key: string]: any }
			| undefined;
		if (state && state.thumb) {
			setOpenDialog(true);
		}
	}, [history.location.state]);

	return (
		<>
			<Box
				onDragOver={handleDrag}
				onDragLeave={handleDrag}
				onDragEnd={handleDrag}
				onDragExit={handleDrag}
				onDrop={handleDrop}
				className={classes.container}
			>
				<Button onClick={() => setOpenDialog(true)}>New search</Button>
				<ImageSearches
					imageSearches={{
						...imageSearches,
						content: imageSearches.content.slice(0, filter.size),
					}}
					loading={loading}
					filter={filter}
					onCheckbox={handleCheckboxChange}
					onChangePage={handleChangePage}
					onChangeRows={handleChangeRowsPerPage}
					canSeeSecrets={canSeeSecrets}
					openDialog={() => setOpenDialog(true)}
					uploadProgress={uploadProgress}
				/>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<ImageSearchDialog
				open={openDialog}
				close={() => setOpenDialog(false)}
				onSubmit={handleSearchRequest}
				loading={loading}
				onLoading={() => setLoading(true)}
				canSeeSecrets={canSeeSecrets}
				draggedImages={draggedImages}
				uploadProgress={uploadProgress}
			/>
		</>
	);
};

export default ImageSearchView;
