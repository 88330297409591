import React from "react";
import { Trigger } from "../../../../store/Rules/types";
import DateTrigger from "./Common/DateTrigger";
import AgeTrigger from "./Faces/AgeTrigger";
import GenderTrigger from "./Faces/GenderTrigger";
import ThresholdTrigger from "./Faces/ThresholdTrigger";
import SourceTrigger from "./Common/SourceTrigger";
import TimeTrigger from "./Common/TimeTrigger";
import WatchlistTrigger from "./Common/WatchlistTrigger";
import VehicleHumanTrigger from "./VehicleHuman/VehicleHumanTrigger";
import { IWatchlist } from "../../../../store/Watchlists/types";
import { ISource } from "../../../../store/Sources/types";

interface ITriggerProps {
	trigger: Trigger;
	watchlists: { [key: string]: IWatchlist };
	sources: ISource[];
	onTriggers(triggers: Trigger | undefined): void;
	vmsName: string;
	isReadOnly: boolean;
	showOnly?: boolean;
}

const TriggerComponent: React.FC<ITriggerProps> = ({
	trigger,
	watchlists,
	sources,
	onTriggers,
	vmsName,
	isReadOnly,
	showOnly,
}) => {
	const handleTriggerUpdate = (triggerino?: Trigger) => {
		onTriggers(triggerino);
	};

	switch (trigger.type) {
		case "DateTrigger":
			return (
				<DateTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "AgeTrigger":
			return (
				<AgeTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "GenderTrigger":
			return (
				<GenderTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "SourceTrigger":
			return (
				<SourceTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					vmsName={vmsName}
					sources={sources}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "FacesWatchlistTrigger":
			return (
				<WatchlistTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					watchlists={watchlists}
					isReadOnly={isReadOnly}
					option="Faces"
					showOnly={showOnly}
				/>
			);
		case "LicensePlateWatchlistTrigger":
			return (
				<WatchlistTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					watchlists={watchlists}
					isReadOnly={isReadOnly}
					option="License Plates"
					showOnly={showOnly}
				/>
			);
		case "TimeTrigger":
			return (
				<TimeTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "ThresholdTrigger":
			return (
				<ThresholdTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		case "DirectionTrigger":
		case "TypeTrigger":
		case "ColorTrigger":
			return (
				<VehicleHumanTrigger
					trigger={trigger}
					updateTrigger={handleTriggerUpdate}
					isReadOnly={isReadOnly}
					showOnly={showOnly}
				/>
			);
		default:
			return null;
	}
};

export default TriggerComponent;
