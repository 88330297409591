import { IWatchlistsState, IWatchlist } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { WatchlistsAction, Watchlists, Watchlist } from "./action";
import { PagedContent } from "../types";

export const initialWatchlistsState: IWatchlistsState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	requestStatus: {},
};

export const watchlistsReducer: Reducer<IWatchlistsState, any> = (
	state = initialWatchlistsState,
	action: WatchlistsAction,
) => {
	if (action.type === Watchlists) {
		switch (action.status) {
			case AsyncActionStatus.SUCCEEDED:
				const nState = { ...state };
				if (action.meta) {
					nState.keys[action.meta] = action.payload as IWatchlist;
				} else {
					const payload = action.payload as PagedContent<
						IWatchlist[]
					>;
					payload.content.forEach((watchlist) => {
						nState.keys[watchlist.id] = watchlist;
					});
				}
				return nState;
		}
	}
	if (action.type === Watchlist) {
		switch (action.status) {
			case AsyncActionStatus.SUCCEEDED:
				const nState = { ...state };
				if (action.meta) {
					nState.keys[action.meta] = action.payload as IWatchlist;
				} else {
					const payload = action.payload as IWatchlist;
					nState.keys[payload.id] = payload;
				}
				return nState;
		}
	}
	return state;
};
