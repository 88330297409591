import { ReactComponent as Bike } from "../components/Events/assets/bike.svg";
import { ReactComponent as Car } from "../components/Events/assets/sedan-car.svg";
import { ReactComponent as Bus } from "../components/Events/assets/bus-symbol.svg";
import { ReactComponent as Truck } from "../components/Events/assets/logistic-truck.svg";
import { ReactComponent as Person } from "../components/Events/assets/man-woman.svg";
import { ReactComponent as East } from "../components/Events/assets/east.svg";
import { ReactComponent as North } from "../components/Events/assets/north.svg";
import { ReactComponent as West } from "../components/Events/assets/west.svg";
import { ReactComponent as South } from "../components/Events/assets/south.svg";
import { ReactComponent as NorthEast } from "../components/Events/assets/northEast.svg";
import { ReactComponent as NorthWest } from "../components/Events/assets/northWest.svg";
import { ReactComponent as SouthEast } from "../components/Events/assets/southEast.svg";
import { ReactComponent as SouthWest } from "../components/Events/assets/southWest.svg";
import { ReactComponent as Beard } from "../assets/Svgs/beard.svg";
import { ReactComponent as DarkGlasses } from "../assets/Svgs/darkGlasses.svg";
import { ReactComponent as Glasses } from "../assets/Svgs/glasses.svg";
import { ReactComponent as Hat } from "../assets/Svgs/hat.svg";
import { ReactComponent as LicensePlate } from "../assets/Svgs/licensePlate.svg";
import { ReactComponent as Mask } from "../assets/Svgs/mask.svg";
import { ReactComponent as Mustache } from "../assets/Svgs/mustache.svg";

const svgToTsx = (objKey: string, className: string, style?: any) => {
	const ICONS = {
		NORTH: <North className={className} style={style} />,
		SOUTH: <South className={className} style={style} />,
		EAST: <East className={className} style={style} />,
		WEST: <West className={className} style={style} />,
		NORTHEAST: <NorthEast className={className} style={style} />,
		NORTHWEST: <NorthWest className={className} style={style} />,
		SOUTHEAST: <SouthEast className={className} style={style} />,
		SOUTHWEST: <SouthWest className={className} style={style} />,
		CAR: <Car className={className} style={style} />,
		BUS: <Bus className={className} style={style} />,
		TRUCK: <Truck className={className} style={style} />,
		BIKE: <Bike className={className} style={style} />,
		PERSON: <Person className={className} style={style} />,
		BEARD: <Beard className={className} style={style} />,
		DARKGLASSES: <DarkGlasses className={className} style={style} />,
		GLASSES: <Glasses className={className} style={style} />,
		HAT: <Hat className={className} style={style} />,
		LICENSEPLATE: <LicensePlate className={className} style={style} />,
		MASK: <Mask className={className} style={style} />,
		MUSTACHE: <Mustache className={className} style={style} />,
	};

	if (objKey.toUpperCase() in ICONS) {
		return ICONS[objKey.toUpperCase() as keyof typeof ICONS];
	}
	return null;
};

export default svgToTsx;
