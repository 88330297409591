const DisplayAuthorities: Map<string, string> = new Map()
	.set("ROLE_ADMINISTRATOR", "Administrator")
	.set("ROLE_VMS_OPERATOR", "Vms Operator")
	.set("ROLE_RULE_OPERATOR", "Rule Operator")
	.set("ROLE_SECRETS_OPERATOR", "Secrets Operator")
	.set("ROLE_WATCHLIST_ADMINISTRATOR", "Watchlist Administrator")
	.set("ROLE_WATCHLIST_OPERATOR", "Watchlist Operator")
	.set("ROLE_ANALYST", "Analyst");

export default DisplayAuthorities;
