import React from "react";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { TasksStatus } from "../../store/VMS/types";
import { Tooltip, makeStyles, Theme, Grid } from "@material-ui/core";

import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

interface ITaskStatusStateProps {
	tasks: TasksStatus | undefined;
}

interface ITaskStatusOwnProps {
	name: string;
}

interface ITaskStatusDispatch {}

interface SourceTableProps
	extends ITaskStatusStateProps,
		ITaskStatusOwnProps,
		ITaskStatusDispatch {}

const useStyles = makeStyles((theme: Theme) => ({
	task: {
		fontSize: "16px",
		margin: "0px 5px",
		"& span": {
			marginTop: "2px",
		},
	},
	taskContainer: {
		display: "flex",
		alignItems: "center",
		cursor: "default",
	},
	running: {
		color: theme.palette.success.light,
	},
	error: {
		color: theme.palette.error.light,
	},
	info: {
		color: theme.palette.primary.light,
	},
	warning: {
		color: theme.palette.warning.light,
	},
}));

const _TaskStatus: React.FC<SourceTableProps> = ({ tasks }) => {
	const statuses = React.useMemo(() => {
		if (!tasks) {
			return {
				running: 0,
				stopped: 0,
				error: 0,
				other: 0,
			};
		}

		return {
			running: tasks!.RUNNING || 0,
			stopped: tasks!.STOPPED || 0,
			error: tasks!.ERROR || 0,
			other:
				(tasks!.SCHEDULED || 0) +
				(tasks!.STARTING || 0) +
				(tasks!.WAITING || 0) +
				(tasks!.STOPPING || 0),
		};
	}, [tasks]);

	const classes = useStyles();

	if (tasks === undefined) {
		return null;
	}

	return (
		<Grid container justifyContent="flex-end">
			<Grid item>
				<Tooltip title="Running tasks">
					<div
						className={[
							classes.task,
							classes.taskContainer,
							classes.running,
						].join(" ")}
					>
						<PlayCircleFilledIcon />
						<span>{statuses.running}</span>
					</div>
				</Tooltip>
			</Grid>

			<Grid item>
				<Tooltip title="Stopped tasks">
					<div
						className={[
							classes.task,
							classes.taskContainer,
							classes.warning,
						].join(" ")}
					>
						<PauseCircleFilledIcon />
						<span>{statuses.stopped}</span>
					</div>
				</Tooltip>
			</Grid>

			<Grid item>
				<Tooltip title="Failed tasks">
					<div
						className={[
							classes.task,
							classes.taskContainer,
							classes.error,
						].join(" ")}
					>
						<ErrorIcon />
						<span>{statuses.error}</span>
					</div>
				</Tooltip>
			</Grid>

			<Grid item>
				<Tooltip
					title={
						<div style={{ width: "100px" }}>
							<p
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<span>Scheduled: </span>
								<span>{tasks ? tasks.SCHEDULED || 0 : 0}</span>
							</p>
							<p
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<span>Starting: </span>
								<span>{tasks ? tasks.STARTING || 0 : 0}</span>
							</p>
							<p
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<span>Waiting: </span>
								<span>{tasks ? tasks.WAITING || 0 : 0}</span>
							</p>
							<p
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<span>Stopping: </span>
								<span>{tasks ? tasks.STOPPING || 0 : 0}</span>
							</p>
						</div>
					}
				>
					<div
						className={[
							classes.task,
							classes.taskContainer,
							classes.info,
						].join(" ")}
					>
						<InfoIcon />
						<span>{statuses.other}</span>
					</div>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: ITaskStatusOwnProps,
): ITaskStatusStateProps => {
	return {
		tasks: state.vms.keys[ownProps.name].tasksStatus,
	};
};

const mapDispatchToProps = (): ITaskStatusDispatch => {
	return {};
};

const TaskStatus = connect(mapStateToProps, mapDispatchToProps)(_TaskStatus);

export default TaskStatus;
