import AsyncState from "../AsyncState";
import { IElasticHit } from "../Events/types";
import { IThumbAttributes } from "../Subjects/types";
import { PagedContent } from "../types";

interface IVmsSourcePair {
	vms: string;
	source: string;
}

export enum RequestTaskStatus {
	scheduled = "Scheduled",
	processing = "Processing",
	preparingResults = "PreparingResults",
	complete = "Complete",
	badRequest = "BadRequest",
	error = "Error",
	notFound = "NotFound",
	NotAuthorized = "NotAuthorized",
}

export interface BackwardSearchTask {
	correlationId?: string;
	action: "BackwardSearch";
	status: RequestTaskStatus;
	error?: string;
	timestamp: string;
	secret?: string;
	watchlist?: string;
	rule?: string;
	user?: string;
	message?: string;
	subject?: string;
	sourceId?: string;
	vms?: string;
	gorup?: string;
}

export interface IBackwardSearchRequestResult {
	user?: string;
	id?: string;
	correlationId?: string;
	thumb?: string;
	requestTime?: string;
	timeFrom?: string;
	timeTo?: string;
	sources?: IVmsSourcePair[];
	error?: string;
	matchingThreshold?: number;
	propertyOverrides?: string;
	acceptLowQuality?: boolean;
	secret?: string;
	status: BackwardSearchStatus | RequestTaskStatus;
	subjectId?: string;
	subjectsFound?: number;
	resultCount?: number;
}

export enum BackwardSearchStatus {
	"PreprocessingFailed",
	"Searching",
	"SearchCompleted",
	"SearchFailed",
}

export interface IGetBackwardSearchResultsRequest {
	page?: number;
	size?: number;
	mine: boolean;
}

export type BackwardSearchContainer = PagedContent<
	IBackwardSearchRequestResult[]
>;

export interface BackwardSearchState extends AsyncState {
	data: BackwardSearchContainer;
	keys: { [key: string]: BackwardSearchContainer };
	results: IBackwardSearchResult[];
	paging: IGetBackwardSearchResultsRequest;
	scrolledY: number;
}

export interface BackwardSearchRequest {
	fromTime?: string;
	toTime?: string;
	templates?: string[];
	image?: string;
	acceptLowQuality?: boolean;
	propertyOverrides?: string;
	secret?: string;
	vmsSourcePair?: IVmsSourcePair[];
	matchingThreshold: number;
	subjectId?: string;
}

export interface IBackwardSearchMatchModel {
	subjectId?: string;
	score: number;
	vms?: string;
	source?: string;
	timestamp: string;
}

export interface IBackwardFilter {
	orderByScore?: boolean;
	lowThreshold?: number;
	highThreshold?: number;
	sources?: string[];
	fromDate?: string;
	toDate?: string;
}

export interface IBackwardSearchResultRequest extends IBackwardFilter {
	id: string;
}

export interface IBackwardThumb {
	index: number;
	thumb?: string;
	thumbData: IThumbAttributes;
	hitCount?: number;
	matches?: PagedContent<IBackwardSearchMatchModel[]>;
}

export interface IHitsResponse
	extends PagedContent<IBackwardSearchMatchModel[]> {
	id: string;
	index: number;
	resetHits: boolean;
}

export interface IBackwardSocketMessage {
	action: string;
	correlationId: string;
	message?: string;
	status: RequestTaskStatus;
	timestamp: string;
	user: string;
	thumb?: IBackwardSearchResult["thumb"];
	sources?: IBackwardSearchResult["sources"];
	timeFrom?: string;
	timeTo?: string;
}

export interface IBackwardSearchResult {
	id?: string;
	user?: string;
	thumb?: string;
	subjects?: IBackwardThumb[];
	requestTime?: string;
	timeFrom?: string;
	timeTo?: string;
	sources?: IVmsSourcePair[];
	error?: string;
	matchingThreshold?: number;
	propertyOverrides?: string;
	acceptLowQuality: boolean;
	secret?: string;
	status: BackwardSearchStatus;
	searchImage?: IBackwardSearchResultImage;
	filter?: IBackwardFilter;
	events?: IElasticHit[];
	selectedIndex?: number;
	scrolledY?: number;
}

export interface IBackwardSearchResultImage {
	image?: string;
	imageAttributes: IThumbAttributes[];
}
