import {
	Popover,
	PopoverProps,
	Box,
	Typography,
	Button,
	Collapse,
	Checkbox,
	FormControlLabel,
	makeStyles,
	Slider,
} from "@material-ui/core";
import { FILTER_BUTTON_ID } from "../../containers/BackwardSearchResult/BackwardSearchResultView";
import {
	IBackwardFilter,
	IBackwardSearchResult,
} from "../../store/BackwardSearch/types";
import moment from "moment";
import { LogsTimeFilter } from "../Logs/LogsTimeFilter";
import { useEffect, useMemo, useState } from "react";
import { ISource } from "../../store/Sources/types";
import SourcesFormSelect from "../UI/SourcesFormSelect";
import _ from "lodash";
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { updateConfigAction } from "../../store/UserConfig/action";
import { IPreference } from "../../store/UserConfig/types";
import { MATCHING_THRESHOLD_LIMITS } from "../Forms/BackwardSearchForm";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		padding: 8,
		flex: 1,
	},
	paper: {
		width: "100%",
		maxWidth: 400,
		minHeight: "min(90%, 600px)",
		maxHeight: "90%",
		marginLeft: 16,
		display: "flex",
		flexDirection: "column",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		flex: 1,
		height: "100%",
		padding: "0 8px",
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		gap: 4,
	},
	label: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	dateLabel: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
		fontSize: "0.9rem",
	},
	dateBox: {
		display: "flex",
		gap: "24px",
	},
	input: {
		marginTop: 8,
	},
	slider: {
		width: "95%",
		margin: "0 auto",
		marginTop: 40,
	},
	thresholds: {
		display: "flex",
		justifyContent: "space-evenly",
	},
	threshold: {
		display: "flex",
		minWidth: "8ch",
		flexDirection: "column",
		fontSize: "0.9rem",
		fontWeight: "bold",
		textAlign: "center",
		color: theme.palette.secondary.dark,
	},
}));

interface IBackwardFilterPopoverProps {
	results: IBackwardSearchResult;
	filter: IBackwardFilter | undefined;
	onFilter: (filter: IBackwardFilter) => void;
	onClose: () => void;
	anchorEl: PopoverProps["anchorEl"];
	allSources: ISource[];
}

const BackwardFilterPopover = ({
	results,
	filter,
	onFilter,
	onClose,
	anchorEl,
	allSources,
}: IBackwardFilterPopoverProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const preferences = useSelector(
		(state: AppState) => state.userConfig.data.preference,
	);
	const f = results.filter;

	const [sources, setSources] = useState<Partial<ISource>[]>(
		results.sources
			?.filter((s) => s.vms && s.source)
			.map((source) => ({
				id: source.source,
				vmsName: source.vms,
			})) || [],
	);

	const [thresholds, setThresholds] = useState([
		f?.lowThreshold ??
			Math.max(
				MATCHING_THRESHOLD_LIMITS.min,
				Math.min(
					results.matchingThreshold ?? 48,
					MATCHING_THRESHOLD_LIMITS.max,
				),
			),
		f?.highThreshold ?? MATCHING_THRESHOLD_LIMITS.max,
	]);
	const [localThresholds, setLocalThresholds] = useState([
		f?.lowThreshold ??
			Math.max(
				MATCHING_THRESHOLD_LIMITS.min,
				Math.min(
					results.matchingThreshold ?? 48,
					MATCHING_THRESHOLD_LIMITS.max,
				),
			),
		f?.highThreshold ?? MATCHING_THRESHOLD_LIMITS.max,
	]);
	const [useTimeConstraints, setUseTimeConstraints] = useState(
		f?.fromDate || f?.toDate || results.timeFrom || results.timeTo
			? true
			: false,
	);

	const [relativeFrom, setRelativeFrom] = useState({
		value: 1,
		text: "month",
	});
	const [relativeUntil, setRelativeUntil] = useState({
		value: 0,
		text: "second",
	});

	const [showAbsoluteStart, setShowAbsoluteStart] = useState(
		f?.fromDate || results.timeFrom ? 1 : 0,
	);
	const [showAbsoluteEnd, setShowAbsoluteEnd] = useState(
		f?.toDate || results.timeTo ? 1 : 0,
	);

	const [from, setFrom] = useState(
		moment(f?.fromDate ?? results.timeFrom ?? undefined).toISOString(),
	);
	const [until, setUntil] = useState(
		moment(f?.toDate ?? results.timeTo ?? undefined).toISOString(),
	);

	const formData: IBackwardFilter = useMemo(() => {
		const selectedSources = sources.map((source) =>
			allSources.find(
				(s) => s.id === source.id && s.vmsName === source.vmsName,
			),
		);
		const sourceIds: IBackwardFilter["sources"] = [];
		selectedSources.forEach((source) => {
			if (source?.id) sourceIds.push(source.id);
		});

		const fromDate = showAbsoluteStart
			? moment(from)
			: moment().subtract(
					relativeFrom.value,
					relativeFrom.text as moment.unitOfTime.DurationConstructor,
			  );
		const toDate = showAbsoluteEnd
			? moment(until)
			: moment().subtract(
					relativeUntil.value,
					relativeUntil.text as moment.unitOfTime.DurationConstructor,
			  );
		return {
			sources: sourceIds.length ? sourceIds : undefined,
			fromDate: useTimeConstraints ? fromDate.toISOString() : undefined,
			toDate: useTimeConstraints ? toDate.toISOString() : undefined,
			lowThreshold: thresholds[0],
			highThreshold: thresholds[1],
			orderByScore: preferences.backwardSortByScore ?? false,
		};
	}, [
		sources,
		thresholds,
		useTimeConstraints,
		relativeFrom,
		relativeUntil,
		showAbsoluteStart,
		showAbsoluteEnd,
		from,
		until,
		allSources,
		preferences.backwardSortByScore,
	]);

	const handleClose = () => {
		onClose();
	};

	const handleReset = () => {
		setSources(
			results.sources?.map((source) => ({
				id: source.source,
				vmsName: source.vms,
			})) || [],
		);
		const oldThresholds = [
			Math.max(
				MATCHING_THRESHOLD_LIMITS.min,
				Math.min(
					results.matchingThreshold ?? 48,
					MATCHING_THRESHOLD_LIMITS.max,
				),
			),
			MATCHING_THRESHOLD_LIMITS.max,
		];

		setThresholds(oldThresholds);
		setLocalThresholds(oldThresholds);
		setUseTimeConstraints(
			results.timeFrom || results.timeTo ? true : false,
		);
		setRelativeFrom({ value: 1, text: "month" });
		setRelativeUntil({ value: 0, text: "second" });
		setShowAbsoluteStart(results.timeFrom ? 1 : 0);
		setShowAbsoluteEnd(results.timeTo ? 1 : 0);
		setFrom(moment(results.timeFrom ?? undefined).toISOString());
		setUntil(moment(results.timeTo ?? undefined).toISOString());
	};

	useEffect(() => {
		const newFilter = _.omit(formData, "orderByScore");
		if (!_.isEqual(newFilter, filter)) {
			onFilter(newFilter);
		}
	}, [formData, filter, onFilter]);
	return (
		<>
			<Popover
				id={FILTER_BUTTON_ID}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => handleClose()}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				classes={{ paper: classes.paper }}
			>
				<Box className={classes.root}>
					<Typography variant="h6">Filter</Typography>
					<Box className={classes.form}>
						<FormControlLabel
							control={
								<Checkbox
									name="useTimeConstraints"
									color="primary"
									checked={useTimeConstraints}
									onChange={() =>
										setUseTimeConstraints((prev) => !prev)
									}
								/>
							}
							label="Use time constraints"
						/>
						<Collapse in={useTimeConstraints}>
							<Box className={classes.dateBox}>
								<Typography className={classes.dateLabel}>
									From
								</Typography>
								<Typography className={classes.dateLabel}>
									To
								</Typography>
							</Box>
							<LogsTimeFilter
								from={moment(from)}
								until={moment(until)}
								relativeFrom={relativeFrom}
								relativeUntil={relativeUntil}
								showAbsoluteStart={showAbsoluteStart}
								showAbsoluteEnd={showAbsoluteEnd}
								onAbsoluteStartChange={(num) =>
									setShowAbsoluteStart(num)
								}
								onAbsoluteEndChange={(num) =>
									setShowAbsoluteEnd(num)
								}
								onFromChange={(date) =>
									setFrom(date.toISOString())
								}
								onUntilChange={(date) =>
									setUntil(date.toISOString())
								}
								onRelativeFromChange={(value, text) =>
									setRelativeFrom({ value, text })
								}
								onRelativeUntilChange={(value, text) =>
									setRelativeUntil({
										value,
										text,
									})
								}
								onFromUntilChange={(from, until) => {
									setFrom(from.toISOString());
									setUntil(until.toISOString());
								}}
							/>
						</Collapse>
						<SourcesFormSelect
							className={classes.input}
							value={sources}
							vmsName=""
							label="Sources"
							onSource={(newSources) =>
								setSources(newSources ?? [])
							}
							defaultToAll
						/>
						<Typography className={classes.input}>
							Thresholds
						</Typography>
						<Slider
							className={classes.slider}
							color="secondary"
							value={localThresholds}
							min={MATCHING_THRESHOLD_LIMITS.min}
							max={MATCHING_THRESHOLD_LIMITS.max}
							valueLabelDisplay="on"
							onChange={(_, newValue) => {
								if (Array.isArray(newValue))
									setLocalThresholds(newValue);
							}}
							onChangeCommitted={(_, newValue) => {
								if (Array.isArray(newValue))
									setThresholds(newValue);
							}}
						/>
						<Box className={classes.thresholds}>
							<Typography className={classes.threshold}>
								From: {thresholds[0]}
							</Typography>
							<Typography className={classes.threshold}>
								To: {thresholds[1]}
							</Typography>
						</Box>
						<FormControlLabel
							control={
								<Checkbox
									name="orderByScore"
									color="primary"
									checked={
										preferences.backwardSortByScore ?? false
									}
									onChange={(_, checked) => {
										const config: IPreference = {
											...preferences,
											backwardSortByScore: checked,
										};
										dispatch(
											updateConfigAction({
												variant: "preference",
												config,
											}),
										);
									}}
								/>
							}
							label="Order by score"
						/>
					</Box>
					<Box className={classes.actions}>
						<Button onClick={handleReset}>Reset</Button>
						<Button onClick={handleClose}>Close</Button>
					</Box>
				</Box>
			</Popover>
		</>
	);
};

export default BackwardFilterPopover;
