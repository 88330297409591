import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import LiveControls from "../Events/Stream/LiveControls";
import moment from "moment";
import { THEME } from "../../config";
import clsx from "clsx";
import ZoomOverlay, { TImagePos } from "./ZoomOverlay";
import { IVMS } from "../../store/VMS/types";
import { vmsConnectionIds } from "../../services/vmsConnectionIds";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { setVMSConnectionIsLostAction } from "../../store/VMS/action";
import VideoConnectionLost from "./VideoConnectionLost";
import useIsOnline from "../../hooks/useIsOnline";

type Props = {
	vms: IVMS;
	maximized: boolean;
	setMaximized: React.Dispatch<React.SetStateAction<boolean>>;
	sourceId?: string;
	isConfigLoading?: boolean;
	children?: React.ReactNode;
	loaderColor?: string;
	onPlaybackClick?: () => void;
	title?: string;
};

export const STREAM_PADDING = 16;

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%",
		maxHeight: "100%",
		margin: "0 auto",
		position: "relative",
		overflow: "hidden",
		flex: 1,
		width: "100%",
	},
	canvas: {
		maxWidth: "100%",
		flex: 1,
		objectFit: "contain",
	},
	canvasWrapper: {
		display: "flex",
		justifyContent: "center",
		maxHeight: "100%",
		overflow: "hidden",
		backgroundColor: THEME.palette.common.black,
		position: "relative",
	},
	loader: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
	},
	maximized: {
		zIndex: 2000,
		backgroundColor: "white",
		paddingTop: 0,
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "flex",
		borderRadius: 0,
		"& $title": {
			borderRadius: 0,
		},
	},
	title: {
		display: "flex",
		justifyContent: "center",
		background: THEME.palette.primary.dark,
		color: THEME.palette.primary.contrastText,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
	},
}));

const LiveStream = ({
	vms,
	sourceId,
	isConfigLoading,
	children,
	loaderColor,
	onPlaybackClick,
	maximized,
	setMaximized,
	title,
}: Props) => {
	const classes = useStyles();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const root = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const isOnline = useIsOnline();

	const sdkIsLoaded = useSelector(
		(state: AppState) => state.live.sdkIsLoaded,
	);
	const isConnectionLost = useSelector(
		(state: AppState) => state.vms.connections[vms.name],
	);
	const connectionWasLost = useRef(false);
	const [_, setConnectionTries] = useState(0);
	const [error, setError] = useState("");
	const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
	const [isFramesValid, setIsFramesValid] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const lastImage = useRef<HTMLImageElement | null>(null);
	const [dragPos, setDragPos] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [zoomLevel, setZoomLevel] = useState(1);
	const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
	const [timestamp, setTimestamp] = useState<number | null>(null);
	const [imagePos, setImagePos] = useState<
		| undefined
		| {
				top: number;
				left: number;
				right: number;
				bottom: number;
		  }
	>();

	const url = useMemo(() => {
		if (!vms) return undefined;
		if (vms.driver !== "MILESTONE" || !vms.ip) {
			setError("Video recording not available for this VMS");
			return undefined;
		}
		if (vms.useMobileServer === false) {
			setError(
				"Video recording is not available, because VMS is not configured to use Mobile Server",
			);
			return undefined;
		}
		setError("");
		const ssl = vms.mobileServerSSL ? "https" : "http";
		return `${ssl}://${vms.ip}:${vms.mobilServerPort}`;
	}, [vms]);

	const videoController = useRef<undefined | { [key: string]: any }>(
		undefined,
	);

	const drawError = useCallback(
		(err: string, textColor: string = "white") => {
			setIsLoading(false);
			const canvas = canvasRef.current;
			if (canvas) {
				const ctx = canvas.getContext("2d");
				if (ctx) {
					ctx.fillStyle = "black";
					ctx.fillRect(0, 0, canvas.width, canvas.height);

					ctx.clearRect(0, 0, canvas.width, canvas.height);
					const spaceFromSides = canvas.width * 0.03;
					const fontSize = canvas.width / 30;
					ctx.textAlign = "center";
					ctx.textBaseline = "middle";
					ctx.font = `${fontSize}px Arial`;
					ctx.fillStyle = textColor;
					if (
						ctx.measureText(err).width >
						canvas.width - spaceFromSides
					) {
						const words = err.split(" ");
						let line = "";
						for (let n = 0; n < words.length; n++) {
							const testLine = line + words[n] + " ";
							const metrics = ctx.measureText(testLine);
							const testWidth = metrics.width;
							if (
								testWidth > canvas.width - spaceFromSides &&
								n > 0
							) {
								ctx.fillText(
									line,
									canvas.width / 2,
									canvas.height / 2,
								);
								line = words[n] + " ";
							} else {
								line = testLine;
							}
						}
						ctx.fillText(
							line,
							canvas.width / 2,
							canvas.height / 2 + fontSize + 10,
						);
					} else {
						ctx.fillText(err, canvas.width / 2, canvas.height / 2);
					}
				}
			}
		},
		[],
	);
	const drawMinimap = useCallback(
		(image: ImageBitmap | HTMLImageElement, pos: TImagePos) => {
			const canvas = canvasRef?.current;
			const ctx = canvas?.getContext("2d");
			if (canvas && ctx && image && pos) {
				const isZoomed = pos.left !== 0 || pos.top !== 0;
				if (!isZoomed) return;
				const { width, height } = image;
				const aspectRatio = width / height;

				const minimapWidth = canvas.width / 8;
				const minimapHeight = minimapWidth / aspectRatio;
				const minimapX = canvas.width - minimapWidth;
				const minimapY = canvas.height - minimapHeight;
				ctx.drawImage(
					image,
					0,
					0,
					width,
					height,
					minimapX,
					minimapY,
					minimapWidth,
					minimapHeight,
				);
				ctx.strokeStyle = THEME.palette.success.main;
				ctx.lineWidth = 4;
				ctx.strokeRect(
					minimapX + (pos.left / width) * minimapWidth,
					minimapY + (pos.top / height) * minimapHeight,
					((pos.right - pos.left) / width) * minimapWidth,
					((pos.bottom - pos.top) / height) * minimapHeight,
				);
			}
		},
		[canvasRef],
	);

	const dragRect = useCallback(
		(
			image: ImageBitmap | HTMLImageElement,
			dragStart: { x: number; y: number },
			cursorPos: { x: number; y: number },
		) => {
			const ctx = canvasRef.current?.getContext("2d");
			if (!ctx || !image) return;
			ctx.strokeStyle = THEME.palette.success.light;
			ctx.lineWidth = 4;
			ctx.strokeRect(
				dragStart.x * ctx.canvas.width,
				dragStart.y * ctx.canvas.height,
				(cursorPos.x - dragStart.x) * ctx.canvas.width,
				(cursorPos.y - dragStart.y) * ctx.canvas.height,
			);
		},
		[],
	);

	const drawImage = useCallback(
		(image: HTMLImageElement | ImageBitmap) => {
			const canvas = canvasRef.current;
			if (canvas) {
				const ctx = canvas.getContext("2d");
				if (ctx) {
					if (canvasSize.width !== image.width) {
						setCanvasSize({
							width: image.width,
							height: image.height,
						});
					}
					imagePos
						? setImagePos((prev) => {
								if (prev) {
									ctx.drawImage(
										image,
										prev.left,
										prev.top,
										prev.right - prev.left,
										prev.bottom - prev.top,
										0,
										0,
										canvas.width,
										canvas.height,
									);
									drawMinimap(image, prev);
								}
								return prev;
						  })
						: ctx.drawImage(image, 0, 0);
					setDragging((prevDragging) => {
						if (prevDragging && zoomLevel === 1) {
							setCursorPos((prevPos) => {
								dragRect(image, dragPos, prevPos);
								return prevPos;
							});
						}
						return prevDragging;
					});
				}
			}
		},
		[canvasSize, imagePos, drawMinimap, dragPos, dragRect, zoomLevel],
	);

	const onFrameReceived = useCallback(
		async (frame: any) => {
			if (connectionWasLost.current) connectionWasLost.current = false;
			if (
				!frame.blob &&
				!frame.hasSizeInformation &&
				frame.frameNumber > 15 &&
				!isFramesValid
			) {
				return drawError("Video not available");
			}
			const src = URL.createObjectURL(frame.blob);
			if (canvasRef.current && frame.blob) {
				if (isLoading) setIsLoading(false);
				setTimestamp(moment(frame.timestamp).valueOf());
				drawImage(
					await new Promise<HTMLImageElement>((resolve) => {
						const img = new Image();
						img.onload = () => {
							lastImage.current = img;
							resolve(img);
						};
						img.src = src;
					}),
				);
			}
			if (!isFramesValid && frame.frameNumber > 0) setIsFramesValid(true);

			URL.revokeObjectURL(src);
			frame = null;
		},
		[drawImage, isLoading, drawError, isFramesValid],
	);

	const onConnection = useCallback(() => {
		if (!vms.name) return;

		vmsConnectionIds
			.getVmsConnectionId(vms.name)
			.then(({ id, isAlreadyConnected }) => {
				if (isAlreadyConnected) return;
				XPMobileSDK.connectWithId(url, id);
			})
			.catch(() =>
				setConnectionTries((prev) => {
					if (prev < 3) {
						setTimeout(() => onConnection(), 500);
						return prev + 1;
					} else {
						drawError("Failed to connect");
						return prev;
					}
				}),
			);
	}, [url, vms, drawError]);

	const onLogin = useCallback(() => {
		if (!localStorage.getItem(vms.name)) {
			drawError("Failed to connect");
			return;
		}

		if (connectionWasLost.current) {
			connectionWasLost.current = false;
			return;
		}

		const isFirefox =
			navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

		XPMobileSDK.RequestStream(
			{
				ConnectionId: localStorage.getItem(vms.name),
				CameraId: sourceId,
				DestWidth: 1200,
				DestHeight: 600,
				Fps: isFirefox ? 10 : 15,
				ComprLevel: 90,
				SignalType: "Live",
				MethodType: "Push",
			},
			(videoConnection: any) => {
				if (!videoConnection?.videoId) return;
				XPMobileSDK.playbackSpeed(videoConnection, 1);
				if (videoConnection?.observers?.length === 0) {
					videoConnection.addObserver({
						videoConnectionReceivedFrame: onFrameReceived,
					});
				}
				videoConnection.open();
				videoController.current = videoConnection;
			},
		);
	}, [onFrameReceived, vms, sourceId, drawError]);

	const connect = useCallback(() => {
		if (connectionWasLost.current) {
			setIsLoading(true);
			return onConnection();
		}
		XPMobileSDKSettings.MobileServerURL = url;
		XPMobileSDKSettings.supportsCHAP = false;
		XPMobileSDK.features = {
			SupportTimeBetweenFrames: false,
		};

		const observer = {
			connectionDidLogIn: () => {
				vmsConnectionIds.setIsVmsConnected(vms.name, true);
				onLogin();
			},
			connectionFailedToConnectWithId: () => {
				vmsConnectionIds.removeVms(vms.name);
				onConnection();
			},
			connectionFailedToConnect: () => {
				drawError("Camera is not responding");
			},
			connectionLostConnection: () => {
				vmsConnectionIds.setIsVmsConnected(vms.name, false, true);
				dispatch(setVMSConnectionIsLostAction(vms.name, true));
				setError("Connection lost");
			},
		};

		XPMobileSDK.addObserver(observer);
		onConnection();
	}, [url, onConnection, onLogin, vms, drawError, dispatch]);

	useEffect(() => {
		if (
			videoController.current &&
			videoController.current.observers[0]?.videoConnectionReceivedFrame
		) {
			videoController.current.observers[0].videoConnectionReceivedFrame =
				onFrameReceived;
		}
	}, [onFrameReceived]);

	useEffect(() => {
		if (error) drawError(error);
		if (!sdkIsLoaded || !url || error) return;
		if (vmsConnectionIds.isVmsConnected(vms.name)) onLogin();
		else connect();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdkIsLoaded, error, url]);

	useEffect(() => {
		if (connectionWasLost.current) setError("");
		if (isConnectionLost) connectionWasLost.current = true;

		return () => {
			if (
				videoController.current &&
				!connectionWasLost.current &&
				!isConnectionLost
			) {
				videoController.current.close();
			}
		};
	}, [isConnectionLost]);

	return (
		<div
			className={clsx(classes.root, maximized && classes.maximized)}
			ref={root}
		>
			{isConnectionLost || !isOnline ? (
				<VideoConnectionLost vmsName={vms.name} isOnline={isOnline} />
			) : (
				<>
					{title && (
						<Typography variant="h6" className={classes.title}>
							{title}
						</Typography>
					)}
					<Box className={classes.canvasWrapper}>
						<canvas
							width={
								canvasSize.width === 0
									? 1200
									: canvasSize.width ?? 1200
							}
							height={
								canvasSize.height === 0
									? 675
									: canvasSize.height ?? 675
							}
							className={classes.canvas}
							ref={canvasRef}
						/>
						<ZoomOverlay
							lastImage={lastImage.current}
							ref={canvasRef}
							setImagePos={setImagePos}
							imagePos={imagePos}
							drawMinimap={drawMinimap}
							dragRect={dragRect}
							dragPos={dragPos}
							setDragPos={setDragPos}
							cursorPos={cursorPos}
							setCursorPos={setCursorPos}
							dragging={dragging}
							setDragging={setDragging}
							zoomLevel={zoomLevel}
							setZoomLevel={setZoomLevel}
							setMaximized={setMaximized}
						/>
						{(isLoading || isConfigLoading) && (
							<Box className={classes.loader}>
								<CircularProgress
									style={{ color: loaderColor ?? "black" }}
								/>
							</Box>
						)}
					</Box>
					<LiveControls
						timestamp={timestamp}
						maximized={maximized}
						setMaximized={setMaximized}
						onPlaybackClick={onPlaybackClick}
					/>
					{children}
				</>
			)}
		</div>
	);
};

export default LiveStream;
