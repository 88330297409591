import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import "../../components/Events/resizableStyles.css";
import { Box, makeStyles } from "@material-ui/core";
import CamLayout from "../../components/LiveEvents/CamLayout";
import { THEME } from "../../config";
import clsx from "clsx";
import CamList from "../../components/LiveEvents/CamList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import CamTitle from "../../components/LiveEvents/CamTitle";
import {
	setDraggingCameraAction,
	setDraggingViewAction,
} from "../../store/LiveCameras/action";
import Controls, {
	isPredefinedLayout,
} from "../../components/LiveEvents/Controls";
import CenteredLoading from "../../components/UI/CenteredLoading";
import {
	addConfigLocallyAction,
	getConfigAction,
} from "../../store/UserConfig/action";
import LiveEventsList from "../../components/LiveEvents/LiveEventsList";
import {
	IElasticSource,
	IElasticSourceObjects,
	MatchProps,
} from "../../store/Events/types";
import { getItemAction } from "../../store/Subjects/action";
import useMQTT from "../../hooks/useMQTT";
import {
	EventsViewSide,
	IEventsViewConfig,
} from "../../store/UserConfig/types";
import { getDefaultEventView } from "../../store/UserConfig/reducer";
import usePointerPos from "../../hooks/usePointerPos";
import ListDropPlaceholder from "../../components/LiveEvents/ListDropPlaceholder";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 15,
		height: "100vh",
		padding: "10px 0 12px 0",
		overflow: "hidden",
	},
	cameras: {
		display: "flex",
		flexDirection: "column",
		flex: 5,
		gap: 4,
	},
	list: {
		flex: 1,
		minWidth: 240,
		maxWidth: 240,
	},
	hiddenList: {
		display: "none",
	},
	events: {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: 12,
		padding: 8,
		backgroundColor: THEME.palette.background.paper,
	},
}));

const SHOW_CAM_LIST_STORAGE_KEY = "SVC_SHOW_CAM_LIST";

const LiveEventsView: React.FC = () => {
	useMQTT();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showCamlist, setShowCamlist] = useState(
		localStorage.getItem(SHOW_CAM_LIST_STORAGE_KEY) === "false"
			? false
			: true,
	);
	const [backwardEvents, setBackwardEvents] = useState<
		(IElasticSource & IElasticSourceObjects)[]
	>([]);
	const { draggingCamera, draggingView } = useSelector(
		(state: AppState) => state.live,
	);
	const cameraTitleRef = useRef<HTMLDivElement>(null);

	const { getPointerPos } = usePointerPos(({ x, y }) => {
		if (!cameraTitleRef.current) return;
		cameraTitleRef.current.style.left = `${x - 36}px`;
		cameraTitleRef.current.style.top = `${y - 24}px`;
	});

	const userConfig = useSelector((state: AppState) => state.userConfig);
	const liveConfig = useSelector(
		(state: AppState) => state.userConfig.data.live,
	);
	const layouts = useSelector(
		(state: AppState) => state.userConfig.data.layouts,
	);
	const views = useSelector(
		(state: AppState) => state.userConfig.data.eventsViews,
	);

	const selectedLayout = useMemo(
		() => layouts.find((l) => l.id === liveConfig?.selectedLayoutId),
		[liveConfig?.selectedLayoutId, layouts],
	);

	const toggleCamlist = () => {
		setShowCamlist((prev) => {
			localStorage.setItem(SHOW_CAM_LIST_STORAGE_KEY, (!prev).toString());
			return !prev;
		});
	};

	const loadMatch = useCallback(
		async (match: MatchProps) =>
			getItemAction(
				match.watchlist,
				match.id,
				match.secret ?? "",
			)(dispatch),
		[dispatch],
	);

	useEffect(() => {
		const setDraggingItemsToNull = () => {
			if (Boolean(draggingCamera))
				dispatch(setDraggingCameraAction(null));
			if (Boolean(draggingView)) dispatch(setDraggingViewAction(null));
		};

		window.addEventListener("mouseup", setDraggingItemsToNull);
		window.document.body.style.overflow = "hidden";

		return () => {
			window.removeEventListener("mouseup", setDraggingItemsToNull);
			window.document.body.style.overflow = "auto";
		};
	}, [draggingCamera, draggingView, dispatch]);

	useEffect(() => {
		if (draggingCamera || draggingView)
			document.body.style.cursor = "grabbing";
		else document.body.style.cursor = "auto";
	}, [draggingCamera, draggingView]);

	useEffect(() => {
		if (!userConfig.loaded) dispatch(getConfigAction());
	}, [userConfig.loaded, dispatch]);

	useEffect(() => {
		layouts.forEach((layout) => {
			if (
				!layout.viewsOrder[EventsViewSide.LEFT].length &&
				!layout.viewsOrder[EventsViewSide.RIGHT].length &&
				isPredefinedLayout(layout.id)
			)
				dispatch(
					addConfigLocallyAction({
						variant: "eventsViews",
						config: getDefaultEventView(layout.id),
					}),
				);
		});
	}, [dispatch, layouts]);

	if (!userConfig.loaded) return <CenteredLoading height={100} />;

	const orderedViewIds = [
		...(selectedLayout?.viewsOrder[EventsViewSide.LEFT] ?? []),
		...(selectedLayout?.viewsOrder[EventsViewSide.RIGHT] ?? []),
	].filter((id) => views.find((v) => v.id === id));

	const draggingViewIdx = draggingView
		? orderedViewIds.indexOf(draggingView.id)
		: -1;

	const leftViews = (selectedLayout?.viewsOrder[EventsViewSide.LEFT]
		.map((id) => views.find((v) => v.id === id))
		?.filter(Boolean) ?? []) as IEventsViewConfig[];
	const rightViews = (selectedLayout?.viewsOrder[EventsViewSide.RIGHT]
		.map((id) => views.find((v) => v.id === id))
		?.filter(Boolean) ?? []) as IEventsViewConfig[];

	return (
		<Box className={classes.root}>
			{draggingView &&
				(draggingViewIdx !== 0 || leftViews.length === 0) && (
					<ListDropPlaceholder
						side={EventsViewSide.LEFT}
						insertAfterId={undefined}
					/>
				)}
			{selectedLayout &&
				leftViews.map((view, i) => {
					const nextView = leftViews[i + 1];
					const isDraggingNextView = !nextView
						? false
						: Boolean(draggingView) &&
						  nextView?.id === draggingView?.id;

					return (
						<React.Fragment key={view.id}>
							<LiveEventsList
								loadMatch={loadMatch}
								eventsView={view}
								viewsCount={
									leftViews.length + rightViews.length
								}
								side={EventsViewSide.LEFT}
								setBackwardEvents={setBackwardEvents}
							/>
							{draggingView &&
								!isDraggingNextView &&
								orderedViewIds.indexOf(view.id) !==
									draggingViewIdx && (
									<ListDropPlaceholder
										side={EventsViewSide.LEFT}
										insertAfterId={view.id}
									/>
								)}
						</React.Fragment>
					);
				})}

			<Box
				className={clsx(classes.cameras, classes.paper)}
				style={{ padding: "8px 2px 2px 2px" }}
			>
				<Controls
					config={liveConfig}
					isConfigLoading={!userConfig.loaded}
					layouts={layouts}
					handleShowCamlist={toggleCamlist}
					isCamlistHidden={!showCamlist}
				/>
				<CamLayout
					config={liveConfig}
					layouts={layouts}
					loadMatch={loadMatch}
					backwardEvents={backwardEvents}
				/>
			</Box>

			{selectedLayout &&
				rightViews.map((view, i) => {
					const prevView = rightViews[i - 1];
					const isDraggingPrevView = !prevView
						? false
						: Boolean(draggingView) &&
						  prevView?.id === draggingView?.id;

					return (
						<React.Fragment key={view.id}>
							{draggingView &&
								!isDraggingPrevView &&
								orderedViewIds.indexOf(view.id) !==
									draggingViewIdx && (
									<ListDropPlaceholder
										side={EventsViewSide.RIGHT}
										insertAfterId={rightViews[i - 1]?.id}
									/>
								)}
							<LiveEventsList
								loadMatch={loadMatch}
								eventsView={view}
								viewsCount={
									leftViews.length + rightViews.length
								}
								side={EventsViewSide.RIGHT}
								setBackwardEvents={setBackwardEvents}
							/>
						</React.Fragment>
					);
				})}
			{draggingView &&
				(draggingViewIdx !== orderedViewIds.length - 1 ||
					rightViews.length === 0) && (
					<ListDropPlaceholder
						side={EventsViewSide.RIGHT}
						insertAfterId={rightViews[rightViews.length - 1]?.id}
					/>
				)}
			<Box
				className={clsx(
					classes.list,
					classes.paper,
					!showCamlist && classes.hiddenList,
				)}
			>
				<CamList handleHide={toggleCamlist} layouts={layouts} />
			</Box>
			{draggingCamera && (
				<CamTitle
					ref={cameraTitleRef}
					style={{
						padding: "4px 8px",
						borderRadius: 6,
						position: "absolute",
						left: getPointerPos().x - 36,
						top: getPointerPos().y - 24,
						width: "fit-content",
						pointerEvents: "none",
						zIndex: 9999,
					}}
					title={
						draggingCamera.hotspotType
							? `${draggingCamera.hotspotType} hotspot`
							: `${draggingCamera.vmsName} - ${draggingCamera.sourceName}`
					}
				/>
			)}
		</Box>
	);
};

export default LiveEventsView;
