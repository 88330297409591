import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_ELASTICSEARCH_LOGS } from "../../config/axios";
import {
	ILogsContainer,
	IRequestLogs,
	logsFilters,
	IElasticLogs,
} from "./types";
import moment from "moment";

export type LOGS = "LOGS" | "LOGS_LOGOUT" | "LOGS_LOGIN" | "LOGS_SCROLL";
export type LogsAction = AsyncAction<
	LOGS,
	ILogsContainer | boolean | string,
	string
>;

function timeRangeQuery(timeRange: { from: moment.Moment; to: moment.Moment }) {
	return {
		range: {
			"@timestamp": {
				gte: timeRange.from.format(),
				lte: timeRange.to.format(),
			},
		},
	};
}

function mustNotQuery(
	filteredOutValues: {
		[key: string]: string;
	}[],
) {
	const mustNotMatchArr = [];
	for (let i = 0; i < filteredOutValues.length; i++) {
		const prop = Object.values(filteredOutValues[i])[0];
		const key =
			Object.keys(filteredOutValues[i])[0] === "Service"
				? logsFilters[0]
				: logsFilters[1];
		if (prop !== undefined) {
			mustNotMatchArr.push({
				match_phrase: { [key]: prop },
			});
		}
	}
	return mustNotMatchArr;
}

function mustQuery(filter: IRequestLogs) {
	const { message, service } = filter;
	const mustMatchArr = [];

	for (let i = 0; i < logsFilters.length; i++) {
		const prop = Object.values(filter)[i];
		const key = logsFilters[i];
		if (prop !== undefined) {
			if (key === "message") {
				if (message !== "") {
					mustMatchArr.push({
						query_string: {
							fields: ["message", "MESSAGE"],
							query: `*${message}*`,
						},
					});
				}
			} else if (
				key ===
				"kubernetes.annotations.sentiveillance_com/serviceName.keyword"
			) {
				if (service.length > 0) {
					const serviceArr = service.map((value) => {
						return {
							bool: {
								should: [{ match_phrase: { [key]: value } }],
								minimum_should_match: 1,
							},
						};
					});

					mustMatchArr.push({
						bool: {
							should: serviceArr,
							minimum_should_match: 1,
						},
					});
				}
			} else {
				mustMatchArr.push({
					bool: {
						should: [{ match_phrase: { [key]: prop } }],
						minimum_should_match: 1,
					},
				});
			}
		}
	}
	return mustMatchArr;
}

export const getLogs = async (
	from: number = 0,
	size: number = 100,
	filter?: IRequestLogs,
) => {
	const empty =
		filter?.service.length === 0 ||
		filter?.severity ||
		filter?.message ||
		filter?.not.length ||
		filter?.timeRange;

	try {
		const response = await Axios.get<ILogsContainer>(
			API_ELASTICSEARCH_LOGS + "_search",
			{
				params: empty
					? {
							source: JSON.stringify({
								from: from,
								size: size,
								query: {
									bool: {
										filter: [
											...mustQuery(filter),
											{
												bool: {
													must_not: mustNotQuery(
														filter?.not,
													),
												},
											},
											timeRangeQuery(filter?.timeRange),
										],
									},
								},
								sort: {
									"@timestamp": "desc",
								},
								highlight: {
									fields: {
										"*": {
											pre_tags: ["<mark>"],
											post_tags: ["</mark>"],
										},
									},
								},
							}),
							source_content_type: "application/json",
					  }
					: {
							source: JSON.stringify({
								from: from,
								size: size,
								sort: {
									"@timestamp": "desc",
								},
							}),
							source_content_type: "application/json",
					  },
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getMoreLogs = async (
	size: number,
	searchAfter: number[],
	filter?: IRequestLogs,
) => {
	const empty =
		filter?.service.length === 0 ||
		filter?.severity ||
		filter?.message ||
		filter?.not.length ||
		filter?.timeRange;
	try {
		const response = await Axios.get(API_ELASTICSEARCH_LOGS + "_search", {
			params: empty
				? {
						source: JSON.stringify({
							search_after: searchAfter,
							size: size,
							query: {
								bool: {
									filter: [
										...mustQuery(filter),
										{
											bool: {
												must_not: mustNotQuery(
													filter?.not,
												),
											},
										},
										timeRangeQuery(filter?.timeRange),
									],
								},
							},
							sort: {
								"@timestamp": "desc",
							},
							highlight: {
								fields: {
									"*": {
										pre_tags: ["<mark>"],
										post_tags: ["</mark>"],
									},
								},
							},
						}),
						source_content_type: "application/json",
				  }
				: {
						source: JSON.stringify({
							size: size,
							sort: {
								"@timestamp": "desc",
							},
							search_after: searchAfter,
						}),
						source_content_type: "application/json",
				  },
		});
		return response.data;
	} catch (err) {
		throw err;
	}
};

export const getLogsForDownload = async (
	size: number = 10000,
	// maxLength: number = 1000000,
	filter?: IRequestLogs,
) => {
	try {
		const logsArr: IElasticLogs[] = [];
		const firstLogs = await getLogs(0, size, filter);
		logsArr.push(firstLogs);
		if (firstLogs.hits.hits.length < size) return logsArr;
		let lastSort = firstLogs.hits.hits.at(-1)?.sort;
		while (lastSort) {
			const moreLogs = await getMoreLogs(size, lastSort, filter);
			logsArr.push(moreLogs);
			if (moreLogs.hits.hits.length < size) {
				break;
			}
			const newSort = moreLogs.hits.hits.at(-1)?.sort;
			if (lastSort === newSort) break;
			lastSort = newSort;
		}

		return logsArr;
	} catch (error) {
		throw error;
	}
};

export function getLogsAction(filter?: IRequestLogs) {
	return async("LOGS", getLogs, "", 0, 100, filter);
}

export function getLogsScrollAction(
	from: number,
	filter?: IRequestLogs,
	size?: number,
) {
	return async("LOGS_SCROLL", getLogs, "", from, size, filter);
}

export function logsLogin(token: string): LogsAction {
	return {
		payload: token,
		status: AsyncActionStatus.SUCCEEDED,
		type: "LOGS_LOGIN",
	};
}

export function logsLogout(): LogsAction {
	return {
		payload: false,
		status: AsyncActionStatus.SUCCEEDED,
		type: "LOGS_LOGOUT",
	};
}
