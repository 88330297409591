import Typography, { TypographyProps } from "@material-ui/core/Typography";

export const CLICKABLE_TYPOGRAPHY_COLOR = "#0047AB";

const ClickableTypography = ({
	style,
	children,
	...props
}: TypographyProps) => {
	return (
		<Typography
			style={{
				color: CLICKABLE_TYPOGRAPHY_COLOR,
				cursor: "pointer",
				userSelect: "none",
				width: "fit-content",
				...style,
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default ClickableTypography;
