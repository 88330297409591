import React, { useState } from "react";
import {
	Collapse,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	withStyles,
	CircularProgress,
} from "@material-ui/core";
import { ITask, actions, statuses } from "../../store/Tasks/types";
import { DATE_TIME_FORMAT, SYMBOL_LIMIT, THEME } from "../../config/index";
import moment from "moment";
import HistoryTask from "./HistoryTask";
import { limitDescription } from "./Filter/TaskFiltering";
import { updateSourceAction } from "../../store/Sources/action";
import { ISource, ISourceState } from "../../store/Sources/types";
import { ISecret } from "../../store/Secrets/types";
import { getSecretAction } from "../../store/Secrets/action";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { getHistoryAction } from "../../store/TaskHistory/action";
import { IDropdownGroup } from "../../store/types";

interface ITaskRowOwnProps {
	task: ITask;
}

interface ITaskRowStateProps {
	secret?: ISecret;
	source?: ISource;
	history: ITask[];
}

interface ITaskRowDispatchProps {
	loadSecret(secretId: string): void;
	loadSource(vms: string, sourceId: string): void;
	loadHistory(taskId: string): void;
}

export const limitSourceName = (displayName: string) => {
	if (displayName.length > SYMBOL_LIMIT) {
		return displayName.slice(0, SYMBOL_LIMIT) + "...";
	}
	return displayName;
};

export const createTooltip = (desc: string, id: string) => {
	return (
		<>
			<div>
				ID: {id}
				<br />
				Description: {limitDescription(desc)}
			</div>
		</>
	);
};

export const TaskTooltip = withStyles(() => ({
	tooltip: {
		maxWidth: 400,
	},
}))(Tooltip);

const addSpaceToAction = (noSpace: string, actionList: IDropdownGroup[]) => {
	let action = null;
	actionList.forEach((a) => {
		const actionName = a.items.find(
			(i) => i.name.replace(/\s/g, "") === noSpace,
		);
		if (actionName) action = actionName.name;
	});
	if (action) return action;
	return noSpace;
};

export const addSpaceToStatus = (noSpace: string, statusList: string[]) => {
	for (let i = 0; i < statusList.length - 1; i++) {
		if (noSpace === statusList[i].replace(/\s/g, "")) {
			return statusList[i];
		}
	}
	return noSpace;
};

interface ITaskRowProps
	extends ITaskRowOwnProps,
		ITaskRowStateProps,
		ITaskRowDispatchProps {}

const TaskRow: React.FC<ITaskRowProps> = ({
	task,
	secret,
	loadSecret,
	source,
	loadSource,
	history,
	loadHistory,
}) => {
	const [open, setOpen] = useState(false);

	const handleTaskClick = () => {
		if (open === false) {
			loadHistory(task.correlationId);
		}
		setOpen(!open);
	};

	React.useEffect(() => {
		if (task.secret) {
			loadSecret(task.secret);
		}
		if (task.sourceId) {
			loadSource(task.vms, task.sourceId);
		}
	}, [task, loadSecret, loadSource]);

	return (
		<>
			<TableRow
				onClick={handleTaskClick}
				hover
				role="checkbox"
				style={{ cursor: "pointer" }}
			>
				<TableCell>
					{moment(task.timestamp).format(DATE_TIME_FORMAT)}
				</TableCell>
				<TableCell>{addSpaceToAction(task.action, actions)}</TableCell>
				<TableCell>{addSpaceToStatus(task.status, statuses)}</TableCell>
				<TableCell>{task.message}</TableCell>
				<TableCell>{task.vms}</TableCell>
				{source ? (
					<TaskTooltip
						title={
							<>
								Name: {source.displayName}
								<br />
								ID: {source.id}
							</>
						}
					>
						<TableCell>
							{limitSourceName(source.displayName)}
						</TableCell>
					</TaskTooltip>
				) : (
					<TableCell />
				)}
				{secret ? (
					<TaskTooltip
						title={createTooltip(secret.description, secret.id)}
					>
						<TableCell>
							<Typography
								style={{
									color: THEME.palette.error.contrastText,
									backgroundColor: THEME.palette.error.dark,
									padding: "2px 6px",
									borderRadius: "3px",
									width: "fit-content",
									fontSize: "0.9rem",
								}}
							>
								{secret.name}
							</Typography>
						</TableCell>
					</TaskTooltip>
				) : (
					<TableCell />
				)}
				<TableCell>{task.watchlist}</TableCell>
				<TableCell>{task.subject}</TableCell>
				<TableCell>{task.rule}</TableCell>
				<TableCell>{task.user}</TableCell>
				<TableCell>{task.error}</TableCell>
			</TableRow>
			<TableRow
				onClick={handleTaskClick}
				style={{
					cursor: "pointer",
				}}
			>
				<TableCell
					style={{
						paddingBottom: 0,
						paddingTop: 0,
					}}
					colSpan={13}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						{history.length > 0 ? (
							<Box margin={1}>
								<Typography gutterBottom component="div">
									History
								</Typography>
								<Table size="small" aria-label="history table">
									<thead>
										<TableRow>
											<TableCell>Timestamp</TableCell>
											<TableCell>Status</TableCell>
											<TableCell>Message</TableCell>
											<TableCell>VMS</TableCell>
											<TableCell>Source</TableCell>
											<TableCell>Secret</TableCell>
											<TableCell>Watchlist</TableCell>
											<TableCell>Subject</TableCell>
											<TableCell>Rule</TableCell>
											<TableCell>User</TableCell>
											<TableCell>Error</TableCell>
										</TableRow>
									</thead>
									<TableBody>
										{history.map((historyTask, i) => (
											<HistoryTask
												task={historyTask}
												secret={secret}
												source={source}
												key={i}
											/>
										))}
									</TableBody>
								</Table>
							</Box>
						) : (
							<CircularProgress />
						)}
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const findSource = (sources: ISourceState, vms: string, sourceId: string) => {
	const source = sources.keys[vms].content;
	for (let i = 0; i < source.length; i++) {
		if (source[i].id === sourceId) {
			return i;
		}
	}
	return null;
};

const mapStateToProps = (
	state: AppState,
	ownProps: ITaskRowOwnProps,
): ITaskRowStateProps => {
	let stateSource = undefined;
	if (state.sources.keys[ownProps.task.vms]) {
		const index = findSource(
			state.sources,
			ownProps.task.vms,
			ownProps.task.sourceId,
		);
		if (index) {
			stateSource = state.sources.keys[ownProps.task.vms].content[index];
		}
	}
	const stateHistory = state.taskHistory.keys[ownProps.task.correlationId]
		? state.taskHistory.keys[ownProps.task.correlationId]
		: [];
	return {
		secret: state.secrets.keys[ownProps.task.secret],
		source: stateSource,
		history: stateHistory,
	};
};

const mapDispatchToProps = (dispatch: any): ITaskRowDispatchProps => {
	return {
		loadSecret: (secretId: string) => getSecretAction(secretId)(dispatch),
		loadSource: (vms: string, sourceId: string) =>
			updateSourceAction(vms, sourceId)(dispatch),
		loadHistory: (taskId: string) => getHistoryAction(taskId)(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskRow);
