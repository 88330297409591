import { ReactNode } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ILayoutConfig } from "../../store/UserConfig/types";

type Props = {
	layout: ILayoutConfig | undefined;
	items: ReactNode[] | undefined;
};

const useStyles = makeStyles(() => ({
	root: {
		display: "grid",
		gridTemplateColumns: "repeat(12, 1fr)",
		gridTemplateRows: "repeat(12, 1fr)",
		width: "100%",
		height: "100%",
	},
	itemWrapper: {
		display: "flex",
		cursor: "pointer",
		height: "100%",
		width: "100%",
	},
}));

const Layout = ({ layout, items }: Props) => {
	const classes = useStyles();

	if (!layout || !items) return null;

	return (
		<Box className={classes.root}>
			{items.map((item, index) => {
				const { x, y, width, height } = layout.areas[index];
				return (
					<Box
						key={index}
						className={classes.itemWrapper}
						style={{
							gridArea: `${y} / ${x} / span ${height} / span ${width}`,
						}}
					>
						{item}
					</Box>
				);
			})}
		</Box>
	);
};

export default Layout;
