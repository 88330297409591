import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { ReportsAction } from "./action";
import { IReportsState } from "./types";

export const initialReportsState: IReportsState = {
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	paging: {},
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleAsync = (
	state: IReportsState = initialReportsState,
	action: ReportsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			nState.data.content = action.payload;
			action.payload.forEach(report => {
				nState.keys[report.title] = report;
			});
			nState.status = AsyncActionStatus.SUCCEEDED;
			return nState;

		case AsyncActionStatus.FAILED:
			return {
				...state,
				status: AsyncActionStatus.FAILED,
			};
	}

	return state;
};

export const ReportsReducer: Reducer<IReportsState, ReportsAction> = (
	state = initialReportsState,
	action: ReportsAction,
) => {
	switch (action.type) {
		case "GET_REPORTS":
			return handleAsync(state, action);
		default:
			return state;
	}
};
