import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowDownward } from "@material-ui/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { THEME } from "../../config";
import {
	ImageSearchResult,
	ImageSearchState,
} from "../../store/ImageSearch/types";
import { IRequest } from "../../store/types";
import clsx from "clsx";
import { AppState } from "../../store";
import ResultInfo from "../../components/ImageSearch/ResultInfo";
import MatchBox from "../../components/ImageSearch/MatchBox";
import { IRecordsRequest } from "../../store/Records/types";
import Information from "../../components/ImageSearch/Information";
import ImagesDialog from "../../components/ImageSearch/ImagesDialog";
import SkeletonLoader from "../../components/UI/SkeletonLoader";
import { isNumber } from "lodash";

const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		gap: 8,
		padding: "10px 0",
		maxWidth: "100%",
		overflow: "hidden",
	},
	paper: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: "white",
		padding: "6px 12px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		gap: 4,
		width: "100%",
		overflowY: "auto",
	},
	empty: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
	},
	title: {
		color: THEME.palette.primary.main,
		margin: 0,
	},
	mainImg: {
		display: "block",
		height: "100%",
		maxWidth: "100%",
		objectFit: "contain",
		aspectRatio: "3 / 4",
	},
	img: {
		height: "fit-content",
		maxHeight: "100%",
		objectFit: "contain",
		position: "relative",
	},
	maxSize: {
		maxWidth: "50%",
		maxHeight: "100%",
	},
	shadow: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
	},
	mainImages: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
		gap: 12,
		minHeight: 115,
		flex: 1,
		height: "50%",
	},
	mainControls: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 50,
	},
	thumbs: {
		display: "flex",
		overflowX: "auto",
		overflowY: "hidden",
		flex: 1,
		gap: 4,
	},
	thumbsContainer: {
		minHeight: "120px",
		maxHeight: "10vh",
		display: "flex",
		flexDirection: "column",
		overflowY: "hidden",
	},
	info: {
		height: "140px",
		display: "flex",
		flexDirection: "column",
		gap: 12,
	},
	matches: {
		display: "flex",
		overflowX: "auto",
		height: "100%",
		gap: 8,
		padding: "2px 4px",
	},
	infoBtn: {
		textAlign: "left",
	},
	mainImgWrapper: {
		position: "relative",
		flex: 1,
		display: "flex",
		aspectRatio: "3 / 4",
		height: "max-content",
		maxHeight: "100%",
	},
	score: {
		position: "absolute",
		bottom: 0,
		left: 0,
		backgroundColor: THEME.palette.info.main,
		color: THEME.palette.common.white,
		padding: "2px 4px",
		fontSize: "1rem",
	},
	matchChip: {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.common.black,
		padding: "2px 4px",
		fontSize: "0.8rem",
		fontWeight: 600,
	},
	textBtn: {
		width: "fit-content",
	},
	notFound: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.grey[800],
		padding: 4,
		textAlign: "center",
	},
	zoomable: {
		cursor: "zoom-in",
	},
	thumb: {
		cursor: "pointer",
	},
	imgBorder: {
		border: `2px solid ${THEME.palette.secondary.main}`,
		borderRadius: 12,
		overflow: "hidden",
	},
	thumbWrapper: {
		border: `2px solid transparent`,
		borderRadius: 6,
		overflow: "hidden",
		minWidth: "fit-content",
	},
	thumbWrapperSelected: {
		border: `2px solid ${THEME.palette.secondary.main}`,
	},
	mainImgError: {
		aspectRatio: "3 / 4",
	},
}));

export interface SearchResultViewDispatchProps {
	getSearchResults: (
		id: string,
		query?: Pick<IRequest, "page" | "size">,
	) => Promise<void>;
	getSearchResultIndex: (
		id: string,
		index: number,
		query?: IRequest,
		controller?: AbortController,
		dontSave?: boolean,
	) => Promise<void>;
	getSecretAction: (id: string) => Promise<void>;
	getWatchlistAction: (id: string, secret: string) => Promise<void>;
	getSubjectAction: (
		watchlistId: string,
		name: string,
		secret?: string,
		controller?: AbortController,
	) => Promise<void>;
	getRecordsAction: (
		watchlistId: string,
		subjectId: string,
		request?: IRecordsRequest,
		controller?: AbortController,
	) => Promise<void>;
}

export interface SearchResultViewStateProps {
	data: ImageSearchResult[];
	resultsByIndex: ImageSearchState["resultsByIndex"];
	secrets: AppState["secrets"];
	watchlists: AppState["watchlists"];
	subjects: AppState["subjects"];
	recordsState: AppState["records"];
}

type SearchResultViewProps = SearchResultViewDispatchProps &
	SearchResultViewStateProps;

const SearchResultView: React.FC<SearchResultViewProps> = ({
	data,
	resultsByIndex,
	secrets,
	watchlists,
	subjects,
	recordsState,
	getSearchResults,
	getSearchResultIndex,
	getSecretAction,
	getWatchlistAction,
	getSubjectAction,
	getRecordsAction,
}) => {
	const classes = useStyles();
	const id = useParams<{ id: string }>().id;
	const history = useHistory();

	const [mainImgError, setMainImgError] = useState(false);
	const [, setThumbsLoaded] = useState(false);
	const [focusedContainer, setFocusedContainer] = useState<
		"thumbs" | "matches"
	>("matches");
	const [loadingRecords, setLoadingRecords] = useState(false);
	const [loadingResults, setLoadingResults] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState<Pick<IRequest, "page" | "size">>({
		page: 0,
		size: 20,
	});

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedResultIndex, setSelectedResultIndex] = useState(0);
	const [selectedThumbIndex, setSelectedThumbIndex] = useState(0);
	const [imgDialogOpen, setImgDialogOpen] = useState(false);
	const [controller, setController] = useState<AbortController | undefined>(
		undefined,
	);
	const [, setSubjectController] = useState<AbortController | undefined>(
		undefined,
	);
	const [, setResultController] = useState<AbortController | undefined>(
		undefined,
	);

	const matchImgRef = useRef<HTMLImageElement>(null);
	const matchesRef = useRef<HTMLDivElement>(null);
	const thumbsRef = useRef<HTMLDivElement>(null);

	const result = useMemo(() => {
		const r = data.find((d) => d.id === id);
		if (!r) return r;
		r.thumbs.sort((a, b) => a.index - b.index);
		return {
			...r,
			results: {
				...r.results,
				content: r.results.content.filter(
					(c) => c.index === selectedThumbIndex,
				),
			},
		};
	}, [data, id, selectedThumbIndex]);

	const matches = useMemo(() => {
		if (!id) return undefined;
		const selector = id + `index-${selectedThumbIndex}`;
		return resultsByIndex[selector] ?? undefined;
	}, [id, resultsByIndex, selectedThumbIndex]);

	const currentMatch = useMemo(
		() => matches?.content[selectedResultIndex],
		[matches, selectedResultIndex],
	);
	const secret = useMemo(
		() => secrets.keys[currentMatch?.secret ?? ""],
		[currentMatch, secrets],
	);
	const watchlist = useMemo(
		() => watchlists.keys[currentMatch?.watchlist ?? ""],
		[currentMatch, watchlists],
	);
	const subject = useMemo(() => {
		const subjectData = subjects.keys[
			currentMatch?.watchlist ?? ""
		]?.content.find((s) => s.name === currentMatch?.subject);
		if (subjectData) {
			return {
				...subjectData,
				metadata: JSON.parse(subjectData.metadata),
			};
		} else {
			return undefined;
		}
	}, [currentMatch, subjects]);

	const records = useMemo(
		() =>
			recordsState.keys[
				`${currentMatch?.subject ?? ""}-${
					currentMatch?.watchlist ?? ""
				}`
			]?.content,
		[currentMatch, recordsState],
	);

	const scrollToElement = (index: number, ref: React.RefObject<any>) => {
		const currentRef = ref.current;
		if (currentRef) {
			const matchElement = currentRef.children[index] as HTMLElement;
			if (matchElement) {
				matchElement.scrollIntoView({
					inline: "nearest",
				});
			}
		}
	};

	const loadResults = useCallback(async () => {
		setLoading(true);
		await getSearchResults(id, {
			page: 0,
			size: 1,
		});
		setLoading(false);
	}, [getSearchResults, id]);

	const onThumbClick = useCallback((index: number) => {
		setFilter((prev) => ({
			...prev,
			page: 0,
		}));
		setSelectedThumbIndex(index);
		setSelectedResultIndex(0);
		scrollToElement(index, thumbsRef);
	}, []);

	const handleHorizontalWheel = (e: React.WheelEvent<HTMLDivElement>) => {
		e.currentTarget.scrollLeft += e.deltaY;
	};

	const loadMoreMatches = useCallback(() => {
		if (matches && matches.totalPages !== (filter?.page ?? 0) + 1) {
			setFilter((prev) => ({ ...prev, page: (prev.page ?? 0) + 1 }));
		}
	}, [filter, matches]);

	useEffect(() => {
		loadResults();
	}, [loadResults]);

	useEffect(() => {
		setSelectedIndex(currentMatch?.matchedRecordIndex ?? 0);
		if (!records && currentMatch?.subject && currentMatch?.watchlist) {
			const abortController = new AbortController();
			setController(abortController);
			setLoadingRecords(true);
			getRecordsAction(
				currentMatch.watchlist,
				currentMatch.subject,
				{
					secret: currentMatch.secret,
				},
				abortController,
			).finally(() => {
				if (!abortController.signal.aborted) {
					setLoadingRecords(false);
				}
			});
		}
	}, [currentMatch, getRecordsAction, records]);

	useEffect(() => {
		if (!subject && currentMatch) {
			const abortController = new AbortController();
			setSubjectController((prev) => {
				if (prev) prev.abort();
				return abortController;
			});
			getSubjectAction(
				currentMatch.watchlist,
				currentMatch.subject,
				currentMatch.secret,
				abortController,
			);
		}
	}, [currentMatch, getSubjectAction, subject]);

	useEffect(() => {
		if (!watchlist && currentMatch?.watchlist)
			getWatchlistAction(currentMatch.watchlist, currentMatch?.secret);
	}, [currentMatch, getWatchlistAction, watchlist]);

	useEffect(() => {
		if (!secret && currentMatch?.secret)
			getSecretAction(currentMatch.secret);
	}, [currentMatch, getSecretAction, secret]);

	useEffect(() => {
		const abortController = new AbortController();
		setResultController((prev) => {
			if (prev) prev.abort();
			return abortController;
		});
		setLoadingResults(true);
		getSearchResultIndex(
			id,
			selectedThumbIndex,
			filter,
			abortController,
		).finally(() => {
			if (!abortController.signal.aborted) setLoadingResults(false);
		});

		setThumbsLoaded((prev) => {
			if (!prev && result) {
				result?.thumbs
					.filter((th) => th.index !== selectedThumbIndex)
					.forEach((t) => {
						getSearchResultIndex(
							id,
							t.index,
							{
								page: 0,
								size: 1,
							},
							undefined,
							true,
						);
					});
				return true;
			}
			return prev;
		});
	}, [getSearchResultIndex, id, selectedThumbIndex, filter, result]);

	useEffect(() => {
		const current = matchesRef.current;
		const onScroll = (e: Event) => {
			const el = e.target as HTMLDivElement;
			if (
				el.scrollLeft + el.clientWidth >= el.scrollWidth - 400 &&
				!loading
			)
				loadMoreMatches();
		};

		if (current) {
			current.addEventListener("scroll", onScroll);
		}

		return () => {
			if (current) {
				current.removeEventListener("scroll", onScroll);
			}
		};
	}, [loadMoreMatches, loading]);

	useEffect(() => {
		if (focusedContainer === "thumbs") {
			const handleKeyDown = (e: KeyboardEvent) => {
				if (e.code === "ArrowLeft") {
					e.preventDefault();
					onThumbClick(Math.max(0, selectedThumbIndex - 1));
				}
				if (e.code === "ArrowRight") {
					e.preventDefault();
					onThumbClick(
						Math.min(
							result?.thumbs.length ?? 0,
							selectedThumbIndex + 1,
						),
					);
				}
			};
			window.addEventListener("keydown", handleKeyDown);
			return () => window.removeEventListener("keydown", handleKeyDown);
		}
	}, [focusedContainer, result, selectedThumbIndex, onThumbClick]);

	const showMainImg =
		result?.thumbs.find((t) => t.index === currentMatch?.index) ||
		!loadingResults;
	const showMatchImg = Boolean(records?.[selectedIndex]?.image);
	const showInfo = Boolean(subject);
	const showImgController = (records?.length ?? 0) > 1;
	const showThumbs = (result?.thumbs.length ?? 0) > 1;

	if (!result)
		return (
			<Box className={classes.empty}>
				<CircularProgress />
			</Box>
		);

	return (
		<Box className={classes.container}>
			<Box display="flex">
				<Button
					variant="text"
					className={classes.textBtn}
					onClick={() => history.push(`/watchlists/search`)}
					startIcon={<ArrowBack />}
				>
					Back
				</Button>
				<Button
					variant="text"
					className={classes.textBtn}
					onClick={() => history.push(`/watchlists/search`, result)}
					disabled={!result.thumb}
				>
					Repeat search
				</Button>
			</Box>
			<Box className={classes.paper} flex={7}>
				{showThumbs && (
					<>
						<Box
							className={classes.thumbsContainer}
							onClick={() => setFocusedContainer("thumbs")}
						>
							<Typography variant="h6" className={classes.title}>
								Detected faces
							</Typography>
							<div
								className={classes.thumbs}
								ref={thumbsRef}
								onWheel={handleHorizontalWheel}
							>
								{result?.thumbs.map((thumb, index) => {
									const selector =
										id + `index-${thumb.index}`;
									const matchCount =
										resultsByIndex[selector]
											?.totalElements ?? undefined;
									return (
										<Box
											position="relative"
											key={index}
											className={clsx(
												classes.thumbWrapper,
												thumb.index ===
													selectedThumbIndex &&
													classes.thumbWrapperSelected,
											)}
										>
											<img
												onClick={() =>
													onThumbClick(thumb.index)
												}
												className={clsx(
													classes.img,
													classes.thumb,
												)}
												src={`data:image/jpeg;base64,${
													thumb.thumb ?? ""
												}`}
											/>
											{isNumber(matchCount) ? (
												<Tooltip
													title={`Total matches: ${matchCount}`}
												>
													<Typography
														className={
															classes.matchChip
														}
													>
														{`${matchCount}`}
													</Typography>
												</Tooltip>
											) : null}
										</Box>
									);
								})}
							</div>
						</Box>
						<Divider />
					</>
				)}
				<Box
					display="flex"
					height={showThumbs ? "calc(100% - 130px)" : "100%"}
					style={{ gap: 12 }}
				>
					<Box flex={2} display="flex" flexDirection="column">
						<Box
							display="flex"
							justifyContent="center"
							flex={showMainImg ? "unset" : 1}
							overflow="hidden"
							paddingRight={showImgController ? 7 : 0}
							style={{ gap: 8 }}
						>
							<Box
								className={classes.mainImgWrapper}
								justifyContent="flex-end"
							>
								{showMainImg ? (
									<Box
										position="relative"
										className={clsx(
											mainImgError &&
												clsx(
													classes.mainImgError,
													classes.notFound,
												),
											classes.imgBorder,
										)}
									>
										{mainImgError ? (
											<Typography>
												Image failed to load
											</Typography>
										) : (
											<>
												<img
													onClick={() =>
														setImgDialogOpen(
															!imgDialogOpen,
														)
													}
													className={clsx(
														classes.mainImg,
														classes.zoomable,
													)}
													src={`data:image/jpeg;base64,${
														result?.thumbs.find(
															(t) =>
																t.index ===
																selectedThumbIndex,
														)?.thumb ??
														result.thumb ??
														""
													}`}
													onError={() =>
														setMainImgError(true)
													}
												/>
												{currentMatch?.score && (
													<Tooltip
														title={`Matched with score: ${currentMatch.score}`}
													>
														<Typography
															className={
																classes.score
															}
														>
															{`${currentMatch.score}`}
														</Typography>
													</Tooltip>
												)}
											</>
										)}
									</Box>
								) : (
									<SkeletonLoader
										className={classes.imgBorder}
									/>
								)}
							</Box>
							<Box
								className={classes.mainImgWrapper}
								justifyContent="flex-start"
								flex={showMatchImg ? "unset" : 1}
							>
								{showMatchImg ? (
									<img
										src={`data:image/jpeg;base64,${
											records[selectedIndex]?.image ?? ""
										}`}
										className={clsx(
											classes.mainImg,
											classes.zoomable,
											classes.imgBorder,
										)}
										onClick={() =>
											setImgDialogOpen(!imgDialogOpen)
										}
										ref={matchImgRef}
									/>
								) : loadingRecords ||
								  loading ||
								  loadingResults ? (
									<SkeletonLoader
										className={classes.imgBorder}
									/>
								) : (
									<Box
										className={clsx(
											classes.mainImg,
											classes.notFound,
											classes.imgBorder,
										)}
									>
										{currentMatch
											? "Subject is no longer available"
											: "Subject not found"}
									</Box>
								)}
								{showImgController && (
									<Box className={classes.mainControls}>
										<Box
											display="flex"
											flexDirection="column"
										>
											<IconButton
												size="small"
												style={{ rotate: "180deg" }}
												disabled={selectedIndex === 0}
												onClick={() =>
													setSelectedIndex((prev) =>
														Math.max(0, prev - 1),
													)
												}
											>
												<ArrowDownward />
											</IconButton>
											<IconButton
												size="small"
												disabled={
													selectedIndex ===
													(records?.length ?? 1) - 1
												}
												onClick={() =>
													setSelectedIndex((prev) =>
														Math.min(
															(records?.length ??
																1) - 1,
															prev + 1,
														),
													)
												}
											>
												<ArrowDownward />
											</IconButton>
										</Box>
										<Typography variant="body1">
											{selectedIndex + 1}/
											{records?.length ?? 0}
										</Typography>
									</Box>
								)}
							</Box>
						</Box>
						<ResultInfo
							match={currentMatch}
							watchlist={watchlist}
							status={result?.status}
							error={result?.error}
							secret={secret}
						/>
					</Box>
					<Divider orientation="vertical" flexItem />
					<Box flex={1} visibility={showInfo ? "visible" : "hidden"}>
						<Information subject={subject} watchlist={watchlist} />
					</Box>
				</Box>
			</Box>
			<Box
				className={classes.paper}
				flex={3}
				style={{ gap: 0 }}
				onClick={() => setFocusedContainer("matches")}
			>
				<Typography variant="h6" className={classes.title}>
					Matches ({matches?.totalElements ?? 0})
				</Typography>
				<div
					className={classes.matches}
					ref={matchesRef}
					onWheel={handleHorizontalWheel}
				>
					{matches?.content.length || loadingResults ? (
						matches?.content.map((match, index) => (
							<MatchBox
								key={index}
								match={match}
								secrets={secrets}
								getSecretAction={getSecretAction}
								getSubjectAction={getSubjectAction}
								subject={subjects?.keys[
									match?.watchlist ?? ""
								]?.content.find(
									(s) => s.name === match?.subject,
								)}
								onClick={(arrow?: "left" | "right") => {
									if (focusedContainer !== "matches") return;
									controller?.abort();
									setController(undefined);
									const maxIndex =
										matches?.content.length - 1;

									let newIndex = index;
									if (arrow === "left")
										newIndex = Math.max(
											0,
											selectedResultIndex - 1,
										);
									else if (arrow === "right")
										newIndex = Math.min(
											maxIndex,
											selectedResultIndex + 1,
										);
									setSelectedResultIndex(newIndex);
									scrollToElement(newIndex, matchesRef);

									setSelectedIndex(
										currentMatch?.matchedRecordIndex ?? 0,
									);
									setLoadingRecords(true);
								}}
								selected={index === selectedResultIndex}
							/>
						))
					) : (
						<Typography
							variant="body1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								width: "100%",
							}}
						>
							Matches not found
						</Typography>
					)}
				</div>
			</Box>
			{imgDialogOpen && (
				<ImagesDialog
					setIndex={onThumbClick}
					isOpen={imgDialogOpen}
					setIsOpen={setImgDialogOpen}
					match={currentMatch}
					recordIndex={selectedThumbIndex}
					matchIndex={selectedIndex}
					result={result}
					lowQualityRecord={records?.[selectedIndex]}
					errText={
						currentMatch
							? "Subject is no longer available"
							: "Subject not found"
					}
				/>
			)}
		</Box>
	);
};

export default SearchResultView;
