import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import {
	IGetImageSearchRequest,
	ImageSearch,
	ImageSearchContainer,
	ImageSearchRequest,
	ImageSearchResult,
	ISearchRequestImage,
	ISearchResult,
} from "./types";
import { IRequest, PagedContent } from "../types";

export type ImageSearches =
	| "ImageSearches"
	| "ImageSearches_Filter"
	| "Update_Search_Request"
	| "Search_Results"
	| "Search_Results_Index"
	| "Scroll";

export type ImageSearchAction = AsyncAction<
	ImageSearches,
	PagedContent<ImageSearch[] | ISearchResult[]> | ImageSearch,
	string
>;

const getSearchRequests = async (
	requestParams: IGetImageSearchRequest,
	controller?: AbortController,
) => {
	try {
		const response = await Axios.get<ImageSearchContainer>(
			API_BASE_PREFIX + `watchlists/search`,
			{
				params: requestParams,
				signal: controller?.signal,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getSearchResult = async (id: string, query: IRequest) => {
	try {
		const response = await Axios.get<ImageSearchResult>(
			API_BASE_PREFIX + `watchlists/search/${id}`,
			{ params: query },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getSearchRequestImage = async (id: string) => {
	try {
		const response = await Axios.get<ISearchRequestImage>(
			API_BASE_PREFIX + `watchlists/search/${id}/image`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getSearchResultIndex = async (
	id: string,
	index: number,
	query: IRequest,
	controller?: AbortController,
	dontSave?: boolean,
) => {
	try {
		const response = await Axios.get<PagedContent<ISearchResult[]>>(
			API_BASE_PREFIX + `watchlists/search/${id}/${index}`,
			{ params: query, signal: controller?.signal },
		);
		return { ...response.data, id, index, dontSave };
	} catch (error) {
		throw error;
	}
};

export const searchImage = async (
	requestImage: ImageSearchRequest,
	secret?: string,
): Promise<ImageSearch> => {
	try {
		const response = await Axios.post(
			API_BASE_PREFIX + `watchlists/search`,
			requestImage,
			{
				params: { secret },
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function updateSearchRequest(data: ImageSearch) {
	return {
		payload: data,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Update_Search_Request",
	};
}

export function getSearchRequestsAction(
	requestParams: IGetImageSearchRequest,
	controller?: AbortController,
) {
	return async(
		"ImageSearches",
		getSearchRequests,
		"",
		requestParams,
		controller,
	);
}

export function getSearchResultsAction(
	id: string,
	query?: Pick<IRequest, "page" | "size">,
) {
	return async("Search_Results", getSearchResult, "", id, query);
}

export function getSearchResultIndexAction(
	id: string,
	index: number,
	query?: Pick<IRequest, "page" | "size">,
	controller?: AbortController,
	dontSave?: boolean,
) {
	return async(
		"Search_Results_Index",
		getSearchResultIndex,
		"",
		id,
		index,
		query,
		controller,
		dontSave,
	);
}

export function setSearchRequestFilterAction(filter: IGetImageSearchRequest) {
	return {
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "ImageSearches_Filter",
	};
}

export function setScroll(scroll: number) {
	return {
		meta: scroll,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Scroll",
	};
}
