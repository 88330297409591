import { combineReducers, applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { WithAsyncActionReducer } from "./AsyncState";
import { VMS } from "./VMS/action";
import { engineReducer, initialVMSState, VMSReducer } from "./VMS/reducer";
import { SourceReducer, initialSourceState } from "./Sources/reducer";
import { SourceTaskReducer } from "./SourceTasks/reducer";
import { initialTasksState, tasksReducer } from "./Tasks/reducer";
import { initialSecretsState, secretsReducer } from "./Secrets/reducer";
import { Secrets } from "./Secrets/action";
import {
	initialWatchlistsState,
	watchlistsReducer,
} from "./Watchlists/reducer";
import { Watchlists } from "./Watchlists/action";
import { SubjectsReducer, initialSubjectsState } from "./Subjects/reducer";
import { Records } from "./Records/action";
import { initialRecordsState, RecordsReducer } from "./Records/reducer";
import { initialTaskHistoryState, historyReducer } from "./TaskHistory/reducer";
import { History } from "./TaskHistory/action";
import { initialLogsState, LogsReducer } from "./Logs/reducer";
import { initialEventsState, EventsReducer } from "./Events/reducer";
import { initialRulesState, RulesReducer } from "./Rules/reducer";
import {
	initialPropertiesState,
	propertiesReducer,
} from "./Properties/reducer";
import {
	initialDefinitionsState,
	definitionsReducer,
} from "./Definitions/reducer";
import {
	initialImageSearchState,
	imageSearchReducer,
} from "./ImageSearch/reducer";
import {
	authoritiesReducer,
	initialAuthoritiesState,
} from "./Authorities/reducer";
import { initialUsersState, usersReducer } from "./Users/reducer";
import { ConfigReducer, initialConfigState } from "./UserConfig/reducer";
import { LiveReducer, initialLiveState } from "./LiveCameras/reducer";
import { initialReportsState, ReportsReducer } from "./Reports/reducer";
import {
	backwardSearchReducer,
	initialBackwardSearchState,
} from "./BackwardSearch/reducer";
import { SchemasReducer, initialSchemaState } from "./Schemas/reducer";

const rootReducer = combineReducers({
	users: WithAsyncActionReducer("Users", initialUsersState, usersReducer),
	authorities: WithAsyncActionReducer(
		"Authorities",
		initialAuthoritiesState,
		authoritiesReducer,
	),
	sources: WithAsyncActionReducer(
		"SOURCE",
		initialSourceState,
		SourceReducer,
	),
	sourceTasks: SourceTaskReducer,
	vms: WithAsyncActionReducer(VMS, initialVMSState, VMSReducer),
	tasks: WithAsyncActionReducer("Tasks", initialTasksState, tasksReducer),
	taskHistory: WithAsyncActionReducer(
		History,
		initialTaskHistoryState,
		historyReducer,
	),
	secrets: WithAsyncActionReducer(
		Secrets,
		initialSecretsState,
		secretsReducer,
	),
	watchlists: WithAsyncActionReducer(
		Watchlists,
		initialWatchlistsState,
		watchlistsReducer,
	),
	subjects: WithAsyncActionReducer(
		"SUBJECT",
		initialSubjectsState,
		SubjectsReducer,
	),
	records: WithAsyncActionReducer(
		Records,
		initialRecordsState,
		RecordsReducer,
	),
	reports: WithAsyncActionReducer(
		"REPORTS",
		initialReportsState,
		ReportsReducer,
	),
	logs: WithAsyncActionReducer("LOGS", initialLogsState, LogsReducer),
	rules: WithAsyncActionReducer("RULES", initialRulesState, RulesReducer),
	properties: WithAsyncActionReducer(
		"Properties",
		initialPropertiesState,
		propertiesReducer,
	),
	definitions: WithAsyncActionReducer(
		"Definitions",
		initialDefinitionsState,
		definitionsReducer,
	),
	imageSearches: WithAsyncActionReducer(
		"ImageSearches",
		initialImageSearchState,
		imageSearchReducer,
	),
	events: WithAsyncActionReducer("Events", initialEventsState, EventsReducer),
	userConfig: WithAsyncActionReducer(
		"UserConfig",
		initialConfigState,
		ConfigReducer,
	),
	backwardSearch: WithAsyncActionReducer(
		"BackwardSearch",
		initialBackwardSearchState,
		backwardSearchReducer,
	),
	live: WithAsyncActionReducer("Live", initialLiveState, LiveReducer),
	engines: engineReducer,
	schemas: WithAsyncActionReducer(
		"Schemas",
		initialSchemaState,
		SchemasReducer,
	),
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
	const composeEnhancers =
		(window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
		compose;
	const enchancer = composeEnhancers(applyMiddleware(thunkMiddleware));

	const store = createStore(rootReducer, enchancer);
	return store;
}
