import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISource } from "../../store/Sources/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import moment from "moment";
import StreamControls from "../Events/Stream/StreamControls";
import Timeline from "../Events/Stream/Timeline";
import { IElasticSource } from "../../store/Events/types";
import clsx from "clsx";
import ZoomOverlay, { TImagePos } from "./ZoomOverlay";
import { THEME } from "../../config";
import { vmsConnectionIds } from "../../services/vmsConnectionIds";
import useIsOnline from "../../hooks/useIsOnline";
import { setVMSConnectionIsLostAction } from "../../store/VMS/action";
import VideoConnectionLost from "./VideoConnectionLost";
import { ISubjectEvent } from "../../proto/types";

export const TimelineScale = {
	"10s": 1,
	"1m": 6,
	"10m": 60,
	"30m": 180,
	"1h": 360,
};

type Props = {
	source: ISource;
	vmsName: string;
	maximized: boolean;
	setMaximized: React.Dispatch<React.SetStateAction<boolean>>;
	timestamp?: string;
	eventSource?: IElasticSource;
	title?: string;
	onLiveClick?: () => void;
};

const useStyles = makeStyles(() => ({
	root: {
		aspectRatio: "16/8",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		maxWidth: "100%",
		maxHeight: "100%",
		margin: "0 auto",
		position: "relative",
		overflow: "hidden",
		flex: 1,
	},
	canvas: {
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
		flex: 1,
	},
	canvasWrapper: {
		backgroundColor: "black",
		position: "relative",
		display: "flex",
		justifyContent: "center",
		maxHeight: "calc(100% - (38px + 40px + 32px))",
	},
	skeleton: {
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 1,
	},
	title: {
		display: "flex",
		justifyContent: "center",
		background: THEME.palette.primary.dark,
		color: THEME.palette.primary.contrastText,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
	},
	loader: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 2,
	},
	timelinePlaceholder: {
		height: 40,
		minHeight: 40,
	},
	maximized: {
		zIndex: 2000,
		backgroundColor: "white",
		paddingTop: 0,
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "flex",
		borderRadius: 0,
		"& $title": {
			borderRadius: 0,
		},
	},
}));

const EventsStream = ({
	source,
	vmsName,
	timestamp,
	eventSource,
	title,
	maximized,
	setMaximized,
	onLiveClick,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const root = useRef<HTMLDivElement>(null);
	const isOnline = useIsOnline();

	const isConnectionLost = useSelector(
		(state: AppState) => state.vms.connections[vmsName],
	);

	const [connectionWasLost, setConnectionWasLost] = useState(false);

	const sdkIsLoaded = useSelector(
		(state: AppState) => state.live.sdkIsLoaded,
	);
	const events = useSelector((state: AppState) => state.events);
	const vms = useSelector((state: AppState) => state.vms.keys);
	const [playSpeed, setPlaySpeed] = useState(1);
	const [isPlaying, setIsPlaying] = useState(false);
	const [timelineScale, setTimelineScale] = useState(TimelineScale["10s"]);
	const [currentTime, setCurrentTime] = useState(moment(timestamp));
	const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
	const [isFramesValid, setIsFramesValid] = useState(false);
	const [lastImage, setLastImage] = useState<HTMLImageElement | null>(null);
	const [loadingSeq, setLoadingSeq] = useState(false);
	const [dragPos, setDragPos] = useState({ x: 0, y: 0 });
	const [dragging, setDragging] = useState(false);
	const [zoomLevel, setZoomLevel] = useState(1);
	const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
	const [imagePos, setImagePos] = useState<
		| undefined
		| {
				top: number;
				left: number;
				right: number;
				bottom: number;
		  }
	>();
	const imagePosRef = useRef(imagePos);
	const [, setConnectionTries] = useState(0);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const videoController = useRef<undefined | { [key: string]: any }>();

	const sequences = useMemo(() => {
		return events.cameras[
			videoController.current?.request?.parameters?.CameraId
		]?.sequences;
	}, [events]);

	const url = useMemo(() => {
		const vm = vms[vmsName];
		if (!vm) return undefined;
		if (vm.driver !== "MILESTONE" || !vm.ip) {
			setError("Video recording not available for this VMS");
			return undefined;
		}
		if (vm.useMobileServer === false) {
			setError(
				"Video recording is not available, because VMS is not configured to use Mobile Server",
			);
			return undefined;
		}
		if (!vm || !vm.ip) {
			return undefined;
		}
		setError("");
		const ssl = vm.mobileServerSSL ? "https" : "http";
		return `${ssl}://${vm.ip}:${vm.mobilServerPort}`;
	}, [vms, vmsName]);

	const drawMinimap = useCallback(
		(image: ImageBitmap | HTMLImageElement, pos: TImagePos) => {
			const canvas = canvasRef?.current;
			const ctx = canvas?.getContext("2d");
			if (canvas && ctx && image && pos) {
				const isZoomed = pos.left !== 0 || pos.top !== 0;
				if (!isZoomed) return;
				const { width, height } = image;
				const aspectRatio = width / height;

				const minimapWidth = canvas.width / 8;
				const minimapHeight = minimapWidth / aspectRatio;
				const minimapX = canvas.width - minimapWidth;
				const minimapY = canvas.height - minimapHeight;
				ctx.drawImage(
					image,
					0,
					0,
					width,
					height,
					minimapX,
					minimapY,
					minimapWidth,
					minimapHeight,
				);
				ctx.strokeStyle = THEME.palette.success.main;
				ctx.lineWidth = 4;
				ctx.strokeRect(
					minimapX + (pos.left / width) * minimapWidth,
					minimapY + (pos.top / height) * minimapHeight,
					((pos.right - pos.left) / width) * minimapWidth,
					((pos.bottom - pos.top) / height) * minimapHeight,
				);
			}
		},
		[canvasRef],
	);

	const dragRect = useCallback(
		(
			image: ImageBitmap | HTMLImageElement,
			dragStart: { x: number; y: number },
			cursorPos: { x: number; y: number },
		) => {
			const ctx = canvasRef.current?.getContext("2d");
			if (!ctx || !image) return;
			ctx.strokeStyle = THEME.palette.success.light;
			ctx.lineWidth = 4;
			ctx.strokeRect(
				dragStart.x * ctx.canvas.width,
				dragStart.y * ctx.canvas.height,
				(cursorPos.x - dragStart.x) * ctx.canvas.width,
				(cursorPos.y - dragStart.y) * ctx.canvas.height,
			);
		},
		[],
	);

	const drawImage = useCallback(
		(image: HTMLImageElement | ImageBitmap) => {
			const canvas = canvasRef.current;
			if (canvas) {
				const ctx = canvas.getContext("2d");
				if (ctx) {
					if (canvasSize.width !== image.width) {
						setCanvasSize({
							width: image.width,
							height: image.height,
						});
					}
					imagePos
						? setImagePos((prev) => {
								if (prev) {
									ctx.drawImage(
										image,
										prev.left,
										prev.top,
										prev.right - prev.left,
										prev.bottom - prev.top,
										0,
										0,
										canvas.width,
										canvas.height,
									);
									drawMinimap(image, prev);
								}
								return prev;
						  })
						: ctx.drawImage(image, 0, 0);
					setDragging((prevDragging) => {
						if (prevDragging && zoomLevel === 1) {
							setCursorPos((prevPos) => {
								dragRect(image, dragPos, prevPos);
								return prevPos;
							});
						}
						return prevDragging;
					});
				}
			}
		},
		[canvasSize, imagePos, drawMinimap, dragPos, dragRect, zoomLevel],
	);

	const drawRectangles = useCallback(
		(
			frameTimestamp: string,
			imgWidth: number,
			imgHeight: number,
			source: any,
			color: string = "red",
			pos?: TImagePos,
		) => {
			const canvas = canvasRef.current;
			if (canvas) {
				const details: ISubjectEvent["details"] = (
					source?.details ?? []
				).filter((d: any) => d.type !== 2);
				const isAfterLastDetail = moment(frameTimestamp).isAfter(
					moment(details[details.length - 1]?.timeStamp),
					"ms",
				);
				const isBeforeFirstDetail = moment(frameTimestamp).isBefore(
					moment(details[0]?.timeStamp),
					"ms",
				);

				if (isAfterLastDetail || isBeforeFirstDetail) return;

				const closestDetailInTime = details.reduce(
					(acc: any, detail: any) => {
						const diff = Math.abs(
							moment(detail.timeStamp).diff(
								moment(frameTimestamp),
								"ms",
							),
						);
						if (!acc || diff < acc.diff) {
							return { ...detail, diff };
						}
						return acc;
					},
					undefined,
				);

				const ctx = canvas.getContext("2d");
				if (ctx) {
					const data = [
						closestDetailInTime?.faces?.[0],
						closestDetailInTime?.licensePlates?.[0],
						closestDetailInTime?.vehicleHuman,
					];

					const { imageHeight, imageWidth } = source;
					data.forEach((item) => {
						if (!item || !item?.rectangle) return;
						const { rectangle, rotation } = item;

						const posTop = pos?.top ?? 0;
						const posBottom = pos?.bottom ?? 0;
						const posLeft = pos?.left ?? 0;
						const posRight = pos?.right ?? 0;
						const scaleX =
							imgWidth / (posRight - posLeft || imgWidth);
						const scaleY =
							imgHeight / (posBottom - posTop || imgHeight);
						const x =
							((rectangle.x / imageWidth) * imgWidth - posLeft) *
							scaleX;
						const y =
							((rectangle.y / imageHeight) * imgHeight - posTop) *
							scaleY;

						const width =
							(rectangle.width / imageWidth) * imgWidth * scaleX;
						const height =
							(rectangle.height / imageHeight) *
							imgHeight *
							scaleY;

						if (rotation) {
							ctx.save();
							ctx.translate(x + width / 2, y + height / 2);
							ctx.rotate((rotation * Math.PI) / 180);
							ctx.translate(-(x + width / 2), -(y + height / 2));
						}

						ctx.beginPath();
						ctx.lineWidth = 3;
						ctx.strokeStyle = color;
						ctx.rect(x, y, width, height);
						ctx.stroke();

						if (rotation) ctx.restore();
					});
				}
			}
		},
		[],
	);

	const drawError = useCallback(
		(err: string, textColor: string = "white", clear: boolean = true) => {
			setLoading(false);
			const canvas = canvasRef.current;
			if (canvas) {
				const ctx = canvas.getContext("2d");
				if (ctx) {
					if (clear) ctx.clearRect(0, 0, canvas.width, canvas.height);
					const fontSize = canvas.width / 30;
					const spaceFromSides = canvas.width * 0.03;
					ctx.textAlign = "center";
					ctx.textBaseline = "middle";
					ctx.font = `${fontSize}px Arial`;
					ctx.fillStyle = textColor;
					if (
						ctx.measureText(err).width >
						canvas.width - spaceFromSides
					) {
						const words = err.split(" ");
						let line = "";
						for (let n = 0; n < words.length; n++) {
							const testLine = line + words[n] + " ";
							const metrics = ctx.measureText(testLine);
							const testWidth = metrics.width;
							if (
								testWidth > canvas.width - spaceFromSides &&
								n > 0
							) {
								ctx.fillText(
									line,
									canvas.width / 2,
									canvas.height / 2,
								);
								line = words[n] + " ";
							} else {
								line = testLine;
							}
						}
						ctx.fillText(
							line,
							canvas.width / 2,
							canvas.height / 2 + fontSize + 10,
						);
					} else {
						ctx.fillText(err, canvas.width / 2, canvas.height / 2);
					}
				}
			}
		},
		[],
	);

	const onConnectionRestored = useCallback(() => {
		if (connectionWasLost) {
			setConnectionWasLost(false);
			XPMobileSDK.playbackGoTo(
				videoController.current,
				moment(timestamp).valueOf(),
				playSpeed > 0 ? "TimeOrAfter" : "TimeOrBefore",
				() => {
					if (isPlaying)
						XPMobileSDK.playbackSpeed(
							videoController.current,
							playSpeed,
						);
				},
				() => {
					if (isPlaying)
						XPMobileSDK.playbackSpeed(
							videoController.current,
							playSpeed,
						);
				},
			);
			return;
		}
	}, [connectionWasLost, timestamp, isPlaying, playSpeed]);

	const onFrameReceived = useCallback(
		async (frame: any) => {
			if (connectionWasLost) onConnectionRestored();

			if (
				!frame.blob &&
				!frame.hasSizeInformation &&
				frame.frameNumber > 15 &&
				!isFramesValid
			) {
				drawError("Video not available");
				if (loading) setLoading(false);
				return;
			}

			if (frame.dataSize > 0) {
				const src = URL.createObjectURL(frame.blob);
				if (loading) setLoading(false);
				if (canvasRef.current) {
					const ctx = canvasRef.current.getContext("2d");
					if (ctx) {
						if (isPlaying && frame.timestamp)
							setCurrentTime(frame.timestamp);

						await new Promise<void>((resolve) => {
							const img = new Image();
							img.onload = () => {
								setLastImage(img);
								drawImage(img);
								drawRectangles(
									frame.timestamp,
									img.width,
									img.height,
									eventSource,
									undefined,
									imagePosRef.current,
								);
								resolve();
							};
							img.src = src;
						});
					}
					if (!isFramesValid && frame.frameNumber > 0)
						setIsFramesValid(true);

					URL.revokeObjectURL(src);
					frame = null;
				}
			}
		},
		[
			loading,
			drawImage,
			drawError,
			isFramesValid,
			isPlaying,
			drawRectangles,
			eventSource,
			onConnectionRestored,
			connectionWasLost,
		],
	);

	const onConnection = useCallback(() => {
		vmsConnectionIds
			.getVmsConnectionId(vmsName)
			.then(({ id, isAlreadyConnected }) => {
				if (isAlreadyConnected) return;
				XPMobileSDK.connectWithId(url, id);
			})
			.catch(() =>
				setConnectionTries((prev) => {
					if (prev < 3) {
						setTimeout(() => onConnection(), 500);
						return prev + 1;
					} else {
						drawError("Failed to connect");
						setLoading(false);
						return prev;
					}
				}),
			);
	}, [url, vmsName, drawError]);

	const onLogin = useCallback(() => {
		if (!localStorage.getItem(vmsName)) {
			drawError("Failed to connect");
			setLoading(false);
			return;
		}

		if (connectionWasLost) return onConnectionRestored();

		XPMobileSDK.RequestStream(
			{
				ConnectionId: localStorage.getItem(vmsName),
				CameraId: source.id,
				DestWidth: 1200,
				DestHeight: 600,
				Fps: 15,
				ComprLevel: 90,
				SignalType: "Playback",
				MethodType: "Push",
				SeekType: "Time",
				Time: moment(timestamp).valueOf(),
			},
			(videoConnection: any) => {
				if (!videoConnection?.videoId) return;
				XPMobileSDK.playbackSpeed(videoConnection, 0);
				if (videoConnection?.observers?.length === 0) {
					videoConnection.addObserver({
						videoConnectionReceivedFrame: onFrameReceived,
					});
				}
				videoConnection.open();
				videoController.current = videoConnection;
			},
		);
	}, [
		vmsName,
		onFrameReceived,
		source,
		timestamp,
		drawError,
		connectionWasLost,
		onConnectionRestored,
	]);

	const connect = useCallback(() => {
		if (connectionWasLost) {
			setLoading(true);
			return onConnection();
		}
		XPMobileSDKSettings.MobileServerURL = url;
		XPMobileSDKSettings.supportsCHAP = false;
		XPMobileSDK.features = {
			SupportTimeBetweenFrames: false,
		};
		const observer = {
			connectionDidLogIn: () => {
				vmsConnectionIds.setIsVmsConnected(vmsName, true);
				onLogin();
			},
			connectionFailedToConnectWithId: () => {
				vmsConnectionIds.removeVms(vmsName);
				onConnection();
			},
			connectionFailedToConnect: () => {
				drawError("Camera is not responding");
				setLoading(false);
			},
			connectionLostConnection: () => {
				vmsConnectionIds.setIsVmsConnected(vmsName, false, true);
				dispatch(setVMSConnectionIsLostAction(vmsName, true));
				setError("Connection lost");
			},
			connectionStateChanged: () => {
				if (connectionWasLost) onConnectionRestored();
			},
		};

		XPMobileSDK.addObserver(observer);
		onConnection();
	}, [
		url,
		onConnection,
		onLogin,
		drawError,
		vmsName,
		connectionWasLost,
		onConnectionRestored,
		dispatch,
	]);

	const drawBlackBackground = useCallback(() => {
		const canvas = canvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext("2d");
			if (ctx) {
				ctx.fillStyle = "black";
				ctx.fillRect(0, 0, canvas.width, canvas.height);
			}
		}
	}, []);

	useEffect(() => {
		if (!isPlaying && sequences && !loadingSeq) {
			const timeHasVideo = sequences?.some(
				(s) =>
					moment(currentTime).isBetween(
						s.StartTime,
						s.EndTime,
						"s",
						"[]",
					) === true,
			);

			if (!timeHasVideo) {
				const ctx = canvasRef.current?.getContext("2d");
				if (!ctx || !lastImage) return;
				ctx.filter = "brightness(0.4) blur(4px)";
				drawImage(lastImage);
				ctx.filter = "none";
				drawError("Video not available at this time", "red", false);
			}
		}
	}, [
		sequences,
		isPlaying,
		currentTime,
		drawError,
		drawImage,
		lastImage,
		loadingSeq,
	]);

	useEffect(() => {
		if (
			videoController.current &&
			videoController.current.observers[0]?.videoConnectionReceivedFrame
		) {
			videoController.current.observers[0].videoConnectionReceivedFrame =
				onFrameReceived;
		}
	}, [onFrameReceived]);

	useEffect(() => {
		const disableFrames = () => {
			if (videoController.current?.observers[0])
				videoController.current.observers[0].videoConnectionReceivedFrame =
					undefined;
		};

		if (error) {
			videoController.current?.close();
			disableFrames();
			drawError(error);
			return;
		}
		if (!sdkIsLoaded) return;
		if (!url) return;

		if (isConnectionLost) return;
		setImagePos(undefined);
		setDragging(false);
		setZoomLevel(1);
		setCurrentTime(moment(timestamp));
		setIsPlaying(false);
		if (!connectionWasLost) setIsFramesValid(false);

		if (videoController.current)
			XPMobileSDK.playbackSpeed(videoController.current, 0);

		drawBlackBackground();
		disableFrames();
		if (eventSource && source.id !== eventSource.sourceId) return;
		if (vmsConnectionIds.isVmsConnected(vmsName)) onLogin();
		else connect();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdkIsLoaded, source, timestamp, error, eventSource, isConnectionLost]);

	useEffect(() => {
		if (connectionWasLost) setError("");
		if (isConnectionLost) setConnectionWasLost(true);
		return () => {
			if (
				videoController.current &&
				!connectionWasLost &&
				!isConnectionLost
			) {
				videoController.current.close();
			}
		};
	}, [isConnectionLost, connectionWasLost]);

	useEffect(() => {
		imagePosRef.current = imagePos;
	}, [imagePos]);

	const streamReady =
		!loading && isFramesValid && !connectionWasLost && !isConnectionLost;

	return (
		<div
			className={clsx(classes.root, maximized && classes.maximized)}
			ref={root}
		>
			{isConnectionLost || !isOnline ? (
				<VideoConnectionLost vmsName={vmsName} isOnline={isOnline} />
			) : (
				<>
					{title && (
						<Typography variant="h6" className={classes.title}>
							{title}
						</Typography>
					)}
					{loading && (
						<Box className={classes.loader}>
							<CircularProgress
								style={{
									color: "white",
								}}
							/>
						</Box>
					)}
					<Box className={classes.canvasWrapper}>
						<canvas
							width={canvasSize.width || 2400}
							height={canvasSize.height || 1200}
							className={classes.canvas}
							ref={canvasRef}
						/>
						<ZoomOverlay
							key={String(loading)}
							lastImage={lastImage}
							ref={canvasRef}
							setImagePos={setImagePos}
							imagePos={imagePos}
							drawMinimap={drawMinimap}
							dragRect={dragRect}
							dragPos={dragPos}
							setDragPos={setDragPos}
							cursorPos={cursorPos}
							setCursorPos={setCursorPos}
							dragging={dragging}
							setDragging={setDragging}
							zoomLevel={zoomLevel}
							setZoomLevel={setZoomLevel}
							drawRects={(pos) => {
								if (lastImage)
									drawRectangles(
										moment(currentTime)
											.toDate()
											.toISOString(),
										lastImage.width,
										lastImage.height,
										eventSource,
										undefined,
										pos,
									);
							}}
							setMaximized={setMaximized}
						/>
					</Box>
					<StreamControls
						videoController={videoController.current}
						timestamp={moment(currentTime).valueOf()}
						disabled={!streamReady}
						playSpeed={playSpeed}
						setPlaySpeed={setPlaySpeed}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						timelineScale={timelineScale}
						setTimelineScale={setTimelineScale}
						setMaximized={setMaximized}
						maximized={maximized}
						onLiveClick={onLiveClick}
					/>
					{streamReady ? (
						<Timeline
							videoController={videoController.current}
							currentTime={currentTime.valueOf()}
							setCurrentTime={setCurrentTime}
							timelineScale={timelineScale}
							setTimelineScale={setTimelineScale}
							isPlaying={isPlaying}
							playSpeed={playSpeed}
							hidden={!streamReady}
							timestamp={moment(timestamp).valueOf()}
							setLoadingSeq={setLoadingSeq}
							isSequencesLoading={loadingSeq}
						/>
					) : (
						<Box className={classes.timelinePlaceholder} />
					)}
				</>
			)}
		</div>
	);
};

export default EventsStream;
