import React from "react";
import {
	makeStyles,
	Grid,
	Button,
	Typography,
	Popover,
	Select,
	MenuItem,
	Theme,
} from "@material-ui/core";
import { AnalyticEvent, RuleVmsAction } from "../../../../../store/Rules/types";
import { getEvents } from "../../../../../store/Rules/action";
import { IAlert } from "../../../../UI/Alert";
import ActionsCheckboxes from "./ActionsCheckboxes";

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		width: "50%",
	},
	popoverContainer: {
		paddingTop: 10,
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	eventName: {
		paddingTop: 5,
	},
	popoverRowEventName: {
		marginBottom: 10,
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	noEvents: {
		paddingTop: 5,
		color: theme.palette.error.main,
	},
}));

const defaultAction: RuleVmsAction = {
	type: "VmsAction",
	eventName: "",
	sendWithTrackEvent: true,
	sendWithDisappearedEvent: true,
};

interface IAnalyticsPopoverProps {
	onUpdate(action: RuleVmsAction): void;
	onCancel: () => void;
	vmsAction?: RuleVmsAction;
	anchorEl: null | HTMLElement;
	onAlert(alert: IAlert): void;
	vmsName: string;
	isReadOnly: boolean;
}

const AnalyticsPopover: React.FC<IAnalyticsPopoverProps> = ({
	onUpdate,
	onCancel,
	vmsAction,
	anchorEl,
	onAlert,
	vmsName,
	isReadOnly,
}) => {
	const classes = useStyles();

	const [action, setAction] = React.useState<RuleVmsAction>(
		vmsAction ? vmsAction : defaultAction,
	);
	const [events, setEvents] = React.useState<AnalyticEvent[]>([]);
	const [failer, setFailer] = React.useState<boolean>(true);

	React.useEffect(() => {
		let isSubed = true;
		const loadEvents = async (vms: string) => {
			try {
				const response = await getEvents(vms);
				if (isSubed) setEvents(response);
			} catch (error) {
				if (failer) {
					onAlert({
						message: "Failed to load events.",
						variant: "error",
					});
				}
				setFailer(false);
			}
		};
		if (!isReadOnly) loadEvents(vmsName);
		return () => {
			isSubed = false;
		};
	}, [vmsName, onAlert, failer, isReadOnly]);

	React.useEffect(() => {
		setAction(vmsAction ? vmsAction : defaultAction);
	}, [vmsAction, anchorEl]);

	const handleConfirm = () => {
		onUpdate(action);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleEventName = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const changedAction = { ...action };
		changedAction.eventName = event.target.value as string;
		setAction(changedAction);
	};

	const handleTrackChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithTrackEvent = value;
		setAction(changedAction);
	};

	const handleDisappearedChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithDisappearedEvent = value;
		setAction(changedAction);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			className={classes.popover}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item container className={classes.popoverRowEventName}>
					<Grid item xs={12}>
						<Typography className={classes.eventName}>
							Analytic event name:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{events.length > 0 ? (
							<Select
								fullWidth
								autoWidth
								value={action.eventName}
								onChange={handleEventName}
							>
								{events.map((e) => (
									<MenuItem
										key={e.eventTypeId}
										value={e.name}
									>
										{e.name}
									</MenuItem>
								))}
							</Select>
						) : (
							<Typography className={classes.noEvents}>
								No events found.
							</Typography>
						)}
					</Grid>
				</Grid>
				<ActionsCheckboxes
					onTrack={handleTrackChange}
					onDisappeared={handleDisappearedChange}
					trackCheck={action.sendWithTrackEvent}
					disCheck={action.sendWithDisappearedEvent}
				/>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								action.eventName === "" ||
								(!action.sendWithTrackEvent &&
									!action.sendWithDisappearedEvent)
							}
						>
							{vmsAction ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default AnalyticsPopover;
