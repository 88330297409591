import axios from "axios";

enum STATUS {
	FETCHING = "FETCHING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

interface IVmsConnection {
	id: string;
	name: string;
	status: STATUS;
	isConnected: boolean;
}

interface IResponse {
	id: string;
	isAlreadyConnected: boolean;
}

class vmsConnectionIdsClass {
	private _vmsConnections: { [name: string]: IVmsConnection } = {};

	isVmsConnected(name: string): boolean {
		return (
			this._vmsConnections[name]?.status === STATUS.SUCCESS &&
			this._vmsConnections[name]?.isConnected
		);
	}

	setIsVmsConnected(
		name: string,
		isConnected: boolean,
		isError = false,
	): void {
		this._vmsConnections[name].isConnected = isConnected;
		if (isError) this._vmsConnections[name].status = STATUS.ERROR;
	}

	removeVms(name: string): void {
		localStorage.removeItem(name);
		delete this._vmsConnections[name];
	}

	async getVmsConnectionId(name: string): Promise<IResponse> {
		try {
			await new Promise((resolve) => setTimeout(resolve, 0));

			if (
				this._vmsConnections[name]?.status === STATUS.SUCCESS &&
				this._vmsConnections[name]?.isConnected
			)
				return {
					id: this._vmsConnections[name].id,
					isAlreadyConnected: true,
				};

			if (
				this._vmsConnections[name]?.status === STATUS.SUCCESS &&
				!this._vmsConnections[name]?.isConnected
			)
				return this.waitForVmsConnection(name);

			const id = localStorage.getItem(name);
			if (id) {
				this._vmsConnections[name] = {
					id,
					name,
					status: STATUS.SUCCESS,
					isConnected: false,
				};
				return { id, isAlreadyConnected: false };
			}

			if (this._vmsConnections[name]?.status === STATUS.FETCHING)
				return this.waitForVmsConnection(name);

			const newId = await this.getConnectionId(name);

			this._vmsConnections[name] = {
				id: newId,
				name,
				status: STATUS.SUCCESS,
				isConnected: false,
			};
			localStorage.setItem(name, newId);
			return { id: newId, isAlreadyConnected: false };
		} catch (error) {
			localStorage.removeItem(name);
			this._vmsConnections[name] = {
				id: "",
				name,
				isConnected: false,
				status: STATUS.ERROR,
			};
			throw error;
		}
	}

	private async getConnectionId(name: string): Promise<string> {
		this._vmsConnections[name] = {
			id: "",
			name,
			status: STATUS.FETCHING,
			isConnected: false,
		};

		const { data: newId } = await axios.get<string>(
			`/rs/vms/${name}/extensions/connectionId`,
		);

		return newId;
	}

	private waitForVmsConnection(name: string): Promise<IResponse> {
		return new Promise((resolve, reject) => {
			const interval = setInterval(() => {
				if (
					this._vmsConnections[name]?.status === STATUS.SUCCESS &&
					this._vmsConnections[name]?.isConnected
				) {
					clearInterval(interval);
					resolve({
						id: this._vmsConnections[name].id,
						isAlreadyConnected: true,
					});
				} else if (
					this._vmsConnections[name]?.status === STATUS.ERROR
				) {
					clearInterval(interval);
					reject(new Error("Failed to get VMS connection ID"));
				}
			}, 50);
		});
	}
}

export const vmsConnectionIds = new vmsConnectionIdsClass();
