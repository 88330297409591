import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Snackbar,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import Title from "./Common/Title";
import exportEvents, {
	cancelEventsExport,
	isEventsExportCancelled,
} from "../../helpers/EventsExport";
import Alert from "../UI/Alert";
import { EventsFilter } from "../../store/Events/types";
import Chips from "../Events/Filter/Chips";
import { AlertTitle, Alert as MuiAlert } from "@material-ui/lab";
import { EXPORT_TIME_HELPERS } from "../Logs/constants";
import { Checkboxes, makeValidate } from "mui-rff";
import { Form } from "react-final-form";
import * as Yup from "yup";

interface ExportDialogProps {
	open: boolean;
	onClose(): void;
	filter: EventsFilter;
}

export enum ExportField {
	"VH" = "vh",
	"FACES" = "faces",
	"ALPR" = "alpr",
}

interface IFormValues {
	fields: ExportField[];
}

const useStyles = makeStyles(() => ({
	content: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
	},
	alert: {
		display: "flex",
		alignItems: "center",
		gap: 4,
	},
	disabledAlert: {
		transition: "linear 0.1s",
		opacity: 0.9,
		color: THEME.palette.grey[600],
		backgroundColor: THEME.palette.grey[200],
	},
	disabledIcon: {
		transition: "linear 0.1s",
		color: `${THEME.palette.grey[600]} !important`,
	},
	filters: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		padding: 8,
		borderBottom: `1px solid ${THEME.palette.secondary.main}`,
	},
	filtersText: {
		height: "100%",
		paddingRight: 8,
		borderRight: `1px solid ${THEME.palette.secondary.main}`,
	},
	helperButton: {
		padding: "4px",
	},
	active: {
		backgroundColor: "#b4dcfa",
		"&:hover": {
			backgroundColor: "#a9d4f7",
		},
	},
	helpers: {
		display: "flex",
		gap: 8,
	},
	form: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
	},
	fields: {
		display: "flex",
		flexDirection: "row",
		gap: 4,
	},
	fieldsBox: {
		paddingTop: 8,
		minHeight: 90,
	},
	action: {
		display: "flex",
		justifyContent: "space-between",
	},
	actionButtons: {
		display: "flex",
		gap: 4,
	},
	progressBox: {
		display: "flex",
		gap: 8,
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	timeBox: {
		borderBottom: `1px solid ${THEME.palette.secondary.main}`,
		display: "flex",
		flexDirection: "column",
		gap: 8,
		paddingBottom: 4,
	},
}));

const schema = Yup.object().shape({
	fields: Yup.array().of(Yup.string()).min(1, "At least one is required"),
});
const validator = makeValidate(schema);

const ExportDialog: React.FC<ExportDialogProps> = ({
	open,
	filter,
	onClose,
}) => {
	const classes = useStyles();
	const [exportFilter, setExportFilter] = useState(filter);
	const [isExporting, setIsExporting] = useState(false);
	const [exportNotification, setExportNotification] = useState<{
		msg: string;
		variant: "error" | "success";
	}>({
		msg: "",
		variant: "success",
	});
	const [loadedHits, setLoadedHits] = useState(0);

	const handleExport = async (values: IFormValues) => {
		setIsExporting(true);
		return exportEvents(exportFilter, values.fields, setLoadedHits)
			.then(() => {
				if (!isEventsExportCancelled())
					setExportNotification({
						msg: "Exported successfully",
						variant: "success",
					});
			})
			.catch(() =>
				setExportNotification({
					msg: "Export failed",
					variant: "error",
				}),
			)
			.finally(() => {
				setLoadedHits(0);
				setIsExporting(false);
			});
	};

	const handleClose = () => {
		setExportFilter(filter);
		cancelEventsExport();
		onClose();
	};

	const isFiltered = Object.keys(exportFilter ?? {}).length > 0;
	const timeRangeWasSelected =
		filter.time || filter.timeFrom || filter.relativeFrom;
	const isTimeRangeSelected =
		exportFilter.time || exportFilter.timeFrom || exportFilter.relativeFrom;

	useEffect(() => {
		setExportFilter(filter);
	}, [filter]);

	return (
		<>
			<Dialog
				keepMounted={false}
				open={open}
				fullWidth
				style={{ overflow: "hidden" }}
				onClose={handleClose}
			>
				<Title title="Export" onClose={handleClose} />
				<Form
					validate={validator}
					initialValues={{
						fields: ["vh", "faces", "alpr"],
					}}
					onSubmit={handleExport}
					keepDirtyOnReinitialize
					render={({ handleSubmit, invalid }) => (
						<form
							onSubmit={handleSubmit}
							autoComplete="off"
							className={classes.form}
						>
							<DialogContent className={classes.content}>
								<Box className={classes.filters}>
									{isFiltered ? (
										<>
											<Typography
												className={classes.filtersText}
											>
												Selected filters
											</Typography>
											<Chips filter={exportFilter} />
										</>
									) : (
										<Typography>
											No filters selected
										</Typography>
									)}
								</Box>

								{!timeRangeWasSelected && (
									<Box className={classes.timeBox}>
										<MuiAlert
											severity="warning"
											className={classes.alert}
											classes={{
												icon: isTimeRangeSelected
													? classes.disabledIcon
													: "",
												standardWarning:
													isTimeRangeSelected
														? classes.disabledAlert
														: "",
											}}
										>
											<AlertTitle>
												Time range not selected, all
												events will be exported!
											</AlertTitle>
											Exporting could take a long time and
											return large amount of data.
											Consider selecting time range to
											filter out events.
										</MuiAlert>
										<Box className={classes.helpers}>
											{EXPORT_TIME_HELPERS.map(
												(helper) => (
													<Button
														onClick={() =>
															setExportFilter({
																...exportFilter,
																time: helper.label,
															})
														}
														key={helper.label}
														className={`${
															classes.helperButton
														} ${
															helper.label ===
															exportFilter?.time
																? classes.active
																: ""
														}`}
													>
														{helper.label}
													</Button>
												),
											)}
										</Box>
									</Box>
								)}

								<Checkboxes
									formGroupProps={{
										className: classes.fields,
									}}
									formControlProps={{
										className: classes.fieldsBox,
										margin: "none",
									}}
									name="fields"
									label="Export fields*"
									data={[
										{
											label: "Vehicle/Human",
											value: "vh",
										},
										{
											label: "Faces",
											value: "faces",
										},
										{
											label: "Alpr",
											value: "alpr",
										},
									]}
								/>
							</DialogContent>
							<DialogActions className={classes.action}>
								<Box className={classes.progressBox}>
									{isExporting && (
										<>
											<CircularProgress
												size={24}
												color="secondary"
											/>
											<Typography>
												<b>{loadedHits}</b> events
												exported
											</Typography>
										</>
									)}
								</Box>
								<Box className={classes.actionButtons}>
									<Button
										type="submit"
										disabled={isExporting || invalid}
									>
										Export
									</Button>
									<Button onClick={handleClose}>
										Cancel
									</Button>
								</Box>
							</DialogActions>
						</form>
					)}
				/>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={exportNotification.msg !== ""}
				autoHideDuration={6000}
				onClose={(_, reason) => {
					if (reason === "clickaway") return;
					setExportNotification({ ...exportNotification, msg: "" });
				}}
			>
				<div>
					<Alert
						onClose={() =>
							setExportNotification({
								...exportNotification,
								msg: "",
							})
						}
						alert={{
							variant: exportNotification.variant,
							message: exportNotification.msg,
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

export default ExportDialog;
