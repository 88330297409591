import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IResponse, PagedContent } from "../types";
import {
	ISubject,
	ISubjectsFilter,
	IEditSubjectBody,
	IEditSubjectQuery,
	ICreateSubjectBody,
} from "./types";
import { IQuerySecret, IWatchlistQueryParameters } from "../Watchlists/types";

export type SUBJECT =
	| "SUBJECTS"
	| "SUBJECT"
	| "SUBJECT_UPDATE_FILTER"
	| "SUBJECT_UPDATE";

export type SubjectsAction = AsyncAction<
	SUBJECT,
	PagedContent<ISubject[]> | ISubjectsFilter | ISubject,
	string
>;

export const getItems = async (
	watchlistId: string,
	request: IWatchlistQueryParameters,
	controller?: AbortController,
) => {
	if (request.size) {
		const result = await Axios.get<PagedContent<ISubject[]>>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}/items`,
			{ params: request, signal: controller?.signal },
		);
		return result.data;
	}
};

export const getItem = async (
	watchlistId: string,
	name: string,
	secret?: string,
	controller?: AbortController,
) => {
	try {
		const response = await Axios.get<ISubject>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}/items/${name}`,
			{ params: { secret }, signal: controller?.signal },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deleteSubject = async (
	watchlistId: string,
	subjectId: string,
	query: IQuerySecret,
) => {
	try {
		const response = await Axios.delete<IResponse>(
			API_BASE_PREFIX +
				`watchlists/items/${watchlistId}/items/${subjectId}`,
			{ params: query },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const editSubject = async (
	watchlistId: string,
	subjectId: string,
	body: IEditSubjectBody,
	query: IEditSubjectQuery,
) => {
	try {
		const response = await Axios.patch<IResponse>(
			API_BASE_PREFIX +
				`watchlists/items/${watchlistId}/items/${subjectId}`,
			body,
			{ params: query },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createSubject = async (
	watchlistId: string,
	body: ICreateSubjectBody,
) => {
	const { secret, ...requestBody } = body;
	try {
		const response = await Axios.post<IResponse>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}`,
			requestBody,
			{ params: { secret } },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function updateSubjectAction(
	watchlistId: string,
	subjectId: string,
	query: IQuerySecret,
) {
	return async (dispatch: (action: SubjectsAction) => void) => {
		try {
			const result = await getItem(watchlistId, subjectId, query.secret);
			if (result) {
				dispatch({
					meta: watchlistId,
					payload: result,
					status: AsyncActionStatus.SUCCEEDED,
					type: "SUBJECT_UPDATE",
				});
			}
		} catch (error) {
			dispatch({
				meta: watchlistId,
				status: AsyncActionStatus.FAILED,
				type: "SUBJECT_UPDATE",
				payload: error as Error,
			});
		}
	};
}

export function getItemAction(
	watchlistId: string,
	name: string,
	secret?: string,
	controller?: AbortController,
) {
	return async(
		"SUBJECT",
		getItem,
		watchlistId,
		watchlistId,
		name,
		secret,
		controller,
	);
}

export function getItemsAction(
	watchlistId: string,
	request?: IWatchlistQueryParameters,
	controller?: AbortController,
) {
	return async(
		"SUBJECTS",
		getItems,
		watchlistId,
		watchlistId,
		request,
		controller,
	);
}

export function setSubjectsFilter(
	watchlistId: string,
	filter: ISubjectsFilter,
): SubjectsAction {
	return {
		meta: watchlistId,
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "SUBJECT_UPDATE_FILTER",
	};
}
