import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import { IBackwardSearchResult } from "../../store/BackwardSearch/types";
import CanvasImage from "./CanvasImage";

const useStyles = makeStyles(() => ({
	paper: {
		padding: 10,
		width: "100%",
		maxWidth: "100%",
		height: "100%",
	},
	content: {
		display: "flex",
		justifyContent: "center",
		gap: 10,
		overflow: "hidden",
	},
	img: {
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
	},
	imgWrapper: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	divider: {
		color: "red",
		backgroundColor: THEME.palette.secondary.light,
	},
	notFound: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.grey[800],
		padding: 4,
		height: "100%",
		width: "100%",
	},
	flex: {
		flex: 1,
		display: "flex",
	},
}));

type Props = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	result: IBackwardSearchResult | undefined;
	eventImage: string | undefined;
	thumbIndex: number;
	setIndex: (index: number) => void;
};

const BackwardImagesDialog = ({
	isOpen,
	setIsOpen,
	eventImage,
	...props
}: Props) => {
	const classes = useStyles();

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			classes={{
				paper: classes.paper,
			}}
		>
			<DialogContent className={classes.content}>
				<>
					<div
						className={classes.flex}
						style={{
							justifyContent: "flex-end",
						}}
					>
						{eventImage ? (
							<div
								className={classes.imgWrapper}
								style={{ justifyContent: "flex-start" }}
							>
								<img
									src={`data:image/jpeg;base64,${eventImage}`}
									className={classes.img}
									style={{ height: "100%" }}
									alt="watchlist"
								/>
							</div>
						) : (
							<div className={classes.notFound}>
								Select event to see image
							</div>
						)}
					</div>
					<Divider
						orientation="vertical"
						classes={{
							root: classes.divider,
						}}
					/>
					<CanvasImage {...props} setIsOpen={setIsOpen} />
				</>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setIsOpen(false)} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default BackwardImagesDialog;
