import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { FACE_ATTRIBUTES_ICONS } from "../constants";
import { THEME } from "../../../config";
import { upperFirst } from "lodash";
import DetailItem from "./DetailItem";
import { faceAttributes } from "../../../helpers/Filter";
import { EventsFilter } from "../../../store/Events/types";
import { CONFIDENCE } from "../Filter/MoreFiltersItem";

const useStyles = makeStyles(() => ({
	root: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: THEME.palette.background.default,
		padding: 6,
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	detailsBox: {
		display: "flex",
		justifyContent: "center",
		gap: 4,
		padding: "4px 0",
	},
	icons: {
		height: 20,
		width: 20,
	},
	attribute: {
		display: "flex",
		gap: 2,
		alignItems: "center",
		whiteSpace: "nowrap",
	},
	dividerRight: {
		borderRight: `1px solid ${THEME.palette.grey[500]}`,
		paddingRight: 4,
	},
	attributesBox: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "4px",
	},
	boldText: {
		fontWeight: "bold",
	},
	title: {
		borderBottom: `1px solid ${THEME.palette.grey[500]}`,
		color: THEME.palette.secondary.main,
		fontWeight: "bold",
	},
}));

export const FaceDetails: React.FC<{
	data: any;
	filter: EventsFilter;
}> = ({ data, filter }) => {
	const { attributes, quality } = data;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography variant="h6" className={classes.title}>
				Face
			</Typography>
			<Box
				className={classes.detailsBox}
				style={{
					borderBottom: `1px solid ${THEME.palette.grey[500]}`,
				}}
			>
				{quality && (
					<DetailItem
						title="Quality"
						value={`${Math.round((quality ?? 0) * 100)}%`}
						justifyContent="center"
					/>
				)}
				{attributes?.age && (
					<DetailItem
						title="Age"
						value={`${
							attributes.age > 100 ? "N/A" : attributes.age
						}`}
						justifyContent="center"
					/>
				)}
				{attributes?.gender && (
					<DetailItem
						title={
							attributes.gender.confidence > 100
								? "Gender"
								: attributes.gender.value === 0
								? "Male"
								: "Female"
						}
						value={
							attributes.gender.confidence > 100
								? "N/A"
								: attributes.gender.confidence + "%"
						}
						justifyContent="center"
					/>
				)}
			</Box>
			<Box
				style={{
					display: "flex",
					flex: 1,
					gap: 8,
				}}
			>
				{Object.keys(attributes ?? {}).some((v) =>
					faceAttributes.includes(v),
				) && (
					<Box
						className={`${
							Object.keys(attributes.ethnicity ?? {})?.length >
								0 ||
							Object.keys(attributes.emotions ?? {})?.length > 0
								? classes.dividerRight
								: ""
						} ${classes.attributesBox}`}
					>
						<Box style={{ margin: "0 auto", width: "fit-content" }}>
							<Typography className={classes.boldText}>
								Attributes
							</Typography>
							{Object.keys(attributes)
								.sort((a, b) => {
									if (attributes[a].confidence > 100)
										return 1;
									if (attributes[b].confidence > 100)
										return -1;
									return (
										attributes[b].confidence -
										attributes[a].confidence
									);
								})
								.map((keyName: string, i) => {
									const upperKey = keyName.toUpperCase();
									if (!FACE_ATTRIBUTES_ICONS[upperKey])
										return null;

									const filterConfidence =
										filter?.moreFilters?.[keyName]
											?.confidence;

									const isConfidenceLow =
										attributes[keyName]?.confidence <
											(filterConfidence ??
												CONFIDENCE[keyName] ??
												50) ||
										attributes[keyName]?.confidence > 100;

									return (
										<Box
											className={classes.attribute}
											style={{
												opacity: isConfidenceLow
													? 0.5
													: 1,
											}}
											key={i}
										>
											<img
												src={
													FACE_ATTRIBUTES_ICONS[
														upperKey
													]
												}
												className={classes.icons}
												alt={`${FACE_ATTRIBUTES_ICONS[upperKey]}`}
											/>
											<Typography
												style={{
													fontWeight: "500",
													paddingRight: 2,
												}}
											>
												{upperFirst(keyName)}
											</Typography>
											<Typography>{`${
												attributes[keyName].confidence >
												100
													? "N/A"
													: `${attributes[keyName].confidence}%`
											} ${
												attributes[keyName]
													.confidence <= 100
													? attributes[keyName]
															.value === 0
														? "No"
														: "Yes"
													: ""
											}`}</Typography>
										</Box>
									);
								})}
						</Box>
					</Box>
				)}
				{Object.keys(attributes?.emotions ?? {})?.length > 0 && (
					<Box
						className={`${
							Object.keys(attributes.ethnicity)?.length > 0
								? classes.dividerRight
								: ""
						} ${classes.attributesBox}`}
					>
						<Box style={{ margin: "0 auto", width: "fit-content" }}>
							<Typography className={classes.boldText}>
								Emotions
							</Typography>
							{Object.keys(attributes.emotions ?? {})
								.sort((a, b) => {
									if (attributes.emotions[a] > 100) return 1;
									if (attributes.emotions[b] > 100) return -1;
									return (
										attributes.emotions[b] -
										attributes.emotions[a]
									);
								})
								.map((keyName: string, i) => {
									const isConfidenceLow =
										attributes?.emotions[keyName] <
											CONFIDENCE.emotions ||
										attributes?.emotions[keyName] > 100;

									return (
										<Box
											className={classes.attribute}
											style={{
												opacity: isConfidenceLow
													? 0.5
													: 1,
											}}
											key={i}
										>
											<Typography>
												{`${upperFirst(
													keyName
														.match(
															/.+?(?=Confidence)/,
														)
														?.toString(),
												)} ${
													attributes.emotions[
														keyName
													] > 100
														? "N/A"
														: attributes.emotions[
																keyName
														  ] + "%"
												}`}
											</Typography>
										</Box>
									);
								})}
						</Box>
					</Box>
				)}
				{Object.keys(attributes?.ethnicity ?? {})?.length > 0 && (
					<Box className={classes.attributesBox}>
						<Box style={{ margin: "0 auto", width: "fit-content" }}>
							<Typography className={classes.boldText}>
								Ethnicity
							</Typography>
							{Object.keys(attributes.ethnicity)
								.sort((a, b) => {
									if (attributes.ethnicity[a] > 100) return 1;
									if (attributes.ethnicity[b] > 100)
										return -1;
									return (
										attributes.ethnicity[b] -
										attributes.ethnicity[a]
									);
								})
								.map((keyName: string, i) => {
									const isConfidenceLow =
										attributes?.ethnicity[keyName] <
											CONFIDENCE.ethnicity ||
										attributes?.ethnicity[keyName] > 100;
									return (
										<Box
											className={classes.attribute}
											style={{
												opacity: isConfidenceLow
													? 0.5
													: 1,
											}}
											key={i}
										>
											<Typography>
												{`${upperFirst(
													keyName
														.match(
															/.+?(?=Confidence)/,
														)
														?.toString(),
												)} ${
													attributes.ethnicity[
														keyName
													] > 100
														? "N/A"
														: attributes.ethnicity[
																keyName
														  ] + "%"
												}`}
											</Typography>
										</Box>
									);
								})}
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};
