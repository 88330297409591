import React from "react";
import {
	IElasticHit,
	IElasticSource,
	IElasticSourceService,
} from "../../store/Logs/types";
import {
	Collapse,
	TableCell,
	TableRow,
	Typography,
	makeStyles,
	IconButton,
	Tooltip,
	Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config";
import { levelStyles } from "../../helpers/Logs";
import ReactHtmlParser from "react-html-parser";
import { FilterButtons } from "./ItemFilterButtons";

const useStyles = (severity: string) =>
	makeStyles(() => ({
		text: {
			fontFamily: "monospace",
			fontSize: 13,
		},
		textInfo: {
			fontFamily: "monospace",
		},
		level: {
			display: "inline-block",
			width: "60px",
			color: levelStyles(severity),
		},
		expandIcon: {
			transform: "rotate(270deg)",
			padding: "3px",
			marginRight: "5px",
		},
		expandBtn: { padding: "3px", marginRight: "5px" },
		filterBox: {
			minWidth: 100,
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
	}));

interface ILogsItemProps {
	log: IElasticHit;
	executeScroll: () => void;
}

const LogsItem: React.FC<ILogsItemProps> = ({ log, executeScroll }) => {
	const logSource = log._source;
	const [open, setOpen] = React.useState(false);
	const classes = useStyles(logSource.level as string)();
	const [hover, setHover] = React.useState(false);

	const highlightedMessage = log?.highlight?.message
		? ReactHtmlParser(log?.highlight?.message as string)
		: log?.highlight?.MESSAGE
		? ReactHtmlParser(log?.highlight?.MESSAGE as string)
		: null;

	const containerName = logSource.kubernetes?.annotations
		? logSource.kubernetes.annotations["sentiveillance_com/serviceName"]
		: null;

	return (
		<>
			<TableRow
				hover
				role="checkbox"
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<TableCell>
					<Box display="flex" alignItems="center">
						<Tooltip title={open ? "Collapse" : "Expand"}>
							<IconButton
								onClick={() => {
									setOpen(!open);
								}}
								className={
									open
										? classes.expandBtn
										: classes.expandIcon
								}
							>
								<ExpandMoreIcon />
							</IconButton>
						</Tooltip>
						<Typography className={classes.text}>
							{logSource["@timestamp"]
								? `${moment(logSource["@timestamp"]).format(
										DATE_TIME_FORMAT,
								  )} `
								: null}
						</Typography>
						<Box className={classes.filterBox}>
							{logSource.level &&
								typeof logSource.level === "string" && (
									<>
										<Typography className={classes.text}>
											|{" "}
											<span className={classes.level}>
												{logSource.level}
											</span>
										</Typography>
										{hover && (
											<FilterButtons
												value={
													logSource.level as string
												}
												type={"Severity"}
												executeScroll={executeScroll}
											/>
										)}
									</>
								)}
						</Box>

						<Box className={classes.filterBox}>
							{containerName && (
								<>
									<Typography className={classes.text}>
										<>
											| <span>{containerName}</span>
										</>
									</Typography>
									{hover && (
										<FilterButtons
											value={containerName}
											type={"Service"}
											executeScroll={executeScroll}
										/>
									)}
								</>
							)}
						</Box>
					</Box>

					<Typography className={classes.textInfo}>
						{
							(highlightedMessage ||
								logSource.message ||
								logSource.MESSAGE ||
								"") as string
						}
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={1}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<LogInfo source={logSource} />
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const LogInfo: React.FC<IElasticSource & IElasticSourceService> = ({
	source,
}) => {
	const keys = Object.keys(source) as Array<keyof typeof source>;

	return (
		<>
			{keys.map((field, i) => {
				if (
					source[field] !== null &&
					typeof source[field] === "object"
				) {
					return (
						<div key={i}>
							<Typography style={{ fontFamily: "monospace" }}>
								{field}:
							</Typography>
							<div style={{ marginLeft: 20 }}>
								<LogInfo source={source[field]} />
							</div>
						</div>
					);
				}
				return (
					<Typography key={i} style={{ fontFamily: "monospace" }}>
						{`${field}: ${source[field]}`}
					</Typography>
				);
			})}
		</>
	);
};

export default LogsItem;
