import React from "react";
import { DateTrigger } from "../../../../../store/Rules/types";
import {
	makeStyles,
	Chip,
	Popover,
	Grid,
	Button,
	Typography,
	Switch,
} from "@material-ui/core";
import { THEME } from "../../../../../config/index";
import { LANGUAGE } from "../../../../../constants";
import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { DateRange } from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	icon: {
		width: 20,
		height: 20,
		fill: THEME.palette.grey[800],
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 6,
	},
}));

interface IDateTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: DateTrigger): void;
	onCancel(): void;
	dateTrigger?: DateTrigger;
}

interface IDateTriggerProps {
	trigger: DateTrigger;
	updateTrigger(trigger: DateTrigger | undefined): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

export const getDateChipLabel = (
	trigger: DateTrigger,
	rowClass?: string,
	tagClass?: string,
) => {
	const fromDate = trigger.from
		? new Date(trigger.from).toLocaleString(LANGUAGE)
		: "";
	const toDate = trigger.to
		? new Date(trigger.to).toLocaleString(LANGUAGE)
		: "";
	return (
		<span className={rowClass}>
			Date{" "}
			{fromDate && (
				<>
					from <span className={tagClass}>{fromDate}</span>
				</>
			)}
			{toDate && (
				<>
					to <span className={tagClass}>{toDate}</span>
				</>
			)}
		</span>
	);
};

const DateTriggerComponent: React.FC<IDateTriggerProps> = ({
	trigger,
	updateTrigger,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: DateTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				icon={<DateRange className={classes.icon} />}
				label={getDateChipLabel(trigger, classes.row, classes.tag)}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<DateTriggerPopover
				dateTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
			/>
		</>
	);
};

const defaultTrigger: DateTrigger = {
	type: "DateTrigger",
	from: new Date().toISOString(),
	to: null,
};

export const DateTriggerPopover: React.FC<IDateTriggerPopoverProps> = ({
	anchorEl,
	onUpdate,
	onCancel,
	dateTrigger,
}) => {
	const classes = useStyles();
	const [trigger, setTrigger] = React.useState<DateTrigger>(
		dateTrigger ? dateTrigger : defaultTrigger,
	);

	React.useEffect(() => {
		setTrigger(dateTrigger ? dateTrigger : defaultTrigger);
	}, [anchorEl, dateTrigger]);

	const handleConfirm = () => {
		onUpdate(trigger);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleFromSwitch = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.from = trigger.from
			? null
			: dateTrigger && dateTrigger.from
			? dateTrigger.from
			: new Date().toISOString();
		setTrigger(changedTrigger);
	};

	const handleToSwitch = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.to = trigger.to
			? null
			: dateTrigger && dateTrigger.from
			? dateTrigger.from
			: new Date(
					new Date().setFullYear(new Date().getFullYear() + 1),
			  ).toISOString();
		setTrigger(changedTrigger);
	};

	const handleFromChange = (time: MaterialUiPickersDate | null) => {
		const date = new Date();
		date.getTime();
		const changedTrigger = { ...trigger };
		if (time === null) {
			changedTrigger.from = null;
		} else {
			if (isNaN(time.unix())) return;
			changedTrigger.from = time.toISOString();
		}
		setTrigger(changedTrigger);
	};

	const handleToChange = (time: MaterialUiPickersDate | null) => {
		const changedTrigger = { ...trigger };
		if (time === null) {
			changedTrigger.to = null;
		} else {
			if (isNaN(time.unix())) return;
			changedTrigger.to = time.toISOString();
		}
		setTrigger(changedTrigger);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			PaperProps={{ style: { width: 480 } }}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item container className={classes.popoverRow} xs={12}>
					<Grid item xs={2}>
						<Typography>From</Typography>
					</Grid>
					<Grid item xs={2}>
						<Switch
							size="small"
							checked={trigger.from !== null}
							onChange={handleFromSwitch}
						/>
					</Grid>
					<Grid item xs={8}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								ampm={false}
								variant="inline"
								format="YYYY-MM-DD HH:mm"
								id="from-date-picker"
								value={trigger.from}
								onChange={handleFromChange}
								disabled={trigger.from === null}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
				<Grid item container className={classes.popoverRow} xs={12}>
					<Grid item xs={2}>
						<Typography>To</Typography>
					</Grid>
					<Grid item xs={2}>
						<Switch
							size="small"
							checked={trigger.to !== null}
							onChange={handleToSwitch}
						/>
					</Grid>
					<Grid item xs={8}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								ampm={false}
								variant="inline"
								format="YYYY-MM-DD HH:mm"
								id="to-date-picker"
								value={trigger.to}
								onChange={handleToChange}
								disabled={trigger.to === null}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								trigger.from === null && trigger.to === null
							}
						>
							{dateTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default DateTriggerComponent;
