import {
	Box,
	Button,
	makeStyles,
	Typography,
	FormControlLabel,
	Checkbox,
	IconButton,
	Tooltip,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import { ArrowBackIosRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { THEME } from "../../config";
import { EventsFilter } from "../../store/Events/types";
import { getSearchRequestImage } from "../../store/ImageSearch/action";
import { ImageSearchRequest } from "../../store/ImageSearch/types";
import LinearBar from "../UI/LinearBar";
import SecretFormSelect from "../UI/SecretFormSelect";
import { Form } from "react-final-form";
import { MATCHING_THRESHOLD_LIMITS } from "./BackwardSearchForm";
import { TextField } from "mui-rff";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 12,
		padding: "8px 0",
		height: "100%",
		overflow: "hidden",
	},
	responsivePhoto: {
		objectFit: "contain",
		width: "100%",
		height: "100%",
	},
	box: {
		borderColor: THEME.palette.secondary.main,
		borderRadius: 4,
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-between",
		height: "80%",
		width: "100%",
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		flex: 1,
	},
	controls: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: 16,
	},
	inputs: { flex: 1, padding: "0 4px 0 14px" },
	input: {
		marginTop: 8,
	},
	imgAction: {
		width: "fit-content",
	},
	photosNav: {
		marginTop: 4,
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	content: {
		display: "flex",
		gap: 20,
	},
}));

interface ImageSearchFormProps {
	close(): void;
	onSubmit(
		searchRequest: Omit<ImageSearchRequest, "image"> & { images: string[] },
		secret?: string,
		multiple?: boolean,
	): Promise<void>;
	loading: boolean;
	onLoading(): void;
	canSeeSecrets: boolean;
	draggedImages: string[];
	uploadProgress: {
		loaded: number;
		total: number;
	};
}

const ImageSearchForm: React.FC<ImageSearchFormProps> = ({
	close,
	onSubmit,
	loading,
	onLoading,
	canSeeSecrets,
	draggedImages,
	uploadProgress,
}) => {
	const history = useHistory();
	const isUploading = uploadProgress.total > uploadProgress.loaded;
	const [images, setImages] = useState<string[]>([]);
	const [allowLowQuality, setAllowLowQuality] = useState<boolean>(false);
	const [secret, setSecret] = useState<EventsFilter["secret"] | undefined>(
		undefined,
	);
	const [matchingThreshold, setMatchingThreshold] = useState<number>(48);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const classes = useStyles();

	const handleSubmit = async () => {
		onLoading();
		onSubmit(
			{
				images,
				matchingThreshold: matchingThreshold,
				acceptLowQuality: allowLowQuality,
			},
			secret?.id,
			images.length > 1,
		);
	};

	const handlePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		setImages([]);
		setCurrentIndex(0);
		const target = e.target as HTMLInputElement;
		const files = target.files;

		if (files) {
			for (const image of files) {
				const reader = new FileReader();
				reader.onload = () => {
					const res = reader.result as string;
					setImages((prev) => [...prev, btoa(res)]);
				};
				reader.readAsBinaryString(image);
			}
		}
	};

	useEffect(() => {
		const state = history.location.state as
			| { [key: string]: any }
			| undefined;
		if (state) {
			getSearchRequestImage(state.id)
				.then((res) => {
					if (res.image) setImages([res.image]);
				})
				.catch(() => null);
			if ("secret" in state) setSecret({ id: state.secret as string });
			if ("matchingThreshold" in state)
				setMatchingThreshold(Number(state.matchingThreshold));
			history.replace(history.location.pathname, undefined);
		}
	}, [history]);

	useEffect(() => {
		if (draggedImages.length > 0) {
			setImages(draggedImages);
		}
	}, [draggedImages]);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={{ matchingThreshold: 48 }}
			render={({ handleSubmit, hasValidationErrors }) => (
				<form
					onSubmit={handleSubmit}
					autoComplete="off"
					className={classes.root}
				>
					<DialogContent className={classes.content}>
						<Box
							flex={1}
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<Box className={classes.box} border={1}>
								{images.length > 0 && (
									<img
										alt="ImageSearch"
										src={`data:image/jpeg;base64,${images[currentIndex]}`}
										className={classes.responsivePhoto}
									/>
								)}
							</Box>
							{images.length > 1 && (
								<Box className={classes.photosNav}>
									<IconButton
										onClick={() =>
											setCurrentIndex((prev) => prev - 1)
										}
										size="small"
										disabled={currentIndex === 0}
									>
										<ArrowBackIosRounded />
									</IconButton>
									<Typography variant="body1">
										{currentIndex + 1}/{images.length}
									</Typography>
									<IconButton
										onClick={() =>
											setCurrentIndex((prev) => prev + 1)
										}
										size="small"
										style={{ transform: "rotate(180deg)" }}
										disabled={
											currentIndex === images.length - 1
										}
									>
										<ArrowBackIosRounded />
									</IconButton>
								</Box>
							)}
							<Button
								component="label"
								className={classes.imgAction}
							>
								{"Open Image(s)"}
								<input
									hidden
									type="file"
									name="image"
									id="file"
									multiple
									accept=".tif, .tiff, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .jpb, .jpl, .png, .bmp, .dib, .webp"
									onChange={handlePhoto}
								/>
							</Button>
						</Box>
						<Box className={classes.controls}>
							<Box>
								<Typography variant="body1">
									Face parameters
								</Typography>
								<Tooltip title="Search low quality faces from provided image">
									<Box className={classes.inputs}>
										<FormControlLabel
											control={
												<Checkbox
													name="allowLowQuality"
													color="primary"
													checked={allowLowQuality}
													onChange={() =>
														setAllowLowQuality(
															!allowLowQuality,
														)
													}
												/>
											}
											label="Allow low quality"
										/>
									</Box>
								</Tooltip>
							</Box>
							<Box>
								<Typography variant="body1">
									Search options
								</Typography>
								<Box className={classes.inputs}>
									<TextField
										className={classes.input}
										name="matchingThreshold"
										fullWidth
										label="Matching threshold"
										type="number"
										value={matchingThreshold}
										fieldProps={{
											validate: (value) => {
												if (
													value <
														MATCHING_THRESHOLD_LIMITS.min ||
													value >
														MATCHING_THRESHOLD_LIMITS.max
												)
													return `Value must be between ${MATCHING_THRESHOLD_LIMITS.min} and ${MATCHING_THRESHOLD_LIMITS.max}`;

												if (!value)
													return "Field is required";
											},
										}}
										inputProps={{
											onChange: (e) =>
												setMatchingThreshold(
													parseInt(
														e.currentTarget.value,
													),
												),
										}}
									/>
									{canSeeSecrets && (
										<Tooltip title="Search results will be visible only to people assigned to selected secret">
											<Box>
												<SecretFormSelect
													onSecret={setSecret}
													value={secret}
												/>
											</Box>
										</Tooltip>
									)}
								</Box>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions>
						<Box className={classes.buttons}>
							{isUploading && (
								<Box
									textAlign="center"
									alignItems="center"
									width="40%"
									m="0 auto"
								>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										gridGap={3}
									>
										<Typography
											variant="body2"
											color="secondary"
										>
											Uploading..
										</Typography>
										<Typography
											variant="body2"
											style={{ fontWeight: "bold" }}
											color="secondary"
										>
											({uploadProgress.loaded} /{" "}
											{uploadProgress.total})
										</Typography>
									</Box>
									<LinearBar {...uploadProgress} />
								</Box>
							)}
							<Button
								type="submit"
								disabled={
									!images.length ||
									loading ||
									isUploading ||
									hasValidationErrors
								}
							>
								Search
							</Button>
							<Button onClick={close}>close</Button>
						</Box>
					</DialogActions>
				</form>
			)}
		/>
	);
};

export default ImageSearchForm;
