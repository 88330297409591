import React from "react";
import {
	makeStyles,
	Chip,
	Popover,
	Grid,
	Button,
	Typography,
	Select,
	MenuItem,
	FormControl,
	Input,
	Checkbox,
	ListItemText,
	Tooltip,
} from "@material-ui/core";
import { THEME } from "../../../../../config";
import { objects, colors, directions } from "./lists";
import { VehicleHumanTrigger } from "../../../../../store/Rules/types";
import Slider from "../../../../UI/Slider";
import { camelCaseToReadable } from "../../../../../helpers/Filter";
import svgToTsx from "../../../../../helpers/SvgToTsx";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		alignItems: "center",
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	lessBtn: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
	confidenceText: {
		justifyContent: "center",
		display: "flex",
		width: "50px",
	},
	icon: {
		width: 14,
		height: 14,
		fill: THEME.palette.grey[800],
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 8,
	},
}));

interface IVehicleHumanTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: VehicleHumanTrigger): void;
	onCancel(): void;
	vehicleHumanTrigger?: VehicleHumanTrigger;
	option: "DirectionTrigger" | "TypeTrigger" | "ColorTrigger";
}

interface IVehicleHumanTriggerComponentProps {
	trigger: VehicleHumanTrigger;
	updateTrigger(trigger?: VehicleHumanTrigger): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

export const getVehicleHumanChipLabel = (
	trigger: VehicleHumanTrigger,
	rowClass?: string,
	tagClass?: string,
	iconClass?: string,
) => {
	const overflowCount = 4;
	const isOverflowing = trigger.value.length > overflowCount;
	return (
		<span className={rowClass}>
			{trigger.type.replace("Trigger", "")}
			{trigger.value.length > 0
				? trigger.value.slice(0, overflowCount).map((t, i) => (
						<React.Fragment key={i}>
							<span className={tagClass}>
								{trigger.type === "ColorTrigger" ? (
									<div
										style={{
											backgroundColor: t,
											borderRadius: "50%",
											border: "1px solid black",
										}}
										className={iconClass}
									/>
								) : (
									svgToTsx(t, iconClass ?? "")
								)}
								{camelCaseToReadable(t)}
								{isOverflowing &&
									i === overflowCount - 1 &&
									"..."}
							</span>
						</React.Fragment>
				  ))
				: " 'Any' "}
			and confidence{" "}
			{trigger.less ? <span>&#60;</span> : <span>&#8805;</span>}{" "}
			{trigger.confidence}
		</span>
	);
};

const getVehicleHumanChipTooltip = (trigger: VehicleHumanTrigger) => {
	if (trigger.value) {
		return camelCaseToReadable(trigger.value.join(", "));
	} else return false;
};

const VehicleHumanTriggerComponent: React.FC<
	IVehicleHumanTriggerComponentProps
> = ({ trigger, updateTrigger, isReadOnly, showOnly }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: VehicleHumanTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				label={
					<Tooltip title={getVehicleHumanChipTooltip(trigger)}>
						<div>
							{getVehicleHumanChipLabel(
								trigger,
								classes.row,
								classes.tag,
								classes.icon,
							)}
						</div>
					</Tooltip>
				}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<VehicleHumanTriggerPopover
				anchorEl={anchorEl}
				vehicleHumanTrigger={trigger}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
				option={trigger.type}
			/>
		</>
	);
};

enum TriggerConfidence {
	"DirectionTrigger" = 30,
	"TypeTrigger" = 80,
	"ColorTrigger" = 50,
}

const getDefaultTrigger = (
	type: "DirectionTrigger" | "TypeTrigger" | "ColorTrigger",
): VehicleHumanTrigger => ({
	type: type,
	confidence: TriggerConfidence[type],
	less: false,
	value: [],
});

export const VehicleHumanTriggerPopover: React.FC<
	IVehicleHumanTriggerPopoverProps
> = ({ anchorEl, onUpdate, onCancel, vehicleHumanTrigger, option }) => {
	const classes = useStyles();

	const [trigger, setTrigger] = React.useState<VehicleHumanTrigger>(
		vehicleHumanTrigger ? vehicleHumanTrigger : getDefaultTrigger(option),
	);
	const [selectTooltipOpen, setSelectTooltipOpen] = React.useState(false);

	React.useEffect(() => {
		setTrigger(
			vehicleHumanTrigger
				? vehicleHumanTrigger
				: getDefaultTrigger(option),
		);
	}, [anchorEl, vehicleHumanTrigger, option]);

	const handleConfirm = () => {
		const newTr = { ...trigger };
		onUpdate(newTr);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		const changedTr = { ...trigger };
		let values = [...(event.target.value as string[])];
		if (values[values.length - 1] === "Any") {
			values = [];
		}
		changedTr.value = values;
		setTrigger(changedTr);
	};

	const handleLess = () => {
		const changedTr = { ...trigger };
		changedTr.less = !changedTr.less;
		setTrigger(changedTr);
	};

	const handleSlider = (event: any, newValue: number | number[]) => {
		const changedTr = { ...trigger };
		changedTr.confidence = newValue as number;
		setTrigger(changedTr);
	};

	const selectList =
		option === "DirectionTrigger"
			? directions
			: option === "TypeTrigger"
			? objects
			: colors;

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item container className={classes.popoverRow} xs={3}>
					<Grid item xs={7}>
						<Typography>{option.replace("Trigger", "")}</Typography>
					</Grid>
					<Grid item xs={5}>
						<FormControl style={{ minWidth: 80, maxWidth: 200 }}>
							<Tooltip
								title={trigger.value.join(", ")}
								open={selectTooltipOpen}
							>
								<Select
									multiple
									displayEmpty
									value={trigger.value}
									onChange={handleSelect}
									input={<Input />}
									MenuProps={{ variant: "menu" }}
									disabled={selectList.length === 0}
									renderValue={(selected) => (
										<Typography
											style={{
												width: 60,
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												overflow: "hidden",
											}}
										>
											{(selected as string[]).length > 0
												? (selected as string[]).join(
														", ",
												  )
												: "Any"}
										</Typography>
									)}
									onMouseEnter={() =>
										setSelectTooltipOpen(true)
									}
									onMouseLeave={() =>
										setSelectTooltipOpen(false)
									}
									onOpen={() => setSelectTooltipOpen(false)}
								>
									<MenuItem key="Any" value="Any" dense>
										<Checkbox
											checked={trigger.value.length === 0}
										/>
										<ListItemText primary="Any" />
									</MenuItem>
									{selectList.map((item) => (
										<MenuItem key={item} value={item} dense>
											<Checkbox
												checked={trigger.value.includes(
													item,
												)}
											/>
											<ListItemText primary={item} />
										</MenuItem>
									))}
								</Select>
							</Tooltip>
						</FormControl>
					</Grid>
				</Grid>
				<Grid item container className={classes.popoverRow} xs={9}>
					<Grid item xs={4}>
						<Typography>when confidence</Typography>
					</Grid>
					<Grid item xs={2}>
						<Button
							onClick={handleLess}
							size="small"
							className={classes.lessBtn}
						>
							{trigger.less ? "<" : "≥"}
						</Button>
					</Grid>
					<Grid item xs={2} className={classes.confidenceText}>
						<Typography>{trigger.confidence}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Slider
							value={trigger.confidence}
							onChange={handleSlider}
							reverse={trigger.less}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button onClick={handleConfirm}>
							{vehicleHumanTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default VehicleHumanTriggerComponent;
