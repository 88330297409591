import React from "react";
import { ISubject } from "../../../store/Subjects/types";
import { Grid } from "@material-ui/core";
import LicensePlateItem from "./LicensePlateItem";
import SubjectsAreEmpty from "../SubjectsAreEmpty";

const includesThumb = (data: ISubject[]) => {
	return data.some((subject) => {
		return subject.thumb !== null;
	});
};

interface ILicensePlateSubjectsListProps {
	subjects: ISubject[];
	selected: number;
	onSelected: (index: number) => void;
	onDoubleClick(option: string): void;
}

const LicensePlateSubjectsList: React.FC<ILicensePlateSubjectsListProps> = ({
	subjects,
	selected,
	onSelected,
	onDoubleClick,
}) => {
	return (
		<Grid container style={{ padding: 4, rowGap: 4 }}>
			{subjects.length > 0 ? (
				subjects.map((sub, i) => (
					<LicensePlateItem
						key={i}
						subject={sub}
						index={i}
						hasThumb={includesThumb(subjects)}
						selected={selected}
						onSubject={onSelected}
						onDblClick={onDoubleClick}
					/>
				))
			) : (
				<SubjectsAreEmpty msg="Watchlist is empty" />
			)}
		</Grid>
	);
};

export default LicensePlateSubjectsList;
