import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IgetTasksRequest, ITask } from "./types";
import { PagedContent } from "../types";

export type Tasks = "Tasks" | "Tasks_Filter";
export type TasksAction = AsyncAction<
	Tasks,
	PagedContent<ITask[]> | ITask,
	string
>;

const getTasks = async (requestParams: IgetTasksRequest) => {
	try {
		const response = await Axios.get(API_BASE_PREFIX + `tasks`, {
			params: requestParams,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getTasksAction(requestParams: IgetTasksRequest) {
	return async("Tasks", getTasks, "", requestParams);
}

export function setTasksFilterAction(filter: IgetTasksRequest) {
	return {
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Tasks_Filter",
	};
}
