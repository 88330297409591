import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Context } from "../../containers/Logs/LogsContext";
import _ from "lodash";

interface Props {
	value: string;
	type: string;
	executeScroll: () => void;
}

export const FilterButtons: React.FC<Props> = ({
	value,
	type,
	executeScroll,
}) => {
	const { setService, setSeverity, not, setNot, service, severity } =
		React.useContext(Context);

	const addFilter = () => {
		if (type === "Service") {
			setService([...service, value]);
		} else {
			setSeverity(value);
		}
		executeScroll();
	};

	const removeFilter = () => {
		setNot([...not, { [type]: value }]);
		executeScroll();
	};

	const exists = _.includes(service, value) || severity === value;

	return !exists ? (
		<>
			<Tooltip title="Filter for value">
				<IconButton onClick={addFilter} size="small">
					<AddCircleOutlineIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Filter out value">
				<IconButton onClick={removeFilter} size="small">
					<RemoveCircleOutlineIcon />
				</IconButton>
			</Tooltip>
		</>
	) : (
		<></>
	);
};
