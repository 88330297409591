import EAST from "./assets/east.svg";
import NORTH from "./assets/north.svg";
import WEST from "./assets/west.svg";
import SOUTH from "./assets/south.svg";
import NORTH_EAST from "./assets/northEast.svg";
import NORTH_WEST from "./assets/northWest.svg";
import SOUTH_EAST from "./assets/southEast.svg";
import SOUTH_WEST from "./assets/southWest.svg";

import BIKE from "./assets/bike.svg";
import CAR from "./assets/sedan-car.svg";
import BUS from "./assets/bus-symbol.svg";
import TRUCK from "./assets/logistic-truck.svg";
import PERSON from "./assets/man-woman.svg";

import GLASSES from "../../assets/Svgs/glasses.svg";
import BEARD from "../../assets/Svgs/beard.svg";
import DARK_GLASSES from "../../assets/Svgs/darkGlasses.svg";
import MUSTACHE from "../../assets/Svgs/mustache.svg";
import MASK from "../../assets/Svgs/mask.svg";
import HAT from "../../assets/Svgs/hat.svg";

export type IconsObj = {
	[key: string]: string;
};

export const DIRECTION_ICONS: IconsObj = {
	EAST: EAST,
	NORTH: NORTH,
	WEST: WEST,
	SOUTH: SOUTH,
	NORTHEAST: NORTH_EAST,
	NORTHWEST: NORTH_WEST,
	SOUTHEAST: SOUTH_EAST,
	SOUTHWEST: SOUTH_WEST,
};

export const OBJECT_TYPE_ICONS: IconsObj = {
	BIKE: BIKE,
	CAR: CAR,
	BUS: BUS,
	TRUCK: TRUCK,
	PERSON: PERSON,
};

export const FACE_ATTRIBUTES_ICONS: IconsObj = {
	BEARD: BEARD,
	DARKGLASSES: DARK_GLASSES,
	GLASSES: GLASSES,
	HAT: HAT,
	MASK: MASK,
	MUSTACHE: MUSTACHE,
};

export const INITIAL_OPEN_GROUPS: { [key: string]: boolean }[] = [
	{ general: true },
	{ time: true },
	{ watchlistSubject: true },
	{ objectAttributes: true },
	{ faceAttributes: true },
	{ favorites: true },
	{ history: true },
];
