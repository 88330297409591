import React from "react";
import { Dialog } from "@material-ui/core";
import { ImageSearchRequest } from "../../store/ImageSearch/types";
import ImageSearchForm from "../Forms/ImageSearchForm";
import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import Title from "./Common/Title";

interface ImageSearchDialogProps {
	open: boolean;
	close(): void;
	onSubmit(
		searchRequest: Omit<ImageSearchRequest, "image"> & { images: string[] },
		secret?: string,
		multiple?: boolean,
	): Promise<void>;
	loading: boolean;
	onLoading(): void;
	canSeeSecrets: boolean;
	draggedImages: string[];
	uploadProgress: {
		loaded: number;
		total: number;
	};
}

const useStyles = makeStyles(() => ({
	dialog: {
		overflow: "hidden",
		maxWidth: "50%",
		height: "60vh",
		[THEME.breakpoints.up("xl")]: {
			height: "50vh",
		},
	},
}));

const ImageSearchDialog: React.FC<ImageSearchDialogProps> = ({
	open,
	close,
	onSubmit,
	loading,
	onLoading,
	canSeeSecrets,
	draggedImages,
	uploadProgress,
}) => {
	const classes = useStyles();
	return (
		<Dialog
			open={open}
			fullWidth
			style={{ overflow: "hidden" }}
			classes={{ paper: classes.dialog }}
			onClose={close}
		>
			<Title title="Search" onClose={close} />
			<ImageSearchForm
				close={close}
				onSubmit={onSubmit}
				loading={loading}
				onLoading={onLoading}
				canSeeSecrets={canSeeSecrets}
				draggedImages={draggedImages}
				uploadProgress={uploadProgress}
			/>
		</Dialog>
	);
};

export default ImageSearchDialog;
