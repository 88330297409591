import { useDispatch, useSelector } from "react-redux";
import { getVMSAction } from "../store/VMS/action";
import { AppState } from "../store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getSourceAction } from "../store/Sources/action";
import { ISource } from "../store/Sources/types";

const useAllSources = ({ enabled = true } = {}) => {
	const dispatch = useDispatch();
	const isVmsLoaded = useRef(false);
	const vmsList = useSelector((state: AppState) => state.vms.data.content);
	const sourceKeys = useSelector((state: AppState) => state.sources.keys);
	const sourcesData: ISource[] = useMemo(
		() =>
			Object.keys(sourceKeys)
				.map((vmsKey) =>
					(sourceKeys[vmsKey]?.content || []).map((s) => ({
						...s,
						vmsName: vmsKey,
					})),
				)
				.flat(),
		[sourceKeys],
	);

	const getVms = useCallback(async () => {
		return getVMSAction({ size: 500 })(dispatch);
	}, [dispatch]);

	useEffect(() => {
		if (!enabled) return;
		if (!isVmsLoaded.current) {
			isVmsLoaded.current = true;
			getVms();
		}

		vmsList.forEach((vm) => {
			if (!sourceKeys[vm.name] && vm.name)
				getSourceAction(vm.name, { size: 500 })(dispatch);
		});
	}, [dispatch, getVms, vmsList, sourceKeys, enabled]);

	return sourcesData;
};

export default useAllSources;
