import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { ISourceState, SourceDataContainer, ISource } from "./types";
import { SourceAction } from "./action";

export const initialSourceState: ISourceState = {
	keys: {},
	paging: {},
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleAsync = (
	state: ISourceState = initialSourceState,
	action: SourceAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				const payload = action.payload as SourceDataContainer;
				nState.keys = {
					...nState.keys,
					[action.meta]: {
						...payload,
						content: [
							...(nState.keys[action.meta]?.content ?? []),
							...payload.content.map((c) => ({
								...c,
								vmsName: action.meta,
							})),
						].filter(
							(v, i, a) =>
								a.findIndex((t) => t.id === v.id) === i,
						),
					},
				};

				if (!nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						name: "",
						page: 0,
						size: 25,
						status: "",
					};
				}
			}
			return nState;
	}
	return state;
};

const hanldeFilter = (
	state: ISourceState = initialSourceState,
	action: SourceAction,
) => {
	if (action.type === "SOURCE_UPDATE_FILTER") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			if (action.meta) {
				if (nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						...nState.paging[action.meta],
						...action.payload,
					};
				} else {
					nState.paging[action.meta] = {
						name: "",
						page: 0,
						size: 25,
						status: null,
						...action.payload,
					};
				}
				return nState;
			}
		}
	}

	return state;
};

const handleUpdate = (
	state: ISourceState = initialSourceState,
	action: SourceAction,
) => {
	if (action.type === "SOURCE_LOCAL_DELETE") {
		const nState = { ...state };

		if (action.status === "SUCCEEDED") {
			if (action.meta) {
				const removingSource = action.payload as ISource;
				const container = nState.keys[action.meta];
				if (container && removingSource) {
					const newContent = container.content.filter(
						(src) => src.displayName !== removingSource.displayName,
					);
					container.content = newContent;
					container.totalElements = newContent.length;
					nState.keys[action.meta] = container;
				}
				return nState;
			}
		}
	}

	if (action.type === "SOURCE_LOCAL_UPDATE") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			if (Array.isArray(action.payload)) {
				if (action.meta) {
					const vms = nState.keys[action.meta];
					if (!vms) {
						nState.keys[action.meta] = {
							content: action.payload.map((c) => ({
								...c,
								vmsName: action.meta,
							})),
							number: 0,
							numberOfElements: action.payload.length,
							size: 25,
							totalElements: action.payload.length,
							totalPages: 1,
						};
						return nState;
					} else {
						nState.keys[action.meta] = {
							...vms,
							content: action.payload.map((c) => ({
								...c,
								vmsName: action.meta,
							})),
						};
						return nState;
					}
				}
				return nState;
			}
			if (action.meta) {
				const newSource = {
					...action.payload,
					vmsName: action.meta,
				} as ISource;
				const content = nState.keys[action.meta].content;

				const oldSourceIndex = content.findIndex(
					(oldSrc) => oldSrc.id === newSource.id,
				);
				if (oldSourceIndex >= 0) {
					content[oldSourceIndex] = newSource;
				} else {
					content.push(newSource);
				}
				nState.keys[action.meta].content = content;
			}
			return nState;
		}
	}

	if (action.type === "SOURCE_UPDATE") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			if (action.meta) {
				const payload = {
					...action.payload,
					vmsName: action.meta,
				} as ISource;
				const container = nState.keys[action.meta];
				if (container) {
					const item = container.content.findIndex(
						(i) => i.id === payload.id,
					);
					if (item > -1) {
						container.content[item] = payload;
					}
					nState.keys[action.meta] = container;
				}
				return nState;
			}
		}
	}

	return state;
};

export const SourceReducer: Reducer<ISourceState, SourceAction> = (
	state = initialSourceState,
	action: SourceAction,
) => {
	switch (action.type) {
		case "SOURCE":
			return handleAsync(state, action);
		case "SOURCE_UPDATE_FILTER":
			return hanldeFilter(state, action);
		case "SOURCE_UPDATE":
			return handleUpdate(state, action);
		case "SOURCE_LOCAL_UPDATE":
			return handleUpdate(state, action);
		case "SOURCE_LOCAL_DELETE":
			return handleUpdate(state, action);
		default:
			return state;
	}
};
