import React from "react";
import { Icon, makeStyles } from "@material-ui/core";
import glasses from "../../assets/Svgs/glasses.svg";
import beard from "../../assets/Svgs/beard.svg";
import darkGlasses from "../../assets/Svgs/darkGlasses.svg";
import mustache from "../../assets/Svgs/mustache.svg";
import mask from "../../assets/Svgs/mask.svg";
import licensePlate from "../../assets/Svgs/licensePlate.svg";

const useStyles = makeStyles(() => ({
	imageIcon: {
		display: "flex",
		height: "inherit",
		width: "inherit",
	},
	iconRoot: {
		textAlign: "center",
	},
	lp: {
		display: "flex",
		height: "inherit",
		width: "inherit",
		transform: "scale(0.70,1)",
	},
}));

export const DarkGlassesIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={darkGlasses} alt="" className={classes.imageIcon} />
		</Icon>
	);
};

export const GlassesIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={glasses} alt="" className={classes.imageIcon} />
		</Icon>
	);
};

export const MustacheIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={mustache} alt="" className={classes.imageIcon} />
		</Icon>
	);
};

export const BeardIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={beard} alt="" className={classes.imageIcon} />
		</Icon>
	);
};

export const MaskIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={mask} alt="" className={classes.imageIcon} />
		</Icon>
	);
};

export const LicensePlateIcon = () => {
	const classes = useStyles();
	return (
		<Icon className={classes.iconRoot}>
			<img src={licensePlate} alt="" className={classes.lp} />
		</Icon>
	);
};
