export interface IHttpError {
	timestamp: number;
	status: number;
	error: string;
	message: string;
}

export class HttpError extends Error implements IHttpError {
	timestamp: number;
	status: number;
	error: string;
	constructor(
		timestamp: number,
		status: number,
		error: string,
		message: string,
	) {
		super(message);
		Object.setPrototypeOf(this, new.target.prototype);
		this.name = HttpError.name;
		this.timestamp = timestamp;
		this.status = status;
		this.error = error;
		this.message = message;
	}
}
