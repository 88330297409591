import AsyncState, { AsyncAction } from "../AsyncState";
import { IFavorite } from "../../components/Events/Filter/Favorites";
import { TCamera } from "../LiveCameras/types";
import { PagedContent } from "../types";

export type Configs =
	| "GET_CONFIG"
	| "UPDATE_CONFIG"
	| "ADD_CONFIG"
	| "DELETE_CONFIG";

export type ConfigActions = AsyncAction<
	Configs,
	boolean | string | IConfigResponse | IConfigState["data"] | IConfig | string
>;

export interface IConfigBase {
	id: string;
	name: string;
}

export enum BackwardBoxes {
	GALLERY,
	PROBE,
	VIDEO,
}

export enum ProbeChoice {
	ORIGINAL = 0,
	THUMB = 1,
}

export interface IPreference extends IConfigBase {
	groups: { [key: string]: boolean }[];
	showFavoriteFilters: boolean;
	favorites: { id: string }[];
	savedInServer: boolean;
	lastViewedReportTitle: string;
	backwardSortByScore?: boolean;
	backwardAcceptLowQuality?: boolean;
	backwardBoxesOrder?: BackwardBoxes[];
	probeChoice?: ProbeChoice;
}

export interface ILayoutConfig extends IConfigBase {
	cameras: (TCamera | null)[];
	areas: { x: number; y: number; width: number; height: number }[];
	viewsOrder: {
		[EventsViewSide.LEFT]: string[];
		[EventsViewSide.RIGHT]: string[];
	};
	saved?: boolean;
}

export enum EventsViewSide {
	LEFT = "left",
	RIGHT = "right",
}

export interface IEventsViewConfig extends IConfigBase {
	id: string;
	layoutId: string;
	enabledVms: string[] | undefined;
	enabledSources: string[] | undefined;
	enabledRules: string[] | undefined;
	enabledAll?: boolean;
	separateEvents?: boolean;
	listTitle?: string;
	maxEventsLimit: number | undefined;
	saved?: boolean;
}

export interface ILiveCamerasConfig extends IConfigBase {
	selectedLayoutId: string;
}

export interface IConfig {
	config:
		| IFavorite
		| IPreference
		| ILiveCamerasConfig
		| ILayoutConfig
		| IEventsViewConfig;
	variant:
		| "favorite"
		| "history"
		| "preference"
		| "live"
		| "layouts"
		| "eventsViews";
}

export type IConfigResponse = PagedContent<{ key: string; value: string }[]>;

export interface IConfigState extends AsyncState {
	data: {
		favorite: IFavorite[];
		history: IFavorite[];
		preference: IPreference;
		live: ILiveCamerasConfig | undefined;
		layouts: ILayoutConfig[];
		eventsViews: IEventsViewConfig[];
	};
	existsInServer: boolean;
	loaded: boolean;
}
