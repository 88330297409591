import React from "react";
import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { formatLength } from "../../../helpers/Utils";

const useStyles = makeStyles(() => ({
	metadata: {
		overflow: "auto",
		maxHeight: "9.160125rem",
		padding: 5,
	},
	nested: {
		marginLeft: 5,
	},
}));

interface ILPMDProps {
	parsedMeta: any;
}

const LicensePlateMetadata: React.FC<ILPMDProps> = ({ parsedMeta }) => {
	const classes = useStyles();
	const metadataKeys = Object.keys(parsedMeta);
	return (
		<div className={classes.metadata}>
			{metadataKeys.map((field, i) => {
				if (
					parsedMeta[field] !== null &&
					typeof parsedMeta[field] === "object"
				) {
					return (
						<div key={i}>
							<Typography>{field}:</Typography>
							<div className={classes.nested}>
								<LicensePlateMetadata
									parsedMeta={parsedMeta[field]}
								/>
							</div>
						</div>
					);
				}
				return (
					<Grid container justifyContent="space-between" key={i}>
						<Hidden smUp>
							<Grid item xs={5}>
								<Typography>{field}</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography>
									{formatLength(parsedMeta[field], 11)}
								</Typography>
							</Grid>
						</Hidden>
						<Hidden only="xs">
							<Grid item xs={4}>
								<Typography>{field}</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography>
									{formatLength(parsedMeta[field], 18)}
								</Typography>
							</Grid>
						</Hidden>
					</Grid>
				);
			})}
		</div>
	);
};

export default LicensePlateMetadata;
