import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import {
	ICreateSubject,
	ICreateSubjectBody,
} from "../../../store/Subjects/types";
import PlateCreateForm from "../../Forms/SubjectForms/PlateCreateForm";
import Title from "../Common/Title";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

interface ICreatePlate {
	open: boolean;
	onConfirm(body: ICreateSubjectBody): void;
	onClose(): void;
	secret?: string;
	schema: IMetadataSchema;
	metadata: IMetadata | null;
	onLoading(): void;
	loading: boolean;
}

const SubjectPlateCreateDialog: React.FC<ICreatePlate> = ({
	open,
	onConfirm,
	onClose,
	secret,
	schema,
	metadata,
	onLoading,
	loading,
}) => {
	const handleConfirm = (plateInfo: ICreateSubject) => {
		const body: ICreateSubjectBody = {
			id: plateInfo.id,
			metadata: plateInfo.metadata,
			image: plateInfo.image,
			origin: plateInfo.origin,
			secret: secret,
			acceptLowQuality: false,
		};
		onConfirm(body);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
			<Title title="Add License Plate Subject" onClose={onClose} />
			<DialogContent>
				<PlateCreateForm
					onConfirm={handleConfirm}
					onClose={onClose}
					schema={schema}
					initialMetadata={metadata}
					onLoading={onLoading}
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default SubjectPlateCreateDialog;
