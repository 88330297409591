import React, { useEffect, useRef, useState } from "react";
import {
	Divider,
	Snackbar,
	Tooltip,
	TextField,
	debounce,
} from "@material-ui/core";
import { HttpError } from "../../config/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import Alert from "./Alert";
import { Autocomplete } from "@material-ui/lab";
import { getRulesAction } from "../../store/Rules/action";
import { IgetRulesRequest, IRule } from "../../store/Rules/types";

interface IRulesViewPropsToState {
	error?: HttpError;
	allRules: IRule[];
	allVmsNames: string[];
}

interface IRulesViewPropsToDispatch {
	loadRules(vmsName: string, query: IgetRulesRequest): void;
}

interface IRulesFormSelectOwnProps {
	secret: string;
	value: Partial<IRule> | undefined;
	vmsName: string | undefined;
	tooltipTitle?: string;
	onRule(rule: Partial<IRule> | undefined): void;
}

interface IRulesFormSelectProps
	extends IRulesViewPropsToState,
		IRulesFormSelectOwnProps,
		IRulesViewPropsToDispatch {
	className?: string;
}

const _RulesFormSelect: React.FC<IRulesFormSelectProps> = ({
	vmsName,
	value,
	secret,
	error,
	loadRules,
	tooltipTitle,
	onRule,
	className,
	allRules,
	allVmsNames,
}) => {
	const options = (
		allRules && secret
			? allRules
			: allRules.filter((rule) => !rule.secret) ?? []
	).filter((item) => (vmsName ? item.vms === vmsName : true));

	const [search, setSearch] = useState("");
	const [firstLoad, setFirstLoad] = React.useState(true);

	const debouncedSearch = useRef(
		debounce((searchValue: string, rules: IRule[], vmsNames: string[]) => {
			vmsNames.forEach((name) => {
				if (
					!rules.some(
						(rule) =>
							rule.vms === name &&
							rule.name.includes(searchValue),
					)
				) {
					loadRules(name, {
						phrase: searchValue,
					});
				}
			});
		}, 700),
	);

	React.useEffect(() => {
		if (allRules.length === 0 && allVmsNames.length > 0 && firstLoad) {
			setFirstLoad(false);
			allVmsNames.forEach((name) => {
				loadRules(name, {});
			});
		}
	}, [loadRules, allRules, allVmsNames, firstLoad]);

	const handleRulesInput = (
		_event: React.ChangeEvent<{}>,
		value: Partial<IRule> | null,
	) => {
		if (value !== null) {
			onRule({
				vms: value?.vms ?? "",
				name: value?.name ?? "",
				secret: value?.secret ?? "",
			});
		} else {
			onRule(undefined);
		}
	};

	useEffect(() => {
		if (
			value &&
			allRules.some((r) => r.secret === value.secret) &&
			!options.some((o) => o.name === value?.name)
		) {
			onRule(undefined);
		}
	}, [options, onRule, value, allRules]);

	useEffect(() => {
		if (search) {
			debouncedSearch.current(search, allRules, allVmsNames);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	return (
		<>
			<Autocomplete
				noOptionsText={"No Rules found."}
				options={options}
				getOptionSelected={(option, value) =>
					option.name === value.name
				}
				getOptionLabel={(option) => option.name ?? ""}
				groupBy={(option) => option.vms ?? "No VMS"}
				inputValue={search}
				onInputChange={(_e, newInputValue) =>
					setSearch(() => newInputValue)
				}
				renderOption={(s) => (
					<div
						style={{
							display: "inline",
							maxHeight: 300,
						}}
					>
						<b>{s.name}</b>
						<br />
						<Divider />
					</div>
				)}
				onChange={handleRulesInput}
				value={
					value && options.some((o) => o.name === value.name)
						? value
						: null
				}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						name="rule"
						id="rule-search"
						className={className}
						label={
							tooltipTitle ? (
								<Tooltip
									title={tooltipTitle}
									placement="bottom-start"
								>
									<span>Rule</span>
								</Tooltip>
							) : (
								"Rule"
							)
						}
					/>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state: AppState): IRulesViewPropsToState => {
	return {
		error: state.vms.erorr,
		allRules: Object.values(state.rules.keys).reduce(
			(acc, val) => acc.concat(val?.content ?? []),
			[] as IRule[],
		),
		allVmsNames: Object.keys(state.vms.keys),
	};
};
const mapDispatchToProps = (dispatch: any): IRulesViewPropsToDispatch => {
	return {
		loadRules: (vmsName: string, query: IgetRulesRequest) =>
			getRulesAction(vmsName, query)(dispatch),
	};
};

const RulesFormSelect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(_RulesFormSelect);
export default RulesFormSelect;
