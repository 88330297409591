import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { Status } from "../../store/VMS/types";

interface IVMSStatusProps {
	status: Status | null;
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: "24px",
		height: "24px",
		display: "flex",
	},
	dot: {
		backgroundColor: "grey",
		width: "20px",
		height: "20px",
		margin: "2px",
		borderRadius: "50%",
		display: "inline-block",
	},
	ok: {
		backgroundColor: theme.palette.success.light,
	},
	error: {
		backgroundColor: theme.palette.error.light,
	},
}));

const VMSStatus: React.FC<IVMSStatusProps> = ({ status }) => {
	const classes = useStyles();
	let statusClass = "";
	switch (status) {
		case "Failed":
			statusClass = classes.error;
			break;
		case "Ok":
			statusClass = classes.ok;
			break;
		default:
			statusClass = "";
	}
	return (
		<div className={classes.container}>
			<div className={[classes.dot, statusClass].join(" ")}></div>
		</div>
	);
};

export default VMSStatus;
