import { IElasticEvents, IElasticHit, IEventsState } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { EventsAction } from "./action";

export const initialEventsState: IEventsState = {
	data: null,
	isAuthenticated: false,
	authorization: "",
	requestStatus: {},
	controllersHistory: [],
	favorites: [],
	cameras: {},
	status: AsyncActionStatus.UNSTARTED,
};

const addToHistory = (state: IEventsState, action: EventsAction) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const nControllersHistory = [...nState.controllersHistory];
			const nController = action.payload;
			nControllersHistory.push(nController as any);
			nState.controllersHistory = nControllersHistory;
			return nState;
		}
	}
	return state;
};

const handleAsync = (
	state: IEventsState = initialEventsState,
	action: EventsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			nState.data = action.payload as IElasticEvents;
			return nState;
		}
	}
	return state;
};

const handleScroll = (
	state: IEventsState = initialEventsState,
	action: EventsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const nEvents = action.payload as IElasticEvents;
			if (nState.data) {
				nState.data.hits.hits = nState.data.hits.hits.concat(
					nEvents.hits.hits,
				);
				if (nState.data.hits.max_score < nEvents.hits.max_score) {
					nState.data.hits.max_score = nEvents.hits.max_score;
				}
				nState.data.hits.total.value += nEvents.hits.total.value;
				nState.data.timed_out = nEvents.timed_out;
				nState.data.took += nEvents.took;
				nState.data._shards.total += nEvents._shards.total;
				nState.data._shards.successful += nEvents._shards.successful;
				nState.data._shards.skipped += nEvents._shards.skipped;
				nState.data._shards.failed += nEvents._shards.failed;

				return nState;
			}
		}
	}
	return state;
};

const addToSequences = (
	state: IEventsState = initialEventsState,
	action: EventsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const payload = action.payload as any;
			const nState = { ...state };
			const camera = nState.cameras[payload.cameraId];
			if (camera) {
				camera.sequences = camera.sequences.concat(payload.sequences);
			} else {
				nState.cameras[payload.cameraId] = {
					sequences: payload.sequences ?? [],
				};
			}
			return nState;
		}
	}
	return state;
};

const updateEvent = (
	state: IEventsState = initialEventsState,
	action: EventsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const payload = action.payload as IElasticHit;
			const nEvents = nState.data?.hits.hits.map((event) => {
				if (event._id === payload._id) {
					return payload;
				}
				return event;
			});
			if (nState.data) {
				nState.data = {
					...nState.data,
					hits: {
						...nState.data.hits,
						hits: nEvents ?? [],
					},
				};
			}
			return nState;
		}
	}
	return state;
};

const resetHistory = (state: IEventsState) => {
	const nState = { ...state };
	nState.controllersHistory.forEach((controller) => {
		if (typeof controller.close !== "string") controller.close();
		XPMobileSDK.closeStream(controller.videoId);
	});
	nState.controllersHistory = [];
	return nState;
};

export const EventsReducer: Reducer<IEventsState, EventsAction> = (
	state = initialEventsState,
	action: EventsAction,
) => {
	switch (action.type) {
		case "EVENTS":
			return handleAsync(state, action);
		case "EVENTS_SCROLL":
			return handleScroll(state, action);
		case "ADD_TO_HISTORY":
			return addToHistory(state, action);
		case "RESET_HISTORY":
			return resetHistory(state);
		case "ADD_TO_SEQUENCES":
			return addToSequences(state, action);
		case "UPDATE_EVENT":
			return updateEvent(state, action);
		default:
			return state;
	}
};
