import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_ELASTICSEARCH_EVENTS } from "../../config/axios";
import {
	IEventsContainer,
	IElasticHit,
	EventsFilter,
	AdditionalFilterItem,
	MoreFiltersQuery,
	IFieldExist,
	ISequence,
} from "./types";
import * as Lists from "../../components/Forms/RuleForm/Triggers/VehicleHuman/lists";
import { lowerFirst } from "lodash";
import { SPECIALDATE } from "../../components/Logs/constants";
import moment from "moment";

export type Events =
	| "EVENTS"
	| "EVENTS_SCROLL"
	| "ADD_TO_HISTORY"
	| "RESET_HISTORY"
	| "GET_FAVORITES"
	| "UPDATE_FAVORITES"
	| "ADD_TO_SEQUENCES"
	| "UPDATE_EVENT";

export type EventsAction = AsyncAction<
	Events,
	IEventsContainer | boolean | string | IElasticHit
>;

export const EVENTS_LOAD_SIZE = 50;

const FILTERS_SELECT_VALUES: { [key: string]: string[] } = {
	colors: Lists.colors,
	directions: Lists.directions,
	objects: Lists.objects,
	gender: Lists.gender,
	ageGroup: Lists.ageGroup,
};

export function mustMatch(
	values: {
		[key: string]: string | undefined;
	}[],
	exactId: "0" | "1" = "0",
) {
	const mustMatchArr = [];
	for (let i = 0; i < values.length; i++) {
		const prop = Object.values(values[i])[0];
		const key = Object.keys(values[i])[0];
		if (prop !== undefined) {
			if (
				(key === "best.licensePlates.value" && exactId === "0") ||
				(key === "best.faces.matches.id" && exactId === "0")
			) {
				mustMatchArr.push({
					query_string: {
						fields: [key],
						query: `*${prop}*`,
					},
				});
			} else
				mustMatchArr.push({
					match_phrase: { [key]: prop },
				});
		}
	}
	return mustMatchArr;
}

function specialDate(name?: SPECIALDATE) {
	if (!name) return [];
	const date = moment();
	let gte = "";
	let lte = "";

	switch (name) {
		case SPECIALDATE.LASTHOUR:
			gte = moment(date).subtract(1, "hours").toISOString();
			lte = moment(date).toISOString();
			break;
		case SPECIALDATE.LAST6HOUR:
			gte = moment(date).subtract(6, "hours").toISOString();
			lte = moment(date).toISOString();
			break;
		case SPECIALDATE.LAST12HOUR:
			gte = moment(date).subtract(12, "hours").toISOString();
			lte = moment(date).toISOString();
			break;
		case SPECIALDATE.LAST24HOUR:
			gte = moment(date).subtract(24, "hours").toISOString();
			lte = moment(date).toISOString();
			break;
		case SPECIALDATE.TODAY:
			gte = moment(date).startOf("day").toISOString();
			lte = moment(date).endOf("day").toISOString();
			break;
		case SPECIALDATE.YESTERDAY:
			gte = moment(date).subtract(1, "days").startOf("day").toISOString();
			lte = moment(date).subtract(1, "days").endOf("day").toISOString();
			break;
		case SPECIALDATE.FIVEDAYS:
			gte = moment(date).subtract(5, "days").startOf("day").toISOString();
			lte = moment(date).endOf("day").toISOString();
			break;
		case SPECIALDATE.LASTWEEK:
			gte = moment(date)
				.subtract(1, "weeks")
				.startOf("day")
				.toISOString();
			lte = moment(date).endOf("day").toISOString();
			break;
		case SPECIALDATE.LASTTWOWEEKS:
			gte = moment(date)
				.subtract(2, "weeks")
				.startOf("day")
				.toISOString();
			lte = moment(date).endOf("day").toISOString();
			break;
		case SPECIALDATE.THISWEEK:
			gte = moment(date).startOf("isoWeek").toISOString();
			lte = moment(date).endOf("isoWeek").toISOString();
			break;
		case SPECIALDATE.PREVIOUSWEEK:
			gte = moment(date)
				.subtract(1, "weeks")
				.startOf("isoWeek")
				.toISOString();
			lte = moment(date)
				.subtract(1, "weeks")
				.endOf("isoWeek")
				.toISOString();
			break;
		default:
			break;
	}

	return [
		{
			range: {
				"@timestamp": {
					gte,
					lte,
				},
			},
		},
	];
}
function rangeQuery(property: string, from?: string, to?: string) {
	if (!from && !to) return [];
	const gte = from
		? {
				gte: from,
		  }
		: {};
	const lte = to
		? {
				lte: to,
		  }
		: {};

	return [
		{
			range: {
				[property]: { ...gte, ...lte },
			},
		},
	];
}

function rangeQueryMoreFilters(
	moreFilters: { [key: string]: AdditionalFilterItem } | undefined,
) {
	if (!moreFilters) return [];
	const groupedFilters: { [key: string]: MoreFiltersQuery[] } = {};
	Object.keys(moreFilters).forEach((key) => {
		if (key === "gender") return;
		const item = { ...moreFilters[key] };
		if (key === "ageGroup") {
			item.values = item.values.map(
				(value) => lowerFirst(value) + "Confidence",
			);
		}
		if (FILTERS_SELECT_VALUES[key]) {
			const selectValues =
				item.values[0] === "Any"
					? FILTERS_SELECT_VALUES[key].filter(function (item) {
							return item !== "Any";
					  })
					: item.values;
			selectValues.forEach((value) => {
				if (!groupedFilters[key]) groupedFilters[key] = [];
				groupedFilters[key].push({
					bool: {
						should: {
							range: {
								[`${item.name}.${lowerFirst(value)}`]: item.less
									? { lte: item.confidence / 100 }
									: { gte: item.confidence / 100 },
							},
						},
					},
				});
			});
		} else {
			if (!groupedFilters[key]) groupedFilters[key] = [];
			groupedFilters[key].push({
				bool: {
					should: {
						range: {
							[`${item.name}.confidence`]: item.less
								? { lte: item.confidence }
								: { gte: item.confidence, lte: 100 },
						},
					},
				},
			});
		}
	});
	const rangeArr: MoreFiltersQuery[] = [];
	Object.keys(groupedFilters).forEach((key) => {
		const groupFilters = groupedFilters[key];
		if (groupFilters.length === 1) {
			rangeArr.push(groupFilters[0]);
		} else {
			rangeArr.push({
				bool: {
					should: groupFilters as any,
				},
			});
		}
	});
	return rangeArr.length > 1 ? [{ bool: { must: rangeArr } }] : rangeArr;
}

function fieldExist(fields: string[], exist: IFieldExist | undefined) {
	if (exist === undefined) return [];
	if (exist.id === "In Any Watchlist") {
		return [
			{
				bool: {
					should: [
						{
							exists: {
								field: fields[0],
							},
						},
						{
							exists: {
								field: fields[1],
							},
						},
					],
				},
			},
		];
	} else {
		return [
			{
				bool: {
					must_not: [
						{
							exists: {
								field: fields[0],
							},
						},
						{
							exists: {
								field: fields[1],
							},
						},
					],
				},
			},
		];
	}
}

export const getEvents = async (
	filter: EventsFilter,
	from: number = 0,
	size: number = EVENTS_LOAD_SIZE,
	excludes: string[] = [],
	searchAfter?: number[],
) => {
	const secret = filter.secret?.id ? `-${filter.secret.id}` : "";
	try {
		const response = await Axios.get<IEventsContainer>(
			API_ELASTICSEARCH_EVENTS + secret + "/_search",
			{
				params: {
					source: JSON.stringify({
						...(searchAfter
							? {
									search_after: searchAfter,
							  }
							: {
									from: from,
							  }),
						size: size,
						_source: {
							excludes,
						},
						query: {
							bool: {
								filter: [
									...mustMatch(
										[
											{ vms: filter?.vms?.name },
											{
												"rules.name":
													filter?.rule?.name,
											},
											{
												[filter?.watchlist?.type ===
												"Faces"
													? "best.matches.watchlist.keyword"
													: "best.licensePlates.matches.watchlist.keyword"]:
													filter?.watchlist?.id,
											},
											{
												subjectType:
													filter?.subjectType?.toString(),
											},
											{
												"best.licensePlates.value":
													filter?.licensePlateValue,
											},
											{
												"best.faces.matches.id":
													filter?.matchId,
											},
										],
										filter?.exactId,
									),
									...specialDate(filter?.time),
									...rangeQuery(
										"@timestamp",
										filter?.timeFrom,
										filter?.timeTo,
									),
									...rangeQuery(
										"best.faces.attributes.age",
										filter?.ageFrom,
										filter?.ageTo,
									),
									...getGenderQuery(filter?.moreFilters),
									{
										bool: {
											should: filter?.source?.map(
												(source) => ({
													match_phrase: {
														sourceId: source.id,
													},
												}),
											),
										},
									},
									{
										bool: {
											should: [
												...rangeQueryMoreFilters(
													filter?.moreFilters,
												),
											],
										},
									},
									...fieldExist(
										[
											"best.matches.watchlist",
											"best.licensePlates.matches.watchlist",
										],
										filter?.fieldExist,
									),
								],
							},
						},
						sort: {
							"@timestamp": "desc",
						},
					}),
					source_content_type: "application/json",
				},
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getGenderQuery = (moreFilters: EventsFilter["moreFilters"]) => {
	const gender = moreFilters?.gender;
	if (!gender) return [];
	if (gender.values.length === 0) return [];
	if (gender.values.length > 1 || gender.values[0] === "Any") {
		return [
			{
				bool: {
					must: [
						{
							range: {
								[`${gender.name}.confidence`]: gender.less
									? { lte: gender.confidence }
									: { gte: gender.confidence },
							},
						},
					],
				},
			},
		];
	}

	const value = gender.values[0] === "Male" ? "0" : "1";
	return [
		{
			bool: {
				must: [
					{
						match_phrase: {
							[`${gender.name}.value`]: value,
						},
					},
					{
						range: {
							[`${gender.name}.confidence`]: gender.less
								? { lte: gender.confidence }
								: { gte: gender.confidence },
						},
					},
				],
			},
		},
	];
};

export function addToSequences(sequences: ISequence[], cameraId: string) {
	return {
		type: "ADD_TO_SEQUENCES",
		payload: { sequences, cameraId },
		status: AsyncActionStatus.SUCCEEDED,
	};
}

export function getEventsAction(filter: EventsFilter, excludes?: string[]) {
	return async(
		"EVENTS",
		getEvents,
		"",
		filter,
		0,
		EVENTS_LOAD_SIZE,
		excludes,
	);
}

export function updateEventAction(event: IElasticHit) {
	return {
		payload: event,
		status: AsyncActionStatus.SUCCEEDED,
		type: "UPDATE_EVENT",
	};
}

export function getEventsScrollAction(
	filter: EventsFilter,
	from: number,
	size?: number,
) {
	return async("EVENTS_SCROLL", getEvents, "", filter, from, size);
}
