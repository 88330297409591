import React from "react";
import { makeStyles, Button, Box, Popover } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { DatePopover } from "./DatePopover";
import moment from "moment";
import { ITimeButtonHelper, SPECIALDATE, TIMEBUTTONHELPERS } from "./constants";
import { EventsFilter } from "../../store/Events/types";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		gap: 4,
	},
	timeBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		borderBottom: "1px solid #e0e0e0",
	},
	popup: {
		"& 	.MuiPopover-paper": {
			borderRadius: "10px",
		},
	},
	dateBtn: {
		width: "100%",
		height: "100%",
		"&:hover": {
			backgroundColor: "#b4dcfa",
		},
		backgroundColor: "#cae7fc",
		borderRadius: "unset",
		whiteSpace: "normal",
	},
	helpersBox: {
		display: "flex",
		flexWrap: "wrap",
	},
	helperButton: {
		flex: "1 1 50%",
		padding: "4px",
	},
	active: {
		backgroundColor: "#b4dcfa",
		"&:hover": {
			backgroundColor: "#a9d4f7",
		},
	},
}));

interface LogsTimeFilterProps {
	filter?: EventsFilter;
	from: moment.Moment;
	until: moment.Moment;
	relativeFrom: { value: number; text: string };
	relativeUntil: { value: number; text: string };
	onRelativeFromChange(value: number, text: string): void;
	onRelativeUntilChange(value: number, text: string): void;
	onFromChange(from: moment.Moment): void;
	onUntilChange(until: moment.Moment): void;
	onFromUntilChange?(from: moment.Moment, until: moment.Moment): void;
	onSpecialChange?(dateName: SPECIALDATE): void;
	showAbsoluteStart: number;
	showAbsoluteEnd: number;
	onAbsoluteStartChange(num: number): void;
	onAbsoluteEndChange(num: number): void;
}

export const LogsTimeFilter: React.FC<LogsTimeFilterProps> = ({
	filter,
	from,
	until,
	relativeFrom,
	relativeUntil,
	onRelativeFromChange,
	onRelativeUntilChange,
	onFromChange,
	onUntilChange,
	onFromUntilChange,
	onSpecialChange,
	showAbsoluteStart,
	showAbsoluteEnd,
	onAbsoluteStartChange,
	onAbsoluteEndChange,
}) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);

	const fromRelative = moment().subtract(
		relativeFrom.value as moment.DurationInputArg1,
		relativeFrom.text as moment.unitOfTime.DurationConstructor,
	);

	const untilRelative = moment().subtract(
		relativeUntil.value as moment.DurationInputArg1,
		relativeUntil.text as moment.unitOfTime.DurationConstructor,
	);

	const handleFromDateClick = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget);
		if (showAbsoluteStart) return;
		onFromChange(fromRelative);
	};

	const handleUntilDateClick = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget);
		if (showAbsoluteEnd) return;
		onUntilChange(untilRelative);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const absoluteUpdate = (from: moment.Moment, until: moment.Moment) => {
		if (onFromUntilChange) {
			onAbsoluteStartChange(1);
			onAbsoluteEndChange(1);
			onFromUntilChange(from, until);
		}
	};

	const handleHelperClick = (helper: ITimeButtonHelper) => {
		switch (helper.label) {
			case "Today":
				absoluteUpdate(moment().startOf("day"), moment().endOf("day"));
				break;
			case "Yesterday":
				absoluteUpdate(
					moment().subtract(1, "day").startOf("day"),
					moment().subtract(1, "day").endOf("day"),
				);
				break;
			case "This week":
				absoluteUpdate(
					moment().startOf("isoWeek"),
					moment().endOf("isoWeek"),
				);
				break;
			case "Previous week":
				absoluteUpdate(
					moment().subtract(1, "week").startOf("isoWeek"),
					moment().subtract(1, "week").endOf("isoWeek"),
				);
				break;
			default:
				if (!helper.until || !helper.from) return;
				if (showAbsoluteStart) {
					onFromChange(
						moment().subtract(helper.from.value, helper.from.text),
					);
				} else {
					onRelativeFromChange(helper.from.value, helper.from.text);
				}
				if (showAbsoluteEnd) {
					onUntilChange(
						moment().subtract(
							helper.until.value,
							helper.until.text,
						),
					);
				} else {
					onRelativeUntilChange(
						helper.until.value,
						helper.until.text,
					);
				}
				break;
		}
	};

	const open = Boolean(anchorEl);
	const isStart = anchorEl?.id === "start";
	const endLabel = untilRelative.fromNow().startsWith("a few seconds ago")
		? "Now"
		: untilRelative.fromNow();
	const startLabel = fromRelative.fromNow().startsWith("a few seconds ago")
		? "Now"
		: fromRelative.fromNow();
	const absoluteStartLabel = moment(from).format("YYYY-MM-DD HH:mm:ss");
	const absoluteEndLabel = moment(until)
		.fromNow()
		.startsWith("a few seconds ago")
		? "Now"
		: moment(until).format("YYYY-MM-DD HH:mm:ss");

	return (
		<>
			<Box className={classes.root}>
				<Box className={classes.timeBox}>
					<Button
						onClick={handleFromDateClick}
						id="start"
						className={classes.dateBtn}
					>
						{showAbsoluteStart ? absoluteStartLabel : startLabel}
					</Button>
					<ArrowRightAltIcon />
					<Button
						onClick={handleUntilDateClick}
						id="end"
						className={classes.dateBtn}
					>
						{showAbsoluteEnd ? absoluteEndLabel : endLabel}
					</Button>
				</Box>
				<Box className={classes.helpersBox}>
					{TIMEBUTTONHELPERS.map((helper) => (
						<Button
							key={helper.label}
							onClick={() => {
								handleHelperClick(helper);
								onSpecialChange &&
									onSpecialChange(helper.label);
							}}
							className={`${classes.helperButton} ${
								helper.label === filter?.time
									? classes.active
									: ""
							}`}
						>
							{helper.label}
						</Button>
					))}
				</Box>
			</Box>
			<Popover
				className={classes.popup}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				{isStart && open && (
					<DatePopover
						date={from}
						relativeValue={relativeFrom}
						onRelativeChange={onRelativeFromChange}
						onDateChange={(newDate: moment.Moment) =>
							onFromChange(newDate)
						}
						onTabChange={(tab: number) =>
							onAbsoluteStartChange(tab)
						}
						openTab={showAbsoluteStart}
					/>
				)}
				{!isStart && open && (
					<DatePopover
						date={until}
						relativeValue={relativeUntil}
						onRelativeChange={onRelativeUntilChange}
						onDateChange={(newDate: moment.Moment) =>
							onUntilChange(newDate)
						}
						onTabChange={(tab: number) => onAbsoluteEndChange(tab)}
						openTab={showAbsoluteEnd}
					/>
				)}
			</Popover>
		</>
	);
};
