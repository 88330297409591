import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { IWatchlist } from "../../store/Watchlists/types";
import WatchlistForm from "../Forms/WatchlistForm";
import Title from "./Common/Title";

interface IWatchlistDialogProps {
	open: boolean;
	close(): void;
	data: IWatchlist | undefined;
	onConfirm(item: IWatchlist | string | undefined): void;
	onLoading(): void;
	loading: boolean;
}

const WatchlistDialog: React.FC<IWatchlistDialogProps> = ({
	open,
	close,
	data,
	onConfirm,
	onLoading,
	loading,
}) => {
	return (
		<Dialog open={open} fullWidth maxWidth="xs" onClose={close}>
			<Title
				onClose={close}
				title={data ? "Change Description" : "Create Watchlist"}
			/>
			<DialogContent>
				<WatchlistForm
					close={close}
					data={data}
					onSubmit={onConfirm}
					loading={loading}
					onLoading={onLoading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default WatchlistDialog;
