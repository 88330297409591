import React from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import {
	ICreateRule,
	IEditRule,
	IRule,
	RuleAction,
	Trigger,
} from "../../../store/Rules/types";
import { Form } from "react-final-form";
import { TextField, makeValidate } from "mui-rff";
import * as Yup from "yup";
import LabelledOutline from "../../UI/LabelledOutline";
import SecretFormSelect from "../../UI/SecretFormSelect";
import Triggers from "./Triggers/Triggers";
import Actions from "./Actions/Actions";
import { IAlert } from "../../UI/Alert";
import { connect } from "react-redux";
import { getSecretAction } from "../../../store/Secrets/action";
import { AppState } from "../../../store";
import { ISecret } from "../../../store/Secrets/types";
import { EventsFilter } from "../../../store/Events/types";
import useCanSeeSecrets from "../../../hooks/useCanSeeSecrets";

const makeSchema = () => {
	const schema = Yup.object<ICreateRule>().shape({
		name: Yup.string().required(),
		description: Yup.string().notRequired().nullable(),
		enabled: Yup.boolean().notRequired().nullable(),
		gracePeriod: Yup.number().notRequired().nullable(),
	});
	return schema;
};

interface IRuleFormStateProps {
	ruleSecret?: ISecret;
}

interface IRuleFormDispatchProps {
	loadSecret(secretId: string): void;
}

interface IRuleFormOwnProps {
	close(): void;
	onConfirm(rule: IEditRule | ICreateRule): void;
	data?: IRule;
	vmsName: string;
	onLoading(): void;
	loading: boolean;
	isReadOnly: boolean;
	onAlert(alert: IAlert): void;
}
interface IRuleFormProps
	extends IRuleFormOwnProps,
		IRuleFormStateProps,
		IRuleFormDispatchProps {}

const _RuleForm: React.FC<IRuleFormProps> = ({
	close,
	onConfirm,
	data,
	vmsName,
	onLoading,
	loading,
	isReadOnly,
	onAlert,
	ruleSecret,
}) => {
	const validate = makeValidate(makeSchema());
	const canSeeSecrets = useCanSeeSecrets(true);

	const [trigger, setTrigger] = React.useState<Trigger | null>(
		data ? { ...data.triggers } : null,
	);
	const [actions, setActions] = React.useState<RuleAction[]>(
		data ? [...data.actions] : [],
	);
	const [enabled, setEnabled] = React.useState<boolean>(
		data ? data.enabled : true,
	);

	const [isTriggerChanged, setIsTriggerChanged] =
		React.useState<boolean>(false);
	const [areActionsChanged, setAreActionsChanged] =
		React.useState<boolean>(false);
	const [isSecretChanged, setIsSecretChanged] =
		React.useState<boolean>(false);
	const [secret, setSecret] = React.useState<
		EventsFilter["secret"] | undefined
	>(undefined);

	const handleConfirm = async (rule: ICreateRule) => {
		let helperTrigger = data
			? trigger
				? trigger
				: data.triggers
			: trigger!;
		if (
			helperTrigger.type === "MultiTrigger" &&
			helperTrigger.triggers.length === 1 &&
			helperTrigger.triggers[0].type !== "MultiTrigger"
		) {
			const temp = helperTrigger.triggers[0];
			helperTrigger = temp;
		}

		onLoading();
		if (data) {
			const editRule: IEditRule = {
				description: rule.description,
				enabled: enabled,
				gracePeriod: rule.gracePeriod,
				actions: actions,
				triggers: helperTrigger,
			};
			onConfirm(editRule);
		} else {
			const createRule: ICreateRule = {
				name: rule.name,
				description: rule.description,
				enabled: enabled,
				gracePeriod: rule.gracePeriod,
				actions: actions,
				triggers: helperTrigger,
				secret: secret?.id,
			};
			onConfirm(createRule);
		}
	};

	const handleTriggerUpdate = (newTrigger: Trigger) => {
		setIsTriggerChanged(true);
		setTrigger(newTrigger);
	};

	const handleActions = (newActions: RuleAction[]) => {
		setAreActionsChanged(true);
		setActions(newActions);
	};

	const handleEnabled = (check: boolean) => {
		setAreActionsChanged(true);
		setEnabled(check);
	};

	const handleClose = () => {
		close();
	};

	const isDisabled = (pristine: boolean): boolean => {
		if (
			trigger === null ||
			trigger === undefined ||
			(trigger.type === "MultiTrigger" && trigger.triggers.length === 0)
		)
			return true;
		return !(isTriggerChanged || areActionsChanged || isSecretChanged)
			? pristine
			: false;
	};

	return (
		<Form
			onSubmit={handleConfirm}
			validate={validate}
			initialValues={data || { gracePeriod: 2000 }}
			render={({ handleSubmit, pristine, values }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<LabelledOutline
						label="Information"
						id="rule-form-info-label"
					>
						<TextField
							autoFocus={data ? false : true}
							name="name"
							label="Name"
							required
							inputProps={{
								spellCheck: false,
								readOnly: Boolean(data),
								maxLength: 32,
							}}
						/>
						{canSeeSecrets &&
							(data ? (
								<TextField
									disabled={true}
									name="secret"
									label="Secret"
									value={
										(ruleSecret?.name || data?.secret) ??
										undefined
									}
								/>
							) : (
								<SecretFormSelect
									value={secret}
									onSecret={(value) => {
										setSecret(value);
										setIsSecretChanged(true);
									}}
									tooltipTitle="Secret rules can only be viewed by users with permissions."
									onlyWriteable
								/>
							))}
						<TextField
							name="description"
							label="Description"
							value={values.description || undefined}
							multiline
							maxRows={5}
							inputProps={{
								spellCheck: false,
								readOnly: isReadOnly,
							}}
						/>
						<TextField
							name="gracePeriod"
							label={
								<Tooltip
									title="Time in miliseconds before triggers are checked"
									placement="bottom-start"
								>
									<span>Grace Period (ms)</span>
								</Tooltip>
							}
							inputProps={{
								type: "number",
								step: 1000,
								min: 0,
								readOnly: isReadOnly,
							}}
						/>
					</LabelledOutline>
					<LabelledOutline
						label="Triggers"
						id="rule-form-triggers-label"
					>
						<Triggers
							onTrigger={handleTriggerUpdate}
							triggers={structuredClone(trigger)}
							vmsName={vmsName}
							secret={data ? data.secret : secret?.id}
							isReadOnly={isReadOnly}
						/>
					</LabelledOutline>
					<LabelledOutline
						label="Actions"
						id="rule-form-actions-label"
					>
						<Actions
							onActions={handleActions}
							actions={actions}
							enabled={enabled}
							onEnabled={handleEnabled}
							isReadOnly={isReadOnly}
							isSecretSet={Boolean(values.secret)}
							onAlert={onAlert}
							vmsName={vmsName}
						/>
					</LabelledOutline>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{loading ? <CircularProgress size={30} /> : null}
						{isReadOnly ? null : (
							<Button
								type="submit"
								disabled={isDisabled(pristine) || loading}
							>
								{data ? "Update" : "Create"}
							</Button>
						)}
						<Button onClick={handleClose}>Close</Button>
					</div>
				</form>
			)}
		/>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IRuleFormOwnProps,
): IRuleFormStateProps => {
	let stateSecret = undefined;
	if (ownProps?.data?.secret) {
		stateSecret = state.secrets.keys[ownProps.data.secret];
	}
	return { ruleSecret: stateSecret };
};

const mapDispatchToProps = (dispatch: any): IRuleFormDispatchProps => {
	return {
		loadSecret: (secretId: string) => getSecretAction(secretId)(dispatch),
	};
};

const RulesItem = connect(mapStateToProps, mapDispatchToProps)(_RuleForm);
export default RulesItem;
