import { v4 as uuidv4 } from "uuid";
import Paho from "paho-mqtt";
import protobuf from "protobufjs";
import protoString from "../proto/eventProtoString";
import { makeKeysLowerCase, ticksToDate } from "../helpers/Utils";
import { ILiveEvent } from "../store/LiveCameras/types";
import moment from "moment";

const subjectEvent = protobuf.parse(protoString);
const MQTT_ID_KEY = "SVC_WEB_MQTT_ID";

class Mqtt {
	private client: Paho.Client | null = null;
	private connectionOptions: Paho.ConnectionOptions | undefined;
	private connectedTimestamp: moment.Moment | null = null;
	private timeoutToPushQueuedEvents: NodeJS.Timeout | null = null;
	private queuedEvents: ILiveEvent[] = [];
	private mqttError: string | undefined;
	private listenerIsAdded = false;
	private isConnecting = false;
	private onConnectionStatusChange:
		| null
		| (({
				isConnected,
				error,
		  }: {
				isConnected: boolean | null;
				error: string;
		  }) => void) = null;
	subscribeList: string[] = [];

	private reset() {
		if (this.client?.isConnected())
			this.subscribeList.forEach((t) => this.client?.unsubscribe(t));
		this.subscribeList = [];
		this.mqttError = undefined;
	}

	private changeConnectionStatus(isConnected: boolean | null, error: string) {
		if (error !== this.mqttError) this.mqttError = error;
		this.onConnectionStatusChange?.({ isConnected, error });
	}

	private onError(error?: string) {
		if (this.client?.isConnected())
			this.changeConnectionStatus(false, error ?? "");
		else if (error && error !== this.mqttError)
			this.changeConnectionStatus(null, error ?? "");
		this.isConnecting = false;
	}

	private onSuccess() {
		this.connectedTimestamp = moment();
		this.subscribeList.forEach((t) => this.subscribeTopic(t));
		this.changeConnectionStatus(true, "");
		this.isConnecting = false;
	}

	initMqtt(token: string, userName: string) {
		if (this.isConnecting || this.client?.isConnected()) return;

		const SERVER_ADDRESS =
			process.env.REACT_APP_ADDRESS_PROXY_PACKAGE_JSON ??
			window.location.origin;
		const HOST = SERVER_ADDRESS.replace(/^(?:https?:\/\/)?/, "");

		const IS_SECURE = SERVER_ADDRESS?.startsWith("https");
		const PROTOCOL = IS_SECURE ? "wss://" : "ws://";

		let MQTT_ID = localStorage.getItem(MQTT_ID_KEY);
		if (!MQTT_ID) {
			MQTT_ID = uuidv4();
			localStorage.setItem(MQTT_ID_KEY, MQTT_ID);
		}

		this.isConnecting = true;
		if (!this.client)
			this.client = new Paho.Client(
				PROTOCOL + HOST + "/mqtt",
				userName + "$" + MQTT_ID,
			);
		const connectionOptions: Paho.ConnectionOptions = {
			onSuccess: () => this.onSuccess(),
			onFailure: (e) => {
				this.onError(String(e.errorMessage));
			},
			userName: userName,
			password: token,
			useSSL: IS_SECURE,
			cleanSession: false,
			reconnect: true,
			timeout: 15,
			keepAliveInterval: 50,
		};
		this.connectionOptions = connectionOptions;

		this.client.onConnectionLost = (e) => {
			this.isConnecting = true;
			this.onError(String(e.errorMessage));
		};
		try {
			this.client.connect(connectionOptions);
		} catch (error) {
			if (String(error).includes("already connected")) return;
			this.onError(String(error));
		}
	}

	async reconnectWithNewToken(accessToken: string) {
		if (this.client && this.connectionOptions) {
			this.connectionOptions.password = accessToken;
			await this.disconnect();
			try {
				this.client.connect(this.connectionOptions);
			} catch (error) {
				if (String(error).includes("already connected")) return;
				this.onError(String(error));
			}
		}
	}

	async disconnect() {
		if (this.client?.isConnected()) {
			this.client?.disconnect();
		}
		this.isConnecting = false;
		this.subscribeList = [];
		this.changeConnectionStatus(false, "");
		this.reset();
	}

	setOnMessageArrived(
		onNewEvent: (events: ILiveEvent | ILiveEvent[]) => void,
	) {
		if (this.listenerIsAdded || !this.client?.isConnected()) return;
		this.client.onMessageArrived = (message: Paho.Message) => {
			const payload = message.payloadBytes;
			const data = new Uint8Array(payload);
			const decoder = subjectEvent.root.lookupType(
				"Neurotec.SentiVeillance.SubjectEvent",
			);
			const decodedMsg = decoder.decode(data);
			const event: ILiveEvent = makeKeysLowerCase(decodedMsg.toJSON());
			event.id = uuidv4();
			event.topic = message.destinationName;
			const eventTime = moment(ticksToDate(Number(event.timeStamp)));
			if (eventTime.isSameOrAfter(this.connectedTimestamp))
				onNewEvent(event);
			else {
				this.queuedEvents.push(event);
				if (this.timeoutToPushQueuedEvents)
					clearTimeout(this.timeoutToPushQueuedEvents);
				this.timeoutToPushQueuedEvents = setTimeout(() => {
					onNewEvent(this.queuedEvents);
					this.queuedEvents = [];
				}, 1000);
			}
		};
	}

	subscribeTopic(topic: string) {
		if (!this.client?.isConnected()) return;
		this.subscribeList.push(topic);
		this.subscribeList = this.subscribeList.filter(
			(value, index, self) => self.indexOf(value) === index,
		);
		this.client.subscribe(topic, {
			qos: 1,
		});
	}

	unsubscribeTopic(topic: string) {
		if (!this.client?.isConnected()) return;
		this.subscribeList = this.subscribeList.filter((t) => t !== topic);
		this.client.unsubscribe(topic);
	}

	subscribeToConnectionStatus(
		subscriber: ({
			isConnected,
			error,
		}: {
			isConnected: boolean | null;
			error: string;
		}) => void,
	) {
		this.onConnectionStatusChange = subscriber;
	}

	isConnectedToMqtt() {
		return this.client?.isConnected() ?? false;
	}
}

export const mqttClient = new Mqtt();
