import React from "react";
import { IVMSState, IVMS, EngineActions } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { VMSAction, VMS } from "./action";
import { PagedContent } from "../types";
import { EngineState } from "../Sources/types";

export const initialVMSState: IVMSState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	connections: {},
	requestStatus: {},
};

export const VMSReducer: Reducer<IVMSState, any> = (
	state = initialVMSState,
	action: VMSAction,
) => {
	if (action.type !== VMS) {
		return state;
	}

	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta && action.meta === "SetConnectionLost") {
				const payload = action.payload as {
					vmsName: string;
					isLost: boolean;
				};
				nState.connections[payload.vmsName] = payload.isLost;
				return nState;
			}
			if (action.meta && action.meta === "Remove") {
				const vmsName = action.payload as unknown as string;
				nState.data.content = nState.data.content.filter(
					(vms) => vms.name !== vmsName,
				);
				delete nState.keys[vmsName];
				return nState;
			}
			if (action.meta) {
				if (
					nState.data.content &&
					nState.data.content.find((vms) => vms.name === action.meta)
				) {
					nState.data.content = nState.data.content.map((vms) => {
						if (vms.name === action.meta) {
							return action.payload as IVMS;
						}
						return vms;
					});
				} else {
					nState.data = {
						...nState.data,
						content: [
							...(nState.data.content ?? []),
							action.payload as IVMS,
						],
					};
				}
				nState.keys[action.meta] = action.payload as IVMS;
			} else {
				const payload = action.payload as PagedContent<IVMS[]>;
				if (payload.content) {
					payload.content.forEach((vms) => {
						nState.keys[vms.name] = vms;
					});
				}
			}

			nState.data.totalElements = nState.data.content
				? nState.data.content.length
				: nState.keys
				? Object.keys(nState.keys).length
				: 0;
			return nState;
	}
	return state;
};

export const engineReducer: React.Reducer<EngineState[], EngineActions> = (
	state: EngineState[] = [],
	action: EngineActions,
): EngineState[] => {
	switch (action.type) {
		case "Load": {
			const nState = [...state];
			const engineIndex = nState.findIndex(
				(engine) => engine.id === action.id,
			);

			if (engineIndex >= 0) {
				nState[engineIndex] = {
					...nState[engineIndex],
					...action,
				};
			} else {
				nState.push({
					...action,
					touched: false,
				});
			}
			return nState;
		}
		case "Update": {
			const nState = [...state];
			const engineIndex = nState.findIndex(
				(engine) => engine.id === action.id,
			);
			if (engineIndex >= 0) {
				nState[engineIndex] = {
					...nState[engineIndex],
					...action,
				};
			}
			return nState;
		}

		default:
			return state;
	}
};
