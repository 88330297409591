import { chunk } from "lodash";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getBackwardSearchEvents,
	getBackwardSearchResultAction,
	getBackwardSearchImageAction,
} from "../store/BackwardSearch/action";
import { AppState } from "../store";
import { IElasticHit } from "../store/Events/types";
import { IBackwardFilter } from "../store/BackwardSearch/types";
import { addConfigAction, getConfigAction } from "../store/UserConfig/action";
import useAllSources from "./useAllSources";
import { AsyncActionStatus } from "../store/AsyncState";

const ONE_REQUEST_MATCHES_LIMIT = 10;

interface Props {
	id: string;
	selectedResultIndex: number;
}

const useBackwardSearchResult = ({ id, selectedResultIndex }: Props) => {
	const dispatch = useDispatch();

	const [isEventsLoading, setIsEventsLoading] = useState(false);
	const [isResultLoading, setIsResultLoading] = useState(false);

	const allSources = useAllSources({ enabled: Boolean(id) });

	const results = useSelector((state: AppState) =>
		state.backwardSearch.results.find((r) => r.id === id),
	);

	const isFirstResultsLoad = useRef(true);
	const orderByScore = useSelector(
		(state: AppState) =>
			state.userConfig.data.preference.backwardSortByScore ?? false,
	);
	const userConfig = useSelector((state: AppState) => state.userConfig);

	const onFilter = useCallback(
		(newFilter: IBackwardFilter) => {
			if (!results) return;
			dispatch({
				type: "Backward_Search_Result",
				status: AsyncActionStatus.SUCCEEDED,
				payload: { ...results, filter: newFilter },
			});
		},
		[dispatch, results],
	);

	const getResults = useCallback(() => {
		const isFirstLoad = isFirstResultsLoad.current;
		isFirstResultsLoad.current = false;
		setIsResultLoading(true);
		const filter = results?.filter;
		getBackwardSearchResultAction({
			...filter,
			orderByScore,
			id,
		})(dispatch)
			.then(() => {
				if (isFirstLoad) dispatch(getBackwardSearchImageAction(id));
			})
			.finally(() => {
				setIsResultLoading(false);
			});
	}, [id, orderByScore, results?.filter, dispatch]);

	const setEvents = useCallback(
		(events: IElasticHit[]) => {
			if (!results) return;
			dispatch({
				type: "Backward_Search_Result",
				status: AsyncActionStatus.SUCCEEDED,
				payload: { ...results, events },
			});
		},
		[results, dispatch],
	);

	useEffect(() => {
		if (!results || isEventsLoading) return;
		const matches =
			results.subjects?.[selectedResultIndex]?.matches?.content || [];
		const events = results.events ?? [];
		const matchesToLoad = matches.filter(
			(match) =>
				!events.some((e) => e._source.subjectId === match.subjectId),
		);
		if (matchesToLoad.length === 0) return;

		const chunks = chunk(matchesToLoad, ONE_REQUEST_MATCHES_LIMIT);

		setIsEventsLoading(true);
		Promise.all(
			chunks.map((chunk) =>
				getBackwardSearchEvents(chunk).then((newEvents) =>
					setEvents(newEvents),
				),
			),
		).finally(() => setIsEventsLoading(false));
	}, [
		selectedResultIndex,
		results,
		id,
		isEventsLoading,
		results?.events,
		dispatch,
		setEvents,
	]);

	useEffect(() => {
		if (!userConfig.loaded) dispatch(getConfigAction());
		else if (
			userConfig.loaded &&
			!userConfig.data.preference.savedInServer
		) {
			dispatch(
				addConfigAction({
					variant: "preference",
					config: {
						...userConfig.data.preference,
						savedInServer: true,
					},
				}),
			);
		}
	}, [userConfig.loaded, userConfig.data.preference, dispatch]);

	useEffect(() => {
		isFirstResultsLoad.current = true;
	}, [id]);

	useEffect(() => {
		if (id && results?.filter && !isFirstResultsLoad.current) {
			getResults();
		}
	}, [id, results?.filter, getResults]);

	useEffect(() => {
		if (!userConfig.loaded) return;
		if (id && !results && !isResultLoading && isFirstResultsLoad.current)
			getResults();

		if (results && isFirstResultsLoad.current)
			isFirstResultsLoad.current = false;
	}, [id, results, isResultLoading, getResults, userConfig.loaded]);

	return {
		events: results?.events ?? [],
		isEventsLoading,
		isResultLoading,
		results,
		allSources,
		setFilter: onFilter,
		filter: results?.filter,
	};
};

export default useBackwardSearchResult;
