import AsyncState from "../AsyncState";
import moment from "moment";

export interface IElasticSource {
	[key: string]: string | number | object;
}

export interface IElasticSourceService {
	kubernetes?: {
		annotations?: {
			"sentiveillance_com/serviceName": string;
		};
	};
}

export interface IElasticHit {
	_id: string;
	_index: string;
	_score: number;
	_source: IElasticSource & IElasticSourceService;
	_type: string;
	sort: number[];
	highlight?: { [key: string]: string };
}

interface IElasticTotal {
	value: number;
	relation: string;
}

interface IElasticHits {
	hits: IElasticHit[];
	max_score: number;
	total: IElasticTotal;
}

interface IElasticShards {
	total: number;
	successful: number;
	skipped: number;
	failed: number;
}

export interface IElasticLogs {
	hits: IElasticHits;
	timed_out: boolean;
	took: number;
	_shards: IElasticShards;
}

export type ILogsContainer = IElasticLogs;

export interface ILogsState extends AsyncState {
	data: ILogsContainer | null;
	isAuthenticated: boolean;
	authorization: string;
}

export interface IRequestLogs {
	service: string[];
	severity?: string;
	message?: string;
	not: { [key: string]: string }[];
	timeRange: {
		from: moment.Moment;
		to: moment.Moment;
	};
}

export type Service = {
	id: string;
	displayName: string;
};

export const logsFilters = [
	"kubernetes.annotations.sentiveillance_com/serviceName.keyword",
	"level.keyword",
	"message",
];
