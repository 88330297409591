import React from "react";
import {
	ICreateSubjectBody,
	IEditSubjectBody,
	ISubject,
} from "../../store/Subjects/types";
import { IWatchlist } from "../../store/Watchlists/types";
import FaceSubjectsList from "./Faces/FaceSubjectsList";
import SubjectPlateCreateDialog from "../Dialogs/SubjectDialogs/SubjectPlateCreateDialog";
import SubjectPlateEditDialog from "../Dialogs/SubjectDialogs/SubjectPlateEditDialog";
import SubjectFaceCreateDialog from "../Dialogs/SubjectDialogs/SubjectFaceCreateDialog";
import SubjectFaceEditDialog from "../Dialogs/SubjectDialogs/SubjectFaceEditDialog";
import LicensePlateSubjectsList from "./LicensePlates/LicensePlateSubjectsList";
import { IAlert } from "../UI/Alert";
import { useDispatch, useSelector } from "react-redux";
import { getSchemaAction } from "../../store/Schemas/action";
import { IMetadata, IMetadataSchema, SCHEMAS } from "../../store/Schemas/types";
import { AppState } from "../../store";

interface ISubjectsDisplayProps {
	subjects: ISubject[];
	selected: number;
	onSelected: React.Dispatch<React.SetStateAction<number>>;
	onDoubleClick(option: string): void;
	openCreate: boolean;
	openEdit: boolean;
	onCreate(body: ICreateSubjectBody): void;
	onEdit(subjectId: string, body: IEditSubjectBody): void;
	closeCreate(): void;
	closeEdit(): void;
	watchlist: IWatchlist;
	onLoading(): void;
	loading: boolean;
	onSnack(alert: IAlert): void;
}

const extractMetadataFromSchema = (schema?: IMetadataSchema) => {
	const fields: IMetadata = {};
	if (schema && schema.properties) {
		const keys = Object.keys(schema.properties);
		for (let i = 0; i < keys.length; i++) {
			fields[keys[i]] = extractMetadataFromSchema(
				schema.properties[keys[i]],
			);
		}
		return fields;
	}
	return null;
};

const SubjectsDisplay: React.FC<ISubjectsDisplayProps> = ({
	subjects,
	selected,
	onSelected,
	onDoubleClick,
	openCreate,
	openEdit,
	onCreate,
	onEdit,
	closeCreate,
	closeEdit,
	watchlist,
	onLoading,
	loading,
	onSnack,
}) => {
	const dispatch = useDispatch();
	const schemaType =
		watchlist.type === "Faces" ? SCHEMAS.PERSON : SCHEMAS.PLATE;
	const schema = useSelector((state: AppState) => state.schemas[schemaType]);

	const createMetadata: IMetadata | null = extractMetadataFromSchema(schema);

	const editMetadata: IMetadata = subjects[selected]
		? JSON.parse(subjects[selected].metadata)
		: {};

	const handleSubjectClick = (index: number) => {
		onSelected(index);
	};

	React.useEffect(() => {
		if (schema) return;

		const handlePersonSchema = async () => {
			dispatch(getSchemaAction(SCHEMAS.PERSON));
		};
		const handlePlateSchema = async () => {
			dispatch(getSchemaAction(SCHEMAS.PLATE));
		};
		try {
			if (watchlist.type === "Faces") {
				handlePersonSchema();
			} else {
				handlePlateSchema();
			}
		} catch (err) {
			onSnack({
				message: "Failed to get metadata schema.",
				variant: "error",
			});
		}
	}, [schema, watchlist.type, onSnack, dispatch]);

	return watchlist.type === "Faces" ? (
		<>
			<FaceSubjectsList
				subjects={subjects}
				selected={selected}
				onSelected={handleSubjectClick}
				onDoubleClick={onDoubleClick}
				watchlist={watchlist}
				extractedSchema={createMetadata}
			/>
			<SubjectFaceCreateDialog
				open={openCreate}
				onConfirm={onCreate}
				onClose={closeCreate}
				secret={watchlist.secret}
				schema={schema as IMetadataSchema}
				metadata={createMetadata}
				onLoading={onLoading}
				loading={loading}
			/>
			{subjects.length > 0 ? (
				<SubjectFaceEditDialog
					open={openEdit}
					onClose={closeEdit}
					onConfirm={onEdit}
					subject={subjects[selected]}
					watchlist={watchlist}
					schema={schema as IMetadataSchema}
					metadata={editMetadata}
					onLoading={onLoading}
					loading={loading}
				/>
			) : null}
		</>
	) : (
		<>
			<LicensePlateSubjectsList
				subjects={subjects}
				selected={selected}
				onSelected={handleSubjectClick}
				onDoubleClick={onDoubleClick}
			/>
			<SubjectPlateCreateDialog
				open={openCreate}
				onConfirm={onCreate}
				onClose={closeCreate}
				secret={watchlist.secret}
				schema={schema as IMetadataSchema}
				metadata={createMetadata}
				onLoading={onLoading}
				loading={loading}
			/>
			{subjects.length > 0 ? (
				<SubjectPlateEditDialog
					open={openEdit}
					onClose={closeEdit}
					onConfirm={onEdit}
					subject={subjects[selected]}
					watchlistId={watchlist.id}
					schema={schema as IMetadataSchema}
					metadata={editMetadata}
					onLoading={onLoading}
					loading={loading}
				/>
			) : null}
		</>
	);
};

export default SubjectsDisplay;
