import React from "react";
import { TimeTrigger } from "../../../../../store/Rules/types";
import {
	makeStyles,
	Chip,
	Popover,
	Button,
	Grid,
	Switch,
	Typography,
	TextField,
	Select,
	MenuItem,
	FormControl,
} from "@material-ui/core";
import { THEME } from "../../../../../config/index";
import { DatePicker } from "@material-ui/pickers";
import { AccessTime } from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	icon: {
		width: 22,
		height: 22,
		fill: THEME.palette.grey[800],
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 4,
	},
}));

export const getTimeChipLabel = (
	trigger: TimeTrigger,
	rowClass?: string,
	tagClass?: string,
) => {
	const weekday = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	];
	const month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	switch (trigger.timeType) {
		case "TimeOfDay": {
			return (
				<span className={rowClass}>
					Time:{" "}
					{trigger.from && (
						<>
							from{" "}
							<span className={tagClass}>
								{(trigger.from / 100)
									.toFixed(2)
									.replace(".", ":")}{" "}
							</span>
						</>
					)}
					{trigger.to && (
						<>
							to
							<span className={tagClass}>
								{(trigger.to / 100)
									.toFixed(2)
									.replace(".", ":")}
							</span>
						</>
					)}
				</span>
			);
		}
		case "DayOfWeek": {
			return (
				<span className={rowClass}>
					Day:{" "}
					{trigger.from && (
						<>
							from{" "}
							<span className={tagClass}>
								{weekday[trigger.from - 1]}
							</span>
						</>
					)}
					{trigger.to && (
						<>
							to
							<span className={tagClass}>
								{weekday[trigger.to - 1]}
							</span>
						</>
					)}
				</span>
			);
		}
		case "MonthAndDay": {
			return (
				<span className={rowClass}>
					{trigger.from && (
						<>
							From{" "}
							<span className={tagClass}>
								{month[Math.trunc(trigger.from / 100) - 1]}{" "}
								{trigger.from % 100}
							</span>
						</>
					)}
					{trigger.to && (
						<>
							To{" "}
							<span className={tagClass}>
								{month[Math.trunc(trigger.to / 100) - 1]}{" "}
								{trigger.to % 100}
							</span>
						</>
					)}
				</span>
			);
		}
	}
};

interface ITimeTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: TimeTrigger): void;
	onCancel(): void;
	timeTrigger?: TimeTrigger;
	triggerOption: string;
}

interface ITimeTriggerComponentProps {
	trigger: TimeTrigger;
	updateTrigger(trigger?: TimeTrigger): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

const TimeTriggerComponent: React.FC<ITimeTriggerComponentProps> = ({
	trigger,
	updateTrigger,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: TimeTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				label={getTimeChipLabel(trigger, classes.row, classes.tag)}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
				icon={<AccessTime className={classes.icon} />}
			/>
			<TimeTriggerPopover
				timeTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
				triggerOption={trigger.timeType}
			/>
		</>
	);
};

const convertHourTime = (time: number) => {
	const timeStr = time.toString();
	switch (timeStr.length) {
		case 1: {
			return "00:0" + timeStr;
		}
		case 2: {
			return "00:" + timeStr;
		}
		case 3: {
			return (
				"0" + timeStr.substring(0, 1) + ":" + timeStr.substring(1, 3)
			);
		}
		case 4: {
			return timeStr.substring(0, 2) + ":" + timeStr.substring(2, 4);
		}
	}
};

const weekdaySelect = [
	{ name: "Monday", value: 1 },
	{ name: "Tuesday", value: 2 },
	{ name: "Wednesday", value: 3 },
	{ name: "Thursday", value: 4 },
	{ name: "Friday", value: 5 },
	{ name: "Saturday", value: 6 },
	{ name: "Sunday", value: 7 },
];

const extractNumberFromDate = (date: Date) =>
	(new Date(date).getMonth() + 1) * 100 + new Date(date).getDate();

const convertDateFromNumber = (date: number) =>
	new Date(2021, Math.trunc(date / 100) - 1, date % 100);

const defaultDayTimeTrigger: TimeTrigger = {
	type: "TimeTrigger",
	timeZoneId: "FLE Standard Time",
	timeType: "TimeOfDay",
	from: 1200,
	to: null,
};

const defaultWeekTimeTrigger: TimeTrigger = {
	type: "TimeTrigger",
	timeZoneId: "FLE Standard Time",
	timeType: "DayOfWeek",
	from: 1,
	to: 5,
};

const defaultMonthTimeTrigger: TimeTrigger = {
	type: "TimeTrigger",
	timeZoneId: "FLE Standard Time",
	timeType: "MonthAndDay",
	from: (new Date().getMonth() + 1) * 100 + new Date().getDate(),
	to: null,
};

export const TimeTriggerPopover: React.FC<ITimeTriggerPopoverProps> = ({
	anchorEl,
	onUpdate,
	onCancel,
	timeTrigger,
	triggerOption,
}) => {
	const classes = useStyles();
	const [dayTimeTrigger, setDayTimeTrigger] = React.useState<TimeTrigger>(
		timeTrigger ? timeTrigger : defaultDayTimeTrigger,
	);
	const [weekTimeTrigger, setWeekTimeTrigger] = React.useState<TimeTrigger>(
		timeTrigger ? timeTrigger : defaultWeekTimeTrigger,
	);
	const [monthTimeTrigger, setMonthTimeTrigger] = React.useState<TimeTrigger>(
		timeTrigger ? timeTrigger : defaultMonthTimeTrigger,
	);

	React.useEffect(() => {
		setDayTimeTrigger(timeTrigger ? timeTrigger : defaultDayTimeTrigger);
		setWeekTimeTrigger(timeTrigger ? timeTrigger : defaultWeekTimeTrigger);
		setMonthTimeTrigger(
			timeTrigger ? timeTrigger : defaultMonthTimeTrigger,
		);
	}, [timeTrigger, anchorEl]);

	const handleCancel = () => {
		onCancel();
	};

	const handleConfirm = () => {
		switch (triggerOption) {
			case "TimeOfDay": {
				onUpdate(dayTimeTrigger);
				break;
			}
			case "DayOfWeek": {
				onUpdate(weekTimeTrigger);
				break;
			}
			case "MonthAndDay": {
				onUpdate(monthTimeTrigger);
				break;
			}
		}
		handleCancel();
	};

	const updateTriggerState = (changedTrigger: TimeTrigger) => {
		switch (triggerOption) {
			case "TimeOfDay": {
				setDayTimeTrigger(changedTrigger);
				break;
			}
			case "DayOfWeek": {
				setWeekTimeTrigger(changedTrigger);
				break;
			}
			case "MonthAndDay": {
				setMonthTimeTrigger(changedTrigger);
				break;
			}
		}
	};

	const handleFromSwitch = () => {
		const changedTrigger =
			triggerOption === "TimeOfDay"
				? { ...dayTimeTrigger }
				: triggerOption === "DayOfWeek"
				? { ...weekTimeTrigger }
				: { ...monthTimeTrigger };
		if (changedTrigger.from !== null) {
			changedTrigger.from = null;
		} else {
			switch (triggerOption) {
				case "TimeOfDay": {
					changedTrigger.from = 1200;
					break;
				}
				case "DayOfWeek": {
					changedTrigger.from = 1;
					break;
				}
				case "MonthAndDay": {
					const d = new Date();
					changedTrigger.from =
						(d.getMonth() + 1) * 100 + d.getDate();
					break;
				}
			}
		}
		updateTriggerState(changedTrigger);
	};

	const handleToSwitch = () => {
		const changedTrigger =
			triggerOption === "TimeOfDay"
				? { ...dayTimeTrigger }
				: triggerOption === "DayOfWeek"
				? { ...weekTimeTrigger }
				: { ...monthTimeTrigger };
		if (changedTrigger.to !== null) {
			changedTrigger.to = null;
		} else {
			switch (triggerOption) {
				case "TimeOfDay": {
					changedTrigger.to = 2359;
					break;
				}
				case "DayOfWeek": {
					changedTrigger.to = 5;
					break;
				}
				case "MonthAndDay": {
					const d = new Date();
					d.setMonth(11, 31);
					changedTrigger.to = (d.getMonth() + 1) * 100 + d.getDate();
					break;
				}
			}
		}
		updateTriggerState(changedTrigger);
	};

	const handleTimeChange = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
		option: string,
	) => {
		const changedTrigger =
			triggerOption === "TimeOfDay"
				? { ...dayTimeTrigger }
				: triggerOption === "DayOfWeek"
				? { ...weekTimeTrigger }
				: { ...monthTimeTrigger };
		const fromOrTo = event.target.name;
		let newVal: number;
		if (option === "TimeOfDay") {
			const arr = (event.target.value as string).split(":");
			newVal = parseInt(arr[0], 10) * 100 + parseInt(arr[1], 10);
		} else {
			newVal = event.target.value as number;
		}
		if (fromOrTo === "from") {
			changedTrigger.from = newVal;
		} else {
			changedTrigger.to = newVal;
		}
		updateTriggerState(changedTrigger);
	};

	const handleMonthAndDayChange = (
		time: MaterialUiPickersDate | null,
		fromOrTo: string,
	) => {
		if (time) {
			const newDate = extractNumberFromDate(time.toDate());
			const changedTrigger =
				triggerOption === "TimeOfDay"
					? { ...dayTimeTrigger }
					: triggerOption === "DayOfWeek"
					? { ...weekTimeTrigger }
					: { ...monthTimeTrigger };
			if (fromOrTo === "from") {
				changedTrigger.from = newDate;
			} else {
				changedTrigger.to = newDate;
			}
			updateTriggerState(changedTrigger);
		}
	};

	const handleTimeOption = (option: string, fromOrTo: string) => {
		switch (option) {
			case "TimeOfDay": {
				const val =
					fromOrTo === "from"
						? dayTimeTrigger.from
							? convertHourTime(dayTimeTrigger.from)
							: dayTimeTrigger.from === 0
							? "00:00"
							: ""
						: dayTimeTrigger.to
						? convertHourTime(dayTimeTrigger.to)
						: dayTimeTrigger.to === 0
						? "00:00"
						: "";
				return (
					<TextField
						id="from-time"
						type="time"
						name={fromOrTo}
						value={val}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						disabled={
							fromOrTo === "from"
								? dayTimeTrigger.from === null
								: dayTimeTrigger.to === null
						}
						onChange={(e) => handleTimeChange(e, option)}
					/>
				);
			}
			case "DayOfWeek": {
				return (
					<FormControl style={{ minWidth: 128 }}>
						<Select
							name={fromOrTo}
							disabled={
								fromOrTo === "from"
									? weekTimeTrigger.from === null
									: weekTimeTrigger.to === null
							}
							value={
								fromOrTo === "from"
									? weekTimeTrigger.from || ""
									: weekTimeTrigger.to || ""
							}
							onChange={(e) => handleTimeChange(e, option)}
						>
							{weekdaySelect.map((item, i) => (
								<MenuItem key={i} value={item.value}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			}
			case "MonthAndDay": {
				return (
					<DatePicker
						disableToolbar
						variant="inline"
						value={
							fromOrTo === "from"
								? monthTimeTrigger.from
									? convertDateFromNumber(
											monthTimeTrigger.from,
									  )
									: null
								: monthTimeTrigger.to
								? convertDateFromNumber(monthTimeTrigger.to)
								: null
						}
						onChange={(d) => handleMonthAndDayChange(d, fromOrTo)}
						views={["month", "date"]}
						disabled={
							fromOrTo === "from"
								? monthTimeTrigger.from === null
								: monthTimeTrigger.to === null
						}
					/>
				);
			}
		}
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid container item xs={12} className={classes.popoverRow}>
					<Grid item xs={3}>
						<Typography>From</Typography>
					</Grid>
					<Grid item xs={3}>
						<Switch
							size="small"
							checked={
								triggerOption === "TimeOfDay"
									? dayTimeTrigger.from !== null
									: triggerOption === "DayOfWeek"
									? weekTimeTrigger.from !== null
									: monthTimeTrigger.from !== null
							}
							onChange={handleFromSwitch}
						/>
					</Grid>
					<Grid item xs={6}>
						{handleTimeOption(triggerOption, "from")}
					</Grid>
				</Grid>
				<Grid container item xs={12} className={classes.popoverRow}>
					<Grid item xs={3}>
						<Typography>To</Typography>
					</Grid>
					<Grid item xs={3}>
						<Switch
							size="small"
							checked={
								triggerOption === "TimeOfDay"
									? dayTimeTrigger.to !== null
									: triggerOption === "DayOfWeek"
									? weekTimeTrigger.to !== null
									: monthTimeTrigger.to !== null
							}
							onChange={handleToSwitch}
						/>
					</Grid>
					<Grid item xs={6}>
						{handleTimeOption(triggerOption, "to")}
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								(triggerOption === "TimeOfDay"
									? dayTimeTrigger.from === null
									: triggerOption === "DayOfWeek"
									? weekTimeTrigger.from === null
									: monthTimeTrigger.from === null) &&
								(triggerOption === "TimeOfDay"
									? dayTimeTrigger.to === null
									: triggerOption === "DayOfWeek"
									? weekTimeTrigger.to === null
									: monthTimeTrigger.to === null)
							}
						>
							{timeTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default TimeTriggerComponent;
