import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/styles";
import {
	ListItem,
	Collapse,
	List,
	ListItemText,
	ListItemIcon,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IMenuItem } from "../../config/MenuItems";
import { THEME } from "../../config";
import { useLocation } from "react-router";

const styles = createStyles({
	selected: {
		backgroundColor: THEME.palette.secondary.main + "!important",
	},
	nested: {
		paddingLeft: THEME.spacing(1),
	},
	nestedClosed: {
		paddingLeft: THEME.spacing(4),
	},
	navLink: {
		textDecoration: "none",
		color: "white",
	},
});

interface INavMenuItemProps extends WithStyles<typeof styles> {
	link: string;
	nestedClass?: string;
	subItems?: IMenuItem[];
	selected?: boolean;
	clicked?(): void;
	isDrawerOpen: boolean;
	children?: React.ReactNode;
}

const NavMenuItem: React.FC<INavMenuItemProps> = (props) => {
	const location = useLocation();
	const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

	const renderExpandIcon = () => {
		if (props.subItems) {
			if (props.subItems.length > 0) {
				if (isMenuOpen) {
					return <ExpandLess />;
				} else {
					return <ExpandMore />;
				}
			}
		}
		return null;
	};

	const handleClick = () => {
		if (props.subItems && props.subItems.length > 0) {
			setIsMenuOpen(!isMenuOpen);
		} else {
			if (props.clicked) {
				props.clicked();
			}
		}
	};

	const handleSubClick = () => () => {
		if (props.clicked) {
			props.clicked();
		}
	};

	const renderCollapsed = () => {
		if (props.subItems && props.subItems.length === 0) {
			return null;
		}
		const StyledSelf = withStyles(styles)(NavMenuItem);
		const subs = props.subItems || [];
		return (
			<Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
				<List
					component="div"
					disablePadding
					style={{
						borderLeft: props.isDrawerOpen
							? `1px solid ${THEME.palette.grey[500]}`
							: "none",
						marginLeft: props.isDrawerOpen ? THEME.spacing(3.5) : 0,
					}}
				>
					{subs.map((mItem, index) => (
						<StyledSelf
							key={index}
							nestedClass={
								props.isDrawerOpen
									? props.classes.nested
									: props.classes.nestedClosed
							}
							subItems={mItem.subItems}
							clicked={handleSubClick()}
							link={mItem.route || ""}
							isDrawerOpen={props.isDrawerOpen}
							selected={
								mItem.route ===
								decodeURIComponent(location.pathname)
							}
						>
							{props.isDrawerOpen ? (
								<ListItemText>{mItem.name}</ListItemText>
							) : mItem.icon ? (
								<ListItemIcon
									style={{
										color: "white",
										marginLeft: -15,
									}}
								>
									{mItem.icon || null}
								</ListItemIcon>
							) : (
								<ListItemText style={{ marginLeft: -10 }}>
									{mItem.name[0]}
								</ListItemText>
							)}
						</StyledSelf>
					))}
				</List>
			</Collapse>
		);
	};

	const renderContent = () => {
		return (
			<ListItem
				className={props.nestedClass || ""}
				onClick={handleClick}
				button
				selected={props.selected}
				classes={{ selected: props.classes.selected }}
			>
				{props.children}
				{renderExpandIcon()}
			</ListItem>
		);
	};

	return (
		<>
			{props.link ? (
				<NavLink
					to={props.link || props.subItems?.[0].route || ""}
					className={props.classes.navLink}
				>
					{renderContent()}
				</NavLink>
			) : (
				renderContent()
			)}
			{renderCollapsed()}
		</>
	);
};

export default withStyles(styles)(NavMenuItem);
