import { connect } from "react-redux";
import LogsView, {
	ILogsViewDispatchProps,
	ILogsViewStateProps,
} from "./LogsView";
import { AppState } from "../../store";
import { getLogsAction, getLogsScrollAction } from "../../store/Logs/action";
import { IRequestLogs } from "../../store/Logs/types";
import { AsyncActionStatus } from "../../store/AsyncState";

const mapStateToProps = (state: AppState): ILogsViewStateProps => {
	return {
		error: state.logs.erorr,
		logs: state.logs.data?.hits?.hits,
		areLogsLoaded:
			state.logs.status === AsyncActionStatus.FAILED ||
			state.logs.status === AsyncActionStatus.SUCCEEDED,
	};
};

const mapDispatchToProps = (dispatch: any): ILogsViewDispatchProps => {
	return {
		loadLogs: (filter?: IRequestLogs) => getLogsAction(filter)(dispatch),
		scrollLogs: (from: number, filter?: IRequestLogs, size?: number) =>
			getLogsScrollAction(from, filter, size)(dispatch),
	};
};

const LogsContainer = connect(mapStateToProps, mapDispatchToProps)(LogsView);
export default LogsContainer;
