import { Reducer } from "redux";
import moment from "moment";
import { HttpError } from "../config/types";

export enum AsyncActionStatus {
	SUCCEEDED = "SUCCEEDED",
	STARTED = "STARTED",
	FAILED = "FAILED",
	UNSTARTED = "UNSTARTED",
}

export interface RequestMetaInfo {
	timestamp: moment.Moment;
	status: AsyncActionStatus;
	error?: Error;
	data?: any;
}

export default interface AsyncState {
	status: AsyncActionStatus;
	erorr?: HttpError;
	requestStatus: { [key: string]: RequestMetaInfo };
}

interface IError extends Error {
	error?: string;
	status?: number;
	timestamp?: string;
	stack?: string;
}

interface StartedAsyncAction<T, S extends string = any> {
	type: T;
	status: AsyncActionStatus.STARTED;
	meta?: S;
}

interface SucceededAsyncAction<T, P = any, S extends string = any> {
	type: T;
	status: AsyncActionStatus.SUCCEEDED;
	payload: P;
	meta?: S;
}

interface FailedAsyncAction<T, S extends string = any> {
	type: T;
	status: AsyncActionStatus.FAILED;
	payload: IError;
	meta?: S;
}

export type AsyncAction<T, P = any, S extends string = any> =
	| StartedAsyncAction<T, S>
	| SucceededAsyncAction<T, P, S>
	| FailedAsyncAction<T, S>;

function startedAsyncAction<T, S extends string>(
	type: T,
	meta: S,
): StartedAsyncAction<T, S> {
	return {
		type,
		status: AsyncActionStatus.STARTED,
		meta,
	};
}

function succeededAsyncAction<T, P, S>(
	type: T,
	payload: P,
	meta: S,
): SucceededAsyncAction<T, P> {
	return {
		type,
		status: AsyncActionStatus.SUCCEEDED,
		payload,
		meta,
	};
}

function failedAsyncAction<T, S extends string>(
	type: T,
	error: Error,
	meta: S,
): FailedAsyncAction<T, S> {
	return {
		type,
		status: AsyncActionStatus.FAILED,
		payload: error,
		meta,
	};
}

export function async<T, P, S extends string>(
	type: T,
	action: (...args: any[]) => Promise<P>,
	meta: S,
	...args: any[]
) {
	return async (dispatch: any) => {
		dispatch(startedAsyncAction(type, meta));
		try {
			const payload = await action(...args);
			dispatch(succeededAsyncAction(type, payload, meta));
		} catch (error) {
			dispatch(failedAsyncAction(type, error as Error, meta));
		}
	};
}

export const AsyncInitialState: AsyncState = {
	status: AsyncActionStatus.UNSTARTED,
	requestStatus: {},
};

export const InitialiseState = () => {
	const as: AsyncState = {
		status: AsyncActionStatus.UNSTARTED,
		requestStatus: {},
	};
	return as;
};

export function WithAsyncActionReducer<T extends string, P extends AsyncState>(
	type: T,
	initialState: P,
	reducer?: Reducer<P, any>,
) {
	const red: Reducer<P, AsyncAction<T>> = (
		state = initialState,
		action: AsyncAction<T>,
	) => {
		let nState: P = state;
		if (reducer) {
			nState = reducer(state, action);
		}
		if (action.type === type) {
			if (action.status === "SUCCEEDED") {
				if (action.meta) {
					nState = { ...nState };
					nState.requestStatus[action.meta] = {
						timestamp: moment(),
						status: action.status,
						data: action.payload,
					};
				} else {
					nState = {
						...nState,
						data: action.payload,
						erorr: undefined,
						status: action.status,
					};
				}
			} else if (action.status === "FAILED") {
				if (action.meta) {
					nState = { ...nState };
					nState.requestStatus[action.meta] = {
						timestamp: moment(),
						status: action.status,
						error: action.payload,
					};
				} else {
					nState = {
						...nState,
						erorr: action.payload,
						status: action.status,
					};
				}
			} else {
				if (action.meta) {
					nState = { ...nState };
					nState.requestStatus[action.meta] = {
						timestamp: moment(),
						status: action.status,
					};
				} else {
					nState = {
						...nState,
						status: action.status,
					};
				}
			}
		}
		return nState;
	};
	return red;
}
