import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { ISchemaState } from "./types";
import { SchemasAction } from "./action";

export const initialSchemaState: ISchemaState = {
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleSchema = (
	state: ISchemaState = initialSchemaState,
	action: SchemasAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (!action.meta) return nState;
			nState[action.meta] = action.payload;
			return nState;
	}
	return state;
};

export const SchemasReducer: Reducer<ISchemaState, SchemasAction> = (
	state = initialSchemaState,
	action,
) => {
	switch (action.type) {
		case "SCHEMA":
			return handleSchema(state, action);
		default:
			return state;
	}
};
