import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { percentage } from "../../../helpers/Utils";
import { THEME } from "../../../config";
import DetailItem from "./DetailItem";

const useStyles = makeStyles(() => ({
	root: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: THEME.palette.background.default,
		padding: 6,
		flex: 1,
		flexDirection: "column",
	},
	detailsBox: {
		justifyContent: "center",
		gap: 4,
		padding: "4px 0",
		flex: 1,
	},
	icons: {
		height: 20,
		width: 20,
	},
	attribute: { display: "flex", gap: 2, alignItems: "center" },
	dividerRight: {
		borderRight: `1px solid ${THEME.palette.grey[500]}`,
		paddingRight: 4,
	},
	boldText: {
		fontWeight: "bold",
	},
	title: {
		borderBottom: `1px solid ${THEME.palette.grey[500]}`,
		color: THEME.palette.secondary.main,
		fontWeight: "bold",
	},
}));

export const LicensePlateDetails: React.FC<{
	data: {
		value: string;
		formattedValue?: string;
		origin?: string;
		detectionConfidence: number;
		ocrConfidence: number;
		characterHeight: number;
		isOccluded?: boolean;
		occlusionConfidence?: number;
	};
}> = ({ data }) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Typography variant="h6" className={classes.title}>
				License Plate
			</Typography>
			<DetailItem title="License Plate" value={data.value} />
			{data.formattedValue && (
				<DetailItem
					rootStyle={{
						paddingLeft: "3ch",
					}}
					title="Formatted"
					value={data.formattedValue}
				/>
			)}
			{data.origin && <DetailItem title="Origin" value={data.origin} />}
			<DetailItem
				title="Detection Confidence"
				value={`${percentage(data.detectionConfidence)}%`}
			/>

			<DetailItem
				title="OCR Confidence"
				value={`${percentage(data.ocrConfidence)}%`}
			/>

			<DetailItem
				title="Character Height"
				value={data.characterHeight.toFixed(2)}
			/>
			{data.occlusionConfidence && (
				<DetailItem
					title="Occluded"
					value={`${data.isOccluded ? "Yes" : "No"} (${percentage(
						data.occlusionConfidence,
					)})`}
				/>
			)}
		</Box>
	);
};
