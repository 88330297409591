import React, { useEffect } from "react";
import {
	ISecretsContainer,
	IgetSecretsRequest,
	ISecret,
	ICreateSecret,
	IUpdateSecret,
} from "../../store/Secrets/types";
import { HttpError } from "../../config/types";
import {
	Button,
	Paper,
	makeStyles,
	Snackbar,
	TextField,
	InputAdornment,
	Box,
} from "@material-ui/core";
import Alert, { IAlert } from "../../components/UI/Alert";
import ConfirmDialog from "../../components/UI/ConfirmDialog";
import Authentication from "../../store/Authentication/AuthenticationStore";
import SecretDialog from "../../components/Dialogs/SecretDialog";
import SecretList from "../../components/Secrets/SecretList";
import {
	deleteSecret,
	updateSecret,
	createSecret,
} from "../../store/Secrets/action";
import { IUserNameRequest, ICurrentUser } from "../../store/Users/types";
import { getUserNames, getUser } from "../../store/Users/action";
import SearchIcon from "@material-ui/icons/Search";
import { handleTaskAction } from "../../helpers/VMSCrud";

export interface ISecretsViewPropsToDispatch {
	loadSecrets: (request: IgetSecretsRequest) => void;
}

export interface ISecretsViewPropsToState {
	secrets: ISecretsContainer;
	error?: HttpError;
}

type ISecretsViewProps = ISecretsViewPropsToDispatch & ISecretsViewPropsToState;

const useStyles = makeStyles(() => ({
	paper: {
		padding: 8,
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		height: "100vh",
		padding: "10px 0",
	},
	actions: {
		display: "flex",
		gap: 4,
	},
}));

const SecretsView: React.FC<ISecretsViewProps> = ({
	secrets,
	loadSecrets,
	error,
}) => {
	const [filter, setFilter] = React.useState<IgetSecretsRequest>({
		page: 0,
		size: 10,
		search: undefined,
	});
	const [selectedSecret, setSelectedSecret] = React.useState<
		string | undefined
	>(undefined);
	const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
	const [secretModal, setSecretModal] = React.useState<boolean>(false);
	const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
	const [alert, setAlert] = React.useState<IAlert | undefined>(undefined);
	const [dialogData, setDialogData] = React.useState<
		ICreateSecret | undefined
	>(undefined);
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [users, setUsers] = React.useState<string[]>([]);
	const [userFilter, setUserFilter] = React.useState<IUserNameRequest>({
		page: undefined,
		size: undefined,
		enabled: undefined,
		userName: undefined,
	});
	const [isReadOnly, setIsReadOnly] = React.useState<boolean>(false);
	const [asyncLoading, setAsyncLoading] = React.useState<boolean>(false);
	const [currentUser, setCurrentUser] = React.useState<ICurrentUser | null>(
		null,
	);

	const classes = useStyles();

	useEffect(() => {
		loadSecrets({
			...filter,
		});
	}, [loadSecrets, filter]);

	const handleSnackOpen = React.useCallback(
		(alert: IAlert) => {
			setAlert(alert);
			if (!openSnackbar) setOpenSnackbar(true);
		},
		[openSnackbar],
	);

	useEffect(() => {
		const loadCurrentUser = async () => {
			try {
				const currentUserData = await getUser();
				setCurrentUser(currentUserData);
			} catch (error) {
				handleSnackOpen({
					message: "Failed to get user: " + (error as Error).message,
					variant: "error",
				});
			}
		};
		loadCurrentUser();
	}, [handleSnackOpen]);

	useEffect(() => {
		let isSubed = true;
		const loadUserNames = async (request: IUserNameRequest) => {
			try {
				const usersData = await getUserNames(request);
				if (isSubed) {
					setUsers(
						usersData.content.filter(
							(username: string) =>
								username !== currentUser?.userName,
						),
					);
				}
			} catch (error) {
				handleSnackOpen({
					message:
						"Failed to get usernames: " + (error as Error).message,
					variant: "error",
				});
			}
		};
		loadUserNames({ ...userFilter });
		return () => {
			isSubed = false;
		};
	}, [userFilter, handleSnackOpen, currentUser]);

	const handleUserSearch = (value: string) => {
		setUserFilter({
			...userFilter,
			userName: value,
		});
	};

	const handleSnackClose = () => setOpenSnackbar(false);

	const handleCreateSuccess = () => {
		handleSnackOpen({
			message: "Secret successfully created.",
			variant: "success",
		});
		loadSecrets({ ...filter });
		setSecretModal(false);
		setSelectedSecret(undefined);
		setAsyncLoading(false);
	};

	const handleCreateError = (msg: string) => {
		handleSnackOpen({
			message: "Create request failed: " + msg,
			variant: "error",
		});
		setAsyncLoading(false);
	};

	const handleCreateSecret = async (newSecret: ICreateSecret) => {
		await handleTaskAction(
			createSecret,
			handleCreateSuccess,
			handleCreateError,
			newSecret,
		);
	};

	const handleEditSuccess = () => {
		handleSnackOpen({
			message: "Secret successfully updated.",
			variant: "success",
		});
		loadSecrets({ ...filter });
		setSecretModal(false);
		setSelectedSecret(undefined);
		setAsyncLoading(false);
	};

	const handleEditError = (msg: string) => {
		handleSnackOpen({
			message: "Update request failed: " + msg,
			variant: "error",
		});
		setAsyncLoading(false);
	};

	const handleEditSecret = async (newSecret: IUpdateSecret) => {
		await handleTaskAction(
			updateSecret,
			handleEditSuccess,
			handleEditError,
			selectedSecret as string,
			newSecret,
		);
	};

	const handleDeleteSuccess = () => {
		handleSnackOpen({
			message: "Secret successfully deleted.",
			variant: "success",
		});
		loadSecrets({ ...filter });
		setSelectedSecret(undefined);
		setConfirmModal(false);
		setAsyncLoading(false);
	};

	const handleDeleteError = (msg: string) => {
		handleSnackOpen({
			message: "Delete request failed: " + msg,
			variant: "error",
		});
		setConfirmModal(false);
		setAsyncLoading(false);
	};

	const handleDeleteSecret = async () => {
		await handleTaskAction(
			deleteSecret,
			handleDeleteSuccess,
			handleDeleteError,
			selectedSecret as string,
		);
	};

	const userHasPermissionToManage = (item: ISecret) => {
		const arr = item.permissions.map(
			(permission) =>
				permission.user === currentUser?.userName && permission.manage,
		);
		return arr.indexOf(true) >= 0;
	};

	const handleSecret = (option: string) => {
		switch (option) {
			case "create": {
				setDialogData(undefined);
				setSecretModal(true);
				break;
			}
			case "edit": {
				const secret = secrets.content.find(
					(el) => el.id === selectedSecret,
				) as ISecret;
				if (secret) {
					if (!userHasPermissionToManage(secret)) {
						setIsReadOnly(true);
					}
					setDialogData({
						name: secret.name,
						description: secret.description,
						permissions: secret.permissions,
					});
					setSelectedSecret(secret.id);
					setSecretModal(true);
				}
				break;
			}
		}
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setFilter({ ...filter, page: newPage });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFilter({
			...filter,
			page: 0,
			size: parseInt(event.target.value, 10),
		});
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter({ ...filter, page: 0, search: event.target.value });
	};

	const handleSecretClick = (secret: string) => {
		setSelectedSecret(secret);
		if (selectedSecret) {
			setDisabled(false);
		}
	};

	const handleModalClose = () => {
		if (isReadOnly) {
			setIsReadOnly(false);
		}
		setSecretModal(false);
		setSelectedSecret(undefined);
	};

	return (
		currentUser && (
			<>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={error !== undefined}
				>
					<div>
						<Alert
							alert={{
								message: error ? error.message : "",
								variant: "error",
							}}
						/>
					</div>
				</Snackbar>
				<Box className={classes.wrapper}>
					<Paper className={classes.paper}>
						<TextField
							autoFocus
							onChange={handleSearch}
							value={filter.search ? filter.search : ""}
							style={{ width: "100%" }}
							margin="dense"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							placeholder="Find by Name or Description"
						/>
					</Paper>
					<Box className={classes.actions}>
						{Authentication.isAuthority("ROLE_ADMINISTRATOR") ||
						Authentication.isAuthority("ROLE_SECRETS_OPERATOR") ? (
							<Button onClick={() => handleSecret("create")}>
								Create
							</Button>
						) : null}
						<Button
							onClick={() => handleSecret("edit")}
							disabled={disabled && selectedSecret === undefined}
						>
							Edit
						</Button>
						<Button
							onClick={() => setConfirmModal(true)}
							disabled={disabled && selectedSecret === undefined}
						>
							Delete
						</Button>
						<Button
							onClick={() => {
								setIsReadOnly(true);
								handleSecret("edit");
							}}
							disabled={selectedSecret === undefined}
						>
							View
						</Button>
					</Box>
					<SecretList
						secrets={secrets}
						onSecret={handleSecretClick}
						onClickAway={() => {
							setDisabled(true);
						}}
						filter={filter}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						selected={selectedSecret}
						onEdit={() => handleSecret("edit")}
					/>
				</Box>
				<ConfirmDialog
					open={confirmModal}
					onConfirm={handleDeleteSecret}
					onClose={() => setConfirmModal(false)}
					onLoading={() => setAsyncLoading(false)}
					loading={asyncLoading}
				>
					Are you sure you want to delete this secret?
				</ConfirmDialog>
				<SecretDialog
					open={secretModal}
					data={dialogData}
					close={handleModalClose}
					onConfirm={
						dialogData ? handleEditSecret : handleCreateSecret
					}
					currentUser={currentUser}
					users={users}
					onUserSearch={handleUserSearch}
					isReadOnly={isReadOnly}
					onLoading={() => setAsyncLoading(true)}
					loading={asyncLoading}
				/>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={handleSnackClose}
				>
					<div>
						<Alert alert={alert as IAlert} />
					</div>
				</Snackbar>
			</>
		)
	);
};

export default SecretsView;
