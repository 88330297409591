import React from "react";
import {
	Divider,
	Grid,
	makeStyles,
	Paper,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	IConfigurationProperty,
	PropertiesGroup,
} from "../../store/Properties/types";
import { IDefinition } from "../../store/Definitions/types";
import PropertyGroupItem from "./PropertyGroupItem";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(() => ({
	root: {
		padding: 5,
		marginTop: 10,
	},
	groupName: {
		paddingLeft: 5,
	},
	paper: {
		paddingBottom: 5,
	},
	list: {
		marginTop: 5,
	},
	service: {
		marginBottom: 10,
	},
	description: {
		marginTop: 10,
		marginRight: 15,
	},
}));

interface IPropertiesProps {
	config: IConfigurationProperty;
	onConfig(newConfig: IConfigurationProperty): void;
	definition: IDefinition;
	setDisabled(disabled: boolean): void;
	saveDisabled: boolean;
}

const Properties: React.FC<IPropertiesProps> = ({
	config,
	onConfig,
	definition,
	setDisabled,
	saveDisabled,
}) => {
	const classes = useStyles();

	const handleConfigUpdate = (changedValue: string, key: string) => {
		const newProperty = { ...config };
		let idx = -1;
		for (let i = 0; i < newProperty.values.length; i++) {
			if (newProperty.values[i].key === key) {
				idx = i;
				break;
			}
		}
		if (idx !== -1) {
			newProperty.values[idx].value = changedValue;
		}
		onConfig(newProperty);
	};

	const groupProperties = () => {
		const arrOfArrs: PropertiesGroup[] = [];
		let counter = 0;
		for (let i = 0; i < definition.properties.length; i++) {
			if (i === 0) {
				arrOfArrs.push({
					groupName: definition.properties[i].group,
					properties: [],
				});
			} else if (
				arrOfArrs[counter].groupName !== definition.properties[i].group
			) {
				arrOfArrs.push({
					groupName: definition.properties[i].group,
					properties: [],
				});
				counter++;
			}
			arrOfArrs[counter].properties.push({
				...definition.properties[i],
				value: config.values[i] ? config.values[i].value : "",
			});
		}
		return arrOfArrs;
	};

	return (
		<Grid container className={classes.root}>
			{definition ? (
				<Grid item container xs={12} sm={10} md={8} lg={6} xl={4}>
					<Grid item xs={12} container justifyContent="space-between">
						<Grid item>
							<Typography
								variant="h4"
								className={classes.service}
							>
								{config.service}
							</Typography>
						</Grid>
						<Grid item>
							{config.description ? (
								<Tooltip title={config.description}>
									<HelpIcon className={classes.description} />
								</Tooltip>
							) : null}
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						{groupProperties().map((group, i) => (
							<Grid item xs={12} key={i}>
								<Paper className={classes.paper}>
									<Grid container>
										<Grid item xs={12}>
											<Typography
												variant="h6"
												className={classes.groupName}
											>
												{group.groupName}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
										<Grid
											item
											container
											className={classes.list}
										>
											{group.properties.map(
												(property, j) => (
													<PropertyGroupItem
														key={j}
														properties={
															group.properties
														}
														property={property}
														onUpdate={
															handleConfigUpdate
														}
														setDisabled={
															setDisabled
														}
														saveDisabled={
															saveDisabled
														}
													/>
												),
											)}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						))}
					</Grid>
				</Grid>
			) : null}
		</Grid>
	);
};

export default Properties;
