import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { async, AsyncAction, AsyncActionStatus } from "../AsyncState";
import { PagedContent } from "../types";
import {
	IUser,
	IUserRequest,
	UsersPagedContent,
	IUserNameRequest,
	ICurrentUser,
} from "./types";

export type Users = "Users" | "Users_Loading" | "Users_Filter";
export type UsersAction = AsyncAction<
	Users,
	PagedContent<IUser[]> | IUser | boolean | IUserRequest,
	string
>;

const getUsers = async (request: IUserRequest) => {
	try {
		const response = await Axios.get<UsersPagedContent>(
			API_BASE_PREFIX + "authorization/user",
			{
				params: request,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getUserNames = async (request: IUserNameRequest) => {
	try {
		const response = await Axios.get(
			API_BASE_PREFIX + "authorization/user/username",
			{
				params: request,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getUser = async (): Promise<ICurrentUser> => {
	try {
		const response = await Axios.get(
			API_BASE_PREFIX + "authorization/auth/user",
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const addUser = async (user: IUser) => {
	try {
		const response = await Axios.post(
			API_BASE_PREFIX + "authorization/user",
			user,
			{ validateStatus: () => true },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const editUser = async (user: {
	id?: number;
	userName?: string;
	email?: string;
	password?: string;
	enabled?: boolean;
	authorities?: string[];
}) => {
	try {
		const response = await Axios.post(
			API_BASE_PREFIX + `authorization/user/${user.id}`,
			user,
			{ validateStatus: () => true },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getUsersAction(filter: IUserRequest) {
	return async("Users", getUsers, "", filter);
}

export function setUsersFilterAction(filter: IUserRequest) {
	return {
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Users_Filter",
	};
}
