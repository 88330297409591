import axios from "axios";

import { history } from "../App";
import Authentication from "../store/Authentication/AuthenticationStore";
import { HttpError } from "./types";

const configAxios = () => {
	axios.interceptors.request.use((config) => {
		if (Authentication.isAuthenticated()) {
			const authenticationToken =
				Authentication.getTokenType() +
				" " +
				Authentication.getAccessToken();
			config.headers.Authorization = authenticationToken;
		} else {
			Authentication.clearAuthentication();
			history.push("/logout");
		}
		return config;
	});

	axios.interceptors.response.use(undefined, async (error) => {
		let httpError: HttpError | null = null;
		if (error?.code === "ERR_CANCELED") return Promise.reject();
		if (!error.response?.status) {
			httpError = new HttpError(
				new Date().valueOf(),
				-1,
				error.message,
				error.message,
			);
		} else if (error.response.status === 401) {
			if (Authentication.isTokenRefreshing) {
				const timeoutPromise = new Promise((_, reject) => {
					setTimeout(() => reject(), 10000);
				});

				await Promise.race([
					new Promise((resolve) => {
						const interval = setInterval(() => {
							if (!Authentication.isTokenRefreshing) {
								clearInterval(interval);
								resolve(null);
							}
						}, 100);
					}),
					timeoutPromise,
				]);
				if (Authentication.getAccessToken()) {
					const config = error.config;
					return await axios.request(config);
				} else {
					Authentication.clearAuthentication();
					history.push("/login");
				}
			} else {
				const newToken = await Authentication.refreshToken();
				if (newToken) {
					const config = error.config;

					return await axios.request(config);
				} else {
					Authentication.clearAuthentication();
					history.push("/login");
				}
			}
		} else {
			httpError = new HttpError(
				error.response.data.timestamp,
				error.response.data.status,
				error.response.data.error,
				error.response.data.message ?? "Service unavailable",
			);
		}
		return Promise.reject(httpError);
	});
};

export const API_BASE_PREFIX = "/rs/";
export const API_ELASTICSEARCH_LOGS =
	API_BASE_PREFIX + "elastic/sentiveillance-logs/";
export const API_ELASTICSEARCH_EVENTS =
	API_BASE_PREFIX + "elastic/sentiveillance-events";
export const API_USERCONFIG = API_BASE_PREFIX + `userConfig`;
export default configAxios;
