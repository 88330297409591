import {
	Box,
	makeStyles,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
	PauseRounded,
	PlayArrowRounded,
	FastForwardRounded,
	FastRewindRounded,
	SpeedRounded,
	FormatLineSpacingRounded,
	Fullscreen,
	FullscreenExit,
	OndemandVideoRounded,
} from "@material-ui/icons";
import { THEME } from "../../../config";
import moment from "moment";
import _ from "lodash";
import { camelCaseToReadable } from "../../../helpers/Filter";
import { TimelineScale } from "../../VideoPlayers/EventsStream";

export interface IControls {
	videoController: { [key: string]: any } | undefined;
	timestamp: number | null;
	disabled: boolean;
	playSpeed: number;
	setPlaySpeed: (playSpeed: IControls["playSpeed"]) => void;
	isPlaying: boolean;
	setIsPlaying: (isPlaying: IControls["isPlaying"]) => void;
	timelineScale?: number;
	setTimelineScale?: (timelineScale: number) => void;
	setMaximized: React.Dispatch<React.SetStateAction<boolean>>;
	maximized: boolean;
	onLiveClick?: () => void;
}

const useStyles = makeStyles(() => ({
	wrapper: {
		position: "relative",
		display: "flex",
		gap: "6px",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: THEME.palette.grey[900],
		color: "white",
		width: "100%",
	},
	icon: {
		color: "white",
		cursor: "pointer",
		fontSize: "2rem",
		boxSizing: "content-box",
		border: `3px solid transparent`,
	},
	background: {
		backgroundColor: THEME.palette.grey[900],
	},
	controls: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: THEME.palette.grey[900],
		gap: "6px",
		zIndex: 100,
	},
	menuItem: {
		color: "white",
		fontWeight: 500,
	},
	absoluteRight: {
		position: "absolute",
		right: 10,
		display: "flex",
		alignItems: "center",
	},
	absoluteLeft: {
		position: "absolute",
		left: 10,
		display: "flex",
		alignItems: "center",
	},
	tooltip: {
		zIndex: 3000,
	},
}));

const speedOptions = [0.25, 0.5, 0.75, 1, 2, 5, 10];

const StreamControls = ({
	videoController,
	timestamp,
	disabled,
	isPlaying,
	setIsPlaying,
	playSpeed,
	setPlaySpeed,
	timelineScale,
	setTimelineScale,
	setMaximized,
	maximized,
	onLiveClick,
}: IControls) => {
	const classes = useStyles();

	const [anchorEls, setAnchorEls] = useState<{
		speed: null | SVGSVGElement;
		scale: null | SVGSVGElement;
	}>({
		speed: null,
		scale: null,
	});

	const pause = () => {
		if (isPlaying) {
			setIsPlaying(false);
			XPMobileSDK.playbackSpeed(videoController, 0);
		}
	};

	const play = (speed?: number) => {
		if (videoController) {
			setIsPlaying(true);
			XPMobileSDK.playbackSpeed(videoController, speed ?? playSpeed);
		}
	};

	const changePlaybackSpeed = (speed: number, playingNow: boolean) => {
		if (!videoController) return;
		setIsPlaying(playingNow);
		setPlaySpeed(speed);
		if (playingNow) {
			XPMobileSDK.playbackSpeed(videoController, speed);
		}
	};

	const handleFastForward = () => {
		const speed = Math.abs(playSpeed) || 1;
		play(speed);
		if (isPlaying && speed === playSpeed) return;
		changePlaybackSpeed(speed, true);
	};

	const handleFastRewind = () => {
		const speed = -Math.abs(playSpeed) || -1;
		changePlaybackSpeed(speed, true);
		play(speed);
	};

	useEffect(() => {
		const handleESC = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				setMaximized(false);
			}
		};
		document.addEventListener("keydown", handleESC);
		return () => document.removeEventListener("keydown", handleESC);
	}, [setMaximized]);

	return (
		<div
			className={classes.wrapper}
			style={{ pointerEvents: disabled ? "none" : "all" }}
		>
			{timestamp ? (
				<Typography className={classes.absoluteLeft}>
					{moment(timestamp).format("YYYY-MM-DD HH:mm:ss")}
				</Typography>
			) : (
				<Box />
			)}
			<div className={classes.controls}>
				<FastRewindRounded
					className={classes.icon}
					style={{
						color:
							playSpeed < 0
								? THEME.palette.success.light
								: "inherit",
						borderBottom:
							playSpeed < 0
								? `3px solid ${THEME.palette.success.main}`
								: "3px solid transparent",
					}}
					onClick={handleFastRewind}
				/>
				{!isPlaying ? (
					<PlayArrowRounded
						className={classes.icon}
						onClick={() => play()}
					/>
				) : (
					<PauseRounded className={classes.icon} onClick={pause} />
				)}
				<FastForwardRounded
					className={classes.icon}
					style={{
						color:
							playSpeed > 0
								? THEME.palette.success.light
								: "inherit",
						borderBottom:
							playSpeed > 0
								? `3px solid ${THEME.palette.success.main}`
								: "3px solid transparent",
					}}
					onClick={handleFastForward}
				/>
			</div>
			<div className={classes.absoluteRight}>
				{Math.abs(playSpeed) !== 1 && (
					<Typography style={{ color: THEME.palette.success.main }}>
						{Math.abs(playSpeed)}x
					</Typography>
				)}
				<Tooltip
					title="Playback speed"
					classes={{ popper: classes.tooltip }}
				>
					<SpeedRounded
						className={classes.icon}
						onClick={(e) =>
							setAnchorEls({
								...anchorEls,
								speed: e.currentTarget,
							})
						}
					/>
				</Tooltip>
				<Tooltip
					title="Timeline scale"
					classes={{ popper: classes.tooltip }}
				>
					<FormatLineSpacingRounded
						className={classes.icon}
						onClick={(e) =>
							setAnchorEls({
								...anchorEls,
								scale: e.currentTarget,
							})
						}
					/>
				</Tooltip>
				{onLiveClick && (
					<Tooltip
						title="Live video"
						classes={{ popper: classes.tooltip }}
					>
						<OndemandVideoRounded
							className={classes.icon}
							style={{
								fontSize: "1.69rem",
								pointerEvents: "all",
							}}
							onClick={onLiveClick}
						/>
					</Tooltip>
				)}
				<Tooltip
					title={maximized ? "Minimize (ESC)" : "Maximize"}
					classes={{ popper: classes.tooltip }}
				>
					{maximized ? (
						<FullscreenExit
							className={classes.icon}
							onClick={() => setMaximized(false)}
						/>
					) : (
						<Fullscreen
							className={classes.icon}
							onClick={() => setMaximized(true)}
						/>
					)}
				</Tooltip>
			</div>
			<Menu
				style={{
					zIndex: 3000,
				}}
				classes={{ paper: classes.background }}
				anchorEl={anchorEls.speed}
				keepMounted
				open={Boolean(anchorEls.speed)}
				onClose={() => setAnchorEls({ ...anchorEls, speed: null })}
			>
				{speedOptions.map((speed) => (
					<MenuItem
						key={speed}
						className={classes.menuItem}
						style={{
							color:
								Math.abs(playSpeed) === speed
									? THEME.palette.success.main
									: "",
						}}
						onClick={() => {
							setAnchorEls({ ...anchorEls, speed: null });
							changePlaybackSpeed(
								speed * Math.sign(playSpeed),
								isPlaying,
							);
						}}
					>
						{speed === 1 ? "Normal" : `${speed}x`}
					</MenuItem>
				))}
			</Menu>
			{timelineScale && setTimelineScale && (
				<Menu
					style={{
						zIndex: 3000,
					}}
					classes={{ paper: classes.background }}
					anchorEl={anchorEls.scale}
					keepMounted
					open={Boolean(anchorEls.scale)}
					onClose={() => setAnchorEls({ ...anchorEls, scale: null })}
				>
					{_.map(TimelineScale, (scale, key) => (
						<MenuItem
							key={scale}
							className={classes.menuItem}
							style={{
								color:
									scale === timelineScale
										? THEME.palette.success.main
										: "",
							}}
							onClick={() => {
								setAnchorEls({ ...anchorEls, scale: null });
								setTimelineScale(scale);
							}}
						>
							{camelCaseToReadable(key)}
						</MenuItem>
					))}
				</Menu>
			)}
		</div>
	);
};

export default StreamControls;
