import React, { useCallback } from "react";
import {
	Button,
	Grid,
	makeStyles,
	Box,
	CircularProgress,
} from "@material-ui/core";
import { THEME } from "../../../config/index";
import LabelledOutline from "../../UI/LabelledOutline";
import { Form } from "react-final-form";
import { IEditSubject, ISubject } from "../../../store/Subjects/types";
import RecordsView from "../../Records/RecordsView";
import { IWatchlist } from "../../../store/Watchlists/types";
import FormMetadata from "./FormMetadata";
import { checkMetadata, isJustAnalyst } from "../../../helpers/Allowances";
import { errorsInBody } from "../../../helpers/ValidateInput";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

const useStyles = makeStyles(() => ({
	responsivePhoto: {
		objectFit: "contain",
		width: "100%",
		height: "100%",
	},
	box: {
		borderColor: THEME.palette.secondary.main,
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		alignItems: "center",
		width: 240,
	},
	records: {
		marginTop: 50,
	},
}));

interface IEditFaceForm {
	onConfirm(body: IEditSubject): void;
	onClose(): void;
	schema: IMetadataSchema;
	initialMetadata: IMetadata;
	subject: ISubject;
	watchlist: IWatchlist;
	onLoading(): void;
	loading: boolean;
}

const FaceEditForm: React.FC<IEditFaceForm> = ({
	onConfirm,
	onClose,
	schema,
	initialMetadata,
	subject,
	watchlist,
	onLoading,
	loading,
}) => {
	const [image, setImage] = React.useState<string>(subject.thumb as string);

	const [metadata, setMetadata] = React.useState<IMetadata>(initialMetadata);
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

	const classes = useStyles();

	const handleFormSubmit = () => {
		const formErrors = errorsInBody(schema.properties, metadata);
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		} else {
			setErrors({});
		}

		onLoading();
		const body: IEditSubject = {
			metadata: JSON.stringify(checkMetadata(schema, metadata)),
			image: image as string,
		};
		onConfirm(body);
	};

	const handleImageChange = (nImage: string) => {
		setImage(nImage);
	};

	const handleMetadata = useCallback(
		(newMeta: IMetadata) => {
			if (JSON.stringify(newMeta) !== JSON.stringify(metadata)) {
				setMetadata(newMeta);
			}
		},
		[metadata],
	);

	const isAnalyst = isJustAnalyst();

	return (
		<Form
			onSubmit={handleFormSubmit}
			initialValues={{ id: subject.name }}
			render={({ handleSubmit, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6} md={5}>
							<Grid
								container
								direction="column"
								alignItems="center"
								justifyContent="center"
								style={{ minHeight: "100%" }}
							>
								<Box className={classes.box} border={1}>
									<img
										className={classes.responsivePhoto}
										alt="License Plate"
										src={`data:image/jpeg;base64,${image}`}
									/>
								</Box>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6} md={7}>
							<Grid container direction="column">
								<Grid item>
									<LabelledOutline
										label="Metadata"
										id="create-face-form-metadata-label"
									>
										<FormMetadata
											schema={schema}
											onUpdate={handleMetadata}
											metadata={metadata}
											isReadOnly={isAnalyst}
											errors={errors}
										/>
									</LabelledOutline>
								</Grid>
								{!isAnalyst ? (
									<Grid item style={{ marginBottom: 15 }}>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												marginTop: 5,
											}}
										>
											{loading ? (
												<CircularProgress />
											) : null}
											<Button
												type="submit"
												disabled={
													loading ||
													(pristine &&
														subject.thumb ===
															image &&
														metadata ===
															initialMetadata)
												}
											>
												Apply Changes
											</Button>
											<Button onClick={onClose}>
												Cancel
											</Button>
										</div>
									</Grid>
								) : null}
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.records}>
							<RecordsView
								watchlist={watchlist}
								subjectId={subject.name}
								onImage={handleImageChange}
								mainImage={image}
							/>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default FaceEditForm;
