import React from "react";
import { isJustAnalyst } from "../../helpers/Allowances";
import { Grid, Button } from "@material-ui/core";

interface IRecordsButtonsProps {
	onPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onRemove: () => void;
	onImage: () => void;
	isRemoveDisabled: boolean;
	isThumbDisabled: boolean;
	onView: () => void;
	selected?: number;
}

const RecordsButtons: React.FC<IRecordsButtonsProps> = (props) =>
	isJustAnalyst() ? (
		<Grid container>
			<Grid item>
				<Button
					onClick={props.onView}
					disabled={props.selected === undefined}
				>
					View
				</Button>
			</Grid>
		</Grid>
	) : (
		<Grid container>
			<Grid item>
				<Button component="label">
					Add
					<input
						hidden
						type="file"
						name="image"
						id="file"
						accept=".jpeg, .png, .jpg"
						onChange={(event) => props.onPhoto(event)}
					/>
				</Button>
			</Grid>
			<Grid item>
				<Button
					onClick={props.onRemove}
					disabled={props.isRemoveDisabled}
				>
					Remove
				</Button>
			</Grid>
			<Grid item>
				<Button
					onClick={props.onImage}
					disabled={props.isThumbDisabled}
				>
					Set as thumbnail
				</Button>
			</Grid>
			<Grid item>
				<Button
					onClick={props.onView}
					disabled={props.selected === undefined}
				>
					View
				</Button>
			</Grid>
		</Grid>
	);

export default RecordsButtons;
