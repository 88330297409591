import { connect } from "react-redux";
import WatchlistsView, {
	IWatchlistViewPropsToDispatch,
	IWatchlistViewPropsToState,
} from "./WatchlistsView";
import { AppState } from "../../store";
import { IgetWatchlistsRequest } from "../../store/Watchlists/types";
import { getWatchlistsAction } from "../../store/Watchlists/action";

const mapStateToProps = (state: AppState): IWatchlistViewPropsToState => {
	return {
		error: state.watchlists.erorr,
		watchlists: state.watchlists.data,
	};
};

const mapDispatchToProps = (dispatch: any): IWatchlistViewPropsToDispatch => {
	return {
		loadWatchlists: (request: IgetWatchlistsRequest) =>
			getWatchlistsAction(request)(dispatch),
	};
};

const WatchlistsContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(WatchlistsView);
export default WatchlistsContainer;
