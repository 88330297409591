import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
	IgetWatchlistsRequest,
	IWatchlistsContainer,
	IWatchlist,
} from "../../store/Watchlists/types";
import WatchlistsList from "./WatchlistsList";
import WatchlistsButtons from "./WatchlistsButtons";
import WatchlistDialog from "../Dialogs/WatchlistDialog";
import {
	createWatchlist,
	deleteWatchlist,
	changeWatchlistDescription,
} from "../../store/Watchlists/action";
import { IAlert } from "../UI/Alert";
import ConfirmDialog from "../UI/ConfirmDialog";
import { getSecret } from "../../store/Secrets/action";
import { checkPermissions } from "../../containers/Layout/Layout";
import BounceTextField from "../UI/BounceTextField";
import { handleTaskAction } from "../../helpers/VMSCrud";
import Pagination from "../UI/Pagination";

interface IWatchlistsOwnProps {
	watchlists: IWatchlistsContainer;
	filter: IgetWatchlistsRequest;
	setFilter: React.Dispatch<React.SetStateAction<IgetWatchlistsRequest>>;
	loadWatchlists(request: IgetWatchlistsRequest): void;
	selected: number;
	setSelected: (watchlist: number, subject: number) => void;
	onSnack(alert: IAlert): void;
}

interface IWatchlistsProps extends IWatchlistsOwnProps {}

const Watchlists: React.FC<IWatchlistsProps> = ({
	watchlists,
	filter,
	setFilter,
	loadWatchlists,
	selected,
	setSelected,
	onSnack,
}) => {
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [dialogData, setDialogData] = React.useState<IWatchlist | undefined>(
		undefined,
	);
	const [openConfirmDialog, setOpenConfirmDialog] =
		React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const handleWatchlistsChangePage = (newPage: number) => {
		setFilter({ ...filter, page: newPage });
		setSelected(0, 0);
	};

	const handleWatchlistsChangeRowsPerPage = (newSize: number) => {
		setFilter({
			...filter,
			page: 0,
			size: newSize,
		});
		if (selected + 1 > newSize) setSelected(0, 0);
	};

	const loadDialogData = async () => {
		try {
			if (watchlists.content[selected].secret) {
				const secret = await getSecret(
					watchlists.content[selected].secret,
				);
				setDialogData({
					id: watchlists.content[selected].id,
					type: watchlists.content[selected].type,
					secret: secret.name,
					description: watchlists.content[selected].description,
				});
			} else {
				setDialogData({
					id: watchlists.content[selected].id,
					type: watchlists.content[selected].type,
					description: watchlists.content[selected].description,
					secret: "(No secret)",
				});
			}
			return true;
		} catch (error) {
			const alert: IAlert = {
				message: (error as Error).message as string,
				variant: "error",
			};
			onSnack(alert);
			return false;
		}
	};

	const handleWatchlist = async (option: string) => {
		switch (option) {
			case "create": {
				setDialogData(undefined);
				setOpenDialog(true);
				break;
			}
			case "delete": {
				setOpenConfirmDialog(true);
				break;
			}
			case "edit": {
				if (await loadDialogData()) {
					setOpenDialog(true);
				}
			}
		}
	};

	const handleCreateSuccess = () => {
		onSnack({
			message: "Watchlist successfully created.",
			variant: "success",
		});
		loadWatchlists({ ...filter });
		setSelected(0, 0);
		setOpenDialog(false);
		setLoading(false);
	};

	const handleCreateError = (msg: string) => {
		onSnack({
			message: "Failed to create watchlist: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleCreateWatchlist = async (watchlist: IWatchlist) => {
		await handleTaskAction(
			createWatchlist,
			handleCreateSuccess,
			handleCreateError,
			watchlist,
		);
	};

	const handleDeleteSuccess = () => {
		onSnack({
			message: "Successfully deleted watchlist.",
			variant: "success",
		});
		if (filter.page && filter.page > 0 && watchlists.content.length === 1) {
			setFilter({
				...filter,
				page: filter.page - 1,
			});
		} else {
			loadWatchlists({ ...filter });
		}
		setSelected(0, 0);
		setLoading(false);
		setOpenConfirmDialog(false);
	};

	const handleDeleteError = (msg: string) => {
		setLoading(false);
		onSnack({
			message: "Failed to delete Watchlist: " + msg,
			variant: "error",
		});
		setOpenConfirmDialog(false);
	};

	const handleDeleteWatchlist = async () => {
		await handleTaskAction(
			deleteWatchlist,
			handleDeleteSuccess,
			handleDeleteError,
			watchlists.content[selected].id,
			{
				secret: watchlists.content[selected].secret,
			},
		);
	};

	const handleEditSuccess = () => {
		onSnack({
			message: "Watchlist successfully updated.",
			variant: "success",
		});
		loadWatchlists({ ...filter });
		setOpenDialog(false);
		setLoading(false);
	};

	const handleEditError = (msg: string) => {
		onSnack({
			message: "Failed to update watchlist: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleEditWatchlist = async (description: string) => {
		await handleTaskAction(
			changeWatchlistDescription,
			handleEditSuccess,
			handleEditError,
			watchlists.content[selected].id,
			description,
			{ secret: watchlists.content[selected].secret },
		);
	};

	const handleSearchChange = (val: string) => {
		setFilter({
			...filter,
			page: 0,
			find: val,
		});
		setSelected(0, 0);
	};

	return (
		<>
			<Grid container direction="column">
				<Grid item xs={12} md={12}>
					<Typography variant="h4">Watchlists</Typography>
				</Grid>
				{checkPermissions([
					"ROLE_ADMINISTRATOR",
					"ROLE_WATCHLIST_ADMINISTRATOR",
				]) ? (
					<Grid item xs={12} md={12}>
						<WatchlistsButtons
							click={handleWatchlist}
							isDisabled={watchlists.content.length === 0}
						/>
					</Grid>
				) : null}
				<Grid item xs={12} md={12}>
					<WatchlistsList
						watchlists={watchlists}
						selected={selected}
						setSelected={setSelected}
						dblClick={handleWatchlist}
					/>
				</Grid>
				<Grid item xs={12} md={12} style={{ marginTop: 5 }}>
					<BounceTextField
						onChange={handleSearchChange}
						style={{ width: "100%" }}
						placeholder="Find by Name or Description"
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<Pagination
						container={watchlists}
						filter={filter}
						rowsPerPageOptions={[5, 10, 20]}
						onChangeRowsPerPage={handleWatchlistsChangeRowsPerPage}
						onChangePage={handleWatchlistsChangePage}
					/>
				</Grid>
			</Grid>
			<ConfirmDialog
				open={openConfirmDialog}
				onConfirm={handleDeleteWatchlist}
				onClose={() => setOpenConfirmDialog(false)}
				loading={loading}
				onLoading={() => setLoading(true)}
			>
				Are you sure you want to delete this watchlist?
			</ConfirmDialog>
			<WatchlistDialog
				open={openDialog}
				close={() => setOpenDialog(false)}
				data={dialogData}
				onConfirm={
					dialogData ? handleEditWatchlist : handleCreateWatchlist
				}
				loading={loading}
				onLoading={() => setLoading(true)}
			/>
		</>
	);
};

export default Watchlists;
