import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import {
	BackwardBoxes,
	ConfigActions,
	EventsViewSide,
	IConfig,
	IConfigResponse,
	IConfigState,
	IEventsViewConfig,
	ILayoutConfig,
	ILiveCamerasConfig,
	IPreference,
	ProbeChoice,
} from "./types";
import { IFavorite } from "../../components/Events/Filter/Favorites";
import moment from "moment";
import { DEFAULT_MAX_EVENTS_LIMIT } from "../LiveCameras/reducer";
import { v4 as uuid } from "uuid";

export const DEFAULT_EVENT_VIEW_NAME = "Default";
export const getDefaultEventView = (layoutId: string): IEventsViewConfig => ({
	maxEventsLimit: DEFAULT_MAX_EVENTS_LIMIT,
	enabledVms: [],
	enabledSources: [],
	enabledRules: [],
	enabledAll: true,
	name: DEFAULT_EVENT_VIEW_NAME,
	id: uuid(),
	layoutId,
});

export const colsWidthKey = "colsWidth";
export const predefinedLayouts: ILayoutConfig[] = [
	{
		id: "predefined-1",
		name: "1x1",
		cameras: [],
		areas: [{ x: 1, y: 1, width: 12, height: 12 }],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-2",
		name: "1x2",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 12, height: 6 },
			{ x: 1, y: 7, width: 12, height: 6 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-3",
		name: "2x1",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 6, height: 12 },
			{ x: 7, y: 1, width: 6, height: 12 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-4",
		name: "2x2",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 6, height: 6 },
			{ x: 7, y: 1, width: 6, height: 6 },
			{ x: 1, y: 7, width: 6, height: 6 },
			{ x: 7, y: 7, width: 6, height: 6 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-5",
		name: "3x2",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 4, height: 6 },
			{ x: 5, y: 1, width: 4, height: 6 },
			{ x: 9, y: 1, width: 4, height: 6 },
			{ x: 1, y: 7, width: 4, height: 6 },
			{ x: 5, y: 7, width: 4, height: 6 },
			{ x: 9, y: 7, width: 4, height: 6 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-6",
		name: "3x3",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 4, height: 4 },
			{ x: 5, y: 1, width: 4, height: 4 },
			{ x: 9, y: 1, width: 4, height: 4 },
			{ x: 1, y: 5, width: 4, height: 4 },
			{ x: 5, y: 5, width: 4, height: 4 },
			{ x: 9, y: 5, width: 4, height: 4 },
			{ x: 1, y: 9, width: 4, height: 4 },
			{ x: 5, y: 9, width: 4, height: 4 },
			{ x: 9, y: 9, width: 4, height: 4 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-7",
		name: "1+3",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 12, height: 8 },
			{ x: 1, y: 9, width: 4, height: 4 },
			{ x: 5, y: 9, width: 4, height: 4 },
			{ x: 9, y: 9, width: 4, height: 4 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-8",
		name: "1+5",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 8, height: 8 },
			{ x: 9, y: 1, width: 4, height: 4 },
			{ x: 9, y: 5, width: 4, height: 4 },
			{ x: 1, y: 9, width: 4, height: 4 },
			{ x: 5, y: 9, width: 4, height: 4 },
			{ x: 9, y: 9, width: 4, height: 4 },
		],
		viewsOrder: { left: [], right: [] },
	},
	{
		id: "predefined-9",
		name: "2+4",
		cameras: [],
		areas: [
			{ x: 1, y: 1, width: 6, height: 8 },
			{ x: 7, y: 1, width: 6, height: 8 },
			{ x: 1, y: 9, width: 3, height: 4 },
			{ x: 4, y: 9, width: 3, height: 4 },
			{ x: 7, y: 9, width: 3, height: 4 },
			{ x: 10, y: 9, width: 3, height: 4 },
		],
		viewsOrder: { left: [], right: [] },
	},
];

export const initialConfigState: IConfigState = {
	data: {
		favorite: [],
		history: [],
		preference: {
			name: "userPreferences",
			groups: [],
			favorites: [],
			showFavoriteFilters: true,
			id: "1",
			savedInServer: false,
			lastViewedReportTitle: "",
			backwardBoxesOrder: [
				BackwardBoxes.GALLERY,
				BackwardBoxes.PROBE,
				BackwardBoxes.VIDEO,
			],
			backwardSortByScore: false,
			backwardAcceptLowQuality: false,
			probeChoice: ProbeChoice.ORIGINAL,
		},
		live: undefined,
		layouts: predefinedLayouts,
		eventsViews: [],
	},
	status: AsyncActionStatus.UNSTARTED,
	requestStatus: {},
	existsInServer: true,
	loaded: false,
};

const getConfig = (
	state: IConfigState = initialConfigState,
	action: ConfigActions,
) => {
	const nState = { ...state };
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const payload = action.payload as IConfigResponse;
			const content = payload.content;
			const favorites = content.filter((c) =>
				c.key.startsWith("favorite"),
			);
			const history = content.filter((c) => c.key.startsWith("history"));
			const preference = content.find((c) =>
				c.key.startsWith("preference"),
			);
			const live = content.find((c) => c.key.startsWith("live"));
			const layouts = content.filter((c) => c.key.startsWith("layouts"));
			const eventsViews = content.filter((c) =>
				c.key.startsWith("eventsView"),
			);

			nState.data.layouts = [
				...layouts.map((l) => JSON.parse(l.value)),
				...predefinedLayouts,
			];

			nState.data.eventsViews = eventsViews.map((l) =>
				JSON.parse(l.value),
			);

			nState.data.favorite = favorites.map((f) => JSON.parse(f.value));
			const newHistory = history
				.map((h) => JSON.parse(h.value))
				.sort((a, b) => {
					return moment(a.name, "DD.MM.YYYY HH:mm:ss").isBefore(
						moment(b.name, "DD.MM.YYYY HH:mm:ss"),
					)
						? 1
						: -1;
				});
			nState.data.history = newHistory;
			if (preference) {
				nState.data.preference = {
					...JSON.parse(preference.value),
					savedInServer: true,
				};
			}

			if (live) {
				nState.data.live = JSON.parse(live.value);
			}

			nState.loaded = true;
			return nState;
		}
		case AsyncActionStatus.FAILED: {
			nState.loaded = true;
			if (action.payload?.status === 404) {
				nState.existsInServer = false;
			}
			return nState;
		}
	}
	return state;
};

const updateConfig = (
	state: IConfigState = initialConfigState,
	action: ConfigActions,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const meta = action.meta as string;
			const payload = action.payload as IConfig;
			if (payload) {
				const { variant } = payload;
				if (variant === "preference") {
					const config = payload.config as IPreference;
					nState.data[variant] = config;
				} else if (variant === "live") {
					const config = payload.config as ILiveCamerasConfig;

					nState.data[variant] = config as ILiveCamerasConfig;
				} else if (variant === "layouts") {
					const config = payload.config as ILayoutConfig;
					if (meta === "local") config.saved = false;
					else delete config.saved;
					const index = nState.data[variant].findIndex(
						(c) => c.id === config.id,
					);
					if (index !== -1) {
						nState.data[variant] = nState.data[variant].map((c) =>
							c.id === config.id ? config : c,
						);
					}
				} else if (variant === "eventsViews") {
					const config = payload.config as IEventsViewConfig;
					if (meta === "local") config.saved = false;
					else delete config.saved;
					const index = nState.data[variant].findIndex(
						(c) => c.id === config.id,
					);
					if (index !== -1) {
						nState.data[variant] = nState.data[variant].map((c) =>
							c.id === config.id ? config : c,
						);
					}
				} else {
					const config = payload.config as IFavorite;
					const index = nState.data[variant].findIndex(
						(c) => c.id === config.id,
					);
					if (index !== -1) {
						if (variant === "history") {
							nState.data[variant].splice(index, 1);
							nState.data[variant].unshift(config as IFavorite);
						} else {
							nState.data[variant][index] = config as IFavorite;
						}
					}
				}

				return { ...nState };
			}
		}
	}
	return state;
};

const addConfig = (
	state: IConfigState = initialConfigState,
	action: ConfigActions,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			if (action.meta !== "local") nState.existsInServer = true;
			const payload = action.payload as IConfig;
			if (payload) {
				const { variant, config } = payload;
				if (variant === "preference") {
					nState.data[variant] = config as IPreference;
				} else if (variant === "live") {
					nState.data[variant] = config as ILiveCamerasConfig;
				} else if (variant === "layouts") {
					const layout = config as ILayoutConfig;
					if (layout.hasOwnProperty("saved")) delete layout.saved;
					const idx = nState.data[variant].findIndex(
						(c) => c.id === layout.id,
					);
					if (idx !== -1) nState.data[variant][idx] = layout;
					else
						nState.data[variant] = [
							layout,
							...nState.data[variant],
						];
				} else if (variant === "eventsViews") {
					const eventsView = config as IEventsViewConfig;
					const idx = nState.data[variant].findIndex(
						(c) => c.id === eventsView.id,
					);
					if (idx !== -1)
						nState.data[variant] = nState.data[variant].map((c) =>
							c.id === eventsView.id ? eventsView : c,
						);
					else
						nState.data[variant] = [
							eventsView,
							...nState.data[variant],
						];

					nState.data.layouts = nState.data.layouts.map((l) => {
						if (l.id !== eventsView.layoutId) return l;

						const existInLeft = l.viewsOrder[
							EventsViewSide.LEFT
						].includes(eventsView.id);
						const existInRight = l.viewsOrder[
							EventsViewSide.RIGHT
						].includes(eventsView.id);
						if (!existInLeft && !existInRight)
							return {
								...l,
								saved: false,
								viewsOrder: {
									...l.viewsOrder,
									[EventsViewSide.LEFT]: [
										eventsView.id,
										...l.viewsOrder[EventsViewSide.LEFT],
									],
								},
							};
						return l;
					});
				} else {
					nState.data[variant] = [
						config as IFavorite,
						...nState.data[variant],
					];
				}
			}
			return nState;
		}
	}
	return state;
};

const deleteConfig = (
	state: IConfigState = initialConfigState,
	action: ConfigActions,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const payload = action.payload as IConfig;
			if (payload) {
				const { variant, config } = payload;
				if (variant === "preference") {
					nState.data[variant] = initialConfigState.data.preference;
				} else if (variant === "live") {
					nState.data[variant] = undefined;
				} else if (variant === "layouts") {
					nState.data[variant] = nState.data[variant].filter(
						(c) => c.id !== config.id,
					);
				} else if (variant === "eventsViews") {
					const view = config as IEventsViewConfig;
					nState.data[variant] = nState.data[variant].filter(
						(c) => c.id !== view.id,
					);

					const colsWidth = JSON.parse(
						localStorage.getItem(colsWidthKey) ?? "null",
					);
					if (colsWidth?.[view.id]) {
						const newColsWidth = { ...colsWidth };
						delete newColsWidth[view.id];
						localStorage.setItem(
							colsWidthKey,
							JSON.stringify(newColsWidth),
						);
					}
				} else {
					nState.data[variant] = nState.data[variant].filter(
						(c) => c.id !== config.id,
					);
				}
			}
			return nState;
		}
	}
	return state;
};

export const ConfigReducer: Reducer<IConfigState, ConfigActions> = (
	state = initialConfigState,
	action: ConfigActions,
) => {
	switch (action.type) {
		case "GET_CONFIG":
			return getConfig(state, action);
		case "UPDATE_CONFIG":
			return updateConfig(state, action);
		case "ADD_CONFIG":
			return addConfig(state, action);
		case "DELETE_CONFIG":
			return deleteConfig(state, action);
		default:
			return state;
	}
};
