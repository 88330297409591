import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from "@material-ui/core";
import Title from "./Common/Title";

interface ILayoutNameDialogProps {
	open: boolean;
	close: () => void;
	onConfirm: () => void;
	name: string;
	onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LayoutNameDialog: React.FC<ILayoutNameDialogProps> = ({
	open,
	close,
	onConfirm,
	name,
	onNameChange,
}) => {
	return (
		<Dialog
			open={open}
			PaperProps={{ style: { maxHeight: "99vh" } }}
			onClose={close}
		>
			<Title title="Enter layout name" />
			<DialogContent>
				<TextField
					label="Name"
					value={name}
					onChange={onNameChange}
					size="small"
					variant="outlined"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} color="primary">
					Save
				</Button>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LayoutNameDialog;
