import AsyncState from "../AsyncState";

interface ISchema {
	$id: string;
	description: string;
	title: string;
}

export interface IMetadataSchema extends ISchema {
	type: string[] | string;
	$schema?: string;
	required?: string[];
	examples?: number[] | string[] | boolean[];
	default?: number | string | boolean;
	enum?: string[];
	properties?: IMetadataProperties;
}

export interface IMetadataProperties {
	[key: string]: IMetadataSchema;
}

export interface IMetadata {
	[key: string]: any;
}

export enum SCHEMAS {
	PLATE = "plate",
	PERSON = "person",
}

export type ISchemaState = AsyncState &
	Partial<{
		[key in SCHEMAS]: IMetadataSchema;
	}>;
