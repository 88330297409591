import moment from "moment";
import { CONFIDENCE } from "../components/Events/Filter/MoreFiltersItem";
import { EventsFilter } from "../store/Events/types";

export const filterArrayDuplicates = (arr: any[], key: any) => {
	return arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);
};

export const camelCaseToReadable = (key: string) => {
	if (!key) return key;
	return key
		.split(/(?=[A-Z])/)
		.map((word, i) =>
			i === 0
				? word[0].toUpperCase() + word.slice(1).toLowerCase()
				: word.toLowerCase(),
		)
		.join(" ");
};

export const getReadableFilterText = (
	key: string,
	value: any,
	onlyValue?: boolean,
) => {
	if (key === "exactId") value = "Yes";
	else if (key === "matchId") key = "Subject";
	else if (key === "licensePlateValue") key = "License Plate";
	else if (key === "moreFilters") {
		const filters = Object.entries(value);
		value = filters
			.map(([key, value]) => getReadableFilterText(key, value))
			.join(", ");
	} else if (key === "subjectType") {
		switch (value) {
			case 0:
				value = "Faces";
				break;
			case 1:
				value = "Vehicle/Human";
				break;
			case 2:
				value = "ALPR";
				break;
			default:
				value = "Any";
				break;
		}
	} else if (key === "timeTo" || key === "timeFrom")
		value = moment(value).format("YYYY-MM-DD HH:mm:ss");
	else if (key === "secret") value = `${value?.name}, ${value?.description}`;
	else if (key === "fieldExist" && value.hasOwnProperty("id"))
		value = value?.id;
	else if (typeof value === "object" && value?.hasOwnProperty("values")) {
		if (key === "objects") key = "type";
		if (
			key === "mask" ||
			key === "beard" ||
			key === "mustache" ||
			key === "glasses" ||
			key === "darkGlasses"
		) {
			value = `${value?.less ? "<=" : ">="} ${value?.confidence}`;
		} else {
			value =
				key === "gender"
					? value?.values.join(", ")
					: value?.values
							.filter((item: string) => item !== "Any")
							.join(", ");
		}
	} else if (
		Array.isArray(value) &&
		value.every((src) => src.hasOwnProperty("displayName"))
	)
		value = value?.map((v: any) => v.displayName).join(", ");
	else if (typeof value === "object" && value?.hasOwnProperty("name"))
		value = value?.name;
	else if (typeof value === "object" && value?.hasOwnProperty("id"))
		value = value?.id;
	else if (typeof value === "object" && value?.hasOwnProperty("description"))
		value = value?.description;
	return onlyValue
		? `${value ? value : ""}`
		: `${camelCaseToReadable(key)}${value ? `: ${value}` : ""}`;
};

export function filterAttributes(attributes: any, filter: EventsFilter) {
	if (!attributes) return attributes;
	const newAttributes = JSON.parse(JSON.stringify(attributes));
	for (const [key, value] of Object.entries(newAttributes)) {
		const confidence =
			filter?.moreFilters?.[key]?.confidence ?? CONFIDENCE[key] ?? 30;
		const val = value as { [key: string]: any };
		if (typeof val === "object" && val?.hasOwnProperty("confidence")) {
			if (val?.confidence < confidence || val?.confidence > 100) {
				delete newAttributes[key];
			}
		} else {
			for (const [unknownKey, unknownVal] of Object.entries(val)) {
				if (unknownVal < confidence || unknownVal > 100) {
					delete val[unknownKey];
				}

				if (Object.entries(val).length === 0) {
					const originalVal = attributes[key] as {
						[key: string]: any;
					};
					if (
						typeof originalVal !== "object" ||
						Object.keys(originalVal).length === 0
					) {
						continue;
					}
					const highestConfidenceKey = Object.keys(
						originalVal,
					).reduce((a, b) =>
						originalVal[a] > originalVal[b] ? a : b,
					);
					if (originalVal[highestConfidenceKey] <= 100) {
						newAttributes[key] = {
							[highestConfidenceKey]:
								originalVal[highestConfidenceKey],
						};
					}
				}
			}
		}
	}

	for (const [key, value] of Object.entries(attributes)) {
		const val = value as { [key: string]: any };
		if (typeof val === "object" && Object.keys(val).length === 0) {
			const originalVal = attributes[key] as { [key: string]: any };
			if (
				typeof originalVal !== "object" ||
				Object.keys(originalVal).length === 0
			) {
				continue;
			}
			const highestConfidenceKey = Object.keys(originalVal).reduce(
				(a, b) => (originalVal[a] > originalVal[b] ? a : b),
			);

			newAttributes[key] = originalVal[highestConfidenceKey];
		}
	}

	const attributeKeys = Object.keys(newAttributes).filter((key) =>
		faceAttributes.includes(key),
	);

	if (attributeKeys.length === 0) {
		const allKeys = Object.keys(attributes).filter((key) =>
			faceAttributes.includes(key),
		);

		const highestConfidenceKey = allKeys.reduce((a, b) => {
			if (attributes[a].confidence > 100) return b;
			if (attributes[b].confidence > 100) return a;
			return attributes[a].confidence > attributes[b].confidence ? a : b;
		});

		if (
			highestConfidenceKey &&
			attributes[highestConfidenceKey]?.confidence <= 100
		)
			newAttributes[highestConfidenceKey] =
				attributes[highestConfidenceKey];
	}

	return newAttributes;
}

export const filterDetails = (data: any, filter: EventsFilter) => {
	if (!data) return data;
	const newDetails = JSON.parse(JSON.stringify(data));
	const filterFn = (key: any, filterName: any) => {
		if (newDetails[key]) {
			const conf =
				filter?.moreFilters?.[filterName]?.confidence ??
				CONFIDENCE[filterName] ??
				80;

			for (const [objKey, value] of Object.entries(newDetails[key])) {
				const val = ((value as number) ?? 0) * 100;
				if (val < conf || val > 100) {
					delete newDetails[key][objKey];
				}
			}
			if (Object.keys(newDetails[key]).length === 0) {
				const allKeys = Object.keys(data[key]);
				if (allKeys.length > 0) {
					const highestConfidenceKey = allKeys.reduce((a, b) => {
						if (data[key][a] > 100) return b;
						if (data[key][b] > 100) return a;
						return data[key][a] > data[key][b] ? a : b;
					});

					newDetails[key][highestConfidenceKey] =
						data[key][highestConfidenceKey];
				}
			}
		}
	};

	filterFn("colorConfidences", "colors");
	filterFn("directionConfidences", "directions");
	filterFn("objectTypeConfidences", "objects");
	filterFn("ageGroupDetails", "ageGroup");
	if (newDetails.clothingDetails) {
		const clothingDetails = newDetails.clothingDetails;
		const clothingDetailsKeys = Object.keys(clothingDetails);
		for (const key of clothingDetailsKeys) {
			if (key !== "gender" && key !== "values") {
				delete clothingDetails[key];
			}
		}
	}

	if (newDetails.vehicleDetails?.models) {
		newDetails.vehicleDetails.models =
			newDetails.vehicleDetails.models.slice(0, 1);
	}
	return newDetails;
};

export const faceAttributes = [
	"mask",
	"hat",
	"beard",
	"mustache",
	"glasses",
	"darkGlasses",
];
