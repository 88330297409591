import Axios from "axios";
import { async, AsyncActionStatus } from "../AsyncState";
import { API_USERCONFIG } from "../../config/axios";
import { IFavorite } from "../../components/Events/Filter/Favorites";
import { IConfig, IConfigResponse, ILayoutConfig } from "./types";
import moment from "moment";

const getConfig = async () => {
	const SIZE = 500;
	try {
		const response = await Axios.get<IConfigResponse>(API_USERCONFIG, {
			params: { size: SIZE },
		});
		if (response.data.totalPages > 1) {
			const requests = [];
			for (let i = 1; i < response.data.totalPages; i++) {
				requests.push(
					Axios.get<IConfigResponse>(API_USERCONFIG, {
						params: { size: SIZE, page: i },
					}),
				);
			}
			const additionalResponses = await Promise.all(requests);
			additionalResponses.forEach((res) => {
				response.data.content = response.data.content.concat(
					res.data.content,
				);
			});
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

const updateConfig = async (data: IConfig, updateName?: boolean) => {
	try {
		const newData = {
			...data,
			config: {
				...data.config,
				name: updateName
					? moment().format("DD.MM.YYYY HH:mm:ss")
					: data.config.name,
			},
		};
		if (newData.config.hasOwnProperty("saved")) {
			const formattedConfig = newData.config as ILayoutConfig;
			delete formattedConfig.saved;
			newData.config = formattedConfig;
		}

		Axios.patch<IFavorite[]>(
			`${API_USERCONFIG}/${newData.variant}-${newData.config.id}`,
			newData.config,
		);
		return newData;
	} catch (error) {
		throw error;
	}
};

const addConfig = async (data: IConfig) => {
	try {
		Axios.post<IConfig["config"]>(
			`${API_USERCONFIG}/${data.variant}-${data.config.id}`,
			data.config,
		);
		return data;
	} catch (error) {
		throw error;
	}
};

const deleteConfig = async (data: IConfig) => {
	try {
		Axios.delete<IConfig["config"]>(
			`${API_USERCONFIG}/${data.variant}-${data.config.id}`,
		);
		return data;
	} catch (error) {
		throw error;
	}
};

export function getConfigAction() {
	return async("GET_CONFIG", getConfig, "");
}

export function updateConfigAction(data: IConfig, updateName?: boolean) {
	return async("UPDATE_CONFIG", updateConfig, "", data, updateName);
}

export function updateConfigLocallyAction(data: IConfig) {
	return {
		type: "UPDATE_CONFIG",
		payload: data,
		status: AsyncActionStatus.SUCCEEDED,
		meta: "local",
	};
}

export function addConfigAction(data: IConfig) {
	return async("ADD_CONFIG", addConfig, "", data);
}

export function addConfigLocallyAction(data: IConfig) {
	return {
		type: "ADD_CONFIG",
		payload: data,
		status: AsyncActionStatus.SUCCEEDED,
		meta: "local",
	};
}

export function deleteConfigAction(data: IConfig) {
	return async("DELETE_CONFIG", deleteConfig, "", data);
}

export function deleteConfigLocallyAction(data: IConfig) {
	return {
		type: "DELETE_CONFIG",
		payload: data,
		status: AsyncActionStatus.SUCCEEDED,
		meta: "local",
	};
}
