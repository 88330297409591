import React from "react";
import RecordsItem from "./RecordsItem";
import { Box } from "@material-ui/core";
import { THEME } from "../../config/index";
import { IRecordsDataContainer } from "../../store/Records/types";
import CenteredLoading from "../UI/CenteredLoading";

interface IRecordsListProps {
	records: IRecordsDataContainer;
	loading: boolean;
	onClick(option: number): void;
	onDoubleClick(): void;
	selected?: number;
}

const RecordsList: React.FC<IRecordsListProps> = (props) => (
	<Box
		border={1}
		display="flex"
		flexDirection="row"
		flexGrow={1}
		overflow="auto"
		color={THEME.palette.secondary.main}
		style={{ paddingTop: 5, paddingBottom: 5 }}
	>
		{props.records && !props.loading ? (
			props.records.content.map((record, i) => (
				<RecordsItem
					key={i}
					selected={props.selected as number}
					index={record.index}
					onClick={props.onClick}
					onDoubleClick={props.onDoubleClick}
					image={record.image}
				/>
			))
		) : (
			<CenteredLoading height={210} />
		)}
	</Box>
);

export default RecordsList;
