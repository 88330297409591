import {
	ImageSearchState,
	ImageSearch,
	ImageSearchResult,
	ISearchResult,
} from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { ImageSearchAction } from "./action";
import { PagedContent } from "../types";
import { isEqual } from "lodash";

export const initialImageSearchState: ImageSearchState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	results: [],
	resultsByIndex: {},
	keys: {},
	paging: {
		page: 0,
		size: 50,
		mineOnly: false,
	},
	scrolledY: 0,
	requestStatus: {},
};

export const imageSearchReducer: Reducer<ImageSearchState, any> = (
	state = initialImageSearchState,
	action: ImageSearchAction,
) => {
	switch (action.type) {
		case "ImageSearches":
			return handleImageSearch(state, action);
		case "ImageSearches_Filter":
			return handleFilter(state, action);
		case "Update_Search_Request":
			return updateSearchRequest(state, action);
		case "Search_Results":
			return handleResults(state, action);
		case "Search_Results_Index":
			return handleResultsIndex(state, action);
		case "Scroll":
			return handleScroll(state, Number(action.meta));
		default:
			return state;
	}
};

const handleScroll = (
	state: ImageSearchState = initialImageSearchState,
	scrolledY: number,
) => {
	const nState = { ...state };
	nState.scrolledY = scrolledY;
	return nState;
};

const handleImageSearch = (
	state: ImageSearchState = initialImageSearchState,
	action: ImageSearchAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				nState.keys[action.meta] = action.payload as ImageSearch;
			} else {
				const payload = action.payload as PagedContent<ImageSearch[]>;
				payload.content.forEach((res) => {
					nState.keys[res.id] = res;
				});

				nState.data.content = payload.content;
			}
			return nState;
	}
	return state;
};

const handleResults = (
	state: ImageSearchState = initialImageSearchState,
	action: ImageSearchAction,
) => {
	if (action.type === "Search_Results") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as ImageSearchResult;
			const result = nState.results.findIndex(
				(res) => res.id === payload.id,
			);
			if (result > -1) {
				const content = [
					...nState.results[result].results.content,
					...payload.results.content,
				].filter(
					(res, index, self) =>
						index === self.findIndex((t) => isEqual(t, res)),
				);
				nState.results = nState.results.map((r, i) =>
					i === result
						? {
								...r,
								results: {
									...r.results,
									content,
								},
						  }
						: r,
				);
			} else {
				nState.results = [...nState.results, payload];
			}
		}
		return nState;
	}
	return state;
};

const handleResultsIndex = (
	state: ImageSearchState = initialImageSearchState,
	action: ImageSearchAction,
) => {
	if (action.type === "Search_Results_Index") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as PagedContent<ISearchResult[]> & {
				id: string;
				index: number;
				dontSave?: boolean;
			};

			const content =
				nState.resultsByIndex[payload.id + `index-${payload.index}`]
					?.content;
			if (content) {
				nState.resultsByIndex = {
					...nState.resultsByIndex,
					[payload.id + `index-${payload.index}`]: {
						...nState.resultsByIndex[
							payload.id + `index-${payload.index}`
						],
						content: payload.dontSave
							? []
							: [...content, ...payload.content].filter(
									(res, index, self) =>
										index ===
										self.findIndex((t) => isEqual(t, res)),
							  ),
					},
				};
			} else {
				nState.resultsByIndex = {
					...nState.resultsByIndex,
					[payload.id + `index-${payload.index}`]: {
						...payload,
						content: payload.dontSave ? [] : payload.content,
					},
				};
			}

			return nState;
		}
	}
	return state;
};

const handleFilter = (
	state: ImageSearchState = initialImageSearchState,
	action: ImageSearchAction,
) => {
	if (action.type === "ImageSearches_Filter") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			nState.paging = {
				...nState.paging,
				...action.payload,
			};
		}
		return nState;
	}
	return state;
};

const updateSearchRequest = (
	state: ImageSearchState = initialImageSearchState,
	action: ImageSearchAction,
) => {
	if (action.type === "Update_Search_Request") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as ImageSearch;
			const id = payload.correlationId ?? payload.id;
			payload.id = id;
			if (id) {
				const existing = nState.data.content.find((r) => r.id === id);
				if (existing) {
					nState.keys[id] = { ...nState.keys[id], ...payload };
					nState.data.content = nState.data.content.map((r) =>
						r.id === id ? { ...r, ...payload } : r,
					);
				} else if (nState.paging.page === 0) {
					nState.data.content = [payload, ...nState.data.content];
					nState.keys[id] = payload;
				}
			}
			return nState;
		}
	}
	return state;
};
