import { makeStyles, Box } from "@material-ui/core";
import { EventsFilter } from "../../../store/Events/types";
import FiltersContent from "./FiltersContent";

const useStyles = makeStyles(() => ({
	wrapper: {
		overflow: "hidden",
		width: 340,
		minHeight: "100vh",
		maxHeight: "100vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative",
		backgroundColor: "white",
		whiteSpace: "nowrap",
	},
}));

interface FilterBoxProps {
	onFilter(filter: EventsFilter): void;
	scrollToTop(): void;
	setIsOpen(value: boolean): void;
	filter?: EventsFilter;
	isOpen: boolean;
}

const FiltersDrawer: React.FC<FilterBoxProps> = ({
	onFilter,
	scrollToTop,
	setIsOpen,
	filter,
	isOpen,
}) => {
	const classes = useStyles();

	if (!isOpen) return null;

	return (
		<div>
			<Box className={classes.wrapper}>
				<FiltersContent
					onFilter={onFilter}
					scrollToTop={scrollToTop}
					topFilter={filter}
					setIsOpen={setIsOpen}
				/>
			</Box>
		</div>
	);
};

export default FiltersDrawer;
