import React from "react";
import { Trigger } from "../../../../../store/Rules/types";
import TriggerComponent from "../TriggerComponent";
import EmptyTrigger from "../EmptyTrigger";
import { IWatchlist } from "../../../../../store/Watchlists/types";
import { ISource } from "../../../../../store/Sources/types";
import {
	Button,
	Grid,
	IconButton,
	Paper,
	Tooltip,
	makeStyles,
} from "@material-ui/core";
import AddTriggerChip from "../AddTriggerChip";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../../../../UI/ConfirmDialog";

const useStyles = makeStyles(() => ({
	paper: {
		flexGrow: 1,
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
	},
	nextMulti: {
		marginLeft: 20,
	},
}));

const checkForMultiTriggers = (triggers: Trigger[]) => {
	let sum = 0;
	for (let i = 0; i < triggers.length; i++) {
		if (triggers[i].type === "MultiTrigger") {
			sum++;
		}
	}
	return sum;
};

const removeMultiTriggers = (triggers: Trigger[]) => {
	const nList: Trigger[] = [];
	for (let i = 0; i < triggers.length; i++) {
		if (triggers[i].type !== "MultiTrigger") {
			nList.push(triggers[i]);
		}
	}
	return nList;
};

const extractMultiTriggers = (triggers: Trigger[]) => {
	const nList: Trigger[] = [];
	for (let i = 0; i < triggers.length; i++) {
		if (triggers[i].type === "MultiTrigger") {
			nList.push(triggers[i]);
		}
	}
	return nList;
};

interface IMultiTriggerProps {
	trigger: Trigger | null;
	onTriggers: (triggers: Trigger | undefined) => void;
	watchlists: { [key: string]: IWatchlist };
	sources: ISource[];
	vmsName: string;
	layer: number;
	isReadOnly: boolean;
	showOnly?: boolean;
}

const MultiTrigger: React.FC<IMultiTriggerProps> = ({
	trigger,
	onTriggers,
	watchlists,
	sources,
	vmsName,
	layer,
	isReadOnly,
	showOnly,
}) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const classes = useStyles();
	if (!trigger) {
		const handleEmptyTrigger = (addedTrigger: Trigger) => {
			onTriggers({
				type: "MultiTrigger",
				operator: "And",
				triggers: [addedTrigger],
			});
		};

		return (
			<EmptyTrigger
				addTrigger={handleEmptyTrigger}
				watchlists={watchlists}
				sources={sources}
			/>
		);
	}

	if (trigger.type !== "MultiTrigger") {
		const handleNotMultiTrigger = (addedTrigger: Trigger) => {
			onTriggers({
				type: "MultiTrigger",
				operator: "And",
				triggers: [trigger, addedTrigger],
			});
		};

		const handleNotMultiTriggerUpdate = (updatedTrigger?: Trigger) => {
			onTriggers(updatedTrigger);
		};

		return (
			<Grid container>
				<Grid item xs={12}>
					<Paper
						className={classes.paper}
						square
						style={{
							backgroundColor: showOnly
								? "transparent"
								: undefined,
						}}
						elevation={0}
					>
						<TriggerComponent
							trigger={trigger}
							onTriggers={handleNotMultiTriggerUpdate}
							vmsName={vmsName}
							watchlists={watchlists}
							sources={sources}
							isReadOnly={isReadOnly}
							showOnly={showOnly}
						/>
						{isReadOnly || showOnly ? null : (
							<AddTriggerChip
								addTrigger={handleNotMultiTrigger}
								watchlists={watchlists}
								sources={sources}
								multiOp="Or"
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
		);
	}

	const handleMultiTriggerUpdate = (
		index: number,
		updatedTrigger?: Trigger,
	) => {
		const stateCopy = { ...trigger };
		if (updatedTrigger) {
			stateCopy.triggers[index] = updatedTrigger;
		} else {
			stateCopy.triggers.splice(index, 1);
		}
		onTriggers(stateCopy);
	};

	const handleMultiTriggerAdd = (addedTrigger: Trigger) => {
		const stateCopy = { ...trigger };
		stateCopy.triggers.push(addedTrigger);
		onTriggers(stateCopy);
	};

	const handleOperator = () => {
		const stateCopy = { ...trigger };
		if (trigger.operator === "And") {
			stateCopy.operator = "Or";
		} else {
			stateCopy.operator = "And";
		}
		onTriggers(stateCopy);
	};

	const handleDeleteMultiTrigger = () => {
		onTriggers(undefined);
	};

	const count = checkForMultiTriggers(trigger.triggers);
	const addMultiOp = trigger.operator === "And" ? "Or" : "And";

	if (count === 0) {
		return (
			<>
				<Grid container>
					<Grid item xs={layer === 0 ? 12 : 11}>
						<Paper
							className={classes.paper}
							square
							style={{
								backgroundColor: showOnly
									? "transparent"
									: undefined,
							}}
							elevation={0}
						>
							{trigger.triggers.map((t, i) => (
								<React.Fragment key={i}>
									<TriggerComponent
										key={i}
										trigger={t}
										onTriggers={(t) =>
											handleMultiTriggerUpdate(i, t)
										}
										vmsName={vmsName}
										watchlists={watchlists}
										sources={sources}
										isReadOnly={isReadOnly}
										showOnly={showOnly}
									/>
									{i + 1 !== trigger.triggers.length ? (
										<Button
											onClick={handleOperator}
											size="small"
											style={{
												color: showOnly ? "white" : "",
											}}
										>
											{trigger.operator === "And"
												? "AND"
												: "OR"}
										</Button>
									) : null}
								</React.Fragment>
							))}
							{isReadOnly || showOnly ? null : (
								<AddTriggerChip
									addTrigger={handleMultiTriggerAdd}
									watchlists={watchlists}
									sources={sources}
									multiOp={addMultiOp}
								/>
							)}
						</Paper>
					</Grid>
					{layer === 0 || isReadOnly || showOnly ? null : (
						<Grid item xs={1}>
							<IconButton onClick={() => setOpen(true)}>
								<Tooltip title="Remove trigger">
									<DeleteIcon />
								</Tooltip>
							</IconButton>
						</Grid>
					)}
				</Grid>
				<ConfirmDialog
					open={open}
					onConfirm={handleDeleteMultiTrigger}
					onClose={() => setOpen(false)}
				>
					Are you sure you want to delete this multi trigger?
				</ConfirmDialog>
			</>
		);
	}

	const newList = removeMultiTriggers(trigger.triggers);
	const multiList = extractMultiTriggers(trigger.triggers);

	return (
		<>
			<Grid container>
				<Grid item container xs={layer === 0 ? 12 : 11}>
					<Paper
						className={classes.paper}
						square
						style={{
							backgroundColor: showOnly
								? "transparent"
								: undefined,
							borderColor: showOnly ? "white" : undefined,
						}}
						elevation={0}
					>
						{newList.map((t, i) => {
							let target = i;
							let helper = 0;
							for (let j = 0; j <= i; j++) {
								while (
									trigger.triggers[helper + j].type ===
									"MultiTrigger"
								) {
									helper++;
								}
							}
							target += helper;
							return (
								<React.Fragment key={i}>
									<TriggerComponent
										key={i}
										trigger={t}
										onTriggers={(t) =>
											handleMultiTriggerUpdate(target, t)
										}
										vmsName={vmsName}
										watchlists={watchlists}
										sources={sources}
										isReadOnly={isReadOnly}
										showOnly={showOnly}
									/>
									{i + 1 !== newList.length ? (
										<Button
											onClick={handleOperator}
											size="small"
											style={{
												color: showOnly ? "white" : "",
											}}
										>
											{trigger.operator === "And"
												? "AND"
												: "OR"}
										</Button>
									) : null}
								</React.Fragment>
							);
						})}
						{isReadOnly || showOnly ? null : (
							<AddTriggerChip
								addTrigger={handleMultiTriggerAdd}
								watchlists={watchlists}
								sources={sources}
								multiOp={addMultiOp}
							/>
						)}
					</Paper>
				</Grid>
				{layer === 0 || isReadOnly || showOnly ? null : (
					<Grid item xs={1}>
						<IconButton onClick={() => setOpen(true)}>
							<Tooltip title="Remove trigger">
								<DeleteIcon />
							</Tooltip>
						</IconButton>
					</Grid>
				)}
				<div className={classes.nextMulti}>
					{multiList.map((t, i) => {
						let target = i;
						let helper = 0;
						for (let j = 0; j <= i; j++) {
							while (
								trigger.triggers[helper + j].type !==
								"MultiTrigger"
							) {
								helper++;
							}
						}
						target += helper;
						return (
							<Grid item key={i} xs={12}>
								<Button
									onClick={handleOperator}
									size="small"
									style={{
										color: showOnly ? "white" : "",
									}}
								>
									{trigger.operator === "And" ? "AND" : "OR"}
								</Button>
								<MultiTrigger
									trigger={t}
									watchlists={watchlists}
									sources={sources}
									onTriggers={(trigger) =>
										handleMultiTriggerUpdate(
											target,
											trigger,
										)
									}
									vmsName={vmsName}
									layer={layer + 1}
									isReadOnly={isReadOnly}
									showOnly={showOnly}
								/>
							</Grid>
						);
					})}
				</div>
			</Grid>
			<ConfirmDialog
				open={open}
				onConfirm={handleDeleteMultiTrigger}
				onClose={() => setOpen(false)}
			>
				Are you sure you want to delete this multi trigger?
			</ConfirmDialog>
		</>
	);
};

export default MultiTrigger;
