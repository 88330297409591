import React from "react";
import { Grid } from "@material-ui/core";

interface IEmptyProps {
	msg: string;
}

const SubjectsAreEmpty: React.FC<IEmptyProps> = ({ msg }) => {
	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			style={{ minHeight: "73vh" }}
		>
			<h3 style={{ color: "rgba(0, 0, 0, 0.54)" }}>{msg}</h3>
		</Grid>
	);
};

export default SubjectsAreEmpty;
