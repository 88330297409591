import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LiveStream from "../VideoPlayers/LiveStream";
import { THEME } from "../../config";
import { ILiveEvent, TCamera } from "../../store/LiveCameras/types";
import CamTitle from "./CamTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
	selectCameraAction,
	setDraggingCameraAction,
	setTemporaryCameraAction,
	updateLiveEventAction,
} from "../../store/LiveCameras/action";
import { useEffect, useMemo, useState } from "react";
import PlaybackStream from "../VideoPlayers/PlaybackStream";
import {
	ILayoutConfig,
	ILiveCamerasConfig,
} from "../../store/UserConfig/types";
import { updateConfigLocallyAction } from "../../store/UserConfig/action";
import EventDetails from "../Events/Event/EventDetails";
import {
	IElasticSource,
	IElasticSourceObjects,
	MatchProps,
} from "../../store/Events/types";
import clsx from "clsx";
import Layout from "./Layout";
import Hotspot from "./Hotspot";
import _ from "lodash";
import { HotspotType } from "./HotspotList";
import ConfirmDialog from "../UI/ConfirmDialog";
import { ISubjectEvent } from "../../proto/types";
import { dateToTicks } from "../../helpers/Utils";

const useStyles = makeStyles(() => ({
	root: {
		flex: 3,
		overflow: "hidden",
		display: "flex",
	},
	itemWrapper: {
		padding: 2,
		display: "flex",
		cursor: "pointer",
		height: "100%",
		width: "100%",
	},
	camera: {
		backgroundColor: THEME.palette.grey[200],
		border: `4px solid transparent`,
		outline: `2px solid ${THEME.palette.grey[300]}`,
		outlineOffset: -2,
		borderRadius: 8,
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		justifyContent: "center",
		flex: 1,
		height: "100%",
		maxWidth: "100%",
		padding: 2,
	},
	selectedCamera: {
		borderColor: THEME.palette.success.dark,
		backgroundColor: THEME.palette.grey[100],
		outline: "none",
	},
	hoveredCamera: {
		borderColor: THEME.palette.secondary.light,
		backgroundColor: THEME.palette.grey[300],
		outline: "none",
	},
	canvasWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		maxHeight: "100%",
		borderRadius: "8px",
		overflow: "hidden",
		width: "100%",
	},
	title: {
		borderBottom: `1px solid ${THEME.palette.grey[200]}`,
		minHeight: 26,
		transition: "all 0.1s ease-in-out",
		fontSize: "1rem",
	},
}));

type Props = {
	config: ILiveCamerasConfig | undefined;
	layouts: ILayoutConfig[];
	backwardEvents: (IElasticSource & IElasticSourceObjects)[];
	loadMatch: (match: MatchProps) => void;
};

enum StreamMode {
	LIVE,
	PLAYBACK,
}

const CamLayout = ({ config, layouts, backwardEvents, loadMatch }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [hotspotTitles, setHotspotTitles] = useState<
		Record<HotspotType, string>
	>(
		Object.values(HotspotType).reduce((acc, curr) => {
			acc[curr] = "";
			return acc;
		}, {} as Record<HotspotType, string>),
	);

	const [maximizedIdx, setMaximizedIdx] = useState(-1);
	const [detailsHeight, setDetailsHeight] = useState(0);
	const [removingCamIndex, setRemovingCamIndex] = useState<number>(-1);
	const { draggingCamera, selectedCamera, draggingView } = useSelector(
		(state: AppState) => state.live,
	);

	const temporaryCam = useSelector(
		(state: AppState) => state.live.temporaryCamera,
	);

	const selectedEvent = useSelector((state: AppState) => {
		const events = [
			...backwardEvents,
			...state.live.events,
			...state.live.separatedEvents,
		];
		const selectedEvtId = state.live.selectedEventIds?.eventId;
		if (!events || !selectedEvtId) return null;
		const event = events.find((e) => e.id === selectedEvtId) ?? null;
		if (event && !event.timeStamp)
			event.timeStamp = dateToTicks(
				(event as IElasticSource)["@timestamp"],
			);
		return event;
	});

	const selectedLayout = useMemo(
		() => layouts.find((l) => l.id === config?.selectedLayoutId),
		[config?.selectedLayoutId, layouts],
	);

	const vmsList = useSelector((state: AppState) =>
		Object.values(state.vms.keys),
	);
	const sourceList = useSelector((state: AppState) => state.sources.keys);

	const updateTitle = (type: HotspotType, title: string) => {
		setHotspotTitles((prev) => {
			prev[type] = title;
			return { ...prev };
		});
	};

	const addCamera = (camera: TCamera | null, i: number) => {
		if (!selectedLayout || !config) return;

		if (i === 0 && temporaryCam) {
			dispatch(setTemporaryCameraAction(null));
			if (!camera) return;
		}
		const newLayout = { ...selectedLayout };
		const currentCameraIndex = newLayout.cameras.findIndex(
			(c) =>
				c?.sourceId === camera?.sourceId &&
				c?.vmsName === camera?.vmsName &&
				c?.hotspotType === camera?.hotspotType,
		);
		if (currentCameraIndex > -1 && camera)
			newLayout.cameras[currentCameraIndex] = newLayout.cameras[i];
		newLayout.cameras[i] = camera;
		dispatch(
			updateConfigLocallyAction({
				variant: "layouts",
				config: newLayout,
			}),
		);
	};

	const handleMouseUp = (e: React.MouseEvent<HTMLElement>, i: number) => {
		if (!draggingCamera) return;
		const el = e.currentTarget.children[0];
		el.classList.remove(classes.hoveredCamera);
		addCamera(draggingCamera, i);
	};

	const switchStreamMode = (cam: TCamera, i: number, mode: StreamMode) => {
		if (!cam) return;
		if (i === 0 && temporaryCam)
			dispatch(
				setTemporaryCameraAction({
					...temporaryCam,
					isLive: mode === StreamMode.LIVE,
				}),
			);
		else
			addCamera(
				{
					...cam,
					isLive: mode === StreamMode.LIVE,
					timestamp: undefined,
				},
				i,
			);
	};

	const handleDragStart = (
		_: React.MouseEvent<HTMLElement>,
		camera: TCamera,
	) => {
		dispatch(setDraggingCameraAction(camera));
	};

	useEffect(() => {
		const handleResize = () => {
			setDetailsHeight(window.innerHeight * 0.2);
		};

		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<Box className={classes.root}>
				<Layout
					layout={selectedLayout}
					items={selectedLayout?.areas?.map((__, i) => {
						const isTempCam = Boolean(i === 0 && temporaryCam);
						const hotspot = !isTempCam
							? selectedLayout?.cameras[i]?.hotspotType
							: undefined;
						const cam = isTempCam
							? temporaryCam
							: selectedLayout?.cameras[i];
						const camVMS = vmsList.find(
							(vms) => vms.name === cam?.vmsName,
						);
						const camSource = sourceList[
							cam?.vmsName ?? ""
						]?.content.find((s) => s.id === cam?.sourceId);

						const isSelected =
							cam &&
							selectedCamera?.vmsName === cam.vmsName &&
							selectedCamera?.sourceId === cam.sourceId &&
							selectedCamera?.hotspotType === cam.hotspotType;

						const title = hotspot
							? `${cam?.hotspotType} hotspot` +
							  (cam &&
							  cam.hotspotType &&
							  hotspotTitles[cam.hotspotType]
									? ` (${hotspotTitles[cam.hotspotType]})`
									: "")
							: `${cam?.vmsName} - ${cam?.sourceName}`;
						const key = Object.values(cam ?? {}).join("");

						return (
							<Box
								key={"box" + key}
								onMouseOver={(e) => {
									const el = e.currentTarget?.children?.[0];
									if (
										(el && draggingCamera) ||
										(el && cam && !draggingView)
									)
										el.classList.add(classes.hoveredCamera);
								}}
								onMouseLeave={(e) => {
									const el = e.currentTarget?.children?.[0];
									if (el)
										el.classList.remove(
											classes.hoveredCamera,
										);
								}}
								onMouseUp={(e) => handleMouseUp(e, i)}
								onClick={(e) => {
									const tag = (e.target as HTMLElement)
										.tagName;
									if (tag === "svg" || tag === "path" || !cam)
										return;

									if (!isTempCam)
										dispatch(
											setTemporaryCameraAction(null),
										);
									const { vmsName, sourceId, hotspotType } =
										cam;
									dispatch(
										selectCameraAction(
											isSelected
												? null
												: {
														vmsName,
														sourceId,
														hotspotType,
												  },
										),
									);

									if (
										selectedLayout.cameras.some(
											(c) => c?.revertToLiveOnChange,
										)
									)
										dispatch(
											updateConfigLocallyAction({
												variant: "layouts",
												config: {
													...selectedLayout,
													cameras:
														selectedLayout.cameras.map(
															(c) => {
																if (
																	c?.revertToLiveOnChange &&
																	!_.isEqual(
																		c,
																		cam,
																	)
																)
																	return {
																		...c,
																		revertToLiveOnChange:
																			false,
																		isLive: true,
																	};
																return c;
															},
														),
												},
											}),
										);
								}}
								className={classes.itemWrapper}
							>
								<div
									className={clsx(
										classes.camera,
										isSelected
											? classes.selectedCamera
											: "",
									)}
								>
									{selectedLayout.cameras && cam ? (
										<Box className={classes.canvasWrapper}>
											<CamTitle
												onClose={() => {
													if (isTempCam)
														return dispatch(
															setTemporaryCameraAction(
																null,
															),
														);
													if (
														!cam.isLive &&
														!cam.hotspotType
													) {
														const updatedCam = {
															...cam,
															isLive: true,
														};
														if (
															updatedCam.revertToLiveOnChange
														)
															delete updatedCam.revertToLiveOnChange;
														addCamera(
															updatedCam,
															i,
														);
													} else
														setRemovingCamIndex(i);
												}}
												status={
													camSource?.tasks?.[0].status
												}
												isTempCam={isTempCam}
												className={classes.title}
												title={title}
												onDragStart={(e) => {
													if (!isTempCam)
														handleDragStart(e, cam);
												}}
												onDoubleClick={() =>
													setMaximizedIdx(i)
												}
												draggable={!isTempCam}
												style={{
													...(hotspot
														? {
																backgroundColor:
																	THEME
																		.palette
																		.secondary
																		.light,
														  }
														: {}),
													cursor: draggingCamera
														? "auto"
														: "grab",
													userSelect: "none",
													pointerEvents:
														draggingCamera
															? "none"
															: "auto",
												}}
											/>
											{hotspot ? (
												<Hotspot
													updateTitle={updateTitle}
													camera={cam}
													maximized={
														i === maximizedIdx
													}
													title={
														i === maximizedIdx
															? title
															: undefined
													}
													selectedEvent={
														selectedEvent as ISubjectEvent
													}
													setMaximized={(bool) =>
														setMaximizedIdx(
															(prev) => {
																if (
																	bool ===
																	false
																)
																	return -1;
																return prev ===
																	i
																	? -1
																	: i;
															},
														)
													}
												/>
											) : cam.isLive &&
											  cam.sourceId &&
											  camVMS ? (
												<LiveStream
													key={key}
													vms={camVMS}
													sourceId={cam.sourceId}
													loaderColor="white"
													maximized={
														i === maximizedIdx
													}
													title={
														i === maximizedIdx
															? title
															: undefined
													}
													setMaximized={(bool) =>
														setMaximizedIdx(
															(prev) => {
																if (
																	bool ===
																	false
																)
																	return -1;
																return prev ===
																	i
																	? -1
																	: i;
															},
														)
													}
													onPlaybackClick={() =>
														switchStreamMode(
															cam,
															i,
															StreamMode.PLAYBACK,
														)
													}
												/>
											) : (
												camVMS &&
												cam.sourceId && (
													<PlaybackStream
														key={key}
														eventTimestamp={
															cam.timestamp
														}
														selectedEvent={
															selectedEvent?.vms ===
																cam.vmsName &&
															selectedEvent?.sourceId ===
																cam.sourceId
																? (selectedEvent as ISubjectEvent)
																: undefined
														}
														sourceId={cam.sourceId}
														vms={camVMS}
														maximized={
															i === maximizedIdx
														}
														title={
															i === maximizedIdx
																? title
																: undefined
														}
														setMaximized={(bool) =>
															setMaximizedIdx(
																(prev) => {
																	if (
																		bool ===
																		false
																	)
																		return -1;
																	return prev ===
																		i
																		? -1
																		: i;
																},
															)
														}
														onLiveClick={() =>
															switchStreamMode(
																cam,
																i,
																StreamMode.LIVE,
															)
														}
													/>
												)
											)}
										</Box>
									) : (
										"Drag and drop a source"
									)}
								</div>
							</Box>
						);
					})}
				/>
			</Box>
			<EventDetails
				key="live-event-details"
				event={
					selectedEvent as
						| (IElasticSource & IElasticSourceObjects)
						| null
				}
				loadMatch={loadMatch}
				updateEvent={(evt) =>
					dispatch(
						updateLiveEventAction(evt as unknown as ILiveEvent),
					)
				}
				fullCollapse={true}
				disableRootStyle={true}
				height={detailsHeight}
				style={{ padding: 2 }}
			/>
			<ConfirmDialog
				fullWidth={false}
				open={removingCamIndex > -1}
				onClose={() => setRemovingCamIndex(-1)}
				onConfirm={() => {
					addCamera(null, removingCamIndex);
					setRemovingCamIndex(-1);
				}}
			>
				Are you sure you want to remove this camera?
			</ConfirmDialog>
		</>
	);
};

export default CamLayout;
