import React from "react";
import { makeStyles, Dialog, DialogContent, Box } from "@material-ui/core";
import { ICreateSecret, IUpdateSecret } from "../../store/Secrets/types";
import SecretForm from "../Forms/SecretForm/SecretForm";
import Title from "./Common/Title";
import { ICurrentUser } from "../../store/Users/types";

const useStyles = makeStyles(() => ({
	formBox: { height: 605 },
	dialog: {
		width: 600,
	},
}));

interface ISecretDialogProps {
	open: boolean;
	close(): void;
	data: ICreateSecret | undefined;
	onConfirm(newSecret: IUpdateSecret | ICreateSecret): void;
	currentUser: ICurrentUser;
	users: string[];
	onUserSearch(value: string): void;
	isReadOnly: boolean;
	onLoading(): void;
	loading: boolean;
}

const SecretDialog: React.FC<ISecretDialogProps> = ({
	open,
	close,
	data,
	onConfirm,
	currentUser,
	users,
	onUserSearch,
	isReadOnly,
	onLoading,
	loading,
}) => {
	const classes = useStyles();
	const getDialogTitle = () => {
		if (isReadOnly) return "View Secret";
		if (data) return "Edit Secret";
		return "Create Secret";
	};

	return (
		<Dialog open={open} maxWidth="md" onClose={close}>
			<Box className={classes.formBox}>
				<Title title={getDialogTitle()} onClose={close} />
				<DialogContent>
					<SecretForm
						onSubmit={onConfirm}
						data={data}
						close={close}
						currentUser={currentUser}
						users={users}
						onUserSearch={onUserSearch}
						isReadOnly={isReadOnly}
						onLoading={onLoading}
						loading={loading}
					/>
				</DialogContent>
			</Box>
		</Dialog>
	);
};

export default SecretDialog;
