import React from "react";
import { getUser } from "../store/Users/action";
import { ICurrentUser } from "../store/Users/types";
import {
	Box,
	ListItemText,
	ListItemIcon,
	makeStyles,
	Container,
	Dialog,
	DialogContent,
	Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import NavMenuItem from "../components/Navigation/NavMenuItem";
import Title from "../components/Dialogs/Common/Title";
import DisplayAuthorities from "../config/Authorities";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
	info: {
		display: "flex",
		gap: 40,
		width: 500,
		minHeight: 100,
	},
}));

export const CurrentUser: React.FC<{
	isDrawerOpen: boolean;
}> = ({ isDrawerOpen }) => {
	const classes = useStyles();
	const history = useHistory();
	const [userData, setUserData] = React.useState<ICurrentUser | null>(null);
	const [open, setOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		const loadCurrentUser = async () => {
			try {
				const currentUserData = await getUser();
				setUserData(currentUserData);
			} catch (error) {
				history.push("/login");
			}
		};
		loadCurrentUser();
	}, [history]);

	return (
		userData && (
			<>
				<NavMenuItem
					key={"currentUser"}
					link={""}
					isDrawerOpen={isDrawerOpen}
				>
					<ListItemIcon
						style={{ color: "white" }}
						onClick={() => setOpen(true)}
					>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText
						primary={userData.userName}
						onClick={() => setOpen(true)}
					/>
				</NavMenuItem>
				{open && (
					<Container>
						<Dialog open={open} onClose={() => setOpen(false)}>
							<Title
								title={"Current user info"}
								onClose={() => setOpen(false)}
							/>
							<DialogContent className={classes.info}>
								<Box>
									<Typography>Username:</Typography>
									<Typography>Email:</Typography>
									<Typography>Roles:</Typography>
								</Box>
								<Box>
									<Typography>{userData.userName}</Typography>
									<Typography>{userData.email}</Typography>
									<Box>
										{userData.authorities.map((role) => (
											<Typography key={role}>
												{DisplayAuthorities.has(role)
													? DisplayAuthorities.get(
															role,
													  )
													: role}
											</Typography>
										))}
									</Box>
								</Box>
							</DialogContent>
						</Dialog>
					</Container>
				)}
			</>
		)
	);
};
