import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
	Box,
	CircularProgress,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import {
	IBackwardFilter,
	IBackwardSearchMatchModel,
} from "../../store/BackwardSearch/types";
import { ResizableBox } from "react-resizable";
import { itemWidth } from "../LiveEvents/LiveEventsList";
import {
	IElasticHit,
	IElasticSource,
	IElasticSourceObjects,
} from "../../store/Events/types";
import BackwardHit from "./BackwardHit";
import _ from "lodash";
import { getHitsForResultAction } from "../../store/BackwardSearch/action";
import { HITS_REQUEST_SIZE } from "../../containers/BackwardSearchResult/BackwardSearchResultView";
import { AsyncActionStatus } from "../../store/AsyncState";

const itemHeight = 300;
const itemPadding = 4;
const maxPercentageOfWindow = 0.4;
const defaultColsCount = 1;
const subtractFromHeight = 64;

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
	},
	listLoader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 80,
		textAlign: "center",
		margin: 0,
	},
}));

type Props = {
	id: string;
	index: number;
	selectEvent: (event: IElasticHit) => void;
	selectedEvent?: IElasticHit;
	filter: IBackwardFilter | undefined;
	events: IElasticHit[];
	isEventsLoading: boolean;
	isResultsLoading: boolean;
	resizableWidth?: number;
	onDoubleClick?: (event: IElasticSource & IElasticSourceObjects) => void;
};

const ResultsList = ({
	id,
	index,
	selectedEvent,
	events,
	filter,
	isEventsLoading,
	isResultsLoading,
	resizableWidth,
	selectEvent,
	onDoubleClick,
}: Props) => {
	const dispatch = useDispatch();
	const results = useSelector((state: AppState) =>
		state.backwardSearch.results.find((r) => r.id === id),
	);

	const orderByScore = useSelector(
		(state: AppState) =>
			state.userConfig.data.preference.backwardSortByScore,
	);
	const classes = useStyles();
	const ref = useRef<VirtuosoHandle>(null);
	const scrollRef = useRef<HTMLElement | null>(null);
	const [isLoadingHits, setIsLoadingHits] = useState(false);

	const maxWidth = Math.max(
		Math.floor((window.innerWidth * maxPercentageOfWindow) / itemWidth) *
			itemWidth,
		itemWidth,
	);

	const itemsFitsToWidth = Math.floor(maxWidth / itemWidth) || 1;

	const [size, setSize] = useState({
		maxWidth,
		minWidth: itemWidth,
		width:
			resizableWidth ??
			Math.min(
				(itemsFitsToWidth > defaultColsCount
					? defaultColsCount
					: itemsFitsToWidth) * itemWidth,
				maxWidth,
			),
		height: window.innerHeight - subtractFromHeight,
		itemsFitsToWidth,
	});

	const sourceKeys = useSelector((state: AppState) => state.sources.keys);

	const hitsByRow = useMemo(() => {
		const hitsArr: (IBackwardSearchMatchModel | null)[] = [
			...(results?.subjects?.[index]?.matches?.content ?? []),
		];
		const addEmptyElementsCount =
			Math.floor(size.width / itemWidth) -
			(hitsArr.length % Math.floor(size.width / itemWidth));

		if (
			addEmptyElementsCount > 0 &&
			addEmptyElementsCount !== Math.floor(size.width / itemWidth)
		) {
			for (let i = 0; i < addEmptyElementsCount; i++) {
				hitsArr.push(null);
			}
		}

		return hitsArr.reduce((acc, hit, i) => {
			const index = Math.floor(i / Math.floor(size.width / itemWidth));
			if (!acc[index]) acc[index] = [];
			acc[index].push(hit);

			return acc;
		}, [] as (IBackwardSearchMatchModel | null)[][]);
	}, [results, index, size.width]);

	const fetchHits = useCallback(
		(
			page: number,
			index: number,
			size = HITS_REQUEST_SIZE,
			resetHits = false,
		) => {
			if (isLoadingHits || isResultsLoading) return;
			setIsLoadingHits(true);
			getHitsForResultAction(index, resetHits, {
				...filter,
				orderByScore: orderByScore ?? false,
				id,
				size,
				page: page,
			})(dispatch).finally(() => setIsLoadingHits(false));
		},
		[dispatch, filter, isResultsLoading, orderByScore, id, isLoadingHits],
	);

	const getMoreHits = () => {
		if (isLoadingHits || isResultsLoading) return;
		const matches = results?.subjects?.[index]?.matches;
		const totalPages = (matches?.totalPages ?? 1) - 1;
		if (!matches || matches.number === totalPages || isEventsLoading)
			return;

		fetchHits(matches.number + 1, index);
	};

	useEffect(() => {
		const handleResize = () => {
			setSize((prev) => ({
				...prev,
				maxWidth: Math.max(
					Math.floor(
						(window.innerWidth * maxPercentageOfWindow) / itemWidth,
					) * itemWidth,
					itemWidth,
				),
				width: Math.min(
					(Math.floor(prev.width / itemWidth) || 1) * itemWidth,
					prev.maxWidth,
				),
				height: window.innerHeight - subtractFromHeight,
				itemsFitsToWidth: Math.floor(prev.maxWidth / itemWidth) || 1,
			}));
		};

		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (scrollRef.current) scrollRef.current.scrollTo({ top: 0 });
	}, [index, filter]);

	useEffect(() => {
		const matches = results?.subjects?.[index]?.matches;
		if (!matches && !isResultsLoading && !isLoadingHits && results)
			fetchHits(0, index, HITS_REQUEST_SIZE, true);
	}, [fetchHits, index, isResultsLoading, isLoadingHits, results]);

	const hitsCount = (results?.subjects?.[index]?.matches?.content ?? [])
		.length;

	const Header = () => {
		if (isResultsLoading || (isLoadingHits && hitsCount === 0))
			return (
				<Box className={classes.listLoader}>
					<CircularProgress />
				</Box>
			);
		return null;
	};

	return (
		<Box display="flex" gridGap={8} className={classes.root}>
			<ResizableBox
				width={size.width}
				height={size.height}
				minConstraints={[size.minWidth, size.height]}
				maxConstraints={[size.maxWidth, size.height]}
				onResize={(_, data) => {
					setSize((prev) => ({
						...prev,
						width: Math.min(data.size.width, prev.maxWidth),
						itemsFitsToWidth:
							Math.floor(prev.maxWidth / itemWidth) || 1,
					}));
				}}
				onResizeStop={() => {
					setSize((prev) => ({
						...prev,
						width: Math.min(
							(Math.floor(prev.width / itemWidth) || 1) *
								itemWidth,
							prev.maxWidth,
						),
					}));
				}}
			>
				<Box
					height="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					{hitsCount === 0 && !isResultsLoading && !isLoadingHits ? (
						<Typography style={{ fontWeight: 'bold'}}>No hits found</Typography>
					) : (
						<Virtuoso
							components={{ Header }}
							ref={ref}
							scrollerRef={(sRef) => {
								if (sRef instanceof HTMLElement)
									scrollRef.current = sRef;
							}}
							style={{ width: "100%", height: "100%" }}
							endReached={() => {
								if (!isResultsLoading && !isLoadingHits)
									getMoreHits();
							}}
							onScroll={(e) => {
								if (results)
									dispatch({
										type: "Backward_Search_Result",
										status: AsyncActionStatus.SUCCEEDED,
										payload: {
											...results,
											scrolledY:
												e.currentTarget.scrollTop,
										},
									});
							}}
							initialScrollTop={results?.scrolledY ?? 0}
							data={hitsByRow || []}
							overscan={100}
							totalCount={hitsByRow.length}
							fixedItemHeight={itemHeight}
							defaultItemHeight={itemHeight}
							itemContent={(itemIndex, item) => {
								return (
									<Box
										display="flex"
										gridGap={8}
										p={itemPadding + "px"}
										height={itemHeight}
									>
										{item.map((hit, i) => {
											const event = events.find(
												(e) =>
													e._source.subjectId ===
													hit?.subjectId,
											);
											return (
												<BackwardHit
													hit={hit}
													event={event}
													isSelected={
														Boolean(event) &&
														Boolean(
															selectedEvent,
														) &&
														event?._id ===
															selectedEvent?._id
													}
													sourceName={
														sourceKeys[
															hit?.vms ?? ""
														]?.content.find(
															(s) =>
																s.id ===
																hit?.source,
														)?.displayName ?? ""
													}
													onClick={() => {
														if (event)
															selectEvent(event);
													}}
													onDoubleClick={() => {
														if (event)
															onDoubleClick?.({
																...event._source,
																id: event._id,
															});
													}}
													key={
														String(itemIndex) +
														String(i)
													}
												/>
											);
										})}
									</Box>
								);
							}}
						/>
					)}
				</Box>
			</ResizableBox>
		</Box>
	);
};

export default ResultsList;
