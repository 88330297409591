import { FC, memo, useMemo, useRef } from "react";
import {
	Box,
	makeStyles,
	Typography,
	IconButton,
	Select,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { DATE_TIME_FORMAT, THEME } from "../../../config";
import { IElasticHit, MatchProps } from "../../../store/Events/types";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { Match } from "./Match";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { Skeleton } from "@material-ui/lab";
import { updateEventAction } from "../../../store/Events/action";
import SecretText from "../../Secrets/SecretText";
import { YoutubeSearchedForRounded } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
	event: {
		display: "flex",
		alignItems: "center",
		padding: 12,
		justifyContent: "flex-start",
		flexDirection: "column",
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		maxWidth: "238px",
		backgroundColor: "white",
		position: "relative",
		flex: 1,
		overflow: "hidden",
		cursor: "pointer",
	},
	selected: {
		backgroundColor: "#BDD2CD",
	},
	photoDetails: {
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		gap: 5,
		maxWidth: "100%",
		paddingBottom: 4,
	},
	img: {
		width: "200px",
		maxWidth: "100%",
		maxHeight: "280px",
		objectFit: "contain",
		overflow: "hidden",
	},
	faceImg: {
		height: "100%",
		width: "100%",
		maxHeight: "280px",
		objectFit: "contain",
	},
	matchImg: {
		maxWidth: "50%",
		objectFit: "contain",
	},
	photos: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
		gap: 2,
	},
	matchPagination: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	detailsBtn: {
		position: "absolute",
		top: 5,
		left: 10,
	},
	centeredTextBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		width: "100%",
		"& > *": {
			fontSize: "0.9rem",
		},
	},
	popup: {
		"& 	.MuiPopover-paper": {
			borderRadius: "10px",
			padding: 10,
		},
	},
	matchMetadata: {
		marginTop: 10,
		display: "flex",
		gap: 8,
	},
	infoWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	score: {
		position: "absolute",
		bottom: -10,
		left: 0,
		backgroundColor: THEME.palette.info.main,
		color: THEME.palette.common.white,
		padding: "2px 4px",
		fontSize: "0.8rem",
		borderRadius: "2px",
	},
	selectIcon: {
		top: 3,
	},
	searchIcon: {
		position: "absolute",
		top: 2,
		right: 2,
	},
}));

export const EventItem: FC<{
	event: IElasticHit;
	isSelected: boolean;
	isLoadingMatches: boolean;
	sourceName?: string;
	onClick: () => void;
	loadMatch: (match: MatchProps) => void;
	openSearchDialog: (image: string) => void;
}> = memo(
	({
		event,
		isSelected,
		onClick,
		isLoadingMatches,
		loadMatch,
		sourceName,
		openSearchDialog,
	}) => {
		const classes = useStyles();
		const rootRef = useRef<HTMLDivElement>(null);
		const dispatch = useDispatch();

		const currentPage = event._source.best?.currentPage ?? 0;
		const hasMatches = event._source.best?.matches?.length > 0;

		const rules = event._source.rules;
		const allRules = useSelector((state: AppState) => state.rules.keys)?.[
			event._source.vms
		]?.content;

		const faceData = event._source.best.faces?.[0];
		const vechicleHumanData = event._source.best.vehicleHuman;
		const licensePlateData = event._source.best.licensePlates?.[0] || null;

		const faceImg = faceData?.thumbnail?.value;
		const vehicleHumanImg = vechicleHumanData?.thumbnail?.value;
		const licensePlateImg = licensePlateData?.thumbnail?.value;

		const matchesCount = event._source.best?.matches?.length;
		const matchProps: MatchProps | null = useMemo(
			() => event._source.best?.matches?.[currentPage] ?? null,
			[currentPage, event._source],
		);
		const subjects = useSelector((state: AppState) => state.subjects);
		const match = useMemo(
			() =>
				matchProps
					? subjects.keys[matchProps?.watchlist]?.content.find(
							(s) => s.name === matchProps.id,
					  )
					: undefined,
			[matchProps, subjects],
		);

		return (
			<>
				<div
					ref={rootRef}
					className={`${classes.event} ${
						isSelected && classes.selected
					}`}
					onClick={onClick}
				>
					{faceImg && (
						<Tooltip title="Search">
							<IconButton
								size="small"
								className={classes.searchIcon}
								onClick={(e) => {
									e.stopPropagation();
									openSearchDialog(faceImg);
								}}
							>
								<YoutubeSearchedForRounded />
							</IconButton>
						</Tooltip>
					)}
					<Typography>
						{event._source["@timestamp"]
							? `${moment(event._source["@timestamp"]).format(
									DATE_TIME_FORMAT,
							  )} `
							: null}
					</Typography>
					<Box
						className={classes.infoWrapper}
						style={{
							justifyContent: hasMatches
								? "space-between"
								: "center",
						}}
					>
						<Box
							className={classes.photoDetails}
							style={{
								marginBottom: matchProps?.score ? 12 : 0,
								flexDirection: licensePlateData
									? "column"
									: "row",
								height: hasMatches ? "unset" : "100%",
							}}
						>
							<Box
								className={classes.photos}
								style={{
									maxWidth: "100%",
									maxHeight: "100%",
									height: hasMatches ? "fit-content" : "100%",
								}}
							>
								{matchProps?.score && match && (
									<Tooltip
										title={`Matched with score: ${matchProps.score}`}
									>
										<Typography className={classes.score}>
											{`${matchProps?.score}`}
										</Typography>
									</Tooltip>
								)}
								{faceImg && (
									<img
										style={{
											height: hasMatches ? "unset" : "",
										}}
										className={classes.faceImg}
										src={`data:image/jpeg;base64,${faceImg}`}
										alt="event"
									/>
								)}
								{vehicleHumanImg && !faceImg && (
									<img
										className={classes.img}
										src={`data:image/jpeg;base64,${vehicleHumanImg}`}
										alt="event"
									/>
								)}
								{licensePlateImg && (
									<img
										src={`data:image/jpeg;base64,${licensePlateImg}`}
										alt="license"
										className={classes.img}
										style={{ height: "fit-content" }}
									/>
								)}
							</Box>

							{hasMatches && match?.thumb ? (
								<img
									className={classes.matchImg}
									src={`data:image/jpeg;base64,${match?.thumb}`}
									alt="match"
									style={{
										maxWidth: faceImg ? "50%" : "100%",
									}}
								/>
							) : hasMatches ? (
								<Skeleton
									variant="rect"
									style={{
										flex: 1,
										height: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}
									animation={
										isLoadingMatches ? "pulse" : false
									}
								/>
							) : null}
						</Box>
						{hasMatches && matchProps ? (
							<Match
								matchProps={matchProps}
								eventSecretId={event._source.secretId}
							/>
						) : event._source.secretId ? (
							<SecretText
								secretID={event._source.secretId}
								enableTooltip
							/>
						) : null}
						{sourceName && (
							<Box
								className={classes.centeredTextBox}
								gridGap={4}
							>
								<Typography color="textSecondary">
									Source:
								</Typography>
								<Typography noWrap>{sourceName}</Typography>
							</Box>
						)}
						{rules.length > 0 && (
							<Box className={classes.centeredTextBox}>
								<Typography color="textSecondary">
									{rules.length === 1 ? "Rule:" : "Rules:"}
								</Typography>
								{rules.length === 1 ? (
									<Tooltip
										title={
											<Typography variant="caption">
												Description:{" "}
												{
													allRules?.find(
														(rule) =>
															rule.name ===
															rules[0].name,
													)?.description
												}
											</Typography>
										}
									>
										<Typography noWrap>
											{rules[0].name}
										</Typography>
									</Tooltip>
								) : (
									<Select
										value={rules[0].name}
										disableUnderline
										onClick={(e) => e.stopPropagation()}
										SelectDisplayProps={{
											style: { fontSize: "0.9rem" },
										}}
										classes={{
											icon: classes.selectIcon,
										}}
									>
										{rules.map(
											(
												rule: { name: string },
												idx: number,
											) => (
												<MenuItem
													key={idx}
													value={rule.name}
													style={{
														backgroundColor:
															"white",
														fontSize: "0.9rem",
														cursor: "auto",
													}}
												>
													<Tooltip
														title={
															<Typography variant="caption">
																Description:{" "}
																{
																	allRules?.find(
																		(r) =>
																			r.name ===
																			rule.name,
																	)
																		?.description
																}
															</Typography>
														}
													>
														<span>{rule.name}</span>
													</Tooltip>
												</MenuItem>
											),
										)}
									</Select>
								)}
							</Box>
						)}
						{hasMatches && (
							<Box className={classes.matchPagination}>
								{matchesCount > 1 ? (
									<>
										<IconButton
											style={{ padding: 0 }}
											disabled={currentPage === 0}
											onClick={(e) => {
												e.stopPropagation();
												dispatch(
													updateEventAction({
														...event,
														_source: {
															...event._source,
															best: {
																...event._source
																	.best,
																currentPage:
																	currentPage -
																	1,
															},
														},
													} as IElasticHit),
												);
											}}
										>
											<ArrowLeftIcon />
										</IconButton>
										{currentPage + 1}/{matchesCount}
										<IconButton
											style={{ padding: 0 }}
											disabled={
												currentPage ===
												event._source.best.matches
													.length -
													1
											}
											onClick={(e) => {
												e.stopPropagation();
												const newPage = currentPage + 1;
												const nextMatch: MatchProps | null =
													event._source.best
														?.matches?.[
														newPage + 1
													] ?? null;

												if (nextMatch) {
													const isNextLoaded =
														subjects.keys[
															nextMatch.watchlist
														]?.content.find(
															(s) =>
																s.name ===
																nextMatch.id,
														);

													if (!isNextLoaded)
														loadMatch(nextMatch);
												}

												dispatch(
													updateEventAction({
														...event,
														_source: {
															...event._source,
															best: {
																...event._source
																	.best,
																currentPage:
																	newPage,
															},
														},
													} as IElasticHit),
												);
											}}
										>
											<ArrowRightIcon />
										</IconButton>
									</>
								) : null}
							</Box>
						)}
					</Box>
				</div>
			</>
		);
	},
);
