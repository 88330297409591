import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	makeStyles,
} from "@material-ui/core";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AppState } from "../../store";
import ResultsList from "../../components/BackwardSearch/ResultsList";
import { IElasticHit, MatchProps } from "../../store/Events/types";
import BackButton from "../../components/BackwardSearch/BackButton";
import clsx from "clsx";
import { isNumber } from "lodash";
import { EventStream } from "../../components/Events/Stream/EventStream";
import EventDetails from "../../components/Events/Event/EventDetails";
import { getItemAction } from "../../store/Subjects/action";
import BackwardImagesDialog from "../../components/BackwardSearch/BackwardImagesDialog";
import { camelCaseToReadable } from "../../helpers/Filter";
import { BackwardSearchStatus } from "../../store/BackwardSearch/types";
import {
	BrokenImageRounded,
	FaceRounded,
	FilterListRounded,
	ImageRounded,
	SwapHorizontalCircleRounded,
} from "@material-ui/icons";
import BackwardFilterPopover from "../../components/BackwardSearch/BackwardFilterPopover";
import { updateConfigAction } from "../../store/UserConfig/action";
import { BackwardBoxes, ProbeChoice } from "../../store/UserConfig/types";
import useBackwardSearchResult from "../../hooks/useBackwardSearchResult";
import { AsyncActionStatus } from "../../store/AsyncState";
import { SELECTED_HIT_BG_COLOR } from "../../components/BackwardSearch/BackwardHit";
import CanvasImage from "../../components/BackwardSearch/CanvasImage";

const useStyles = makeStyles((THEME) => ({
	container: {
		height: "100vh",
		overflow: "hidden",
		display: "flex",
		gap: 16,
		padding: "10px 0",
	},
	list: {
		display: "flex",
		flexDirection: "column",
	},
	main: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		gap: 7,
		flex: 1,
	},
	content: {
		display: "flex",
		gap: 8,
		flex: 3,
		width: "100%",
		overflow: "hidden",
		paddingBottom: 1,
	},
	paper: {
		padding: 12,
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		gap: 4,
	},
	imageContainer: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
	},
	empty: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	matchChip: {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: THEME.palette.grey[900],
		color: THEME.palette.common.white,
		padding: "2px 4px",
		fontSize: "0.8rem",
		fontWeight: 600,
	},
	thumbWrapper: {
		border: `3px solid transparent`,
		borderRadius: 6,
		overflow: "hidden",
		minWidth: 60,
		height: 80,
		cursor: "pointer",
	},
	thumbWrapperSelected: {
		border: `3px solid ${THEME.palette.success.dark}`,
	},
	img: {
		height: "fit-content",
		maxHeight: "100%",
		objectFit: "contain",
		position: "relative",
		border: `2px solid ${THEME.palette.grey[900]}`,
	},
	mainImg: {
		width: "100%",
		height: "100%",
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
	},
	thumbs: {
		display: "flex",
		overflowX: "auto",
		overflowY: "hidden",
		flex: 1,
		gap: 4,
	},
	thumbsContainer: {
		display: "flex",
		flexDirection: "column",
		overflowY: "hidden",
	},
	title: {
		color: THEME.palette.primary.main,
		margin: 0,
	},
	zoomable: {
		cursor: "zoom-in",
	},
	errorText: {
		textAlign: "center",
		color: THEME.palette.error.dark,
	},
	imageError: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	listHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	swapIcon: {
		position: "absolute",
		top: 0,
		right: 0,
	},
	probe: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export const FILTER_BUTTON_ID = "filter-button";
export const HITS_REQUEST_SIZE = 50;
const DEFAULT_BOXES_ORDER = [
	BackwardBoxes.GALLERY,
	BackwardBoxes.PROBE,
	BackwardBoxes.VIDEO,
];

const BackwardSearchResultView = () => {
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const userConfig = useSelector((state: AppState) => state.userConfig);

	const selectedResultIndex = useMemo(
		() =>
			Number(
				new URLSearchParams(history.location.search).get("index") ?? 0,
			),
		[history.location.search],
	);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [selectedEvent, setSelectedEvent] = useState<
		IElasticHit | undefined
	>();

	const [isImgDialogOpen, setIsImgDialogOpen] = useState(false);
	const [mainImgError, setMainImgError] = useState(false);
	const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);

	const thumbsRef = useRef<HTMLDivElement | null>(null);
	const {
		events,
		results,
		isEventsLoading,
		isResultLoading,
		filter,
		allSources,
		setFilter,
	} = useBackwardSearchResult({ id, selectedResultIndex });

	const selectEvent = (event: IElasticHit) => setSelectedEvent(event);

	const loadMatch = useCallback(
		async (match: MatchProps) =>
			getItemAction(
				match.watchlist,
				match.id,
				match.secret ?? "",
			)(dispatch),
		[dispatch],
	);

	const handleBoxSwap = (index: number) => {
		const boxes =
			userConfig.data.preference.backwardBoxesOrder ??
			DEFAULT_BOXES_ORDER;
		const newBoxes = [...boxes];

		const temp = newBoxes[index];

		newBoxes[index] = newBoxes[index + 1];
		newBoxes[index + 1] = temp;

		dispatch(
			updateConfigAction({
				variant: "preference",
				config: {
					...userConfig.data.preference,
					backwardBoxesOrder: newBoxes,
				},
			}),
		);
	};

	const selectThumb = useCallback(
		(index: number) => {
			history.replace(`?index=${index}`);

			if (results)
				dispatch({
					type: "Backward_Search_Result",
					status: AsyncActionStatus.SUCCEEDED,
					payload: {
						...results,
						selectedIndex: index,
					},
				});
		},
		[dispatch, history, results],
	);

	useEffect(() => {
		if (events.length > 0 && results) {
			const firstHit =
				results.subjects?.[selectedResultIndex].matches?.content[0];
			const event = events.find(
				(e) => e._source.subjectId === firstHit?.subjectId,
			);

			const isCurrentEventFromSelectedFace = results.subjects?.[
				selectedResultIndex
			].matches?.content.some(
				(match) => match.subjectId === selectedEvent?._source.subjectId,
			);

			if (!isCurrentEventFromSelectedFace) setSelectedEvent(event);
		}
	}, [events, selectedEvent, results, selectedResultIndex]);

	useEffect(() => {
		const scrollToThumb = (index: number) => {
			thumbsRef.current?.children?.[index]?.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		};

		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.code === "ArrowLeft") {
				e.preventDefault();
				selectThumb(Math.max(0, selectedResultIndex - 1));
				scrollToThumb(selectedResultIndex - 1);
			}
			if (e.code === "ArrowRight") {
				e.preventDefault();
				selectThumb(
					Math.min(
						(results?.subjects?.length ?? 0) - 1,
						selectedResultIndex + 1,
					),
				);
				scrollToThumb(selectedResultIndex + 1);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [selectedResultIndex, results, id, history, selectThumb]);

	return (
		<Box className={classes.container}>
			{!results ? (
				<>
					<BackButton />
					<Box className={classes.empty}>
						<CircularProgress />
					</Box>
				</>
			) : (
				<>
					<Box className={classes.list}>
						<Box className={classes.listHeader}>
							<BackButton />
							<IconButton
								aria-describedby={FILTER_BUTTON_ID}
								color="inherit"
								size="small"
								disabled={!results}
								onClick={(e) =>
									setFilterAnchor(e.currentTarget)
								}
							>
								<FilterListRounded />
							</IconButton>
							<BackwardFilterPopover
								results={results}
								filter={filter}
								onFilter={(f) => setFilter(f)}
								anchorEl={filterAnchor}
								onClose={() => setFilterAnchor(null)}
								allSources={allSources}
							/>
						</Box>
						<ResultsList
							filter={filter}
							id={id}
							index={selectedResultIndex}
							selectEvent={selectEvent}
							selectedEvent={selectedEvent}
							events={events}
							isEventsLoading={isEventsLoading}
							isResultsLoading={isResultLoading}
						/>
					</Box>
					<Box className={classes.main}>
						{(results.subjects?.length ?? 0) > 1 && (
							<>
								<Box className={classes.thumbsContainer}>
									<Typography
										variant="h6"
										className={classes.title}
									>
										Detected faces
									</Typography>
									<div
										className={classes.thumbs}
										onWheel={(e) =>
											(e.currentTarget.scrollLeft +=
												e.deltaY)
										}
										ref={thumbsRef}
									>
										{results.subjects &&
											results.subjects.map(
												(thumb, index) => (
													<Box
														onClick={() =>
															selectThumb(
																thumb.index,
															)
														}
														position="relative"
														key={index}
														className={clsx(
															classes.thumbWrapper,
															thumb.index ===
																selectedResultIndex &&
																classes.thumbWrapperSelected,
														)}
													>
														<img
															className={
																classes.img
															}
															src={`data:image/jpeg;base64,${
																thumb.thumb ??
																""
															}`}
														/>
														{isNumber(
															thumb.hitCount,
														) ? (
															<Tooltip
																title={`Total matches: ${thumb.hitCount}`}
															>
																<Typography
																	className={
																		classes.matchChip
																	}
																>
																	{`${thumb.hitCount}`}
																</Typography>
															</Tooltip>
														) : null}
													</Box>
												),
											)}
									</div>
								</Box>
								<Divider />
							</>
						)}
						<Box className={classes.content}>
							{(
								userConfig.data.preference.backwardBoxesOrder ??
								DEFAULT_BOXES_ORDER
							).map((box, i, selfArray) => (
								<React.Fragment key={box}>
									{box === BackwardBoxes.GALLERY ? (
										<Box
											className={classes.paper}
											position="relative"
											flex={1}
											style={{
												backgroundColor: selectedEvent
													? SELECTED_HIT_BG_COLOR
													: undefined,
											}}
										>
											<Typography
												variant="h6"
												align="center"
											>
												Gallery
											</Typography>
											{i < selfArray.length - 1 && (
												<IconButton
													size="small"
													className={classes.swapIcon}
													onClick={() =>
														handleBoxSwap(i)
													}
												>
													<SwapHorizontalCircleRounded />
												</IconButton>
											)}
											<Box
												className={
													classes.imageContainer
												}
											>
												{selectedEvent ? (
													<img
														className={clsx(
															classes.mainImg,
															classes.zoomable,
														)}
														onClick={() =>
															setIsImgDialogOpen(
																!isImgDialogOpen,
															)
														}
														src={`data:image/jpeg;base64,${
															selectedEvent
																._source.best
																?.faces?.[0]
																?.thumbnail
																?.value ?? ""
														}`}
													/>
												) : (
													<Typography align="center">
														Select event to see
														image
													</Typography>
												)}
											</Box>
										</Box>
									) : box === BackwardBoxes.PROBE ? (
										<Box
											className={classes.paper}
											flex={1}
											position="relative"
										>
											<Typography
												variant="h6"
												align="center"
												className={classes.probe}
											>
												<Tooltip
													title={
														userConfig.data
															.preference
															.probeChoice ===
														ProbeChoice.THUMB
															? "Show original image"
															: "Show face image"
													}
												>
													<IconButton
														size="small"
														onClick={() =>
															dispatch(
																updateConfigAction(
																	{
																		variant:
																			"preference",
																		config: {
																			...userConfig
																				.data
																				.preference,
																			probeChoice:
																				userConfig
																					.data
																					.preference
																					.probeChoice ===
																				ProbeChoice.THUMB
																					? ProbeChoice.ORIGINAL
																					: ProbeChoice.THUMB,
																		},
																	},
																),
															)
														}
													>
														{userConfig.data
															.preference
															.probeChoice ===
														ProbeChoice.THUMB ? (
															<ImageRounded />
														) : (
															<FaceRounded />
														)}
													</IconButton>
												</Tooltip>
												<Divider
													style={{ marginRight: 4 }}
													flexItem
													orientation="vertical"
												/>
												Probe
												{i < selfArray.length - 1 && (
													<IconButton
														size="small"
														className={
															classes.swapIcon
														}
														onClick={() =>
															handleBoxSwap(i)
														}
													>
														<SwapHorizontalCircleRounded />
													</IconButton>
												)}
											</Typography>
											<Box
												className={
													classes.imageContainer
												}
											>
												{mainImgError ? (
													<Box
														className={
															classes.imageError
														}
													>
														<BrokenImageRounded
															className={
																classes.img
															}
															color="action"
															fontSize="large"
														/>
														<Typography>
															Image failed to load
														</Typography>
													</Box>
												) : userConfig.data.preference
														.probeChoice ===
														ProbeChoice.THUMB &&
												  results.subjects?.[
														selectedResultIndex
												  ]?.thumb ? (
													<img
														className={clsx(
															classes.mainImg,
															classes.zoomable,
														)}
														onClick={() =>
															setIsImgDialogOpen(
																!isImgDialogOpen,
															)
														}
														src={
															"data:image/jpeg;base64," +
															results.subjects?.[
																selectedResultIndex
															]?.thumb
														}
														onError={() =>
															setMainImgError(
																true,
															)
														}
													/>
												) : results.searchImage
														?.image ? (
													<CanvasImage
														thumbIndex={
															selectedResultIndex
														}
														result={results}
														drawSelectedOnly
														imgClassName={
															classes.mainImg
														}
														rootClassName={
															classes.zoomable
														}
														onClick={() =>
															setIsImgDialogOpen(
																!isImgDialogOpen,
															)
														}
														onImgError={() =>
															setMainImgError(
																true,
															)
														}
													/>
												) : results.searchImage !==
												  undefined ? (
													<Typography align="center">
														No image
													</Typography>
												) : (
													<CircularProgress />
												)}
											</Box>
											{results?.error && (
												<>
													<Typography
														className={
															classes.errorText
														}
													>
														{camelCaseToReadable(
															BackwardSearchStatus[
																results.status -
																	1
															] ?? "Unknown",
														)}
													</Typography>
													<Typography align="center">
														{results.error}
													</Typography>
												</>
											)}
										</Box>
									) : box === BackwardBoxes.VIDEO ? (
										<EventStream
											event={selectedEvent ?? null}
											style={{
												maxHeight: "100%",
												position: "relative",
												...(i < selfArray.length - 1
													? {
															paddingTop: 28,
													  }
													: {}),
											}}
										>
											{i < selfArray.length - 1 && (
												<IconButton
													size="small"
													className={classes.swapIcon}
													onClick={() =>
														handleBoxSwap(i)
													}
												>
													<SwapHorizontalCircleRounded />
												</IconButton>
											)}
										</EventStream>
									) : null}
								</React.Fragment>
							))}
						</Box>
						<EventDetails
							event={selectedEvent?._source ?? null}
							loadMatch={loadMatch}
							updateEvent={(event) => {
								if (selectedEvent)
									setSelectedEvent({
										...selectedEvent,
										_source: {
											...selectedEvent._source,
											...event,
										},
									});
							}}
							style={{
								marginBottom: "1px",
								minHeight: window.innerHeight * 0.2,
							}}
						/>
					</Box>
				</>
			)}
			{isImgDialogOpen && (
				<BackwardImagesDialog
					setIndex={selectThumb}
					isOpen={isImgDialogOpen}
					setIsOpen={setIsImgDialogOpen}
					thumbIndex={selectedResultIndex}
					result={results}
					eventImage={
						selectedEvent?._source.best?.faces?.[0]?.thumbnail.value
					}
				/>
			)}
		</Box>
	);
};

export default BackwardSearchResultView;
