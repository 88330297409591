import Authentication from "../store/Authentication/AuthenticationStore";
import {
	IMetadataSchema,
	IMetadata,
	IMetadataProperties,
} from "../store/Schemas/types";

export const isJustAnalyst = () =>
	Authentication.isAuthority("ROLE_ANALYST") &&
	Authentication.getUserRoles().length === 1;

export const checkMetadata = (schema: IMetadataSchema, metadata: IMetadata) => {
	const properties = schema.properties as IMetadataProperties;
	const copyMetadata = { ...metadata };
	if (metadata) {
		for (const [key, value] of Object.entries(metadata)) {
			if (Object.keys(properties).includes(key)) {
				if (Array.isArray(properties[key].type)) {
					if (properties[key].type.includes("number")) {
						copyMetadata[key] = parseFloat(value);
					} else if (
						properties[key].type.includes("integer") &&
						value !== null
					) {
						copyMetadata[key] = parseInt(value);
					}
				} else {
					if (properties[key].type === "number") {
						copyMetadata[key] = parseFloat(value);
					} else if (
						properties[key].type === "integer" &&
						value !== null
					) {
						copyMetadata[key] = parseInt(value);
					}
				}
			}
			if (value !== null && typeof value === "object") {
				const values = Object.values(value);
				if (values.every((item) => item === null)) {
					if (schema.required && schema.required.includes(key)) {
						copyMetadata[key] = checkMetadata(
							properties[key],
							value,
						);
					} else {
						delete copyMetadata[key];
					}
				} else {
					copyMetadata[key] = checkMetadata(properties[key], value);
				}
			}
		}
	}

	return copyMetadata;
};
