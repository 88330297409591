import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import {
	ISource,
	ISourceRequestInfo,
	ISourceinfo,
	IRunSourceTaskQuery,
} from "./types";
import { PagedContent, IResponse } from "../types";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import qs from "querystring";

export type SOURCE =
	| "SOURCE"
	| "SOURCE_UPDATE_FILTER"
	| "SOURCE_UPDATE"
	| "SOURCE_LOCAL_UPDATE"
	| "SOURCE_LOCAL_DELETE";

export type SourceAction = AsyncAction<
	SOURCE,
	PagedContent<ISource[]> | ISourceRequestInfo | ISource,
	string
>;

export const getSources = async (
	vmsName: string,
	request?: ISourceRequestInfo,
) => {
	const result = await Axios.get<PagedContent<ISource[]>>(
		API_BASE_PREFIX + `vms/${vmsName}/sources`,
		{
			params: request,
		},
	);
	return result.data;
};

export const getSource = async (vmsName: string, sourceId: string) => {
	try {
		const result = await Axios.get<ISource>(
			API_BASE_PREFIX + `vms/${vmsName}/sources/${sourceId}`,
		);
		return result.data;
	} catch (error) {
		throw error;
	}
};

export const startSourceTask = async (
	vmsName: string,
	sourceId: string,
	query: IRunSourceTaskQuery,
	taskId: string = "live",
) => {
	const response = await Axios.post<IResponse>(
		API_BASE_PREFIX +
			`vms/${vmsName}/sources/${sourceId}/tasks/${taskId}/start`,
		undefined,
		{
			params: query,
			paramsSerializer: (params) => qs.stringify(params),
		},
	);
	return response.data;
};

export const stopSourceTask = async (
	vmsName: string,
	sourceId: string,
	taskId: string = "live",
) => {
	const response = await Axios.post<IResponse>(
		API_BASE_PREFIX +
			`vms/${vmsName}/sources/${sourceId}/tasks/${taskId}/stop`,
	);
	return response.data;
};

export const connectSource = async (vmsName: string, body: ISourceinfo) => {
	return (
		await Axios.post<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/sources`,
			body,
		)
	).data;
};

export const disconnectSource = async (vmsName: string, sourceId: string) => {
	return (
		await Axios.delete<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/sources/${sourceId}`,
		)
	).data;
};

export const editSource = async (
	vmsName: string,
	sourceId: string,
	body: ISourceinfo,
) => {
	return (
		await Axios.put<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/sources/${sourceId}`,
			body,
		)
	).data;
};

export function getSourceAction(vmsName: string, request?: ISourceRequestInfo) {
	return async("SOURCE", getSources, vmsName, vmsName, request);
}
export function updateSourceAction(
	vmsName: string,
	sourceId: string,
	source?: ISource,
	action?: string,
) {
	return async (dispatch: (action: SourceAction) => void) => {
		try {
			if (action === "Remove") {
				dispatch({
					meta: vmsName,
					type: "SOURCE_LOCAL_DELETE",
					status: AsyncActionStatus.SUCCEEDED,
					payload: source as ISource,
				});
				return;
			}
			if (source) {
				dispatch({
					meta: vmsName,
					type: "SOURCE_LOCAL_UPDATE",
					status: AsyncActionStatus.SUCCEEDED,
					payload: source,
				});
				return;
			}
			const result = await getSource(vmsName, sourceId);
			if (result)
				dispatch({
					meta: vmsName,
					payload: result,
					status: AsyncActionStatus.SUCCEEDED,
					type: "SOURCE_UPDATE",
				});
		} catch (error) {
			dispatch({
				meta: vmsName,
				status: AsyncActionStatus.FAILED,
				type: "SOURCE_UPDATE",
				payload: error as Error,
			});
		}
	};
}

export function setSourcesFilter(
	vmsName: string,
	filter: ISourceRequestInfo,
): SourceAction {
	return {
		meta: vmsName,
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "SOURCE_UPDATE_FILTER",
	};
}
