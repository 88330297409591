import React, { SyntheticEvent, useEffect, useState } from "react";
import {
	IWatchlistsContainer,
	IgetWatchlistsRequest,
} from "../../store/Watchlists/types";
import { HttpError } from "../../config/types";
import { Grid, Paper, makeStyles, Snackbar } from "@material-ui/core";
import Watchlists from "../../components/Watchlists/Watchlists";
import Alert, { IAlert } from "../../components/UI/Alert";
import Subjects from "../../components/Subjects/Subjects";
import { checkPermissions } from "../Layout/Layout";

export interface IWatchlistViewPropsToDispatch {
	loadWatchlists: (request: IgetWatchlistsRequest) => void;
}

export interface IWatchlistViewPropsToState {
	watchlists: IWatchlistsContainer;
	error?: HttpError;
}

type IWatchlistViewProps = IWatchlistViewPropsToDispatch &
	IWatchlistViewPropsToState;

const useStyles = (authentication: boolean) =>
	makeStyles(() => ({
		paper: {
			marginTop: 10,
			display: "flex",
			maxWidth: authentication ? "100%" : "25%",
		},
		container: {
			marginTop: 10,
			padding: 10,
		},
		watchlists: {
			overflow: "auto",
		},
		watchgrid: {
			maxWidth: "23.5%",
		},
	}));

const WatchlistsView: React.FC<IWatchlistViewProps> = ({
	watchlists,
	loadWatchlists,
	error,
}) => {
	const classes = useStyles(
		checkPermissions([
			"ROLE_ADMINISTRATOR",
			"ROLE_WATCHLIST_ADMINISTRATOR",
			"ROLE_WATCHLIST_OPERATOR",
			"ROLE_ANALYST",
		]),
	)();

	const [filter, setFilter] = useState<IgetWatchlistsRequest>({
		page: 0,
		size: 10,
		secret: undefined,
		find: undefined,
		type: undefined,
	});
	const [selectedWatchlist, setSelectedWatchlist] = useState<number>(0);
	const [selectedSubject, setSelectedSubject] = useState<number>(0);
	const [alert, setAlert] = useState<IAlert | undefined>(undefined);
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

	useEffect(() => {
		loadWatchlists(filter);
	}, [filter, loadWatchlists]);

	const handleOpenSnackBar = React.useCallback((alert: IAlert) => {
		handleCloseSnackBar();
		setTimeout(() => {
			setAlert(alert);
			setOpenSnackbar(true);
		}, 100);
	}, []);

	const handleCloseSnackBar = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	const handleSelected = (watchlist: number, subject: number) => {
		setSelectedWatchlist(watchlist);
		setSelectedSubject(subject);
	};

	return (
		<>
			<Paper className={classes.paper}>
				<Grid
					container
					className={classes.container}
					alignItems="stretch"
				>
					{checkPermissions([
						"ROLE_ADMINISTRATOR",
						"ROLE_WATCHLIST_ADMINISTRATOR",
						"ROLE_WATCHLIST_OPERATOR",
						"ROLE_ANALYST",
					]) ? (
						<>
							<Grid item xs={12} md={3}>
								<Watchlists
									watchlists={watchlists}
									filter={filter}
									setFilter={setFilter}
									loadWatchlists={loadWatchlists}
									selected={selectedWatchlist}
									setSelected={handleSelected}
									onSnack={handleOpenSnackBar}
								/>
							</Grid>
							<Grid item xs={12} md={9}>
								{watchlists.content.length > 0 ? (
									<Subjects
										watchlist={
											watchlists.content[
												selectedWatchlist
											]
										}
										onSnack={handleOpenSnackBar}
										selected={selectedSubject}
										setSelected={setSelectedSubject}
									/>
								) : null}
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<Watchlists
								watchlists={watchlists}
								filter={filter}
								setFilter={setFilter}
								loadWatchlists={loadWatchlists}
								selected={selectedWatchlist}
								setSelected={handleSelected}
								onSnack={handleOpenSnackBar}
							/>
						</Grid>
					)}
				</Grid>
			</Paper>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={openSnackbar || error !== undefined}
				autoHideDuration={6000}
				onClose={handleCloseSnackBar}
			>
				<div>
					<Alert
						onClose={handleCloseSnackBar}
						alert={
							error
								? { variant: "error", message: error.message }
								: (alert as IAlert)
						}
					/>
				</div>
			</Snackbar>
		</>
	);
};

export default WatchlistsView;
