import React, { useEffect } from "react";
import {
	MenuItem,
	Select,
	TextField,
	Checkbox,
	FormControlLabel,
	FormControl,
	InputLabel,
	Grid,
	makeStyles,
	CircularProgress,
	Typography,
	Tooltip,
} from "@material-ui/core";
import {
	IMetadataSchema,
	IMetadata,
	IMetadataProperties,
} from "../../../store/Schemas/types";

const useStyles = makeStyles(() => ({
	enum: {
		display: "flex",
	},
	select: {
		"&.Mui-disabled option": {
			color: "black",
		},
	},
	bool: {
		display: "flex",
	},
}));

interface IFormMetadataProps {
	schema: IMetadataSchema;
	onUpdate(newMeta: IMetadata): void;
	metadata: IMetadata;
	isReadOnly: boolean;
	errors?: { [key: string]: string };
}

const FormMetadata: React.FC<IFormMetadataProps> = ({
	schema,
	onUpdate,
	metadata,
	isReadOnly,
	errors,
}) => {
	const classes = useStyles();

	useEffect(() => {
		if (schema && schema.properties) {
			const newMeta: IMetadata = {};
			Object.keys(schema.properties).forEach((key) => {
				if (metadata[key] === null && schema.properties) {
					newMeta[key] = schema.properties[key].default || null;
				}
			});
			onUpdate({ ...metadata, ...newMeta });
		}
	}, [schema, onUpdate, metadata]);

	const handleUpdate = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
		type: string,
		key: string,
	) => {
		const result = event.target.value;

		switch (type) {
			case "enum": {
				onUpdate({ ...metadata, [key]: result });
				break;
			}
			case "string": {
				onUpdate({
					...metadata,
					[key]: result === "" ? null : result,
				});
				break;
			}
			case "boolean": {
				if (!isReadOnly)
					onUpdate({ ...metadata, [key]: !metadata[key] });
				break;
			}
			case "number": {
				const value = (result as string)
					.replace(/[^0-9.]/g, "")
					.replace(/(\..*?)\..*/g, "$1");
				onUpdate({
					...metadata,
					[key]: value,
				});
				break;
			}
			case "integer": {
				if (result === "") {
					onUpdate({
						...metadata,
						[key]: null,
					});
				} else {
					const re = /^\d+$/;
					if (re.test(result as string)) {
						onUpdate({
							...metadata,
							[key]: result,
						});
					}
				}
				break;
			}
			case "object": {
				onUpdate({
					...metadata,
					[key]: result,
				});
				break;
			}
		}
	};

	const handleNestedMeta = (newMeta: IMetadata, key: string) => {
		onUpdate({
			...metadata,
			[key]: newMeta,
		});
	};

	const Field = (
		property: IMetadataSchema,
		field: string,
		isRequired: boolean,
	) => {
		const propertyDescription = property.description || "";
		if (property.enum) {
			return (
				<FormControl className={classes.enum}>
					<InputLabel>
						{field}
						{isRequired ? " *" : ""}
					</InputLabel>
					<Select
						name={field}
						label={field}
						onChange={(e) => handleUpdate(e, "enum", field)}
						value={metadata ? metadata[field] || "" : ""}
						fullWidth
						disabled={isReadOnly}
						className={classes.select}
						required={isRequired}
						error={errors && !!errors[field]}
						inputProps={{
							title: propertyDescription,
						}}
					>
						{(property.enum as Array<number | string>).map(
							(item, i) => (
								<MenuItem key={i} value={item}>
									{item}
								</MenuItem>
							),
						)}
					</Select>
				</FormControl>
			);
		}
		const propertyType =
			property.type instanceof Array ? property.type[0] : property.type;
		switch (propertyType) {
			case "string": {
				return (
					<TextField
						fullWidth
						name={field}
						label={field}
						multiline
						maxRows={5}
						error={errors && !!errors[field]}
						helperText={errors && errors[field]}
						inputProps={{
							spellCheck: false,
							title: propertyDescription,
							readOnly: isReadOnly,
						}}
						value={metadata ? metadata[field] || "" : ""}
						onChange={(e) => handleUpdate(e, propertyType, field)}
						required={isRequired}
					/>
				);
			}
			case "boolean": {
				return (
					<FormControlLabel
						control={
							<Checkbox
								name={field}
								checked={Boolean(metadata[field])}
								inputProps={{
									title: propertyDescription,
								}}
								onChange={(e) =>
									handleUpdate(e, propertyType, field)
								}
							/>
						}
						label={
							<Typography className={classes.bool}>
								{field}
							</Typography>
						}
					/>
				);
			}
			case "number": {
				return (
					<TextField
						name={field}
						label={field}
						inputProps={{
							title: propertyDescription,
							readOnly: isReadOnly,
						}}
						error={errors && !!errors[field]}
						helperText={errors && errors[field]}
						value={metadata ? metadata[field] || "" : ""}
						onChange={(e) => handleUpdate(e, propertyType, field)}
						fullWidth
						required={isRequired}
					/>
				);
			}
			case "integer": {
				return (
					<TextField
						name={field}
						label={field}
						error={errors && !!errors[field]}
						helperText={errors && errors[field]}
						inputProps={{
							title: propertyDescription,
							readOnly: isReadOnly,
						}}
						value={metadata ? metadata[field] || "" : ""}
						onChange={(e) => handleUpdate(e, propertyType, field)}
						fullWidth
						required={isRequired}
					/>
				);
			}
			case "object": {
				return (
					<div>
						<Typography>
							{field}:{isRequired ? " *" : ""}
						</Typography>
						<div style={{ display: "flex", marginLeft: 20 }}>
							<Tooltip title={property.description}>
								<div>
									<FormMetadata
										schema={property}
										isReadOnly={isReadOnly}
										metadata={
											metadata
												? metadata[field] || ""
												: ""
										}
										onUpdate={(meta) =>
											handleNestedMeta(meta, field)
										}
										errors={errors}
									/>
								</div>
							</Tooltip>
						</div>
					</div>
				);
			}
			default: {
				return null;
			}
		}
	};

	if (schema && schema.properties) {
		const keys = Object.keys(schema.properties);
		return (
			<Grid container spacing={1}>
				{schema && schema.properties ? (
					keys.map((field) => (
						<Grid item xs={12} key={field}>
							{Field(
								(schema.properties as IMetadataProperties)[
									field
								],
								field,
								schema.required
									? schema.required.includes(field)
									: false,
							)}
						</Grid>
					))
				) : (
					<Grid item>
						<CircularProgress />
					</Grid>
				)}
			</Grid>
		);
	}
	return null;
};

export default FormMetadata;
