import React, { useState, useEffect, useRef } from "react";
import {
	Typography,
	Grid,
	TablePagination,
	makeStyles,
	Box,
} from "@material-ui/core";
import SubjectsButtons from "./SubjectsButtons";
import Alert, { IAlert } from "../UI/Alert";
import {
	IQuerySecret,
	IWatchlist,
	IWatchlistQueryParameters,
} from "../../store/Watchlists/types";
import {
	SubjectsDataContainer,
	ISubjectsFilter,
	IEditSubjectBody,
	ICreateSubjectBody,
} from "../../store/Subjects/types";
import { AppState } from "../../store";
import {
	getItemsAction,
	setSubjectsFilter,
	deleteSubject,
	editSubject,
	createSubject,
	updateSubjectAction,
} from "../../store/Subjects/action";
import { connect } from "react-redux";
import { HttpError } from "../../config/types";
import ConfirmDialog from "../UI/ConfirmDialog";
import SubjectsDisplay from "./SubjectsDisplay";
import CenteredLoading from "../UI/CenteredLoading";
import BounceTextField from "../UI/BounceTextField";
import { handleTaskAction } from "../../helpers/VMSCrud";
import { clearWatchlist } from "../../store/Watchlists/action";

const useStyles = makeStyles(() => ({
	box: {
		borderColor: "#4495a9",
		height: "74vh",
		marginRight: 10,
		overflowY: "auto",
		overflowX: "hidden",
		borderRadius: 4,
	},
}));

interface ISubjectsOwnProps {
	watchlist: IWatchlist;
	onSnack(alert: IAlert): void;
	selected: number;
	setSelected: React.Dispatch<React.SetStateAction<number>>;
}

interface ISubjectsStateProps {
	subjects: SubjectsDataContainer | null;
	filter: ISubjectsFilter;
	error?: HttpError;
}

interface ISubjectDispatchProps {
	loadSubjects(
		watchlistId: string,
		query?: IWatchlistQueryParameters,
		controller?: AbortController,
	): void;
	setFilter(watchlistId: string, filter: ISubjectsFilter): void;
	updateSubject(
		watchlistId: string,
		subjectId: string,
		query: IQuerySecret,
	): void;
}

interface ISubjectsProps
	extends ISubjectsOwnProps,
		ISubjectsStateProps,
		ISubjectDispatchProps {}

const _Subjects: React.FC<ISubjectsProps> = ({
	watchlist,
	onSnack,
	subjects,
	loadSubjects,
	filter,
	setFilter,
	error,
	selected,
	setSelected,
	updateSubject,
}) => {
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
	const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
	const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
	const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
	const [confirmOn, setConfirmOn] = React.useState<"clear" | "delete">(
		"clear",
	);
	const abortController = useRef<AbortController | undefined>();
	const classes = useStyles();

	useEffect(() => {
		const controller = new AbortController();
		if (abortController.current) abortController.current.abort();
		abortController.current = controller;

		loadSubjects(
			watchlist.id,
			{
				...filter,
				secret: watchlist.secret,
			},
			abortController.current,
		);
	}, [watchlist, filter, loadSubjects]);

	const handleSubjectsChangePage = (_: unknown, newPage: number) => {
		setFilter(watchlist.id, { ...filter, page: newPage });
		setSelected(0);
	};

	const handleSubjectsChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFilter(watchlist.id, {
			...filter,
			page: 0,
			size: parseInt(event.target.value, 10),
		});
		if (selected + 1 > parseInt(event.target.value, 10)) setSelected(0);
	};

	const handleSubjectsButton = (option: string) => {
		switch (option) {
			case "create": {
				setOpenCreateDialog(true);
				break;
			}
			case "edit": {
				setOpenEditDialog(true);
				break;
			}
			case "delete": {
				setConfirmOn(option);
				setOpenConfirmDialog(true);
				break;
			}
			case "clear": {
				setConfirmOn(option);
				setOpenConfirmDialog(true);
				break;
			}
		}
	};

	const handleDeleteSuccess = () => {
		onSnack({
			message: "Subject deleted successfully",
			variant: "success",
		});
		if (
			(subjects as SubjectsDataContainer).content.length === 1 &&
			(filter.page as number) > 0
		) {
			setFilter(watchlist.id, {
				...filter,
				page: (filter.page as number) - 1,
			});
		} else {
			loadSubjects(watchlist.id, {
				...filter,
				secret: watchlist.secret,
			});
		}
		setSelected(0);
		setOpenConfirmDialog(false);
		setAsyncLoading(false);
	};

	const handleDeleteError = (msg: string) => {
		onSnack({
			variant: "error",
			message: "Failed to delete subject: " + msg,
		});
		setOpenConfirmDialog(false);
		setAsyncLoading(false);
	};

	const handleDeleteSubject = async () => {
		if (subjects)
			await handleTaskAction(
				deleteSubject,
				handleDeleteSuccess,
				handleDeleteError,
				watchlist.id,
				subjects.content[selected].name,
				{ secret: watchlist.secret },
			);
	};

	const handleEditSuccess = (subjectId: string) => {
		onSnack({
			variant: "success",
			message: "Subject updated successfully",
		});
		updateSubject(watchlist.id, subjectId, {
			secret: watchlist.secret,
		});
		loadSubjects(watchlist.id, {
			...filter,
			secret: watchlist.secret,
		});
		setOpenEditDialog(false);
		setAsyncLoading(false);
	};

	const handleEditError = (msg: string) => {
		onSnack({
			variant: "error",
			message: "Failed to edit subject: " + msg,
		});
		setAsyncLoading(false);
	};

	const handleEditSubject = async (
		subjectId: string,
		body: IEditSubjectBody,
	) => {
		await handleTaskAction(
			editSubject,
			() => handleEditSuccess(subjectId),
			handleEditError,
			watchlist.id,
			subjectId,
			body,
			{ secret: watchlist.secret, type: watchlist.type },
		);
	};

	const handleCreateSuccess = () => {
		onSnack({
			variant: "success",
			message: "Subject created successfully",
		});
		loadSubjects(watchlist.id, {
			...filter,
			secret: watchlist.secret,
		});
		setSelected(0);
		setAsyncLoading(false);
		setOpenCreateDialog(false);
	};

	const handleCreateError = (msg: string) => {
		onSnack({
			variant: "error",
			message: "Failed to create subject: " + msg,
		});
		setAsyncLoading(false);
	};

	const handleCreateSubject = async (body: ICreateSubjectBody) => {
		await handleTaskAction(
			createSubject,
			handleCreateSuccess,
			handleCreateError,
			watchlist.id,
			body,
		);
	};

	const handleSearchChange = (val: string) => {
		setFilter(watchlist.id, {
			...filter,
			page: 0,
			find: val,
		});
		setSelected(0);
	};

	const handleCloseModal = (
		closeModal: (value: React.SetStateAction<boolean>) => void,
	) => {
		closeModal(false);
		setAsyncLoading(false);
	};

	const handleClearSuccess = () => {
		onSnack({
			message: "Watchlist successfully cleared.",
			variant: "success",
		});
		loadSubjects(watchlist.id, {
			...filter,
			secret: watchlist.secret,
		});
		setOpenConfirmDialog(false);
		setAsyncLoading(false);
	};

	const handleClearError = (msg: string) => {
		onSnack({
			message: "Failed to clear watchlist: " + msg,
			variant: "error",
		});
		setOpenConfirmDialog(false);
		setAsyncLoading(false);
	};

	const handleClearSubjects = async () => {
		await handleTaskAction(
			clearWatchlist,
			handleClearSuccess,
			handleClearError,
			watchlist.id,
			{
				secret: watchlist.secret,
			},
		);
	};
	return (
		<>
			{error !== undefined ? (
				<Alert
					alert={{
						message: error.message,
						variant: "error",
					}}
				/>
			) : null}
			<Grid container direction="column" style={{ marginLeft: 15 }}>
				<Grid item xs={12} md={12}>
					<Typography variant="h4">
						{watchlist.id} - Subjects
					</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					<SubjectsButtons
						click={handleSubjectsButton}
						hasSubjects={!(subjects && subjects.content.length > 0)}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<Box className={classes.box} border={1}>
						{subjects ? (
							<SubjectsDisplay
								subjects={subjects.content}
								selected={selected}
								onSelected={setSelected}
								onDoubleClick={handleSubjectsButton}
								openCreate={openCreateDialog}
								openEdit={openEditDialog}
								onCreate={handleCreateSubject}
								onEdit={handleEditSubject}
								closeCreate={() =>
									handleCloseModal(setOpenCreateDialog)
								}
								closeEdit={() =>
									handleCloseModal(setOpenEditDialog)
								}
								watchlist={watchlist}
								onLoading={() => setAsyncLoading(true)}
								loading={asyncLoading}
								onSnack={onSnack}
							/>
						) : (
							<CenteredLoading height={73} />
						)}
					</Box>
				</Grid>
				<Grid container>
					<Grid item xs={11} xl={7} style={{ marginTop: 5 }}>
						<BounceTextField
							onChange={handleSearchChange}
							style={{ width: "100%" }}
							placeholder="Find by Id or Metadata"
							value={
								filter && filter.find ? filter.find : undefined
							}
							key={watchlist.id}
						/>
					</Grid>
					<Grid item xs={11} xl={5}>
						<TablePagination
							count={subjects ? subjects.totalElements : 0}
							page={filter ? (filter.page as number) : 0}
							onPageChange={handleSubjectsChangePage}
							rowsPerPage={
								filter ? (filter.size as number) || 20 : 20
							}
							onRowsPerPageChange={
								handleSubjectsChangeRowsPerPage
							}
							rowsPerPageOptions={[5, 10, 20, 50]}
							component="div"
						/>
					</Grid>
				</Grid>
			</Grid>
			<ConfirmDialog
				open={openConfirmDialog}
				onConfirm={
					confirmOn === "clear"
						? handleClearSubjects
						: handleDeleteSubject
				}
				onClose={() => setOpenConfirmDialog(false)}
				loading={asyncLoading}
				onLoading={() => setAsyncLoading(true)}
			>
				{confirmOn === "clear"
					? "Are you sure you want to clear this watchlist?"
					: "Are you sure you want to delete this subject?"}
			</ConfirmDialog>
		</>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: ISubjectsOwnProps,
): ISubjectsStateProps => {
	const subjects = state.subjects.keys[ownProps.watchlist.id]
		? state.subjects.keys[ownProps.watchlist.id]
		: null;

	return {
		subjects,
		error: state.subjects.erorr,
		filter: state.subjects.paging[ownProps.watchlist.id],
	};
};

const mapDispatchToProps = (dispatch: any): ISubjectDispatchProps => {
	return {
		loadSubjects: (
			watchlistId: string,
			query?: IWatchlistQueryParameters,
			controller?: AbortController,
		) => getItemsAction(watchlistId, query, controller)(dispatch),
		setFilter: (watchlistId: string, filter: ISubjectsFilter) =>
			dispatch(setSubjectsFilter(watchlistId, filter)),
		updateSubject: (
			watchlistId: string,
			subjectId: string,
			query: IQuerySecret,
		) => updateSubjectAction(watchlistId, subjectId, query)(dispatch),
	};
};

const Subjects = connect(mapStateToProps, mapDispatchToProps)(_Subjects);
export default Subjects;
