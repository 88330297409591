import React from "react";
import clsx from "clsx";
import {
	Drawer,
	createStyles,
	WithStyles,
	withStyles,
	makeStyles,
} from "@material-ui/core";
import {
	DRAWER_WIDTH,
	DRAWER_WIDTH_COLLAPSED,
	THEME,
} from "../../config/index";
import DrawerItems from "./DrawerItems";
import { IMenuItem } from "../../config/MenuItems";
import { RouteComponentProps, withRouter } from "react-router-dom";

const styles = createStyles({
	drawer: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		[THEME.breakpoints.up("sm")]: {
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
	},
	drawerPaper: {
		width: DRAWER_WIDTH,
		backgroundColor: THEME.palette.primary.main,
		color: "white",
	},
	selected: {
		backgroundColor: THEME.palette.secondary.main + "!important",
	},
});

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: "flex",
		},
		drawerOpen: {
			width: DRAWER_WIDTH,
			color: "white",
			backgroundColor: THEME.palette.primary.main,
			transition: THEME.transitions.create("width", {
				easing: THEME.transitions.easing.sharp,
				duration: THEME.transitions.duration.enteringScreen,
			}),
			overflow: "hidden",
		},
		drawerClose: {
			color: "white",
			backgroundColor: THEME.palette.primary.main,
			transition: THEME.transitions.create("width", {
				easing: THEME.transitions.easing.sharp,
				duration: THEME.transitions.duration.leavingScreen,
			}),
			overflow: "hidden",
			width: DRAWER_WIDTH_COLLAPSED,
		},
	}),
);

export interface INavigationProps
	extends WithStyles<typeof styles>,
		RouteComponentProps<any, any, any> {
	menu: Readonly<IMenuItem[]>;
	logo?: string;
	largerLogo?: string;
	name?: string;
	alt?: string;
	isDrawerOpen: boolean;
	onDrawerChange(state: boolean): void;
	redirectLink?: string;
}

const Navigation: React.FC<INavigationProps> = (props) => {
	const miniClasses = useStyles();

	const handleDrawerClick = (state: boolean) => {
		props.onDrawerChange(state);
	};

	return (
		<>
			<nav className={props.classes.drawer}>
				<Drawer
					variant="permanent"
					anchor="left"
					open
					className={clsx(props.classes.drawer, {
						[miniClasses.drawerOpen]: props.isDrawerOpen,
						[miniClasses.drawerClose]: !props.isDrawerOpen,
					})}
					classes={{
						paper: clsx({
							[miniClasses.drawerOpen]: props.isDrawerOpen,
							[miniClasses.drawerClose]: !props.isDrawerOpen,
						}),
					}}
				>
					<DrawerItems
						menu={props.menu}
						logo={props.logo}
						largerLogo={props.largerLogo}
						alt={props.alt}
						name={props.name}
						onDrawer={handleDrawerClick}
						redirectLink={props.redirectLink}
						isDrawerOpen={props.isDrawerOpen}
					/>
				</Drawer>
			</nav>
		</>
	);
};

export default withRouter(withStyles(styles)(Navigation));
