import AsyncState from "../AsyncState";
import { PagedContent, IRequest } from "../types";

export type Driver = "MILESTONE" | "GENERIC";
export type Status = "Ok" | "Failed";

export interface TasksStatus {
	RUNNING?: number;
	ERROR?: number;
	STOPPED?: number;
	STOPPING?: number;
	STARTING?: number;
	COMPLETED?: number;
	SCHEDULED?: number;
	WAITING?: number;
}

export interface IgetVMSRequest extends IRequest {
	sortBy?: string;
	asc?: boolean;
	like?: string;
}

export interface IVMS {
	name: string;
	driver: Driver;
	user: string;
	ip: string;
	eventPort: number;
	startAutomatically: boolean;
	status: Status;
	errorMessage: string | null;
	canConnectSource: boolean;
	sourceCount: number;
	tasksStatus?: TasksStatus;
	defaultEngineType: string[];
	mobilServerPort: number;
	useMobileServer: boolean;
	mobileServerSSL: boolean;
}

export interface IVMSInfo extends IVMS {
	password: string;
}

export type IVMSDataContainer = PagedContent<IVMS[]>;

export interface IVMSState extends AsyncState {
	data: IVMSDataContainer;
	keys: { [key: string]: IVMS };
	connections: { [key: string]: boolean };
}

type LoadEnginesAction = {
	readonly type: "Load";
	readonly engines: string[];
	readonly id: string;
};

type UpdateEnginesAction = {
	readonly type: "Update";
	readonly engines: string[];
	readonly id: string;
};

export type EngineActions = LoadEnginesAction | UpdateEnginesAction;

export const defaultEngineTypes = ["Faces", "VehicleHuman", "ALPR"];
