import { DialogTitle, Typography } from "@material-ui/core";
import { State } from "./AreaDialog";

type Props = {
	state: State;
};

const AreaDialogTitle = ({ state }: Props) => {
	const Text = () => {
		switch (state) {
			case State.grid:
				return (
					<>
						Click on rectangles to change if you want to search in
						specified area <br /> Click while holding CTRL key to
						split rectangle into smaller pieces
					</>
				);
			case State.incRect:
				return (
					<>
						Click and drag to select region where search should be
						performed
					</>
				);
			case State.excRect:
				return (
					<>
						Click and drag to select region where search should NOT
						be performed
					</>
				);
			case State.incPoly:
				return (
					<>
						Click to select points of polygon region where search
						should be performed.
						<br />
						Press 'ENTER' or double click to complete polygon
						selection.
					</>
				);
			case State.excPoly:
				return (
					<>
						Click to select points of polygon region where search
						should NOT be performed.
						<br />
						Press 'ENTER' or double click to complete polygon
						selection.
					</>
				);
			default:
				return <>Click on map to select area</>;
		}
	};

	return (
		<DialogTitle disableTypography style={{ minHeight: "7rem" }}>
			<Typography variant="h6" style={{ width: "fit-content" }}>
				Select search areas
			</Typography>
			<Typography
				variant="body1"
				align="center"
				style={{ width: "fit-content", margin: "0 auto" }}
			>
				<Text />
			</Typography>
		</DialogTitle>
	);
};

export default AreaDialogTitle;
