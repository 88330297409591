import React, { useEffect, useRef, useState } from "react";
import {
	Divider,
	Snackbar,
	Tooltip,
	TextField,
	TextFieldProps,
} from "@material-ui/core";
import { IgetSecretsRequest, ISecretsState } from "../../store/Secrets/types";
import { HttpError } from "../../config/types";
import { AppState } from "../../store";
import { getSecretsAction } from "../../store/Secrets/action";
import { connect } from "react-redux";
import { limitDescription } from "../Tasks/Filter/TaskFiltering";
import Alert from "./Alert";
import { Autocomplete } from "@material-ui/lab";
import { EventsFilter } from "../../store/Events/types";
import { debounce } from "lodash";
import { secretsToWritableSecrets } from "../../helpers/Utils";
import Authentication from "../../store/Authentication/AuthenticationStore";
import useCanSeeSecrets from "../../hooks/useCanSeeSecrets";

interface ISecretFormSelectStateProps {
	secrets: ISecretsState["data"]["content"];
	error?: HttpError;
}

interface ISecretFormSelectDispatchProps {
	loadSecrets(filter: IgetSecretsRequest): void;
}

interface ISecretFormSelectOwnProps {
	value?: EventsFilter["secret"];
	tooltipTitle?: string;
	onSecret(secret?: EventsFilter["secret"]): void;
	onlyWriteable?: boolean;
	margin?: TextFieldProps["margin"];
}

interface ISecretFormSelectProps
	extends ISecretFormSelectStateProps,
		ISecretFormSelectOwnProps,
		ISecretFormSelectDispatchProps {
	className?: string;
}

const _SecretFormSelect: React.FC<ISecretFormSelectProps> = ({
	secrets,
	value,
	error,
	loadSecrets,
	tooltipTitle,
	onSecret,
	className,
	onlyWriteable,
	margin,
}) => {
	const canSeeSecrets = useCanSeeSecrets(onlyWriteable);
	const [search, setSearch] = useState("");

	const debouncedSearch = useRef(
		debounce((searchValue: string) => {
			if (
				secrets.some((secret) =>
					secret.name
						.toLowerCase()
						.includes(searchValue.toLowerCase()),
				)
			) {
				loadSecrets({ search: searchValue });
			}
		}, 700),
	);

	useEffect(() => {
		if (search) {
			debouncedSearch.current(search);
		}
	}, [search]);

	React.useEffect(() => {
		loadSecrets({});
	}, [loadSecrets]);

	const handleSecretInput = (_: object, value: any) => {
		if (value !== null) {
			onSecret(value);
		} else {
			onSecret(undefined);
		}
	};

	const options = onlyWriteable
		? secretsToWritableSecrets(secrets, Authentication.getUserName() ?? "")
		: secrets;

	if (!canSeeSecrets) return null;

	return (
		<>
			<Tooltip title={tooltipTitle ?? ""} placement="bottom-start">
				<Autocomplete
					disabled={options.length === 0 && !search}
					value={
						secrets.find((secret) => secret.id === value?.id) ??
						null
					}
					noOptionsText={"No secrets found."}
					options={options}
					getOptionSelected={(option, value) =>
						option?.id === value?.id
					}
					getOptionLabel={(secretOption) => secretOption.name}
					inputValue={search}
					onInputChange={(_e, newInputValue) =>
						setSearch(() => newInputValue)
					}
					renderOption={(s) => (
						<div
							style={{
								display: "inline",
								maxHeight: 300,
							}}
						>
							<b>{s.name}</b>
							<br />
							{s.description ? (
								<>
									Description:
									{limitDescription(s.description)}
								</>
							) : null}
							<Divider />
						</div>
					)}
					onChange={handleSecretInput}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							margin={margin}
							name="secret"
							id="secret-search"
							className={className}
							label="Secret"
						/>
					)}
				/>
			</Tooltip>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state: AppState): ISecretFormSelectStateProps => {
	return {
		secrets: state.secrets.data.content,
		error: state.secrets.erorr,
	};
};

const mapDispatchToProps = (dispatch: any): ISecretFormSelectDispatchProps => {
	return {
		loadSecrets: (filter: IgetSecretsRequest) =>
			getSecretsAction(filter)(dispatch),
	};
};

const SecretFormSelect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(_SecretFormSelect);
export default SecretFormSelect;
