import React from "react";
import {
	ISecretsContainer,
	ISecret,
	IgetSecretsRequest,
} from "../../store/Secrets/types";
import {
	Paper,
	ClickAwayListener,
	TablePagination,
	makeStyles,
	Box,
} from "@material-ui/core";
import Authentication from "../../store/Authentication/AuthenticationStore";
import DisplaySecret from "./DisplaySecret";

interface ISecretListProps {
	secrets: ISecretsContainer;
	onSecret(secret: string, managable: boolean): void;
	onClickAway(): void;
	filter: IgetSecretsRequest;
	onPageChange: (event: unknown, newPage: number) => void;
	onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selected: string | undefined;
	onEdit(): void;
}

const useStyles = makeStyles(() => ({
	table: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%",
		overflow: "hidden",
	},
	list: {
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
	},
}));

const SecretList: React.FC<ISecretListProps> = ({
	secrets,
	onSecret,
	onClickAway,
	filter,
	onPageChange,
	onRowsPerPageChange,
	selected,
	onEdit,
}) => {
	const classes = useStyles();
	const [border, setBorder] = React.useState<string | undefined>(selected);

	const handleClickAway = () => {
		setBorder(undefined);
		onClickAway();
	};

	const handleSecretClick = (secret: ISecret) => {
		const userId = Authentication.getUserName();
		let flag = false;
		for (let i = 0; i < secret.permissions.length; i++) {
			if (
				userId === secret.permissions[i].user &&
				secret.permissions[i].manage
			) {
				flag = true;
				break;
			}
		}
		onSecret(secret.id, flag);
		setBorder(secret.id);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onPageChange(event, newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		onRowsPerPageChange(event);
	};

	const handleDblClick = (id: string) => {
		if (id === selected) {
			onEdit();
		}
	};

	return (
		<>
			{secrets.content.length > 0 ? (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Paper className={classes.table}>
						<Box className={classes.list}>
							{secrets.content.map((secret) => (
								<DisplaySecret
									key={secret.id}
									secret={secret}
									onSecretClick={handleSecretClick}
									onDblClick={handleDblClick}
									border={border}
								/>
							))}
						</Box>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							count={secrets ? secrets.totalElements : 0}
							page={filter ? filter.page || 0 : 0}
							rowsPerPage={filter ? filter.size || 10 : 10}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							component="div"
							style={{ overflow: "visible" }}
						/>
					</Paper>
				</ClickAwayListener>
			) : (
				<h3
					style={{
						color: "rgba(0, 0, 0, 0.54)",
						textAlign: "center",
					}}
				>
					No secrets available
				</h3>
			)}
		</>
	);
};

export default SecretList;
