import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import "typeface-roboto";

const theme = createTheme({
	palette: {
		primary: {
			main: "#293846",
		},
		secondary: {
			main: "#224d58",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#f3f3f4",
		},
		info: {
			main: "#1c7dc7",
		},
	},
	typography: {
		fontFamily: "Roboto",
	},
	props: {
		MuiTextField: {
			margin: "normal",
		},
		MuiFormControl: {
			margin: "normal",
		},
	},
	overrides: {
		MuiTable: {
			stickyHeader: {
				"& .MuiTableHead-root .MuiTableCell-root": {
					backgroundColor: "#e6e6e6",
				},
			},
		},
	},
});

export default theme;
