import React, { useCallback, useEffect } from "react";
import {
	makeStyles,
	Button,
	InputLabel,
	TextField,
	Box,
	Collapse,
	Typography,
	Checkbox,
} from "@material-ui/core";
import SecretFormSelect from "../../UI/SecretFormSelect";
import VMSFormSelect from "../../UI/VMSFormSelect";
import RulesFormSelect from "../../UI/RulesFormSelect";
import WatchlistFormSelect from "../../UI/WatchlistFormSelect";
import SourcesFormSelect from "../../UI/SourcesFormSelect";
import {
	EventsFilter,
	AdditionalFilterItem,
	IFieldExist,
} from "../../../store/Events/types";
import DropdownButton from "../../UI/DropdownButton";
import { THEME } from "../../../config";
import {
	SentimentSatisfiedAltOutlined as FaceLogo,
	DoubleArrow,
} from "@material-ui/icons";
import { ReactComponent as VehicleHumanLogo } from "../assets/vehicleHuman.svg";
import moment from "moment";
import { LogsTimeFilter } from "../../Logs/LogsTimeFilter";
import { ObjectAttributes } from "./ObjectAttributes";
import { FaceAttributes } from "./FaceAttributes";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import {
	addConfigAction,
	updateConfigAction,
} from "../../../store/UserConfig/action";
import _, { omit, upperFirst } from "lodash";
import Favorites from "./Favorites";
import History from "./History";
import { INITIAL_OPEN_GROUPS } from "../constants";
import { getSpecialDateAbsolute, SPECIALDATE } from "../../Logs/constants";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		margin: 0,
		padding: 12,
		overflowY: "auto",
	},
	box: {
		backgroundColor: THEME.palette.background.default,
		padding: "0px 8px",
	},
	textField: {
		marginTop: 4,
		marginBottom: 8,
	},
	flexItem: {
		gap: 4,
		display: "flex",
	},
	buttonsWrapper: {
		padding: "8px 12px",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "white",
	},
	subjectType: {
		cursor: "pointer",
		gap: 6,
		padding: "3px 6px",
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		borderBottom: `2px solid ${THEME.palette.grey[400]}`,
		"&:hover": {
			backgroundColor: THEME.palette.grey[300],
		},
	},
	selectedSubjectType: {
		backgroundColor: "#cfdee3",
		borderBottom: `2px solid ${THEME.palette.secondary.main}`,
		"&:hover": {
			backgroundColor: "#cfdee3",
		},
	},
	subjectTypeOptions: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 16,
		paddingTop: 8,
	},
	closeIcon: {
		alignSelf: "flex-end",
		cursor: "pointer",
	},
}));

interface Props {
	onFilter(filter: EventsFilter): void;
	scrollToTop(): void;
	setIsOpen(value: boolean): void;
	topFilter?: EventsFilter;
}

const FiltersContent = ({
	onFilter,
	topFilter,
	scrollToTop,
	setIsOpen,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const preferences = useSelector(
		(state: AppState) => state.userConfig.data.preference,
	);
	const [filter, setFilter] = React.useState<EventsFilter | undefined>(
		topFilter ?? undefined,
	);
	const [subjectType, setSubjectType] = React.useState<number>(
		topFilter?.subjectType ?? -1,
	);
	
	const [additionalFilters, setAdditionalFilters] = React.useState<{
		[key: string]: AdditionalFilterItem;
	}>({
		...filter?.moreFilters,
	});

	const [openBoxes, setOpenBoxes] = React.useState<
		{ [key: string]: boolean }[]
	>([
		...preferences.groups,
		...INITIAL_OPEN_GROUPS.filter((obj) => {
			return !Object.keys(obj).every((key) => {
				return preferences?.groups.some((otherObj) =>
					otherObj.hasOwnProperty(key),
				);
			});
		}),
	]);

	const [relativeFrom, setRelativeFrom] = React.useState(
		topFilter?.relativeFrom ?? {
			value: 1,
			text: "month",
		},
	);
	const [relativeUntil, setRelativeUntil] = React.useState(
		topFilter?.relativeUntil ?? {
			value: 0,
			text: "second",
		},
	);

	const [showAbsoluteStart, setShowAbsoluteStart] = React.useState(
		filter?.showAbsoluteStart ?? 0,
	);
	const [showAbsoluteEnd, setShowAbsoluteEnd] = React.useState(
		filter?.showAbsoluteEnd ?? 0,
	);

	const [from, setFrom] = React.useState(
		topFilter?.timeFrom
			? moment(topFilter?.timeFrom).toISOString()
			: topFilter?.time
			? getSpecialDateAbsolute(topFilter?.time).from
			: moment().toISOString(),
	);
	const [until, setUntil] = React.useState(
		topFilter?.timeTo
			? moment(topFilter?.timeTo).toISOString()
			: topFilter?.time
			? getSpecialDateAbsolute(topFilter?.time).until
			: moment().toISOString(),
	);

	const handleSpecialTime = (helperDate: SPECIALDATE) => {
		const newFilter = omit(
			{
				...filter,
				time: helperDate,
				showAbsoluteEnd: 1,
				showAbsoluteStart: 1,
			},
			["timeFrom", "timeTo", "relativeFrom", "relativeUntil"],
		);
		setFrom(getSpecialDateAbsolute(helperDate).from);
		setUntil(getSpecialDateAbsolute(helperDate).until);
		setShowAbsoluteEnd(1);
		setShowAbsoluteStart(1);
		setFilter(newFilter);
	};

	const handleFrom = (date: moment.Moment) => {
		const newFrom = moment(date).toISOString();
		setFrom(newFrom);
		if (filter?.time) setUntil(getSpecialDateAbsolute(filter?.time).until);
		setFilter(
			omit(
				{
					...filter,
					timeFrom: newFrom,
					timeTo: filter?.time
						? getSpecialDateAbsolute(filter?.time).until
						: until,
				},
				["time"],
			),
		);
	};
	const handleUntil = (date: moment.Moment) => {
		const newUntil = moment(date).toISOString();
		setUntil(newUntil);
		if (filter?.time) setFrom(getSpecialDateAbsolute(filter?.time).from);
		setFilter(
			omit(
				{
					...filter,
					timeTo: newUntil,
					timeFrom: filter?.time
						? getSpecialDateAbsolute(filter?.time).from
						: from,
				},
				["time"],
			),
		);
	};

	const handleFromUntil = (from: moment.Moment, until: moment.Moment) => {
		const newFrom = moment(from).toISOString();
		const newUntil = moment(until).toISOString();
		setFrom(newFrom);
		setUntil(newUntil);
		setFilter(
			omit(
				{
					...filter,
					timeFrom: newFrom,
					timeTo: newUntil,
				},
				["time"],
			),
		);
	};

	const handleRelativeFrom = (value: number, text: string) => {
		const newFrom = showAbsoluteStart
			? from
			: moment()
					.subtract(
						value as moment.DurationInputArg1,
						text as moment.unitOfTime.DurationConstructor,
					)
					.toISOString();
		setRelativeFrom({ value, text });
		setFrom(newFrom);
		if (filter?.time) setUntil(getSpecialDateAbsolute(filter?.time).until);
		setFilter(
			omit(
				{
					...filter,
					timeFrom: newFrom,
					relativeFrom: { value, text },
					timeTo: filter?.time
						? getSpecialDateAbsolute(filter?.time).until
						: until,
				},
				["time"],
			),
		);
	};

	const handleRelativeUntil = (value: number, text: string) => {
		const newUntil = showAbsoluteStart
			? until
			: moment()
					.subtract(
						value as moment.DurationInputArg1,
						text as moment.unitOfTime.DurationConstructor,
					)
					.toISOString();
		setRelativeUntil({ value, text });
		setUntil(newUntil);
		if (filter?.time) setFrom(getSpecialDateAbsolute(filter?.time).from);
		setFilter(
			omit(
				{
					...filter,
					timeTo: newUntil,
					relativeUntil: { value, text },
					timeFrom: filter?.time
						? getSpecialDateAbsolute(filter?.time).from
						: from,
				},
				["time"],
			),
		);
	};

	const saveGroupsConfig = useCallback(() => {
		if (_.isEqual(preferences.groups, openBoxes)) return;
		if (!preferences.savedInServer) {
			dispatch(
				addConfigAction({
					variant: "preference",
					config: {
						...preferences,
						groups: openBoxes,
						id: "1",
						savedInServer: true,
					},
				}),
			);
		} else {
			dispatch(
				updateConfigAction({
					variant: "preference",
					config: {
						...preferences,
						groups: openBoxes,
						id: "1",
					},
				}),
			);
		}
	}, [dispatch, openBoxes, preferences]);

	const handleFilterClick = useCallback(() => {
		saveGroupsConfig();
		if (filter) onFilter({ ...filter, showAbsoluteStart, showAbsoluteEnd });
		scrollToTop();
	}, [
		filter,
		showAbsoluteStart,
		showAbsoluteEnd,
		onFilter,
		saveGroupsConfig,
		scrollToTop,
	]);

	const handleResetClick = () => {
		onFilter({});
		setFilter(undefined);
		setSubjectType(-1);
		setAdditionalFilters({});
		setRelativeFrom({
			value: 1,
			text: "month",
		});
		setRelativeUntil({
			value: 0,
			text: "second",
		});
		setShowAbsoluteStart(0);
		setShowAbsoluteEnd(0);
		setFrom(moment().toISOString());
		setUntil(moment().toISOString());

		scrollToTop();
		saveGroupsConfig();
	};

	function isAnFieldExist(obj: any): obj is IFieldExist {
		return "id" in obj && obj.type === "Additional" && "description" in obj;
	}

	const toogleBox = (box: string) => {
		setOpenBoxes((prevState) => {
			const newState = prevState.map((item) => {
				if (item.hasOwnProperty(box)) {
					return {
						...item,
						[box]: !item[box],
					};
				}
				return item;
			});
			return newState;
		});
	};

	const renderDropdownBox = (key: string, index: number) => {
		switch (key) {
			case "favorites":
				return <Favorites currentFilter={filter} onFilter={onFilter} />;
			case "history":
				return <History currentFilter={filter} onFilter={onFilter} />;
			case "general":
				return (
					<div key={index}>
						<SecretFormSelect
							className={classes.textField}
							value={filter?.secret}
							onSecret={(secret) =>
								setFilter({
									...filter,
									secret,
									watchlist:
										!filter?.watchlist?.secret ||
										filter?.watchlist?.secret === secret?.id
											? filter?.watchlist
											: undefined,
								})
							}
							tooltipTitle="Secret rules can only be viewed by users with permissions."
						/>
						<VMSFormSelect
							value={filter?.vms}
							onVMS={(value) =>
								setFilter({
									...filter,
									vms: value,
									rule:
										value?.name === filter?.rule?.vms
											? filter?.rule
											: undefined,
								})
							}
							className={classes.textField}
						/>
						<SourcesFormSelect
							className={classes.textField}
							value={filter?.source ?? []}
							vmsName={filter?.vms?.name}
							onSource={(value) =>
								setFilter({
									...filter,
									source: value,
								})
							}
						/>
						<Box mt={1}>
							<InputLabel>Subject Type</InputLabel>
							<Box className={classes.subjectTypeOptions}>
								{subjectTypeData.map((option, i) => {
									const isSelected =
										subjectType === option.value;
									return (
										<Box
											display="flex"
											alignItems="center"
											key={i}
											className={clsx(
												classes.subjectType,
												isSelected &&
													classes.selectedSubjectType,
											)}
											onClick={() => {
												setSubjectType(option.value);
												setFilter({
													...filter,
													subjectType:
														option.value === -1
															? undefined
															: option.value,
												});
											}}
										>
											<Typography>
												{option.label}
											</Typography>
											{option.logo && option.logo}
										</Box>
									);
								})}
							</Box>
						</Box>
						<RulesFormSelect
							className={classes.textField}
							secret={filter?.secret?.id || ""}
							vmsName={filter?.vms?.name}
							value={filter?.rule}
							onRule={(value) =>
								setFilter({
									...filter,
									rule: value,
								})
							}
						/>
					</div>
				);
			case "time":
				return (
					<div key={index}>
						<Box className={classes.flexItem}>
							<LogsTimeFilter
								filter={filter}
								from={moment(from)}
								until={moment(until)}
								relativeFrom={relativeFrom}
								relativeUntil={relativeUntil}
								onRelativeFromChange={handleRelativeFrom}
								onRelativeUntilChange={handleRelativeUntil}
								onFromChange={handleFrom}
								onUntilChange={handleUntil}
								onFromUntilChange={handleFromUntil}
								onSpecialChange={handleSpecialTime}
								showAbsoluteStart={showAbsoluteStart}
								showAbsoluteEnd={showAbsoluteEnd}
								onAbsoluteStartChange={(num) =>
									setShowAbsoluteStart(num)
								}
								onAbsoluteEndChange={(num) =>
									setShowAbsoluteEnd(num)
								}
							/>
						</Box>
					</div>
				);
			case "watchlistSubject":
				return (
					<div
						key={index}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<WatchlistFormSelect
							className={classes.textField}
							type={filter?.subjectType}
							secret={filter?.secret?.id || ""}
							value={filter?.watchlist || filter?.fieldExist}
							onWatchlist={(value) => {
								if (!value) {
									setFilter({
										...filter,
										watchlist: undefined,
										fieldExist: undefined,
									});
								} else if (isAnFieldExist(value)) {
									setFilter({
										...filter,
										fieldExist: value,
										watchlist: undefined,
									});
								} else {
									setFilter({
										...filter,
										watchlist: value,
										fieldExist: undefined,
									});
								}
							}}
						/>
						<TextField
							value={filter?.matchId ?? ""}
							className={classes.textField}
							fullWidth
							label="Subject ID"
							onChange={(event) =>
								setFilter({
									...filter,
									matchId: event.target.value as string,
								})
							}
						/>
						<TextField
							value={filter?.licensePlateValue ?? ""}
							className={classes.textField}
							fullWidth
							label="License Plate"
							onChange={(event) =>
								setFilter({
									...filter,
									licensePlateValue: event.target.value,
								})
							}
						/>
						<Box display="flex" alignItems="center">
							<InputLabel
								id="subject-type-label"
								style={{
									width: "fit-content",
									color:
										filter?.exactId === "1" ? "black" : "",
								}}
							>
								Exact match
							</InputLabel>
							<Checkbox
								checked={filter?.exactId === "1"}
								onChange={(event) => {
									setFilter({
										...filter,
										exactId: event.target.checked
											? "1"
											: undefined,
									});
								}}
							/>
						</Box>
					</div>
				);
			case "objectAttributes":
				return (
					<div key={index}>
						<ObjectAttributes
							handleChange={(moreFilters: {
								[key: string]: AdditionalFilterItem;
							}) => {
								setFilter({
									...filter,
									moreFilters,
								});
								setAdditionalFilters(moreFilters);
							}}
							additionalFilters={additionalFilters}
						/>
					</div>
				);
			case "faceAttributes":
				return (
					<div key={index}>
						<FaceAttributes
							handleChange={(moreFilters: {
								[key: string]: AdditionalFilterItem;
							}) => {
								setFilter({
									...filter,
									moreFilters,
								});
								setAdditionalFilters(moreFilters);
							}}
							additionalFilters={additionalFilters}
							ageComponent={
								<Box
									className={classes.flexItem}
									style={{
										padding: "0 10px",
									}}
								>
									<TextField
										fullWidth
										className={classes.textField}
										type="number"
										value={filter?.ageFrom ?? ""}
										label="From Age"
										InputProps={{
											inputProps: {
												min: 0,
											},
										}}
										onChange={(e) =>
											setFilter({
												...filter,
												ageFrom: e.target.value,
											})
										}
									/>
									<TextField
										fullWidth
										className={classes.textField}
										type="number"
										value={filter?.ageTo ?? ""}
										label="To Age"
										onChange={(e) =>
											setFilter({
												...filter,
												ageTo: e.target.value,
											})
										}
										InputProps={{
											inputProps: {
												min: 0,
											},
										}}
									/>
								</Box>
							}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	const handleDrop = (result: DropResult) => {
		if (!result.destination) return;
		const items = Array.from(openBoxes);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setOpenBoxes(items);
	};

	const getLabel = (key: string) => {
		switch (key) {
			case "watchlistSubject":
				return "Watchlist / Subject";
			case "objectAttributes":
				return "Object Attributes";
			case "faceAttributes":
				return "Face Attributes";
			default:
				return upperFirst(key);
		}
	};

	React.useEffect(() => {
		setFilter(topFilter);
		setSubjectType(topFilter?.subjectType ?? -1);
		setAdditionalFilters(topFilter?.moreFilters ?? {});
		setRelativeFrom(
			topFilter?.relativeFrom ?? {
				value: 1,
				text: "month",
			},
		);
		setRelativeUntil(
			topFilter?.relativeUntil ?? {
				value: 0,
				text: "second",
			},
		);
		setShowAbsoluteStart(topFilter?.showAbsoluteStart ?? 0);
		setShowAbsoluteEnd(topFilter?.showAbsoluteEnd ?? 0);
		setFrom(
			topFilter?.timeFrom
				? moment(topFilter?.timeFrom).toISOString()
				: topFilter?.time
				? getSpecialDateAbsolute(topFilter?.time).from
				: moment().toISOString(),
		);
		setUntil(
			topFilter?.timeTo
				? moment(topFilter?.timeTo).toISOString()
				: topFilter?.time
				? getSpecialDateAbsolute(topFilter?.time).until
				: moment().toISOString(),
		);
	}, [topFilter]);

	useEffect(() => {
		const handleEnter = (event: KeyboardEvent) => {
			if (event.key === "Enter") {
				handleFilterClick();
			}
		};

		window.addEventListener("keydown", handleEnter);
		return () => window.removeEventListener("keydown", handleEnter);
	}, [handleFilterClick, filter, topFilter]);

	return (
		<>
			<Box className={classes.root}>
				<DoubleArrow
					className={classes.closeIcon}
					onClick={() => {
						setIsOpen(false);
						saveGroupsConfig();
					}}
				/>
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId="list-container">
						{(provided) => (
							<div
								className="list-container"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{openBoxes.map((box, index) => {
									const key = Object.keys(box)[0];
									const label = getLabel(key);
									return (
										<Draggable
											key={key}
											draggableId={key}
											index={index}
										>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<div
														{...provided.dragHandleProps}
													>
														<DropdownButton
															label={label}
															open={
																openBoxes[
																	index
																][key]
															}
															onClick={() =>
																toogleBox(key)
															}
														/>
													</div>
													<Collapse
														className={classes.box}
														in={
															openBoxes[index][
																key
															]
														}
													>
														{renderDropdownBox(
															key,
															index,
														)}
													</Collapse>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Box>

			<Box className={classes.buttonsWrapper}>
				<Box
					className={classes.flexItem}
					justifyContent="space-between"
				>
					<Button onClick={handleFilterClick}>Apply Filter</Button>{" "}
					<Button onClick={handleResetClick}>Reset</Button>
				</Box>
			</Box>
		</>
	);
};

export default FiltersContent;

export const subjectTypeData = [
	{
		label: "Any",
		value: -1,
	},
	{
		label: "Faces",
		value: 0,
		logo: <FaceLogo />,
	},
	{
		label: "Vehicle/Human",
		value: 1,
		logo: <VehicleHumanLogo width={24} height={24} />,
	},
	{
		label: "ALPR",
		value: 2,
		logo: (
			<Typography
				style={{
					fontSize: "0.65rem",
					rotate: "-45deg",
				}}
			>
				abc1
			</Typography>
		),
	},
];
