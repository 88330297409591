import { ISourceTaskState } from "./types";
import { ISourceTaskAction } from "./action";
import { Reducer } from "redux";
import { ISourceResponse } from "../types";

const initialTaskState: ISourceTaskState = {};

export const SourceTaskReducer: Reducer<ISourceTaskState, ISourceTaskAction> = (
	state = initialTaskState,
	action: ISourceTaskAction,
) => {
	switch (action.type) {
		case "TASK_UPDATE":
			const nState = { ...state };
			nState[action.target] = {
				...nState[action.target],
			};
			if (action.target === "source") {
				const payload = action.payload as ISourceResponse;
				const vms = nState[payload.vms];
				if (!vms) {
					nState[payload.vms] = {};
				}
				nState[payload.vms][payload.sourceId] = payload;
			}
			return nState;
		default:
			return state;
	}
};
