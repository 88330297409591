import axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IDefinition } from "./types";

export type Definitions = "Definitions";
export type DefinitionsAction = AsyncAction<
	Definitions,
	IDefinition[] | IDefinition,
	string
>;

const getDefinitions = async () => {
	try {
		const response = await axios.get(API_BASE_PREFIX + `config/definition`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getDefinitionsAction() {
	return async("Definitions", getDefinitions, "");
}
