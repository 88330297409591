import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecretsItem } from "../config/MenuItems";
import { checkPermissions } from "../containers/Layout/Layout";
import { secretsToWritableSecrets } from "../helpers/Utils";
import { AppState } from "../store";
import { AsyncActionStatus } from "../store/AsyncState";
import Authentication from "../store/Authentication/AuthenticationStore";
import { getSecretsAction } from "../store/Secrets/action";

const useCanSeeSecrets = (writeOnly?: boolean) => {
	const dispatch = useDispatch();
	const secretsState = useSelector((state: AppState) => state.secrets);
	const [isRequestSent, setIsRequestSent] = useState(false);
	const [canSeeSecrets, setCanSeeSecrets] = useState(false);

	useEffect(() => {
		const username = Authentication.getUserName();
		const secrets = writeOnly
			? secretsToWritableSecrets(
					secretsState.data.content,
					username ?? "",
			  )
			: secretsState.data.content;

		const isAllowed =
			checkPermissions(SecretsItem.permissions) || secrets.length > 0;

		setCanSeeSecrets(isAllowed);
		if (isAllowed) return;
		if (
			secretsState.status === AsyncActionStatus.UNSTARTED &&
			!isRequestSent
		) {
			setIsRequestSent(true);
			dispatch(getSecretsAction({}));
		}
	}, [
		secretsState.data.content,
		secretsState.status,
		dispatch,
		isRequestSent,
		writeOnly,
	]);

	return canSeeSecrets;
};

export default useCanSeeSecrets;
