import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { ITask, statuses } from "../../store/Tasks/types";
import { ISecret } from "../../store/Secrets/types";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config/index";
import {
	createTooltip,
	TaskTooltip,
	limitSourceName,
	addSpaceToStatus,
} from "./TaskRow";
import { ISource } from "../../store/Sources/types";

interface IHistoryTaskProps {
	task: ITask;
	secret?: ISecret;
	source?: ISource;
}

const HistoryTask: React.FC<IHistoryTaskProps> = ({ task, secret, source }) => {
	return (
		<TableRow>
			<TableCell>
				{moment(task.timestamp).format(DATE_TIME_FORMAT)}
			</TableCell>
			<TableCell>{addSpaceToStatus(task.status, statuses)}</TableCell>
			<TableCell>{task.message}</TableCell>
			<TableCell>{task.vms}</TableCell>
			{source ? (
				<TaskTooltip
					title={
						<>
							Name: {source.displayName}
							<br />
							ID: {source.id}
						</>
					}
				>
					<TableCell>{limitSourceName(source.displayName)}</TableCell>
				</TaskTooltip>
			) : (
				<TableCell />
			)}
			{secret ? (
				<TaskTooltip
					title={createTooltip(secret.description, secret.id)}
				>
					<TableCell>{secret.name}</TableCell>
				</TaskTooltip>
			) : (
				<TableCell />
			)}
			<TableCell>{task.watchlist}</TableCell>
			<TableCell>{task.subject}</TableCell>
			<TableCell>{task.rule}</TableCell>
			<TableCell>{task.user}</TableCell>
			<TableCell>{task.error}</TableCell>
		</TableRow>
	);
};

export default HistoryTask;
