import React, { useEffect } from "react";
import { ITasksContainer, IgetTasksRequest } from "../../store/Tasks/types";
import { Box, Snackbar } from "@material-ui/core";
import { HttpError } from "../../config/types";
import Alert from "../../components/UI/Alert";
import TasksFiltering from "../../components/Tasks/Filter/TaskFiltering";
import TaskList from "../../components/Tasks/TaskList";
import { THEME } from "../../config";

export interface ITasksViewPropsToDispatch {
	loadTasks: (request: IgetTasksRequest) => void;
	setTasksFilter: (filter: IgetTasksRequest) => void;
}

export interface ITasksViewPropsToState {
	tasks: ITasksContainer;
	filter: IgetTasksRequest;
	error?: HttpError;
}

type ITasksViewProps = ITasksViewPropsToDispatch & ITasksViewPropsToState;

const TasksView: React.FC<ITasksViewProps> = ({
	tasks,
	loadTasks,
	error,
	filter,
	setTasksFilter,
}) => {
	useEffect(() => {
		loadTasks({
			...filter,
		});
	}, [filter, loadTasks]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setTasksFilter({ ...filter, page: newPage });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setTasksFilter({
			...filter,
			page: 0,
			size: parseInt(event.target.value, 10),
		});
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			gridGap={THEME.spacing(2)}
			paddingY={1.25}
			height="100vh"
			overflow="hidden"
		>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<TasksFiltering onFilter={setTasksFilter} filter={filter} />
			<TaskList
				tasks={tasks}
				filter={filter}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				setFilter={setTasksFilter}
			/>
		</Box>
	);
};

export default TasksView;
