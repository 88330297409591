import React from "react";
import { Grid, Typography, makeStyles, Checkbox } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	eventText: {
		paddingTop: 10,
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

interface IActionsCheckboxesProps {
	onTrack(value: boolean): void;
	onDisappeared(value: boolean): void;
	trackCheck: boolean;
	disCheck: boolean;
}

const ActionsCheckboxes: React.FC<IActionsCheckboxesProps> = ({
	onTrack,
	onDisappeared,
	trackCheck,
	disCheck,
}) => {
	const classes = useStyles();

	const handleTrackClick = () => {
		onTrack(!trackCheck);
	};

	const handleDisappearedClick = () => {
		onDisappeared(!disCheck);
	};

	return (
		<>
			<Grid item className={classes.popoverRow}>
				<Typography>Send with:</Typography>
			</Grid>
			<Grid item container className={classes.popoverRow}>
				<Grid item>
					<Checkbox
						checked={trackCheck}
						onChange={handleTrackClick}
					/>
				</Grid>
				<Grid item>
					<Typography
						className={classes.eventText}
						onClick={handleTrackClick}
					>
						Track event
					</Typography>
				</Grid>
			</Grid>
			<Grid item container className={classes.popoverRow}>
				<Grid item>
					<Checkbox
						checked={disCheck}
						onChange={handleDisappearedClick}
					/>
				</Grid>
				<Grid item>
					<Typography
						className={classes.eventText}
						onClick={handleDisappearedClick}
					>
						Disappeared event
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default ActionsCheckboxes;
