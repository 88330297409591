import { makeStyles, Box } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { EventsFilter } from "../../../store/Events/types";
import FilterChip from "./FilterChip";
import { hiddenFilterKeys } from "./FiltersBar";

type Props = {
	filter: EventsFilter;
	onClear?: (key: string, value?: string) => void;
	chipStyles?: React.CSSProperties;
	disablePortal?: boolean;
};
const useStyles = makeStyles(() => ({
	chipsBox: {
		display: "flex",
		flexWrap: "wrap",
		padding: "0 4px",
		gap: 6,
		flex: 1,
	},
}));
const Chips = ({ filter, onClear, chipStyles, disablePortal }: Props) => {
	const classes = useStyles();

	const restMorefilters = _.omit(
		filter.moreFilters,
		"colors",
		"directions",
		"objects",
		"ageGroup",
	);
	const restFilters = _.omit(
		{ ...filter, ...restMorefilters },
		hiddenFilterKeys,
		"moreFilters",
	);
	const colors = {
		...filter?.moreFilters?.colors,
		values:
			filter?.moreFilters?.colors?.values.filter((v) => v !== "Any") ??
			[],
	};
	const directions = {
		...filter?.moreFilters?.directions,
		values:
			filter?.moreFilters?.directions?.values.filter(
				(v) => v !== "Any",
			) ?? [],
	};
	const objects = {
		...filter?.moreFilters?.objects,
		values:
			filter?.moreFilters?.objects?.values.filter((v) => v !== "Any") ??
			[],
	};

	const ageGroup = {
		...filter?.moreFilters?.ageGroup,
		values:
			filter?.moreFilters?.ageGroup?.values.filter((v) => v !== "Any") ??
			[],
	};

	return (
		<Box className={classes.chipsBox}>
			{_.map(
				restFilters,
				(value: EventsFilter[typeof key], key: keyof EventsFilter) => (
					<FilterChip
						key={key}
						objKey={key}
						value={value}
						onClear={onClear}
						chipStyle={chipStyles}
						disablePortal={disablePortal}
					/>
				),
			)}
			{colors?.values.map((_, i) => (
				<FilterChip
					key={i}
					objKey={"colors"}
					value={{
						...colors,
						values: [colors.values[i] ?? ""],
					}}
					onClear={onClear}
					chipStyle={chipStyles}
					disablePortal={disablePortal}
				/>
			))}
			{directions?.values.map((_, i) => (
				<FilterChip
					key={i}
					objKey={"directions"}
					value={{
						...directions,
						values: [directions.values[i] ?? ""],
					}}
					onClear={onClear}
					chipStyle={chipStyles}
					disablePortal={disablePortal}
				/>
			))}
			{objects?.values.map((_, i) => (
				<FilterChip
					key={i}
					objKey={"objects"}
					value={{
						...objects,
						values: [objects.values[i] ?? ""],
					}}
					onClear={onClear}
					chipStyle={chipStyles}
					disablePortal={disablePortal}
				/>
			))}
			{ageGroup?.values.map((_, i) => (
				<FilterChip
					key={i}
					objKey={"ageGroup"}
					value={{
						...ageGroup,
						values: [ageGroup.values[i] ?? ""],
					}}
					onClear={onClear}
					chipStyle={chipStyles}
					disablePortal={disablePortal}
				/>
			))}
		</Box>
	);
};

export default Chips;
