import { Box, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { THEME } from "../../config";

const useStyles = makeStyles(() => ({
	bar: {
		backgroundColor: THEME.palette.success.main,
	},
}));

type Props = {
	loaded: number;
	total: number;
	width?: string | number;
	showPercentage?: boolean;
};

const LinearBar = ({
	loaded,
	total,
	showPercentage,
	width = "100%",
}: Props) => {
	const classes = useStyles();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={width}
		>
			<Box
				width="100%"
				display="flex"
				mr={1}
				alignItems="center"
				gridGap={3}
			>
				<LinearProgress
					style={{ width: "100%" }}
					variant="determinate"
					classes={{
						bar: classes.bar,
					}}
					value={(loaded / total) * 100}
				/>
				{showPercentage && (
					<Typography
						variant="body2"
						style={{ fontWeight: "bold" }}
						color="secondary"
					>{`${Math.round((loaded / total) * 100)}%`}</Typography>
				)}
			</Box>
		</Box>
	);
};

export default LinearBar;
