import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { AdditionalFilterItem } from "../../../store/Events/types";
import * as Lists from "../../Forms/RuleForm/Triggers/VehicleHuman/lists";
import _ from "lodash";
import { FilterItem } from "./MoreFiltersItem";
import { THEME } from "../../../config";
import { IconsObj } from ".././constants";
import { getDefaultSelectedWithValues } from "./MoreFiltersItem";
import { getReadableFilterText } from "../../../helpers/Filter";

const useStyles = makeStyles(() => ({
	itemRoot: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	faceAttributes: {
		width: "100%",
	},
	box: {
		marginBottom: 10,
		paddingRight: 8,
		width: "100%",
		backgroundColor: THEME.palette.background.default,
		"& > *:not(:last-child)": {
			borderBottom: `1px solid ${THEME.palette.divider}`,
		},
	},
}));

interface MoreFiltersProps {
	handleChange(filter: any): void;
	additionalFilters: { [key: string]: AdditionalFilterItem };
	ageComponent: JSX.Element;
}

export const FaceAttributes: React.FC<MoreFiltersProps> = ({
	handleChange,
	additionalFilters,
	ageComponent,
}) => {
	const classes = useStyles();

	const onCheckFilterWithValues = (item: string) => {
		if (additionalFilters[item]) {
			const newValue = _.omit(additionalFilters, item);
			handleChange(newValue);

			return;
		}
		handleChange({
			...additionalFilters,
			[item]: getDefaultSelectedWithValues(item),
		});
	};

	function getFilterItem(
		name: string,
		label: string,
		list?: string[],
		icons?: IconsObj,
		showSwitch: boolean = false,
		splitSlider: boolean = true,
	) {
		return (
			<Box className={classes.itemRoot}>
				<FilterItem
					selected={additionalFilters[name]}
					onChange={(newValue) => {
						if (newValue === null) {
							const newFilters = _.omit(additionalFilters, name);
							handleChange(newFilters);
						} else {
							handleChange({
								...additionalFilters,
								[name]: newValue,
							});
						}
					}}
					selectValues={list}
					name={name}
					label={getReadableFilterText(label, "")}
					icons={icons}
					splitSlider={splitSlider}
					switchEl={showSwitch}
					onSwitchChange={onCheckFilterWithValues}
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.box}>
			{ageComponent}
			{getFilterItem(
				"gender",
				"Gender",
				Lists.gender,
				undefined,
				true,
				false,
			)}
			{getFilterItem("mask", "Mask", undefined, undefined, true)}
			{getFilterItem("beard", "Beard", undefined, undefined, true)}
			{getFilterItem("mustache", "Mustache", undefined, undefined, true)}
			{getFilterItem("glasses", "Glasses", undefined, undefined, true)}
			{getFilterItem(
				"darkGlasses",
				"DarkGlasses",
				undefined,
				undefined,
				true,
			)}
		</Box>
	);
};
