import { makeStyles, Tooltip } from "@material-ui/core";
import { CheckBoxOutlined, Clear, Lock, Videocam } from "@material-ui/icons";
import React from "react";
import { THEME } from "../../../config";
import {
	camelCaseToReadable,
	getReadableFilterText,
} from "../../../helpers/Filter";
import svgToTsx from "../../../helpers/SvgToTsx";
import {
	AdditionalFilterItem,
	EventsFilter,
} from "../../../store/Events/types";

import { subjectTypeData } from "./FiltersContent";

const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: "6px",
		backgroundColor: THEME.palette.primary.dark,
		padding: "4px 6px 5px 6px",
		color: THEME.palette.grey[200],
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		maxWidth: 140,
		width: "fit-content",
		gap: 4,
		"& > svg": {
			fill: THEME.palette.grey[200],
			color: THEME.palette.grey[200],
		},
		lineHeight: "normal",
	},
	chipClearBox: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		"&:hover > svg": {
			fill: "#B60000",
		},
	},
	chipText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "100%",
		fontSize: "smaller",
	},
	centeredText: {
		display: "flex",
		alignItems: "center",
		gap: 4,
		width: "100%",
		"& > svg": {
			fill: THEME.palette.grey[200],
			color: THEME.palette.grey[200],
		},
	},
	icon: {
		width: 16,
		height: 16,
		fill: THEME.palette.grey[200],
		pointerEvents: "none",
	},
	iconPositioned: {
		width: 16,
		height: 16,
		marginRight: 4,
		position: "relative",
		top: 4,
		fill: THEME.palette.grey[200],
		pointerEvents: "none",
	},
	colorDiv: {
		width: 16,
		height: 16,
		borderRadius: "50%",
		marginRight: 2,
		border: "1px solid #000",
		display: "inline-block",
		position: "relative",
		top: 3,
	},
	iconText: {
		marginRight: 4,
		lineHeight: 1.43,
	},
	test: {
		"& > svg": {
			width: 20,
			height: 20,
			fill: THEME.palette.grey[200],
			color: THEME.palette.grey[200],
		},
		display: "flex",
		alignItems: "center",
	},
}));

type Props = {
	objKey: keyof EventsFilter | string;
	value: EventsFilter[keyof EventsFilter] | AdditionalFilterItem;
	onClear?: (objKey: string, value?: string) => void;
	chipStyle?: React.CSSProperties;
	disablePortal?: boolean;
};

const FilterChip = ({
	objKey,
	value,
	onClear,
	chipStyle,
	disablePortal,
}: Props) => {
	const classes = useStyles();

	const handleClear = () => {
		if (onClear) {
			if (
				objKey === "colors" ||
				objKey === "directions" ||
				objKey === "objects" ||
				objKey === "ageGroup"
			) {
				onClear(objKey, (value as AdditionalFilterItem)?.values[0]);
			} else {
				onClear(objKey);
			}
		}
	};

	return (
		<Tooltip
			title={<TooltipTitle objKey={objKey} value={value} />}
			PopperProps={{
				disablePortal: disablePortal,
			}}
			placement="bottom"
		>
			<div className={classes.chip} style={chipStyle}>
				<Logo objKey={objKey} value={value} />
				<span className={classes.chipText}>
					<Title objKey={objKey} value={value} />
				</span>
				{onClear && (
					<div className={classes.chipClearBox}>
						<Clear
							fontSize="small"
							style={{
								width: 16,
								height: 16,
							}}
							onClick={!!onClear && handleClear}
						/>
					</div>
				)}
			</div>
		</Tooltip>
	);
};

export default FilterChip;

const Title = ({ objKey, value }: Partial<Props>) => {
	const classes = useStyles();
	switch (objKey) {
		case "secret":
			const secret = value as EventsFilter["secret"];
			return <>{secret?.name}</>;
		case "source":
			const source = value as EventsFilter["source"];
			const sourcesName = source?.map((s) => s.displayName).join(", ");
			return <>{sourcesName}</>;
		case "subjectType":
			const subjectType = value as EventsFilter["subjectType"];
			return <>{getReadableFilterText(objKey, subjectType, true)}</>;
		case "fieldExist":
			return <>{getReadableFilterText(objKey, value, true)}</>;
		case "exactId":
			return <>{camelCaseToReadable(objKey)}</>;
		case "ageGroup":
			return <>{getReadableFilterText(objKey, value, true)}</>;
		case "colors":
			const colors = value as AdditionalFilterItem;
			return (
				<>
					{colors.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span className={classes.iconText} key={i}>
									<div
										className={classes.colorDiv}
										style={{ backgroundColor: v }}
									/>
									{v}
								</span>
							);
						})}
				</>
			);
		case "directions":
			const directions = value as AdditionalFilterItem;
			return (
				<>
					{directions.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span className={classes.iconText} key={i}>
									{svgToTsx(v, classes.iconPositioned, {
										width: 14,
										height: 14,
									})}
									{camelCaseToReadable(v)}
								</span>
							);
						})}
				</>
			);
		case "objects":
			const type = value as AdditionalFilterItem;
			return (
				<>
					{type.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span className={classes.iconText} key={i}>
									{svgToTsx(v, classes.iconPositioned)}
									{v}
								</span>
							);
						})}
				</>
			);
		default:
			return <>{getReadableFilterText(objKey ?? "", value)}</>;
	}
};

const Logo = ({ objKey, value }: Partial<Props>) => {
	const classes = useStyles();
	switch (objKey) {
		case "secret":
			return <Lock fontSize="small" className={classes.icon} />;
		case "source":
			return <Videocam fontSize="small" className={classes.icon} />;
		case "subjectType":
			const logo = subjectTypeData.find(
				(s) => s.value === (value as EventsFilter["subjectType"]),
			)?.logo;
			return logo ? <span className={classes.test}>{logo}</span> : null;
		case "exactId":
			return (
				<CheckBoxOutlined fontSize="small" className={classes.icon} />
			);
		default:
			return null;
	}
};

const TooltipTitle = ({ objKey, value }: Partial<Props>) => {
	const classes = useStyles();
	switch (objKey) {
		case "secret":
			const secret = value as EventsFilter["secret"];
			return (
				<>
					Secret: {secret?.name}
					<br />
					Description: {secret?.description}
				</>
			);
		case "source":
			const source = value as EventsFilter["source"];
			return (
				<>
					{source?.map((s) => (
						<span key={s.id} className={classes.centeredText}>
							Source: {s.displayName} (VMS - {s.vmsName})
						</span>
					))}
				</>
			);
		case "gender":
			const gender = value as AdditionalFilterItem;
			return (
				<>
					<span className={classes.centeredText}>
						{getReadableFilterText(objKey, gender)}
					</span>
					{`Confidence ${gender.less ? "<=" : ">="} ${
						gender.confidence
					}`}
				</>
			);
		case "colors":
			const colors = value as AdditionalFilterItem;
			return (
				<>
					Colors:
					{colors.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span
									className={classes.centeredText}
									style={{ paddingTop: 4 }}
									key={i}
								>
									<div
										className={classes.colorDiv}
										style={{ backgroundColor: v, top: 0 }}
									/>
									{`${v} Confidence ${
										colors.less ? "<=" : ">="
									} ${colors.confidence}`}
								</span>
							);
						})}
				</>
			);
		case "directions":
			const directions = value as AdditionalFilterItem;
			return (
				<>
					Directions:
					{directions.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span
									className={classes.centeredText}
									style={{ paddingTop: 4 }}
									key={i}
								>
									{svgToTsx(v, classes.icon, {
										width: 16,
										height: 16,
									})}
									{`${camelCaseToReadable(v)} Confidence ${
										directions.less ? "<=" : ">="
									} ${directions.confidence}`}
								</span>
							);
						})}
				</>
			);
		case "objects":
			const type = value as AdditionalFilterItem;
			return (
				<>
					Type:
					{type.values
						.filter((val) => val !== "Any")
						.map((v, i) => {
							return (
								<span
									className={classes.centeredText}
									style={{ paddingTop: 2 }}
									key={i}
								>
									{svgToTsx(v, classes.icon)}
									{`${v} Confidence ${
										type.less ? "<=" : ">="
									} ${type.confidence}`}
								</span>
							);
						})}
				</>
			);
		default:
			return <>{getReadableFilterText(objKey ?? "", value)}</>;
	}
};
