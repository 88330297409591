import { useEffect, useMemo, useState } from "react";
import {
	EventsFilter,
	IElasticHit,
	MatchProps,
} from "../../../store/Events/types";
import { FaceDetails } from "./FaceDetails";
import { LicensePlateDetails } from "./LicensePlateDetails";
import { VehicleHumanDetails } from "./VehicleHumanDetails";
import { Box, Typography, makeStyles, Collapse } from "@material-ui/core";
import MatchDetails from "./MatchDetails";
import { filterAttributes, filterDetails } from "../../../helpers/Filter";
import ClickableTypography, {
	CLICKABLE_TYPOGRAPHY_COLOR,
} from "../../UI/ClickableTypography";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { ArrowUpward } from "@material-ui/icons";
import { getItemAction } from "../../../store/Subjects/action";

type Props = {
	event: IElasticHit["_source"] | null;
	filter?: EventsFilter;
	loadMatch: (match: MatchProps) => void;
	updateEvent: (event: IElasticHit["_source"]) => void;
	fullCollapse?: boolean;
	disableRootStyle?: boolean;
	height?: number | string;
	style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
	root: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: "white",
		padding: 12,
		overflowY: "auto",
		flex: 1,
	},
	defaultRootStyles: {
		overflowY: "auto",
	},
	wrapper: {
		display: "flex",
		flexWrap: "wrap",
		gap: 8,
		height: "fit-content",
		width: "100%",
		marginTop: 12,
		padding: 4,
	},
	empty: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
	},
}));

const EventDetails = ({
	event,
	filter,
	loadMatch,
	updateEvent,
	fullCollapse,
	disableRootStyle,
	height,
	style,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(false);
	const [collapse, setCollapse] = useState(fullCollapse === true);
	const currentPage = event?.best?.currentPage ?? 0;

	const modifyAttributes = (attributes: any) => {
		if (!attributes) return;
		const newAttributes = { ...attributes };
		Object.keys(newAttributes).forEach((key) => {
			if (
				typeof newAttributes[key] === "object" &&
				!newAttributes[key].confidence &&
				!Object.keys(newAttributes[key]).some((v) =>
					/.+?(?=Confidence)/.test(v),
				)
			) {
				newAttributes[key].confidence = 0;
			}
			if (
				typeof newAttributes[key] === "object" &&
				(newAttributes[key].value === "Yes" ||
					newAttributes[key].value === "No")
			) {
				newAttributes[key].value =
					newAttributes[key].value === "Yes" ? 1 : 0;
			}
		});
		return newAttributes;
	};

	const getFaceData = () => {
		if (!event?.best?.faces?.[0]) return null;
		if (expanded) return event?.best?.faces[0];
		return {
			...event?.best?.faces[0],
			attributes: filterAttributes(
				modifyAttributes(event?.best?.faces?.[0]?.attributes),
				filter ?? {},
			),
		};
	};

	const getVehicleHumanData = () => {
		if (event?.best?.vehicleHuman?.ageGroupDetails) {
			delete event?.best?.vehicleHuman?.ageGroupDetails?.group;
			delete event?.best?.vehicleHuman?.ageGroupDetails?.groupConfidence;
		}
		if (!event?.best.vehicleHuman) return null;
		if (expanded) return event?.best?.vehicleHuman;
		return filterDetails(event?.best?.vehicleHuman, filter ?? {});
	};

	const matchProps: MatchProps | null = useMemo(
		() => event?.best?.matches?.[currentPage] ?? null,
		[currentPage, event],
	);
	const subjects = useSelector((state: AppState) => state.subjects);
	const match = useMemo(
		() =>
			matchProps
				? subjects.keys[matchProps?.watchlist]?.content.find(
						(s) => s.name === matchProps.id,
				  )
				: undefined,
		[matchProps, subjects],
	);

	const faceData = getFaceData();
	const vechicleHumanData = getVehicleHumanData();
	const licensePlateData = event?.best?.licensePlates?.[0] ?? null;
	const hasMatches = Boolean(event?.best.matches?.length > 0);

	useEffect(() => {
		if (
			!match &&
			event?.best.matches?.length > 0 &&
			matchProps?.watchlist &&
			matchProps?.id
		) {
			dispatch(getItemAction(matchProps?.watchlist, matchProps?.id));
		}
	}, [match, dispatch, event, matchProps]);

	if (!event && !fullCollapse)
		return (
			<div
				className={
					disableRootStyle ? classes.defaultRootStyles : classes.root
				}
				style={style}
			>
				<Box className={classes.empty}>
					<Typography>Select event to see details</Typography>
				</Box>
			</div>
		);

	return (
		<div
			className={
				disableRootStyle ? classes.defaultRootStyles : classes.root
			}
			style={style}
		>
			<Box
				display="flex"
				justifyContent={
					collapse || !event ? "flex-end" : "space-between"
				}
			>
				{!collapse && event && (
					<ClickableTypography onClick={() => setExpanded(!expanded)}>
						Show {expanded ? "less" : "more"} details
					</ClickableTypography>
				)}
				{fullCollapse && (
					<Box
						display="flex"
						alignItems="center"
						style={{ cursor: "pointer" }}
						gridGap={1}
						color={CLICKABLE_TYPOGRAPHY_COLOR}
						onClick={() => setCollapse(!collapse)}
					>
						<ClickableTypography>Details</ClickableTypography>
						<ArrowUpward
							fontSize="small"
							style={{
								rotate: collapse ? "0deg" : "180deg",
							}}
						/>
					</Box>
				)}
			</Box>
			<Collapse in={!collapse}>
				<div style={{ height: height ?? "100%" }}>
					{!event ? (
						<Box className={classes.empty}>
							<Typography>Select event to see details</Typography>
						</Box>
					) : (
						<div className={classes.wrapper}>
							{faceData && (
								<FaceDetails
									data={faceData}
									filter={filter ?? {}}
								/>
							)}
							{licensePlateData && (
								<LicensePlateDetails data={licensePlateData} />
							)}
							{vechicleHumanData && (
								<VehicleHumanDetails
									data={vechicleHumanData}
									filter={filter}
								/>
							)}
							{hasMatches && (
								<MatchDetails
									match={match}
									matchProps={matchProps}
									currentPage={currentPage}
									matchesCount={
										event?.best?.matches?.length ?? 0
									}
									event={event}
									handleMatchPageChange={(event, page) => {
										const nextMatch: MatchProps | null =
											event.best?.matches?.[page + 1] ??
											null;

										if (nextMatch) {
											const isNextLoaded = subjects.keys[
												nextMatch.watchlist
											]?.content.find(
												(s) => s.name === nextMatch.id,
											);

											if (!isNextLoaded)
												loadMatch(nextMatch);
										}
										updateEvent({
											...event,
											best: {
												...event.best,
												currentPage: page,
											},
										});
									}}
								/>
							)}
						</div>
					)}
				</div>
			</Collapse>
		</div>
	);
};

export default EventDetails;
