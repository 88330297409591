import { FC } from "react";
import { Box, makeStyles, Typography, Tooltip } from "@material-ui/core";
import { itemWidth } from "../LiveEvents/LiveEventsList";
import { IBackwardSearchMatchModel } from "../../store/BackwardSearch/types";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { IElasticHit } from "../../store/Events/types";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config";

export const SELECTED_HIT_BG_COLOR = "#BDD2CD";

const useStyles = makeStyles((theme) => ({
	hit: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		justifyContent: "flex-start",
		flexDirection: "column",
		borderRadius: "12px",
		maxWidth: itemWidth - 2,
		position: "relative",
		flex: 1,
		overflow: "hidden",
		height: "100%",
		cursor: "pointer",
	},
	hitStyles: {
		backgroundColor: "white",
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
	},
	selected: {
		backgroundColor: SELECTED_HIT_BG_COLOR,
	},

	faceImg: {
		height: "100%",
		width: "100%",
		maxHeight: "100%",
		maxWidth: "100%",
		objectFit: "contain",
	},
	centeredTextBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		width: "100%",
		"& > *": {
			fontSize: "0.9rem",
		},
	},
	score: {
		position: "absolute",
		bottom: 0,
		left: 0,
		backgroundColor: theme.palette.info.main,
		color: theme.palette.common.white,
		padding: "2px 4px",
		fontSize: "0.8rem",
		borderRadius: "2px",
	},
	matches: {
		display: "flex",
		overflow: "hidden",
		position: "relative",
		height: "100%",
		alignItems: "center",
	},
}));

const BackwardHit: FC<{
	hit: IBackwardSearchMatchModel | null;
	event: IElasticHit | undefined;
	isSelected: boolean;
	onClick: () => void;
	onDoubleClick: () => void;
	sourceName?: string;
}> = ({ hit, event, isSelected, onClick, onDoubleClick, sourceName }) => {
	const classes = useStyles();
	const eventSource = event?._source;
	const face = eventSource?.best?.faces?.[0];
	const image = face?.thumbnail?.value;

	if (!hit)
		return (
			<div
				className={`${classes.hit} ${isSelected && classes.selected}`}
			/>
		);

	return (
		<>
			<div
				className={clsx(
					classes.hit,
					isSelected && classes.selected,
					classes.hitStyles,
				)}
				onClick={onClick}
				onDoubleClick={onDoubleClick}
			>
				{eventSource && (
					<Typography>
						{eventSource["@timestamp"]
							? `${moment(eventSource["@timestamp"]).format(
									DATE_TIME_FORMAT,
							  )} `
							: null}
					</Typography>
				)}
				<Box
					className={classes.matches}
					style={{
						width: image ? "auto" : "100%",
					}}
				>
					{!isNaN(hit.score) && (
						<Tooltip title={`Matched with score: ${hit.score}`}>
							<Typography className={classes.score}>
								{`${hit.score}`}
							</Typography>
						</Tooltip>
					)}
					{image ? (
						<img
							className={classes.faceImg}
							src={`data:image/jpeg;base64,${image}`}
							alt="hit"
						/>
					) : (
						<Skeleton
							variant="rect"
							className={classes.faceImg}
							animation="pulse"
						/>
					)}
				</Box>
				{sourceName && (
					<Box
						className={classes.centeredTextBox}
						gridGap={4}
						pt={0.3}
					>
						<Typography color="textSecondary">Source:</Typography>
						<Typography noWrap>{sourceName}</Typography>
					</Box>
				)}
			</div>
		</>
	);
};

export default BackwardHit;
