import { connect } from "react-redux";
import { AppState } from "../../store";
import {
	getSearchResultIndexAction,
	getSearchResultsAction,
} from "../../store/ImageSearch/action";
import { getSecretAction } from "../../store/Secrets/action";
import { getItemAction } from "../../store/Subjects/action";

import { IRequest } from "../../store/types";
import { getWatchlistAction } from "../../store/Watchlists/action";

import SearchResultView, {
	SearchResultViewDispatchProps,
	SearchResultViewStateProps,
} from "./SearchResultView";
import { getRecordsAction } from "../../store/Records/action";
import { IRecordsRequest } from "../../store/Records/types";

const mapStateToProps = (state: AppState): SearchResultViewStateProps => {
	return {
		data: state.imageSearches.results,
		resultsByIndex: state.imageSearches.resultsByIndex,
		secrets: state.secrets,
		watchlists: state.watchlists,
		subjects: state.subjects,
		recordsState: state.records,
	};
};

const mapDispatchToProps = (dispatch: any): SearchResultViewDispatchProps => {
	return {
		getSearchResults: (
			id: string,
			query?: Pick<IRequest, "page" | "size">,
		) => getSearchResultsAction(id, query)(dispatch),
		getSearchResultIndex: (
			id: string,
			index: number,
			query?: IRequest,
			controller?: AbortController,
			dontSave?: boolean,
		) =>
			getSearchResultIndexAction(
				id,
				index,
				query,
				controller,
				dontSave,
			)(dispatch),
		getSecretAction: (id: string) => getSecretAction(id)(dispatch),
		getWatchlistAction: (id: string, secret: string) =>
			getWatchlistAction(id, secret)(dispatch),
		getSubjectAction: (
			watchlistId: string,
			name: string,
			secret?: string,
			controller?: AbortController,
		) => getItemAction(watchlistId, name, secret, controller)(dispatch),
		getRecordsAction: (
			watchlistId: string,
			subjectId: string,
			request: IRecordsRequest = {},
			controller?: AbortController,
		) =>
			getRecordsAction(
				watchlistId,
				subjectId,
				request,
				controller,
			)(dispatch),
	};
};

const SearchResultContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SearchResultView);
export default SearchResultContainer;
