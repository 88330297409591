import React from "react";
import { Typography, Tooltip, Box, makeStyles } from "@material-ui/core";
import { THEME } from "../../config";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	text: {
		fontSize: "1rem",
		[THEME.breakpoints.down("md")]: {
			fontSize: "0.8rem",
		},
	},
}));

interface IWatchlistFieldProps {
	property: string;
	field: string;
	description?: string;
	className?: string;
}

const WatchlistField: React.FC<IWatchlistFieldProps> = ({
	property,
	field,
	description,
	className,
}) => {
	const classes = useStyles();
	return (
		<Box display="flex">
			<Box style={{ flex: 1 }} display="flex" justifyContent="flex-end">
				<Typography
					className={clsx(classes.text, className)}
					style={{ padding: "1px 3px" }}
				>
					{property}:
				</Typography>
			</Box>
			<Tooltip title={description ? description : ""}>
				<Box style={{ flex: 1 }} display="flex">
					<Typography
						className={clsx(classes.text, className)}
						style={{ padding: "1px 3px" }}
					>
						{field}
					</Typography>
				</Box>
			</Tooltip>
		</Box>
	);
};

export default WatchlistField;
