import React from "react";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import IPassword from "./IPassword";
import { TextFieldProps } from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface IPasswordFieldProps {
	validatefield?: boolean;
	disableAutoComplete?: boolean;
	onPasswordChange?(password: IPassword): void;
	variant?: string;
	showButton?: boolean;
}

interface IPasswordFieldState {
	password: IPassword;
	showPassword: boolean;
}

class PasswordField extends React.Component<
	IPasswordFieldProps & TextFieldProps,
	IPasswordFieldState
> {
	constructor(props: IPasswordFieldProps & TextFieldProps) {
		super(props);

		this.state = {
			password: {
				value: "",
				valid: true,
			},
			showPassword: false,
		};
	}

	/**
	 * Hack to make 'props.variant' type safe
	 *
	 * See: https://github.com/mui-org/material-ui/issues/15697
	 */
	tsProps = (() => {
		let tsVariant;
		switch (this.props.variant) {
			case "outlined": {
				tsVariant = { variant: "outlined" as "outlined" };
				break;
			}
			case "filled": {
				tsVariant = { variant: "filled" as "filled" };
				break;
			}
			case "standard":
			default: {
				tsVariant = { variant: "standard" as "standard" };
				break;
			}
		}
		const p = this.props;
		const transformedProps = { ...p, ...tsVariant };
		delete (transformedProps as IPasswordFieldProps).onPasswordChange;
		delete (transformedProps as IPasswordFieldProps).disableAutoComplete;
		return transformedProps;
	})();

	handleInputChange = (
		event: React.ChangeEvent<{
			name?: string;
			value: unknown;
		}>,
	) => {
		switch (event.target.name) {
			case (this.props as TextFieldProps).name: {
				const value = event.target.value as string;
				let valid = true;
				if ((this.props as IPasswordFieldProps).validatefield) {
					valid = false;
				}
				const password = {
					...this.state.password,
					value,
					valid,
				};
				this.setState({ password });
				(this.props as IPasswordFieldProps).onPasswordChange!(password);
				break;
			}
		}
	};

	handleShowPassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	render() {
		return (
			<TextField
				autoComplete={
					(this.props as IPasswordFieldProps).disableAutoComplete
						? "new-password"
						: "password"
				}
				type={!this.state.showPassword ? "password" : "text"}
				fullWidth
				value={this.state.password.value}
				onChange={this.handleInputChange}
				InputProps={{
					endAdornment: this.props.showButton ? (
						<InputAdornment position="end">
							<IconButton
								tabIndex={-1}
								aria-label="toggle password visibility"
								onClick={this.handleShowPassword}
								onMouseDown={this.handleMouseDownPassword}
							>
								{this.state.showPassword ? (
									<Visibility />
								) : (
									<VisibilityOff />
								)}
							</IconButton>
						</InputAdornment>
					) : null,
				}}
				{...this.tsProps}
			/>
		);
	}
}

export default PasswordField;
