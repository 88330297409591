import { useEffect, useRef, useState } from "react";
import Authentication from "../store/Authentication/AuthenticationStore";
import { useDispatch, useSelector } from "react-redux";
import {
	addLiveEventsAction,
	setMqttAsConfiguredAction,
	setMqttConfigurationAsCheckedAction,
} from "../store/LiveCameras/action";
import { getSecretsAction } from "../store/Secrets/action";
import { AppState } from "../store";
import { getRulesAction } from "../store/Rules/action";
import { getVMSAction } from "../store/VMS/action";
import { mqttClient } from "../services/mqtt";

enum FetchedStatus {
	NOT_FETCHED,
	FETCHING,
	FETCHED,
}

const useMQTT = () => {
	const dispatch = useDispatch();
	const [connectedFirstTime, setConnectedFirstTime] = useState(false);
	const [isMqttConnected, setIsMqttConnected] = useState(false);
	const secrets = useSelector(
		(state: AppState) => state.secrets.data.content,
	);
	const vmsState = useSelector((state: AppState) => state.vms);
	const rulesState = useSelector((state: AppState) => state.rules);
	const isMqttConfigured = useSelector(
		(state: AppState) => state.live.isMqttConfigured,
	);
	const eventsViews = useSelector(
		(state: AppState) => state.userConfig.data.eventsViews,
	);
	const selectedLayoutId = useSelector(
		(state: AppState) => state.userConfig.data.live?.selectedLayoutId,
	);

	const secretsFetched = useRef(FetchedStatus.NOT_FETCHED);
	const rulesFetched = useRef(FetchedStatus.NOT_FETCHED);
	const vmsFetched = useRef(FetchedStatus.NOT_FETCHED);

	useEffect(() => {
		const token = Authentication.getAccessToken();
		const userName = Authentication.getUserName();
		if (!token || !userName) return;

		mqttClient.initMqtt(token, userName);
		mqttClient.setOnMessageArrived((events) =>
			dispatch(addLiveEventsAction(events, eventsViews)),
		);
		mqttClient.subscribeToConnectionStatus(({ isConnected }) => {
			if (!connectedFirstTime) setConnectedFirstTime(true);

			if (isConnected === null) return;
			if (isMqttConnected != isConnected) setIsMqttConnected(isConnected);
		});
	}, [dispatch, connectedFirstTime, isMqttConnected, eventsViews]);

	useEffect(() => {
		if (!Authentication.isAuthenticated()) return;

		if (
			secrets.length === 0 &&
			secretsFetched.current === FetchedStatus.NOT_FETCHED
		) {
			secretsFetched.current = FetchedStatus.FETCHED;
			dispatch(getSecretsAction({}));
		}
	}, [dispatch, secrets, connectedFirstTime]);

	useEffect(() => {
		if (isMqttConfigured || !Authentication.isAuthenticated()) return;

		const rules = rulesState.data.content;
		const vmsList = vmsState.data.content;
		if (vmsFetched.current === FetchedStatus.NOT_FETCHED) {
			vmsFetched.current = FetchedStatus.FETCHING;
			getVMSAction({ size: 500 })(dispatch).then(() => {
				vmsFetched.current = FetchedStatus.FETCHED;
			});
		}

		if (
			rulesFetched.current === FetchedStatus.NOT_FETCHED &&
			vmsFetched.current === FetchedStatus.FETCHED
		) {
			rulesFetched.current = FetchedStatus.FETCHING;
			Promise.all(
				vmsList.map((vms) =>
					getRulesAction(vms.name, { size: 500 })(dispatch),
				),
			).finally(() => (rulesFetched.current = FetchedStatus.FETCHED));
		}

		const ruleWithMqttAction = rules.find((rule) =>
			rule.actions.find((action) => action.type === "MqttAction"),
		);

		if (ruleWithMqttAction) dispatch(setMqttAsConfiguredAction());
		else if (
			rulesFetched.current === FetchedStatus.FETCHED &&
			vmsFetched.current === FetchedStatus.FETCHED
		)
			dispatch(setMqttConfigurationAsCheckedAction());
	}, [isMqttConfigured, vmsState, rulesState, dispatch]);

	useEffect(() => {
		if (
			!connectedFirstTime ||
			!Authentication.isAuthenticated() ||
			!isMqttConnected ||
			!selectedLayoutId
		)
			return;

		const subscribeTopicList: string[] = [];

		Object.values(
			eventsViews.filter((v) => v.layoutId === selectedLayoutId),
		).forEach((view) => {
			if (view.enabledAll) {
				rulesState.data.content.forEach((rule) =>
					subscribeTopicList.push(
						`${rule.secret ?? "public"}/events/${rule.vms}/${
							rule.name
						}/#`,
					),
				);
			} else
				view.enabledRules?.forEach((ruleString) => {
					const [vms, name] = ruleString.split("/");
					const rule = rulesState.data.content.find(
						(r) => r.vms === vms && r.name === name,
					);
					if (!rule || !view.enabledVms?.includes(vms)) return;

					view.enabledSources?.forEach((sourceString) => {
						const [sourceVms, sourceId] = sourceString.split("/");
						if (vms !== sourceVms) return;
						const topic = `${rule.secret ?? "public"}/events/${
							rule.vms
						}/${rule.name}/${sourceId}`;
						subscribeTopicList.push(topic);
					});
				});
		});

		const filteredSubscribeTopicList = subscribeTopicList.filter(
			(value, index, self) => self.indexOf(value) === index,
		);

		filteredSubscribeTopicList.forEach((topic) => {
			if (mqttClient.subscribeList.includes(topic)) return;
			mqttClient.subscribeTopic(topic);
		});

		mqttClient.subscribeList.forEach((topic) => {
			if (!filteredSubscribeTopicList.includes(topic))
				mqttClient.unsubscribeTopic(topic);
		});
	}, [
		rulesState,
		connectedFirstTime,
		isMqttConnected,
		eventsViews,
		selectedLayoutId,
		dispatch,
	]);

	useEffect(() => {
		const onNetworkStateChange = () => {
			if (navigator.onLine) {
				const token = Authentication.getAccessToken();
				const userName = Authentication.getUserName();
				if (!token || !userName) return;
				mqttClient.initMqtt(token, userName);
			}
		};

		window.addEventListener("online", onNetworkStateChange);

		return () => {
			if (mqttClient.isConnectedToMqtt()) mqttClient.disconnect();
			window.removeEventListener("online", onNetworkStateChange);
		};
	}, []);
};

export default useMQTT;
