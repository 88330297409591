import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { actions } from "../../../store/Tasks/types";
import {
	Typography,
	Divider,
	InputAdornment,
	Popover,
	makeStyles,
	TextField,
	MenuItem,
	Grid,
	IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
	groupName: {
		fontWeight: "bold",
		color: "black",
		paddingLeft: 16,
	},
	popover: {
		paddingTop: 5,
		width: 600,
	},
}));

interface IActionSelectProps {
	onSelect(action: string): void;
	value?: string;
}

const ActionSelect: React.FC<IActionSelectProps> = ({ onSelect, value }) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	let popover = true;

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		if (popover) {
			setAnchorEl(event.currentTarget);
		} else {
			popover = true;
		}
	};

	const handlePopoverClose = () => setAnchorEl(null);

	const handleActionSelect = (action: string) => {
		handlePopoverClose();
		onSelect(action);
	};

	const handleRemove = () => {
		popover = false;
		onSelect("");
	};

	return (
		<>
			<TextField
				fullWidth
				value={value || ""}
				style={{ marginTop: "15px" }}
				InputProps={{
					endAdornment: (
						<InputAdornment
							position="end"
							style={{ color: "gray" }}
						>
							<>
								{value && value !== "" ? (
									<IconButton onClick={() => handleRemove()}>
										<CloseIcon />
									</IconButton>
								) : null}
								{Boolean(anchorEl) ? (
									<ArrowDropUpIcon />
								) : (
									<ArrowDropDownIcon />
								)}
							</>
						</InputAdornment>
					),
				}}
				onClick={handleOpenPopover}
			/>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "left" }}
			>
				<Grid container className={classes.popover}>
					{actions.map((group) => (
						<Grid item key={group.name} xs={4}>
							<Typography className={classes.groupName}>
								{group.name}
							</Typography>
							<Divider variant="middle" />
							{group.items.map((item) => (
								<MenuItem
									key={item.name}
									onClick={() =>
										handleActionSelect(item.name)
									}
								>
									{item.name}
								</MenuItem>
							))}
						</Grid>
					))}
				</Grid>
			</Popover>
		</>
	);
};

export default ActionSelect;
