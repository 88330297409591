import { Box, BoxProps, makeStyles, Typography } from "@material-ui/core";
import { THEME } from "../../../config";

const useStyles = makeStyles(() => ({
	detailsBox: {
		display: "flex",
		gap: 4,
		flex: 1,
	},

	dividerRight: {
		borderRight: `1px solid ${THEME.palette.primary.main}`,
		paddingRight: 4,
	},
	boldText: {
		fontWeight: "bold",
	},
}));

const DetailItem = ({
	title,
	value,
	justifyContent,
	divider,
	noFlex,
	wrap,
	rootStyle,
}: {
	title?: string;
	value: string;
	justifyContent?: "center" | "flex-start" | "flex-end";
	divider?: boolean;
	noFlex?: boolean;
	wrap?: boolean;
	rootStyle?: BoxProps["style"];
}) => {
	const classes = useStyles();
	return (
		<Box
			className={`${classes.detailsBox} ${
				divider ? classes.dividerRight : ""
			}`}
			style={{
				justifyContent,
				flex: noFlex ? 0 : 1,
				...rootStyle,
			}}
		>
			{title && (
				<Typography noWrap={!wrap} className={classes.boldText}>
					{title}
				</Typography>
			)}
			<Typography noWrap={!wrap}>{value}</Typography>
		</Box>
	);
};

export default DetailItem;
