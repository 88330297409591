import { makeStyles } from "@material-ui/core";
import { isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { THEME } from "../../../config";
import { AppState } from "../../../store";
import { addToSequences } from "../../../store/Events/action";
import { ISequence } from "../../../store/Events/types";
import { TimelineScale } from "../../VideoPlayers/EventsStream";
import { IControls } from "./StreamControls";

const useStyles = makeStyles(() => ({
	root: {
		borderBottomLeftRadius: "4px",
		borderBottomRightRadius: "4px",
		height: "40px",
		minHeight: "40px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		userSelect: "none",
		overflow: "hidden",
		backgroundColor: THEME.palette.grey[300],
		"&::after": {
			content: "''",
			position: "absolute",
			left: "50%",
			top: 0,
			bottom: 0,
			width: "2px",
			backgroundColor: "green",
			boxShadow: "0 0 8px 0.5px green",
		},
	},
	steps: {
		position: "absolute",
		height: "100%",
		display: "flex",
		justifyContent: "space-between",
		bottom: 0,
		width: `${defaultWidth}px`,
	},
	item: {
		position: "relative",
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-end",
	},
	indicator: {
		backgroundColor: THEME.palette.secondary.light,
		height: "20%",
		width: "1px",
		position: "absolute",
		bottom: 0,
	},
}));

type TimelineProps = {
	videoController:
		| {
				[key: string]: any;
		  }
		| undefined;
	currentTime: number;
	isPlaying: IControls["isPlaying"];
	playSpeed: IControls["playSpeed"];
	timelineScale: number;
	setTimelineScale: (scale: number) => void;
	setCurrentTime: (time: moment.Moment) => void;
	hidden?: boolean;
	timestamp: number;
	isSequencesLoading: boolean;
	setLoadingSeq: (isLoading: boolean) => void;
};

const defaultWidth = 6000;
const stepsLength = 61;

const Timeline: React.FC<TimelineProps> = ({
	videoController,
	currentTime,
	isPlaying,
	playSpeed,
	timestamp,
	timelineScale,
	setTimelineScale,
	setCurrentTime,
	hidden,
	setLoadingSeq,
	isSequencesLoading,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [sequencesFirstLoaded, setSequencesFirstLoaded] = useState(false);
	const events = useSelector((state: AppState) => state.events);
	const [fetchRetries, setFetchRetries] = useState(0);
	const [dragging, setDragging] = useState(false);
	const draggedRight = useRef(0);
	const draggedLeft = useRef(0);
	const [time, setTime] = useState(timestamp);
	const [seekingTime, setSeekingTime] = useState(false);
	const scaleMultiplier = 10;
	const parentRef = useRef<HTMLDivElement>(null);
	const timelineRef = useRef<HTMLDivElement>(null);
	const sequencesRef = useRef<HTMLDivElement>(null);
	const seekDebounceRef = useRef<NodeJS.Timeout | null>(null);

	const steps = useMemo(() => {
		return Array.from({ length: stepsLength }, (_, i) => {
			const stepTime = moment(time).add(
				(i * scaleMultiplier -
					(stepsLength / 2 - 0.5) * scaleMultiplier) *
					timelineScale,
				"s",
			);

			return {
				time: stepTime,
				label: stepTime.format(
					timelineScale === TimelineScale["10s"]
						? "HH:mm:ss"
						: "HH:mm:ss",
				),
			};
		});
	}, [time, timelineScale, scaleMultiplier]);

	const sequences = useMemo(() => {
		if (!videoController) return [];
		const firstStepTime = moment(time).subtract(
			(stepsLength / 2) * scaleMultiplier * timelineScale,
			"seconds",
		);
		const lastStepTime = moment(time).add(
			(stepsLength / 2) * scaleMultiplier * timelineScale,
			"seconds",
		);
		return (
			events.cameras[
				videoController?.request?.parameters?.CameraId
			]?.sequences
				.filter(
					(seq) =>
						moment(seq.StartTime).isBetween(
							firstStepTime,
							lastStepTime,
							"s",
							"[]",
						) ||
						moment(seq.EndTime).isBetween(
							firstStepTime,
							lastStepTime,
							"s",
							"[]",
						) ||
						(moment(seq.StartTime).isBefore(firstStepTime) &&
							moment(seq.EndTime).isAfter(lastStepTime)),
				)
				.map((seq) => {
					const stepWidth = defaultWidth / stepsLength;
					const firstStepTime = moment(steps[0].time).subtract(
						5 * timelineScale,
						"seconds",
					);
					const lastStepTime = moment(
						steps[steps.length - 1].time,
					).add(5 * timelineScale, "seconds");
					const end = moment(seq.EndTime).isAfter(lastStepTime)
						? lastStepTime
						: moment(seq.EndTime);
					const start = moment(seq.StartTime).isBefore(firstStepTime)
						? firstStepTime
						: moment(seq.StartTime);

					const left =
						((start.diff(firstStepTime, "ms") * scaleMultiplier) /
							1000 /
							timelineScale) *
						(stepWidth / 100);

					const width =
						(Math.abs(
							(end.diff(start, "ms") * scaleMultiplier) / 1000,
						) /
							timelineScale) *
						(stepWidth / 100);

					return {
						...seq,
						width,
						left,
					};
				})
				.filter((seq, index, self) => {
					return (
						index ===
						self.findIndex(
							(s) =>
								s.StartTime === seq.StartTime &&
								s.EndTime === seq.EndTime,
						)
					);
				}) ?? []
		);
	}, [events, videoController, steps, timelineScale, time]);

	const onDraggedChange = useCallback(() => {
		const dragTransform = `translateX(${
			draggedLeft.current > draggedRight.current
				? draggedLeft.current
				: -draggedRight.current
		}px)`;
		if (timelineRef.current)
			timelineRef.current.style.transform = dragTransform;
		if (sequencesRef.current)
			sequencesRef.current.style.transform = dragTransform;
	}, []);

	const handleDragStart = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
		setDragging(true);
		if (isPlaying) XPMobileSDK.playbackSpeed(videoController, 0);
	};

	const handleDragEnd = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			if (parentRef.current && timelineRef.current && dragging) {
				setSeekingTime(true);
				const stepWidth = defaultWidth / stepsLength;
				const dragged =
					draggedLeft.current > draggedRight.current
						? -draggedLeft.current
						: draggedRight.current;
				const draggedTime =
					(dragged / stepWidth) * scaleMultiplier * timelineScale;
				const stepTime = moment(time).add(draggedTime, "seconds");
				if (seekDebounceRef.current) {
					clearTimeout(seekDebounceRef.current);
					seekDebounceRef.current = null;
				}
				setCurrentTime(stepTime);
				XPMobileSDK.playbackGoTo(
					videoController,
					moment(stepTime).valueOf(),
					playSpeed > 0 ? "TimeOrAfter" : "TimeOrBefore",
					() => {
						setTimeout(() => setSeekingTime(false), 100);
						if (isPlaying)
							XPMobileSDK.playbackSpeed(
								videoController,
								playSpeed,
							);
					},
					() => {
						setTimeout(() => setSeekingTime(false), 100);
						if (isPlaying)
							XPMobileSDK.playbackSpeed(
								videoController,
								playSpeed,
							);
					},
				);
			}
			setDragging(false);
			onDraggedChange();
		},
		[
			draggedLeft,
			draggedRight,
			dragging,
			time,
			timelineScale,
			playSpeed,
			videoController,
			setCurrentTime,
			onDraggedChange,
			isPlaying,
		],
	);

	const handleDrag = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			if (dragging && parentRef.current && timelineRef.current) {
				const DRAGSPEED = Math.abs(event.movementX / 2);
				if (
					event.movementX > 0 &&
					draggedLeft.current < defaultWidth / 2
				) {
					draggedLeft.current = draggedLeft.current + DRAGSPEED;
					draggedRight.current = draggedRight.current - DRAGSPEED;
				} else if (
					event.movementX < 0 &&
					draggedRight.current < defaultWidth / 2
				) {
					draggedRight.current = draggedRight.current + DRAGSPEED;
					draggedLeft.current = draggedLeft.current - DRAGSPEED;
				}
				const stepWidth = defaultWidth / stepsLength;
				const dragged =
					draggedLeft.current > draggedRight.current
						? -draggedLeft.current
						: draggedRight.current;
				const draggedTime =
					(dragged / stepWidth) * scaleMultiplier * timelineScale;
				const stepTime = moment(time).add(draggedTime, "seconds");

				if (!seekDebounceRef.current) {
					seekDebounceRef.current = setTimeout(() => {
						XPMobileSDK.playbackGoTo(
							videoController,
							moment(stepTime).valueOf(),
							playSpeed > 0 ? "TimeOrAfter" : "TimeOrBefore",
							() => {
								if (seekDebounceRef.current)
									clearTimeout(seekDebounceRef.current);
								seekDebounceRef.current = null;
							},
							() => {
								if (seekDebounceRef.current)
									clearTimeout(seekDebounceRef.current);
								seekDebounceRef.current = null;
							},
						);
					}, 500);
				}
				onDraggedChange();
			}
		},
		[
			draggedLeft,
			draggedRight,
			dragging,
			time,
			timelineScale,
			playSpeed,
			videoController,
			onDraggedChange,
		],
	);

	const getSequences = useCallback(() => {
		if (isSequencesLoading) return;
		setLoadingSeq(true);
		setSequencesFirstLoaded(true);
		XPMobileSDK.createInvestigation(
			{
				StartTime: moment(time)
					.subtract(
						(stepsLength / 2) * scaleMultiplier * timelineScale,
						"seconds",
					)
					.valueOf(),
				EndTime: moment(time)
					.add(
						(stepsLength / 2) * scaleMultiplier * timelineScale,
						"seconds",
					)
					.valueOf(),
				Name: "Investigation",
				CameraId: videoController?.request?.parameters?.CameraId,
			},
			(investigation: any) => {
				if (!investigation || !investigation?.ItemId) {
					setFetchRetries((prev) => prev + 1);
					if (fetchRetries < 3) return getSequences();
					else return setLoadingSeq(false);
				}
				XPMobileSDK.getSequencesInInterval(
					videoController?.request.parameters.CameraId,
					moment(time)
						.subtract(
							(stepsLength / 2) * scaleMultiplier * timelineScale,
							"seconds",
						)
						.valueOf(),
					moment(time)
						.add(
							(stepsLength / 2) * scaleMultiplier * timelineScale,
							"seconds",
						)
						.valueOf(),
					investigation.ItemId,
					(res: any) => {
						if (investigation.ItemId)
							XPMobileSDK.deleteInvestigation(
								investigation.ItemId,
							);
						if (
							res &&
							videoController?.request?.parameters?.CameraId
						) {
							const newSequences = res.filter(
								(seq: ISequence) =>
									!events.cameras[
										videoController?.request?.parameters
											?.CameraId
									]?.sequences.find((s: ISequence) =>
										isEqual(s, seq),
									),
							);
							dispatch(
								addToSequences(
									newSequences,
									videoController.request.parameters.CameraId,
								),
							);
							setLoadingSeq(false);
						} else setLoadingSeq(false);
					},
					() => {
						if (investigation.ItemId)
							XPMobileSDK.deleteInvestigation(
								investigation.ItemId,
							);
					},
				);
			},
		);
	}, [
		videoController,
		time,
		scaleMultiplier,
		timelineScale,
		dispatch,
		events,
		fetchRetries,
		setLoadingSeq,
		isSequencesLoading,
	]);

	const reset = useCallback(
		(time: number) => {
			setTime(time);
			draggedLeft.current = 0;
			draggedRight.current = 0;
			setSequencesFirstLoaded(false);
			onDraggedChange();
		},
		[onDraggedChange],
	);

	useEffect(() => {
		reset(currentTime);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timelineScale, reset]);

	useEffect(() => {
		if (!sequencesFirstLoaded) getSequences();
	}, [getSequences, sequencesFirstLoaded]);

	useEffect(() => {
		if (timelineRef.current && !dragging && isPlaying && !seekingTime) {
			if (
				!sequences.find(
					(seq) =>
						moment(currentTime).isBetween(
							seq.StartTime,
							seq.EndTime,
							"s",
							"[]",
						) ||
						moment(currentTime).isSame(seq.StartTime) ||
						moment(currentTime).isSame(seq.EndTime),
				)
			) {
				getSequences();
			}

			const stepWidth = defaultWidth / stepsLength;
			const dragged =
				draggedLeft.current > draggedRight.current
					? -draggedLeft.current
					: draggedRight.current;

			if (Math.abs(dragged) > defaultWidth / 2 - 500)
				return reset(currentTime);

			const draggedTime =
				(dragged / stepWidth) * scaleMultiplier * timelineScale;
			const stepTime = moment(time).add(draggedTime, "s");
			const difference =
				Math.abs(stepTime.diff(moment(currentTime), "ms")) / 1000;
			if (stepTime.isBefore(moment(currentTime))) {
				draggedRight.current =
					draggedRight.current +
					(difference * stepWidth) / scaleMultiplier / timelineScale;

				draggedLeft.current =
					draggedLeft.current -
					(difference * stepWidth) / scaleMultiplier / timelineScale;
			} else if (stepTime.isAfter(moment(currentTime))) {
				draggedLeft.current =
					draggedLeft.current +
					(difference * stepWidth) / scaleMultiplier / timelineScale;

				draggedRight.current =
					draggedRight.current -
					(difference * stepWidth) / scaleMultiplier / timelineScale;
			}
		}
		onDraggedChange();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTime, onDraggedChange]);

	useEffect(() => {
		reset(timestamp);
	}, [timestamp, reset]);

	const handleZoomIn = () => {
		if (timelineScale === TimelineScale["10s"]) {
			setTimelineScale(TimelineScale["1m"]);
		} else if (timelineScale === TimelineScale["1m"]) {
			setTimelineScale(TimelineScale["10m"]);
		} else if (timelineScale === TimelineScale["10m"]) {
			setTimelineScale(TimelineScale["30m"]);
		} else if (timelineScale === TimelineScale["30m"]) {
			setTimelineScale(TimelineScale["1h"]);
		}
	};

	const handleZoomOut = () => {
		if (timelineScale === TimelineScale["1h"]) {
			setTimelineScale(TimelineScale["30m"]);
		} else if (timelineScale === TimelineScale["30m"]) {
			setTimelineScale(TimelineScale["10m"]);
		} else if (timelineScale === TimelineScale["10m"]) {
			setTimelineScale(TimelineScale["1m"]);
		} else if (timelineScale === TimelineScale["1m"]) {
			setTimelineScale(TimelineScale["10s"]);
		}
	};

	useEffect(() => {
		if (dragging) {
			window.addEventListener("mousemove", handleDrag);
			window.addEventListener("mouseup", handleDragEnd);
			document.body.style.cursor = "grabbing";
		}

		return () => {
			window.removeEventListener("mousemove", handleDrag);
			window.removeEventListener("mouseup", handleDragEnd);
			document.body.style.cursor = "default";
		};
	}, [dragging, handleDrag, handleDragEnd]);

	return (
		<div
			className={classes.root}
			onClickCapture={(e) => e.stopPropagation()}
			onMouseDown={handleDragStart}
			onWheel={(event: React.WheelEvent<HTMLDivElement>) => {
				if (event.deltaY > 0) {
					handleZoomIn();
				} else {
					handleZoomOut();
				}
			}}
			ref={parentRef}
			style={{
				cursor: dragging ? "grabbing" : "grab",
				visibility: hidden ? "hidden" : "visible",
			}}
		>
			<div
				ref={sequencesRef}
				style={{
					height: "100%",
					width: defaultWidth,
					position: "absolute",
					display: "flex",
					justifyContent: "space-between",
					bottom: 0,
					transform: `translateX(${
						draggedLeft.current > draggedRight.current
							? draggedLeft.current
							: -draggedRight.current
					}px)`,
				}}
			>
				<div
					style={{
						width: "100%",
						height: "100%",
						position: "relative",
					}}
				>
					{sequences.map((seq, i) => {
						return (
							<div
								key={i}
								style={{
									position: "absolute",
									top: 0,
									bottom: 0,
									left: `${Math.abs(seq.left)}px`,
									width: `${seq.width}px`,
									height: "100%",
									backgroundColor: "#79cd88",
								}}
							/>
						);
					})}
				</div>
			</div>
			<div
				className={classes.steps}
				ref={timelineRef}
				style={{
					transform: `translateX(${
						draggedLeft.current > draggedRight.current
							? draggedLeft.current
							: -draggedRight.current
					}px)`,
				}}
			>
				{steps.map((step, i) => {
					return (
						<div key={i} className={classes.item}>
							<span
								style={{
									position: "absolute",
									top: 0,
									fontWeight: 500,
								}}
							>
								{step.label}
							</span>
							<div
								className={classes.indicator}
								style={{
									left: "10%",
								}}
							/>
							<div
								className={classes.indicator}
								style={{
									left: "30%",
								}}
							/>
							<div
								style={{
									backgroundColor: THEME.palette.primary.main,
									height: "40%",
									width: "2px",
								}}
							/>
							<div
								className={classes.indicator}
								style={{
									left: "70%",
								}}
							/>
							<div
								className={classes.indicator}
								style={{
									left: "90%",
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Timeline;
