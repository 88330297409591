import React from "react";
import { makeStyles } from "@material-ui/core";
import { THEME } from "../../config";
import { ExpandLessRounded, DragIndicator } from "@material-ui/icons";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	label: string;
	open: boolean;
}

const useStyles = makeStyles(() => ({
	button: {
		marginTop: 10,
		backgroundColor: THEME.palette.primary.main,
		color: "white",
		border: "none",
		padding: "4px 8px 4px 0px",
		borderRadius: "4px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		fontSize: "0.9rem",
	},
	icon: {
		transition: "all 0.2s linear",
	},
	iconClosed: {
		transform: "rotateX(180deg)",
	},
	iconOpen: {
		transition: "all 0.2s linear",
		transform: "rotateX(0deg)",
	},
	label: {
		fontWeight: "bold",
	},
	dragLabelGroup: {
		display: "flex",
		alignItems: "center",
	},
}));

const DropdownButton = ({ label, open, ...props }: Props) => {
	const classes = useStyles();
	return (
		<div
			className={classes.button}
			{...props}
			style={{
				backgroundColor: open ? THEME.palette.secondary.main : "",
				borderBottomLeftRadius: open ? "unset" : "",
				borderBottomRightRadius: open ? "unset" : "",
			}}
		>
			<div className={classes.dragLabelGroup}>
				<DragIndicator
					className={classes.icon}
					style={{ cursor: "grab" }}
				/>
				<span className={classes.label}>{label}</span>
			</div>
			<ExpandLessRounded
				className={`${classes.icon} ${
					open ? classes.iconOpen : classes.iconClosed
				}`}
			/>
		</div>
	);
};

export default DropdownButton;
