import {
	Divider,
	IconButton,
	makeStyles,
	Popper,
	PopperProps,
	Snackbar,
	TextField,
	Tooltip,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addConfigAction,
	addConfigLocallyAction,
	deleteConfigAction,
	updateConfigAction,
	updateConfigLocallyAction,
} from "../../store/UserConfig/action";
import {
	EventsViewSide,
	IConfig,
	ILayoutConfig,
	ILiveCamerasConfig,
} from "../../store/UserConfig/types";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import {
	Delete,
	DoubleArrow,
	Edit,
	PostAddRounded,
	Save,
} from "@material-ui/icons";
import {
	getDefaultEventView,
	predefinedLayouts,
} from "../../store/UserConfig/reducer";
import { v4 as uuidv4 } from "uuid";
import Alert from "../UI/Alert";
import { THEME } from "../../config";
import ConfirmDialog from "../UI/ConfirmDialog";
import LayoutNameDialog from "../Dialogs/LayoutNameDialog";
import { AppState } from "../../store";

interface IControlsProps {
	config: ILiveCamerasConfig | undefined;
	layouts: ILayoutConfig[];
	isConfigLoading: boolean;
	handleShowCamlist: () => void;
	isCamlistHidden: boolean;
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 8,
		padding: "0 2px",
	},
	select: {
		maxWidth: 240,
		width: "100%",
		marginLeft: 2,
	},
	button: {
		color: "black",
		width: 34,
		height: 34,
		borderRadius: 10,
	},
}));

const Controls = ({
	config,
	layouts,
	isConfigLoading,
	handleShowCamlist,
	isCamlistHidden,
}: IControlsProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isMqttConfigured } = useSelector((state: AppState) => state.live);
	const selectedLayout = layouts.find(
		(l) => l.id === config?.selectedLayoutId,
	);
	const selectedLayoutRef = useRef(selectedLayout);
	const eventsViews = useSelector(
		(state: AppState) => state.userConfig.data.eventsViews,
	);
	const [editableName, setEditableName] = useState(
		selectedLayout?.name ?? "",
	);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [openDialogs, setOpenDialogs] = useState({
		edit: false,
		delete: false,
	});

	const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditableName(e.target.value);
	};

	const createLayout = (layout: ILayoutConfig) => {
		const newLayout = { ...layout, id: uuidv4() };
		addConfigAction({
			variant: "layouts",
			config: newLayout,
		})(dispatch).then(() => selectLayout(newLayout));

		const views = eventsViews.filter((v) => v.layoutId === layout.id);
		views.forEach((view) => {
			addConfigAction({
				variant: "eventsViews",
				config: {
					...view,
					name: layout.name,
					layoutId: newLayout.id,
				},
			})(dispatch);
		});
	};

	const closeDialog = () =>
		setOpenDialogs((prev) => _.mapValues(prev, () => false));

	const handleSave = () => {
		if (!config || !selectedLayout) return;
		if (isPredefinedLayout(config.selectedLayoutId)) {
			createLayout({
				...selectedLayout,
				name: editableName,
			});
		} else {
			dispatch(
				updateConfigAction({
					variant: "layouts",
					config: { ...selectedLayout, name: editableName },
				}),
			);
		}
		setSnackbarOpen(true);
		closeDialog();
	};

	const handleDelete = () => {
		if (selectedLayout) {
			const id = selectedLayout.id;
			const newLayout = layouts.find((l) => l.id !== id);
			selectLayout(newLayout);
			const layoutViews = eventsViews.filter((v) => v.layoutId === id);
			layoutViews.forEach((view) => {
				dispatch(
					deleteConfigAction({
						config: view,
						variant: "eventsViews",
					}),
				);
			});

			dispatch(
				deleteConfigAction({
					config: selectedLayout,
					variant: "layouts",
				}),
			);
		}
		closeDialog();
	};

	const addView = () => {
		if (!selectedLayout || !selectedLayoutRef.current) return;
		const newView = getDefaultEventView(selectedLayout.id);
		dispatch(
			addConfigLocallyAction({
				variant: "eventsViews",
				config: newView,
			}),
		);
		if (!isPredefinedLayout(selectedLayout.id)) {
			addConfigAction({
				variant: "eventsViews",
				config: { ...newView, name: selectedLayout.name },
			})(dispatch).then(() => {
				if (selectedLayoutRef.current)
					dispatch(
						updateConfigAction({
							variant: "layouts",
							config: { ...selectedLayoutRef.current },
						}),
					);
			});
		}
	};

	const selectLayout = (layout: ILayoutConfig | undefined) => {
		if (config && layout) {
			const newConfig: IConfig = {
				variant: "live",
				config: {
					...config,
					selectedLayoutId: layout.id,
				},
			};

			dispatch(updateConfigLocallyAction(newConfig));
			dispatch(updateConfigAction(newConfig));
			setEditableName(layout.name);
		}
	};

	useEffect(() => {
		if (!config && !isConfigLoading)
			dispatch(
				addConfigAction({ variant: "live", config: defaultConfig }),
			);
	}, [config, isConfigLoading, dispatch]);

	useEffect(() => {
		selectedLayoutRef.current = selectedLayout;
	}, [selectedLayout]);

	useEffect(() => {
		if (!selectedLayout || isPredefinedLayout(selectedLayout?.id)) return;
		const selectedLayoutViews = eventsViews.filter(
			(v) => v.layoutId === selectedLayout.id,
		);
		const leftViews = selectedLayout?.viewsOrder[EventsViewSide.LEFT];
		const rightViews = selectedLayout?.viewsOrder[EventsViewSide.RIGHT];
		if (
			leftViews?.some(
				(id) => !selectedLayoutViews.find((v) => v.id === id),
			) ||
			rightViews?.some(
				(id) => !selectedLayoutViews.find((v) => v.id === id),
			)
		) {
			const newLayout = {
				...selectedLayout,
				viewsOrder: {
					[EventsViewSide.LEFT]: selectedLayout.viewsOrder[
						EventsViewSide.LEFT
					].filter((id) =>
						selectedLayoutViews.find((v) => v.id === id),
					),
					[EventsViewSide.RIGHT]: selectedLayout.viewsOrder[
						EventsViewSide.RIGHT
					].filter((id) =>
						selectedLayoutViews.find((v) => v.id === id),
					),
				},
			};

			dispatch(
				updateConfigAction({
					variant: "layouts",
					config: newLayout,
				}),
			);
		}
	}, [eventsViews, selectedLayout, dispatch]);

	return (
		<>
			<Box className={classes.root}>
				<Box display="flex" alignItems="center" gridGap={4} flex={1}>
					{config && (
						<Autocomplete
							PopperComponent={PopperComponent}
							groupBy={(option) =>
								isPredefinedLayout(option.id)
									? "Predefined"
									: "Custom"
							}
							className={classes.select}
							value={selectedLayout}
							options={layouts}
							getOptionSelected={(option, value) =>
								option?.id === value?.id
							}
							getOptionLabel={(option) => option.name}
							renderOption={(option) => (
								<Box display="flex">{option.name}</Box>
							)}
							onChange={(_, value) => {
								if (value) selectLayout(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Layout"
									size="small"
									variant="outlined"
									margin="none"
								/>
							)}
						/>
					)}

					<>
						<Tooltip
							title={
								selectedLayout?.saved === undefined
									? ""
									: "Save layout"
							}
						>
							<IconButton
								size="small"
								color="secondary"
								onClick={() => {
									if (
										isPredefinedLayout(
											config?.selectedLayoutId ?? "",
										)
									)
										setOpenDialogs({
											...openDialogs,
											edit: true,
										});
									else handleSave();
								}}
								disabled={selectedLayout?.saved === undefined}
							>
								<Save />
							</IconButton>
						</Tooltip>
						<Divider orientation="vertical" flexItem />
					</>

					{!isPredefinedLayout(selectedLayout?.id ?? "") && (
						<>
							<Tooltip title="Edit layout name">
								<IconButton
									size="small"
									color="secondary"
									onClick={() =>
										setOpenDialogs({
											...openDialogs,
											edit: true,
										})
									}
								>
									<Edit />
								</IconButton>
							</Tooltip>
							<Divider orientation="vertical" flexItem />
						</>
					)}

					{!isPredefinedLayout(selectedLayout?.id ?? "") && (
						<>
							<Tooltip title="Delete layout">
								<IconButton
									size="small"
									onClick={() =>
										setOpenDialogs({
											...openDialogs,
											delete: true,
										})
									}
									color="primary"
								>
									<Delete />
								</IconButton>
							</Tooltip>
							<Divider orientation="vertical" flexItem />
						</>
					)}
					<Tooltip
						title={isMqttConfigured ? "Add new events view" : ""}
					>
						<IconButton
							size="small"
							onClick={addView}
							disabled={!isMqttConfigured}
							color="secondary"
						>
							<PostAddRounded />
						</IconButton>
					</Tooltip>
				</Box>
				{isCamlistHidden && (
					<>
						<Divider orientation="vertical" flexItem />
						<Tooltip title="Show sources">
							<IconButton
								onClick={handleShowCamlist}
								className={classes.button}
							>
								<DoubleArrow
									style={{ transform: "rotate(180deg)" }}
								/>
							</IconButton>
						</Tooltip>
					</>
				)}
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={(_, reason) => {
					if (reason === "clickaway") return;
					setSnackbarOpen(false);
				}}
			>
				<div>
					<Alert
						onClose={() => setSnackbarOpen(false)}
						alert={{
							variant: "success",
							message: "Layout saved!",
						}}
					/>
				</div>
			</Snackbar>
			<LayoutNameDialog
				open={openDialogs.edit}
				onConfirm={handleSave}
				close={() => {
					closeDialog();
					setEditableName(selectedLayout?.name ?? "");
				}}
				name={editableName}
				onNameChange={onNameChange}
			/>
			<ConfirmDialog
				key={selectedLayout?.id}
				open={openDialogs.delete}
				onClose={closeDialog}
				onConfirm={handleDelete}
			>
				Are you sure you want to delete layout{" "}
				<span
					style={{
						fontWeight: "bold",
						color: THEME.palette.primary.main,
					}}
				>
					{selectedLayout?.name}
				</span>
				?
			</ConfirmDialog>
		</>
	);
};

export default Controls;

const PopperComponent = (props: PopperProps) => (
	<Popper
		{...props}
		style={{ width: "fit-content" }}
		placement="bottom-start"
	/>
);

export const isPredefinedLayout = (id: ILayoutConfig["id"]) =>
	predefinedLayouts.some((l) => l.id === id);

const defaultConfig: ILiveCamerasConfig = {
	id: "config1",
	name: "default",
	selectedLayoutId: predefinedLayouts[3].id,
};
