import { connect } from "react-redux";
import { AppState } from "../../store";
import { getDefinitionsAction } from "../../store/Definitions/action";
import { getPropertiesAction } from "../../store/Properties/action";
import ConfigurationView, {
	IConfigurationViewPropsToDispatch,
	IConfigurationViewPropsToState,
} from "./ConfigurationView";

const mapStateToProps = (state: AppState): IConfigurationViewPropsToState => {
	if (state.definitions.data.length === 0) {
		return {
			error: state.properties.erorr || state.definitions.erorr,
			properties: [],
			definitions: [],
		};
	}

	const keysOfHiddenProperties: string[] = [];
	for (const definition of state.definitions.data) {
		definition.properties.forEach((p) => {
			if (p.hasOwnProperty("scopeOnly") && p.scopeOnly === true)
				keysOfHiddenProperties.push(p.key);
		});
	}
	return {
		error: state.properties.erorr || state.definitions.erorr,
		properties: state.properties.data.map((p) => ({
			...p,
			values: p.values.filter(
				(v) => !keysOfHiddenProperties.includes(v.key),
			),
		})),
		definitions: state.definitions.data.map((d) => ({
			...d,
			properties: d.properties.filter(
				(p) => !keysOfHiddenProperties.includes(p.key),
			),
		})),
	};
};

const mapDispatchToProps = (
	dispatch: any,
): IConfigurationViewPropsToDispatch => {
	return {
		loadProperties: () => getPropertiesAction()(dispatch),
		loadDefinitions: () => getDefinitionsAction()(dispatch),
	};
};

const ConfigurationContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfigurationView);
export default ConfigurationContainer;
