import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { AdditionalFilterItem } from "../../../store/Events/types";
import * as Lists from "../../Forms/RuleForm/Triggers/VehicleHuman/lists";
import _ from "lodash";
import { FilterItem, getDefaultSelectedWithValues } from "./MoreFiltersItem";
import { THEME } from "../../../config";
import { DIRECTION_ICONS, IconsObj, OBJECT_TYPE_ICONS } from ".././constants";

const useStyles = makeStyles(() => ({
	itemRoot: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	faceAttributes: {
		width: "100%",
	},
	box: {
		marginBottom: 10,
		paddingRight: 8,
		width: "100%",
		backgroundColor: THEME.palette.background.default,
		"& > *:not(:last-child)": {
			borderBottom: `1px solid ${THEME.palette.divider}`,
		},
	},
}));

interface MoreFiltersProps {
	handleChange(filter: any): void;
	additionalFilters: { [key: string]: AdditionalFilterItem };
}

export const ObjectAttributes: React.FC<MoreFiltersProps> = ({
	handleChange,
	additionalFilters,
}) => {
	const classes = useStyles();

	const onCheckFilterWithValues = (item: string) => {
		if (additionalFilters[item]) {
			const newValue = _.omit(additionalFilters, item);
			handleChange(newValue);

			return;
		}
		handleChange({
			...additionalFilters,
			[item]: getDefaultSelectedWithValues(
				item,
				item === "ageGroup" ? [Lists.ageGroup[2]] : undefined,
			),
		});
	};

	function getFilterItem(
		name: string,
		label: string,
		list?: string[],
		icons?: IconsObj,
		showSwitch: boolean = false,
		splitSlider: boolean = true,
	) {
		return (
			<Box className={classes.itemRoot}>
				<FilterItem
					selected={additionalFilters[name]}
					onChange={(newValue) => {
						if (newValue === null) {
							const newFilters = _.omit(additionalFilters, name);
							handleChange(newFilters);
						} else {
							handleChange({
								...additionalFilters,
								[name]: newValue,
							});
						}
					}}
					selectValues={list}
					name={name}
					label={label}
					icons={icons}
					splitSlider={splitSlider}
					switchEl={showSwitch}
					onSwitchChange={onCheckFilterWithValues}
					noEmpty={name === "ageGroup"}
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.box}>
			{getFilterItem(
				"objects",
				"Type",
				Lists.objects,
				OBJECT_TYPE_ICONS,
				false,
				false,
			)}
			{getFilterItem(
				"colors",
				"Colors",
				Lists.colors,
				undefined,
				false,
				false,
			)}
			{getFilterItem(
				"directions",
				"Directions",
				Lists.directions,
				DIRECTION_ICONS,
				false,
				false,
			)}
			{getFilterItem(
				"ageGroup",
				"Age Group",
				Lists.ageGroup,
				undefined,
				true,
				false,
			)}
		</Box>
	);
};
