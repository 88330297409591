import React, { SyntheticEvent } from "react";
import moment from "moment";
import { IElasticHit, IRequestLogs } from "../../store/Logs/types";
import { HttpError } from "../../config/types";
import {
	Button,
	Snackbar,
	CircularProgress,
	Typography,
	Box,
} from "@material-ui/core";
import Alert, { IAlert } from "../../components/UI/Alert";
import LogsList from "../../components/Logs/LogsList";
import LogsFiltersBox from "../../components/Logs/LogsFiltersBox";
import { getLogsForDownload } from "../../store/Logs/action";
import {
	createBlobArr,
	createZipFromBlobs,
	downloadBlobText,
} from "../../helpers/Utils";
import { ContextProvider } from "./LogsContext";
import { THEME } from "../../config";

export interface ILogsViewDispatchProps {
	loadLogs: (filter?: IRequestLogs) => void;
	scrollLogs: (from: number, filter?: IRequestLogs, size?: number) => void;
}

export interface ILogsViewStateProps {
	logs: IElasticHit[] | undefined;
	areLogsLoaded: boolean;
	error?: HttpError;
}

type ILogsViewProps = ILogsViewDispatchProps & ILogsViewStateProps;

const filterDefaultValues: IRequestLogs = {
	service: [],
	severity: undefined,
	message: undefined,
	not: [],
	timeRange: {
		from: moment().subtract(1, "day"),
		to: moment(),
	},
};

export const LogsView: React.FC<ILogsViewProps> = ({
	logs,
	areLogsLoaded,
	loadLogs,
	scrollLogs,
}) => {
	const [severity, setSeverity] = React.useState(
		filterDefaultValues.severity,
	);
	const [service, setService] = React.useState(filterDefaultValues.service);
	const [message, setMessage] = React.useState(filterDefaultValues.message);
	const [not, setNot] = React.useState(filterDefaultValues.not);
	const [timeRange, setTimeRange] = React.useState(
		filterDefaultValues.timeRange,
	);

	const [downloadLoading, setDownloadLoading] =
		React.useState<boolean>(false);
	const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
	const [alert, setAlert] = React.useState<IAlert | undefined>(undefined);

	const logsRef = React.useRef<HTMLDivElement>(null);

	const executeScroll = () => {
		if (logsRef.current) {
			logsRef.current.scrollTo({ top: 0, behavior: "smooth" });
		}
	};

	React.useEffect(() => {
		loadLogs({ service, severity, message, not, timeRange });
	}, [service, severity, message, not, timeRange, loadLogs]);

	const handleScroll = (from: number) => {
		scrollLogs(from, { service, severity, message, not, timeRange });
	};

	const handleDownloadLogs = async (filters?: IRequestLogs) => {
		try {
			setDownloadLoading(true);

			downloadBlobText(
				await createZipFromBlobs(
					createBlobArr(await getLogsForDownload(10000, filters)),
					"sentiveillance-logs",
					"json",
				),
				"sentiveillance-logs.zip",
			);
		} catch (error) {
			handleOpenSnackBar({
				message: `Failed to download logs.`,
				variant: "error",
			});
		} finally {
			setDownloadLoading(false);
		}
	};

	const handleOpenSnackBar = (alert: IAlert) => {
		if (openSnackbar) {
			handleCloseSnackBar();
		}
		setAlert(alert);
		setOpenSnackbar(true);
	};

	const handleCloseSnackBar = (_event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	return (
		<ContextProvider
			severity={severity}
			setSeverity={setSeverity}
			service={service}
			setService={setService}
			message={message}
			setMessage={setMessage}
			not={not}
			setNot={setNot}
			timeRange={timeRange}
			setTimeRange={setTimeRange}
		>
			<Box
				display="flex"
				flexDirection="column"
				gridGap={THEME.spacing(2)}
				paddingY={1.25}
				height="130vh"
				overflow="hidden"
			>
				<LogsFiltersBox scrollToTop={executeScroll} />
				<Box>
					<Typography
						style={{
							display: "inline",
							marginRight: "10px",
							fontWeight: 500,
							color: "#727272",
						}}
					>
						Download:
					</Typography>
					<Button onClick={() => handleDownloadLogs()}>all</Button>
					<Button
						onClick={() =>
							handleDownloadLogs({
								...filterDefaultValues,
								timeRange: {
									from: moment().subtract(1, "day"),
									to: moment(),
								},
							})
						}
					>
						last day
					</Button>
					<Button
						onClick={() =>
							handleDownloadLogs({
								...filterDefaultValues,
								timeRange: {
									from: moment().subtract(1, "week"),
									to: moment(),
								},
							})
						}
					>
						last week
					</Button>
					<Button
						onClick={() =>
							handleDownloadLogs({
								service,
								severity,
								message,
								not,
								timeRange,
							})
						}
					>
						filtered
					</Button>
					{downloadLoading ? (
						<CircularProgress size={20} style={{ marginLeft: 5 }} />
					) : null}
				</Box>
				{logs || areLogsLoaded ? (
					<LogsList
						containerRef={logsRef}
						logs={logs ?? []}
						onScroll={handleScroll}
						executeScroll={executeScroll}
					/>
				) : (
					<CircularProgress
						style={{ marginInline: "auto", marginTop: "10%" }}
					/>
				)}
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackBar}
			>
				<div>
					<Alert
						onClose={handleCloseSnackBar}
						alert={alert as IAlert}
					/>
				</div>
			</Snackbar>
		</ContextProvider>
	);
};

export default LogsView;
