import React from "react";
import moment from "moment";
import { Context } from "../../containers/Logs/LogsContext";
import {
	Paper,
	Grid,
	Button,
	makeStyles,
	TextField,
	Chip,
	FormControl,
	InputLabel,
	Select,
	Input,
	MenuItem,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { LogsTimeFilter } from "./LogsTimeFilter";
import { SERVICES } from "./constants";

const useStyles = makeStyles(() => ({
	root: {
		margin: 0,
		width: "100%",
		padding: "0 10px",
		flexBasis: "auto",
	},
	title: {
		paddingLeft: 5,
		textAlign: "right",
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginRight: 5,
	},
	filterBox: {
		display: "flex",
		alignItems: "center",
	},
	searchInput: {
		width: "100%",
	},
	chipBox: { margin: 0 },
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: { margin: 5 },
}));

interface ILogsFiltersBoxProps {
	scrollToTop: () => void;
}

const LogsFiltersBox: React.FC<ILogsFiltersBoxProps> = ({ scrollToTop }) => {
	const classes = useStyles();
	const {
		timeRange,
		service,
		severity,
		not,
		setTimeRange,
		setSeverity,
		setService,
		setMessage,
		setNot,
	} = React.useContext(Context);
	const [inputMsg, setInputMsg] = React.useState("");
	const [relativeFrom, setRelativeFrom] = React.useState({
		value: 1,
		text: "day",
	});
	const [relativeUntil, setRelativeUntil] = React.useState({
		value: 0,
		text: "second",
	});
	const [showAbsoluteStart, setShowAbsoluteStart] = React.useState(0);
	const [showAbsoluteEnd, setShowAbsoluteEnd] = React.useState(0);

	const [from, setFrom] = React.useState(timeRange.from);
	const [until, setUntil] = React.useState(timeRange.to);

	React.useEffect(() => {
		setFrom(timeRange.from);
		setUntil(timeRange.to);
	}, [timeRange, setFrom, setUntil]);

	React.useEffect(() => {
		setFrom(
			moment().subtract(
				relativeFrom.value as moment.DurationInputArg1,
				relativeFrom.text as moment.unitOfTime.DurationConstructor,
			),
		);
	}, [relativeFrom]);

	React.useEffect(() => {
		setUntil(
			moment().subtract(
				relativeUntil.value as moment.DurationInputArg1,
				relativeUntil.text as moment.unitOfTime.DurationConstructor,
			),
		);
	}, [relativeUntil]);

	const handleResetClick = () => {
		setSeverity(undefined);
		setService([]);
		setMessage(undefined);
		setNot([]);
		setInputMsg("");
		scrollToTop();
		setTimeRange({
			from: moment().subtract(1, "day"),
			to: moment(),
		});
		setRelativeFrom({ value: 1, text: "day" });
		setRelativeUntil({ value: 0, text: "second" });
	};

	const handleTextInput = (
		event: React.ChangeEvent<
			{ name?: string; value: unknown } | HTMLInputElement
		>,
	) => {
		const result = event.target.value as string;
		setInputMsg(result);
	};

	const handleFilteredOutDelete = (value: string) => {
		const newFilteredOut = not.filter(function (item) {
			return Object.values(item)[0] !== value;
		});
		setNot(newFilteredOut);
		scrollToTop();
	};

	const handleFilterClick = () => {
		scrollToTop();
		setTimeRange({
			from: showAbsoluteStart
				? from
				: moment().subtract(
						relativeFrom.value as moment.DurationInputArg1,
						relativeFrom.text as moment.unitOfTime.DurationConstructor,
				  ),
			to: showAbsoluteEnd
				? until
				: moment().subtract(
						relativeUntil.value as moment.DurationInputArg1,
						relativeUntil.text as moment.unitOfTime.DurationConstructor,
				  ),
		});
		setMessage(inputMsg);
	};

	const handleServiceChange = (
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		setService(event.target.value as string[]);
		scrollToTop();
	};

	const isTimeError =
		new Date(from.format()).getTime() >= new Date(until.format()).getTime();

	return (
		<Grid
			item
			xs={10}
			sm={11}
			md={9}
			lg={8}
			component={Paper}
			className={classes.root}
		>
			<Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
				<Grid item xs={10} className={classes.filterBox}>
					<TextField
						autoFocus
						label="Search in message"
						variant="standard"
						onChange={handleTextInput}
						value={inputMsg}
						className={classes.searchInput}
						margin="dense"
						onKeyPress={(ev) => {
							if (ev.key === "Enter") {
								setMessage(inputMsg);
								scrollToTop();
							}
						}}
					/>
				</Grid>
				<Grid item xs={10} className={classes.filterBox}>
					<LogsTimeFilter
						from={from}
						until={until}
						relativeFrom={relativeFrom}
						relativeUntil={relativeUntil}
						onRelativeFromChange={(value, text) =>
							setRelativeFrom({ value, text })
						}
						onRelativeUntilChange={(value, text) =>
							setRelativeUntil({ value, text })
						}
						onFromChange={(newFrom) => setFrom(newFrom)}
						onUntilChange={(newUntil) => setUntil(newUntil)}
						showAbsoluteStart={showAbsoluteStart}
						showAbsoluteEnd={showAbsoluteEnd}
						onAbsoluteStartChange={(num) =>
							setShowAbsoluteStart(num)
						}
						onAbsoluteEndChange={(num) => setShowAbsoluteEnd(num)}
						onFromUntilChange={(newFrom, newUntil) => {
							setFrom(newFrom);
							setUntil(newUntil);
						}}
					/>
				</Grid>
				<Grid item xs={10} className={classes.filterBox}>
					<FormControl fullWidth>
						<InputLabel>Service</InputLabel>
						<Select
							fullWidth
							multiple
							value={service}
							onChange={handleServiceChange}
							input={<Input />}
							margin="dense"
							renderValue={(selected) => (
								<div className={classes.chips}>
									{(selected as string[]).map((value) => (
										<Chip
											key={value}
											label={value}
											className={classes.chip}
											deleteIcon={
												<CancelIcon
													onMouseDown={(event) =>
														event.stopPropagation()
													}
												/>
											}
											onDelete={() =>
												setService(
													service.filter(
														(other) =>
															other !== value,
													),
												)
											}
										/>
									))}
								</div>
							)}
							MenuProps={{
								PaperProps: {
									style: {
										width: 300,
									},
								},
								variant: "menu",
							}}
						>
							{SERVICES.map((serviceName) => (
								<MenuItem key={serviceName} value={serviceName}>
									{serviceName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} className={classes.chipBox}>
					{severity && (
						<Chip
							className={classes.chip}
							label={`Severity: ${severity}`}
							variant="outlined"
							onDelete={() => setSeverity(undefined)}
						/>
					)}
					{not.length !== 0 &&
						not.map((item, idx) => {
							const prop = Object.values(item)[0];

							return (
								<Chip
									key={idx}
									className={classes.chip}
									label={`NOT ${
										Object.keys(item)[0]
									}: ${prop}`}
									variant="outlined"
									onDelete={() =>
										handleFilteredOutDelete(
											Object.values(item)[0],
										)
									}
								/>
							);
						})}
				</Grid>
				<Grid item xs={12}>
					<div className={classes.buttons}>
						<Button
							onClick={handleFilterClick}
							disabled={isTimeError}
						>
							Filter
						</Button>
						<Button
							className={classes.button}
							onClick={handleResetClick}
						>
							Reset
						</Button>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LogsFiltersBox;
