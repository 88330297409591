import React from "react";
import { IResponse } from "../../store/types";
import { Tooltip, LinearProgress, makeStyles, Theme } from "@material-ui/core";
import { ISourceTask } from "../../store/Sources/types";

export interface ITaskStatusProps {
	task?: IResponse;
	lastTask: ISourceTask;
	children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	green: {
		backgroundColor: theme.palette.success.main + " !important",
	},
	red: {
		backgroundColor: theme.palette.error.main + " !important",
	},
}));

const TaskStatusIndicator: React.FC<ITaskStatusProps> = ({
	task,
	lastTask,
	children,
}) => {
	const classes = useStyles();

	if (
		!task ||
		(lastTask.status === "STOPPED" && task?.status !== "Processing")
	) {
		return <>{children}</>;
	}

	const statusToClass = (status: string) => {
		switch (status) {
			case "Scheduled":
			case "Error":
				return classes.red;
			case "Processing":
				return "";
			case "Complete":
				return classes.green;
			default:
				return classes.red;
		}
	};

	const renderIcon = (lastTaskStatus: string, status: string) => {
		let variant:
			| "indeterminate"
			| "determinate"
			| "buffer"
			| "query"
			| undefined = "indeterminate";
		switch (status) {
			case "Scheduled":
			case "Processing":
				variant = "query";
				break;
			case "Complete":
				variant = "determinate";
				break;
			default:
				variant = "determinate";
		}
		const liquidStatus =
			variant === "determinate" && lastTaskStatus === "ERROR"
				? "Error"
				: status;

		return (
			<LinearProgress
				variant={variant}
				value={100}
				classes={{ barColorPrimary: statusToClass(liquidStatus) }}
			/>
		);
	};
	const errorMsg =
		task.message && task.error ? `${task.message}  ${task.error}` : null;
	return (
		<Tooltip title={`${errorMsg || task.message || task}`}>
			<div>
				{children}
				{renderIcon(lastTask.status, task.status)}
			</div>
		</Tooltip>
	);
};

export default TaskStatusIndicator;
