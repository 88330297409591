import React from "react";
import {
	makeStyles,
	Chip,
	CircularProgress,
	Popover,
	Grid,
	Select,
	Button,
	Input,
	MenuItem,
	Checkbox,
	ListItemText,
	Divider,
	FormControl,
	Tooltip,
} from "@material-ui/core";
import { SourceTrigger } from "../../../../../store/Rules/types";
import { THEME } from "../../../../../config/index";
import { getSource } from "../../../../../store/Sources/action";
import { ISource } from "../../../../../store/Sources/types";
import { Videocam } from "@material-ui/icons";
import { AppState } from "../../../../../store";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	formControl: {
		minWidth: 200,
		maxWidth: 200,
	},
	icon: {
		width: 22,
		height: 22,
		fill: THEME.palette.grey[800],
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 6,
	},
}));

export const displayStrings = (strings: string[]) => {
	if (strings.length === 0) {
		return "Any";
	}
	const response = strings.join(", ");
	return response.length > 30 ? response.slice(0, 30) + "..." : response;
};

interface ISourceTriggerComponentProps {
	trigger: SourceTrigger;
	updateTrigger(trigger?: SourceTrigger): void;
	vmsName: string;
	sources: ISource[];
	isReadOnly: boolean;
	showOnly?: boolean;
}

interface ISourceTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: SourceTrigger): void;
	onCancel(): void;
	sourceTrigger?: SourceTrigger;
	sources: ISource[];
}

interface ISourceChipLabelProps {
	trigger: SourceTrigger;
	vmsName: string;
}

const SourceTriggerComponent: React.FC<ISourceTriggerComponentProps> = ({
	trigger,
	updateTrigger,
	vmsName,
	sources,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: SourceTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				icon={<Videocam className={classes.icon} />}
				label={
					<Tooltip
						title={findSourceName(trigger.values, sources).join(
							", ",
						)}
					>
						<div>
							<SourceChipLabel
								trigger={trigger}
								vmsName={vmsName}
							/>
						</div>
					</Tooltip>
				}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<SourceTriggerPopover
				sourceTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
				sources={sources}
			/>
		</>
	);
};

export const getSourceChipLabel = (trigger: SourceTrigger, vmsName: string) => (
	<SourceChipLabel trigger={trigger} vmsName={vmsName} />
);

const SourceChipLabel = ({ trigger, vmsName }: ISourceChipLabelProps) => {
	const classes = useStyles();
	const [sourceNames, setSourceNames] = React.useState<string[]>([]);
	const sources = useSelector(
		(state: AppState) => state.sources.keys[vmsName]?.content,
	);
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		let isSubed = true;

		const sourceList: string[] = [];
		const notLoadedSources: string[] = [];
		for (const value of trigger.values) {
			const src = sources?.find((source) => source.id === value);
			if (src) {
				sourceList.push(src.displayName);
			} else {
				notLoadedSources.push(value);
			}
		}

		const loadSources = async () => {
			setLoading(true);
			const responses = await Promise.allSettled(
				notLoadedSources.map((value) => getSource(vmsName, value)),
			);

			responses.forEach((response) => {
				if (response.status === "fulfilled") {
					sourceList.push(response.value.displayName);
				} else {
					sourceList.push("Failed to load.");
				}
			});

			if (isSubed) {
				setSourceNames(sourceList);
				setLoading(false);
			}
		};
		if (notLoadedSources.length > 0) loadSources();
		else {
			setSourceNames(sourceList);
		}
		return () => {
			isSubed = false;
		};
	}, [vmsName, trigger, sources]);

	return (
		<span className={classes.row}>
			Sources ({trigger.values.length}):{" "}
			{loading ? (
				<CircularProgress size={10} />
			) : (
				sourceNames.map((name, index) => (
					<span key={index} className={classes.tag}>
						{name}
					</span>
				))
			)}
		</span>
	);
};

const findSourceName = (selected: string[], sources: ISource[]): string[] => {
	const names: string[] = [];
	for (let i = 0; i < selected.length; i++) {
		const selectedSource = sources.find((source) => {
			return source.id === selected[i];
		});
		if (selectedSource) {
			names.push(selectedSource.displayName);
		}
	}
	return names;
};

const defaultTrigger: SourceTrigger = {
	type: "SourceTrigger",
	in: true,
	values: [],
};

export const SourceTriggerPopover: React.FC<ISourceTriggerPopoverProps> = ({
	anchorEl,
	onUpdate,
	onCancel,
	sourceTrigger,
	sources,
}) => {
	const classes = useStyles();
	const [trigger, setTrigger] = React.useState<SourceTrigger>(
		sourceTrigger ? sourceTrigger : defaultTrigger,
	);
	const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

	React.useEffect(() => {
		setTrigger(sourceTrigger ? sourceTrigger : defaultTrigger);
	}, [anchorEl, sourceTrigger]);

	const handleConfirm = () => {
		onUpdate(trigger);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleInPress = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.in = !changedTrigger.in;
		setTrigger(changedTrigger);
	};

	const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		const changedTrigger = { ...trigger };
		changedTrigger.values = event.target.value as string[];
		setTrigger(changedTrigger);
	};

	const handleTooltipOpen = () => setOpenTooltip(true);

	const handleTooltipClose = () => setOpenTooltip(false);

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			style={{ maxWidth: 4000 }}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item xs={12}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={4}>
							<Button onClick={handleInPress}>
								{trigger.in ? "From" : "Not From"}
							</Button>
						</Grid>
						<Grid item xs={8}>
							<FormControl className={classes.formControl}>
								<Tooltip
									title={
										trigger.values.length > 0
											? findSourceName(
													trigger.values,
													sources,
											  ).join(", ")
											: "No sources selected"
									}
									open={openTooltip}
								>
									<Select
										multiple
										value={trigger.values}
										onChange={handleSelect}
										input={<Input />}
										renderValue={(s) =>
											findSourceName(
												s as string[],
												sources,
											).join(", ")
										}
										fullWidth
										MenuProps={{ variant: "menu" }}
										disabled={sources.length === 0}
										onMouseEnter={handleTooltipOpen}
										onMouseLeave={handleTooltipClose}
										onClick={handleTooltipClose}
									>
										{sources.map((source, i) => (
											<MenuItem
												key={i}
												value={source.id}
												dense
											>
												<Checkbox
													checked={trigger.values.includes(
														source.id,
													)}
												/>
												<ListItemText
													primary={source.displayName}
												/>
												<Divider />
											</MenuItem>
										))}
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={trigger.values.length === 0}
						>
							{sourceTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default SourceTriggerComponent;
