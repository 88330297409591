import React from "react";
import { Grid, Box, makeStyles, Divider } from "@material-ui/core";
import { IRule } from "../../store/Rules/types";
import RulesItem from "./RulesItem";

const useStyles = makeStyles(() => ({
	container: {
		margin: 5,
	},
}));

interface IRulesListProps {
	rulesContent: IRule[];
	onEdit(rule: IRule): void;
	onDelete(rule: IRule): void;
	onView(rule: IRule): void;
	vmsName: string;
	areRulesAuthorized: boolean;
}

const RulesList: React.FC<IRulesListProps> = ({
	rulesContent,
	onEdit,
	onDelete,
	onView,
	vmsName,
	areRulesAuthorized,
}) => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Grid container>
				{rulesContent.length > 0 ? (
					rulesContent.map((rule, i) => (
						<Grid item key={i} xs={12}>
							<RulesItem
								rule={rule}
								onEdit={onEdit}
								onDelete={onDelete}
								vmsName={vmsName}
								areRulesAuthorized={areRulesAuthorized}
								onView={onView}
							/>
						</Grid>
					))
				) : (
					<>
						<Grid item xs={12}>
							<h3
								style={{
									color: "rgba(0, 0, 0, 0.54)",
									textAlign: "center",
									height: 30,
								}}
							>
								{areRulesAuthorized
									? 'No rules found. Press "Create New" to create new rule.'
									: "No rules found."}
							</h3>
							<Divider />
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};

export default RulesList;
