import React from "react";
import {
	Button,
	CircularProgress,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
} from "@material-ui/core";
import { IUserForm } from "../Dialogs/UserDialog";
import PasswordForm from "../InputForm/PasswordForm";
import TransferList from "../UI/TransferList";
import DisplayAuthorities from "../../config/Authorities";
import IPassword from "../InputForm/IPassword";

interface IUserFormProps {
	handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
	form: IUserForm;
	loading: boolean;
	handleInputChange: (
		event: React.ChangeEvent<{
			name?: string;
			value: unknown;
		}>,
	) => void;
	handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
	passwordRequired: boolean;
	handlePasswordChange(password: IPassword): void;
	handleAuthoritiesChange(selectedAuthorities: string[]): void;
}

const UserForm: React.FC<IUserFormProps> = ({
	handleSubmit,
	form,
	loading,
	handleInputChange,
	handleClose,
	passwordRequired,
	handlePasswordChange,
	handleAuthoritiesChange,
}) => {
	return (
		<form onSubmit={handleSubmit}>
			<Grid
				container
				spacing={2}
				alignContent="center"
				alignItems="center"
			>
				<Grid item xs={6}>
					<TextField
						fullWidth
						autoFocus
						name="username"
						label="Username"
						margin="normal"
						value={form.userName}
						onChange={handleInputChange}
						disabled={Boolean(form.id)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required={true}
						fullWidth
						label="Email"
						name="email"
						type="email"
						margin="normal"
						value={form.email}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<PasswordForm
						required={passwordRequired}
						onPasswordChange={handlePasswordChange}
					/>
				</Grid>
				{!passwordRequired && (
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Switch
									name="enabled"
									checked={form.enabled}
									onChange={handleInputChange}
									value={form.enabled}
									color="primary"
								/>
							}
							labelPlacement="top"
							label={form.enabled ? "Active" : "Disabled"}
						/>
					</Grid>
				)}
			</Grid>
			<TransferList
				all={form.allAuthorities}
				available={form.availableAuthorities}
				selected={form.selectedAuthorities}
				leftName="Available roles"
				rightName="Assigned roles"
				onChange={handleAuthoritiesChange}
				displayValues={DisplayAuthorities}
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				{loading ? <CircularProgress /> : null}
				<Button type="submit" color="primary" disabled={loading}>
					Submit
				</Button>
				<Button
					onClick={() => handleClose({}, "escapeKeyDown")}
					color="primary"
				>
					Cancel
				</Button>
			</div>
		</form>
	);
};

export default UserForm;
