import React from "react";
import {
	Checkbox,
	TextField,
	makeStyles,
	MenuItem,
	Select,
} from "@material-ui/core";
import { IDefinitionsProperty } from "../../store/Definitions/types";

const useStyles = makeStyles(() => ({
	checkbox: {
		marginLeft: -12,
	},
}));

interface IPropertyControlsProps {
	property: IDefinitionsProperty;
	value: string;
	onChange(changedValue: string): void;
	onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
	readOnly?: boolean;
}

const PropertyControls: React.FC<IPropertyControlsProps> = ({
	property,
	value,
	onChange,
	onBlur,
	readOnly,
}) => {
	const classes = useStyles();

	const handleChangeTextField = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const result = event.target.value as string;
		onChange(result);
	};

	const handleChangeBoolean = () => {
		if (value === "True") {
			onChange("False");
		} else {
			onChange("True");
		}
	};

	const handleChangeSelect = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const result = event.target.value as string;
		onChange(result);
	};

	switch (property.type) {
		case "Int32":
			return (
				<TextField
					fullWidth
					value={value}
					InputProps={{
						inputProps: {
							type: "number",
							min: property.minimum
								? parseInt(property.minimum)
								: 0,
							max: property.maximum
								? parseInt(property.maximum)
								: 2147483647,
						},
					}}
					onChange={handleChangeTextField}
				/>
			);
		case "Single":
			return (
				<TextField
					value={value}
					fullWidth
					onChange={handleChangeTextField}
					InputProps={{
						inputProps: {
							type: "number",
							min: property.minimum
								? parseInt(property.minimum)
								: 0,
							max: property.maximum
								? parseInt(property.maximum)
								: null,
						},
					}}
				/>
			);
		case "Int8":
			return (
				<TextField
					value={value}
					onChange={handleChangeTextField}
					fullWidth
					InputProps={{
						inputProps: {
							type: "number",
							min: property.minimum
								? parseInt(property.minimum)
								: 0,
							max: property.maximum
								? parseInt(property.maximum)
								: 127,
						},
					}}
				/>
			);
		case "Boolean":
			return (
				<Checkbox
					checked={value === "True"}
					className={classes.checkbox}
					onChange={handleChangeBoolean}
				/>
			);
		case "string":
			return (
				<Select value={value} onChange={handleChangeSelect} fullWidth>
					{property.allowedValues.map((val) => (
						<MenuItem key={val} value={val}>
							{val}
						</MenuItem>
					))}
				</Select>
			);
		case "Double":
			return (
				<TextField
					fullWidth
					onChange={handleChangeTextField}
					value={value}
					InputProps={{
						inputProps: {
							type: "number",
							step: 0.1,
							min: property.minimum
								? parseFloat(property.minimum)
								: 0,
							max: property.maximum
								? parseFloat(property.maximum)
								: null,
						},
					}}
				/>
			);
		case "String":
			return (
				<TextField
					fullWidth
					value={value}
					onChange={handleChangeTextField}
					inputProps={{
						readOnly,
					}}
					onBlur={onBlur}
				/>
			);
		default:
			return null;
	}
};

export default PropertyControls;
