import Axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IReport } from "./types";

export type Reports = "GET_REPORTS";

export type ReportsAction = AsyncAction<Reports, IReport[], string>;

export const getReports = async () => {
	try {
		const response = await Axios.get(API_BASE_PREFIX + `dashboards`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getReportsAction() {
	return async("GET_REPORTS", getReports, "");
}
