import { Box, CircularProgress, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import { TCamera } from "../../store/LiveCameras/types";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	HotspotType,
	PASSIVE_HOTSPOT_SECONDS_BETWEEN_UPDATES,
} from "./HotspotList";
import PlaybackStream from "../VideoPlayers/PlaybackStream";
import { ticksToDate } from "../../helpers/Utils";
import moment, { Moment } from "moment";
import _ from "lodash";
import LiveStream from "../VideoPlayers/LiveStream";
import { ISubjectEvent } from "../../proto/types";

interface Props {
	selectedEvent: ISubjectEvent | undefined;
	camera: TCamera;
	maximized: boolean;
	setMaximized: React.Dispatch<React.SetStateAction<boolean>>;
	title?: string;
	updateTitle: (type: HotspotType, title: string) => void;
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		overflow: "hidden",
	},
	empty: {
		height: "100vh",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		gap: 4,
		justifyContent: "center",
		alignItems: "center",
		color: THEME.palette.warning.dark,
		backgroundColor: THEME.palette.common.black,
	},
}));

const Hotspot = ({
	selectedEvent,
	camera,
	title,
	maximized,
	setMaximized,
	updateTitle,
}: Props) => {
	const classes = useStyles();
	const lastEventAddedAt = useRef<Moment | undefined>();
	const sourceList = useSelector((state: AppState) => state.sources.keys);
	const vmsList = useSelector((state: AppState) =>
		Object.values(state.vms.keys),
	);
	const { events, selectedEventIds, newestAppearedEvent } =
		useSelector((state: AppState) => state.live);


	const [cam, setCam] = useState<TCamera>(
		camera.hotspotType === HotspotType.Playback
			? {
					...camera,
					sourceId: selectedEvent?.sourceId,
					sourceName: sourceList[
						selectedEvent?.vms ?? ""
					]?.content?.find((s) => s.id === selectedEvent?.sourceId)
						?.displayName,
					vmsName: selectedEvent?.vms,
					isLive: false,
					timestamp:
						camera.timestamp ?? selectedEvent?.timeStamp
							? moment(
									ticksToDate(
										Number(selectedEvent?.timeStamp),
									),
							  ).valueOf()
							: undefined,
			  }
			: camera,
	);

	const camVMS = useMemo(
		() => vmsList.find((vms) => vms.name === cam?.vmsName),
		[vmsList, cam?.vmsName],
	);

	const updateCamera = useCallback(
		(newCamera: TCamera) => {
			if (_.isEqual(newCamera, cam)) return;
			setCam(newCamera);
			if (
				newCamera.hotspotType &&
				newCamera.vmsName &&
				newCamera.sourceName
			)
				updateTitle(
					newCamera.hotspotType,
					`${newCamera.vmsName} - ${newCamera.sourceName}`,
				);
		},
		[setCam, updateTitle, cam],
	);

	const handlePassiveHotspot = useCallback(() => {
		if (camera.hotspotType !== HotspotType.Passive) return;
		const currentTime = moment();
		if (
			lastEventAddedAt.current &&
			currentTime.diff(lastEventAddedAt.current, "seconds") <
				PASSIVE_HOTSPOT_SECONDS_BETWEEN_UPDATES
		)
			return;

		if (!newestAppearedEvent || newestAppearedEvent?.type === "Disappeared")
			return;
		lastEventAddedAt.current = currentTime;

		updateCamera({
			...camera,
			sourceId: newestAppearedEvent.sourceId,
			sourceName: sourceList[newestAppearedEvent.vms]?.content?.find(
				(s) => s.id === newestAppearedEvent.sourceId,
			)?.displayName,
			vmsName: newestAppearedEvent.vms,
			isLive: true,
		});
	}, [camera, newestAppearedEvent, sourceList, updateCamera]);

	useEffect(() => {
		if (camera.hotspotType === HotspotType.Playback)
			updateCamera({
				...camera,
				sourceId: selectedEvent?.sourceId,
				sourceName: sourceList[selectedEvent?.vms ?? ""]?.content?.find(
					(s) => s.id === selectedEvent?.sourceId,
				)?.displayName,
				vmsName: selectedEvent?.vms,
				isLive: false,
				timestamp:
					camera.timestamp ?? selectedEvent?.timeStamp
						? moment(
								ticksToDate(Number(selectedEvent?.timeStamp)),
						  ).valueOf()
						: undefined,
			});
		if (camera.hotspotType === HotspotType.Active)
			updateCamera({
				...camera,
				sourceId: selectedEvent?.sourceId,
				sourceName: sourceList[selectedEvent?.vms ?? ""]?.content?.find(
					(s) => s.id === selectedEvent?.sourceId,
				)?.displayName,
				vmsName: selectedEvent?.vms,
				isLive: true,
			});
	}, [
		events.length,
		selectedEvent,
		sourceList,
		camera,
		selectedEventIds,
		handlePassiveHotspot,
		updateCamera,
	]);

	useEffect(() => {
		if (camera.hotspotType === HotspotType.Passive) handlePassiveHotspot();
	}, [camera.hotspotType, handlePassiveHotspot]);

	return (
		<Box className={classes.root}>
			{camVMS && cam.sourceId ? (
				cam.isLive ? (
					<LiveStream
						key={Object.values(cam ?? {}).join("")}
						sourceId={cam.sourceId}
						vms={camVMS}
						maximized={maximized}
						title={title}
						loaderColor="white"
						setMaximized={setMaximized}
					/>
				) : (
					<PlaybackStream
						key={Object.values(cam ?? {}).join("")}
						eventTimestamp={cam.timestamp}
						selectedEvent={
							cam.vmsName === selectedEvent?.vms &&
							cam.sourceId === selectedEvent?.sourceId
								? selectedEvent
								: undefined
						}
						sourceId={cam.sourceId}
						vms={camVMS}
						maximized={maximized}
						title={title}
						setMaximized={setMaximized}
						autoStart
					/>
				)
			) : (
				<Box className={classes.empty}>
					{camera.hotspotType === HotspotType.Passive && (
						<CircularProgress
							size={24}
							style={{ color: THEME.palette.warning.dark }}
						/>
					)}
					<Typography variant="h6" align="center">
						{camera.hotspotType === HotspotType.Passive
							? "Waiting for event"
							: "Select an event to display"}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default Hotspot;
