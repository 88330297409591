import React from "react";
import { Divider, Snackbar, Tooltip, TextField } from "@material-ui/core";
import { HttpError } from "../../config/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import Alert from "./Alert";
import {
	IWatchlistsContainer,
	IgetWatchlistsRequest,
	IWatchlist,
} from "../../store/Watchlists/types";
import { Autocomplete } from "@material-ui/lab";
import { getWatchlistsAction } from "../../store/Watchlists/action";
import { limitDescription } from "../Tasks/Filter/TaskFiltering";
import { IFieldExist } from "../../store/Events/types";

interface IWatchlistViewPropsToDispatch {
	loadWatchlists: (request: IgetWatchlistsRequest) => void;
}

interface IWatchlistViewPropsToState {
	watchlists: IWatchlistsContainer;
	error?: HttpError;
}

interface IWatchlistFormSelectOwnProps {
	type: number | undefined;
	secret: string;
	value: IWatchlist | IFieldExist | undefined;
	tooltipTitle?: string;
	onWatchlist(watchlist: IWatchlist | IFieldExist | undefined): void;
}

interface IWatchlistFormSelectProps
	extends IWatchlistViewPropsToState,
		IWatchlistFormSelectOwnProps,
		IWatchlistViewPropsToDispatch {
	className?: string;
}

const _WatchlistFormSelect: React.FC<IWatchlistFormSelectProps> = ({
	watchlists,
	value,
	type,
	secret,
	error,
	loadWatchlists,
	tooltipTitle,
	onWatchlist,
	className,
}) => {
	React.useEffect(() => {
		loadWatchlists({});
	}, [loadWatchlists, secret]);

	const handleWatchlistInput = (
		_event: React.ChangeEvent<{}>,
		value: any | null,
	) => {
		if (value !== null) {
			onWatchlist(value);
		} else {
			onWatchlist(undefined);
		}
	};

	const subjectType =
		type === 0
			? "Faces"
			: type === 1
			? "VehicleHuman"
			: type === 2
			? "LicensePlates"
			: undefined;
	const options =
		subjectType && subjectType !== "VehicleHuman"
			? watchlists?.content.filter(
					(option) => option.type === subjectType,
			  )
			: watchlists?.content.sort((a) =>
					a.type === "Faces"
						? 1
						: a.type === "LicensePlates"
						? -1
						: 0,
			  );

	const additionalOptions = [
		{
			id: "In Any Watchlist",
			type: "Additional",
			description: "",
		},
		{
			id: "Not In Any Watchlist",
			type: "Additional",
			description: "",
		},
	];

	return (
		<>
			<Autocomplete
				noOptionsText={"No Watchlists found."}
				options={
					[
						...additionalOptions,
						...(secret
							? options
							: options.filter((item) => !item.secret)),
					] ?? []
				}
				getOptionSelected={(option, value) => option.id === value.id}
				getOptionLabel={(option) => option.id}
				groupBy={(option) =>
					option.type === "LicensePlates"
						? "License Plates"
						: option.type
				}
				renderOption={(s) => (
					<div
						style={{
							display: "inline",
							maxHeight: 300,
						}}
					>
						<b>{s.id}</b>
						<br />
						{s.description ? (
							<>
								{`Description: `}
								{limitDescription(s.description)}
							</>
						) : null}
						<Divider />
					</div>
				)}
				onChange={handleWatchlistInput}
				value={value ?? null}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						name="watchlist"
						id="watchlist-search"
						className={className}
						label={
							tooltipTitle ? (
								<Tooltip
									title={tooltipTitle}
									placement="bottom-start"
								>
									<span>Watchlist</span>
								</Tooltip>
							) : (
								"Watchlist"
							)
						}
					/>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state: AppState): IWatchlistViewPropsToState => {
	return {
		error: state.watchlists.erorr,
		watchlists: state.watchlists.data,
	};
};

const mapDispatchToProps = (dispatch: any): IWatchlistViewPropsToDispatch => {
	return {
		loadWatchlists: (request: IgetWatchlistsRequest) =>
			getWatchlistsAction(request)(dispatch),
	};
};

const WatchlistFormSelect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(_WatchlistFormSelect);
export default WatchlistFormSelect;
