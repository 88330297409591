import JSZip from "jszip";
import { IMenuItem } from "../config/MenuItems";
import * as Localization from "expo-localization";
import * as Locales from "date-fns/locale";
import { Locale } from "date-fns";
import { ISecret } from "../store/Secrets/types";
import moment from "moment";
import { DATE_TIME_FORMAT_WITH_MS } from "../config";
import { TPoint, TRect } from "../components/Sources/AreaDialog/RegionsOverlay";

interface PointF {
	X: number;
	Y: number;
}

export function getDateFnsLocale({
	locale,
}: Pick<typeof Localization, "locale">): Locale {
	const region = locale.match(/[A-Z]+/g) ?? "";
	return (
		//@ts-ignore
		Locales[locale.substring(0, 2) + region[0]] ??
		//@ts-ignore
		Locales[locale.substring(0, 2)] ??
		Locales.enUS
	);
}

export const delay = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const CapitalizeFirst = (str: string) => {
	if (str.length === 0) {
		return str;
	}
	str = str.toLocaleLowerCase();
	return str[0].toLocaleUpperCase() + str.slice(1);
};

export const lowerCaseFirstOnly = (str: string) => {
	if (str.length === 0) {
		return str;
	}
	return str[0].toLocaleLowerCase() + str.slice(1);
};

export const matchRoutes = (
	url: string,
	menu: Readonly<IMenuItem[]>,
): Readonly<IMenuItem[]> => {
	for (let i = 0; i < menu.length; i++) {
		const item = menu[i];
		if (item.route === url) {
			return [item];
		}
		if (item.subItems !== undefined && item.subItems.length > 0) {
			const matchedRoutes = matchRoutes(url, item.subItems);
			if (matchedRoutes.length > 0) {
				return [item, ...matchedRoutes];
			}
			if (item.route && url.includes(item.route)) {
				return [item];
			}
		}
	}
	return [];
};

export const formatLength = (str: any | null, length: number) => {
	if (typeof str == "boolean") {
		return str.toString();
	}
	if (str) {
		if (typeof str == "string") {
			if (str.length < length + 1) return str;
			let newString = "";
			for (let i = 0; i < str.length; i = i + length) {
				newString += str.slice(i, i + length) + "\n";
			}
			return newString;
		}
	}
	return str;
};

export const createZipFromBlobs = async (
	blobs: Blob[],
	fileName: string,
	type: string,
) => {
	const zip = JSZip();
	blobs.forEach((blob, i) => {
		zip.file(`${fileName}-${i}.${type}`, blob);
	});
	return zip.generateAsync({ type: "blob" });
};

export const createBlobArr = (items: any[]): Blob[] => {
	const blobs: Blob[] = [];
	items.forEach((item) => {
		blobs.push(
			new Blob([JSON.stringify(item, null, 2)], {
				type: "text/json",
			}),
		);
	});
	return blobs;
};

export const base64toBlob = (base64Data: string, contentType: string) => {
	const byteCharacters = atob(base64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: contentType });
};

export const downloadBlob = (blob: Blob, fileName: string) => {
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.download = fileName;
	link.click();
	link.remove();
};

export const downloadBlobText = (blob: Blob, fileName: string) => {
	const link = document.createElement("a");
	link.download = fileName;
	link.href = window.URL.createObjectURL(blob);
	link.dataset.downloadurl = ["text/json", link.download, link.href].join(
		":",
	);
	const evt = new MouseEvent("click", {
		view: window,
		bubbles: true,
		cancelable: true,
	});
	link.dispatchEvent(evt);
	link.remove();
};

export const percentage = (value: number) =>
	value < 0 ? "0" : (100 * value).toFixed(2);

export const unsecuredCopyToClipboard = (text: string) => {
	const textArea = document.createElement("textarea");
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	try {
		document.execCommand("copy");
	} catch (err) {
		throw err;
	}
	document.body.removeChild(textArea);
};

export const stringToPdfAndDownload = (str: string, fileName: string) => {
	const binaryData = atob(str);
	const uint8Array = new Uint8Array(binaryData.length);
	for (let i = 0; i < binaryData.length; i++) {
		uint8Array[i] = binaryData.charCodeAt(i);
	}
	const blob = new Blob([uint8Array], { type: "application/pdf" });
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	link.download = `${fileName}.pdf`;
	link.click();
};

export const isRectOutsideOfCanvas = (rect: TRect) => {
	const { start, end } = rect;
	const { X: startX, Y: startY } = start;
	const { X: endX, Y: endY } = end;

	const canvasXMin = 0;
	const canvasXMax = 1;
	const canvasYMin = 0;
	const canvasYMax = 1;

	const xIntersects =
		(startX <= canvasXMax && endX >= canvasXMin) ||
		(startX >= canvasXMin && endX <= canvasXMax);

	const yIntersects =
		(startY <= canvasYMax && endY >= canvasYMin) ||
		(startY >= canvasYMin && endY <= canvasYMax);

	if (xIntersects && yIntersects) {
		return false;
	}

	return true;
};

export const isPolygonValid = (points: PointF[]): boolean => {
	const numPoints: number = points.length;

	if (numPoints < 3) return false;

	const doLinesIntersect = (
		p1: PointF,
		p2: PointF,
		q1: PointF,
		q2: PointF,
	): boolean => {
		const dx1: number = p2.X - p1.X;
		const dy1: number = p2.Y - p1.Y;
		const dx2: number = q2.X - q1.X;
		const dy2: number = q2.Y - q1.Y;
		const crossProduct1: number = (q1.X - p1.X) * dy1 - (q1.Y - p1.Y) * dx1;
		const crossProduct2: number = (q2.X - p1.X) * dy1 - (q2.Y - p1.Y) * dx1;
		const crossProduct3: number = (p1.X - q1.X) * dy2 - (p1.Y - q1.Y) * dx2;
		const crossProduct4: number = (p2.X - q1.X) * dy2 - (p2.Y - q1.Y) * dx2;

		return (
			crossProduct1 * crossProduct2 < 0 &&
			crossProduct3 * crossProduct4 < 0
		);
	};

	for (let i = 0; i < numPoints; i++) {
		const p1: PointF = points[i];
		const p2: PointF = points[(i + 1) % numPoints];

		for (let j = i + 1; j < numPoints; j++) {
			const q1: PointF = points[j];
			const q2: PointF = points[(j + 1) % numPoints];

			if (doLinesIntersect(p1, p2, q1, q2)) {
				return false;
			}
		}
	}

	return true;
};

export const getIntersectionPoint = (point: TPoint, lastPoint: TPoint) => {
	const newPoint = { ...point };
	if (newPoint.X > 1 || newPoint.X < 0) {
		const slope = (newPoint.Y - lastPoint.Y) / (newPoint.X - lastPoint.X);
		newPoint.X = newPoint.X > 1 ? 1 : 0;
		newPoint.Y = slope * (newPoint.X - lastPoint.X) + lastPoint.Y;
	}
	if (newPoint.Y > 1 || newPoint.Y < 0) {
		const slope = (newPoint.Y - lastPoint.Y) / (newPoint.X - lastPoint.X);
		newPoint.Y = newPoint.Y > 1 ? 1 : 0;
		newPoint.X = (newPoint.Y - lastPoint.Y) / slope + lastPoint.X;
	}

	return newPoint;
};

export const secretsToWritableSecrets = (
	secrets: ISecret[],
	userName: string,
) => {
	return secrets.filter((secret) => {
		const arr = secret.permissions.map(
			(permission) => permission.user === userName && permission.write,
		);
		return arr.indexOf(true) >= 0;
	});
};

export const makeKeysLowerCase = (value: any): any => {
	if (typeof value === "object" && !Array.isArray(value)) {
		const keys = Object.keys(value);
		const newObj: any = {};

		keys.forEach((key) => {
			newObj[lowerCaseFirstOnly(key)] = makeKeysLowerCase(value[key]);
		});
		return newObj;
	} else if (Array.isArray(value)) {
		return value.map((item) => makeKeysLowerCase(item));
	}
	return value;
};

export const ticksToDate = (
	ticks: number,
	format = DATE_TIME_FORMAT_WITH_MS,
) => {
	const EPOCH_TICKS = 621355968000000000;
	const unixTimestamp = (ticks - EPOCH_TICKS) / 10000;
	return moment(unixTimestamp).format(format);
};

export const dateToTicks = (date: string) => {
	const EPOCH_TICKS = 621355968000000000;
	const unixTimestamp = moment(date).valueOf();
	return unixTimestamp * 10000 + EPOCH_TICKS;
};
