import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

const INTERVAL_MS = 3000;
let interval: NodeJS.Timeout | null = null;
const subscribers = new Set<{
	id: string;
	callback: (isOnline: boolean) => void;
}>();

const subscribeToOnlineStatus = (callback: (isOnline: boolean) => void) => {
	const newSetItem = { id: uuid(), callback };
	subscribers.add(newSetItem);
	return newSetItem;
};

const useIsOnline = () => {
	const dispatch = useDispatch();
	const [isOnline, setIsOnline] = useState(navigator.onLine ?? true);

	useEffect(() => {
		const subscriber = subscribeToOnlineStatus((online) => {
			if (online !== isOnline) setIsOnline(online);
		});

		if (interval) clearInterval(interval);
		interval = setInterval(async () => {
			const online = await checkIfOnline();
			if (isOnline !== online) {
				setIsOnline(online);
				if (!online)
					dispatch({
						type: "RESET_HISTORY",
					});
				for (const subscriber of subscribers) {
					subscriber.callback(online);
				}
			}
		}, INTERVAL_MS);

		return () => {
			subscribers.delete(subscriber);
			if (subscribers.size === 0 && interval) {
				clearInterval(interval);
				interval = null;
			}
		};
	}, [isOnline, dispatch]);

	return isOnline;
};

async function checkIfOnline(): Promise<boolean> {
	try {
		if (!self.navigator.onLine) return false;
		const request = new URL(self.location.origin);
		request.searchParams.set("isOnline", Date.now().toString());
		const response = await fetch(request.toString(), { method: "HEAD" });
		return response.ok;
	} catch (err) {
		return false;
	}
}

export default useIsOnline;
