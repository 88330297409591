import React from "react";
import { Grid, Button } from "@material-ui/core";
import { isJustAnalyst } from "../../helpers/Allowances";

interface ISubjectsButtons {
	click(option: "edit" | "create" | "delete" | "clear"): void;
	hasSubjects: boolean;
}

const SubjectsButtons: React.FC<ISubjectsButtons> = ({
	click,
	hasSubjects,
}) => {
	return isJustAnalyst() ? (
		<Grid container>
			<Grid item>
				<Button onClick={() => click("edit")} disabled={hasSubjects}>
					View
				</Button>
			</Grid>
		</Grid>
	) : (
		<Grid container>
			<Grid item>
				<Button onClick={() => click("create")}>Add</Button>
			</Grid>
			<Grid item>
				<Button onClick={() => click("edit")} disabled={hasSubjects}>
					Edit
				</Button>
			</Grid>
			<Grid item>
				<Button onClick={() => click("delete")} disabled={hasSubjects}>
					Delete
				</Button>
			</Grid>
			<Grid item>
				<Button onClick={() => click("clear")} disabled={hasSubjects}>
					Clear
				</Button>
			</Grid>
		</Grid>
	);
};

export default SubjectsButtons;
