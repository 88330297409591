import { subjectTypeData } from "../components/Events/Filter/FiltersContent";
import { getEvents } from "../store/Events/action";
import { EventsFilter, IElasticHit } from "../store/Events/types";
import { getSources } from "../store/Sources/action";
import { getVMS } from "../store/VMS/action";
import { defaultEngineTypes } from "../store/VMS/types";
import moment from "moment";
import { ISource } from "../store/Sources/types";
import JSZip from "jszip";
import { ExportField } from "../components/Dialogs/ExportDialog";
import { Dispatch, SetStateAction } from "react";

let cancelExport = false;
let SIZE = 1000;

const SEPARATOR = ";";
const IMAGES_BATCH_SIZE = 50;
const IMAGES_DELAY_MS = 25;
const HITS_IN_MEMORY_THRESHOLD = 50000;
const EXCLUDE_FIELDS: string[] = [
	"details",
	"imageHeight",
	"imageWidth",
	"taskId",
	"best.rectangle",
	"best.thumbnailRectangle",
];

export const cancelEventsExport = () => {
	cancelExport = true;
};

export const isEventsExportCancelled = () => {
	return cancelExport;
};

const exportEvents = async (
	filter: EventsFilter,
	fields: ExportField[],
	setLoadedHits: Dispatch<SetStateAction<number>>,
	prevSort?: number[],
	sourceList?: ISource[],
): Promise<void> => {
	cancelExport = false;
	let loadedHits = 0;
	const loaderInterval = setInterval(() => {
		setLoadedHits((prev) => prev + loadedHits);
		loadedHits = 0;
	}, 1000);

	try {
		let hits: IElasticHit[] | undefined = (
			await getEvents(filter, 0, SIZE, EXCLUDE_FIELDS, prevSort)
		).hits.hits;
		let totalHits = hits.length;
		if (cancelExport) return;
		const sources = sourceList ?? (await getAllSources());

		const zip = new JSZip();
		const imgFolder = zip.folder("images");

		const addLoadedHitsCount = (count: number) =>
			(loadedHits = loadedHits + count);

		const csvData = generateData(hits, sources, fields);
		await generateImages(hits, imgFolder, fields, addLoadedHitsCount);
		let lastSort = hits[hits.length - 1].sort;
		if (hits.length >= SIZE) {
			if (hits) hits = undefined;
			while (true) {
				let moreHits: IElasticHit[] | undefined = (
					await getEvents(filter, 0, SIZE, EXCLUDE_FIELDS, lastSort)
				).hits.hits;
				if (cancelExport) break;
				totalHits += moreHits.length;
				csvData.push(...generateData(moreHits, sources, fields));
				await generateImages(
					moreHits,
					imgFolder,
					fields,
					addLoadedHitsCount,
				);
				const newSort = moreHits[moreHits.length - 1].sort;
				if (
					moreHits.length < SIZE ||
					newSort === lastSort ||
					totalHits >= HITS_IN_MEMORY_THRESHOLD
				) {
					moreHits = undefined;
					lastSort = newSort;
					break;
				}
				lastSort = newSort;
				moreHits = undefined;
			}
		}
		if (cancelExport) return;

		const csv = getColumns(fields).join(SEPARATOR) + "\n";
		const finalCsv = csv + csvData.join("\n");

		const csvBlob = new Blob([finalCsv], { type: "text/csv" });
		zip.file("events.csv", csvBlob);

		const content = await zip.generateAsync({ type: "blob" });

		if (cancelExport) return;

		zip.remove("images");
		zip.remove("events.csv");
		const url = URL.createObjectURL(content);
		const a = document.createElement("a");
		a.href = url;
		a.download = "events.zip";
		a.click();
		URL.revokeObjectURL(url);
		a.remove();

		if (totalHits >= HITS_IN_MEMORY_THRESHOLD)
			return exportEvents(
				filter,
				fields,
				setLoadedHits,
				lastSort,
				sources,
			);
	} catch (error) {
		SIZE = SIZE / 2;
		if (SIZE < 100) throw error;
		return exportEvents(filter, fields, setLoadedHits);
	} finally {
		clearInterval(loaderInterval);
	}
};

export default exportEvents;

const getColumns = (fields: ExportField[]) => {
	const CSV_COLUMNS = [
		"subjectId",
		"timestamp",
		"vms",
		"sourceName",
		"sourceId",
		"subjectType_array",
		"engineType_array",
		"rules_array",
		"best_timestamp",
		"vh_detectionConfidence",
		"vh_objectType_car",
		"vh_objectType_person",
		"vh_objectType_bus",
		"vh_objectType_truck",
		"vh_objectType_bike",
		"vh_color_red",
		"vh_color_orange",
		"vh_color_yellow",
		"vh_color_green",
		"vh_color_blue",
		"vh_color_silver",
		"vh_color_white",
		"vh_color_black",
		"vh_color_brown",
		"vh_color_gray",
		"vh_direction_north",
		"vh_direction_northEast",
		"vh_direction_east",
		"vh_direction_southEast",
		"vh_direction_south",
		"vh_direction_southWest",
		"vh_direction_west",
		"vh_direction_northWest",
		"vh_ageGroup_senior",
		"vh_ageGroup_adult",
		"vh_ageGroup_child",
		"vh_ageGroup_teenager",
		"vh_clothingDetails_gender",
		"vh_clothingDetails_headwear",
		"vh_clothingDetails_torso",
		"vh_clothingDetails_arms",
		"vh_clothingDetails_legs",
		"vh_clothingDetails_feet",
		"vh_clothingDetails_clothes_array",
		"lp_value",
		"lp_formattedValue",
		"lp_detectionConfidence",
		"lp_ocrConfidence",
		"lp_characterHeight",
		"face_quality",
		"face_age",
		"face_gender",
		"face_mask",
		"face_hat",
		"face_beard",
		"face_mustache",
		"face_glasses",
		"face_darkGlasses",
		"face_mouthOpen",
		"face_blink",
		"face_smile",
		"matches_array",
	].filter((col) => {
		if (col.startsWith("face_") && !fields.includes(ExportField.FACES))
			return false;
		if (col.startsWith("vh_") && !fields.includes(ExportField.VH))
			return false;
		if (col.startsWith("lp_") && !fields.includes(ExportField.ALPR))
			return false;
		return true;
	});
	return CSV_COLUMNS;
};

const getAllSources = async () => {
	const vms = await getVMS({ size: 500 });

	const sourcesData = await Promise.all(
		vms.content.map(async (vm) =>
			getSources(vm.name, { size: 500 }).then((res) =>
				res.content.map((source) => ({ ...source, vms: vm.name })),
			),
		),
	);
	return sourcesData.reduce((acc, source) => {
		acc.push(...source);
		return acc;
	}, []);
};

const generateImages = async (
	hits: IElasticHit[],
	folder: JSZip | null,
	fields: ExportField[],
	addLoadedHitsCount: (count: number) => void,
) => {
	const writeImage = async (base64: string, name: string) => {
		folder?.file(name, base64, { base64: true });
	};

	const processHit = async (hit: IElasticHit) => {
		const lpImage = hit._source.best.licensePlates?.[0]?.thumbnail?.value;
		const faceImage = hit._source.best.faces?.[0]?.thumbnail?.value;
		const vhImage = hit._source.best.vehicleHuman?.thumbnail?.value;

		const timestamp = moment(hit._source.best.timeStamp).format(
			"YYYY_MM_DD_HH_mm_ss",
		);
		if (lpImage && fields.includes(ExportField.ALPR))
			await writeImage(lpImage, `${timestamp}-${hit._id}-lp.jpg`);
		if (faceImage && fields.includes(ExportField.FACES))
			await writeImage(faceImage, `${timestamp}-${hit._id}-face.jpg`);
		if (vhImage && fields.includes(ExportField.VH))
			await writeImage(vhImage, `${timestamp}-${hit._id}-vh.jpg`);
	};

	const processBatch = async (batch: IElasticHit[]) => {
		for (const hit of batch) {
			await processHit(hit);
		}
		addLoadedHitsCount(batch.length);
	};

	for (let i = 0; i < hits.length; i += IMAGES_BATCH_SIZE) {
		const batch = hits.slice(i, i + IMAGES_BATCH_SIZE);
		await processBatch(batch);
		await new Promise((resolve) => setTimeout(resolve, IMAGES_DELAY_MS));
	}
};

const generateData = (
	hits: IElasticHit[],
	sources: ISource[],
	fields: ExportField[],
) => {
	return hits.map((hit) => {
		const source = hit._source;
		const vh = source.best.vehicleHuman;
		const lp = source.best.licensePlates?.[0];
		const faces = source.best.faces?.[0];
		const attributes = faces?.attributes;
		const matches = source.best.matches;

		const vhValues = fields.includes(ExportField.VH)
			? [
					vh?.detectionConfidence ? vh.detectionConfidence ?? 0 : "",
					vh?.objectTypeConfidences?.car
						? vh.objectTypeConfidences.car
						: "",
					vh?.objectTypeConfidences?.person
						? vh.objectTypeConfidences.person
						: "",
					vh?.objectTypeConfidences?.bus
						? vh.objectTypeConfidences.bus
						: "",
					vh?.objectTypeConfidences?.truck
						? vh.objectTypeConfidences.truck
						: "",
					vh?.objectTypeConfidences?.bike
						? vh.objectTypeConfidences.bike
						: "",
					vh?.colorConfidences?.red ? vh.colorConfidences.red : "",
					vh?.colorConfidences?.orange
						? vh.colorConfidences.orange
						: "",
					vh?.colorConfidences?.yellow
						? vh.colorConfidences.yellow
						: "",
					vh?.colorConfidences?.green
						? vh.colorConfidences.green
						: "",
					vh?.colorConfidences?.blue ? vh.colorConfidences.blue : "",
					vh?.colorConfidences?.silver
						? vh.colorConfidences.silver
						: "",
					vh?.colorConfidences?.white
						? vh.colorConfidences.white
						: "",
					vh?.colorConfidences?.black
						? vh.colorConfidences.black
						: "",
					vh?.colorConfidences?.brown
						? vh.colorConfidences.brown
						: "",
					vh?.colorConfidences?.gray ? vh.colorConfidences.gray : "",
					vh?.directionConfidences?.north
						? vh.directionConfidences.north
						: "",
					vh?.directionConfidences?.northEast
						? vh.directionConfidences.northEast
						: "",
					vh?.directionConfidences?.east
						? vh.directionConfidences.east
						: "",
					vh?.directionConfidences?.southEast
						? vh.directionConfidences.southEast
						: "",
					vh?.directionConfidences?.south
						? vh.directionConfidences.south
						: "",
					vh?.directionConfidences?.southWest
						? vh.directionConfidences.southWest
						: "",
					vh?.directionConfidences?.west
						? vh.directionConfidences.west
						: "",
					vh?.directionConfidences?.northWest
						? vh.directionConfidences.northWest
						: "",
					vh?.ageGroupDetails?.seniorConfidence
						? vh.ageGroupDetails.seniorConfidence
						: "",
					vh?.ageGroupDetails?.adultConfidence
						? vh.ageGroupDetails.adultConfidence
						: "",
					vh?.ageGroupDetails?.childConfidence
						? vh.ageGroupDetails.childConfidence
						: "",
					vh?.ageGroupDetails?.teenagerConfidence
						? vh.ageGroupDetails.teenagerConfidence
						: "",
					vh?.clothingDetails?.gender
						? vh.clothingDetails.gender.value === 0
							? `male ${vh.clothingDetails.gender.confidence}`
							: `female ${vh.clothingDetails.gender.confidence}`
						: "",
					vh?.clothingDetails?.headwear
						? vh.clothingDetails.headwear.confidence
						: "",
					vh?.clothingDetails?.torso
						? vh.clothingDetails.torso.confidence
						: "",
					vh?.clothingDetails?.arms
						? vh.clothingDetails.arms.confidence
						: "",
					vh?.clothingDetails?.legs
						? vh.clothingDetails.legs.confidence
						: "",
					vh?.clothingDetails?.feet
						? vh.clothingDetails.feet.confidence
						: "",
					vh?.clothingDetails?.values
						?.map(
							(val: { name: string; confidence: number }) =>
								`${val.name} ${val.confidence}`,
						)
						.join(" | "),
			  ]
			: [];

		const lpValues = fields.includes(ExportField.ALPR)
			? [
					lp?.value,
					lp?.formattedValue,
					lp?.detectionConfidence ? lp.detectionConfidence : "",
					lp?.ocrConfidence ? lp.ocrConfidence : "",
					lp?.characterHeight ? lp.characterHeight : "",
			  ]
			: [];

		const faceValues = fields.includes(ExportField.FACES)
			? [
					faces?.quality ? faces.quality : "",
					attributes?.age ?? "",
					attributes?.gender
						? attributes.gender.value === 0
							? `male ${attributes.gender.confidence}`
							: `female ${attributes.gender.confidence}`
						: "",
					attributes?.mask ? `${attributes.mask.confidence}` : "",
					attributes?.hat ? `${attributes.hat.confidence}` : "",
					attributes?.beard ? `${attributes.beard.confidence}` : "",
					attributes?.mustache
						? `${attributes.mustache.confidence}`
						: "",
					attributes?.glasses
						? `${attributes.glasses.confidence}`
						: "",
					attributes?.darkGlasses
						? `${attributes.darkGlasses.confidence}`
						: "",
					attributes?.mouthOpen
						? `${attributes.mouthOpen.confidence}`
						: "",
					attributes?.blink ? `${attributes.blink.confidence}` : "",
					attributes?.smile ? `${attributes.smile.confidence}` : "",
			  ]
			: [];

		return [
			source.subjectId,
			source["@timestamp"],
			source.vms,
			sources.find((s) => s.id === source.sourceId)?.displayName,
			source.sourceId,
			source.subjectType
				.map(
					(type: number) =>
						subjectTypeData.find((s) => s.value === type)?.label,
				)
				.join(" | "),
			source.engineType
				.map((type: number) => defaultEngineTypes[type])
				.join(" | "),
			source.rules
				.map(
					(rule: { name: string; secret?: string }) =>
						`${rule.name}${
							rule.secret ? `/?secret=${rule.secret}` : ""
						}`,
				)
				.join(" | "),
			source.best.timeStamp,
			...vhValues,
			...lpValues,
			...faceValues,
			matches
				?.map(
					(match: {
						watchlist: string;
						id: string;
						score: number;
						secret?: string;
					}) =>
						`${match.watchlist}/${match.id}/${match.score}${
							match.secret ? `/?secret=${match.secret}` : ""
						}`,
				)
				.join(" | "),
		]
			.map((val) =>
				JSON.stringify(
					Array.isArray(val) ? val.join(" | ") : val ? val : "",
				),
			)
			.join(SEPARATOR);
	});
};
