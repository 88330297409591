import React from "react";
import {
	Grid,
	Paper,
	makeStyles,
	TableContainer,
	Table,
	TableBody,
	Box,
} from "@material-ui/core";
import { IElasticHit } from "../../store/Logs/types";
import LogsItem from "./LogsItem";
import InfiniteScroll from "react-infinite-scroll-component";
import CenteredLoading from "../UI/CenteredLoading";
import Delayed from "../UI/Delayed";
import { Context } from "../../containers/Logs/LogsContext";

const useStyles = makeStyles(() => ({
	root: {
		minHeight: "50vh",
		height: "100%",
		overflow: "hidden",
	},
	items: {
		overflowY: "auto",
		height: "100%",
	},
}));

interface ILogsListProps {
	logs: IElasticHit[];
	containerRef: React.RefObject<HTMLDivElement>;
	onScroll(from: number): void;
	executeScroll: () => void;
}

const LogsList: React.FC<ILogsListProps> = ({
	logs,
	containerRef,
	onScroll,
	executeScroll,
}) => {
	const classes = useStyles();
	const { message, severity, service, not, timeRange } =
		React.useContext(Context);
	const [previousLogsLength, setPreviousLogsLength] = React.useState(
		logs.length,
	);

	React.useEffect(() => {
		setPreviousLogsLength(logs.length < 100 ? 0 : logs.length - 100);
	}, [logs.length]);

	React.useEffect(() => {
		setPreviousLogsLength(0);
	}, [message, severity, service.length, not.length, timeRange]);

	const handleNext = () => {
		onScroll(logs.length);
		setPreviousLogsLength(logs.length);
	};

	return (
		<Box className={classes.root}>
			{logs.length > 0 ? (
				<Grid
					item
					container
					xs={12}
					style={{ overflow: "hidden", height: "100%" }}
				>
					<TableContainer
						component={Paper}
						style={{ height: "100%", overflow: "hidden" }}
					>
						<div
							id="logs-list"
							className={classes.items}
							ref={containerRef}
						>
							<InfiniteScroll
								style={{ overflow: "hidden" }}
								dataLength={logs.length}
								next={handleNext}
								hasMore={
									logs.length >= previousLogsLength + 100
								}
								loader={<CenteredLoading />}
								scrollableTarget="logs-list"
								endMessage={
									<Delayed waitBeforeShow={1000}>
										<Grid
											container
											alignItems="center"
											justifyContent="center"
										>
											<Grid item>
												<h3
													style={{
														color: "rgba(0, 0, 0, 0.54)",
													}}
												>
													No more logs are left to
													load.
												</h3>
											</Grid>
										</Grid>
									</Delayed>
								}
							>
								<Table aria-label="logs-table" size="small">
									<TableBody>
										{logs.map((log, i) => (
											<LogsItem
												log={log}
												key={i}
												executeScroll={executeScroll}
											/>
										))}
									</TableBody>
								</Table>
							</InfiniteScroll>
						</div>
					</TableContainer>
				</Grid>
			) : (
				<Grid item xs={12} style={{ height: "100%" }}>
					<h3
						style={{
							color: "rgba(0, 0, 0, 0.54)",
							textAlign: "center",
						}}
					>
						No logs found
					</h3>
				</Grid>
			)}
		</Box>
	);
};
export default LogsList;
