import Axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IgetWatchlistsRequest, IWatchlist, IQuerySecret } from "./types";
import { IResponse, PagedContent } from "../types";

export const Watchlists = "Watchlists";
export const Watchlist = "Watchlist";
export type WatchlistsAction = AsyncAction<
	typeof Watchlists | typeof Watchlist,
	PagedContent<IWatchlist[]> | IWatchlist,
	string
>;

export const getWatchlists = async (requestParams: IgetWatchlistsRequest) => {
	try {
		const response = await Axios.get(API_BASE_PREFIX + `watchlists/items`, {
			params: requestParams,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getWatchlist = async (watchlistId: string, secret: string) => {
	try {
		const response = await Axios.get<IWatchlist>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}`,
			{ params: { secret } },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createWatchlist = async (watchlist: IWatchlist) => {
	const { secret, ...requestBody } = watchlist;
	try {
		const response = await Axios.post<IResponse>(
			API_BASE_PREFIX + `watchlists/items`,
			requestBody,
			{ params: { secret } },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const clearWatchlist = async (
	watchlistId: string,
	secret: IQuerySecret,
) => {
	try {
		const response = await Axios({
			url: API_BASE_PREFIX + `watchlists/items/${watchlistId}/clear`,
			method: "post",
			params: secret,
		});
		return response.data as IResponse;
	} catch (error) {
		throw error;
	}
};

export const deleteWatchlist = async (
	watchlistId: string,
	secret: IQuerySecret,
) => {
	try {
		const response = await Axios.delete<IResponse>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}`,
			{ params: secret },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const changeWatchlistDescription = async (
	watchlistId: string,
	description: string,
	secret: IQuerySecret,
) => {
	try {
		const response = await Axios.patch<IResponse>(
			API_BASE_PREFIX + `watchlists/items/${watchlistId}`,
			description,
			{
				params: secret,
				headers: { "Content-Type": "text/plain" },
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getWatchlistsAction(requestParams: IgetWatchlistsRequest) {
	return async(Watchlists, getWatchlists, "", requestParams);
}

export function getWatchlistAction(watchlistId: string, secret: string) {
	return async(Watchlist, getWatchlist, "", watchlistId, secret);
}
