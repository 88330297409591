import React from "react";
import {
	IGetImageSearchRequest,
	ImageSearchContainer,
} from "../../store/ImageSearch/types";
import {
	Box,
	Checkbox,
	CircularProgress,
	makeStyles,
	Paper,
	TablePagination,
	Typography,
} from "@material-ui/core";
import ListTable from "./ListTable";
import { THEME } from "../../config";

const useStyles = makeStyles(() => ({
	paper: {
		padding: 10,
		width: "100%",
		overflow: "hidden",
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: 4,
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	showSearches: {
		paddingTop: 10,
	},
	content: {
		borderColor: "#4495a9",
		borderRadius: 4,
		overflowY: "auto",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		gap: 8,
		backgroundColor: THEME.palette.grey[100],
	},
	empty: {
		color: "rgba(0, 0, 0, 0.54)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	link: {
		cursor: "pointer",
		color: THEME.palette.secondary.light,
		"&:hover": {
			color: THEME.palette.secondary.main,
		},
	},
}));

interface ImageSearchesProps {
	filter: IGetImageSearchRequest;
	imageSearches: ImageSearchContainer;
	loading: boolean;
	canSeeSecrets: boolean;
	onCheckbox(): void;
	onChangePage(event: unknown, newPage: number): void;
	onChangeRows(event: React.ChangeEvent<HTMLInputElement>): void;
	openDialog(): void;
	uploadProgress: { loaded: number; total: number };
}

const ImageSearches: React.FC<ImageSearchesProps> = ({
	onCheckbox,
	onChangePage,
	onChangeRows,
	filter,
	loading,
	imageSearches,
	canSeeSecrets,
	openDialog,
}) => {
	const classes = useStyles();
	return (
		<Paper className={classes.paper}>
			<Box className={classes.header}>
				<Typography variant="h4">Search history</Typography>
				<Box display="flex">
					<Checkbox
						checked={filter.mineOnly}
						onChange={() => onCheckbox()}
					/>

					<Typography
						className={classes.showSearches}
						onClick={() => onCheckbox()}
					>
						Show Only my Searches
					</Typography>
				</Box>
			</Box>
			<Box className={classes.content} border={1}>
				{imageSearches.content.length > 0 ? (
					<ListTable
						data={imageSearches.content}
						canSeeSecrets={canSeeSecrets}
						page={filter.page || 0}
						loading={loading}
					/>
				) : loading ? (
					<Box className={classes.empty}>
						<CircularProgress />
					</Box>
				) : (
					<Box className={classes.empty}>
						<Typography
							variant="h5"
							onClick={openDialog}
							className={classes.link}
						>
							Click "New Search" to start searching
						</Typography>
					</Box>
				)}
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					count={imageSearches.totalElements}
					page={filter.page || 0}
					onPageChange={onChangePage}
					onRowsPerPageChange={onChangeRows}
					rowsPerPage={filter.size || 100}
					component="div"
					style={{ overflow: "hidden" }}
				/>
			</Box>
		</Paper>
	);
};

export default ImageSearches;
