import React from "react";
import Axios from "axios";
import { CssBaseline } from "@material-ui/core";
import { API_BASE_PREFIX } from "../config/axios";

const getInfo = async () => {
	const response = await Axios.get(API_BASE_PREFIX + "info");
	return response.data;
};

export const Info: React.FC = () => {
	const [info, setInfo] = React.useState<{ [key: string]: any } | null>(null);
	const [error, setError] = React.useState<string | null>(null);

	React.useEffect(() => {
		const loadInfo = async () => {
			try {
				const info = await getInfo();
				setInfo(info);
			} catch (error) {
				if (error instanceof Error) setError(error.message);
				else setError("An error occurred");
			}
		};
		loadInfo();
	}, []);

	return (
		<CssBaseline>
			<div style={{ display: "flex", gap: 10, padding: 10 }}>
				{info
					? Object.keys(info).map((key) => (
							<span key={key}>
								{key}: {info[key]}
							</span>
					  ))
					: error}
			</div>
		</CssBaseline>
	);
};
