import {
	Dispatch,
	FC,
	MutableRefObject,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
	makeStyles,
} from "@material-ui/core";
import {
	IElasticHit,
	EventsFilter,
	MatchProps,
} from "../../store/Events/types";
import { EventItem } from "./Event";
import { unsecuredCopyToClipboard } from "../../helpers/Utils";
import { ResizableBox } from "react-resizable";
import { StateSnapshot, Virtuoso, VirtuosoHandle } from "react-virtuoso";
import ExportDialog from "../Dialogs/ExportDialog";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import useBackwardSearch from "../../hooks/useBackwardSearch";
import BackwardSearchDialog from "../Dialogs/BackwardSearchDialog";
import ResultsList from "../BackwardSearch/ResultsList";
import useBackwardSearchResult from "../../hooks/useBackwardSearchResult";
import {
	ArrowBackRounded,
	FilterListRounded,
	OpenInNewRounded,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import BackwardFilterPopover from "../BackwardSearch/BackwardFilterPopover";
import { FILTER_BUTTON_ID } from "../../containers/BackwardSearchResult/BackwardSearchResultView";

const useStyles = makeStyles(({ palette }) => ({
	root: {
		height: "100%",
		padding: "8px 0 16px 16px",
	},
	listLoader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 80,
		textAlign: "center",
		margin: 0,
	},
	footerInfo: {
		color: "rgba(0, 0, 0, 0.54)",
		textAlign: "center",
		padding: "16px 0px 16px 16px",
	},
	exportBox: {
		maxWidth: 90,
	},
	searchHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	filterIcon: {
		color: palette.text.primary,
	},
}));

interface IEventsListProps {
	events: IElasticHit[];
	onScrollHandler(from: number): Promise<void>;
	selectedEvent: IElasticHit | null;
	setSelectedEventId: Dispatch<SetStateAction<string | null>>;
	innerRef: MutableRefObject<VirtuosoHandle | null>;
	isLoading: boolean;
	isLoadingMatches: boolean;
	loadEvents: (filter: EventsFilter) => void;
	filter: EventsFilter;
	loadMatch: (match: MatchProps) => void;
	setBackwardEvents: Dispatch<SetStateAction<IElasticHit[]>>;
}

const itemWidth = 240;
const maxCols = 3;

export const EventsList: FC<IEventsListProps> = ({
	events,
	onScrollHandler,
	selectedEvent,
	setSelectedEventId,
	isLoading,
	isLoadingMatches,
	loadEvents,
	filter,
	innerRef,
	loadMatch,
	setBackwardEvents,
}) => {
	const classes = useStyles();
	const itemsFitsToWidth = Math.min(
		Math.floor((window?.innerWidth * 0.33) / itemWidth) || 1,
		maxCols,
	);
	const sourceKeys = useSelector((state: AppState) => state.sources.keys);

	const virtuosoState = useRef<StateSnapshot | undefined>();
	const [size, setSize] = useState({
		maxWidth: itemWidth * maxCols,
		minWidth: itemWidth,
		width: itemsFitsToWidth * itemWidth,
		height: window.innerHeight - 60,
		itemsFitsToWidth,
	});
	const [scrollLoading, setScrollLoading] = useState<boolean>(false);
	const [exportDialogIsOpen, setExportDialogIsOpen] = useState(false);
	const [searchDialogIsOpen, setSearchDialogIsOpen] = useState(false);
	const [searchEvent, setSearchEvent] = useState<
		| {
				faceImg: string;
				subjectId: string;
		  }
		| undefined
	>();
	const [searchId, setSearchId] = useState("");
	const [isSearchLoading, setIsSearchLoading] = useState(false);
	const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);

	const lastLoadedIndex = useRef(0);

	const { uploadProgress, canSeeSecrets, handleSearchRequest } =
		useBackwardSearch({
			isDialogOpen: searchDialogIsOpen,
			onComplete: (id) => {
				setSearchId(id);
				setIsSearchLoading(false);
			},
			setOpenDialog: setSearchDialogIsOpen,
		});
	const {
		events: backwardEvents,
		isEventsLoading,
		isResultLoading,
		filter: backwardFilter,
		results,
		allSources,
		setFilter,
	} = useBackwardSearchResult({
		id: searchId,
		selectedResultIndex: 0,
	});

	const eventsByRow = useMemo(
		() =>
			events
				.filter(
					(event, index, self) =>
						index === self.findIndex((t) => t._id === event._id),
				)
				.reduce((acc, event, i) => {
					const index = Math.floor(i / size.itemsFitsToWidth);
					if (!acc[index]) acc[index] = [];
					acc[index].push(event as IElasticHit);
					return acc;
				}, [] as IElasticHit[][]),
		[events, size.itemsFitsToWidth],
	);

	const Footer = () => {
		return scrollLoading ? (
			<Box className={classes.listLoader}>
				<CircularProgress />
			</Box>
		) : !isLoading ? (
			<h3 className={classes.listLoader}>
				No more events are left to load.
			</h3>
		) : null;
	};

	const Header = () => {
		if (!isLoading) return null;
		return (
			<Box className={classes.listLoader}>
				<CircularProgress />
			</Box>
		);
	};

	const handleAtTop = () => {
		if (isLoading) return;
		loadEvents(filter);
	};

	useEffect(() => {
		window.addEventListener("keydown", (e) => handleCopy(e, selectedEvent));
		return () => {
			window.removeEventListener("keydown", (e) =>
				handleCopy(e, selectedEvent),
			);
		};
	}, [selectedEvent]);

	useEffect(() => {
		if (backwardEvents.length > 0) {
			setBackwardEvents(backwardEvents);
		}
	}, [backwardEvents, setBackwardEvents]);

	useEffect(() => {
		const handleResize = () => {
			const itemsFits = Math.min(
				Math.floor((window?.innerWidth / itemWidth) * 0.33) || 1,
				maxCols,
			);
			setSize((prev) => ({
				...prev,
				width: itemsFits * itemWidth,
				height: window.innerHeight - 60,
				itemsFitsToWidth: itemsFits,
			}));
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		setIsSearchLoading(false);
		setSearchId("");
	}, [filter]);

	return (
		<Box className={classes.root}>
			{searchId || isSearchLoading ? (
				<>
					<Box className={classes.searchHeader}>
						<IconButton
							className={classes.filterIcon}
							size="small"
							onClick={() => {
								setIsSearchLoading(false);
								setSearchId("");
							}}
						>
							<ArrowBackRounded />
						</IconButton>
						<Typography
							variant="subtitle1"
							style={{ fontWeight: "bold" }}
						>
							Search hits
						</Typography>
						<Box display="flex" gridGap={4} alignItems="center">
							<Tooltip title="Filter">
								<IconButton
									aria-describedby={FILTER_BUTTON_ID}
									className={classes.filterIcon}
									size="small"
									disabled={!results}
									onClick={(e) =>
										setFilterAnchor(e.currentTarget)
									}
								>
									<FilterListRounded />
								</IconButton>
							</Tooltip>
							<Tooltip title="Open search">
								<Link
									to={`/backwardSearch/${searchId}?index=0`}
								>
									<IconButton
										size="small"
										className={classes.filterIcon}
									>
										<OpenInNewRounded />
									</IconButton>
								</Link>
							</Tooltip>
						</Box>
					</Box>
					<ResultsList
						events={backwardEvents}
						filter={backwardFilter}
						id={searchId}
						index={0}
						isEventsLoading={isEventsLoading}
						isResultsLoading={isResultLoading || isSearchLoading}
						selectEvent={(event) => setSelectedEventId(event._id)}
						selectedEvent={backwardEvents.find(
							(e) => e._id === selectedEvent?._id,
						)}
						resizableWidth={size.width}
					/>
				</>
			) : (
				<>
					<Box className={classes.exportBox}>
						<Button
							size="small"
							variant="text"
							color="primary"
							onClick={() => setExportDialogIsOpen(true)}
							fullWidth
						>
							Export
						</Button>
						<ExportDialog
							filter={filter}
							open={exportDialogIsOpen}
							onClose={() => setExportDialogIsOpen(false)}
						/>
					</Box>
					<ResizableBox
						width={size.width}
						height={size.height}
						minConstraints={[size.minWidth, size.height]}
						maxConstraints={[size.maxWidth, size.height]}
						onResize={(_, data) => {
							setSize((prev) => ({
								...prev,
								width: data.size.width,
								itemsFitsToWidth: Math.min(
									Math.floor(data.size.width / itemWidth),
									maxCols,
								),
							}));
						}}
						onResizeStop={() => {
							setSize((prev) => ({
								...prev,
								width: size.itemsFitsToWidth * itemWidth,
							}));
						}}
					>
						{events.length < 1 && !isLoading ? (
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<h3 className={classes.footerInfo}>
									No events found
								</h3>
							</Box>
						) : (
							<Box
								height="100%"
								display="flex"
								alignItems="center"
								justifyContent="center"
								padding="4px 0 0 0"
							>
								<Virtuoso
									ref={innerRef}
									style={{ width: "100%", height: "100%" }}
									data={eventsByRow}
									overscan={100}
									onScroll={() => {
										innerRef.current?.getState(
											(state) =>
												(virtuosoState.current = state),
										);
									}}
									restoreStateFrom={virtuosoState.current}
									atBottomThreshold={window.innerHeight * 3}
									atTopStateChange={(atTop) => {
										if (atTop && events.length > 0)
											handleAtTop();
									}}
									endReached={(i) => {
										if (
											i !== lastLoadedIndex.current &&
											!scrollLoading &&
											!isLoading &&
											events.length > 0
										) {
											lastLoadedIndex.current = i;
											setScrollLoading(true);
											onScrollHandler(
												events.length,
											).finally(() => {
												setScrollLoading(false);
											});
										}
									}}
									components={{
										Footer,
										Header,
									}}
									itemContent={(_, item) => {
										return (
											<Box
												display="flex"
												gridGap={8}
												p={0.5}
											>
												{item.map((event, i) => (
													<EventItem
														key={i}
														event={event}
														isSelected={
															selectedEvent?._id ===
															event?._id
														}
														sourceName={
															event
																? sourceKeys[
																		event
																			._source
																			.vms
																  ]?.content.find(
																		(s) =>
																			s.id ===
																			event
																				._source
																				.sourceId,
																  )
																		?.displayName ??
																  ""
																: ""
														}
														onClick={() =>
															setSelectedEventId(
																event._id,
															)
														}
														isLoadingMatches={
															isLoadingMatches
														}
														loadMatch={loadMatch}
														openSearchDialog={(
															image: string,
														) => {
															setSearchDialogIsOpen(
																true,
															);
															setSearchEvent({
																faceImg: image,
																subjectId:
																	event
																		._source
																		.subjectId,
															});
														}}
													/>
												))}
											</Box>
										);
									}}
								/>
							</Box>
						)}
					</ResizableBox>
				</>
			)}
			<BackwardSearchDialog
				open={searchDialogIsOpen}
				close={() => setSearchDialogIsOpen(false)}
				onSubmit={async (request) => {
					setSearchDialogIsOpen(false);
					setIsSearchLoading(true);
					handleSearchRequest(request);
				}}
				loading={uploadProgress.total > uploadProgress.loaded}
				canSeeSecrets={canSeeSecrets}
				draggedImages={searchEvent ? [searchEvent.faceImg] : []}
				uploadProgress={uploadProgress}
				subjectId={searchEvent?.subjectId}
			/>
			{results && (
				<BackwardFilterPopover
					results={results}
					filter={backwardFilter}
					onFilter={(f) => setFilter(f)}
					anchorEl={filterAnchor}
					onClose={() => setFilterAnchor(null)}
					allSources={allSources}
				/>
			)}
		</Box>
	);
};

export const handleCopy = (e: KeyboardEvent, value: any) => {
	if (
		(e.ctrlKey || e.metaKey) &&
		e.key === "c" &&
		!window.getSelection()?.toString() &&
		value
	) {
		const text = JSON.stringify(value, null, 2);
		if (navigator.clipboard) {
			navigator.clipboard.writeText(text);
		} else {
			unsecuredCopyToClipboard(text);
		}
	}
};
