import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BackwardSearchRequest } from "../../store/BackwardSearch/types";
import BackwardSearchForm from "../Forms/BackwardSearchForm";

interface BackwardSearchDialogProps {
	open: boolean;
	close(): void;
	onSubmit(
		searchRequest: Omit<BackwardSearchRequest, "image"> & {
			images: string[];
		},
	): Promise<void>;
	loading: boolean;
	canSeeSecrets: boolean;
	draggedImages: string[];
	uploadProgress: {
		loaded: number;
		total: number;
	};
	subjectId?: string;
}

const useStyles = makeStyles(() => ({
	dialog: {
		overflow: "hidden",
		maxWidth: "50%",
		maxHeight: "80%",
		minHeight: "min(80%, 700px)",
	},
}));

const BackwardSearchDialog: React.FC<BackwardSearchDialogProps> = ({
	open,
	close,
	onSubmit,
	loading,
	canSeeSecrets,
	draggedImages,
	uploadProgress,
	subjectId,
}) => {
	const classes = useStyles();
	return (
		<Dialog
			open={open}
			fullWidth
			style={{ overflow: "hidden" }}
			classes={{ paper: classes.dialog }}
			onClose={close}
		>
			<DialogTitle>Search</DialogTitle>
			<BackwardSearchForm
				close={close}
				onSubmit={onSubmit}
				loading={loading}
				canSeeSecrets={canSeeSecrets}
				draggedImages={draggedImages}
				uploadProgress={uploadProgress}
				subjectId={subjectId}
			/>
		</Dialog>
	);
};

export default BackwardSearchDialog;
