import { ILiveEvent, ILiveState, TCamera } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { LiveAction } from "./action";
import { v4 as uuid } from "uuid";
import { omit } from "lodash";
import { IEventsViewConfig } from "../UserConfig/types";
import _ from "lodash";
import moment from "moment";
import { ticksToDate } from "../../helpers/Utils";

export const DEFAULT_MAX_EVENTS_LIMIT = 100;

export const initialLiveState: ILiveState = {
	events: [],
	separatedEvents: [],
	newestAppearedEvent: null,
	draggingCamera: null,
	draggingView: null,
	selectedEventIds: null,
	selectedCamera: null,
	isMqttConfigured: false,
	mqttConfigurationChecked: false,
	temporaryCamera: null,
	sdkIsLoaded: false,
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleDraggingCamera = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const payload = action.payload as TCamera;

		nState.draggingCamera =
			payload === null ? null : omit(payload, "isAlreadyAdded");
		return nState;
	}
	return state;
};

const handleDraggingView = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const payload = action.payload as IEventsViewConfig | null;
		nState.draggingView = payload;
		return nState;
	}
	return state;
};

const setSdkIsLoaded = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		nState.sdkIsLoaded = true;
		return nState;
	}
	return state;
};

const addEvents = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const events = [...nState.events];
		const separatedEvents = [...nState.separatedEvents];
		const newEvents: ILiveEvent[] = [];
		const newSeparatedEvents: ILiveEvent[] = [];

		const payload = action.payload as ILiveEvent | ILiveEvent[];
		const isArray = Array.isArray(payload);
		const eventsArray = isArray ? payload : [payload];
		eventsArray.forEach((evt) => {
			const newEvent = {
				...evt,
				triggeredRules: [evt.triggeredRule],
			} as ILiveEvent;
			if (newEvent.type !== "Disappeared")
				nState.newestAppearedEvent = newEvent;

			const mergeEventIdx = events.findIndex(
				(event) => event.subjectId === newEvent.subjectId,
			);
			const mergeNewEventIdx = newEvents.findIndex(
				(event) => event.subjectId === newEvent.subjectId,
			);

			const mergeSeparateEventIdx = separatedEvents.findIndex(
				(event) =>
					event.subjectId === newEvent.subjectId &&
					event.triggeredRule.name === newEvent.triggeredRule.name,
			);
			const mergeNewSeparateEventIdx = newSeparatedEvents.findIndex(
				(event) =>
					event.subjectId === newEvent.subjectId &&
					event.triggeredRule.name === newEvent.triggeredRule.name,
			);

			const newEventId = uuid();

			const mergeEvent = (
				index: number,
				eventToMerge: ILiveEvent,
				array: ILiveEvent[],
				addTriggeredRules = false,
			) => {
				if (nState.selectedEventIds?.eventId === array[index]?.id)
					nState.selectedEventIds.eventId = newEventId;

				array[index] = {
					...eventToMerge,
					id: newEventId,
					triggeredRules: addTriggeredRules
						? [
								...(array[index].triggeredRules ?? []),
								eventToMerge.triggeredRule,
						  ].filter(
								(rule, i, arr) =>
									arr.findIndex(
										(r) => r?.name === rule?.name,
									) === i,
						  )
						: array[index].triggeredRules,
				};
			};
			if (mergeEventIdx !== -1) {
				mergeEvent(mergeEventIdx, newEvent, events, true);
			} else if (mergeNewEventIdx !== -1) {
				mergeEvent(mergeNewEventIdx, newEvent, newEvents, true);
			} else newEvents.unshift({ ...newEvent, id: newEventId });

			const newSeparateEventId = uuid();
			if (mergeSeparateEventIdx !== -1) {
				mergeEvent(mergeSeparateEventIdx, newEvent, separatedEvents);
			} else if (mergeNewSeparateEventIdx !== -1) {
				mergeEvent(
					mergeNewSeparateEventIdx,
					newEvent,
					newSeparatedEvents,
				);
			} else
				newSeparatedEvents.unshift({
					...newEvent,
					id: newSeparateEventId,
				});
		});

		const getIndexByTimestamp = (
			event: ILiveEvent,
			eventsArr: ILiveEvent[],
		) => {
			const eventTime = moment(ticksToDate(Number(event.timeStamp)));
			const index = eventsArr.findIndex((e) => {
				const eTime = moment(ticksToDate(Number(e.timeStamp)));
				return eventTime.isSameOrAfter(eTime);
			});
			return index;
		};

		if (isArray) {
			const newestSeparateEvent = newSeparatedEvents[0];
			const newestEvent = newEvents[0];
			if (newestEvent) {
				const indexOfEvent = getIndexByTimestamp(newestEvent, events);
				if (indexOfEvent !== -1)
					events.splice(indexOfEvent, 0, ...newEvents);
				else events.push(...newEvents);
			} else events.push(...newEvents);

			if (newestSeparateEvent) {
				const indexOfSeparateEvent = getIndexByTimestamp(
					newestSeparateEvent,
					separatedEvents,
				);
				if (indexOfSeparateEvent !== -1)
					separatedEvents.splice(
						indexOfSeparateEvent,
						0,
						...newSeparatedEvents,
					);
				else separatedEvents.push(...newSeparatedEvents);
			} else separatedEvents.push(...newSeparatedEvents);
		} else {
			events.unshift(...newEvents);
			separatedEvents.unshift(...newSeparatedEvents);
		}

		const views = action.meta as IEventsViewConfig[];
		const maxEventsLimit = views.reduce((acc, view) => {
			return Math.max(
				acc,
				view.maxEventsLimit || DEFAULT_MAX_EVENTS_LIMIT,
			);
		}, DEFAULT_MAX_EVENTS_LIMIT);

		if (events.length >= Math.max(maxEventsLimit, 1000) * 2)
			events.splice(maxEventsLimit);
		if (separatedEvents.length >= Math.max(maxEventsLimit, 1000) * 2)
			separatedEvents.splice(maxEventsLimit);

		nState.events = events;
		nState.separatedEvents = separatedEvents;

		return nState;
	}
	return state;
};

const updateEvent = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const updatedEvent = action.payload as ILiveEvent;

		nState.events = nState.events.map((event) => {
			if (event.id === updatedEvent.id) return updatedEvent;
			return event;
		});

		nState.separatedEvents = nState.separatedEvents.map((event) => {
			if (event.id === updatedEvent.id) return updatedEvent;
			return event;
		});
		return nState;
	}
	return state;
};

const selectEvent = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const payload = action.payload as ILiveState["selectedEventIds"];
		nState.selectedEventIds = payload;
		return nState;
	}
	return state;
};

const setMqttAsConfigured = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		nState.mqttConfigurationChecked = true;
		nState.isMqttConfigured = true;
		return nState;
	}
	return state;
};

const setMqttAsChecked = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		nState.mqttConfigurationChecked = true;
		return nState;
	}
	return state;
};

const selectCamera = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const payload = action.payload as ILiveState["selectedCamera"];
		nState.selectedCamera = payload;
		return nState;
	}
	return state;
};

const setTemporaryCamera = (
	state: ILiveState = initialLiveState,
	action: LiveAction,
) => {
	const nState = { ...state };
	if (action.status === AsyncActionStatus.SUCCEEDED) {
		const payload = action.payload as ILiveState["temporaryCamera"];
		nState.temporaryCamera = payload;
		return nState;
	}
	return state;
};

export const LiveReducer: Reducer<ILiveState, LiveAction> = (
	state = initialLiveState,
	action: LiveAction,
) => {
	switch (action.type) {
		case "DRAGGING_CAMERA":
			return handleDraggingCamera(state, action);
		case "DRAGGING_VIEW":
			return handleDraggingView(state, action);
		case "ADD_LIVE_EVENT":
			return addEvents(state, action);
		case "UPDATE_LIVE_EVENT":
			return updateEvent(state, action);
		case "SELECT_LIVE_EVENT":
			return selectEvent(state, action);
		case "SET_MQTT_AS_CONFIGURED":
			return setMqttAsConfigured(state, action);
		case "SET_MQTT_AS_CHECKED":
			return setMqttAsChecked(state, action);
		case "SELECT_CAMERA":
			return selectCamera(state, action);
		case "SET_TEMPORARY_CAMERA":
			return setTemporaryCamera(state, action);
		case "SET_SDK_IS_LOADED":
			return setSdkIsLoaded(state, action);
		default:
			return state;
	}
};
