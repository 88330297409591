import React from "react";
import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DIRECTION_ICONS, OBJECT_TYPE_ICONS } from "../constants";
import { percentage } from "../../../helpers/Utils";
import {
	EventsFilter,
	IVehicleHumanDetails,
} from "../../../store/Events/types";
import { THEME } from "../../../config";
import DetailItem from "./DetailItem";
import { upperFirst } from "lodash";
import { CONFIDENCE } from "../Filter/MoreFiltersItem";
import { camelCaseToReadable } from "../../../helpers/Filter";

const useStyles = makeStyles(() => ({
	root: {
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: THEME.palette.background.default,
		padding: 6,
		flex: 2,
		display: "flex",
		flexDirection: "column",
	},
	detailsBox: {
		gap: 2,
		padding: "4px 0",
		flex: 1,
	},
	icons: {
		height: 20,
		width: 20,
	},
	attribute: {
		display: "flex",
		gap: 4,
		alignItems: "center",
		whiteSpace: "nowrap",
	},
	dividerRight: {
		borderRight: `1px solid ${THEME.palette.grey[500]}`,
		paddingRight: 4,
	},
	attributesBox: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		wrap: "nowrap",
		gap: 8,
	},
	boldText: {
		fontWeight: "bold",
		whiteSpace: "nowrap",
		paddingBottom: 4,
	},
	title: {
		borderBottom: `1px solid ${THEME.palette.grey[500]}`,
		color: THEME.palette.secondary.main,
		fontWeight: "bold",
	},
	colors: {
		border: "1px solid black",
		borderRadius: 20,
		height: 20,
		width: 20,
	},
	makeModel: { display: "flex", flexDirection: "column" },
	details: { display: "flex", gap: 10 },
}));

export const VehicleHumanDetails: React.FC<{
	data: IVehicleHumanDetails;
	filter?: EventsFilter;
}> = ({ data, filter }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title} variant="h6">
				Details
			</Typography>
			<Box className={classes.attributesBox}>
				{Object.keys(data.objectTypeConfidences).length > 0 && (
					<Box
						className={`${classes.detailsBox} ${
							Object.keys(data.directionConfidences).length > 0 ||
							data.vehicleDetails ||
							data.clothingDetails ||
							Object.keys(data.colorConfidences).length > 0
								? classes.dividerRight
								: ""
						}`}
					>
						<Typography className={classes.boldText}>
							Object Type
						</Typography>
						<DetailsWithIcons
							details={data.objectTypeConfidences}
							icons={OBJECT_TYPE_ICONS}
							filter={filter}
							findByKey="objects"
						/>
					</Box>
				)}
				{Object.keys(data.colorConfidences).length > 0 && (
					<Box
						className={`${classes.detailsBox} ${
							Object.keys(data.directionConfidences).length > 0 ||
							data.vehicleDetails ||
							data.clothingDetails
								? classes.dividerRight
								: ""
						}`}
					>
						<Typography className={classes.boldText}>
							Colors
						</Typography>
						{Object.keys(data.colorConfidences)
							.sort(
								(a, b) =>
									data.colorConfidences[b] -
									data.colorConfidences[a],
							)
							.map((keyName: string, i) => {
								const filterConfidence =
									filter?.moreFilters?.colors?.confidence;

								const isConfidenceLow =
									data.colorConfidences[keyName] * 100 <
										(filterConfidence ??
											CONFIDENCE.colors) ||
									data.colorConfidences[keyName] * 100 > 100;
								return (
									<Box
										className={classes.attribute}
										key={i}
										style={{
											opacity: isConfidenceLow ? 0.5 : 1,
										}}
									>
										<Tooltip
											title={camelCaseToReadable(keyName)}
											PopperProps={{
												disablePortal: true,
											}}
										>
											<Box
												className={classes.colors}
												style={{
													backgroundColor: keyName,
												}}
											/>
										</Tooltip>
										<Typography>{`${percentage(
											data.colorConfidences[keyName],
										)}%`}</Typography>
									</Box>
								);
							})}
					</Box>
				)}
				{Object.keys(data.directionConfidences).length > 0 && (
					<Box
						className={`${
							data.vehicleDetails || data.clothingDetails
								? classes.dividerRight
								: ""
						} ${classes.detailsBox}`}
					>
						<Typography className={classes.boldText}>
							Direction
						</Typography>
						<DetailsWithIcons
							details={data.directionConfidences}
							icons={DIRECTION_ICONS}
							filter={filter}
							findByKey="directions"
						/>
					</Box>
				)}
				{data.vehicleDetails && (
					<Box
						className={`${classes.detailsBox} ${
							data.clothingDetails || data.ageGroupDetails
								? classes.dividerRight
								: ""
						}`}
						style={{ flex: 2 }}
					>
						<Typography className={classes.boldText}>
							Vehicle Details
						</Typography>
						{data.vehicleDetails.models.length > 0 && (
							<Typography
								style={{
									fontWeight: 500,
								}}
							>
								Models:
							</Typography>
						)}
						{data.vehicleDetails.models.map((item) => (
							<Box
								className={classes.makeModel}
								key={item.confidence}
							>
								<DetailItem
									wrap
									value={`${
										item.makeModel?.[0]?.make ??
										item.makeModel?.[0]?.name
									} (${percentage(item.confidence)}%) - ${
										item.makeModel?.[0]?.model ??
										item.makeModel?.[0]?.value
									}`}
								/>
								{item.makeModel
									?.slice(1)
									?.map((makeModel, i) => (
										<DetailItem
											wrap
											key={i}
											value={`${
												makeModel.make ?? makeModel.name
											} - ${
												makeModel.model ??
												makeModel.value
											}`}
											rootStyle={{
												paddingLeft: 20,
											}}
										/>
									))}
							</Box>
						))}
					</Box>
				)}
				{data.ageGroupDetails && (
					<Box
						className={`${classes.detailsBox} ${
							data.clothingDetails ? classes.dividerRight : ""
						}`}
					>
						<Typography className={classes.boldText}>
							Age Group
						</Typography>
						{Object.keys(data.ageGroupDetails)
							.sort(
								(a, b) =>
									data.ageGroupDetails[b] -
									data.ageGroupDetails[a],
							)
							.map((keyName: string, i) => {
								const filterConfidence =
									filter?.moreFilters?.ageGroup?.confidence;
								const isConfidenceLow =
									data.ageGroupDetails[keyName] * 100 <
										(filterConfidence ??
											CONFIDENCE.ageGroup) ||
									data.ageGroupDetails[keyName] * 100 > 100;
								return (
									<DetailItem
										rootStyle={{
											opacity: isConfidenceLow ? 0.5 : 1,
										}}
										key={i}
										title={upperFirst(
											keyName.replace("Confidence", ""),
										)}
										value={`${percentage(
											data.ageGroupDetails[keyName],
										)}%`}
									/>
								);
							})}
					</Box>
				)}
				{data.clothingDetails && (
					<Box className={classes.detailsBox}>
						<Typography className={classes.boldText}>
							Clothing Details
						</Typography>
						<DetailItem
							title={
								data.clothingDetails.gender.value === 0
									? "Male"
									: "Female"
							}
							value={`${data.clothingDetails.gender.confidence}%`}
						/>
						{data.clothingDetails.values?.map(
							(
								item: {
									name: string;
									confidence: number;
								},
								i: number,
							) => (
								<DetailItem
									key={i}
									title={upperFirst(item.name)}
									value={`${percentage(item.confidence)}%`}
								/>
							),
						)}
						{Object.keys(data.clothingDetails).map(
							(keyName: string, i) => {
								if (
									keyName === "values" ||
									keyName === "gender"
								)
									return null;

								return (
									<DetailItem
										key={i}
										title={upperFirst(keyName)}
										value={`${
											data.clothingDetails?.[keyName]
												?.name
										} ${percentage(
											data.clothingDetails?.[keyName]
												?.confidence,
										)}%`}
									/>
								);
							},
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
};

const DetailsWithIcons: React.FC<{
	details: { [key: string]: number };
	icons: { [key: string]: string };
	filter?: EventsFilter;
	findByKey?: string;
}> = ({ details, icons, filter, findByKey }) => {
	const classes = useStyles();
	const keys = Object.keys(details) as Array<keyof typeof details>;
	keys.sort((a, b) => details[b] - details[a]);

	return (
		<>
			{keys.map((keyName, i) => {
				const iconKey =
					typeof keyName === "string"
						? keyName.toUpperCase()
						: keyName;
				if (!icons[iconKey]) return null;

				const filterConfidence =
					filter?.moreFilters?.[findByKey ?? ""]?.confidence;

				const isConfidenceLow =
					details[keyName] * 100 <
						(filterConfidence ?? CONFIDENCE[findByKey ?? ""]) ||
					details[keyName] * 100 > 100;

				return (
					<Box
						className={classes.attribute}
						key={i}
						style={{
							opacity: isConfidenceLow ? 0.5 : 1,
						}}
					>
						<Tooltip
							title={camelCaseToReadable(String(keyName))}
							PopperProps={{
								disablePortal: true,
							}}
						>
							<img
								src={icons[iconKey]}
								className={classes.icons}
								alt={`${icons[iconKey]}`}
							/>
						</Tooltip>
						<Typography>{`${percentage(
							details[keyName],
						)}%`}</Typography>
					</Box>
				);
			})}
		</>
	);
};
