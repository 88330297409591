import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import { IValuedProperty } from "../../store/Properties/types";
import List from "./List";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { PRIORITY_COUNTRIES } from "./constants";
import { THEME } from "../../config";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	controller: {
		paddingRight: 5,
		display: "flex",
		gap: 5,
	},
	value: {
		paddingLeft: 5,
	},
	friendlyName: {
		marginTop: 5.5,
	},
	button: {
		height: 32,
	},
	listWrapper: {
		height: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 14,
	},
	listControls: {
		display: "flex",
		flexDirection: "column",
	},
	dialog: {
		backgroundColor: THEME.palette.grey[200],
		maxWidth: "40vw",
		height: "70vh",
		"@media (max-width: 1400px)": {
			maxWidth: "60vw",
		},
		"@media (max-width: 1000px)": {
			maxWidth: "80vw",
		},
	},
}));

interface IPropertyGroupItemProps {
	property: IValuedProperty;
	onUpdate(value: string, key: string): void;
	setDisabled(disabled: boolean): void;
	saveDisabled: boolean;
	inputDisabled: boolean;
}

export type TList = Record<"selected" | "available", string[]>;

const CountriesControl = ({
	property,
	onUpdate,
	setDisabled,
	saveDisabled,
	inputDisabled,
}: IPropertyGroupItemProps) => {
	const classes = useStyles();
	const [error, setError] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [countriesDialogOpen, setCountriesDialogOpen] = useState(false);
	const [countries, setCountries] = useState<TList>({
		selected: [],
		available: [],
	});

	const [checked, setChecked] = useState<TList>({
		selected: [],
		available: [],
	});
	const filteredCountries = useMemo(
		() => ({
			selected: countries.selected,
			available: countries.available
				.filter((c) =>
					c.toLowerCase().includes(searchValue.toLowerCase()),
				)
				.sort(),
		}),
		[countries, searchValue],
	);
	const handleToggle = (country: string, list: keyof TList) => {
		if (checked[list].includes(country)) {
			setChecked({
				...checked,
				[list]: checked[list].filter((c) => c !== country),
			});
		} else {
			setChecked({
				...checked,
				[list]: [...checked[list], country],
			});
		}
	};

	const handleAllToggle = (list: keyof TList) => {
		if (checked[list].length === countries[list].length) {
			setChecked((prev) => ({ ...prev, [list]: [] }));
		} else {
			setChecked((prev) => ({
				...prev,
				[list]: [...countries[list]],
			}));
		}
	};

	const moveChecked = (fromList: keyof TList) => {
		const toList = fromList === "selected" ? "available" : "selected";
		setCountries((prev) => {
			prev[fromList] = countries[fromList].filter(
				(c) => !checked[fromList].includes(c),
			);
			prev[toList] = [...countries[toList], ...checked[fromList]];
			return { ...prev };
		});

		setChecked({
			...checked,
			[fromList]: [],
		});
		const indicatorsArr = countries.selected.map(
			(country) => country.split(" ")[0],
		);
		onUpdate(indicatorsArr.join(" "), property.key);
		recheckCountries(indicatorsArr.join(" "));
		setError("");
	};

	const onSelectedSort = (array: TList["selected"]) => {
		setCountries({ ...countries, selected: array });
		const indicatorsArr = array.map((country) => country.split(" ")[0]);
		onUpdate(indicatorsArr.join(" "), property.key);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const recheckCountries = useCallback((value: string) => {
		const selected = value
			?.split(" ")
			.filter(Boolean)
			.map(
				(country) =>
					PRIORITY_COUNTRIES.find(
						(c) => c.split(" ")[0] === country,
					) || "",
			)
			.filter(Boolean);

		const available = PRIORITY_COUNTRIES.filter(
			(country) => !selected.some((c) => country.startsWith(c)),
		);
		setCountries({ selected, available });
		setChecked({ selected: [], available: [] });
	}, []);

	const handleBlur = useCallback(
		(value: string) => {
			const indicatorsArr = value.trim().split(" ");
			const allIndicators = indicatorsArr
				.map((indicator) => indicator?.toUpperCase())
				.filter(Boolean);

			const validIndicators = indicatorsArr
				.filter(
					(indicator) =>
						PRIORITY_COUNTRIES.some(
							(country) =>
								country.split(" ")[0].toUpperCase() ===
								indicator.toUpperCase(),
						) && indicator,
				)
				.map((indicator) => indicator?.toUpperCase());
			if (allIndicators.length !== validIndicators.length) {
				const invalidIndicators = allIndicators.filter(
					(indicator) =>
						!validIndicators.some(
							(validIndicator) => validIndicator === indicator,
						),
				);
				setError(
					`Invalid country code: ${invalidIndicators.join(", ")}`,
				);
			} else {
				setError("");
			}
			recheckCountries(validIndicators.join(" "));
			onUpdate(allIndicators.join(" "), property.key);
		},
		[onUpdate, recheckCountries, property.key],
	);

	useEffect(() => {
		if (
			property.key === "ObjectTracker.LicensePlatePriorityCountries" &&
			!countries.available.length &&
			!countries.selected.length &&
			!searchValue
		) {
			recheckCountries(property.value);
		}
	}, [property, countries, searchValue, recheckCountries, handleBlur]);

	useEffect(() => {
		if (!saveDisabled) {
			setError("");
		}
	}, [saveDisabled]);

	useEffect(() => {
		setDisabled(!!error);
	}, [error, setDisabled]);

	useEffect(() => {
		if (inputDisabled) {
			setError("");
			setDisabled(false);
			const indicatorsArr = property.value.trim().split(" ");
			const validIndicators = indicatorsArr
				.filter(
					(indicator) =>
						PRIORITY_COUNTRIES.some(
							(country) =>
								country.split(" ")[0].toUpperCase() ===
								indicator.toUpperCase(),
						) && indicator,
				)
				.map((indicator) => indicator?.toUpperCase());

			recheckCountries(validIndicators.join(" "));
			onUpdate(validIndicators.join(" "), property.key);
		} else {
			handleBlur(property.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputDisabled, setDisabled]);

	return (
		<>
			<Tooltip
				title="Enter 3 letter country codes"
				placement="left"
				arrow
			>
				<TextField
					fullWidth
					value={property.value}
					onChange={(e) => onUpdate(e.target.value, property.key)}
					onBlur={(e) => handleBlur(e.target.value)}
					error={!!error}
					helperText={error}
					disabled={inputDisabled}
				/>
			</Tooltip>
			<Button
				variant="contained"
				color="secondary"
				className={classes.button}
				onClick={() => setCountriesDialogOpen(!countriesDialogOpen)}
				disabled={inputDisabled}
			>
				Edit
			</Button>
			<Dialog
				open={countriesDialogOpen}
				onClose={() => setCountriesDialogOpen(false)}
				classes={{ paper: classes.dialog }}
			>
				<DialogTitle style={{ paddingBottom: 0 }}>
					Select Priority Countries
					<Typography
						variant="body2"
						style={{ color: THEME.palette.grey[700] }}
					>
						Select countries for license plate template matching.
						Recommended to set countries which are commonly occuring
						in system deployment location in order of decreasing
						occurence probability. You can do it by dragging them in
						needed order.
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Box className={classes.listWrapper}>
						<List
							countries={filteredCountries.selected}
							totalLength={countries.selected.length}
							checked={checked.selected}
							handleToogle={(country) =>
								handleToggle(country, "selected")
							}
							handleToogleAll={() => handleAllToggle("selected")}
							sortCountries={onSelectedSort}
							listName="Selected"
							draggable
						/>
						<Box className={classes.listControls}>
							<IconButton
								disabled={!checked.selected.length}
								color={
									!checked.selected.length
										? "default"
										: "secondary"
								}
								onClick={() => moveChecked("selected")}
							>
								<ArrowForwardIosOutlinedIcon fontSize="small" />
							</IconButton>
							<IconButton
								disabled={!checked.available.length}
								color={
									!checked.available.length
										? "default"
										: "secondary"
								}
								onClick={() => moveChecked("available")}
							>
								<ArrowForwardIosOutlinedIcon
									fontSize="small"
									style={{
										transform: "rotate(180deg)",
									}}
								/>
							</IconButton>
						</Box>
						<List
							countries={filteredCountries.available}
							totalLength={countries.available.length}
							checked={checked.available}
							listName="Supported"
							handleSearch={handleSearch}
							searchValue={searchValue}
							handleToogleAll={() => handleAllToggle("available")}
							handleToogle={(country) =>
								handleToggle(country, "available")
							}
						/>
					</Box>
				</DialogContent>
				<DialogActions style={{ paddingRight: 14 }}>
					<Button
						variant="text"
						color="secondary"
						onClick={() => setCountriesDialogOpen(false)}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CountriesControl;
