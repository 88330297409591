import { IPropertiesState, IConfigurationProperty } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { PropertiesAction } from "./action";

export const initialPropertiesState: IPropertiesState = {
	status: AsyncActionStatus.UNSTARTED,
	data: [],
	changedProperties: {},
	keys: {},
	requestStatus: {},
};

export const propertiesReducer: Reducer<IPropertiesState, any> = (
	state = initialPropertiesState,
	action: PropertiesAction,
) => {
	switch (action.type) {
		case "Properties": {
			return handleProperties(state, action);
		}
		case "Get_Properties": {
			return handleChangedProperties(state, action);
		}
		default:
			return state;
	}
};

const handleChangedProperties = (
	state: IPropertiesState = initialPropertiesState,
	action: PropertiesAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			if (action.meta) {
				const nState = { ...state };
				const payload = action.payload as IConfigurationProperty[];
				const vmsName = action.meta;
				nState.changedProperties[vmsName] = payload;
				return nState;
			}
	}
	return state;
};

const handleProperties = (
	state: IPropertiesState = initialPropertiesState,
	action: PropertiesAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			const payload = action.payload as IConfigurationProperty[];
			nState.data = payload;
			return nState;
	}
	return state;
};
