import { AsyncAction, async } from "../AsyncState";
import { IRecord, IRecordsRequest, IAddRecordBody } from "./types";
import { IResponse, PagedContent } from "../types";
import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { IQuerySecret } from "../Watchlists/types";

export const Records = "RECORDS";

export type RecordsAction = AsyncAction<
	typeof Records,
	PagedContent<IRecord[]> | IRecord,
	string
>;

export const getRecords = async (
	watchlistId: string,
	subjectId: string,
	request: IRecordsRequest,
	controller?: AbortController,
) => {
	const response = await Axios.get(
		API_BASE_PREFIX +
			`watchlists/items/${watchlistId}/items/${subjectId}/items`,
		{ params: request, signal: controller?.signal },
	);
	return { ...response.data, watchlistId };
};

export const addRecord = async (
	watchlistId: string,
	subjectId: string,
	body: IAddRecordBody,
	query: IQuerySecret,
) => {
	const response = await Axios.post<IResponse>(
		API_BASE_PREFIX + `watchlists/items/${watchlistId}/items/${subjectId}`,
		body,
		{ params: query },
	);
	return response.data;
};

export const deleteRecord = async (
	watchlistId: string,
	subjectId: string,
	index: number,
	query: IQuerySecret,
) => {
	const response = await Axios.delete<IResponse>(
		API_BASE_PREFIX +
			`watchlists/items/${watchlistId}/items/${subjectId}/${index}`,
		{ params: query },
	);
	return response.data;
};

export const getRecord = async (
	watchlistId: string,
	subjectId: string,
	index: number,
	query: IQuerySecret,
) => {
	const response = await Axios.get(
		API_BASE_PREFIX +
			`watchlists/items/${watchlistId}/items/${subjectId}/${index}`,
		{ params: query },
	);
	return { ...response.data, watchlistId };
};

export function getRecordsAction(
	watchlistId: string,
	subjectId: string,
	request: IRecordsRequest,
	controller?: AbortController,
) {
	return async(
		Records,
		getRecords,
		subjectId,
		watchlistId,
		subjectId,
		request,
		controller,
	);
}
