import { ForwardedRef, useRef, useEffect } from "react";

const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue: any = null) => {
	const targetRef = useRef<T>(initialValue);

	useEffect(() => {
		if (!ref) return;

		if (typeof ref === "function") {
			ref(targetRef.current);
		} else {
			targetRef.current = ref.current as T;
		}
	}, [ref]);

	return targetRef;
};

export default useForwardRef;
