import React from "react";

export function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface ITabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const TabPanel: React.FC<ITabPanelProps> = ({
	index,
	value,
	children,
	...other
}) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`simmple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && <div>{children}</div>}
	</div>
);

export default TabPanel;
