import {
	Button,
	CircularProgress,
	Container,
	TextField,
	Typography,
	CssBaseline,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React, { SyntheticEvent } from "react";
import { withRouter } from "react-router";
import LOGO from "../assets/images/neurotechnology-retina.png";
import IPassword from "../components/InputForm/IPassword";
import PasswordField from "../components/InputForm/PasswordField";
import Alert, { IAlert } from "../components/UI/Alert";
import { THEME } from "../config";
import Authentication from "../store/Authentication/AuthenticationStore";

interface ILoginForm {
	username: string;
	password: string;
}

interface ILoginState {
	snackBarOpen: boolean;
	currentAlert: IAlert | null;
	form: ILoginForm;
	loading: boolean;
}
class LoginContainer extends React.Component<any, ILoginState> {
	constructor(props: any) {
		super(props);
		this.state = {
			snackBarOpen: false,
			currentAlert: null,
			form: {
				username: "",
				password: "",
			},
			loading: false,
		};
	}

	handleInputChange = (
		event: React.ChangeEvent<{
			name?: string;
			value: unknown;
		}>,
	) => {
		switch (event.target.name) {
			case "username": {
				const username = event.target.value as string;
				const form = { ...this.state.form, username };
				this.setState({ form });
				break;
			}
		}
	};

	handlePasswordChange = (password: IPassword) => {
		const form = { ...this.state.form, password: password.value };
		this.setState({ form });
	};

	handleLogin = (form: React.FormEvent<HTMLFormElement>) => {
		form.preventDefault();
		if (
			this.state.form.username !== "" &&
			this.state.form.password !== ""
		) {
			this.setState({ loading: true }, () => {
				Authentication.authenticate(
					this.state.form.username,
					this.state.form.password,
				)
					.then(() => {
						const { from } = this.props.location.state || {
							from: { pathname: "/" },
						};
						if (
							from.pathname === "/login" ||
							from.pathname === "/logout"
						)
							this.props.history.push("/vms");
						else this.props.history.push(from);
					})
					.catch((error) => {
						this.setState({ loading: false });
						const alert: IAlert = {
							message: error.message
								? error.message
								: ("Service unavailable" as string),
							variant: "error",
						};
						this.handleOpenSnackBar(alert);
					});
			});
		}
	};

	handleOpenSnackBar = (alert: IAlert) => {
		if (this.state.snackBarOpen) {
			this.handleSnackBarClose();
		}
		this.setState({ currentAlert: alert, snackBarOpen: true });
	};

	handleSnackBarClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ snackBarOpen: false });
	};

	render() {
		return (
			<ThemeProvider theme={THEME}>
				<CssBaseline>
					<div
						style={{
							display: "flex",
							justifyItems: "center",
							alignItems: "center",
							height: "calc(100% - 10%)",
						}}
					>
						<Container component="main" maxWidth="xs">
							<div>
								<img
									src={LOGO}
									style={{
										maxWidth: "95%",
									}}
									alt="logo"
								/>
								<Typography
									component="h1"
									variant="h3"
									style={{
										marginTop: "10px",
										textAlign: "center",
									}}
								>
									SentiVeillance
								</Typography>
								<Alert
									onClose={this.handleSnackBarClose}
									alert={this.state.currentAlert}
								></Alert>
								<form
									noValidate
									onSubmit={this.handleLogin}
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="username"
										label="Username"
										name="username"
										value={this.state.form.username}
										onChange={this.handleInputChange}
									/>
									<PasswordField
										style={{ margin: 0 }}
										id="password"
										name="password"
										label="Password"
										variant="outlined"
										required
										onPasswordChange={
											this.handlePasswordChange
										}
									/>

									<Button
										type="submit"
										style={{
											marginTop: "5px",
											height: "56px",
										}}
										fullWidth
										variant="contained"
										color="primary"
										disabled={this.state.loading}
									>
										Sign In
									</Button>
									<CircularProgress
										style={{
											marginTop: 8,
											visibility: this.state.loading
												? "visible"
												: "hidden",
										}}
									/>
								</form>
							</div>
						</Container>
					</div>
				</CssBaseline>
			</ThemeProvider>
		);
	}
}

export default withRouter(LoginContainer);
