import React from "react";
import { Grid, DialogTitle, Button, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
	button: {
		marginTop: 10,
		marginRight: 10,
	},
}));

interface ITitleProps {
	title: string;
	onClose?: () => void;
}

const Title: React.FC<ITitleProps> = ({ title, onClose }) => {
	const classes = useStyles();
	return (
		<Grid container justifyContent="space-between">
			<Grid item>
				<DialogTitle style={{ paddingBottom: 0 }}>{title}</DialogTitle>
			</Grid>
			{onClose && (
				<Grid item>
					<Button
						onClick={() => onClose()}
						className={classes.button}
					>
						<CloseIcon />
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default Title;
