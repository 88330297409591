import React, { useEffect } from "react";
import {
	SnackbarContent,
	IconButton,
	Theme,
	makeStyles,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import { green, amber } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
}));

export const variants = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

export interface IAlert {
	message: string;
	variant: keyof typeof variants;
}

interface IAlertProps {
	alert: IAlert | null;
	onClose?(): void;
}

const Alert: React.FC<IAlertProps> = ({ alert, onClose }) => {
	const classes = useStyles();
	const [isClosed, setIsClosed] = React.useState<boolean>(true);

	useEffect(() => {
		setIsClosed(false);
	}, [alert]);

	const close = () => {
		setIsClosed(true);
		if (onClose) {
			onClose();
		}
	};

	if (alert === null || isClosed) {
		return null;
	}

	return (
		<SnackbarContent
			style={{ margin: "15px 0px" }}
			className={classes[alert.variant]}
			message={alert.message}
			action={[
				<IconButton
					key="close"
					aria-label="close"
					color="inherit"
					onClick={close}
				>
					<Close className={classes.icon} />
				</IconButton>,
			]}
		/>
	);
};

export default Alert;
