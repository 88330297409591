import React from "react";
import {
	Grid,
	IconButton,
	MenuItem,
	Select,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { IRequest, PagedContent } from "../../store/types";
import { ArrowBackIos } from "@material-ui/icons/";
import { useWindowWidth } from "../../helpers/Hooks";

const useStyles = makeStyles(() => ({
	label: {
		paddingTop: 6,
	},
	select: {
		marginLeft: 3,
		"&:before": {
			borderBottom: "0",
		},
		"&:after": {
			borderBottom: "0",
		},
	},
}));

type PageValues = {
	count: number;
	from: number;
	to: number;
};

interface IPaginationProps {
	container: PagedContent<any[]>;
	filter: IRequest;
	defaultRows?: number;
	rowsPerPageOptions: number[];
	onChangePage(newPage: number): void;
	onChangeRowsPerPage(newSize: number): void;
}

const getValues = (
	container: PagedContent<any[]>,
	filter: IRequest,
	defaultRows: number = 10,
): PageValues => {
	const count = container ? container.totalElements : 0;
	const page = filter ? filter.page || 0 : 0;
	const rowsPerPage = filter ? filter.size || defaultRows : defaultRows;
	return {
		from: count === 0 ? 0 : page * rowsPerPage + 1,
		to:
			count !== -1
				? Math.min(count, (page + 1) * rowsPerPage)
				: (page + 1) * rowsPerPage,
		count: count === -1 ? -1 : count,
	};
};

const labelDisplayRows = (page: PageValues) =>
	`${page.from}-${page.to} of ${
		page.count !== -1 ? page.count : `more than ${page.to}`
	}`;

const Pagination: React.FC<IPaginationProps> = ({
	container,
	filter,
	rowsPerPageOptions,
	onChangePage,
	onChangeRowsPerPage,
	defaultRows,
}) => {
	const classes = useStyles();
	const [width] = useWindowWidth();

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		onChangeRowsPerPage(parseInt(event.target.value as string, 10));
	};

	const { from, to, count } = getValues(container, filter, defaultRows);

	const isRightArrowDisabled = (filter: IRequest) => {
		if (filter && filter.size && (filter.page || filter.page === 0)) {
			return filter.page >= Math.ceil(count / filter.size) - 1;
		}
		return true;
	};

	return (
		<Grid container>
			<Grid container item xs={12} xl={10} justifyContent="flex-end">
				<Grid item>
					<Typography variant="body2" className={classes.label}>
						{width >= 944 && width < 1055
							? "Rows"
							: "Rows per page"}
					</Typography>
				</Grid>
				<Grid item>
					<Select
						value={filter ? filter.size || 10 : 10}
						onChange={handleChangeRowsPerPage}
						className={classes.select}
					>
						{rowsPerPageOptions.map((op, i) => (
							<MenuItem key={i} value={op}>
								{op}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item>
					<Typography variant="body2" className={classes.label}>
						{labelDisplayRows({ from, to, count })}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} xl={2} justifyContent="flex-end">
				<IconButton
					aria-label="page-down"
					disabled={filter.page === 0}
					size="small"
					onClick={() =>
						onChangePage(
							filter && filter.page ? filter.page - 1 : 0,
						)
					}
				>
					<ArrowBackIos />
				</IconButton>
				<IconButton
					aria-label="page-up"
					size="small"
					disabled={isRightArrowDisabled(filter)}
					onClick={() =>
						onChangePage(
							filter && (filter.page || filter.page === 0)
								? filter.page + 1
								: 0,
						)
					}
				>
					<ArrowBackIos style={{ transform: "rotate(0.5turn)" }} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default Pagination;
