import ClientOauth2 from "client-oauth2";

class TokenRefreshHook {
	timeoutId: any = -1;

	delay(ms: number) {
		return new Promise((resolve) => {
			this.timeoutId = setTimeout(resolve, ms);
		});
	}

	async refreshToken(token: ClientOauth2.Token) {
		try {
			await this.delay((+token.data.expires_in - 60) * 1000);
			const newToken = await token.refresh();
			return newToken;
		} catch (error) {
			//Fail silently and force user to reenter login details
			return null;
		}
	}

	destroy() {
		clearTimeout(this.timeoutId);
	}
}
export default TokenRefreshHook;
