import React, { useState } from "react";
import {
	makeStyles,
	Chip,
	Menu,
	MenuItem,
	Tooltip,
	Grid,
	Typography,
	Divider,
} from "@material-ui/core";
import {
	AddCircleOutline,
	DateRange,
	FilterNone,
	Money,
	Videocam,
	SupervisorAccount,
	Wc,
	Schedule,
	Filter7,
	Today,
	ColorLens,
	LocalShipping,
	Directions,
} from "@material-ui/icons/";
import { Trigger } from "../../../../store/Rules/types";
import { THEME } from "../../../../config/index";
import { AgeTriggerPopover } from "./Faces/AgeTrigger";
import { GenderTriggerPopover } from "./Faces/GenderTrigger";
import { TimeTriggerPopover } from "./Common/TimeTrigger";
import { DateTriggerPopover } from "./Common/DateTrigger";
import { ThresholdTriggerPopover } from "./Faces/ThresholdTrigger";
import { IWatchlist } from "../../../../store/Watchlists/types";
import { ISource } from "../../../../store/Sources/types";
import { WatchlistTriggerPopover } from "./Common/WatchlistTrigger";
import { SourceTriggerPopover } from "./Common/SourceTrigger";
import {
	DarkGlassesIcon,
	GlassesIcon,
	MustacheIcon,
	BeardIcon,
	MaskIcon,
	LicensePlateIcon,
} from "../../../Icons/Icons";
import { ITriggerDropdownGroup } from "../../../../store/Rules/types";
import { VehicleHumanTriggerPopover } from "./VehicleHuman/VehicleHumanTrigger";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
	},
	groupName: {
		fontWeight: "bold",
		color: "black",
		paddingLeft: 16,
	},
	menu: {
		display: "grid",
		gridTemplateColumns: "45% 27.5% 27.5%",
	},
	menuColumn: {
		display: "block",
	},
}));

const triggerGroups: ITriggerDropdownGroup[] = [
	{
		name: "Common",
		items: [
			{
				name: "Multi Trigger",
				tooltip: "Create sub group for more advanced logic",
				icon: <FilterNone />,
			},
			{
				name: "Date",
				tooltip: "Trigger by specified date range",
				icon: <DateRange />,
			},
			{
				name: "Time",
				tooltip: "Trigger by event time",
				icon: <Schedule />,
			},
			{
				name: "Day of Week",
				tooltip: "Trigger only on some week days",
				icon: <Filter7 />,
			},
			{
				name: "Month and Day",
				tooltip: "Trigger by specified month/day for every year",
				icon: <Today />,
			},
			{
				name: "Camera",
				tooltip: "Only triggers if from selected camera",
				icon: <Videocam />,
			},
			{
				name: "Faces Watchlist",
				tooltip:
					"Trigger if from specified watchlist, in any watchlist or in none",
				icon: <SupervisorAccount />,
			},
			{
				name: "License Plates Watchlist",
				tooltip:
					"Trigger if from specified watchlist, in any watchlist or in none",
				icon: <LicensePlateIcon />,
			},
		],
	},
	{
		name: "Faces",
		items: [
			{
				name: "Age",
				tooltip: "Trigger by specified age value",
				icon: <Money />,
			},
			{
				name: "Gender",
				tooltip: "Trigger by specified gender and confidence",
				icon: <Wc />,
			},
			{
				name: "Beard",
				tooltip: "Trigger only if person is with/without beard",
				icon: <BeardIcon />,
			},
			{
				name: "Mustache",
				tooltip: "Trigger only if person is with/without mustache",
				icon: <MustacheIcon />,
			},
			{
				name: "Glasses",
				tooltip: "Trigger only if person is with/without glasses",
				icon: <GlassesIcon />,
			},
			{
				name: "Dark Glasses",
				tooltip: "Trigger only if person is with/without dark glasses",
				icon: <DarkGlassesIcon />,
			},
			{
				name: "Mask",
				tooltip: "Trigger only if person is with/without mask",
				icon: <MaskIcon />,
			},
		],
	},
	{
		name: "Vehicle-Human",
		items: [
			{
				name: "Type",
				tooltip: "Trigger only if the object is of selected type",
				icon: <LocalShipping />,
			},
			{
				name: "Color",
				tooltip: "Trigger only if object is of declared color",
				icon: <ColorLens />,
			},
			{
				name: "Direction",
				tooltip: "Trigger only if object moves in declared direction",
				icon: <Directions />,
			},
		],
	},
];

interface IAddTriggerChipProps {
	addTrigger(trigger: Trigger): void;
	watchlists: { [key: string]: IWatchlist };
	sources: ISource[];
	multiOp: string;
}

const AddTriggerChip: React.FC<IAddTriggerChipProps> = ({
	addTrigger,
	watchlists,
	sources,
	multiOp,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [ageTriggerAnchor, setAgeTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [genderTriggerAnchor, setGenderTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [timeTriggerAnchor, setTimeTriggerAnchor] =
		React.useState<null | HTMLElement>(null);
	const [timeTriggerOption, setTimeTriggerOption] = useState("");
	const [dateTriggerAnchor, setDateTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [ThresholdTriggerAnchor, setThresholdTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [ThresholdTriggerOption, setThresholdTriggerOption] = useState("");
	const [watchlistTriggerAnchor, setWatchlistTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [sourceTriggerAnchor, setSourceTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [vehicleHumanTriggerAnchor, setVehicleHumanTriggerAnchor] =
		useState<null | HTMLElement>(null);
	const [vehicleHumanTriggerOption, setVehicleHumanTriggerOption] = useState<
		"DirectionTrigger" | "TypeTrigger" | "ColorTrigger" | null
	>(null);
	const [watchlistTriggerOption, setWatchlistTriggerOption] = useState<
		"Faces" | "License Plates" | null
	>(null);

	const classes = useStyles();

	const handleMenuClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleOpenTriggerCreate = (choice: string) => {
		handleMenuClose();
		switch (choice) {
			case "Age": {
				setAgeTriggerAnchor(anchorEl);
				break;
			}
			case "Gender": {
				setGenderTriggerAnchor(anchorEl);
				break;
			}
			case "Time": {
				setTimeTriggerOption("TimeOfDay");
				setTimeTriggerAnchor(anchorEl);
				break;
			}
			case "Day of Week": {
				setTimeTriggerOption("DayOfWeek");
				setTimeTriggerAnchor(anchorEl);
				break;
			}
			case "Month and Day": {
				setTimeTriggerOption("MonthAndDay");
				setTimeTriggerAnchor(anchorEl);
				break;
			}
			case "Date": {
				setDateTriggerAnchor(anchorEl);
				break;
			}
			case "Beard": {
				setThresholdTriggerOption("Beard");
				setThresholdTriggerAnchor(anchorEl);
				break;
			}
			case "Mustache": {
				setThresholdTriggerOption("Mustache");
				setThresholdTriggerAnchor(anchorEl);
				break;
			}
			case "Glasses": {
				setThresholdTriggerOption("Glasses");
				setThresholdTriggerAnchor(anchorEl);
				break;
			}
			case "Dark Glasses": {
				setThresholdTriggerOption("DarkGlasses");
				setThresholdTriggerAnchor(anchorEl);
				break;
			}
			case "Mask": {
				setThresholdTriggerOption("Mask");
				setThresholdTriggerAnchor(anchorEl);
				break;
			}
			case "Faces Watchlist": {
				setWatchlistTriggerOption("Faces");
				setWatchlistTriggerAnchor(anchorEl);
				break;
			}
			case "License Plates Watchlist": {
				setWatchlistTriggerOption("License Plates");
				setWatchlistTriggerAnchor(anchorEl);
				break;
			}
			case "Camera": {
				setSourceTriggerAnchor(anchorEl);
				break;
			}
			case "Multi Trigger": {
				handleCreateTrigger({
					type: "MultiTrigger",
					operator: multiOp,
					triggers: [],
				});
				break;
			}
			case "Type": {
				setVehicleHumanTriggerOption("TypeTrigger");
				setVehicleHumanTriggerAnchor(anchorEl);
				break;
			}
			case "Color": {
				setVehicleHumanTriggerOption("ColorTrigger");
				setVehicleHumanTriggerAnchor(anchorEl);
				break;
			}
			case "Direction": {
				setVehicleHumanTriggerOption("DirectionTrigger");
				setVehicleHumanTriggerAnchor(anchorEl);
				break;
			}
		}
	};

	const handleCreateTrigger = (trigger: Trigger) => {
		addTrigger(trigger);
	};

	const handleCancelTimeTrigger = () => {
		setTimeTriggerOption("");
		setTimeTriggerAnchor(null);
	};

	const handleCancelThresholdTrigger = () => {
		setThresholdTriggerOption("");
		setThresholdTriggerAnchor(null);
	};

	const handleCancelVehicleHumanTrigger = () => {
		setVehicleHumanTriggerOption(null);
		setVehicleHumanTriggerAnchor(null);
	};

	const handleCancelWatchlistTrigger = () => {
		setWatchlistTriggerOption(null);
		setWatchlistTriggerAnchor(null);
	};

	return (
		<>
			<Chip
				icon={<AddCircleOutline />}
				label="Add"
				className={classes.chip}
				onClick={handleMenuClick}
				color="primary"
			/>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<Grid container className={classes.menu}>
					{triggerGroups.map((group) => (
						<Grid item key={group.name}>
							<Typography className={classes.groupName}>
								{group.name}
							</Typography>
							<Divider variant="middle" />
							{group.items.map((item) => (
								<Tooltip key={item.name} title={item.tooltip}>
									<MenuItem
										onClick={() =>
											handleOpenTriggerCreate(item.name)
										}
									>
										{item.icon} {item.name}
									</MenuItem>
								</Tooltip>
							))}
						</Grid>
					))}
				</Grid>
			</Menu>
			<AgeTriggerPopover
				anchorEl={ageTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={() => setAgeTriggerAnchor(null)}
			/>
			<GenderTriggerPopover
				anchorEl={genderTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={() => setGenderTriggerAnchor(null)}
			/>
			<TimeTriggerPopover
				anchorEl={timeTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={handleCancelTimeTrigger}
				triggerOption={timeTriggerOption}
			/>
			<DateTriggerPopover
				anchorEl={dateTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={() => setDateTriggerAnchor(null)}
			/>
			<ThresholdTriggerPopover
				anchorEl={ThresholdTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={handleCancelThresholdTrigger}
				option={ThresholdTriggerOption}
			/>
			{watchlistTriggerOption ? (
				<WatchlistTriggerPopover
					anchorEl={watchlistTriggerAnchor}
					onUpdate={handleCreateTrigger}
					onCancel={handleCancelWatchlistTrigger}
					watchlists={watchlists}
					option={watchlistTriggerOption}
				/>
			) : null}
			<SourceTriggerPopover
				anchorEl={sourceTriggerAnchor}
				onUpdate={handleCreateTrigger}
				onCancel={() => setSourceTriggerAnchor(null)}
				sources={sources}
			/>
			{vehicleHumanTriggerOption ? (
				<VehicleHumanTriggerPopover
					anchorEl={vehicleHumanTriggerAnchor}
					onUpdate={handleCreateTrigger}
					onCancel={handleCancelVehicleHumanTrigger}
					option={vehicleHumanTriggerOption}
				/>
			) : null}
		</>
	);
};

export default AddTriggerChip;
