import React, { useCallback, useMemo, useState } from "react";
import { Divider, Snackbar, Tooltip, TextField, Chip } from "@material-ui/core";
import { HttpError } from "../../config/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import Alert from "./Alert";
import { Autocomplete } from "@material-ui/lab";
import { getSourceAction } from "../../store/Sources/action";
import {
	ISource,
	ISourceRequestInfo,
	ISourceState,
} from "../../store/Sources/types";
import { IVMSState } from "../../store/VMS/types";
import { debounce } from "lodash";

interface ISourcesViewPropsToState {
	sources: ISource[];
	error?: HttpError;
	sourcesState: ISourceState;
	vmsState: IVMSState;
}

interface ISourcesViewPropsToDispatch {
	loadSources(vmsName: string, request?: ISourceRequestInfo): void;
}

interface ISourcesFormSelectOwnProps {
	value: Partial<ISource>[] | undefined;
	vmsName: string | undefined;
	label?: string;
	tooltipTitle?: string;
	onSource(source: Partial<ISource>[] | undefined): void;
	defaultToAll?: boolean;
}

interface ISourcesFormSelectProps
	extends ISourcesViewPropsToState,
		ISourcesFormSelectOwnProps,
		ISourcesViewPropsToDispatch {
	className?: string;
}

const FIXED_VALUES: Partial<ISource>[] = [
	{
		id: "all",
		displayName: "All",
		vmsName: "",
	},
];

const _SourcesFormSelect: React.FC<ISourcesFormSelectProps> = ({
	sources,
	vmsName,
	value,
	error,
	loadSources,
	tooltipTitle,
	onSource,
	className,
	sourcesState,
	vmsState,
	label,
	defaultToAll = false,
}) => {
	const [search, setSearch] = useState("");
	const sourcesWithVmsName = useMemo(
		() =>
			Object.keys(sourcesState.keys)
				.reduce((acc: ISource[], vmsName: string) => {
					if (sourcesState.keys[vmsName]) {
						return acc.concat(
							sourcesState.keys[vmsName].content.map((s) => ({
								...s,
								vmsName,
							})),
						);
					}
					return acc;
				}, [])
				.sort((a, b) => {
					if (a.vmsName && b.vmsName) {
						if (a.vmsName < b.vmsName) return -1;
						if (a.vmsName > b.vmsName) return 1;
					}
					return 0;
				}),
		[sourcesState],
	);

	const debouncedSearch = React.useRef(
		debounce(
			(
				searchValue: string,
				vmsKeysArr: string[],
				sourcesArr: ISource[],
			) => {
				vmsKeysArr.forEach((vms) => {
					if (
						sourcesArr.some(
							(s) =>
								vms === s.vmsName &&
								s.displayName
									.toLowerCase()
									.includes(searchValue.toLowerCase()),
						)
					)
						return;
					loadSources(vms, { name: searchValue });
				});
			},
			700,
		),
	);

	const handleSources = useCallback(() => {
		if (
			vmsName &&
			sourcesState.keys[vmsName] &&
			sourcesState.keys[vmsName].content.length > 0
		) {
			return;
		}

		if (!sources.length) {
			Object.keys(vmsState.keys).forEach((vms) => {
				loadSources(vms);
			});
		}
	}, [loadSources, vmsName, vmsState, sourcesState.keys, sources.length]);

	React.useEffect(() => {
		handleSources();
	}, [handleSources]);

	React.useEffect(() => {
		if (search) {
			debouncedSearch.current(
				search,
				Object.keys(vmsState.keys),
				sourcesWithVmsName,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, vmsState.keys]);

	const handleSourceInput = (
		_event: React.ChangeEvent<{}>,
		value: Partial<ISource>[] | null,
	) => {
		if (value !== null) {
			if (value.at(-1)?.id === "all") return onSource(undefined);
			onSource(
				value
					.filter((v) => !FIXED_VALUES.some((f) => f.id === v.id))
					.map((v) => ({
						id: v.id,
						displayName: v.displayName,
						vmsName: v.vmsName,
					})),
			);
		} else {
			onSource(undefined);
		}
	};

	return (
		<>
			<Autocomplete
				noOptionsText={"No Sources found."}
				options={
					defaultToAll
						? [...FIXED_VALUES, ...sourcesWithVmsName]
						: sourcesWithVmsName
				}
				multiple
				disableCloseOnSelect
				getOptionSelected={(option, value) =>
					option.id === value.id && option.vmsName === value.vmsName
				}
				getOptionLabel={(option) => option.displayName ?? ""}
				inputValue={search}
				groupBy={(option) => option.vmsName ?? "No VMS"}
				renderOption={(s) => (
					<div
						style={{
							display: "inline",
							maxHeight: 300,
						}}
					>
						<b>{s.displayName}</b>
						<br />
						<Divider />
					</div>
				)}
				renderTags={(values, getTagProps) =>
					values.map((src, index) => (
						<Chip
							{...getTagProps({ index })}
							label={
								src.displayName ??
								sourcesState.keys[
									src.vmsName ?? ""
								]?.content.find((s) => s.id === src.id)
									?.displayName ??
								""
							}
							{...(FIXED_VALUES.some((f) => f.id === src.id)
								? { onDelete: undefined }
								: {})}
						/>
					))
				}
				onChange={handleSourceInput}
				value={
					value && value.length > 0
						? value
						: defaultToAll
						? FIXED_VALUES
						: []
				}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						name="source"
						id="source-search"
						onChange={(e) => setSearch(e.target.value)}
						className={className}
						label={
							tooltipTitle ? (
								<Tooltip
									title={tooltipTitle}
									placement="bottom-start"
								>
									<span>{label ?? "Source"}</span>
								</Tooltip>
							) : (
								label ?? "Source"
							)
						}
					/>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (
	state: AppState,
	props: ISourcesFormSelectOwnProps,
): ISourcesViewPropsToState => {
	let sources: ISource[] = [];
	if (props.vmsName && state.sources.keys[props.vmsName]) {
		sources = state.sources.keys[props.vmsName].content;
	} else if (state.vms.keys) {
		sources = Object.keys(state.vms.keys).reduce(
			(acc: ISource[], vmsName: string) => {
				if (state.sources.keys[vmsName]) {
					return acc.concat(state.sources.keys[vmsName].content);
				}
				return acc;
			},
			[],
		);
	}
	return {
		error: state.vms.erorr,
		sources: sources,
		sourcesState: state.sources,
		vmsState: state.vms,
	};
};
const mapDispatchToProps = (dispatch: any): ISourcesViewPropsToDispatch => {
	return {
		loadSources: (vmsName: string, request?: ISourceRequestInfo) =>
			getSourceAction(vmsName, request)(dispatch),
	};
};

const SourcesFormSelect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(_SourcesFormSelect);
export default SourcesFormSelect;
