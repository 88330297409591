import React from "react";
import { Grid, Box, makeStyles, Snackbar } from "@material-ui/core";
import { IWatchlistsContainer } from "../../store/Watchlists/types";
import WatchlistItem from "./WatchlistItem";
import { checkPermissions } from "../../containers/Layout/Layout";
import { connect } from "react-redux";
import { HttpError } from "../../config/types";
import Alert from "../../components/UI/Alert";
import { AppState } from "../../store";

const useStyles = (authentication: boolean) =>
	makeStyles(() => ({
		box: {
			borderColor: "#4495a9",
			height: authentication ? "70vh" : "74vh",
			overflow: "auto",
			borderRadius: 4,
		},
	}));

interface IWatchlistsListOwnProps {
	watchlists: IWatchlistsContainer;
	selected: number;
	setSelected: (watchlist: number, subject: number) => void;
	dblClick(option: string): void;
}

interface IWatchlistsListStateProps {
	error?: HttpError;
}

interface IWatchlistsListProps
	extends IWatchlistsListOwnProps,
		IWatchlistsListStateProps {}

const WatchlistsList: React.FC<IWatchlistsListProps> = ({
	watchlists,
	selected,
	setSelected,
	dblClick,
	error,
}) => {
	const classes = useStyles(
		checkPermissions([
			"ROLE_ADMINISTRATOR",
			"ROLE_WATCHLIST_ADMINISTRATOR",
		]),
	)();

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<Box className={classes.box} border={1}>
				{watchlists.content.length > 0 ? (
					<Grid container direction="column">
						{watchlists.content.map((watchlist, i) => (
							<WatchlistItem
								key={i}
								watchlist={watchlist}
								index={i}
								selected={selected}
								setSelected={setSelected}
								dblClick={dblClick}
							/>
						))}
					</Grid>
				) : (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						style={{ minHeight: "69vh" }}
					>
						<h3
							style={{
								color: "rgba(0, 0, 0, 0.54)",
								textAlign: "center",
							}}
						>
							No watchlist found
						</h3>
					</Grid>
				)}
			</Box>
		</>
	);
};

const mapStateToProps = (state: AppState): IWatchlistsListStateProps => {
	return { error: state.secrets.erorr };
};

export default connect(mapStateToProps, null)(WatchlistsList);
