import React from "react";
import IPassword from "./IPassword";
import PasswordField from "./PasswordField";
import { Grid, Typography } from "@material-ui/core";

interface IPasswordFormProps {
	required: boolean;
	onPasswordChange(password: IPassword): void;
}

interface IPasswordFormState {
	displayNonMatchingPasswordError: boolean;
	firstPassword: IPassword;
	secondPassword: IPassword;
}

class PasswordForm extends React.Component<
	IPasswordFormProps,
	IPasswordFormState
> {
	constructor(props: IPasswordFormProps) {
		super(props);

		this.state = {
			displayNonMatchingPasswordError: false,
			firstPassword: {
				value: "",
				valid: false,
			},
			secondPassword: {
				value: "",
				valid: false,
			},
		};
	}

	doesPasswordMatch(firstPassword: IPassword, secondPassword: IPassword) {
		return (
			firstPassword.valid &&
			secondPassword.valid &&
			firstPassword.value === secondPassword.value
		);
	}

	handleFirstPasswordChange = (password: IPassword) => {
		this.setState({ firstPassword: password });
		if (this.state.secondPassword.value !== "") {
			if (this.doesPasswordMatch(password, this.state.secondPassword)) {
				this.setState({ displayNonMatchingPasswordError: false });
				this.props.onPasswordChange(password);
			} else {
				this.setState({ displayNonMatchingPasswordError: true });
				this.props.onPasswordChange({
					value: password.value,
					valid: false,
				});
			}
		}
	};

	handleSecondPasswordChange = (password: IPassword) => {
		this.setState({ secondPassword: password });
		if (this.doesPasswordMatch(password, this.state.firstPassword)) {
			this.setState({ displayNonMatchingPasswordError: false });
			this.props.onPasswordChange(password);
		} else {
			this.setState({ displayNonMatchingPasswordError: true });
			this.props.onPasswordChange({
				value: password.value,
				valid: false,
			});
		}
	};

	render() {
		return (
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<PasswordField
						id="firstPassword"
						name="firstPassword"
						label="Password"
						required={this.props.required}
						disableAutoComplete
						onPasswordChange={this.handleFirstPasswordChange}
						showButton={true}
					/>
				</Grid>
				<Grid item xs={6}>
					<PasswordField
						id="secondPassword"
						name="firstPassword"
						label="Repeat password"
						required={this.props.required}
						disableAutoComplete
						onPasswordChange={this.handleSecondPasswordChange}
						showButton={true}
					/>
				</Grid>
				{this.state.displayNonMatchingPasswordError ? (
					<Typography variant="body2" gutterBottom color="error">
						Passwords do not match
					</Typography>
				) : null}
			</Grid>
		);
	}
}

export default PasswordForm;
