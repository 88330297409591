import React from "react";
import { Grid, Button } from "@material-ui/core";
import { checkPermissions } from "../../containers/Layout/Layout";

interface IWatchlistsButtons {
	click(option: string): void;
	isDisabled: boolean;
}

const WatchlistsButtons: React.FC<IWatchlistsButtons> = ({
	click,
	isDisabled,
}) => {
	const roles = !checkPermissions([
		"ROLE_ADMINISTRATOR",
		"ROLE_WATCHLIST_ADMINISTRATOR",
	]);

	return (
		<Grid container>
			<Grid item>
				<Button disabled={roles} onClick={() => click("create")}>
					Create
				</Button>
			</Grid>
			<Grid item>
				<Button
					disabled={isDisabled || roles}
					onClick={() => click("edit")}
				>
					Edit
				</Button>
			</Grid>
			<Grid item>
				<Button
					disabled={isDisabled || roles}
					onClick={() => click("delete")}
				>
					Delete
				</Button>
			</Grid>
		</Grid>
	);
};

export default WatchlistsButtons;
