import React from "react";
import { Box, makeStyles, Typography, Tooltip } from "@material-ui/core";
import store from "../../../store";
import SecretText from "../../Secrets/SecretText";
import { MatchProps } from "../../../store/Events/types";

const useStyles = makeStyles(() => ({
	matchBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: "6px",
		width: "100%",
	},
	idText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		width: "90%",
		textAlign: "center",
		textOverflow: "ellipsis",
	},
	centeredTextBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		width: "100%",
		"& > *": {
			fontSize: "0.9rem",
		},
	},
	tooltip: {
		display: "flex",
		flexDirection: "column",
	},
}));

export const Match: React.FC<{
	matchProps: MatchProps;
	eventSecretId?: string;
}> = React.memo(({ matchProps, eventSecretId }) => {
	const classes = useStyles();
	const watchlists = store().getState().watchlists;
	const secrets = store().getState().secrets;

	const watchlist = watchlists.keys[matchProps.watchlist];
	const watchlistDescription = watchlist ? watchlist.description : null;
	const secretId = matchProps.secret ?? eventSecretId;
	const secret = secretId ? secrets.keys[secretId] : null;

	return (
		<Box className={classes.matchBox}>
			{matchProps.id && matchProps.watchlist && (
				<Tooltip
					title={
						<div className={classes.tooltip}>
							<Typography variant="caption">
								Subject: {matchProps.id}
							</Typography>
							<Typography variant="caption">
								Watchlist: {matchProps.watchlist}
							</Typography>
							{watchlistDescription && (
								<Typography variant="caption">
									Description: {watchlistDescription}
								</Typography>
							)}
						</div>
					}
				>
					<Box width="100%">
						<Box className={classes.centeredTextBox}>
							<Typography color="textSecondary" noWrap>
								ID:
							</Typography>
							<Typography noWrap>{matchProps.id}</Typography>
						</Box>
						<Box className={classes.centeredTextBox}>
							<Typography color="textSecondary" noWrap>
								Watchlist:
							</Typography>
							<Typography noWrap>
								{matchProps.watchlist}
							</Typography>
						</Box>
					</Box>
				</Tooltip>
			)}
			<SecretText
				secretID={secretId}
				secretName={secret?.name}
				secretDescription={secret?.description}
				enableTooltip
			/>
		</Box>
	);
});
