import Axios from "axios";
import ClientOauth2 from "client-oauth2";

import { API_BASE_PREFIX } from "../../config/axios";
import { User } from "./types";

const ssAuth = new ClientOauth2({
	clientId: "sentiveillance",
	accessTokenUri: "/oauth/token",
});

export const authenticate = async (userName: string, password: string) => {
	try {
		const response = await ssAuth.owner.getToken(userName, password);
		return response;
	} catch (error) {
		throw error;
	}
};

export const createToken = (
	accessToken: string,
	refreshToken: string,
	type: string,
	expiresIn: number,
) => {
	return ssAuth.createToken(accessToken, refreshToken, type, {
		//In seconds
		expires_in: expiresIn.toString(),
	});
};

export const loadUser = async () => {
	try {
		const user = await Axios.get<User>(
			API_BASE_PREFIX + "authorization/auth/user",
		);
		return user.data;
	} catch (error) {
		throw error;
	}
};
