import Axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { ITask } from "../Tasks/types";

export const History = "History";
export type HistoryAction = AsyncAction<typeof History, ITask[], string>;

const getTaskHistory = async (correlationId: string) => {
	try {
		const response = await Axios.get<ITask[]>(
			API_BASE_PREFIX + `tasks/${correlationId}/history`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getHistoryAction(correlationId: string) {
	return async(History, getTaskHistory, correlationId, correlationId);
}
