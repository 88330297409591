import React from "react";
import {
	IUpdateSecret,
	ICreateSecret,
	ISecretPermission,
} from "../../../store/Secrets/types";
import { Form } from "react-final-form";
import { TextField, makeValidate } from "mui-rff";
import { Button, CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import PermissionManagement from "./PermissionManagement/PermissionManagement";
import { ICurrentUser } from "../../../store/Users/types";

const makeSchema = (edit: boolean) => {
	const createSchema = Yup.object<ICreateSecret>().shape({
		name: Yup.string().required().max(32),
		description: Yup.string().notRequired().nullable(),
		permissions: Yup.array().of(
			Yup.object<ISecretPermission>().shape({
				user: Yup.string(),
				write: Yup.boolean(),
				manage: Yup.boolean(),
			}),
		),
	});
	const updateSchema = Yup.object<IUpdateSecret>().shape({
		description: Yup.string().notRequired().nullable(),
		permissions: Yup.array().of(
			Yup.object<ISecretPermission>().shape({
				user: Yup.string(),
				write: Yup.boolean(),
				manage: Yup.boolean(),
			}),
		),
	});
	return edit ? createSchema : updateSchema;
};

interface ISecretFormProps {
	onSubmit(newSecret: IUpdateSecret | ICreateSecret): void;
	data: ICreateSecret | undefined;
	close(): void;
	currentUser: ICurrentUser;
	users: string[];
	onUserSearch(value: string): void;
	isReadOnly: boolean;
	onLoading(): void;
	loading: boolean;
}

const SecretForm: React.FC<ISecretFormProps> = ({
	onSubmit,
	data,
	close,
	currentUser,
	users,
	onUserSearch,
	isReadOnly,
	onLoading,
	loading,
}) => {
	const [perms, setPerms] = React.useState<ISecretPermission[]>(
		data
			? data.permissions
			: [
					{
						user: currentUser.userName,
						write: true,
						manage: true,
					},
			  ],
	);

	const validate = makeValidate(makeSchema(data !== undefined));

	const handleFormSubmit = (newSecret: IUpdateSecret | ICreateSecret) => {
		onLoading();
		newSecret.permissions = [...perms];
		onSubmit(newSecret);
	};

	return (
		<Form
			onSubmit={handleFormSubmit}
			initialValues={data}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<TextField
						name="name"
						label="Name"
						autoFocus
						disabled={data !== undefined && !isReadOnly}
						required
						inputProps={{
							spellCheck: false,
							readOnly: isReadOnly,
						}}
					/>
					<TextField
						name="description"
						label="Description"
						multiline
						maxRows={10}
						inputProps={{
							spellCheck: false,
							readOnly: isReadOnly,
						}}
					/>
					<PermissionManagement
						onUserSearch={onUserSearch}
						currentUser={currentUser}
						perms={perms}
						setPerms={setPerms}
						isReadOnly={isReadOnly}
						users={users}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{loading ? <CircularProgress /> : null}
						{isReadOnly ? null : (
							<Button type="submit" disabled={loading}>
								{data ? "Confirm" : "Create"}
							</Button>
						)}
						<Button onClick={close}>Cancel</Button>
					</div>
				</form>
			)}
		/>
	);
};

export default SecretForm;
