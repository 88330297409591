import React, { useState } from "react";
import { ISource, ISourceRequestInfo } from "../../store/Sources/types";
import {
	TableRow,
	TableCell,
	Select,
	MenuItem,
	IconButton,
	makeStyles,
	Chip,
	Tooltip,
} from "@material-ui/core";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import theme from "../../config/Theme";
import { ISourceResponse } from "../../store/types";
import TaskStatusIndicator from "../SourceTasks/TaskStatusIndicator";
import { Driver, defaultEngineTypes } from "../../store/VMS/types";
import TuneIcon from "@material-ui/icons/Tune";
import { checkPermissions } from "../../containers/Layout/Layout";
import { getEngineSelectionStyles } from "../../helpers/Styles";
import { CapitalizeFirst } from "../../helpers/Utils";
import { DashboardRounded } from "@material-ui/icons";
import AreaDialog from "./AreaDialog/AreaDialog";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const useStyles = makeStyles(() => ({
	disabled: {
		color: theme.palette.divider,
	},
	menuItem: {
		color: theme.palette.primary.light,
	},
	menuItemActive: {
		color: theme.palette.success.dark,
	},
	running: {
		color: theme.palette.success.main,
	},
	stopped: {
		color: theme.palette.warning.main,
	},
	failed: {
		color: theme.palette.error.main,
	},
	other: {
		color: theme.typography.body1.color,
	},
}));

export const renameTypesForDisplay = (types: string[]) => {
	const result = [...types];
	for (let i = 0; i < types.length; i++) {
		if (types[i] === "VehicleHuman") {
			result[i] = "Vehicle - Human";
		}
	}
	return result;
};

export const renameTypesForQuery = (types?: string[]) => {
	if (types === undefined) {
		return [];
	}
	const result = [...types];
	for (let i = 0; i < types.length; i++) {
		if (types[i] === "Vehicle - Human") {
			result[i] = "VehicleHuman";
		}
	}
	return result;
};

interface ISourceItemProps {
	source: ISource;
	index: number;
	filter: ISourceRequestInfo;
	sourceTasks: { [key: string]: ISourceResponse };
	onStart(source: ISource, selectedTypes: string[]): void;
	onStop(source: ISource): void;
	driver: Driver;
	onMenu: (
		source: ISource,
	) => (event: React.MouseEvent<HTMLButtonElement>) => void;
	engines: string[];
	onEngines(engines: string[], id: string): void;
	setNotification: React.Dispatch<
		React.SetStateAction<{ message: string; variant: "success" | "error" }>
	>;
	isConfigLoading: boolean;
}

const SourceItem: React.FC<ISourceItemProps> = ({
	source,
	sourceTasks,
	onStart,
	onStop,
	driver,
	onMenu,
	engines,
	onEngines,
	setNotification,
	isConfigLoading,
}) => {
	const isAuthorized = checkPermissions([
		"ROLE_ADMINISTRATOR",
		"ROLE_VMS_OPERATOR",
	]);

	const savedProperties = useSelector(
		(state: AppState) =>
			state.properties.changedProperties[source.vmsName ?? ""]
				?.filter((p) => p.scope?.sourceTarget === source.id)
				?.find((p) => p.scope?.sourceTarget === source.id)?.values,
	);

	const classes = useStyles();

	const searchAreaString = savedProperties?.find(
		(p) => p.key === "SearchArea",
	)?.value;
	const parsedSearchArea = JSON.parse(searchAreaString ?? "{}");
	const isGrid =
		(parsedSearchArea?.Columns ?? 0) > 0 ||
		(parsedSearchArea?.Rows ?? 0) > 0;
	const items = isGrid
		? parsedSearchArea?.SearchAreas?.filter(
				(r: { IsInclude: boolean }) => !r.IsInclude,
		  )
		: parsedSearchArea?.SearchAreas;
	const isAreasEnabled = (items?.length ?? 0) > 0;

	const lastTask = source.tasks[source.tasks.length - 1] || undefined;
	const isRunning = lastTask?.status === "RUNNING";
	const isWaiting = lastTask?.status === "WAITING";
	const isError = lastTask?.status === "ERROR";
	const isStopped = lastTask?.status === "STOPPED";
	const isScheduled = lastTask?.status === "SCHEDULED";

	const [showDialog, setShowDialog] = useState(false);

	const statusStyle = isRunning
		? classes.running
		: isStopped
		? classes.stopped
		: isError
		? classes.failed
		: classes.other;

	const task = sourceTasks[source.id] || lastTask?.error;

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		onEngines(event.target.value as string[], source.id);
	};

	const handleEngineRemove = (index: number) => {
		const newEngineArr = [...engines];
		newEngineArr.splice(index, 1);
		onEngines(newEngineArr, source.id);
	};

	return (
		<TableRow hover role="checkbox">
			<TableCell style={{ width: "40%" }}>{source.displayName}</TableCell>
			<TableCell style={{ width: "15%" }}>
				<span className={statusStyle}>
					{lastTask && (
						<TaskStatusIndicator task={task} lastTask={lastTask}>
							{CapitalizeFirst(lastTask.status)}
						</TaskStatusIndicator>
					)}
				</span>
			</TableCell>
			<TableCell style={{ width: "20%", padding: 8 }}>
				<Select
					fullWidth
					value={renameTypesForDisplay(engines) || []}
					onChange={handleChange}
					multiple
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					disabled={isRunning || !isAuthorized || !lastTask}
					renderValue={(selected) => (
						<div>
							{(selected as string[]).map((value, index) => (
								<Chip
									key={index}
									label={value}
									onDelete={() => handleEngineRemove(index)}
									onMouseDown={(event) => {
										event.stopPropagation();
									}}
									disabled={isRunning || !isAuthorized}
								/>
							))}
						</div>
					)}
				>
					{renameTypesForDisplay(defaultEngineTypes).map((type) => (
						<MenuItem
							key={type}
							value={type}
							style={getEngineSelectionStyles(
								type,
								renameTypesForDisplay(engines) || [],
							)}
						>
							{type}
						</MenuItem>
					))}
				</Select>
			</TableCell>
			{isAuthorized ? (
				<TableCell style={{ width: "10%" }}>
					<IconButton
						aria-label="start"
						size="small"
						onClick={() =>
							onStart(source, renameTypesForQuery(engines))
						}
						disabled={
							isRunning || isWaiting || isScheduled || !lastTask
						}
					>
						<PlayCircleFilledWhiteIcon
							className={
								!(
									isRunning ||
									isWaiting ||
									isScheduled ||
									!lastTask
								)
									? classes.menuItem
									: classes.disabled
							}
						/>
					</IconButton>
					<IconButton
						aria-label="stop"
						size="small"
						onClick={() => onStop(source)}
						disabled={isStopped || !lastTask}
					>
						<PauseCircleFilledIcon
							className={
								isStopped || !lastTask
									? classes.disabled
									: classes.menuItem
							}
						/>
					</IconButton>
					{driver === "GENERIC" ? (
						<IconButton
							aria-label="edit"
							size="small"
							onClick={(event) => onMenu(source)(event)}
						>
							<TuneIcon className={classes.menuItem} />
						</IconButton>
					) : null}
					<Tooltip title="Search areas">
						<IconButton
							aria-label="areas"
							size="small"
							onClick={() => setShowDialog(!showDialog)}
						>
							<DashboardRounded
								className={
									isAreasEnabled
										? classes.menuItemActive
										: classes.menuItem
								}
								color="error"
							/>
						</IconButton>
					</Tooltip>
				</TableCell>
			) : null}
			<AreaDialog
				isOpen={showDialog}
				onClose={() => setShowDialog(false)}
				source={source}
				isConfigLoading={isConfigLoading}
				setNotification={setNotification}
			/>
		</TableRow>
	);
};

export default SourceItem;
