import React from "react";
import {
	makeStyles,
	Chip,
	Popover,
	Grid,
	Button,
	Typography,
} from "@material-ui/core";
import { ThresholdTrigger } from "../../../../../store/Rules/types";
import { THEME } from "../../../../../config/index";
import Slider from "../../../../UI/Slider";
import { camelCaseToReadable } from "../../../../../helpers/Filter";
import svgToTsx from "../../../../../helpers/SvgToTsx";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		alignItems: "center",
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 25,
	},
	lessBtn: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
	sliderText: {
		display: "flex",
		flexDirection: "column",
	},
	confidenceText: {
		justifyContent: "center",
		display: "flex",
		width: "50px",
	},
	icon: {
		width: 18,
		height: 18,
		fill: THEME.palette.grey[800],
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 4,
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
}));

interface IThresholdTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: ThresholdTrigger): void;
	onCancel(): void;
	thresholdTrigger?: ThresholdTrigger;
	option: string;
}

interface IThresholdTriggerComponentProps {
	trigger: ThresholdTrigger;
	updateTrigger(trigger?: ThresholdTrigger): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

export const getOptionalChipLabel = (
	trigger: ThresholdTrigger,
	iconClass?: string,
	rowClass?: string,
	tagClass?: string,
) => (
	<span className={rowClass}>
		When
		<span className={tagClass}>
			{svgToTsx(trigger.attributeType, iconClass ?? "")}
			{camelCaseToReadable(trigger.attributeType)}
		</span>
		confidence
		{trigger.less ? <span>&#60; </span> : <span>&#8805; </span>}
		{trigger.confidence}
	</span>
);

const ThresholdTriggerComponent: React.FC<IThresholdTriggerComponentProps> = ({
	trigger,
	updateTrigger,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: ThresholdTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				label={getOptionalChipLabel(
					trigger,
					classes.icon,
					classes.row,
					classes.tag,
				)}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<ThresholdTriggerPopover
				anchorEl={anchorEl}
				thresholdTrigger={trigger}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
				option={trigger.attributeType}
			/>
		</>
	);
};

const getDefaultTrigger = (option: string): ThresholdTrigger => {
	return {
		type: "ThresholdTrigger",
		attributeType: option,
		confidence: 50,
		less: false,
	};
};

export const ThresholdTriggerPopover: React.FC<
	IThresholdTriggerPopoverProps
> = ({ anchorEl, onUpdate, onCancel, thresholdTrigger, option }) => {
	const classes = useStyles();

	const [trigger, setTrigger] = React.useState<ThresholdTrigger>(
		thresholdTrigger ? thresholdTrigger : getDefaultTrigger(option),
	);

	React.useEffect(() => {
		setTrigger(
			thresholdTrigger ? thresholdTrigger : getDefaultTrigger(option),
		);
	}, [anchorEl, thresholdTrigger, option]);

	const attribute = option === "DarkGlasses" ? "Dark Glasses" : option;

	const handleConfirm = () => {
		const newTr = { ...trigger };
		newTr.attributeType = option;
		onUpdate(newTr);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleLess = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.less = !changedTrigger.less;
		setTrigger(changedTrigger);
	};

	const handleSlider = (_event: any, newValue: number | number[]) => {
		const changedTrigger = { ...trigger };
		changedTrigger.confidence = newValue as number;
		setTrigger(changedTrigger);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item container className={classes.popoverRow} xs={12}>
					<Grid item xs={4}>
						<Typography>When {attribute} confidence</Typography>
					</Grid>
					<Grid item xs={2}>
						<Button
							onClick={handleLess}
							size="small"
							className={classes.lessBtn}
						>
							{trigger.less ? "<" : "≥"}
						</Button>
					</Grid>
					<Grid item xs={2} className={classes.confidenceText}>
						<Typography>{trigger.confidence}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Slider
							value={trigger.confidence}
							onChange={handleSlider}
							reverse={trigger.less}
							splitSlider={true}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button onClick={handleConfirm}>
							{thresholdTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default ThresholdTriggerComponent;
