import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { THEME } from "../../config";
import { ISearchResult } from "../../store/ImageSearch/types";
import { ISecret } from "../../store/Secrets/types";
import { IWatchlist } from "../../store/Watchlists/types";
import { Skeleton } from "@material-ui/lab";
import { camelCaseToReadable } from "../../helpers/Filter";
import SecretText from "../Secrets/SecretText";

const useStyles = makeStyles(() => ({
	root: {
		width: "fit-content",
		margin: "8px auto 0 auto",
	},
	tooltip: {
		display: "flex",
		flexDirection: "column",
	},
	secretText: {
		color: THEME.palette.error.contrastText,
		backgroundColor: THEME.palette.error.dark,
		fontSize: "0.8rem",
		padding: "2px 4px",
		borderRadius: "3px",
		width: "fit-content",
		margin: "0 auto",
	},
}));

type Props = {
	watchlist?: IWatchlist;
	secret?: ISecret;
	match?: ISearchResult;
	status?: string;
	error?: string;
};

const ResultInfo = ({ watchlist, match, secret, status, error }: Props) => {
	const classes = useStyles();

	if (status && error)
		return (
			<>
				<Typography
					align="center"
					style={{ marginTop: 4, color: THEME.palette.error.dark }}
				>
					{camelCaseToReadable(status)}
				</Typography>
				<Typography align="center" variant="body2">
					{camelCaseToReadable(error)}
				</Typography>
			</>
		);

	return (
		<Box className={classes.root} visibility={match ? "visible" : "hidden"}>
			<Tooltip
				title={
					((watchlist?.description || secret?.description) && (
						<div className={classes.tooltip}>
							{watchlist?.description && (
								<Typography variant="caption">
									Watchlist description:{" "}
									{watchlist?.description}
								</Typography>
							)}
							{secret?.description && (
								<Typography variant="caption">
									Secret description: {secret.description}
								</Typography>
							)}
						</div>
					)) ??
					""
				}
			>
				<Box>
					{match?.subject ? (
						<Typography align="center">
							<b>ID:</b> {match?.subject}
						</Typography>
					) : (
						<Skeleton>
							<Typography>{genText(30)}</Typography>
						</Skeleton>
					)}
					{match?.watchlist ? (
						<Typography align="center">
							<b>Watchlist:</b> {match?.watchlist}
						</Typography>
					) : (
						<Skeleton>
							<Typography>{genText(30)}</Typography>
						</Skeleton>
					)}
					<SecretText
						secretID={secret?.id}
						secretName={secret?.name}
						secretDescription={secret?.description}
						textAlign="center"
						visibility={secret ? "visible" : "hidden"}
					/>
				</Box>
			</Tooltip>
		</Box>
	);
};

export default ResultInfo;

const genText = (length: number) => "t".repeat(length);
