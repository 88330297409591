import React from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { ICreateRule, IEditRule, IRule } from "../../store/Rules/types";
import RuleForm from "../Forms/RuleForm/RuleForm";
import { ResizableBox } from "react-resizable";
import { useWindowWidth } from "../../helpers/Hooks";
import { calcDialogSize } from "../../helpers/Dialog";
import Title from "./Common/Title";
import { IAlert } from "../UI/Alert";

const useStyles = makeStyles(() => ({
	resizable: {
		position: "relative",
		"& .react-resizable-handle": {
			position: "absolute",
			width: 20,
			height: 20,
			bottom: 0,
			right: 0,
			background:
				"url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
			"background-position": "bottom right",
			padding: "0 3px 3px 0",
			"background-repeat": "no-repeat",
			"background-origin": "content-box",
			"box-sizing": "border-box",
			cursor: "se-resize",
		},
	},
}));

interface IRuleDialogProps {
	open: boolean;
	close(): void;
	data?: IRule;
	onConfirm(rule: IEditRule | ICreateRule): void;
	vmsName: string;
	onLoading(): void;
	loading: boolean;
	isReadOnly: boolean;
	onAlert(alert: IAlert): void;
}

const RuleDialog: React.FC<IRuleDialogProps> = ({
	open,
	close,
	data,
	onConfirm,
	vmsName,
	onLoading,
	loading,
	isReadOnly,
	onAlert,
}) => {
	const classes = useStyles();
	const [width] = useWindowWidth();
	return (
		<Dialog open={open} maxWidth={false} onClose={close}>
			<ResizableBox
				height={calcDialogSize(width, 960, 800, 760)}
				width={calcDialogSize(width, 960, 870, 410)}
				className={classes.resizable}
			>
				<>
					<Title
						title={data ? "Edit Rule" : "Create Rule"}
						onClose={close}
					/>
					<DialogContent>
						<RuleForm
							close={close}
							onConfirm={onConfirm}
							data={data}
							vmsName={vmsName}
							onLoading={onLoading}
							loading={loading}
							isReadOnly={isReadOnly}
							onAlert={onAlert}
						/>
					</DialogContent>
				</>
			</ResizableBox>
		</Dialog>
	);
};

export default RuleDialog;
