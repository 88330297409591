import { CircularProgress } from "@material-ui/core";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React from "react";

interface Props extends SkeletonProps {
	children?: React.ReactNode;
	rootProps?: React.HTMLAttributes<HTMLDivElement>;
}

const useStyles = makeStyles(() => ({
	loader: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	loaderWrapper: {
		position: "relative",
		height: "100%",
		alignItems: "center",
		aspectRatio: "3 / 4",
	},
}));

const SkeletonLoader = ({ children, rootProps, ...props }: Props) => {
	const classes = useStyles();
	return (
		<div className={classes.loaderWrapper} {...rootProps}>
			<Skeleton variant="rect" width="100%" height="100%" {...props}>
				{children}
			</Skeleton>
			<span className={classes.loader}>
				<CircularProgress />
			</span>
		</div>
	);
};

export default SkeletonLoader;
