import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import {
	ICreateSubject,
	ICreateSubjectBody,
} from "../../../store/Subjects/types";
import FaceCreateForm from "../../Forms/SubjectForms/FaceCreateForm";
import Title from "../Common/Title";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

interface ICreateFace {
	open: boolean;
	onConfirm(body: ICreateSubjectBody): void;
	onClose(): void;
	secret?: string;
	schema: IMetadataSchema;
	metadata: IMetadata | null;
	onLoading(): void;
	loading: boolean;
}

const SubjectFaceCreateDialog: React.FC<ICreateFace> = ({
	open,
	onConfirm,
	onClose,
	secret,
	schema,
	metadata,
	onLoading,
	loading,
}) => {
	const handleConfirm = (faceInfo: ICreateSubject) => {
		const body: ICreateSubjectBody = {
			id: faceInfo.id,
			metadata: faceInfo.metadata,
			image: faceInfo.image,
			secret: secret,
			acceptLowQuality: false,
		};
		onConfirm(body);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
			<Title title="Add Subject" onClose={onClose} />
			<DialogContent>
				<FaceCreateForm
					onConfirm={handleConfirm}
					onClose={onClose}
					schema={schema}
					initialMetadata={metadata}
					onLoading={onLoading}
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default SubjectFaceCreateDialog;
