import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import {
	Fullscreen,
	FullscreenExit,
	PersonalVideoRounded,
} from "@material-ui/icons";
import { THEME } from "../../../config";
import moment from "moment";
import _ from "lodash";

export interface IControls {
	timestamp: number | null;
	setMaximized: React.Dispatch<React.SetStateAction<boolean>>;
	maximized: boolean;
	onPlaybackClick?: () => void;
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	wrapper: {
		position: "relative",
		display: "flex",
		gap: "6px",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: THEME.palette.grey[900],
		color: "white",
		width: "100%",
		padding: "0 10px",
	},
	icon: {
		color: "white",
		cursor: "pointer",
		fontSize: "2rem",
		boxSizing: "content-box",
		border: `3px solid transparent`,
	},
	controls: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: THEME.palette.grey[900],
		gap: "6px",
		zIndex: 100,
	},
	tooltip: {
		zIndex: 3000,
	},
}));

const LiveControls = ({
	timestamp,
	disabled,
	setMaximized,
	maximized,
	onPlaybackClick,
}: IControls) => {
	const classes = useStyles();

	useEffect(() => {
		const handleESC = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				setMaximized(false);
			}
		};
		document.addEventListener("keydown", handleESC);
		return () => document.removeEventListener("keydown", handleESC);
	}, [setMaximized]);

	return (
		<div
			className={classes.wrapper}
			style={{ pointerEvents: disabled ? "none" : "all" }}
		>
			{timestamp ? (
				<Typography>
					{moment(timestamp).format("YYYY-MM-DD HH:mm:ss")}
				</Typography>
			) : (
				<Box />
			)}
			<Box className={classes.controls}>
				{onPlaybackClick && (
					<Tooltip
						title={"Playback video"}
						classes={{ popper: classes.tooltip }}
					>
						<PersonalVideoRounded
							className={classes.icon}
							style={{
								fontSize: "1.7rem",
							}}
							onClick={onPlaybackClick}
						/>
					</Tooltip>
				)}
				<Tooltip
					title={maximized ? "Minimize (ESC)" : "Maximize"}
					classes={{ popper: classes.tooltip }}
				>
					{maximized ? (
						<FullscreenExit
							className={classes.icon}
							onClick={() => setMaximized(false)}
						/>
					) : (
						<Fullscreen
							className={classes.icon}
							onClick={() => setMaximized(true)}
						/>
					)}
				</Tooltip>
			</Box>
		</div>
	);
};

export default LiveControls;
