import React from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Grid,
	Button,
	Divider,
	Switch,
} from "@material-ui/core";

interface ITrasnferListProps {
	all: string[];
	available: string[];
	selected: string[];
	leftName: string;
	rightName: string;
	displayValues?: Map<string, string>;
	onChange(selected: string[]): void;
}

interface ITransferListState {
	all: string[];
	available: string[];
	selected: string[];
}

class TransferList extends React.Component<
	ITrasnferListProps,
	ITransferListState
> {
	constructor(props: ITrasnferListProps) {
		super(props);
		this.state = {
			all: props.all,
			available: props.available,
			selected: props.selected,
		};
	}

	componentDidUpdate(prevProps: ITrasnferListProps) {
		if (this.props.available !== prevProps.available) {
			this.setState({ available: this.props.available });
		}
	}

	not = (a: string[], b: string) => {
		return a.filter((value) => b.indexOf(value) === -1);
	};

	handleToggle = (value: string) => () => {
		if (this.state.selected.indexOf(value) !== -1) {
			const newRight = this.not(this.state.selected, value);
			this.setState(
				{
					available: [...this.state.available, value],
					selected: newRight,
				},
				() => this.props.onChange(this.state.selected),
			);
		} else {
			const newLeft = this.not(this.state.available, value);
			this.setState(
				{
					available: newLeft,
					selected: [...this.state.selected, value],
				},
				() => this.props.onChange(this.state.selected),
			);
		}
	};

	handleDiselectAll = () => {
		this.setState(
			{
				available: [...this.state.selected, ...this.state.available],
				selected: [],
			},
			() => this.props.onChange(this.state.selected),
		);
	};

	handleSelectAll = () => {
		this.setState(
			{
				selected: [...this.state.selected, ...this.state.available],
				available: [],
			},
			() => this.props.onChange(this.state.selected),
		);
	};

	customList = (items: string[]) => (
		<>
			<List dense component="div" role="list">
				{items.map((value: string) => {
					const labelId = `transfer-list-item-${value}-label`;
					return (
						<ListItem key={value} role="listitem">
							<ListItemText
								id={labelId}
								primary={
									this.props.displayValues &&
									this.props.displayValues.has(value)
										? this.props.displayValues.get(value)
										: value
								}
							/>
							<ListItemIcon>
								<Switch
									checked={
										this.state.selected.indexOf(value) !==
										-1
									}
									onChange={this.handleToggle(value)}
								/>
							</ListItemIcon>
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</>
	);

	render() {
		return (
			<>
				<Divider
					style={{
						marginBottom: "20px",
					}}
				/>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{
							fontWeight: "bold",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0 15px",
						}}
					>
						Select roles
						<div>
							<Button
								onClick={this.handleSelectAll}
								disabled={!this.state.available.length}
							>
								Select All
							</Button>
							<Button
								onClick={this.handleDiselectAll}
								disabled={!this.state.selected.length}
							>
								Diselect All
							</Button>
						</div>
					</Grid>
					<Grid item xs={6}>
						{this.customList(this.props.all)}
					</Grid>
				</Grid>
			</>
		);
	}
}

export default TransferList;
