import React, { useCallback } from "react";
import {
	Button,
	Grid,
	makeStyles,
	Box,
	CircularProgress,
} from "@material-ui/core";
import { IEditSubject, IPlate, ISubject } from "../../../store/Subjects/types";
import { THEME } from "../../../config/index";
import LabelledOutline from "../../UI/LabelledOutline";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import FormMetadata from "./FormMetadata";
import { checkMetadata, isJustAnalyst } from "../../../helpers/Allowances";
import { errorsInBody } from "../../../helpers/ValidateInput";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

const useStyles = (image: string | undefined) =>
	makeStyles(() => ({
		responsivePhoto: {
			objectFit: "contain",
			width: "100%",
			height: "100%",
		},
		box: {
			borderColor: THEME.palette.secondary.main,
			display: "flex",
			flexDirection: "column",
			flexWrap: "wrap",
			alignItems: "center",
			width: "30rem",
			height: image ? "" : "25rem",
		},
		container: {
			overflow: "hidden",
		},
	}));

interface IEditPlateForm {
	onConfirm(body: IEditSubject): void;
	onClose(): void;
	schema: IMetadataSchema;
	initialMetadata: IMetadata;
	subject: ISubject;
	onLoading(): void;
	loading: boolean;
}

const PlateEditForm: React.FC<IEditPlateForm> = ({
	onConfirm,
	onClose,
	schema,
	initialMetadata,
	subject,
	onLoading,
	loading,
}) => {
	const [imageString, setImageString] = React.useState<string | undefined>(
		subject.thumb,
	);
	const [metadata, setMetadata] = React.useState<IMetadata>(initialMetadata);
	const [errors, setErrors] = React.useState<IMetadata>({});
	const classes = useStyles(subject.thumb)();

	const handleFormSubmit = (formPlate: IPlate) => {
		const formErrors = errorsInBody(schema.properties, metadata);
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		} else {
			setErrors({});
		}

		onLoading();
		const plate = { ...formPlate };
		const org = plate.origin ? plate.origin.toUpperCase() : undefined;
		const body: IEditSubject = {
			origin: org,
			metadata: JSON.stringify(checkMetadata(schema, metadata)),
			image: imageString,
		};
		onConfirm(body);
	};

	const handlePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const file: File = (target.files as FileList)[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				const res = reader.result as string;
				setImageString(btoa(res));
			};
			reader.readAsBinaryString(file);
		}
	};

	const handleMetadata = useCallback(
		(newMeta: IMetadata) => {
			if (JSON.stringify(newMeta) !== JSON.stringify(metadata)) {
				setMetadata(newMeta);
			}
		},
		[metadata],
	);

	const isAnalyst = isJustAnalyst();

	return (
		<Form
			onSubmit={handleFormSubmit}
			initialValues={{
				licensePlate: subject.name,
				origin: subject.origin ? subject.origin : "",
			}}
			render={({ handleSubmit, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<Grid container>
						<Grid item xs={12} md={7} lg={5}>
							<Grid
								container
								direction="column"
								alignItems="center"
								justifyContent="center"
								style={{ minHeight: "100%" }}
							>
								<Box className={classes.box} border={1}>
									{imageString ? (
										<img
											className={classes.responsivePhoto}
											alt="License Plate"
											src={`data:image/jpeg;base64,${imageString}`}
										/>
									) : null}
								</Box>
								{!isAnalyst ? (
									<Button component="label">
										Change Thumbnail
										<input
											hidden
											type="file"
											name="image"
											id="file"
											accept=".jpeg, .png, .jpg"
											onChange={handlePhoto}
										/>
									</Button>
								) : null}
							</Grid>
						</Grid>
						<Grid item xs={12} md={5} lg={7}>
							<Grid container direction="column">
								<Grid item>
									<TextField
										fullWidth
										name="licensePlate"
										label="License Plate"
										required
										disabled={!isAnalyst}
										inputProps={{
											spellCheck: false,
											readOnly: isAnalyst,
										}}
									/>
								</Grid>
								<Grid item>
									<TextField
										fullWidth
										name="origin"
										label="Origin"
										inputProps={{
											readOnly: isAnalyst,
											spellCheck: false,
											style: {
												textTransform: "uppercase",
											},
											maxLength: 3,
										}}
									/>
								</Grid>
								<Grid item>
									<LabelledOutline
										label="Metadata"
										id="edit-plate-form-metadata-label"
									>
										<FormMetadata
											schema={schema}
											onUpdate={handleMetadata}
											metadata={metadata}
											isReadOnly={isAnalyst}
											errors={errors}
										/>
									</LabelledOutline>
								</Grid>
								{!isAnalyst ? (
									<Grid item style={{ marginBottom: 15 }}>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												marginTop: 5,
											}}
										>
											{loading ? (
												<CircularProgress />
											) : null}
											<Button
												type="submit"
												disabled={
													loading ||
													(pristine &&
														subject.thumb ===
															imageString &&
														metadata ===
															initialMetadata)
												}
											>
												Apply Changes
											</Button>
											<Button onClick={onClose}>
												Cancel
											</Button>
										</div>
									</Grid>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default PlateEditForm;
