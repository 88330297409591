import { Service } from "../store/Logs/types";

export const matchIdWithName = (list: Service[], containerName: string) => {
	const filtered = list.filter((item) => item.id === containerName);
	if (filtered.length > 0) {
		return filtered[0].displayName;
	}
	return containerName;
};

export const levelStyles = (severity: string) => {
	const level = severity ? severity.toLowerCase() : "";
	switch (level) {
		case "fatal": {
			return "red";
		}
		case "error": {
			return "red";
		}
		case "warn": {
			return "orange";
		}
		default: {
			return undefined;
		}
	}
};
