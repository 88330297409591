import React from "react";
import {
	Table,
	TableBody,
	TableHead,
	Paper,
	TableContainer,
	TableRow,
	TableCell,
	TablePagination,
	TableSortLabel,
	Snackbar,
} from "@material-ui/core";
import {
	IgetTasksRequest,
	ITasksContainer,
	ITask,
} from "../../store/Tasks/types";
import TaskRow from "./TaskRow";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { HttpError } from "../../config/types";
import Alert from "../UI/Alert";

interface ITaskListOwnProps {
	tasks: ITasksContainer;
	filter: IgetTasksRequest;
	onPageChange: (event: unknown, newPage: number) => void;
	onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	setFilter: (filter: IgetTasksRequest) => void;
}

interface ITaskListStateProps {
	error?: HttpError;
}

type Order = "asc" | "desc";

interface HeadCell {
	id: keyof ITask;
	label: string;
}

const headCells: HeadCell[] = [
	{ id: "timestamp", label: "Timestamp" },
	{ id: "action", label: "Action" },
	{ id: "status", label: "Status" },
	{ id: "message", label: "Message" },
	{ id: "vms", label: "VMS" },
	{ id: "sourceId", label: "Source" },
	{ id: "secret", label: "Secret" },
	{ id: "watchlist", label: "Watchlist" },
	{ id: "subject", label: "Subject" },
	{ id: "rule", label: "Rule" },
	{ id: "user", label: "User" },
	{ id: "error", label: "Error" },
];

interface ITaskListProps extends ITaskListOwnProps, ITaskListStateProps {}

const TaskList: React.FC<ITaskListProps> = ({
	tasks,
	filter,
	onPageChange,
	onRowsPerPageChange,
	setFilter,
	error,
}) => {
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof ITask | null>(
		filter.sortBy ?? null,
	);

	const handleChangePage = (event: unknown, newPage: number) => {
		onPageChange(event, newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		onRowsPerPageChange(event);
	};

	const handleSort = (property: keyof ITask) => () => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
		setFilter({
			...filter,
			asc: isAsc,
			sortBy: property,
		});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			{tasks.content.length > 0 ? (
				<Paper
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						overflowY: "auto",
					}}
				>
					<TableContainer style={{ flex: 1 }}>
						<Table
							aria-label="taskTable"
							stickyHeader
							style={{
								overflow: "auto",
							}}
						>
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											sortDirection={
												orderBy === headCell.id
													? order
													: false
											}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={
													orderBy === headCell.id
														? order
														: "asc"
												}
												onClick={handleSort(
													headCell.id,
												)}
											>
												{headCell.label}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{tasks.content.map((task) => (
									<TaskRow
										task={task}
										key={task.correlationId}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 50, 100]}
						count={tasks ? tasks.totalElements : 0}
						page={filter ? filter.page || 0 : 0}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPage={filter ? filter.size || 25 : 25}
						component="div"
					/>
				</Paper>
			) : (
				<h3
					style={{
						color: "rgba(0, 0, 0, 0.54)",
						textAlign: "center",
					}}
				>
					No tasks found
				</h3>
			)}
		</>
	);
};

const mapStateToProps = (state: AppState): ITaskListStateProps => {
	let stateError = undefined;
	if (state.taskHistory.erorr) {
		stateError = state.taskHistory.erorr;
	} else if (state.sources.erorr) {
		stateError = state.sources.erorr;
	} else if (state.secrets.erorr) {
		stateError = state.secrets.erorr;
	}

	return { error: stateError };
};

export default connect(mapStateToProps, null)(TaskList);
