import { ITasksState, ITask } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { TasksAction } from "./action";
import { PagedContent } from "../types";

export const initialTasksState: ITasksState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	paging: {
		page: 0,
		size: 25,
		asc: false,
		sortBy: "timestamp",
	},
	requestStatus: {},
};

const handleTasks = (
	state: ITasksState = initialTasksState,
	action: TasksAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				nState.keys[action.meta] = action.payload as ITask;
			} else {
				const payload = action.payload as PagedContent<ITask[]>;
				payload.content.forEach((task) => {
					nState.keys[task.correlationId] = task;
				});
			}
			return nState;
	}
	return state;
};

const handleFilter = (
	state: ITasksState = initialTasksState,
	action: TasksAction,
) => {
	if (action.type === "Tasks_Filter") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			nState.paging = {
				...nState.paging,
				...action.payload,
			};
		}
		return nState;
	}
	return state;
};

export const tasksReducer: Reducer<ITasksState, any> = (
	state = initialTasksState,
	action: TasksAction,
) => {
	switch (action.type) {
		case "Tasks":
			return handleTasks(state, action);
		case "Tasks_Filter":
			return handleFilter(state, action);
		default:
			return state;
	}
};
