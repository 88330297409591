import React from "react";
import {
	makeStyles,
	Grid,
	Chip,
	Typography,
	Switch,
	TextField,
	Popover,
	Button,
} from "@material-ui/core";
import { AgeTrigger } from "../../../../../store/Rules/types";
import { THEME } from "../../../../../config/index";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 6,
	},
}));

interface IAgeTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate: (trigger: AgeTrigger) => void;
	onCancel: () => void;
	ageTrigger?: AgeTrigger;
}

interface IAgeTriggerProps {
	trigger: AgeTrigger;
	updateTrigger(trigger?: AgeTrigger): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

export const getAgeChipLabel = (
	trigger: AgeTrigger,
	rowClass?: string,
	tagClass?: string,
) => (
	<span className={rowClass}>
		Age{" "}
		{trigger.from && (
			<>
				from <span className={tagClass}>{trigger.from}</span>
			</>
		)}
		{trigger.to && (
			<>
				to
				<span className={tagClass}>{trigger.to}</span>
			</>
		)}
	</span>
);

const AgeTriggerComponent: React.FC<IAgeTriggerProps> = ({
	trigger,
	updateTrigger,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: AgeTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				label={getAgeChipLabel(trigger, classes.row, classes.tag)}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<AgeTriggerPopover
				ageTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
			/>
		</>
	);
};

const defaultTrigger: AgeTrigger = {
	type: "AgeTrigger",
	from: 18,
	to: null,
};

export const AgeTriggerPopover: React.FC<IAgeTriggerPopoverProps> = ({
	anchorEl,
	onUpdate,
	onCancel,
	ageTrigger,
}) => {
	const classes = useStyles();
	const [trigger, setTrigger] = React.useState<AgeTrigger>(
		ageTrigger ? ageTrigger : defaultTrigger,
	);

	React.useEffect(() => {
		setTrigger(ageTrigger ? ageTrigger : defaultTrigger);
	}, [anchorEl, ageTrigger]);

	const handleConfirm = () => {
		onUpdate(trigger);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleFromSwitch = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.from = trigger.from
			? null
			: ageTrigger && ageTrigger.from
			? ageTrigger.from
			: 18;
		setTrigger(changedTrigger);
	};

	const handleToSwitch = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.to = trigger.to
			? null
			: ageTrigger && ageTrigger.to
			? ageTrigger.to
			: 100;
		setTrigger(changedTrigger);
	};

	const handleAgeInput = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const result = event.target.value as number;
		const changedTrigger = { ...trigger };
		switch (event.target.name) {
			case "from": {
				changedTrigger.from = result;
				setTrigger(changedTrigger);
				break;
			}
			case "to": {
				changedTrigger.to = result;
				setTrigger(changedTrigger);
				break;
			}
		}
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item xs={6}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={3}>
							<Typography>From</Typography>
						</Grid>
						<Grid item xs={3}>
							<Switch
								size="small"
								checked={trigger.from !== null}
								onChange={handleFromSwitch}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								disabled={trigger.from === null}
								name="from"
								value={trigger.from ? trigger.from : ""}
								type="number"
								InputProps={{
									inputProps: {
										style: {
											textAlign: "right",
										},
										min: 0,
										max: 100,
									},
								}}
								onChange={handleAgeInput}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={3}>
							<Typography>To</Typography>
						</Grid>
						<Grid item xs={3}>
							<Switch
								size="small"
								checked={Boolean(trigger.to)}
								onChange={handleToSwitch}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								disabled={trigger.to === null}
								name="to"
								value={trigger.to ? trigger.to : ""}
								type="number"
								InputProps={{
									inputProps: {
										style: {
											textAlign: "right",
										},
										min: 0,
										max: 100,
									},
								}}
								onChange={handleAgeInput}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								trigger.from === null && trigger.to === null
							}
						>
							{ageTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default AgeTriggerComponent;
