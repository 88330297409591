import { Box, Divider, Tooltip, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";

import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import clsx from "clsx";
import { FilterCenterFocusRounded, HelpRounded } from "@material-ui/icons";
import { setDraggingCameraAction } from "../../store/LiveCameras/action";

export const PASSIVE_HOTSPOT_SECONDS_BETWEEN_UPDATES = 3;

const useStyles = makeStyles(() => ({
	root: {
		paddingTop: 6,
		borderTop: `2px solid ${THEME.palette.primary.dark}`,
	},
	hotspotList: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: THEME.palette.grey[200],
		borderRadius: 6,
	},
	titleWrapper: {
		display: "flex",
		alignItems: "center",
		gap: 6,
		padding: "2px 6px",
		backgroundColor: THEME.palette.primary.dark,
		color: THEME.palette.grey[300],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
	title: {
		fontWeight: 500,
	},
	source: {
		padding: "4px 4px",
		borderBottom: `2px solid transparent`,
		"&:hover": {
			cursor: "grab",
			backgroundColor: THEME.palette.grey[300],
		},
		userSelect: "none",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	borderBottom: {
		borderBottom: `2px solid ${THEME.palette.grey[300]}`,
	},
	divider: {
		backgroundColor: THEME.palette.grey[400],
	},
	tooltip: {
		display: "flex",
		"& svg": {
			fontSize: "1.3rem",
		},
	},
}));

export enum HotspotType {
	Playback = "Playback",
	Active = "Active",
	Passive = "Passive",
}

const hotspots = [
	{
		type: HotspotType.Active,
		tooltip:
			"Selected event will be displayed on this hotspot in live mode",
	},
	{
		type: HotspotType.Passive,
		tooltip: `New event will be displayed on this hotspot for at least ${PASSIVE_HOTSPOT_SECONDS_BETWEEN_UPDATES} seconds`,
	},
	{
		type: HotspotType.Playback,
		tooltip:
			"Selected event will be displayed on this hotspot in playback mode",
	},
];

const HotspotList = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const draggingCamera = useSelector(
		(state: AppState) => state.live.draggingCamera,
	);

	const handleDragStart = (
		_: React.DragEvent<HTMLElement>,
		type: HotspotType,
	) => {
		dispatch(
			setDraggingCameraAction({
				hotspotType: type,
				vmsName: undefined,
				sourceName: undefined,
				sourceId: undefined,
				isLive: undefined,
			}),
		);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.hotspotList}>
				<Box className={classes.titleWrapper}>
					<FilterCenterFocusRounded fontSize="small" />
					<Divider
						orientation="vertical"
						flexItem
						classes={{ root: classes.divider }}
					/>
					<Typography
						variant="subtitle1"
						noWrap
						className={classes.title}
					>
						Hotspots
					</Typography>
				</Box>
				{hotspots.map((hotspot, i) => {
					return (
						<Box
							key={i}
							style={{
								pointerEvents: draggingCamera ? "none" : "auto",
							}}
							draggable
							onDragStart={(e) =>
								handleDragStart(e, hotspot.type)
							}
							className={clsx(
								classes.source,
								i !== hotspots.length - 1 &&
									classes.borderBottom,
							)}
						>
							<Typography variant="body2" noWrap>
								{hotspot.type}
							</Typography>
							<Tooltip title={hotspot.tooltip} placement="top">
								<HelpRounded fontSize="small" color="action" />
							</Tooltip>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default HotspotList;
