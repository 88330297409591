import Axios from "axios";
import { AsyncAction, AsyncActionStatus, async } from "../AsyncState";
import { API_BASE_PREFIX, API_ELASTICSEARCH_EVENTS } from "../../config/axios";
import {
	BackwardSearchContainer,
	BackwardSearchRequest,
	BackwardSearchTask,
	IBackwardSearchMatchModel,
	IBackwardSearchResult,
	IBackwardSearchResultImage,
	IBackwardSearchResultRequest,
	IBackwardSocketMessage,
	IGetBackwardSearchResultsRequest,
	IHitsResponse,
} from "./types";
import { IElasticEvents } from "../Events/types";
import { PagedContent } from "../types";

export type BackwardSearches =
	| "Backward_Searches"
	| "Scroll"
	| "Backward_Searches_Filter"
	| "Backward_Search_Result"
	| "Backward_Search_Image"
	| "Backward_Search_Hits"
	| "Backward_Searches_Socket";

export type BackwardSearchAction = AsyncAction<
	BackwardSearches,
	| BackwardSearchContainer
	| IBackwardSearchResult
	| IHitsResponse
	| IBackwardSearchResultImage
	| IBackwardSocketMessage
>;

export interface IGetImagesResponse {
	image: string | undefined;
	subjectId: IBackwardSearchMatchModel["subjectId"];
}

export const getBackwardSearchEvents = async (
	matches: IBackwardSearchMatchModel[],
) => {
	try {
		const allVms = matches
			.map((hit) => hit.vms)
			.filter((v, i, a) => a.indexOf(v) === i);
		const allSources = matches
			.map((hit) => hit.source)
			.filter((v, i, a) => a.indexOf(v) === i);
		const allSubjectIds = matches
			.map((hit) => hit.subjectId)
			.filter((v, i, a) => a.indexOf(v) === i);

		const response = await Axios.get<IElasticEvents>(
			API_ELASTICSEARCH_EVENTS + "/_search",
			{
				params: {
					source: JSON.stringify({
						size: matches.length,
						query: {
							bool: {
								filter: [
									{
										bool: {
											must: [
												{
													bool: {
														should: allSources.map(
															(s) => ({
																match_phrase: {
																	sourceId: s,
																},
															}),
														),
													},
												},
												{
													bool: {
														should: allVms.map(
															(v) => ({
																match_phrase: {
																	vms: v,
																},
															}),
														),
													},
												},
												{
													bool: {
														should: allSubjectIds.map(
															(s) => ({
																match_phrase: {
																	subjectId:
																		s,
																},
															}),
														),
													},
												},
											],
										},
									},
								],
							},
						},
					}),
					source_content_type: "application/json",
				},
			},
		);

		return response.data.hits.hits;
	} catch (error) {
		throw error;
	}
};

const getBackwardSearchRequests = async (
	requestParams: IGetBackwardSearchResultsRequest,
	controller?: AbortController,
) => {
	try {
		const response = await Axios.get<BackwardSearchContainer>(
			API_BASE_PREFIX + "BackwardSearch",
			{
				params: requestParams,
				signal: controller?.signal,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const searchBackward = async (
	request: BackwardSearchRequest,
): Promise<BackwardSearchTask> => {
	try {
		const response = await Axios.post(
			API_BASE_PREFIX + `BackwardSearch/search`,
			request,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getBackwardSearchResult = async (
	{ id, sources, ...params }: IBackwardSearchResultRequest,
	controller?: AbortController,
): Promise<IBackwardSearchResult> => {
	try {
		const response = await Axios.get(
			API_BASE_PREFIX +
				`BackwardSearch/${id}${
					Boolean(sources)
						? "?" +
						  new URLSearchParams(
								sources?.map((s) => ["sources", s]),
						  ).toString()
						: ""
				}`,
			{
				params,
				signal: controller?.signal,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getBackwardSearchImage = async (
	id: string,
	controller?: AbortController,
) => {
	try {
		const response = await Axios.get<IBackwardSearchResultImage>(
			API_BASE_PREFIX + `BackwardSearch/${id}/image`,
			{
				signal: controller?.signal,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getHitsForResult = async (
	index: number,
	resetHits: boolean,
	{ id, sources, ...params }: IBackwardSearchResultRequest,
): Promise<IHitsResponse> => {
	try {
		const response = await Axios.get<
			PagedContent<IBackwardSearchMatchModel[]>
		>(
			API_BASE_PREFIX +
				`BackwardSearch/${id}/${index}${
					Boolean(sources)
						? "?" +
						  new URLSearchParams(
								sources?.map((s) => ["sources", s]),
						  ).toString()
						: ""
				}`,
			{
				params,
			},
		);

		return { ...response.data, id, index, resetHits };
	} catch (error) {
		throw error;
	}
};

export function getBackwardSearchImageAction(id: string) {
	return async("Backward_Search_Image", getBackwardSearchImage, id, id);
}

export function getBackwardSearchRequestsAction(
	requestParams: IGetBackwardSearchResultsRequest,
	controller?: AbortController,
) {
	return async(
		"Backward_Searches",
		getBackwardSearchRequests,
		"",
		requestParams,
		controller,
	);
}

export function getBackwardSearchResultAction(
	request: IBackwardSearchResultRequest,
	controller?: AbortController,
) {
	return async(
		"Backward_Search_Result",
		getBackwardSearchResult,
		"",
		request,
		controller,
	);
}

export function getHitsForResultAction(
	index: number,
	resetHits = false,
	params: IBackwardSearchResultRequest & {
		page: number;
		size: number;
		orderByScore: boolean;
	},
) {
	return async(
		"Backward_Search_Hits",
		getHitsForResult,
		"",
		index,
		resetHits,
		params,
	);
}

export function setScroll(scroll: number) {
	return {
		meta: scroll,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Scroll",
	};
}

export function setBackwardSearchRequestFilterAction(
	filter: IGetBackwardSearchResultsRequest,
) {
	return {
		payload: filter,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Backward_Searches_Filter",
	};
}

export function handleBackwardSocketDataAction(data: IBackwardSocketMessage) {
	return {
		payload: data,
		status: AsyncActionStatus.SUCCEEDED,
		type: "Backward_Searches_Socket",
	};
}
