import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { AuthoritiesAction, Authorities } from "./action";
import { IAuthoritiesState } from "./types";

export const initialAuthoritiesState: IAuthoritiesState = {
	status: AsyncActionStatus.UNSTARTED,
	data: [],
	requestStatus: {},
};

export const authoritiesReducer: Reducer<IAuthoritiesState, any> = (
	state = initialAuthoritiesState,
	action: AuthoritiesAction,
) => {
	if (action.type !== Authorities) {
		return state;
	}
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			nState.data = action.payload as string[];
			return nState;
		}
	}
	return state;
};
