import axios from "axios";
import { AsyncAction, async } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IConfigurationProperty } from "./types";
import { IResponse } from "../types";

export type Properties = "Properties" | "Get_Properties" | "Change_Properties";
export type PropertiesAction = AsyncAction<
	Properties,
	IConfigurationProperty[] | IConfigurationProperty,
	string
>;

export const getProperties = async () => {
	try {
		const response = await axios.get(API_BASE_PREFIX + `config`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const changeConfiguration = async (
	properties: Omit<IConfigurationProperty, "description">[],
) => {
	try {
		const response = await axios.post<IResponse>(
			API_BASE_PREFIX + `config`,
			properties,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getChangedProperties = async (vmsName: string) => {
	try {
		const response = await axios.get(
			API_BASE_PREFIX + `config/vms/${vmsName}`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getPropertiesAction() {
	return async("Properties", getProperties, "");
}

export async function getChangedPropertiesAction(vmsName: string) {
	return async("Get_Properties", getChangedProperties, vmsName, vmsName);
}
