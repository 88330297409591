import { useCallback, useEffect, useState } from "react";
import { IVMS } from "../../store/VMS/types";
import { connect } from "react-redux";
import { AppState } from "../../store";
import {
	getSourceAction,
	disconnectSource,
	updateSourceAction,
	editSource,
	setSourcesFilter,
} from "../../store/Sources/action";
import {
	ISource,
	ISourceRequestInfo,
	ISourceinfo,
	IRunSourceTaskQuery,
	SourceStatuses,
	EngineState,
} from "../../store/Sources/types";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Menu,
	MenuItem,
	makeStyles,
	Tooltip,
	Grid,
	IconButton,
	Snackbar,
} from "@material-ui/core";
import SourceDialog from "../Dialogs/SourceDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../UI/ConfirmDialog";
import theme from "../../config/Theme";
import { ISourceResponse } from "../../store/types";
import {
	updateSourceTaskAction,
	startSourceTaskAction,
	stopSourceTaskAction,
} from "../../store/SourceTasks/action";
import Alert, { IAlert } from "../UI/Alert";
import SourceItem from "./SourceItem";
import { CapitalizeFirst } from "../../helpers/Utils";
import { getVMSTasks, handleTaskAction } from "../../helpers/VMSCrud";
import { useDispatch } from "react-redux";
import { addVMSAction } from "../../store/VMS/action";
import { getChangedPropertiesAction } from "../../store/Properties/action";

interface IsourcesTableStateProps {
	vms: IVMS;
	sources: ISource[] | null;
	unfilteredSources: ISource[] | null;
	filter: ISourceRequestInfo;
	sourceTasks: { [key: string]: ISourceResponse };
}

interface IsourcesTableOwnProps {
	name: string;
	onAlert(alert: IAlert): void;
	areSourcesAuthorized: boolean;
	engines: EngineState[];
	onEngines(engines: string[], id: string): void;
}

interface IsourcesTableDispatch {
	loadSources: (vmsName: string, query?: ISourceRequestInfo) => void;
	updateSource: (vmsName: string, sourceId: string) => void;
	updateSourceTask: (taskId: string) => void;
	startSourceTask: (
		vmsName: string,
		sourceId: string,
		query: IRunSourceTaskQuery,
	) => void;
	stopSourceTask: (vmsName: string, sourceId: string) => void;
	updateFilter: (vmsName: string, filter: ISourceRequestInfo) => void;
}

interface SourceTableProps
	extends IsourcesTableStateProps,
		IsourcesTableOwnProps,
		IsourcesTableDispatch {}

const useStyles = makeStyles(() => ({
	menuItem: {
		color: theme.palette.primary.light,
	},
	status: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const _SourcesTable = (props: SourceTableProps) => {
	const dispatch = useDispatch();
	const [sourceForm, setSourceForm] = useState<ISource | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [statusAnchor, setStatusAnchor] = useState<null | HTMLElement>(null);
	const [isConfirmDialogOpen, setConfirmDialogOpen] =
		useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [isFiltered, setIsFiltered] = useState<boolean>(false);
	const [isConfigLoading, setIsConfigLoading] = useState(true);
	const [notification, setNotification] = useState<{
		variant: "error" | "success";
		message: string;
	}>({
		variant: "error",
		message: "",
	});

	const {
		name,
		filter,
		sourceTasks,
		loadSources,
		updateFilter,
		sources,
		unfilteredSources,
		vms,
	} = props;
	const classes = useStyles();

	const handleSubmitSuccess = (id: string) => {
		props.updateSource(props.name, id);
		setIsModalOpen(false);
		handleMenuClose();
		props.onAlert({
			message: "Successfully edited source.",
			variant: "success",
		});
	};

	const handleSubmitError = (msg: string) => {
		props.onAlert({
			message: "Failed to connect source: " + msg,
			variant: "error",
		});
	};

	const loadProperties = useCallback(async () => {
		getChangedPropertiesAction(vms.name)
			.then(dispatch)
			.finally(() => setIsConfigLoading(false));
	}, [dispatch, vms.name]);

	useEffect(() => {
		loadProperties();
	}, [loadProperties]);

	useEffect(() => {
		loadSources(name, { name: filter?.name, size: 500 });
	}, [name, filter?.name, loadSources]);

	useEffect(() => {
		if (sources) {
			for (let i = 0; i < sources.length; i++) {
				const src = sources[i];
				dispatch({
					type: "Load",
					engines: src.tasks[src.tasks.length - 1]?.engine || [],
					id: sources[i].id,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sources?.length]);

	useEffect(() => {
		if (unfilteredSources) {
			const newVMS = {
				...vms,
				tasksStatus: getVMSTasks(unfilteredSources),
			};
			dispatch(addVMSAction(newVMS));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unfilteredSources?.length]);

	const onSubmit = async (values: ISourceinfo) => {
		if (sourceForm != null) {
			await handleTaskAction(
				editSource,
				() => handleSubmitSuccess(sourceForm.id),
				handleSubmitError,
				props.name,
				sourceForm.id,
				values,
			);
		}
	};

	const handleStatusClick = (event: React.MouseEvent<HTMLSpanElement>) =>
		setStatusAnchor(event.currentTarget);

	const handleStatusClose = () => setStatusAnchor(null);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const handleStart = (source: ISource, selectedEngineTypes: string[]) => {
		props.startSourceTask(props.name, source.id, {
			engineTypes: selectedEngineTypes,
		});
	};

	const handleStop = (source: ISource) => {
		props.stopSourceTask(props.name, source.id);
	};

	const handleMenuClick =
		(source: ISource) => (event: React.MouseEvent<HTMLButtonElement>) => {
			setSourceForm(source);
			setAnchorEl(event.currentTarget);
		};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleDisconnectSuccess = () => {
		setConfirmDialogOpen(false);
		setLoading(false);
		handleMenuClose();
		props.onAlert({
			message: "Successfully disconnected source.",
			variant: "success",
		});
	};

	const handleDisconnectError = (msg: string) => {
		setLoading(false);
		props.onAlert({
			message: "Failed to disconnect source: " + msg,
			variant: "error",
		});
	};

	const handleDisconnect = async () => {
		if (sourceForm && props.sources) {
			await handleTaskAction(
				disconnectSource,
				() => handleDisconnectSuccess(),
				handleDisconnectError,
				props.name,
				sourceForm.id,
			);
		}
	};

	const handleStatusSelect = (status: string) => {
		updateFilter(name, { ...filter, status: status });
		setStatusAnchor(null);
		setIsFiltered(true);
	};

	const handleClearStatusFilter = () => {
		const nFilter = { ...filter };
		nFilter.status = "";
		updateFilter(name, nFilter);
		setIsFiltered(false);
	};

	const handleEnginesUpdate = (nEngines: string[], id: string) => {
		props.onEngines(nEngines, id);
	};

	return (
		<>
			{props.sources && props.sources.length > 0 ? (
				<>
					<Table>
						<TableHead>
							<TableRow>
								{props.sources.length > 0 ? (
									<TableCell style={{ padding: "0px 16px" }}>
										Source name
									</TableCell>
								) : null}
								{isFiltered || props.sources.length > 0 ? (
									<TableCell style={{ padding: "0px 16px" }}>
										<Grid
											container
											spacing={1}
											justifyContent={
												props.sources.length > 0
													? "flex-start"
													: "center"
											}
										>
											<Grid item>
												<Tooltip title="Select by what status to filter">
													<span
														onClick={
															handleStatusClick
														}
														className={
															classes.status
														}
													>
														Status
													</span>
												</Tooltip>
											</Grid>
											{filter && filter.status ? (
												<Grid item>
													<Tooltip title="Clear filter">
														<IconButton
															size="small"
															onClick={
																handleClearStatusFilter
															}
															style={{
																padding: 0,
															}}
														>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</Grid>
											) : null}
										</Grid>
									</TableCell>
								) : null}
								{props.sources.length > 0 ? (
									<>
										<TableCell
											style={{ padding: "0px 16px" }}
										>
											Engine
										</TableCell>
										{props.areSourcesAuthorized ? (
											<TableCell
												style={{ padding: "0px 16px" }}
											>
												{" "}
												Action
											</TableCell>
										) : null}
									</>
								) : null}
							</TableRow>
						</TableHead>
						<TableBody>
							{props.sources.length > 0 ? (
								props.sources.map((s, i) => (
									<SourceItem
										key={i}
										source={s}
										index={i}
										filter={props.filter}
										sourceTasks={sourceTasks}
										onStart={handleStart}
										onStop={handleStop}
										driver={props.vms.driver}
										onMenu={handleMenuClick}
										engines={
											props.engines.find(
												(e) => e.id === s.id,
											)?.engines || []
										}
										onEngines={handleEnginesUpdate}
										setNotification={setNotification}
										isConfigLoading={isConfigLoading}
									/>
								))
							) : (
								<TableRow>
									<TableCell>
										<h3
											style={{
												color: "rgba(0, 0, 0, 0.54)",
												textAlign: "center",
											}}
										>
											No{" "}
											{CapitalizeFirst(
												filter.status
													? filter.status
													: "",
											)}{" "}
											Sources.
										</h3>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					<SourceDialog
						open={isModalOpen}
						close={() => {
							setIsModalOpen(false);
							handleMenuClose();
						}}
						onSubmit={onSubmit}
						data={sourceForm}
						edit
					/>

					<ConfirmDialog
						open={isConfirmDialogOpen}
						onClose={() => {
							setConfirmDialogOpen(false);
							handleMenuClose();
						}}
						onConfirm={handleDisconnect}
						loading={loading}
						onLoading={() => setLoading(true)}
					>
						Are you sure you want to disconnect{" "}
						{sourceForm ? sourceForm.displayName : ""}?
					</ConfirmDialog>

					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
					>
						<MenuItem onClick={openModal}>
							<EditIcon className={classes.menuItem} /> Edit
						</MenuItem>
						{props.vms.driver === "GENERIC" ? (
							<MenuItem
								onClick={() => setConfirmDialogOpen(true)}
							>
								<DeleteIcon className={classes.menuItem} />{" "}
								Disconnect
							</MenuItem>
						) : null}
					</Menu>
					<Menu
						anchorEl={statusAnchor}
						keepMounted
						open={Boolean(statusAnchor)}
						onClose={handleStatusClose}
					>
						{SourceStatuses.map((status) => (
							<MenuItem
								key={status}
								value={status}
								onClick={() => handleStatusSelect(status)}
							>
								{CapitalizeFirst(status)}
							</MenuItem>
						))}
					</Menu>
				</>
			) : (
				<div style={{ textAlign: "center" }}>
					<div style={{ margin: "10px 0px 10px 0px" }}>
						<h3
							style={{
								color: "rgba(0,0,0,0.54)",
								height: "30px",
							}}
						>
							No Sources.
						</h3>
					</div>
				</div>
			)}
			<Snackbar
				open={Boolean(notification.message)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={(_, reason) => {
					if (reason === "clickaway") return;
					setNotification({ ...notification, message: "" });
				}}
				autoHideDuration={3000}
			>
				<div>
					<Alert
						onClose={() =>
							setNotification({ ...notification, message: "" })
						}
						alert={notification}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IsourcesTableOwnProps,
): IsourcesTableStateProps => {
	const sources = state.sources.keys[ownProps.name]
		? state.sources.keys[ownProps.name].content
		: null;
	const filters = sources ? state.sources.paging[ownProps.name] : null;

	const startIndex =
		filters?.page !== undefined && filters?.size
			? filters.page * filters.size
			: 0;
	const endIndex =
		filters?.page !== undefined && filters?.size
			? (filters.page + 1) * filters.size
			: 25;
	const filteredSources = sources
		? sources.filter((s) => {
				if (
					filters?.name &&
					filters.name !== "" &&
					!s.displayName
						.toLowerCase()
						.includes(filters.name.toLowerCase())
				) {
					return false;
				}
				if (
					filters?.status &&
					filters.status !== "" &&
					s.tasks[0].status !== filters.status
				) {
					return false;
				}
				return true;
		  })
		: null;

	const finalSources = filteredSources
		? filteredSources.slice(startIndex, endIndex)
		: null;

	if (filters) {
		filters.itemsCount = filteredSources ? filteredSources.length : 0;
	}

	const sourceIds = !sources ? [] : sources.map((s) => s.id);
	const sourceTasks = Object.keys(state.sourceTasks[ownProps.name] ?? {})
		.filter((key) => sourceIds.includes(key))
		.reduce<{ [key: string]: ISourceResponse }>((obj, key) => {
			obj[key] = state.sourceTasks[ownProps.name]?.[key];
			return obj;
		}, {});

	return {
		vms: state.vms.keys[ownProps.name],
		sources: finalSources,
		filter: state.sources.paging[ownProps.name],
		unfilteredSources: sources,
		sourceTasks,
	};
};

const mapDispatchToProps = (dispatch: any): IsourcesTableDispatch => {
	return {
		loadSources: (vmsName: string, query?: ISourceRequestInfo) =>
			getSourceAction(vmsName, query)(dispatch),
		updateSource: (vmsName: string, sourceId: string) =>
			updateSourceAction(vmsName, sourceId)(dispatch),
		updateSourceTask: (taskId: string) =>
			updateSourceTaskAction(taskId)(dispatch),
		startSourceTask: (
			vmsName: string,
			sourceId: string,
			query: IRunSourceTaskQuery,
		) => startSourceTaskAction(vmsName, sourceId, query)(dispatch),
		stopSourceTask: (vmsName: string, sourceId: string) =>
			stopSourceTaskAction(vmsName, sourceId)(dispatch),
		updateFilter: (vmsName: string, filter: ISourceRequestInfo) =>
			dispatch(setSourcesFilter(vmsName, filter)),
	};
};

const SourceTable = connect(mapStateToProps, mapDispatchToProps)(_SourcesTable);
export default SourceTable;
