import { connect } from "react-redux";
import SecretsView, {
	ISecretsViewPropsToDispatch,
	ISecretsViewPropsToState,
} from "./SecretsView";
import { AppState } from "../../store";
import { IgetSecretsRequest } from "../../store/Secrets/types";
import { getSecretsAction } from "../../store/Secrets/action";

const mapStateToProps = (state: AppState): ISecretsViewPropsToState => {
	return {
		error: state.secrets.erorr,
		secrets: state.secrets.data,
	};
};

const mapDispatchToProps = (dispatch: any): ISecretsViewPropsToDispatch => {
	return {
		loadSecrets: (request: IgetSecretsRequest) =>
			getSecretsAction(request)(dispatch),
	};
};

const SecretsContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SecretsView);
export default SecretsContainer;
