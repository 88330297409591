import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { THEME } from "../../config";
import { getSearchRequestImage } from "../../store/ImageSearch/action";
import {
	ImageSearchResult,
	ISearchRequestImage,
	ISearchResult,
} from "../../store/ImageSearch/types";
import { Skeleton } from "@material-ui/lab";
import { IRecord } from "../../store/Records/types";
import { getRecord } from "../../store/Records/action";
import { base64toBlob, downloadBlob } from "../../helpers/Utils";

type Props = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	result?: ImageSearchResult;
	recordIndex?: number;
	matchIndex?: number;
	errText?: string;
	setIndex: (index: number) => void;
	match?: ISearchResult;
	lowQualityRecord?: IRecord;
};

const useStyles = makeStyles(() => ({
	paper: {
		padding: 10,
		width: "100%",
		maxWidth: "100%",
		height: "100%",
	},
	content: {
		display: "flex",
		justifyContent: "center",
		gap: 10,
		overflow: "hidden",
	},
	img: {
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
	},
	imgWrapper: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	canvas: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
		zIndex: 10,
	},
	divider: {
		color: "red",
		backgroundColor: THEME.palette.secondary.light,
	},
	notFound: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.grey[800],
		padding: 4,
		height: "100%",
		width: "100%",
	},
	flex: {
		flex: 1,
		display: "flex",
	},
}));

const ImagesDialog = ({
	isOpen,
	setIsOpen,
	lowQualityRecord,
	result,
	recordIndex,
	matchIndex,
	errText,
	setIndex,
	match,
}: Props) => {
	const classes = useStyles();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const mainImgRef = useRef<HTMLImageElement>(null);
	const [isHorizontal, setIsHorizontal] = useState<boolean | undefined>(
		undefined,
	);
	const [maxWidth, setMaxWidth] =
		useState<React.CSSProperties["maxWidth"]>(undefined);
	const [maxHeight, setMaxHeight] =
		useState<React.CSSProperties["maxHeight"]>(undefined);
	const [searchImage, setSearchImage] = useState<
		ISearchRequestImage | undefined
	>(undefined);
	const [loadingImage, setLoadingImage] = useState(false);
	const [searchError, setSearchError] = useState("");
	const [record, setRecord] = useState<IRecord | undefined>(undefined);
	const [loadingRecord, setLoadingRecord] = useState(false);
	const [, setFirstDraw] = useState(true);

	const drawRects = useCallback(
		(hoveredIndex?: number) => {
			const attrs = searchImage?.imageAttributes;
			if (!canvasRef.current || !mainImgRef.current) return;
			const canvas = canvasRef.current;
			const mainImg = mainImgRef.current;
			setMaxWidth(undefined);
			setMaxHeight(undefined);

			const horizontal = mainImg.naturalHeight < mainImg.naturalWidth;
			setIsHorizontal((prev) => {
				if (prev !== horizontal) return horizontal;
				return prev;
			});

			setMaxWidth(
				(mainImg.naturalWidth / mainImg.naturalHeight) * mainImg.height,
			);
			setMaxHeight(
				(mainImg.naturalHeight / mainImg.naturalWidth) * mainImg.width,
			);

			const ctx = canvas.getContext("2d");
			if (!ctx) return;
			const { width: cWidth, height: cHeight } =
				canvas.getBoundingClientRect();

			canvas.width = cWidth;
			canvas.height = cHeight;

			const offset = cHeight / 2 - mainImg.height / 2;

			const widthRatio = mainImg.width / mainImg.naturalWidth;
			const heightRatio = mainImg.height / mainImg.naturalHeight;
			attrs?.forEach((attr, i) => {
				const rect = attr.rect;
				const isSelected = recordIndex === i;
				const x = rect.x * widthRatio;
				const y = rect.y * heightRatio + offset;
				const w = rect.width * widthRatio;
				const h = rect.height * heightRatio;
				const roll = attr.roll;

				ctx.strokeStyle = isSelected ? "green" : "red";
				ctx.lineWidth = isSelected ? 4 : 2;
				ctx.translate(x + w / 2, y + h / 2);
				ctx.rotate((roll * Math.PI) / 180);
				ctx.strokeRect(-w / 2, -h / 2, w, h);
				if (i === hoveredIndex) {
					ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
					ctx.fillRect(-w / 2, -h / 2, w, h);
				}
				ctx.rotate((-roll * Math.PI) / 180);
				ctx.translate(-(x + w / 2), -(y + h / 2));
			});
			setFirstDraw((prev) => {
				if (prev) setTimeout(() => drawRects(hoveredIndex), 1);
				return false;
			});
		},
		[searchImage, recordIndex],
	);

	useEffect(() => {
		if (!searchImage && result) {
			setLoadingImage(true);
			getSearchRequestImage(result.id)
				.then((res) => {
					setSearchImage(res);
				})
				.catch(() => setSearchError("Image not found"))
				.finally(() => setLoadingImage(false));
		}
	}, [searchImage, result]);

	useEffect(() => {
		if (match && matchIndex !== undefined) {
			setLoadingRecord(true);
			getRecord(match.watchlist, match.subject, matchIndex, {
				secret: match.secret,
			})
				.then((res) => {
					setRecord(res);
				})
				.finally(() => setLoadingRecord(false));
		}
	}, [match, matchIndex]);

	useEffect(() => {
		drawRects();

		window.addEventListener("resize", () => drawRects());
		return () => {
			window.removeEventListener("resize", () => drawRects());
		};
	}, [drawRects]);

	const isMouseOnRect = (e: React.MouseEvent<HTMLCanvasElement>) => {
		if (!searchImage?.imageAttributes || !mainImgRef.current) return -1;
		const mainImg = mainImgRef.current;
		const attrs = searchImage.imageAttributes;
		const { height: cHeight } =
			canvasRef.current?.getBoundingClientRect() || {
				width: 0,
				height: 0,
			};

		const widthRatio = mainImg.width / mainImg.naturalWidth;
		const heightRatio = mainImg.height / mainImg.naturalHeight;
		const offset = cHeight / 2 - mainImg.height / 2;

		const x = e.nativeEvent.offsetX / widthRatio;
		const y = (e.nativeEvent.offsetY - offset) / heightRatio;

		return attrs.findIndex((attr) => {
			const rect = attr.rect;
			const x1 = rect.x;
			const y1 = rect.y;
			const x2 = rect.x + rect.width;
			const y2 = rect.y + rect.height;

			return x1 <= x && x <= x2 && y1 <= y && y <= y2;
		});
	};

	const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
		if (!canvasRef.current) return;
		const index = isMouseOnRect(e);
		canvasRef.current.style.cursor = index > -1 ? "pointer" : "default";
		drawRects(index);
	};

	const handleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const index = isMouseOnRect(e);
		if (index > -1) {
			setIndex(index);
			setIsOpen(false);
		}
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === "s") {
				e.preventDefault();
				if (searchImage) {
					const blob = base64toBlob(searchImage.image, "image/jpg");
					downloadBlob(
						blob,
						result?.id ? `${result.id}.jpg` : "searchImage.jpg",
					);
				}
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [searchImage, result]);

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			classes={{
				paper: classes.paper,
			}}
		>
			<DialogContent className={classes.content}>
				<>
					<div
						className={classes.flex}
						style={{
							justifyContent: "flex-end",
						}}
					>
						<div
							className={classes.imgWrapper}
							style={{
								maxWidth:
									!searchImage || searchError
										? "100%"
										: maxWidth,
								width: "100%",
								height: isHorizontal ? "unset" : "100%",
								visibility:
									isHorizontal === undefined && !loadingImage
										? "hidden"
										: "visible",
							}}
						>
							{!loadingImage &&
							(searchImage?.image || result?.thumb) ? (
								<>
									<img
										src={`data:image/jpeg;base64,${
											searchImage?.image ?? result?.thumb
										}`}
										className={classes.img}
										style={{
											width: "100%",
											height: isHorizontal
												? "auto"
												: "100%",
											maxWidth,
											maxHeight,
										}}
										alt="search"
										ref={mainImgRef}
										onLoad={() => drawRects()}
									/>
									<canvas
										ref={canvasRef}
										className={classes.canvas}
										onClick={handleClick}
										onMouseMove={handleMouseMove}
										style={{
											display:
												isHorizontal === undefined
													? "none"
													: "unset",
										}}
									/>
								</>
							) : searchError ? (
								<div className={classes.notFound}>
									{searchError}
								</div>
							) : (
								<Skeleton
									variant="rect"
									height="100%"
									style={{ flex: 1 }}
									className={classes.img}
								/>
							)}
						</div>
					</div>
					<Divider
						orientation="vertical"
						classes={{
							root: classes.divider,
						}}
					/>
					<div
						className={classes.flex}
						style={{
							justifyContent: "flex-start",
						}}
					>
						{loadingRecord ? (
							<Skeleton
								variant="rect"
								height="100%"
								style={{ flex: 1 }}
								className={classes.img}
							/>
						) : record?.image || lowQualityRecord?.image ? (
							<div
								className={classes.imgWrapper}
								style={{ justifyContent: "flex-start" }}
							>
								<img
									src={`data:image/jpeg;base64,${
										record?.image ??
										lowQualityRecord?.image ??
										""
									}`}
									className={classes.img}
									style={{ height: "100%" }}
									alt="watchlist"
								/>
							</div>
						) : (
							<Box className={classes.notFound}>{errText}</Box>
						)}
					</div>
				</>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setIsOpen(false)} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImagesDialog;
