import React from "react";
import {
	List,
	ListItem,
	ListItemText,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { ISecretPermission } from "../../../../store/Secrets/types";
import { useStyles } from "./UserList";
import { ICurrentUser } from "../../../../store/Users/types";

interface ISecretPermissionProps {
	currentUser: ICurrentUser;
	perms: ISecretPermission[];
	handleToggle(name: string): void;
	handlePermissionClick(
		index: number,
		permission: string,
		flag: boolean,
	): void;
	isReadOnly: boolean;
}

const SecretPermissions: React.FC<ISecretPermissionProps> = ({
	currentUser,
	perms,
	handleToggle,
	handlePermissionClick,
	isReadOnly,
}) => {
	const classes = useStyles();
	const style = {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	} as React.CSSProperties;

	return (
		<List dense component="div" role="list" className={classes.list}>
			{perms.map((perm, i) => (
				<ListItem
					key={perm.user}
					role="listitem"
					button
					onClick={() => {
						if (perm.user === currentUser.userName) return;
						handleToggle(perm.user);
					}}
					disabled={isReadOnly}
				>
					<Tooltip
						title={perm.user}
						aria-label={perm.user}
						disableHoverListener={
							perm.user?.split("").length < 20 || true
						}
					>
						<ListItemText
							primaryTypographyProps={{ style }}
							id={perm.user}
							primary={perm.user}
						/>
					</Tooltip>
					<Tooltip
						title="Allows change objects belonging to this secret"
						aria-label="write"
					>
						<FormControlLabel
							style={{
								height: "0.875rem",
							}}
							value="Write"
							label={
								<span
									style={{
										fontSize: "0.875rem",
									}}
								>
									Write
								</span>
							}
							labelPlacement="end"
							control={
								<Checkbox
									checked={perm.write}
									style={{
										transform: "scale(0.875)",
									}}
									disableRipple
								/>
							}
							onClick={(e) => {
								handlePermissionClick(i, "write", perm.write);
								e.stopPropagation();
								e.preventDefault();
							}}
						/>
					</Tooltip>
					<Tooltip
						title="Allows to change this secret"
						aria-label="manage"
					>
						<FormControlLabel
							style={{
								height: "0.875rem",
							}}
							value="Manage"
							label={
								<span
									style={{
										fontSize: "0.875rem",
									}}
								>
									Manage
								</span>
							}
							labelPlacement="end"
							control={
								<Checkbox
									checked={perm.manage}
									style={{
										transform: "scale(0.875)",
									}}
									disableRipple
									disabled={
										perm.user === currentUser.userName
									}
								/>
							}
							onClick={(e) => {
								if (perm.user === currentUser.userName) return;
								handlePermissionClick(i, "manage", perm.manage);
								e.stopPropagation();
								e.preventDefault();
							}}
						/>
					</Tooltip>
				</ListItem>
			))}
		</List>
	);
};

export default SecretPermissions;
