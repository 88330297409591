const protoString = `
syntax = "proto3";
package Neurotec.SentiVeillance;

enum AgeGroup {
   Unknown = 0;
   Adult = 1;
   Child = 2;
   Senior = 3;
   Teenager = 4;
}
message AgeGroupEntry {
   AgeGroup Group = 1;
   float GroupConfidence = 2;
   float AdultConfidence = 3;
   float ChildConfidence = 4;
   float SeniorConfidence = 5;
   float TeenagerConfidence = 6;
}
message AttributeConf {
   string Value = 1;
   int32 Confidence = 2;
}
message Attributes {
   int32 Age = 1;
   AttributeConf Mask = 2;
   AttributeConf Hat = 3;
   AttributeConf Gender = 4;
   AttributeConf Beard = 5;
   AttributeConf Mustache = 6;
   AttributeConf Glasses = 7;
   AttributeConf DarkGlasses = 8;
   AttributeConf MouthOpen = 9;
   AttributeConf Blink = 10;
   AttributeConf Smile = 11;
   Emotions Emotions = 12;
   Ethnicity Ethnicity = 13;
}
message ClothingDetails {
   AttributeConf Gender = 1;
   repeated NameConfidencePair Values = 2;
   NameConfidencePair Headwear = 3;
   NameConfidencePair Torso = 4;
   NameConfidencePair Arms = 5;
   NameConfidencePair Legs = 6;
   NameConfidencePair Feet = 7;
}
message Color {
   float Red = 1;
   float Orange = 2;
   float Yellow = 3;
   float Green = 4;
   float Blue = 5;
   float Silver = 6;
   float White = 7;
   float Black = 8;
   float Brown = 9;
   float Gray = 10;
}
message Direction {
   float North = 1;
   float NorthEast = 2;
   float East = 3;
   float SouthEast = 4;
   float South = 5;
   float SouthWest = 6;
   float West = 7;
   float NorthWest = 8;
}
message Emotions {
   int32 NeutralConfidence = 1;
   int32 AngerConfidence = 2;
   int32 ContemptConfidence = 3;
   int32 DisgustConfidence = 4;
   int32 HappinessConfidence = 5;
   int32 SadnessConfidence = 6;
   int32 SurpriseConfidence = 7;
}
enum EngineType {
   Faces = 0;
   VehicleHuman = 1;
   ALPR = 2;
}
message Ethnicity {
   int32 WhiteConfidence = 1;
   int32 BlackConfidence = 2;
   int32 AsianConfidence = 3;
   int32 IndianConfidence = 4;
   int32 HispanicConfidence = 5;
   int32 ArabianConfidence = 6;
}
message FaceDetails {
   Rect Rectangle = 1;
   bool ContainsDetails = 2;
   Point LeftEye = 3;
   Point RightEye = 4;
   int32 Sharpness = 5;
   float Quality = 6;
   float Roll = 7;
   float Pitch = 8;
   float Yaw = 9;
   repeated FaceMatch Matches = 10;
   Thumbnail Thumbnail = 11;
   Attributes Attributes = 12;
}
message FaceMatch {
   string Secret = 1;
   string Watchlist = 2;
   string Id = 3;
   int32 Score = 4;
}
message LicensePlate {
   string Value = 1;
   string Origin = 2;
   float DetectionConfidence = 3;
   float OcrConfidence = 4;
   float CharacterHeight = 5;
   Rect BoundingBox = 6;
   Rect Rectangle = 7;
   float Rotation = 8;
   string Type = 9;
   Thumbnail Thumbnail = 10;
   repeated LicensePlateMatch Matches = 11;
   string Region = 12;
   string FormattedValue = 13;
   bool IsOccluded = 14;
   float OcclusionConfidence = 15;
}
message LicensePlateMatch {
   string Secret = 1;
   string Watchlist = 2;
   string Id = 3;
}
message NameConfidencePair {
   string Name = 1;
   float Confidence = 2;
}
message NameStringPair {
   string Name = 1;
   string Value = 2;
}
message ObjectType {
   float Car = 1;
   float Person = 2;
   float Bus = 3;
   float Truck = 4;
   float Bike = 5;
}
enum Orientation {
   Right = 0;
   RearRight = 1;
   Rear = 2;
   RearLeft = 3;
   Left = 4;
   FrontLeft = 5;
   Front = 6;
   FrontRight = 7;
}
message Point {
   int32 X = 1;
   int32 Y = 2;
}
message Rect {
   int32 X = 1;
   int32 Y = 2;
   int32 Width = 3;
   int32 Height = 4;
}
message SubjectEvent {
   int64 TimeStamp = 1;
   string Type = 2;
   string Vms = 3;
   string SourceId = 4;
   string TaskId = 5;
   repeated EngineType EngineType = 6 [packed = false];
   repeated EngineType SubjectType = 7 [packed = false];
   string SubjectId = 8;
   string SecretId = 9;
   int32 ImageWidth = 10;
   int32 ImageHeight = 11;
   TrackDetails Best = 12;
   repeated TrackDetails Details = 13;
   TriggeredRule TriggeredRule = 14;
   repeated TriggeredRule Rules = 15;
}
message Thumbnail {
   bytes Value = 1;
   Rect Rectangle = 2;
   float Rotation = 3;
}
message TrackDetails {
   int32 Type = 1;
   int64 TimeStamp = 2;
   Rect Rectangle = 3;
   Rect ThumbnailRectangle = 4;
   repeated FaceDetails Faces = 5;
   repeated FaceMatch Matches = 6;
   repeated LicensePlate LicensePlates = 7;
   VehicleHumanModel VehicleHuman = 8;
}
message TriggeredRule {
   string Name = 1;
   string Secret = 2;
   TriggeringWatchlist TriggeringWatchlist = 3;
}
message TriggeringWatchlist {
   string Id = 1;
   string Secret = 2;
}
message VehicleDetailsEntry {
   Orientation Orientation = 1;
   float OrientationAngle = 2;
   float OrientationAngleConfidence = 3;
   repeated NameConfidencePair Tags = 4;
   repeated VehicleModel Models = 5;
}
message DateTime {
  sint64 value = 1;
  TimeSpanScale scale = 2;
  DateTimeKind kind = 3;
  enum TimeSpanScale {
    DAYS = 0;
    HOURS = 1;
    MINUTES = 2;
    SECONDS = 3;
    MILLISECONDS = 4;
	 TICKS = 5;
    MINMAX = 15;
  }
  enum DateTimeKind
  {     
     UNSPECIFIED = 0;
     UTC = 1;
     LOCAL = 2;
   }
}
message VehicleHumanModel {
   float DetectionConfidence = 1;
   Rect Rectangle = 2;
   ObjectType ObjectTypeConfidences = 3;
   Color ColorConfidences = 4;
   Direction DirectionConfidences = 5;
   Thumbnail Thumbnail = 6;
   ClothingDetails ClothingDetails = 7;
   VehicleDetailsEntry VehicleDetails = 8;
   AgeGroupEntry AgeGroupDetails = 9;
   DateTime TimeStamp = 10;
}
message VehicleModel {
   float Confidence = 1;
   repeated NameStringPair MakeModel = 2;
}
`;

export default protoString;
