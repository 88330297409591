import React from "react";
import {
	Divider,
	makeStyles,
	CircularProgress,
	Container,
} from "@material-ui/core";
import { IWatchlist } from "../../store/Watchlists/types";
import { ISecret } from "../../store/Secrets/types";
import WatchlistField from "./WatchlistField";
import { checkPermissions } from "../../containers/Layout/Layout";
import { connect } from "react-redux";
import { getSecretAction } from "../../store/Secrets/action";
import { AppState } from "../../store";
import { THEME, WATCHLIST_DESCRIPTION_LIMIT } from "../../config";

const useStyles = (selected: number, index: number) =>
	makeStyles(() => ({
		watchlistItem: {
			"&:hover": {
				background: "rgba(224, 224, 224, 1)",
			},
			background: selected === index ? "rgba(224, 224, 224, 1)" : "white",
		},
		fields: {
			padding: 5,
		},
		secretText: {
			color: THEME.palette.error.contrastText,
			backgroundColor: THEME.palette.error.dark,
			width: "fit-content",
		},
	}));

interface IWatchlistItemOwnProps {
	index: number;
	watchlist: IWatchlist;
	selected: number;
	setSelected: (watchlist: number, subject: number) => void;
	dblClick(option: string): void;
}

interface IWatchlistItemStateProps {
	secret?: ISecret;
}

interface IWatchlistItemDispatchProps {
	loadSecret(secretId: string): void;
}

interface IWatchlistsItemProps
	extends IWatchlistItemOwnProps,
		IWatchlistItemStateProps,
		IWatchlistItemDispatchProps {}

const limitWatchlistDescription = (description: string) =>
	description.length <= WATCHLIST_DESCRIPTION_LIMIT
		? description
		: description.slice(0, WATCHLIST_DESCRIPTION_LIMIT) + "(...)";

const WatchlistsItem: React.FC<IWatchlistsItemProps> = ({
	index,
	watchlist,
	selected,
	setSelected,
	dblClick,
	secret,
	loadSecret,
}) => {
	const [loading, setLoading] = React.useState<boolean>(true);

	const classes = useStyles(selected, index)();

	React.useEffect(() => {
		if (watchlist.secret) {
			loadSecret(watchlist.secret);
		}
		setLoading(false);
	}, [watchlist, loadSecret]);

	const handleWatchlistClick = () => {
		setSelected(index, 0);
	};

	const handleWatchlistDoubleClick = () => {
		if (
			checkPermissions([
				"ROLE_ADMINISTRATOR",
				"ROLE_WATCHLIST_ADMINISTRATOR",
			])
		) {
			dblClick("edit");
		}
	};

	return (
		<Container className={classes.watchlistItem} disableGutters>
			{loading ? (
				<CircularProgress />
			) : (
				<div
					onClick={handleWatchlistClick}
					onDoubleClick={handleWatchlistDoubleClick}
					className={classes.fields}
				>
					<WatchlistField property="ID" field={watchlist.id} />
					<WatchlistField
						property="Type"
						field={
							watchlist.type === "LicensePlates"
								? "License Plates"
								: watchlist.type
						}
					/>
					{watchlist.secret && secret ? (
						<WatchlistField
							property="Secret"
							field={secret.name}
							description={secret.description}
							className={classes.secretText}
						/>
					) : null}
					{watchlist.description ? (
						<WatchlistField
							property="Description"
							field={limitWatchlistDescription(
								watchlist.description,
							)}
							description={
								watchlist.description.length >
								WATCHLIST_DESCRIPTION_LIMIT
									? watchlist.description
									: undefined
							}
						/>
					) : null}
				</div>
			)}
			<Divider />
		</Container>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IWatchlistItemOwnProps,
): IWatchlistItemStateProps => {
	let stateSecret = undefined;
	if (ownProps.watchlist.secret) {
		stateSecret = state.secrets.keys[ownProps.watchlist.secret];
	}
	return { secret: stateSecret };
};

const mapDispatchToProps = (dispatch: any): IWatchlistItemDispatchProps => {
	return {
		loadSecret: (secretId: string) => getSecretAction(secretId)(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistsItem);
