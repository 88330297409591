import { connect } from "react-redux";
import UsersView, {
	IUsersViewDispatchProps,
	IUsersViewStateProps,
} from "./UsersView";
import { AppState } from "../../store";
import { IUserRequest } from "../../store/Users/types";
import { getAuthoritiesAction } from "../../store/Authorities/action";
import { getUsersAction, setUsersFilterAction } from "../../store/Users/action";

const mapStateToProps = (state: AppState): IUsersViewStateProps => {
	return {
		error: state.users.erorr || state.authorities.erorr,
		users: state.users.data,
		authorities: state.authorities.data,
		filter: state.users.paging,
	};
};

const mapDispatchToProps = (dispatch: any): IUsersViewDispatchProps => {
	return {
		loadUsers: (filter: IUserRequest) => getUsersAction(filter)(dispatch),
		loadAuthorities: () => getAuthoritiesAction()(dispatch),
		setFilter: (filter: IUserRequest) =>
			dispatch(setUsersFilterAction(filter)),
	};
};

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(UsersView);
export default UsersContainer;
