import React from "react";
import {
	Divider,
	Grid,
	Typography,
	makeStyles,
	Button,
} from "@material-ui/core";
import { IRule, RuleAction, Trigger } from "../../store/Rules/types";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import RulesField from "./RulesField";
import { connect } from "react-redux";
import { getSecretAction } from "../../store/Secrets/action";
import { AppState } from "../../store";
import { ISecret } from "../../store/Secrets/types";
import { generateActionText } from "../Forms/RuleForm/Actions/ActionItem";
import { getDateChipLabel } from "../Forms/RuleForm/Triggers/Common/DateTrigger";
import { getAgeChipLabel } from "../Forms/RuleForm/Triggers/Faces/AgeTrigger";
import { getGenderChipLabel } from "../Forms/RuleForm/Triggers/Faces/GenderTrigger";
import { getSourceChipLabel } from "../Forms/RuleForm/Triggers/Common/SourceTrigger";
import { getWatchlistChipLabel } from "../Forms/RuleForm/Triggers/Common/WatchlistTrigger";
import { getTimeChipLabel } from "../Forms/RuleForm/Triggers/Common/TimeTrigger";
import { getOptionalChipLabel } from "../Forms/RuleForm/Triggers/Faces/ThresholdTrigger";
import Triggers from "../Forms/RuleForm/Triggers/Triggers";
import clsx from "clsx";
import { THEME } from "../../config";

const useStyles = makeStyles(() => ({
	item: {
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		marginBottom: 4,
		"&:hover": {
			backgroundColor: "#f3f3f4",
		},
		borderLeft: `4px solid ${THEME.palette.success.main}`,
	},
	button: {
		textTransform: "none",
		marginLeft: 4,
	},
	disabled: {
		opacity: 0.6,
		borderLeft: `4px solid ${THEME.palette.grey[600]}`,
	},
	secretText: {
		color: THEME.palette.error.contrastText,
		backgroundColor: THEME.palette.error.dark,
		paddingTop: "1px",
		paddingBottom: "1px",
		borderRadius: "3px",
		width: "fit-content",
	},
}));

interface IRulesItemOwnProps {
	rule: IRule;
	onEdit(rule: IRule): void;
	onDelete(rule: IRule): void;
	vmsName: string;
	areRulesAuthorized: boolean;
	onView(rule: IRule): void;
}

interface IRulesItemStateProps {
	secret?: ISecret;
}

interface IRulesItemDispatchProps {
	loadSecret(secretId: string): void;
}

interface IRulesItemProps
	extends IRulesItemOwnProps,
		IRulesItemStateProps,
		IRulesItemDispatchProps {}

function getTriggerCount(trigger: Trigger, count: number) {
	if (trigger.type === "MultiTrigger") {
		for (let i = 0; i < trigger.triggers.length; i++) {
			if (trigger.triggers[i].type === "MultiTrigger")
				count += getTriggerCount(trigger.triggers[i], 1);
			else count++;
		}
		return count;
	}
	return count + 1;
}

function getActionsTooltip(actions: RuleAction[]): React.ReactNode {
	return (
		<>
			{actions.map((action, i) => (
				<div key={i}>{generateActionText(action)}</div>
			))}
		</>
	);
}

export const createTriggerLabel = (trigger: Trigger, vmsName: string) => {
	switch (trigger.type) {
		case "DateTrigger":
			return getDateChipLabel(trigger);
		case "AgeTrigger":
			return getAgeChipLabel(trigger);
		case "GenderTrigger":
			return getGenderChipLabel(trigger);
		case "SourceTrigger":
			return getSourceChipLabel(trigger, vmsName);
		case "FacesWatchlistTrigger":
			return getWatchlistChipLabel(trigger);
		case "LicensePlateWatchlistTrigger":
			return getWatchlistChipLabel(trigger);
		case "TimeTrigger":
			return getTimeChipLabel(trigger)!;
		case "ThresholdTrigger":
			return getOptionalChipLabel(trigger);
		case "MultiTrigger":
			return "No triggers selected.";
	}
};

const getTriggerTooltip = (
	trigger: Trigger,
	vmsName: string,
): React.ReactNode => {
	return (
		<Triggers
			triggers={trigger}
			vmsName={vmsName}
			onTrigger={() => ""}
			isReadOnly={false}
			showOnly={true}
		/>
	);
};

const _RulesItem: React.FC<IRulesItemProps> = ({
	rule,
	onEdit,
	onDelete,
	onView,
	secret,
	loadSecret,
	vmsName,
	areRulesAuthorized,
}) => {
	const classes = useStyles();
	React.useEffect(() => {
		if (rule.secret) {
			loadSecret(rule.secret);
		}
	}, [rule, loadSecret]);

	const handleEditButton = () => onEdit(rule);

	const handleDblClick = () =>
		areRulesAuthorized ? handleEditButton() : handleViewButton();

	const handleDeleteButton = () => onDelete(rule);

	const handleViewButton = () => onView(rule);

	return (
		<div onDoubleClick={() => handleDblClick()}>
			<Grid
				container
				className={clsx(
					classes.item,
					!rule.enabled && classes.disabled,
				)}
			>
				<Grid item xs={12}>
					<Grid container>
						<Grid item>
							<Typography variant="h5">
								Name: {rule.name}
							</Typography>
						</Grid>
						{areRulesAuthorized ? (
							<>
								<Grid item>
									<Button
										className={classes.button}
										startIcon={<EditIcon />}
										onClick={handleEditButton}
									>
										Edit
									</Button>
								</Grid>
								<Grid item>
									<Button
										className={classes.button}
										startIcon={<DeleteIcon />}
										onClick={handleDeleteButton}
									>
										Delete
									</Button>
								</Grid>
							</>
						) : (
							<Grid item>
								<Button
									className={classes.button}
									startIcon={<SearchIcon />}
									onClick={handleViewButton}
								>
									View
								</Button>
							</Grid>
						)}
					</Grid>
				</Grid>
				{rule.secret ? (
					<Grid item xs={12}>
						<RulesField
							property="Secret"
							field={secret?.name ?? ""}
							tooltipTitle={
								secret?.description === null
									? undefined
									: secret?.description
							}
							className={classes.secretText}
						/>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<RulesField
						property="Triggers"
						field={getTriggerCount(rule.triggers, 0)}
						tooltipTitle={getTriggerTooltip(rule.triggers, vmsName)}
					/>
				</Grid>
				<Grid item xs={12}>
					<RulesField
						property="Actions"
						field={rule.actions.length}
						tooltipTitle={
							rule.actions.length > 0
								? getActionsTooltip(rule.actions)
								: undefined
						}
					/>
				</Grid>
				{rule.description ? (
					<Grid item xs={12}>
						<RulesField
							property="Description"
							field={rule.description}
						/>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IRulesItemOwnProps,
): IRulesItemStateProps => {
	let stateSecret = undefined;
	if (ownProps.rule.secret) {
		stateSecret = state.secrets.keys[ownProps.rule.secret];
	}
	return { secret: stateSecret };
};

const mapDispatchToProps = (dispatch: any): IRulesItemDispatchProps => {
	return {
		loadSecret: (secretId: string) => getSecretAction(secretId)(dispatch),
	};
};

const RulesItem = connect(mapStateToProps, mapDispatchToProps)(_RulesItem);
export default RulesItem;
