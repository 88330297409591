import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ImageSearch } from "../../store/ImageSearch/types";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import { camelCaseToReadable } from "../../helpers/Filter";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { getSecretAction, getSecretsAction } from "../../store/Secrets/action";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { AsyncActionStatus } from "../../store/AsyncState";
import { THEME } from "../../config";
import { BrokenImageRounded, Info } from "@material-ui/icons";
import { setScroll } from "../../store/ImageSearch/action";

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: "white",
		flex: 1,
		"& th": {
			whiteSpace: "nowrap",
		},
	},
	img: {
		height: "100%",
		maxHeight: "8rem",
		maxWidth: "8rem",
		objectFit: "contain",
	},
	imgWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	row: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
	},
	secretText: {
		color: THEME.palette.error.contrastText,
		backgroundColor: THEME.palette.error.dark,
		padding: "2px 6px",
		borderRadius: "3px",
		width: "fit-content",
	},
	empty: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	overlay: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
	},
	failedToLoadImage: {
		width: "60%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "6rem",
		color: THEME.palette.error.dark,
	},
}));

type Props = {
	data: ImageSearch[];
	canSeeSecrets: boolean;
	page: number;
	loading: boolean;
};

const ListTable = ({ data, canSeeSecrets, page, loading }: Props) => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const scrollRef = useRef<HTMLTableElement>(null);
	const [isFirstLoad, onFirstLoad] = useReducer(() => false, true);
	const [prevPage, setPrevPage] = useState<number | null>(null);
	const secrets = useSelector((state: AppState) => state.secrets);
	const scrolledY = useSelector(
		(state: AppState) => state.imageSearches.scrolledY,
	);

	const loadSecrets = useCallback(async () => {
		const secretsRequested: string[] = [];
		if (isFirstLoad) {
			onFirstLoad();
			await getSecretsAction({})(dispatch);
		}

		if (secrets.status === AsyncActionStatus.SUCCEEDED) {
			for (const item of data) {
				if (
					item.secret &&
					!secrets.keys[item.secret] &&
					!secretsRequested.includes(item.secret)
				) {
					secretsRequested.push(item.secret);
					await getSecretAction(item.secret)(dispatch);
				}
			}
		}
	}, [dispatch, data, secrets, isFirstLoad]);

	useEffect(() => {
		loadSecrets();
	}, [loadSecrets]);

	useEffect(() => {
		const ref = scrollRef.current;
		if (!ref) return;
		if (ref.scrollTop === 0) ref.scrollTop = scrolledY;
		const onScroll = () => {
			dispatch(setScroll(ref.scrollTop));
		};
		ref.addEventListener("scroll", onScroll);
		return () => ref.removeEventListener("scroll", onScroll);
	}, [scrollRef, dispatch, scrolledY]);

	useEffect(() => {
		if (prevPage !== page) {
			setPrevPage(page);
			if (prevPage !== null) scrollRef.current?.scrollTo(0, 0);
		}
	}, [page, prevPage]);

	return (
		<TableContainer className={classes.root} ref={scrollRef}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell align="center">Image</TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Hits</TableCell>
						<TableCell>Matching threshold</TableCell>
						{canSeeSecrets && <TableCell>Secret</TableCell>}
					</TableRow>
				</TableHead>
				<TableBody style={{ position: "relative" }}>
					{loading && (
						<tr className={classes.overlay}>
							<td className={classes.overlay}>
								<Skeleton
									variant="rect"
									width="100%"
									height="100%"
								/>
							</td>
						</tr>
					)}
					{data.map((row) => (
						<TableRow
							key={row.id}
							onClick={() =>
								history.push(`/watchlists/search/${row.id}`)
							}
							className={classes.row}
						>
							<TableCell style={{ padding: "4px 2px" }}>
								<div className={classes.imgWrapper}>
									{row.thumb ? (
										<img
											alt="Person"
											src={`data:image/jpeg;base64,${row.thumb}`}
											className={classes.img}
										/>
									) : (
										<BrokenImageRounded
											className={classes.img}
											color="action"
											style={{
												width: "100%",
												height: "100%",
											}}
										/>
									)}
								</div>
							</TableCell>
							<TableCell>
								{moment(row.time).format("YYYY-MM-DD HH:mm:ss")}
							</TableCell>
							<TableCell>
								<Tooltip title={row.error ?? ""}>
									<Typography
										style={{ display: "flex", gap: 2 }}
									>
										{camelCaseToReadable(row.status ?? "")}
										{row.error && (
											<Info
												style={{
													fill: THEME.palette.error
														.dark,
												}}
											/>
										)}
									</Typography>
								</Tooltip>
							</TableCell>
							<TableCell>{row.resultCount ?? ""}</TableCell>
							<TableCell>{row.matchingThreshold ?? ""}</TableCell>
							{canSeeSecrets && (
								<TableCell>
									{secrets?.keys[row.secret ?? ""] && (
										<Tooltip
											title={
												secrets.keys[row.secret ?? ""]
													?.description
													? `Description: ${
															secrets.keys[
																row.secret ?? ""
															].description
													  }`
													: ""
											}
										>
											<Typography
												className={classes.secretText}
											>
												{
													secrets.keys[
														row.secret ?? ""
													]?.name
												}
											</Typography>
										</Tooltip>
									)}
								</TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ListTable;
