import React from "react";
import { connect } from "react-redux";
import {
	createRule,
	deleteRule,
	editRule,
	getRulesAction,
	setRulesQuery,
} from "../../store/Rules/action";
import {
	ICreateRule,
	IEditRule,
	IgetRulesRequest,
	IRule,
	RulesDataContainer,
} from "../../store/Rules/types";
import { IAlert } from "../UI/Alert";
import RulesList from "./RulesList";
import CenteredLoading from "../UI/CenteredLoading";
import ConfirmDialog from "../UI/ConfirmDialog";
import RuleDialog from "../Dialogs/RuleDialog";
import { handleTaskAction } from "../../helpers/VMSCrud";

interface IRulesOwnProps {
	vms: string;
	onAlert: (alert: IAlert) => void;
	rules: RulesDataContainer;
	filter: IgetRulesRequest;
	openDialog: boolean;
	closeDialog(): void;
	onDialog(): void;
	areRulesAuthorized: boolean;
}

interface IRulesDispatchProps {
	loadRules(vmsName: string, query: IgetRulesRequest): void;
	updateFilter(vmsName: string, filter: IgetRulesRequest): void;
}

interface IRulesProps extends IRulesOwnProps, IRulesDispatchProps {}

const _Rules: React.FC<IRulesProps> = ({
	vms,
	onAlert,
	rules,
	filter,
	loadRules,
	updateFilter,
	openDialog,
	closeDialog,
	onDialog,
	areRulesAuthorized,
}) => {
	const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
	const [selectedRule, setSelectedRule] = React.useState<IRule | null>(null);
	const [loading, setLoading] = React.useState(false);
	const [isReadOnly, setIsReadOnly] = React.useState(false);

	React.useEffect(() => {
		loadRules(vms, filter);
	}, [filter, loadRules, vms]);

	const handleDeleteConfirmClose = () => {
		setSelectedRule(null);
		setOpenConfirmDialog(false);
	};

	const handleRuleFormClose = () => {
		loadRules(vms, filter);
		closeDialog();
		setSelectedRule(null);
	};

	const handleDeleteRule = (rule: IRule) => {
		setSelectedRule(rule);
		setOpenConfirmDialog(true);
	};

	const handleEditRule = (rule: IRule) => {
		setSelectedRule(rule);
		onDialog();
	};

	const handleViewRule = (rule: IRule) => {
		setSelectedRule(rule);
		setIsReadOnly(true);
		onDialog();
	};

	const handleDeleteSuccess = () => {
		onAlert({
			message: "Rule successfully deleted.",
			variant: "success",
		});
		if (filter.page && filter.page > 0 && rules.content.length === 1) {
			updateFilter(vms, {
				...filter,
				page: filter.page - 1,
			});
		} else {
			loadRules(vms, filter);
		}
		setSelectedRule(null);
		setLoading(false);
		setOpenConfirmDialog(false);
	};

	const handleDeleteError = (msg: string) => {
		setLoading(false);
		onAlert({
			message: "Failed to delete rule: " + msg,
			variant: "error",
		});
		setOpenConfirmDialog(false);
	};

	const handleDeleteAction = async () => {
		await handleTaskAction(
			deleteRule,
			handleDeleteSuccess,
			handleDeleteError,
			vms,
			selectedRule as IRule,
		);
	};

	const handleEditSuccess = () => {
		onAlert({
			message: "Rule successfully updated.",
			variant: "success",
		});
		loadRules(vms, filter);
		handleRuleFormClose();
		setLoading(false);
	};

	const handleEditError = (msg: string) => {
		onAlert({
			message: "Failed to update rule: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleEditAction = async (rule: IEditRule) => {
		if (selectedRule) {
			await handleTaskAction(
				editRule,
				handleEditSuccess,
				handleEditError,
				vms,
				selectedRule.name,
				rule,
				selectedRule.secret,
			);
		}
	};

	const handleCreateSuccess = () => {
		onAlert({
			message: "Rule successfully created.",
			variant: "success",
		});
		loadRules(vms, filter);
		closeDialog();
		setLoading(false);
	};

	const handleCreateError = (msg: string) => {
		onAlert({
			message: "Failed to create rule: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleCreateAction = async (rule: ICreateRule) => {
		await handleTaskAction(
			createRule,
			handleCreateSuccess,
			handleCreateError,
			vms,
			rule,
		);
	};

	return (
		<>
			{rules ? (
				<RulesList
					rulesContent={rules.content}
					onEdit={handleEditRule}
					onDelete={handleDeleteRule}
					onView={handleViewRule}
					vmsName={vms}
					areRulesAuthorized={areRulesAuthorized}
				/>
			) : (
				<CenteredLoading height={15} column />
			)}
			<ConfirmDialog
				open={openConfirmDialog}
				onConfirm={handleDeleteAction}
				onClose={handleDeleteConfirmClose}
				loading={loading}
				onLoading={() => setLoading(true)}
			>
				Are you sure you want to delete this rule?
			</ConfirmDialog>
			<RuleDialog
				open={openDialog}
				close={handleRuleFormClose}
				data={selectedRule !== null ? selectedRule : undefined}
				onConfirm={
					selectedRule !== null
						? handleEditAction
						: handleCreateAction
				}
				vmsName={vms}
				onLoading={() => setLoading(true)}
				loading={loading}
				isReadOnly={isReadOnly}
				onAlert={onAlert}
			/>
		</>
	);
};

const mapDispatchToProps = (dispatch: any): IRulesDispatchProps => {
	return {
		loadRules: (vmsName: string, query: IgetRulesRequest) =>
			getRulesAction(vmsName, query)(dispatch),
		updateFilter: (vmsName: string, filter: IgetRulesRequest) =>
			dispatch(setRulesQuery(vmsName, filter)),
	};
};

const Rules = connect(null, mapDispatchToProps)(_Rules);
export default Rules;
