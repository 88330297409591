import React from "react";
import moment from "moment";
import {
	makeStyles,
	Box,
	Select,
	TextField,
	Tabs,
	Tab,
	MenuItem,
} from "@material-ui/core";
import { RELATIVE_OPTIONS } from "./constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../UI/DatePopoverCalendar.css";
import TabPanel from "../UI/TabPanel";
import "date-fns";
import { LANGUAGE } from "../../constants";
import { getDateFnsLocale } from "../../helpers/Utils";

const useStyles = makeStyles(() => ({
	tabBtn: {
		minWidth: "182px",
	},
	tabPanel: { margin: "20px 15px" },
	relativeTimePicker: {
		display: "flex",
		margin: "22px 0px",
		alignItems: "center",
		justifyContent: "space-evenly",
		gap: "33px",
	},
	relativeSelect: {
		flex: 2,
	},
}));

interface DatePopoverProps {
	date: moment.Moment;
	relativeValue: { value: number; text: string };
	onRelativeChange(value: number, text: string): void;
	onDateChange(newDate: moment.Moment): void;
	onTabChange(tab: number): void;
	openTab: number;
}

export const DatePopover: React.FC<DatePopoverProps> = ({
	date,
	relativeValue,
	onRelativeChange,
	onDateChange,
	onTabChange,
	openTab,
}) => {
	const classes = useStyles();

	const handleTabChange = (
		_event: React.ChangeEvent<{}>,
		newValue: number,
	) => {
		onTabChange(newValue);
		if (newValue === 0) {
			const newRelativeValue = getRelativeValue(date);
			onRelativeChange(newRelativeValue.value, newRelativeValue.text);
		}
	};

	const handleRelativeValueSelect = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const result = event.target.value as moment.DurationInputArg1;
		onRelativeChange(+result!, relativeValue.text);
	};

	const handleRelativeTextSelect = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
		_child: React.ReactNode,
	) => {
		const result = event.target
			.value as moment.unitOfTime.DurationConstructor;
		onRelativeChange(relativeValue.value, result);
	};

	const handleInputChange = (event: any) => {
		const selectedValue = new Date(event);
		if (isNaN(selectedValue.getTime())) return;
		onDateChange(moment(selectedValue));
	};

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={openTab}
					onChange={handleTabChange}
					TabIndicatorProps={{
						style: {
							width: "182px",
							left: openTab ? "182px" : "0px",
						},
					}}
				>
					<Tab label="Relative" className={classes.tabBtn} />
					<Tab label="Absolute" className={classes.tabBtn} />
				</Tabs>
			</Box>
			<TabPanel value={openTab} index={0} className={classes.tabPanel}>
				<Box className={classes.relativeTimePicker}>
					<TextField
						className={classes.relativeSelect}
						type="number"
						value={relativeValue.value}
						onChange={handleRelativeValueSelect}
						placeholder="Contact phone number"
						InputProps={{ inputProps: { min: 0 } }}
					/>
					<Select
						className={classes.relativeSelect}
						value={relativeValue.text}
						onChange={handleRelativeTextSelect}
					>
						{RELATIVE_OPTIONS.map((option, idx) => (
							<MenuItem key={idx} value={option.value}>
								{option.text}
							</MenuItem>
						))}
					</Select>
				</Box>
				<Box>
					<TextField
						fullWidth
						value={new Date(date.format()).toLocaleString(LANGUAGE)}
						disabled={true}
					/>
				</Box>
			</TabPanel>
			<TabPanel value={openTab} index={1} className={classes.tabPanel}>
				<DatePicker
					selected={new Date(date.format())}
					onChange={handleInputChange}
					inline
					showTimeSelect
					locale={getDateFnsLocale({ locale: LANGUAGE })}
					timeIntervals={1}
					showMonthDropdown
					showYearDropdown
				/>
			</TabPanel>
		</>
	);
};

function getRelativeValue(date: moment.Moment) {
	const now = moment();
	let relativeTime = {
		value: 0,
		text: "second",
	};

	for (const option of RELATIVE_OPTIONS.slice().reverse()) {
		//@ts-ignore
		const diff = now.diff(date, option.value);
		if (diff > 0) {
			relativeTime = {
				value: diff,
				text: option.value,
			};
			break;
		}
	}

	return relativeTime;
}
