import React from "react";
import {
	makeStyles,
	Grid,
	IconButton,
	Typography,
	Divider,
	Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { RuleAction } from "../../../../store/Rules/types";

const useStyles = makeStyles(() => ({
	item: {
		"&:hover": {
			backgroundColor: "#f3f3f4",
		},
	},
	text: {
		paddingTop: 10,
		paddingLeft: 5,
	},
}));

export const generateActionText = (action: RuleAction): string => {
	let result = "";
	if (action.type === "HttpAction") {
		result += "Send to '" + action.address + "'";
	} else if (action.type === "VmsAction") {
		result += "Analytic Event '" + action.eventName + "'";
	} else {
		result += "Send MQTT message";
	}
	if (action.sendWithTrackEvent && action.sendWithDisappearedEvent) {
		result += " on track and disappeared events";
	} else if (action.sendWithTrackEvent) {
		result += " on track event";
	} else {
		result += " on disappeared event";
	}
	return result;
};

interface IActionItemProps {
	index: number;
	action: RuleAction;
	onEdit(
		event: React.MouseEvent<HTMLButtonElement>,
		action: RuleAction,
		index: number,
	): void;
	onDelete(index: number): void;
	isReadOnly: boolean;
}

const ActionItem: React.FC<IActionItemProps> = ({
	index,
	action,
	onEdit,
	onDelete,
	isReadOnly,
}) => {
	const classes = useStyles();

	const handleDoubleClick = (event: unknown) => {
		if (!isReadOnly)
			onEdit(
				event as React.MouseEvent<HTMLButtonElement, MouseEvent>,
				action,
				index,
			);
	};

	return (
		<Grid item xs={12} className={classes.item}>
			<div onDoubleClick={handleDoubleClick}>
				<Grid container>
					{isReadOnly ? null : (
						<Grid item xs={1}>
							<IconButton
								aria-label="edit"
								onClick={(e) => onEdit(e, action, index)}
							>
								<Tooltip title="Edit">
									<EditIcon />
								</Tooltip>
							</IconButton>
						</Grid>
					)}
					<Grid item xs={isReadOnly ? 12 : 10}>
						<Typography className={classes.text}>
							{generateActionText(action)}
						</Typography>
					</Grid>
					{isReadOnly ? null : (
						<Grid item xs={1}>
							<IconButton
								aria-label="delete"
								onClick={() => onDelete(index)}
							>
								<Tooltip title="Remove action">
									<DeleteIcon />
								</Tooltip>
							</IconButton>
						</Grid>
					)}
				</Grid>
				<Divider />
			</div>
		</Grid>
	);
};

export default ActionItem;
