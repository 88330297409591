import React from "react";
import { Route, Redirect } from "react-router-dom";
import Authentication from "../../store/Authentication/AuthenticationStore";
import { IProps } from "./PrivateRoute";
import { checkPermissions } from "../../containers/Layout/Layout";

interface IPermissionsRouteProps extends IProps {
	redirect: string;
	permissions: string[];
}

const PermissionsRoute = ({
	component: Component,
	redirect,
	permissions,
	...otherProps
}: IPermissionsRouteProps) => (
	<Route
		{...otherProps}
		render={(props) =>
			Authentication.isAuthenticated() ? (
				checkPermissions(permissions) ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: redirect,
							state: { from: props.location },
						}}
					/>
				)
			) : (
				<Redirect
					to={{ pathname: "/login", state: { from: props.location } }}
				/>
			)
		}
	/>
);

export default PermissionsRoute;
