import { Box, makeStyles } from "@material-ui/core";
import { FilterCenterFocusRounded } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { EventsViewSide } from "../../store/UserConfig/types";
import { setDraggingViewAction } from "../../store/LiveCameras/action";
import {
	updateConfigAction,
	updateConfigLocallyAction,
} from "../../store/UserConfig/action";
import { isPredefinedLayout } from "./Controls";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		marginBottom: 8,
		borderRadius: 12,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 50,
		overflow: "hidden",
		transition: "all 0.2s",
		animation: "listWidth 0.3s",
		position: "relative",
		flexShrink: 0,
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
			"& svg": {
				color: theme.palette.grey[200],
			},
		},
	},
	icon: {
		fontSize: 40,
		color: theme.palette.secondary.light,
		animation: "pulse 1.3s infinite",
	},
}));

interface Props {
	side: EventsViewSide;
	insertAfterId: string | undefined;
}

const ListDropPlaceholder = ({ side, insertAfterId }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const selectedLayoutId = useSelector(
		(state: AppState) => state.userConfig.data.live?.selectedLayoutId,
	);
	const selectedLayout = useSelector((state: AppState) =>
		state.userConfig.data.layouts.find((l) => l.id === selectedLayoutId),
	);

	const draggingView = useSelector(
		(state: AppState) => state.live.draggingView,
	);

	const handleMouseUp = () => {
		if (!draggingView || !selectedLayout) return;
		const viewsOrder = { ...selectedLayout.viewsOrder };
		viewsOrder[EventsViewSide.LEFT] = [
			...viewsOrder[EventsViewSide.LEFT],
		].map((id) => (id === draggingView.id ? "" : id));
		viewsOrder[EventsViewSide.RIGHT] = [
			...viewsOrder[EventsViewSide.RIGHT],
		].map((id) => (id === draggingView.id ? "" : id));

		if (insertAfterId) {
			const index = viewsOrder[side].indexOf(insertAfterId);
			viewsOrder[side].splice(index + 1, 0, draggingView.id);
		} else {
			viewsOrder[side].unshift(draggingView.id);
		}

		viewsOrder[EventsViewSide.LEFT] =
			viewsOrder[EventsViewSide.LEFT].filter(Boolean);
		viewsOrder[EventsViewSide.RIGHT] =
			viewsOrder[EventsViewSide.RIGHT].filter(Boolean);

		if (isPredefinedLayout(selectedLayout.id))
			dispatch(
				updateConfigLocallyAction({
					variant: "layouts",
					config: {
						...selectedLayout,
						viewsOrder,
					},
				}),
			);
		else
			dispatch(
				updateConfigAction({
					variant: "layouts",
					config: {
						...selectedLayout,
						viewsOrder,
					},
				}),
			);

		dispatch(setDraggingViewAction(null));
	};

	return (
		<Box className={classes.root} onMouseUp={handleMouseUp}>
			<FilterCenterFocusRounded className={classes.icon} />
		</Box>
	);
};

export default ListDropPlaceholder;
