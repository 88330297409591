import { IRecordsState, IRecordsDataContainer } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { RecordsAction, Records } from "./action";

export const initialRecordsState: IRecordsState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
		watchlistId: "",
	},
	keys: {},
	requestStatus: {},
};

export const RecordsReducer: Reducer<IRecordsState, any> = (
	state = initialRecordsState,
	action: RecordsAction,
) => {
	if (action.type !== Records) {
		return state;
	}
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				const payload = action.payload as IRecordsDataContainer;
				nState.keys[`${action.meta}-${payload.watchlistId}`] = payload;
			}
			return nState;
	}
	return state;
};
