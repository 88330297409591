import ListItem from "./ListItem";
import {
	Box,
	Divider,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getVMSAction } from "../../store/VMS/action";
import { DoubleArrow } from "@material-ui/icons";
import { updateConfigLocallyAction } from "../../store/UserConfig/action";
import { ILayoutConfig } from "../../store/UserConfig/types";
import { isPredefinedLayout } from "./Controls";
import SearchInput from "./SearchInput";
import HotspotList from "./HotspotList";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
		height: "100%",
		overflow: "hidden",
	},
	button: {
		color: "black",
		width: 34,
		height: 34,
		borderRadius: 10,
	},
	list: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
		flex: 1,
		overflowY: "auto",
		padding: 2,
		marginBottom: 2,
	},
}));

type Props = {
	handleHide: () => void;
	layouts: ILayoutConfig[];
};

const CamList = ({ handleHide, layouts }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const camerasAdded = useRef(false);
	const vmsContent = useSelector((state: AppState) =>
		Object.values(state.vms.keys),
	);
	const sourcesState = useSelector((state: AppState) => state.sources);
	const [searchValue, setSearchValue] = useState("");
	const userConfig = useSelector((state: AppState) => state.userConfig);

	const selectedLayout = useMemo(
		() =>
			layouts.find(
				(l) => l.id === userConfig.data.live?.selectedLayoutId,
			),
		[layouts, userConfig.data.live?.selectedLayoutId],
	);

	const autoAddCameras = useCallback(() => {
		if (
			layouts.some(
				(l) => !isPredefinedLayout(l.id) || l.cameras.length > 0,
			)
		)
			return;

		const vmsWithMostSources = [...vmsContent]
			.filter(
				(v) => v.driver === "MILESTONE" && v.ip && v.useMobileServer,
			)
			.sort((a, b) => b.sourceCount - a.sourceCount);
		const vms = vmsWithMostSources[0];
		if (!vms || !sourcesState.keys[vms.name]) return;

		const randomSources = [...sourcesState.keys[vms.name]?.content]
			?.sort(() => Math.random() - Math.random())
			.slice(0, 4);

		if (randomSources && selectedLayout) {
			camerasAdded.current = true;
			const newLayout: ILayoutConfig = {
				...selectedLayout,
				cameras: randomSources.map((s) => ({
					sourceId: s.id,
					vmsName: vms.name,
					sourceName: s.displayName,
					isLive: true,
				})),
			};
			dispatch(
				updateConfigLocallyAction({
					variant: "layouts",
					config: newLayout,
				}),
			);
		}
	}, [dispatch, selectedLayout, sourcesState, vmsContent, layouts]);

	useEffect(() => {
		if (
			vmsContent.length > 0 &&
			Object.keys(sourcesState.keys).length > 0 &&
			!camerasAdded.current
		) {
			autoAddCameras();
		}
	}, [autoAddCameras, vmsContent, sourcesState]);

	useEffect(() => {
		getVMSAction({ size: 500 })(dispatch);
	}, [dispatch]);

	return (
		<Box className={classes.root}>
			<Box display="flex" alignItems="center" gridGap={1}>
				<SearchInput
					placeholder="Source name..."
					value={searchValue}
					onChange={setSearchValue}
				/>
				<Divider
					orientation="vertical"
					flexItem
					style={{ marginLeft: 8 }}
				/>
				<Tooltip title="Hide sources">
					<IconButton onClick={handleHide} className={classes.button}>
						<DoubleArrow />
					</IconButton>
				</Tooltip>
			</Box>

			<Box className={classes.list}>
				{vmsContent && vmsContent.length > 0 ? (
					vmsContent.map((vms, i) => (
						<ListItem key={i} vms={vms} searchValue={searchValue} />
					))
				) : (
					<Typography
						variant="body1"
						align="center"
						color="textSecondary"
						style={{ fontWeight: "bold" }}
					>
						No VMS found
					</Typography>
				)}
			</Box>
			<HotspotList />
		</Box>
	);
};

export default CamList;
