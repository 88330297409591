export const directions = [
	"North",
	"NorthEast",
	"East",
	"SouthEast",
	"South",
	"SouthWest",
	"West",
	"NorthWest",
];

export const ethnicities = ["White", "Black", "Asian", "Indian", "Hispanic"];

export const colors = [
	"Red",
	"Orange",
	"Yellow",
	"Green",
	"Blue",
	"Silver",
	"White",
	"Black",
	"Brown",
	"Gray",
];

export const objects = ["Car", "Person", "Bus", "Truck", "Bike"];

export const gender = ["Any", "Male", "Female"];

export const attributes = [
	"Beard",
	"Mustache",
	"Glasses",
	"Dark Glasses",
	"Mask",
];

export const ageGroup = ["Child", "Teenager", "Adult", "Senior"];
