import React from "react";
import { makeStyles } from "@material-ui/core";
import { THEME } from "../../config/index";

const useStyles = (selected: number, index: number) =>
	makeStyles(() => ({
		item: {
			marginLeft: 10,
			marginRight: 10,
			borderStyle: "solid",
			borderWidth: 1,
			borderColor:
				selected === index ? "#59CBE8" : THEME.palette.secondary.main,
			height: 210,
			width: 140,
		},
		responsivePhoto: {
			objectFit: "contain",
			width: "100%",
			height: "100%",
		},
	}));

interface IRecordItemProps {
	selected: number;
	index: number;
	onClick(option: number): void;
	onDoubleClick(): void;
	image: string;
}

const RecordsItem: React.FC<IRecordItemProps> = ({
	selected,
	index,
	onClick,
	onDoubleClick,
	image,
}) => {
	const classes = useStyles(selected, index)();
	return (
		<div
			className={classes.item}
			onClick={() => onClick(index)}
			onDoubleClick={onDoubleClick}
		>
			<img
				className={classes.responsivePhoto}
				alt="Record"
				src={`data:image/jpeg;base64,${image}`}
			/>
		</div>
	);
};

export default RecordsItem;
