import React, { useState, useEffect } from "react";
import CenteredLoading from "../UI/CenteredLoading";

type Props = {
	children: React.ReactNode;
	waitBeforeShow?: number;
};

const Delayed: React.FC<Props> = ({ children, waitBeforeShow = 500 }) => {
	const [isShown, setIsShown] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShown(true);
		}, waitBeforeShow);
		return () => clearTimeout(timer);
	}, [waitBeforeShow]);

	return isShown ? <>{children}</> : <CenteredLoading />;
};

export default Delayed;
