import React, { useEffect, useState, SyntheticEvent } from "react";
import { Box, Snackbar } from "@material-ui/core";
import { IUser, IUserRequest, IUsersContainer } from "../../store/Users/types";
import { addUser, editUser } from "../../store/Users/action";
import UserDialogEx, { IUserForm } from "../../components/Dialogs/UserDialog";
import Alert, { IAlert } from "../../components/UI/Alert";
import { HttpError } from "../../config/types";
import UsersFiltering from "../../components/Users/UsersFiltering";
import Users from "../../components/Users/Users";
import ConfirmDialog from "../../components/UI/ConfirmDialog";
import { THEME } from "../../config";

export interface IUsersViewStateProps {
	error?: HttpError;
	users: IUsersContainer;
	authorities: string[];
	filter: IUserRequest;
}

export interface IUsersViewDispatchProps {
	loadUsers(filter: IUserRequest): void;
	loadAuthorities(): void;
	setFilter(filter: IUserRequest): void;
}

type IUsersViewProps = IUsersViewStateProps & IUsersViewDispatchProps;

const UsersView: React.FC<IUsersViewProps> = ({
	error,
	users,
	authorities,
	loadUsers,
	loadAuthorities,
	setFilter,
	filter,
}) => {
	const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
	const [alert, setAlert] = useState<IAlert | null>(null);
	const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
	const [selectedEditUser, setSelectedEditUser] = useState<IUser | undefined>(
		undefined,
	);

	const [selectedDeleteUser, setSelectedDeleteUser] = useState<
		| {
				id: number;
				userName: string;
				email: string;
				authorities: string[];
				enabled: boolean;
		  }
		| undefined
	>(undefined);

	const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

	const handleOpenSnackbar = (alert: IAlert) => {
		if (snackbarOpen) handleSnackbarClose();
		setSnackbarOpen(true);
		setAlert(alert);
	};

	const handleSnackbarClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") return;
		setSnackbarOpen(false);
	};

	useEffect(() => {
		loadUsers(filter);
		loadAuthorities();
	}, [loadUsers, loadAuthorities, filter]);

	const handleAddSubmit = async (form: IUserForm) => {
		try {
			const response = await addUser({
				userName: form.userName,
				email: form.email,
				password: form.password.value,
				authorities: form.selectedAuthorities,
				enabled: form.enabled,
			});
			if (!response) {
				handleOpenSnackbar({
					message: "User successfully created",
					variant: "success",
				});
				loadUsers(filter);
				setOpenAddDialog(false);
				setAsyncLoading(false);
			} else {
				handleOpenSnackbar({
					message: response.message,
					variant: "error",
				});
				setAsyncLoading(false);
			}
		} catch (err) {
			handleOpenSnackbar({
				message: (err as Error).message,
				variant: "error",
			});
			setAsyncLoading(false);
		}
	};

	const handleEditSubmit = async (form: IUserForm) => {
		if (form.password.value !== "" && !form.password.valid) return;
		try {
			const response = await editUser({
				id: form.id,
				userName: form.userName,
				email: form.email,
				password: form.password.value,
				authorities: form.selectedAuthorities,
				enabled: form.enabled,
			});
			if (!response) {
				handleOpenSnackbar({
					message: "User successfully updated",
					variant: "success",
				});
				loadUsers(filter);
				setSelectedEditUser(undefined);
				setAsyncLoading(false);
			} else {
				handleOpenSnackbar({
					message: response.message,
					variant: "error",
				});
				setAsyncLoading(false);
			}
		} catch (error) {
			handleOpenSnackbar({
				message: (error as Error).message,
				variant: "error",
			});
			setAsyncLoading(false);
		}
	};

	const handleFilter = (newFilter: IUserRequest) => setFilter(newFilter);

	const handleEnableDisable = async (
		id: number,
		userName: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	) => {
		try {
			const response = await editUser({
				id,
				email,
				userName,
				authorities,
				enabled,
			});
			if (!response) {
				handleOpenSnackbar({
					message: "User successfully updated",
					variant: "success",
				});
				loadUsers(filter);
				setSelectedDeleteUser(undefined);

				setAsyncLoading(false);
			} else {
				handleOpenSnackbar({
					message: response.message,
					variant: "error",
				});
				setSelectedDeleteUser(undefined);
				setAsyncLoading(false);
			}
		} catch (error) {
			handleOpenSnackbar({
				message: (error as Error).message,
				variant: "error",
			});
			setAsyncLoading(false);
		}
	};

	const onDisable = (
		id: number,
		userName: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	) => {
		setSelectedDeleteUser({
			id,
			userName,
			email,
			authorities,
			enabled,
		});
	};
	return (
		<Box
			display="flex"
			flexDirection="column"
			gridGap={THEME.spacing(2)}
			paddingY={1.25}
			height="100vh"
			overflow="hidden"
		>
			<UsersFiltering filter={filter} onFilter={handleFilter} />
			<Users
				onFilter={handleFilter}
				filter={filter}
				users={users}
				onAddUser={() => setOpenAddDialog(true)}
				onEdit={(u) => setSelectedEditUser(u)}
				handleEnableDisable={handleEnableDisable}
				onDisable={onDisable}
			/>
			<ConfirmDialog
				open={selectedDeleteUser !== undefined}
				onConfirm={() =>
					selectedDeleteUser &&
					handleEnableDisable(
						selectedDeleteUser.id,
						selectedDeleteUser.userName,
						selectedDeleteUser.email,
						selectedDeleteUser.authorities,
						selectedDeleteUser.enabled,
					)
				}
				onClose={() => setSelectedDeleteUser(undefined)}
				onLoading={() => setAsyncLoading(true)}
				loading={asyncLoading}
			>
				Are you sure you want to disable this user?
			</ConfirmDialog>
			{selectedEditUser ? (
				<UserDialogEx
					availableAuthorities={authorities}
					dialogOpen={selectedEditUser !== undefined}
					handleSubmit={handleEditSubmit}
					closeDialog={() => setSelectedEditUser(undefined)}
					user={selectedEditUser}
					onLoading={() => setAsyncLoading(true)}
					loading={asyncLoading}
				></UserDialogEx>
			) : null}
			{openAddDialog ? (
				<UserDialogEx
					availableAuthorities={authorities}
					dialogOpen={openAddDialog}
					handleSubmit={handleAddSubmit}
					closeDialog={() => setOpenAddDialog(false)}
					onLoading={() => setAsyncLoading(true)}
					loading={asyncLoading}
				></UserDialogEx>
			) : null}
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<div>
					<Alert onClose={handleSnackbarClose} alert={alert}></Alert>
				</div>
			</Snackbar>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</Box>
	);
};

export default UsersView;
