import React from "react";

export function useWindowWidth() {
	const [width, setWidth] = React.useState([0]);
	React.useLayoutEffect(() => {
		function updateWidth() {
			setWidth([window.innerWidth]);
		}
		window.addEventListener("resize", updateWidth);
		updateWidth();
		return () => window.removeEventListener("resize", updateWidth);
	}, []);
	return width;
}
