import { Box, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ISearchResult } from "../../store/ImageSearch/types";
import { ISecretsState } from "../../store/Secrets/types";
import { ISubject } from "../../store/Subjects/types";
import { Skeleton } from "@material-ui/lab";
import { THEME } from "../../config";
import clsx from "clsx";
import SecretText from "../Secrets/SecretText";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: "white",
		padding: 4,
		gap: 2,
		minWidth: 180,
		width: "15%",
		height: "100%",
		overflow: "hidden",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: THEME.palette.grey[100],
		},
		minHeight: 140,
	},
	selected: {
		backgroundColor: THEME.palette.grey[200],
		outline: `2px solid ${THEME.palette.secondary.light}`,
	},
	imgContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		width: "fit-content",
		position: "relative",
		borderRadius: "8px",
	},
	img: {
		maxHeight: "100%",
		maxWidth: "100%",
		objectFit: "contain",
		minHeight: 40,
	},
	notFound: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
		overflow: "hidden",
		backgroundColor: THEME.palette.grey[600],
		color: THEME.palette.grey[200],
		padding: 4,
		height: "100%",
		textAlign: "center",
		aspectRatio: "3/4",
	},
	textContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		height: "fit-content",
		maxWidth: "100%",
	},
	text: {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	score: {
		position: "absolute",
		bottom: 0,
		left: 0,
		backgroundColor: THEME.palette.info.main,
		color: THEME.palette.common.white,
		padding: "1px 4px",
	},
	secretText: {
		color: THEME.palette.error.contrastText,
		backgroundColor: THEME.palette.error.dark,
		fontSize: "0.8rem",
		padding: "2px 4px",
		borderRadius: "3px",
		width: "fit-content",
		margin: "0 auto",
	},
}));

type Props = {
	match?: ISearchResult;
	secrets?: ISecretsState;
	subject?: ISubject;
	selected: boolean;
	getSecretAction?: (id: string) => Promise<void>;
	getSubjectAction: (
		watchlistId: string,
		name: string,
		secret?: string,
	) => Promise<void>;
	onClick: (arrow?: "left" | "right") => void;
};

const ResultInfo = ({
	match,
	subject,
	selected,
	getSubjectAction,
	onClick,
}: Props) => {
	const classes = useStyles();
	const [loadingSubject, setLoadingSubject] = useState(false);
	const [loadingImage, setLoadingImage] = useState(true);

	useEffect(() => {
		if (!subject && match) {
			setLoadingSubject(true);

			getSubjectAction(
				match.watchlist,
				match.subject,
				match.secret,
			).finally(() => setLoadingSubject(false));
		}
	}, [match, getSubjectAction, subject]);

	useEffect(() => {
		if (selected) {
			const handleKeyDown = (e: KeyboardEvent) => {
				if (e.code === "ArrowLeft") {
					e.preventDefault();
					onClick("left");
				}
				if (e.code === "ArrowRight") {
					e.preventDefault();
					onClick("right");
				}
			};
			window.addEventListener("keydown", handleKeyDown);
			return () => window.removeEventListener("keydown", handleKeyDown);
		}
	}, [onClick, selected]);

	return match ? (
		<Box
			className={clsx(classes.root, selected && classes.selected)}
			onClick={() => onClick()}
		>
			<Box
				className={classes.imgContainer}
				style={{
					width: !subject?.thumb ? "80%" : "fit-content",
					flex: loadingSubject ? 1 : "unset",
				}}
			>
				{subject?.thumb ? (
					<img
						src={`data:image/jpeg;base64,${subject?.thumb ?? ""}`}
						alt="Subject"
						className={classes.img}
						onLoad={() => setLoadingImage(false)}
					/>
				) : loadingSubject ? (
					<Skeleton variant="rect" width="60%" height="100%" />
				) : (
					<Box className={clsx(classes.notFound, classes.img)}>
						Subject is no longer <br /> available
					</Box>
				)}
				{match && !loadingImage && (
					<Typography variant="body2" className={classes.score}>
						{match?.score}
					</Typography>
				)}
			</Box>
			<Box className={classes.textContainer}>
				<Typography variant="body2" className={classes.text}>
					<b>ID:</b> {match?.subject}
				</Typography>
				<Typography variant="body2" className={classes.text}>
					<b>Watchlist:</b> {match?.watchlist}
				</Typography>

				{match?.secret && <SecretText secretID={match.secret} />}
			</Box>
		</Box>
	) : null;
};

export default ResultInfo;
