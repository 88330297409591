import { AsyncAction, AsyncActionStatus } from "../AsyncState";
import { IEventsViewConfig } from "../UserConfig/types";
import { ILiveEvent, ILiveState, TCamera } from "./types";

export type LIVE =
	| "DRAGGING_CAMERA"
	| "ADD_LIVE_EVENT"
	| "UPDATE_LIVE_EVENT"
	| "SELECT_LIVE_EVENT"
	| "SET_MQTT_AS_CONFIGURED"
	| "SET_MQTT_AS_CHECKED"
	| "SELECT_CAMERA"
	| "SET_TEMPORARY_CAMERA"
	| "SET_SDK_IS_LOADED"
	| "DRAGGING_VIEW";

export type LiveAction = AsyncAction<TCamera | LIVE>;

export function addLiveEventsAction(
	events: ILiveEvent | ILiveEvent[],
	eventViews: IEventsViewConfig[],
) {
	return {
		payload: events,
		status: AsyncActionStatus.SUCCEEDED,
		type: "ADD_LIVE_EVENT",
		meta: eventViews,
	};
}

export function updateLiveEventAction(payload: ILiveEvent) {
	return {
		payload,
		status: AsyncActionStatus.SUCCEEDED,
		type: "UPDATE_LIVE_EVENT",
	};
}

export function setDraggingCameraAction(camera: TCamera | null) {
	return {
		payload: camera,
		status: AsyncActionStatus.SUCCEEDED,
		type: "DRAGGING_CAMERA",
	};
}

export function setDraggingViewAction(view: IEventsViewConfig | null) {
	return {
		payload: view,
		status: AsyncActionStatus.SUCCEEDED,
		type: "DRAGGING_VIEW",
	};
}

export function selectCameraAction(payload: ILiveState["selectedCamera"]) {
	return {
		payload,
		status: AsyncActionStatus.SUCCEEDED,
		type: "SELECT_CAMERA",
	};
}

export function selectLiveEventAction(ids: ILiveState["selectedEventIds"]) {
	return {
		payload: ids,
		status: AsyncActionStatus.SUCCEEDED,
		type: "SELECT_LIVE_EVENT",
	};
}

export function setMqttAsConfiguredAction() {
	return {
		status: AsyncActionStatus.SUCCEEDED,
		type: "SET_MQTT_AS_CONFIGURED",
	};
}

export function setMqttConfigurationAsCheckedAction() {
	return {
		status: AsyncActionStatus.SUCCEEDED,
		type: "SET_MQTT_AS_CHECKED",
	};
}

export function setTemporaryCameraAction(camera: TCamera | null) {
	return {
		status: AsyncActionStatus.SUCCEEDED,
		type: "SET_TEMPORARY_CAMERA",
		payload: camera,
	};
}

export function setSdkIsLoadedAction() {
	return {
		status: AsyncActionStatus.SUCCEEDED,
		type: "SET_SDK_IS_LOADED",
	};
}
