import React, { useEffect, useRef, useState } from "react";

import {
	Box,
	CircularProgress,
	Grid,
	makeStyles,
	Paper,
	TextField,
} from "@material-ui/core";
import { IReport } from "../../store/Reports/types";
import { Autocomplete } from "@material-ui/lab";
import { AsyncActionStatus } from "../../store/AsyncState";
import { IConfig, IConfigState } from "../../store/UserConfig/types";

export interface IReportsViewPropsToDispatch {
	loadReports: () => void;
	loadUserConfig: () => void;
	updateConfig: (data: IConfig, updateName?: boolean) => void;
	addConfig: (data: IConfig) => void;
}

export interface IReportsViewPropsToState {
	reports: IReport[];
	userConfig: IConfigState;
	status: string;
}

type IReportsViewProps = IReportsViewPropsToDispatch & IReportsViewPropsToState;

const useStyles = makeStyles(() => ({
	root: {
		padding: 10,
		minHeight: "100vh",
		maxHeight: "100vh",
	},
	paper: {
		padding: 10,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		gap: 8,
	},
	loading: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
	},
	iframe: {
		border: "none",
		backgroundColor: "white",
		height: "100%",
		flex: 1,
	},
	select: {
		flex: 1,
	},
	nav: {
		display: "flex",
		width: "100%",
		gap: 8,
	},
}));

const ReportsView: React.FC<IReportsViewProps> = ({
	loadReports,
	loadUserConfig,
	updateConfig,
	addConfig,
	reports,
	status,
	userConfig,
}) => {
	const classes = useStyles();
	const [selectedReport, setSelectedReport] = useState<IReport | undefined>();
	// const [checked, setChecked] = useState(false);
	// const [downloadingReport, setDownloadingReport] = useState(false);
	const ref = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		loadUserConfig();
		loadReports();
	}, [loadReports, loadUserConfig]);

	useEffect(() => {
		if (userConfig.loaded && reports.length > 0) {
			const savedReport = reports.find(
				(r) =>
					r.title ===
					userConfig.data.preference.lastViewedReportTitle,
			);
			if (savedReport) setSelectedReport(savedReport);
			else setSelectedReport(reports[0]);
		}
	}, [reports, userConfig]);

	// const extractTimeFromUrl = (url: string) => {
	// 	const regex =
	// 		/%2Ctime%3A.(.*)...show-top|,time:.(.*)...show-top|,time:.(.*)..|%2Ctime%3A.(.*)../;
	// 	const match = url.match(regex);
	// 	if (match && match.length > 0) {
	// 		return match[1];
	// 	}
	// 	return "";
	// };

	// const createReport = async () => {
	// 	if (downloadingReport) return;
	// 	try {
	// 		setDownloadingReport(true);
	// 		const iframeUrl = ref.current?.contentWindow?.location?.href ?? "";
	// 		const timeString = extractTimeFromUrl(iframeUrl);
	// 		const fetchUrl = `${API_BASE_PREFIX}dashboards/${
	// 			selectedReport?.id
	// 		}/timepdf?timeString=${timeString}${
	// 			checked ? "&showTimeFilter=true" : ""
	// 		}`;

	// 		const response = await Axios.get(fetchUrl);
	// 		const { pdf } = response.data;
	// 		if (pdf)
	// 			stringToPdfAndDownload(
	// 				response.data?.pdf,
	// 				selectedReport?.title ?? "Report",
	// 			);
	// 	} catch (error) {
	// 		throw error;
	// 	} finally {
	// 		setDownloadingReport(false);
	// 	}
	// };

	if (
		(reports.length === 0 && status !== AsyncActionStatus.SUCCEEDED) ||
		!selectedReport
	) {
		return (
			<Box className={classes.loading}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Box className={classes.nav}>
						<Autocomplete
							className={classes.select}
							disableClearable
							options={Array.isArray(reports) ? reports : []}
							getOptionLabel={(option) => option?.title ?? ""}
							getOptionSelected={(option, value) =>
								option?.id === value?.id
							}
							onChange={(_, value) => {
								if (value) {
									setSelectedReport(value);
									if (!userConfig.loaded) return;
									const newConfig: IConfig = {
										config: {
											...userConfig.data.preference,
											lastViewedReportTitle: value.title,
										},
										variant: "preference",
									};
									if (
										userConfig.data.preference.savedInServer
									)
										updateConfig(newConfig);
									else addConfig(newConfig);
								}
							}}
							value={selectedReport}
							renderInput={(params) => (
								<TextField {...params} label="Select Report" />
							)}
						/>
						{/* 
							### HIDDEN FOR NOW ###
						*/}

						{/* <FormControlLabel
							control={
								<Checkbox
									checked={checked}
									onChange={(e) =>
										setChecked(e.target.checked)
									}
									name="checkbox"
									color="primary"
								/>
							}
							label="Generate report with Time field"
							labelPlacement="start"
							style={{ marginRight: 4 }}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={createReport}
							style={{ minWidth: 140 }}
						>
							{downloadingReport ? (
								<CircularProgress style={{ color: "white" }} />
							) : (
								"Create Report"
							)}
						</Button> */}
					</Box>
					<iframe
						ref={ref}
						className={classes.iframe}
						src={selectedReport?.url}
						title="Report"
						width="100%"
						height="100%"
						security="allow-same-origin allow-scripts allow-popups allow-forms"
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default ReportsView;
