import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import VMSForm from "../Forms/VMSForm";
import { IVMS, IVMSInfo } from "../../store/VMS/types";
import Title from "./Common/Title";

interface IVMSDialogProps {
	open: boolean;
	close: () => void;
	onSubmit: (vms: IVMSInfo) => void;
	data?: IVMS;
	edit?: boolean;
	loading: boolean;
	onLoading(): void;
}

const VMSDialog: React.FC<IVMSDialogProps> = (props) => {
	return (
		<Dialog
			open={props.open}
			PaperProps={{ style: { maxHeight: "99vh" } }}
			onClose={props.close}
		>
			<Title
				onClose={props.close}
				title={
					props.data ? `Edit ${props.data.name} VMS` : "Register VMS"
				}
			/>
			<DialogContent>
				<VMSForm
					onSubmit={props.onSubmit}
					data={props.data as IVMSInfo}
					discard={props.close}
					edit={props.edit}
					loading={props.loading}
					onLoading={props.onLoading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default VMSDialog;
