import React, { useMemo, useState } from "react";
import {
	Box,
	makeStyles,
	CircularProgress,
	Typography,
} from "@material-ui/core";
import { IElasticHit } from "../../../store/Events/types";
import { getVMSInfo } from "../../../store/VMS/action";
import { getSource } from "../../../store/Sources/action";
import { THEME } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import EventsStream from "../../VideoPlayers/EventsStream";
import { ISource } from "../../../store/Sources/types";

const useStyles = makeStyles(() => ({
	root: {
		padding: 12,
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flex: 2,
		maxHeight: "66%",
	},
	loader: {
		padding: 12,
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
	},
	error: {
		color: THEME.palette.grey[700],
		fontSize: "1.3rem",
	},
	name: {
		margin: 1,
		textAlign: "center",
	},
}));

type Props = {
	event: IElasticHit | null;
	style?: React.CSSProperties;
	children?: React.ReactNode;
};

export const EventStream: React.FC<Props> = ({ event, style, children }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const sources = useSelector((state: AppState) => state.sources.keys);
	const vms = useSelector((state: AppState) => state.vms.keys);
	const [source, setSource] = useState<ISource | null>(null);
	const [isSourceLoading, setIsSourceLoading] = useState(false);
	const [isMaximized, setIsMaximized] = useState(false);
	const vmsName = useMemo(() => event?._source.vms, [event]);
	const timestamp = useMemo(() => event?._source["@timestamp"], [event]);

	React.useEffect(() => {
		if (!event) return;
		const newSource = sources[event?._source.vms]?.content.find(
			(source) => source.id === event?._source.sourceId,
		);
		if (newSource) setSource(newSource);
		else {
			setIsSourceLoading(true);
			getSource(vmsName, event?._source.sourceId)
				.then((res) => {
					setSource(res);
				})
				.finally(() => setIsSourceLoading(false));
		}

		const vm = vms[vmsName];
		if (!vm) dispatch(getVMSInfo(vmsName));
	}, [event, sources, vmsName, vms, dispatch]);

	return (
		<Box className={classes.root} style={style}>
			{!event ? (
				<Typography>Select event for the stream</Typography>
			) : source && !isSourceLoading ? (
				<EventsStream
					key={event._id}
					source={source}
					vmsName={vmsName}
					timestamp={timestamp}
					eventSource={event._source}
					title={`${source.vmsName} - ${source.displayName}`}
					maximized={isMaximized}
					setMaximized={setIsMaximized}
				/>
			) : (
				<div className={classes.loader}>
					<CircularProgress />
				</div>
			)}
			{children}
		</Box>
	);
};
