import * as React from "react";
import moment from "moment";

interface ContextProps {
	severity: string | undefined;
	setSeverity: React.Dispatch<React.SetStateAction<string | undefined>>;
	service: string[];
	setService: React.Dispatch<React.SetStateAction<string[]>>;
	message: string | undefined;
	setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
	not: { [key: string]: string }[];
	setNot: React.Dispatch<React.SetStateAction<{ [key: string]: string }[]>>;
	timeRange: {
		from: moment.Moment;
		to: moment.Moment;
	};
	setTimeRange: React.Dispatch<
		React.SetStateAction<{
			from: moment.Moment;
			to: moment.Moment;
		}>
	>;
	children?: React.ReactNode;
}

export const Context = React.createContext({} as ContextProps);

export const ContextProvider: React.FC<ContextProps> = ({
	children,
	severity,
	setSeverity,
	service,
	setService,
	message,
	setMessage,
	not,
	setNot,
	timeRange,
	setTimeRange,
}) => {
	return (
		<Context.Provider
			value={{
				severity,
				setSeverity,
				service,
				setService,
				message,
				setMessage,
				not,
				setNot,
				timeRange,
				setTimeRange,
			}}
		>
			{children}
		</Context.Provider>
	);
};
