import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router";

const BackButton = () => {
	const history = useHistory();

	return (
		<Button
			variant="text"
			onClick={() => history.goBack()}
			startIcon={<ArrowBack />}
			style={{ width: "fit-content", height: "fit-content" }}
		>
			Back
		</Button>
	);
};

export default BackButton;
