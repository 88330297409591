import React from "react";
import { Route, Redirect } from "react-router-dom";
import Authentication from "../../store/Authentication/AuthenticationStore";

export interface IProps {
	exact?: boolean;
	path: string;
	component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...otherProps }: IProps) => (
	<Route
		{...otherProps}
		render={(props) =>
			Authentication.isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{ pathname: "/login", state: { from: props.location } }}
				/>
			)
		}
	/>
);

export default PrivateRoute;
