import { useCallback, useEffect, useRef } from "react";

const usePointerPos = (
	onPosChange: (pos: { x: number; y: number }) => void,
) => {
	const pointerPos = useRef({ x: 0, y: 0 });

	const getPointerPos = useCallback(() => {
		return pointerPos.current;
	}, []);

	const handleCursorPosition = useCallback(
		(e: MouseEvent) => {
			const { clientX, clientY } = e;
			pointerPos.current = { x: clientX, y: clientY };
			onPosChange(pointerPos.current);
		},
		[onPosChange],
	);

	useEffect(() => {
		window.addEventListener("mousemove", handleCursorPosition);
		return () => {
			window.removeEventListener("mousemove", handleCursorPosition);
		};
	}, [handleCursorPosition]);

	return { getPointerPos };
};

export default usePointerPos;
