import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { THEME } from "../../config";
import { useDispatch } from "react-redux";
import { Replay } from "@material-ui/icons";
import { setVMSConnectionIsLostAction } from "../../store/VMS/action";

interface Props {
	vmsName: string;
	isOnline: boolean;
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		overflow: "hidden",
	},
	empty: {
		height: "100vh",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		gap: 4,
		justifyContent: "center",
		alignItems: "center",
		color: THEME.palette.text.secondary,
	},
}));

const VideoConnectionLost = ({ vmsName, isOnline }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<Box
			className={classes.root}
			onClick={() =>
				dispatch(setVMSConnectionIsLostAction(vmsName, false))
			}
			style={{
				cursor: isOnline ? "pointer" : "default",
			}}
		>
			<Box className={classes.empty}>
				<Typography variant="h6" style={{ paddingBottom: 8 }}>
					Video Connection Lost
				</Typography>
				{isOnline ? (
					<>
						<Replay fontSize="large" />
						<Typography variant="body1">Click to retry</Typography>
					</>
				) : (
					<Typography variant="body1">
						No internet connection
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default VideoConnectionLost;
