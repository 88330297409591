import { connect } from "react-redux";
import { AppState } from "../../store";
import {
	getSearchRequestsAction,
	setSearchRequestFilterAction,
	updateSearchRequest,
} from "../../store/ImageSearch/action";
import {
	IGetImageSearchRequest,
	ImageSearch,
} from "../../store/ImageSearch/types";
import ImageSearchView, {
	ImageSearchViewDispatchProps,
	ImageSearchViewStateProps,
} from "./ImageSearchView";

const mapStateToProps = (state: AppState): ImageSearchViewStateProps => {
	return {
		error: state.imageSearches.erorr,
		imageSearches: state.imageSearches.data,
		filter: state.imageSearches.paging,
	};
};

const mapDispatchToProps = (dispatch: any): ImageSearchViewDispatchProps => {
	return {
		loadImageSearches: (
			request: IGetImageSearchRequest,
			controller?: AbortController,
		) => getSearchRequestsAction(request, controller)(dispatch),
		setImageSearchFilter: (filter: IGetImageSearchRequest) =>
			dispatch(setSearchRequestFilterAction(filter)),
		updateSearchRequest: (data: ImageSearch) =>
			dispatch(updateSearchRequest(data)),
	};
};

const ImageSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ImageSearchView);
export default ImageSearchContainer;
