export const PRIORITY_COUNTRIES = [
	"AND Andorra",
	"ARE United Arab Emirates",
	"ARG Argentina",
	"ARM Armenia",
	"AUS Australia",
	"AUT Austria",
	"BEL Belgium",
	"BGR Bulgaria",
	"BLR Belarus",
	"BHR Bahrain",
	"BOL Bolivia",
	"BRA Brazil",
	"CAN Canada",
	"CHE Switzerland",
	"CHL Chile",
	"COL Colombia",
	"CYP Cyprus",
	"CZE Czechia",
	"DEU Germany",
	"DNK Denmark",
	"ECU Ecuador",
	"EGY Egypt",
	"ESP Spain",
	"EST Estonia",
	"FIN Finland",
	"FRA France",
	"GBR Great Britain",
	"GEO Georgia",
	"GRC Greece",
	"HRV Croatia",
	"HUN Hungary",
	"IND India",
	"IRL Ireland",
	"ITA Italy",
	"JOR Jordan",
	"KAZ Kazakhstan",
	"KWT Kuwait",
	"LIE Liechtenstein",
	"LNK Sri Lanka",
	"LTU Lithuania",
	"LUX Luxembourg",
	"LVA Latvia",
	"MAR Morocco",
	"MCO Monaco",
	"MDA Moldova",
	"MLT Malta",
	"MNE Montenegro",
	"NLD Netherlands",
	"NOR Norway",
	"NZL New Zealand",
	"OMN Oman",
	"PER Peru",
	"POL Poland",
	"PRT Portugal",
	"PRY Paraguay",
	"QAT Qatar",
	"ROU Romania",
	"RUS Russia",
	"SAU Saudi Arabia",
	"SLV El Salvador",
	"SRB Serbia",
	"SVK Slovakia",
	"SVN Slovenia",
	"SWE Sweden",
	"TUR Turkey",
	"UKR Ukraine",
	"URY Uruguay",
	"USA United States of America",
	"UZB Uzbekistan",
	"VEN Venezuela",
	"KGZ Kyrgyzstan",
	"TJK Tajikistan",
];
