import React, { useCallback } from "react";
import {
	Button,
	Grid,
	makeStyles,
	Box,
	CircularProgress,
} from "@material-ui/core";
import { THEME } from "../../../config/index";
import LabelledOutline from "../../UI/LabelledOutline";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { ICreateSubject, IFace } from "../../../store/Subjects/types";
import FormMetadata from "./FormMetadata";
import { checkMetadata } from "../../../helpers/Allowances";
import { errorsInBody } from "../../../helpers/ValidateInput";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

const useStyles = (image: string | null) =>
	makeStyles(() => ({
		responsivePhoto: {
			objectFit: "contain",
			width: "100%",
			height: "100%",
		},
		box: {
			borderColor: THEME.palette.secondary.main,
			height: image ? "" : "25rem",
			width: "30rem",
			display: "flex",
			flexDirection: "column",
			flexWrap: "wrap",
			alignItems: "center",
		},
		container: {
			overflow: "hidden",
		},
	}));

interface ICreateFaceForm {
	onConfirm(body: ICreateSubject): void;
	onClose(): void;
	schema: IMetadataSchema;
	initialMetadata: IMetadata | null;
	onLoading(): void;
	loading: boolean;
}

const FaceCreateForm: React.FC<ICreateFaceForm> = ({
	onConfirm,
	onClose,
	schema,
	initialMetadata,
	onLoading,
	loading,
}) => {
	const [image, setImage] = React.useState<string | null>(null);
	const [metadata, setMetadata] = React.useState<IMetadata>(
		initialMetadata || {},
	);
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
	const classes = useStyles(image)();

	const handleFormSubmit = (formFace: IFace) => {
		const formErrors = errorsInBody(schema.properties, metadata);
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		} else {
			setErrors({});
		}

		onLoading();
		const face = { ...formFace };
		const faceId = face.id ? face.id : "";
		const body: ICreateSubject = {
			id: faceId,
			metadata: JSON.stringify(checkMetadata(schema, metadata)),
			image: image as string,
		};
		onConfirm(body);
	};

	const handlePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const file: File = (target.files as FileList)[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				const res = reader.result as string;
				setImage(btoa(res));
			};
			reader.readAsBinaryString(file);
		}
	};

	const handleMetadata = useCallback(
		(newMeta: IMetadata) => {
			if (JSON.stringify(newMeta) !== JSON.stringify(metadata)) {
				setMetadata(newMeta);
			}
		},
		[metadata],
	);

	return (
		<Form
			onSubmit={handleFormSubmit}
			render={({ handleSubmit, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<Grid container>
						<Grid item xs={12} md={7} lg={6}>
							<Grid
								container
								direction="column"
								alignItems="center"
								justifyContent="center"
								style={{ minHeight: "100%" }}
							>
								<Box className={classes.box} border={1}>
									{image ? (
										<img
											alt="Person"
											src={`data:image/jpeg;base64,${image}`}
											className={classes.responsivePhoto}
										/>
									) : null}
								</Box>
								<Button component="label">
									Open Image
									<input
										hidden
										type="file"
										name="image"
										id="file"
										accept=".jpeg, .png, .jpg"
										onChange={handlePhoto}
									/>
								</Button>
							</Grid>
						</Grid>
						<Grid item xs={12} md={5} lg={6}>
							<Grid container direction="column">
								<Grid item>
									<TextField
										autoFocus
										fullWidth
										name="id"
										label="Id"
										required
										inputProps={{ spellCheck: false }}
									/>
								</Grid>
								<Grid item>
									<LabelledOutline
										label="Metadata"
										id="create-face-form-metadata-label"
									>
										<FormMetadata
											schema={schema}
											onUpdate={handleMetadata}
											metadata={metadata}
											isReadOnly={false}
											errors={errors}
										/>
									</LabelledOutline>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ marginBottom: 15 }}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: 5,
								}}
							>
								{loading ? <CircularProgress /> : null}
								<Button
									type="submit"
									disabled={!image || pristine || loading}
								>
									Create
								</Button>
								<Button onClick={onClose}>Cancel</Button>
							</div>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

export default FaceCreateForm;
