import React from "react";
import { Paper, TablePagination } from "@material-ui/core";
import BounceTextField from "../UI/BounceTextField";

interface ISearchHeaderProps {
	onSubmit?: (value: string) => void;
	totalItems: number;
	currentPage: number;
	itemsPerPage: number;
	onChangePage: (event: unknown, newPage: number) => void;
	onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchHeader: React.FC<ISearchHeaderProps> = (props) => {
	const handleSearchSubmit = (value: string) => {
		if (props.onSubmit) {
			props.onSubmit(value);
		}
	};

	return (
		<Paper
			style={{
				padding: "15px",
				paddingTop: "10px",
				paddingBottom: "0px",
			}}
		>
			<BounceTextField
				onChange={handleSearchSubmit}
				style={{ width: "100%" }}
				autofocus={true}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end ",
				}}
			>
				<TablePagination
					rowsPerPageOptions={[1, 5, 10, 25]}
					component="div"
					count={props.totalItems}
					page={props.currentPage}
					onPageChange={props.onChangePage}
					onRowsPerPageChange={props.onChangeRowsPerPage}
					rowsPerPage={props.itemsPerPage}
				/>
			</div>
		</Paper>
	);
};

export default SearchHeader;
