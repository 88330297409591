import { FC, memo, useMemo, useRef } from "react";
import {
	Box,
	makeStyles,
	Typography,
	IconButton,
	Tooltip,
	Select,
	MenuItem,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { DATE_TIME_FORMAT, THEME } from "../../config";
import { MatchProps } from "../../store/Events/types";
import { AppState } from "../../store";
import { Match } from "../Events/Event/Match";
import { ticksToDate } from "../../helpers/Utils";
import { updateLiveEventAction } from "../../store/LiveCameras/action";
import SecretText from "../Secrets/SecretText";
import { ILiveEvent } from "../../store/LiveCameras/types";
import clsx from "clsx";
import { IEventsViewConfig } from "../../store/UserConfig/types";
import { itemWidth } from "./LiveEventsList";
import { YoutubeSearchedForRounded } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
	event: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		justifyContent: "flex-start",
		flexDirection: "column",
		borderRadius: "12px",
		maxWidth: itemWidth - 2,
		position: "relative",
		flex: 1,
		overflow: "hidden",
		height: "100%",
		cursor: "pointer",
	},
	eventStyles: {
		backgroundColor: "white",
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
	},
	selected: {
		backgroundColor: "#BDD2CD",
	},
	photoDetails: {
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		gap: 5,
		maxWidth: "100%",
	},
	img: {
		height: "100%",
		width: "100%",
		maxWidth: "100%",
		maxHeight: "280px",
		objectFit: "contain",
		overflow: "hidden",
	},
	faceImg: {
		height: "100%",
		width: "100%",
		maxHeight: "280px",
		objectFit: "contain",
	},
	matchImg: {
		maxWidth: "50%",
		objectFit: "contain",
	},
	photos: {
		maxHeight: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		gap: 5,
		overflow: "hidden",
		position: "relative",
	},
	matchPagination: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	detailsBtn: {
		position: "absolute",
		top: 5,
		left: 10,
	},
	centeredTextBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		width: "100%",
		"& > *": {
			fontSize: "0.9rem",
		},
	},
	popup: {
		"& 	.MuiPopover-paper": {
			borderRadius: "10px",
			padding: 10,
		},
	},
	matchMetadata: {
		marginTop: 10,
		display: "flex",
		gap: 8,
	},
	infoWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	score: {
		position: "absolute",
		bottom: 0,
		left: 0,
		backgroundColor: THEME.palette.info.main,
		color: THEME.palette.common.white,
		padding: "2px 4px",
		fontSize: "0.8rem",
		borderRadius: "2px",
	},
	selectIcon: {
		top: 3,
	},
	matches: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		gap: 5,
		position: "relative",
		flex: 1,
	},
	searchIcon: {
		position: "absolute",
		top: 2,
		right: 2,
	},
}));

const LiveEvent: FC<{
	event: ILiveEvent | null;
	isSelected: boolean;
	onClick: () => void;
	onDoubleClick: () => void;
	isLoadingMatches: boolean;
	loadMatch: (match: MatchProps) => void;
	view: IEventsViewConfig;
	sourceName?: string;
	openSearchDialog: (image: string) => void;
}> = memo(
	({
		event,
		isSelected,
		onClick,
		onDoubleClick,
		isLoadingMatches,
		loadMatch,
		view,
		sourceName,
		openSearchDialog,
	}) => {
		const classes = useStyles();
		const rootRef = useRef<HTMLDivElement>(null);
		const dispatch = useDispatch();

		const currentPage = event?.best?.currentPage ?? 0;
		const hasMatches = (event?.best?.matches?.length ?? 0) > 0;

		const allRules = useSelector((state: AppState) => state.rules.keys)?.[
			event?.vms ?? ""
		]?.content;
		const faceData = event?.best?.faces?.[0];
		const vechicleHumanData = event?.best?.vehicleHuman;
		const licensePlateData = event?.best?.licensePlates?.[0] || null;

		const faceImg = faceData?.thumbnail?.value;
		const vehicleHumanImg = vechicleHumanData?.thumbnail?.value;
		const licensePlateImg = licensePlateData?.thumbnail?.value;

		const matchesCount = event?.best?.matches?.length ?? 0;
		const matchProps: MatchProps | null = useMemo(
			() => event?.best?.matches?.[currentPage] ?? null,
			[currentPage, event],
		);
		const subjects = useSelector((state: AppState) => state.subjects);
		const match = useMemo(
			() =>
				matchProps
					? subjects.keys[matchProps?.watchlist]?.content.find(
							(s) => s.name === matchProps.id,
						)
					: undefined,
			[matchProps, subjects],
		);

		const timestamp = ticksToDate(
			Number(event?.timeStamp),
			DATE_TIME_FORMAT,
		);

		if (!event)
			return (
				<div
					ref={rootRef}
					className={`${classes.event} ${
						isSelected && classes.selected
					}`}
				/>
			);

		return (
			<>
				<div
					ref={rootRef}
					className={clsx(
						classes.event,
						isSelected && classes.selected,
						event !== null && classes.eventStyles,
					)}
					onClick={onClick}
					onDoubleClick={onDoubleClick}
				>
					{faceImg && (
						<IconButton
							size="small"
							className={classes.searchIcon}
							onClick={(e) => {
								e.stopPropagation();
								openSearchDialog(faceImg);
							}}
						>
							<YoutubeSearchedForRounded />
						</IconButton>
					)}
					{timestamp && <Typography>{timestamp}</Typography>}
					<Box
						className={classes.photos}
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							height: hasMatches ? "fit-content" : "100%",
						}}
					>
						{faceImg && (
							<Box className={classes.matches}>
								{matchProps?.score && match && (
									<Tooltip
										title={`Matched with score: ${matchProps.score}`}
									>
										<Typography className={classes.score}>
											{`${matchProps?.score}`}
										</Typography>
									</Tooltip>
								)}
								<img
									style={
										hasMatches
											? {
													height: "100%",
													width: "auto",
												}
											: {}
									}
									className={classes.faceImg}
									src={`data:image/jpeg;base64,${faceImg}`}
									alt="event"
								/>
								{hasMatches && match?.thumb ? (
									<img
										className={classes.matchImg}
										src={`data:image/jpeg;base64,${match?.thumb}`}
										alt="match"
										style={{
											height: "100%",
											width: "auto",
										}}
									/>
								) : hasMatches ? (
									<Skeleton
										variant="rect"
										style={{
											flex: 1,
											height: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
										}}
										animation={
											isLoadingMatches ? "pulse" : false
										}
									/>
								) : null}
							</Box>
						)}
						{vehicleHumanImg && !faceImg && (
							<img
								className={classes.img}
								src={`data:image/jpeg;base64,${vehicleHumanImg}`}
								alt="event"
							/>
						)}
						{licensePlateImg && (
							<img
								src={`data:image/jpeg;base64,${licensePlateImg}`}
								alt="license"
								className={classes.img}
								style={{ height: "fit-content" }}
							/>
						)}
					</Box>
					{hasMatches && matchProps ? (
						<Match
							matchProps={matchProps}
							eventSecretId={event.secretId}
						/>
					) : event.secretId ? (
						<SecretText secretID={event.secretId} enableTooltip />
					) : null}
					{sourceName && (
						<Box
							className={classes.centeredTextBox}
							gridGap={4}
							pt={0.3}
						>
							<Typography color="textSecondary">
								Source:
							</Typography>
							<Typography noWrap>{sourceName}</Typography>
						</Box>
					)}
					{event.triggeredRule && (
						<Box className={classes.centeredTextBox}>
							<Typography color="textSecondary">Rule:</Typography>
							{(event.triggeredRules?.length ?? 0) <= 1 ||
							view.separateEvents ? (
								<Tooltip
									title={
										allRules?.find(
											(rule) =>
												rule.name ===
												event.triggeredRule?.name,
										) ? (
											<Typography variant="caption">
												Description:{" "}
												{
													allRules?.find(
														(rule) =>
															rule.name ===
															event.triggeredRule
																?.name,
													)?.description
												}
											</Typography>
										) : (
											""
										)
									}
								>
									<Typography noWrap>
										{event.triggeredRule?.name}
									</Typography>
								</Tooltip>
							) : (
								<Select
									value={event.triggeredRules?.[0].name}
									disableUnderline
									onClick={(e) => e.stopPropagation()}
									SelectDisplayProps={{
										style: { fontSize: "0.9rem" },
									}}
									classes={{
										icon: classes.selectIcon,
									}}
								>
									{event.triggeredRules?.map(
										(
											rule: { name: string },
											idx: number,
										) => (
											<MenuItem
												key={idx}
												value={rule.name}
												style={{
													backgroundColor: "white",
													fontSize: "0.9rem",
													cursor: "auto",
												}}
											>
												<Tooltip
													title={
														<Typography variant="caption">
															Description:{" "}
															{
																allRules?.find(
																	(r) =>
																		r.name ===
																		rule.name,
																)?.description
															}
														</Typography>
													}
												>
													<span>{rule.name}</span>
												</Tooltip>
											</MenuItem>
										),
									)}
								</Select>
							)}
						</Box>
					)}

					{hasMatches && (
						<Box className={classes.matchPagination}>
							{matchesCount > 1 ? (
								<>
									<IconButton
										style={{ padding: 0 }}
										disabled={currentPage === 0}
										onClick={(e) => {
											e.stopPropagation();
											dispatch(
												updateLiveEventAction({
													...event,
													best: {
														...event.best,
														currentPage:
															currentPage - 1,
													},
												} as ILiveEvent),
											);
										}}
									>
										<ArrowLeftIcon />
									</IconButton>
									{currentPage + 1}/{matchesCount}
									<IconButton
										style={{ padding: 0 }}
										disabled={
											currentPage ===
											(event.best?.matches?.length ?? 1) -
												1
										}
										onClick={(e) => {
											e.stopPropagation();
											const newPage = currentPage + 1;
											const nextMatch: MatchProps | null =
												event.best?.matches?.[
													newPage + 1
												] ?? null;

											if (nextMatch) {
												const isNextLoaded =
													subjects.keys[
														nextMatch.watchlist
													]?.content.find(
														(s) =>
															s.name ===
															nextMatch.id,
													);

												if (!isNextLoaded)
													loadMatch(nextMatch);
											}
											dispatch(
												updateLiveEventAction({
													...event,
													best: {
														...event.best,
														currentPage: newPage,
													},
												} as ILiveEvent),
											);
										}}
									>
										<ArrowRightIcon />
									</IconButton>
								</>
							) : null}
						</Box>
					)}
				</div>
			</>
		);
	},
);

export default LiveEvent;
