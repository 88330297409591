import React from "react";
import {
	Divider,
	List,
	ListItemText,
	ListItemIcon,
	makeStyles,
	IconButton,
	Link,
	Box,
} from "@material-ui/core";
import { useLocation } from "react-router";
import NavMenuItem from "./NavMenuItem";
import { CurrentUser } from "../../containers/CurrentUser";
import { THEME } from "../../config/index";
import MenuIcon from "@material-ui/icons/Menu";
import { IMenuItem } from "../../config/MenuItems";
import { matchRoutes } from "../../helpers/Utils";

interface IDrawerProps {
	menu: Readonly<IMenuItem[]>;
	logo?: string;
	largerLogo?: string;
	alt?: string;
	name?: string;
	onDrawer(state: boolean): void;
	redirectLink?: string;
	isDrawerOpen: boolean;
}

const useStyles = makeStyles(() => ({
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0 5px",
		...THEME.mixins.toolbar,
		justifyContent: "flex-start",
	},
	dividerColor: {
		marginLeft: "5px",
		marginRight: "5px",
	},
	logoText: {
		paddingLeft: "16px",
	},
	smallLogo: {
		isplay: "inline",
		height: "30px",
		paddingLeft: "5px",
		"&:hover": {
			cursor: "pointer",
		},
	},
	list: {
		height: "93vh",
		display: "flex",
		gap: 24,
		flexDirection: "column",
		justifyContent: "space-between",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		scrollbarWidth: "none",
	},
}));

const InsideDrawer: React.FC<IDrawerProps> = (props) => {
	const location = useLocation();
	const classes = useStyles();
	const matches = matchRoutes(window.location.pathname, props.menu);
	let active = -1;
	for (let i = matches.length - 1; i > -1; i--) {
		active = props.menu.indexOf(matches[i]);
		if (active !== -1) {
			break;
		}
	}

	const handleClick = () => {
		props.onDrawer(true);
	};

	return (
		<div>
			<div className={classes.drawerHeader}>
				<IconButton onClick={handleClick} color="inherit">
					<MenuIcon />
				</IconButton>
				<Divider
					orientation="vertical"
					flexItem
					classes={{ root: classes.dividerColor }}
				/>
				<Link href={props.redirectLink}>
					<img
						className={classes.smallLogo}
						src={props.logo}
						alt={props.alt ? props.alt : "Neurotechnology"}
					/>
				</Link>

				<span className={classes.logoText}>{props.name}</span>
			</div>
			<Divider />
			<List className={classes.list}>
				<Box>
					{props.menu
						.filter((item) => item.route !== "/logout")
						.map((mItem, index) => (
							<NavMenuItem
								key={index}
								selected={
									mItem.route === location.pathname ||
									mItem.subItems?.some(
										(subItem) =>
											subItem.route ===
											decodeURIComponent(
												location.pathname,
											),
									)
								}
								subItems={mItem.subItems || []}
								link={
									mItem.subItems?.find(
										(s) =>
											s.route ===
											decodeURIComponent(
												location.pathname,
											),
									)?.route ??
									((mItem.subItems?.length ?? 0) > 0 &&
									mItem.subItems
										? mItem.subItems[0].route
										: mItem.route) ??
									""
								}
								isDrawerOpen={props.isDrawerOpen}
							>
								{mItem.icon ? (
									<ListItemIcon style={{ color: "white" }}>
										{mItem.icon || null}
									</ListItemIcon>
								) : null}
								<ListItemText primary={mItem.name} />
							</NavMenuItem>
						))}
				</Box>
				<Box>
					<CurrentUser isDrawerOpen={props.isDrawerOpen} />
					{props.menu.find((item) => item.route === "/logout") ? (
						<NavMenuItem
							link={"/logout"}
							isDrawerOpen={props.isDrawerOpen}
						>
							<ListItemIcon style={{ color: "white" }}>
								{props.menu.find(
									(item) => item.route === "/logout",
								)?.icon || null}
							</ListItemIcon>
							<ListItemText primary={"Logout"} />
						</NavMenuItem>
					) : null}
				</Box>
			</List>
		</div>
	);
};

export default InsideDrawer;
