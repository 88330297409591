import React from "react";
import { IWatchlist } from "../../store/Watchlists/types";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import {
	Button,
	CircularProgress,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@material-ui/core";
import SecretFormSelect from "../UI/SecretFormSelect";
import { EventsFilter } from "../../store/Events/types";
import useCanSeeSecrets from "../../hooks/useCanSeeSecrets";

interface IWatchlistFormProps {
	onSubmit(item: IWatchlist | string | undefined): void;
	data: IWatchlist | undefined;
	close(): void;
	loading: boolean;
	onLoading(): void;
}

type DisplayableWatchlist = {
	secret?: string;
	id: string;
	description: string;
	type: "Faces" | "LicensePlates" | "License Plates";
};

const convertType = (data: IWatchlist) => {
	if (data.type === "LicensePlates") {
		return {
			...data,
			type: "License Plates",
		};
	}
	return data;
};

const convertTypeBack = (data: DisplayableWatchlist): IWatchlist => {
	if (data.type === "License Plates")
		return {
			...data,
			type: "LicensePlates",
		};
	return data as IWatchlist;
};

const WatchlistForm: React.FC<IWatchlistFormProps> = ({
	onSubmit,
	data,
	close,
	loading,
	onLoading,
}) => {
	const canSeeSecrets = useCanSeeSecrets(true);
	const [secret, setSecret] = React.useState<
		EventsFilter["secret"] | undefined
	>(undefined);
	const [type, setType] = React.useState<string>(
		data ? convertType(data).type : "Faces",
	);

	const handleFormSubmit = async (item: IWatchlist) => {
		onLoading();
		onSubmit(item.description);
	};

	const handleFormCreate = async (item: DisplayableWatchlist) => {
		onLoading();
		item.secret = secret?.id;
		item.type = type as unknown as
			| "Faces"
			| "LicensePlates"
			| "License Plates";
		onSubmit(convertTypeBack(item));
	};

	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setType((event.target as HTMLInputElement).value);
	};

	return (
		<Form
			onSubmit={data ? handleFormSubmit : handleFormCreate}
			initialValues={data ? convertType(data) : { type: "Faces" }}
			render={({ handleSubmit, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<TextField
						autoFocus
						name="id"
						label="Id"
						required
						disabled={Boolean(data)}
						inputProps={{
							spellCheck: false,
							maxLength: 32,
						}}
					/>
					<TextField
						name="description"
						label="Description"
						multiline
						maxRows={5}
						inputProps={{
							spellCheck: false,
						}}
					/>
					{data ? (
						<>
							{canSeeSecrets && (
								<TextField
									name="secret"
									label="Secret"
									disabled={Boolean(data)}
									inputProps={{
										spellCheck: false,
									}}
								/>
							)}
							<TextField
								name="type"
								label="Type"
								required
								disabled={Boolean(data)}
								inputProps={{
									spellCheck: false,
								}}
							/>
						</>
					) : (
						<>
							<SecretFormSelect
								onSecret={setSecret}
								value={secret}
								onlyWriteable
							/>
							<FormControl
								component="fieldset"
								style={{ marginTop: 10 }}
								required
							>
								<FormLabel component="legend">Type</FormLabel>
								<RadioGroup
									aria-label="type"
									name="type"
									value={type}
									onChange={handleTypeChange}
								>
									<FormControlLabel
										value="Faces"
										control={<Radio />}
										label="Faces"
									/>
									<FormControlLabel
										value="LicensePlates"
										control={<Radio />}
										label="License Plates"
									/>
								</RadioGroup>
							</FormControl>
						</>
					)}
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginBottom: 10,
						}}
					>
						{loading ? <CircularProgress /> : null}
						<Button type="submit" disabled={pristine || loading}>
							{data ? "Save" : "Create"}
						</Button>
						<Button onClick={close}>Cancel</Button>
					</div>
				</form>
			)}
		/>
	);
};

export default WatchlistForm;
