import React from "react";
import Authentication from "../store/Authentication/AuthenticationStore";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { logsLogout } from "../store/Logs/action";

interface ILogoutDispatchProps {
	removeLogs(): void;
}

type ILogoutProps = ILogoutDispatchProps & RouteComponentProps<any>;

const Logout: React.FC<ILogoutProps> = ({ removeLogs }) => {
	React.useEffect(() => {
		window.location.reload();
		Authentication.clearAuthentication();
		removeLogs();
	}, [removeLogs]);
	return <Redirect to="/login" />;
};

const mapDispatchToProps = (dispatch: any): ILogoutDispatchProps => {
	return {
		removeLogs: () => dispatch(logsLogout()),
	};
};
export default connect(null, mapDispatchToProps)(Logout);
