import {
	Box,
	Button,
	makeStyles,
	Typography,
	FormControlLabel,
	Checkbox,
	IconButton,
	Tooltip,
	DialogContent,
	DialogActions,
	Collapse,
} from "@material-ui/core";
import { ArrowBackIosRounded } from "@material-ui/icons";
import { TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useHistory } from "react-router";
import { THEME } from "../../config";
import { EventsFilter } from "../../store/Events/types";
import { getSearchRequestImage } from "../../store/ImageSearch/action";
import LinearBar from "../UI/LinearBar";
import SecretFormSelect from "../UI/SecretFormSelect";
import { BackwardSearchRequest } from "../../store/BackwardSearch/types";
import SourcesFormSelect from "../UI/SourcesFormSelect";
import { ISource } from "../../store/Sources/types";
import useAllSources from "../../hooks/useAllSources";
import { LogsTimeFilter } from "../Logs/LogsTimeFilter";
import moment from "moment";
import { IPreference } from "../../store/UserConfig/types";
import { updateConfigAction } from "../../store/UserConfig/action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		gap: 12,
		padding: "8px 0",
		flex: 1,
		overflow: "hidden",
	},
	responsivePhoto: {
		objectFit: "contain",
		width: "100%",
		height: "100%",
	},
	box: {
		borderColor: THEME.palette.secondary.main,
		borderRadius: 4,
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-between",
		height: "80%",
		width: "100%",
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		flex: 1,
	},
	controls: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: 16,
	},
	inputs: { flex: 1, padding: "0 4px 0 14px" },
	input: {
		marginTop: 8,
	},
	imgAction: {
		width: "fit-content",
	},
	photosNav: {
		marginTop: 4,
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	content: {
		display: "flex",
		gap: 20,
	},
	dateLabel: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
		fontSize: "0.9rem",
	},
	dateBox: {
		display: "flex",
		gap: "24px",
	},
}));

interface BackwardSearchFormProps {
	close(): void;
	onSubmit(
		searchRequest: Omit<BackwardSearchRequest, "image"> & {
			images: string[];
		},
	): Promise<void>;
	loading: boolean;
	canSeeSecrets: boolean;
	draggedImages: string[];
	uploadProgress: {
		loaded: number;
		total: number;
	};
	subjectId?: string;
}

export interface IVmsSourcePair {
	vms: string;
	source: string;
}

export const MATCHING_THRESHOLD_LIMITS = {
	min: 1,
	max: 500,
} as const;

const BackwardSearchForm: React.FC<BackwardSearchFormProps> = ({
	close,
	onSubmit,
	loading,
	canSeeSecrets,
	draggedImages,
	uploadProgress,
	subjectId,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const isUploading = uploadProgress.total > uploadProgress.loaded;
	const allSources = useAllSources();
	const preferences = useSelector(
		(state: AppState) => state.userConfig.data.preference,
	);
	const [sources, setSources] = useState<Partial<ISource>[]>([]);
	const [images, setImages] = useState<string[]>([]);
	const [useTimeConstraints, setUseTimeConstraints] = useState(false);
	const [secret, setSecret] = useState<EventsFilter["secret"] | undefined>(
		undefined,
	);
	const [matchingThreshold, setMatchingThreshold] = useState<number>(48);
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	const [relativeFrom, setRelativeFrom] = useState({
		value: 1,
		text: "month",
	});
	const [relativeUntil, setRelativeUntil] = useState({
		value: 0,
		text: "second",
	});

	const [showAbsoluteStart, setShowAbsoluteStart] = useState(0);
	const [showAbsoluteEnd, setShowAbsoluteEnd] = useState(0);

	const [from, setFrom] = useState(moment().toISOString());
	const [until, setUntil] = useState(moment().toISOString());

	const handleSubmit = async () => {
		const selectedSources = sources.map((source) =>
			allSources.find(
				(s) => s.id === source.id && s.vmsName === source.vmsName,
			),
		);
		const vmsSourcePair: IVmsSourcePair[] = [];
		selectedSources.forEach((source) => {
			if (source?.vmsName && source.id)
				vmsSourcePair.push({ vms: source.vmsName, source: source.id });
		});

		const fromDate = showAbsoluteStart
			? moment(from)
			: moment().subtract(
					relativeFrom.value,
					relativeFrom.text as moment.unitOfTime.DurationConstructor,
			  );
		const toDate = showAbsoluteEnd
			? moment(until)
			: moment().subtract(
					relativeUntil.value,
					relativeUntil.text as moment.unitOfTime.DurationConstructor,
			  );

		const data: Omit<BackwardSearchRequest, "image"> & {
			images: string[];
		} = {
			images,
			matchingThreshold,
			acceptLowQuality: preferences.backwardAcceptLowQuality ?? false,
			vmsSourcePair: vmsSourcePair.length ? vmsSourcePair : undefined,
			fromTime: useTimeConstraints ? fromDate.toISOString() : undefined,
			toTime: useTimeConstraints ? toDate.toISOString() : undefined,
		};

		if (subjectId) data.subjectId = subjectId;
		if (secret?.id) data.secret = secret.id;

		onSubmit(data);
	};

	const handlePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		setImages([]);
		setCurrentIndex(0);
		const target = e.target as HTMLInputElement;
		const files = target.files;

		if (files) {
			for (const image of files) {
				const reader = new FileReader();
				reader.onload = () => {
					const res = reader.result as string;
					setImages((prev) => [...prev, btoa(res)]);
				};
				reader.readAsBinaryString(image);
			}
		}
	};

	useEffect(() => {
		const state = history.location.state as
			| { [key: string]: any }
			| undefined;
		if (state) {
			getSearchRequestImage(state.id).then((res) => {
				if (res.image) setImages([res.image]);
			});
			if ("secret" in state) setSecret({ id: state.secret as string });
			if ("matchingThreshold" in state)
				setMatchingThreshold(Number(state.matchingThreshold));
			history.replace(history.location.pathname, undefined);
		}
	}, [history]);

	useEffect(() => {
		if (draggedImages.length > 0) setImages(draggedImages);
	}, [draggedImages]);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={{
				matchingThreshold: 48,
				subjectId: subjectId ?? "",
			}}
			render={({ handleSubmit, hasValidationErrors }) => (
				<form
					onSubmit={handleSubmit}
					autoComplete="off"
					className={classes.root}
				>
					<DialogContent className={classes.content}>
						<Box
							flex={1}
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<Box className={classes.box} border={1}>
								{images.length > 0 && (
									<img
										alt="BackwardSearch"
										src={`data:image/jpeg;base64,${images[currentIndex]}`}
										className={classes.responsivePhoto}
									/>
								)}
							</Box>
							{images.length > 1 && (
								<Box className={classes.photosNav}>
									<IconButton
										onClick={() =>
											setCurrentIndex((prev) => prev - 1)
										}
										size="small"
										disabled={currentIndex === 0}
									>
										<ArrowBackIosRounded />
									</IconButton>
									<Typography variant="body1">
										{currentIndex + 1}/{images.length}
									</Typography>
									<IconButton
										onClick={() =>
											setCurrentIndex((prev) => prev + 1)
										}
										size="small"
										style={{ transform: "rotate(180deg)" }}
										disabled={
											currentIndex === images.length - 1
										}
									>
										<ArrowBackIosRounded />
									</IconButton>
								</Box>
							)}
							<Button
								component="label"
								className={classes.imgAction}
							>
								{"Open Image(s)"}
								<input
									hidden
									type="file"
									name="image"
									id="file"
									multiple
									accept=".tif, .tiff, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .jpb, .jpl, .png, .bmp, .dib, .webp"
									onChange={handlePhoto}
								/>
							</Button>
						</Box>
						<Box className={classes.inputs}>
							{canSeeSecrets && (
								<SecretFormSelect
									onSecret={setSecret}
									value={secret}
									tooltipTitle="Search results will be visible only to people assigned to selected secret"
									margin="none"
								/>
							)}
							<FormControlLabel
								control={
									<Checkbox
										name="useTimeConstraints"
										color="primary"
										checked={useTimeConstraints}
										onChange={() =>
											setUseTimeConstraints(
												(prev) => !prev,
											)
										}
									/>
								}
								label="Use time constraints"
							/>
							<Collapse in={useTimeConstraints}>
								<Box className={classes.dateBox}>
									<Typography className={classes.dateLabel}>
										From
									</Typography>
									<Typography className={classes.dateLabel}>
										To
									</Typography>
								</Box>
								<LogsTimeFilter
									from={moment(from)}
									until={moment(until)}
									relativeFrom={relativeFrom}
									relativeUntil={relativeUntil}
									showAbsoluteStart={showAbsoluteStart}
									showAbsoluteEnd={showAbsoluteEnd}
									onAbsoluteStartChange={(num) =>
										setShowAbsoluteStart(num)
									}
									onAbsoluteEndChange={(num) =>
										setShowAbsoluteEnd(num)
									}
									onFromChange={(date) =>
										setFrom(date.toISOString())
									}
									onUntilChange={(date) =>
										setUntil(date.toISOString())
									}
									onRelativeFromChange={(value, text) =>
										setRelativeFrom({ value, text })
									}
									onRelativeUntilChange={(value, text) =>
										setRelativeUntil({
											value,
											text,
										})
									}
									onFromUntilChange={(from, until) => {
										setFrom(from.toISOString());
										setUntil(until.toISOString());
									}}
								/>
							</Collapse>
							<Tooltip title="Search low quality faces from provided image">
								<FormControlLabel
									control={
										<Checkbox
											name="acceptLowQuality"
											color="primary"
											checked={
												preferences.backwardAcceptLowQuality ??
												false
											}
											onChange={(_, checked) => {
												const config: IPreference = {
													...preferences,
													backwardAcceptLowQuality:
														checked,
												};
												dispatch(
													updateConfigAction({
														variant: "preference",
														config,
													}),
												);
											}}
										/>
									}
									label="Accept low quality"
								/>
							</Tooltip>
							<TextField
								className={classes.input}
								name="matchingThreshold"
								label="Matching threshold"
								type="number"
								required
								value={matchingThreshold}
								fieldProps={{
									validate: (value) => {
										if (
											value <
												MATCHING_THRESHOLD_LIMITS.min ||
											value >
												MATCHING_THRESHOLD_LIMITS.max
										)
											return `Value must be between ${MATCHING_THRESHOLD_LIMITS.min} and ${MATCHING_THRESHOLD_LIMITS.max}`;

										if (!value) return "Field is required";
									},
								}}
								inputProps={{
									onChange: (e) =>
										setMatchingThreshold(
											parseInt(e.currentTarget.value),
										),
								}}
							/>
							<SourcesFormSelect
								className={classes.input}
								value={sources}
								vmsName=""
								label="Sources"
								onSource={(newSources) =>
									setSources(newSources ?? [])
								}
								defaultToAll
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Box className={classes.buttons}>
							{isUploading && (
								<Box
									textAlign="center"
									alignItems="center"
									width="40%"
									m="0 auto"
								>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										gridGap={3}
									>
										<Typography
											variant="body2"
											color="secondary"
										>
											Uploading..
										</Typography>
										<Typography
											variant="body2"
											style={{ fontWeight: "bold" }}
											color="secondary"
										>
											({uploadProgress.loaded} /{" "}
											{uploadProgress.total})
										</Typography>
									</Box>
									<LinearBar {...uploadProgress} />
								</Box>
							)}
							<Button
								type="submit"
								disabled={
									(!images.length && !subjectId) ||
									loading ||
									isUploading ||
									hasValidationErrors
								}
							>
								Search
							</Button>
							<Button onClick={close}>close</Button>
						</Box>
					</DialogActions>
				</form>
			)}
		/>
	);
};

export default BackwardSearchForm;
