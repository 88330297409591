import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import {
	IEditSubject,
	IEditSubjectBody,
	ISubject,
} from "../../../store/Subjects/types";
import { IWatchlist } from "../../../store/Watchlists/types";
import FaceEditForm from "../../Forms/SubjectForms/FaceEditForm";
import Title from "../Common/Title";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

interface IEditFace {
	open: boolean;
	onConfirm(subjectId: string, body: IEditSubjectBody): void;
	onClose(): void;
	subject: ISubject;
	watchlist: IWatchlist;
	schema: IMetadataSchema;
	metadata: IMetadata;
	onLoading(): void;
	loading: boolean;
}

const SubjectFaceEditDialog: React.FC<IEditFace> = ({
	open,
	onConfirm,
	onClose,
	subject,
	watchlist,
	schema,
	metadata,
	onLoading,
	loading,
}) => {
	const handleConfirm = (faceInfo: IEditSubject) => {
		let isThumbNew = false;
		let isMetadataNew = false;
		if (subject.thumb !== faceInfo.image) isThumbNew = true;
		if (subject.metadata !== faceInfo.metadata) isMetadataNew = true;
		const body: IEditSubjectBody = {
			metadata: faceInfo.metadata,
			thumbnail: faceInfo.image,
			changeThumbnail: isThumbNew,
			changeMetadata: isMetadataNew,
			changeOrigin: false,
		};
		onConfirm(subject.name, body);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
			<Title
				title={`${watchlist.id} - ${subject.name}`}
				onClose={onClose}
			/>
			<DialogContent>
				<FaceEditForm
					onConfirm={handleConfirm}
					onClose={onClose}
					schema={schema}
					initialMetadata={metadata}
					subject={subject}
					watchlist={watchlist}
					onLoading={onLoading}
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default SubjectFaceEditDialog;
