import React from "react";
import { Container, Dialog, DialogContent } from "@material-ui/core";
import { IUser } from "../../store/Users/types";
import IPassword from "../InputForm/IPassword";
import Title from "./Common/Title";
import UserForm from "../Forms/UserForm";

interface IUserDialogPropsInternal {
	dialogOpen: boolean;
	passwordRequired: boolean;
	form: IUserForm;
	dialogTitle: string;
	handleSubmit(): void;
	closeDialog(): void;
	handleInputChange(
		event: React.ChangeEvent<{
			name?: string;
			value: unknown;
		}>,
	): void;
	handleAuthoritiesChange(selectedAuthorities: string[]): void;
	handlePasswordChange(password: IPassword): void;
	loading: boolean;
}

const UserDialog: React.FC<IUserDialogPropsInternal> = ({
	dialogOpen,
	passwordRequired,
	form,
	dialogTitle,
	handleSubmit,
	closeDialog,
	handleInputChange,
	handleAuthoritiesChange,
	handlePasswordChange,
	loading,
}) => {
	const handleClose = (
		event: {},
		reason: "backdropClick" | "escapeKeyDown",
	) => {
		if (reason !== "backdropClick") closeDialog();
	};

	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		handleSubmit();
	};

	return (
		<Container>
			<Dialog
				fullWidth
				maxWidth={"sm"}
				open={dialogOpen}
				onClose={handleClose}
			>
				<Title title={dialogTitle} onClose={closeDialog} />
				<DialogContent>
					<UserForm
						loading={loading}
						handleInputChange={handleInputChange}
						handleAuthoritiesChange={handleAuthoritiesChange}
						handlePasswordChange={handlePasswordChange}
						form={form}
						passwordRequired={passwordRequired}
						handleSubmit={handleFormSubmit}
						handleClose={handleClose}
					/>
				</DialogContent>
			</Dialog>
		</Container>
	);
};

export interface IUserDialogProps {
	dialogOpen: boolean;
	availableAuthorities: string[];
	user?: IUser;
	handleSubmit(form: IUserForm): void;
	closeDialog(): void;
	onLoading(): void;
	loading: boolean;
}

export interface IUserForm {
	id?: number;
	userName: string;
	email: string;
	password: IPassword;
	enabled: boolean;
	allAuthorities: string[];
	availableAuthorities: string[];
	selectedAuthorities: string[];
}

type IUserDialogExProps = IUserDialogProps;

const filterAuthorities = (availableAuthorities: string[], user?: IUser) => {
	if (!user) return availableAuthorities;
	return availableAuthorities.filter(
		(auth) => !user.authorities.includes(auth),
	);
};

const UserDialogEx: React.FC<IUserDialogExProps> = (props) => {
	const [form, setForm] = React.useState<IUserForm>({
		id: props.user ? props.user.id : undefined,
		userName: props.user ? props.user.userName : "",
		email: props.user ? props.user.email : "",
		password: {
			value: "",
			valid: false,
		},
		allAuthorities: props.availableAuthorities,
		availableAuthorities: filterAuthorities(
			props.availableAuthorities,
			props.user,
		),
		selectedAuthorities: props.user ? props.user.authorities : [],
		enabled: props.user ? props.user.enabled : true,
	});
	const dialogTitle = props.user ? "Edit user" : "New user";
	const passwordRequired = props.user ? false : true;

	const handleSubmit = () => {
		props.onLoading();
		props.handleSubmit(form);
	};

	const handleAuthoritiesChange = (selectedAuthorities: string[]) => {
		setForm({ ...form, selectedAuthorities: selectedAuthorities });
	};

	const handleInputChange = (
		event: React.ChangeEvent<
			{ name?: string; value: unknown } | HTMLInputElement
		>,
	) => {
		switch (event.target.name) {
			case "username": {
				setForm({ ...form, userName: event.target.value as string });
				break;
			}
			case "email": {
				setForm({ ...form, email: event.target.value as string });
				break;
			}
			case "enabled": {
				setForm({ ...form, enabled: !form.enabled });
				break;
			}
		}
	};

	const handlePasswordChange = (password: IPassword) => {
		setForm({ ...form, password: password });
	};

	return (
		<UserDialog
			passwordRequired={passwordRequired}
			dialogTitle={dialogTitle}
			dialogOpen={props.dialogOpen}
			handleSubmit={handleSubmit}
			closeDialog={props.closeDialog}
			handleAuthoritiesChange={handleAuthoritiesChange}
			handleInputChange={handleInputChange}
			handlePasswordChange={handlePasswordChange}
			form={form}
			loading={props.loading}
		></UserDialog>
	);
};

export default UserDialogEx;
