import React from "react";
import MenuItems from "../../config/MenuItems";
import {
	ThemeProvider,
	createStyles,
	makeStyles,
	CssBaseline,
} from "@material-ui/core";
import { DRAWER_WIDTH, DRAWER_WIDTH_COLLAPSED, THEME } from "../../config";
import { Container } from "@material-ui/core";
import { IMenuItem } from "../../config/MenuItems";
import logo from "../../assets/Svgs/logo.svg";
import neurotechnologyRetina from "../../assets/images/neurotechnology-retina.png";
import Navigation from "../../components/Navigation/Navigation";
import Authentication from "../../store/Authentication/AuthenticationStore";
import { checkSecrets } from "../../store/Secrets/action";
import { getProperties } from "../../store/Properties/action";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: "flex",
			maxWidth: "100vw",
		},
		content: {
			flexGrow: 1,
			width: "100%",
		},
	}),
);

export const checkPermissions = (permissions: string[]) => {
	if (permissions.length === 0) {
		return true;
	}
	let flag = false;
	for (let i = 0; i < permissions.length; i++) {
		if (Authentication.isAuthority(permissions[i])) {
			flag = true;
			break;
		}
	}
	return flag;
};

const checkAvailability = async (mItem: IMenuItem) => {
	let flag = checkPermissions(mItem.permissions);
	switch (mItem.name) {
		case "Secrets": {
			if (flag) {
				flag = true;
				break;
			}
			if (await checkSecrets()) {
				flag = true;
			}
			break;
		}
	}
	return flag;
};

const getServicesAsSubItems = async (permissions: string[]) => {
	const services: IMenuItem[] = [];
	try {
		const properties = await getProperties();
		for (let i = 0; i < properties.length; i++) {
			services.push({
				name: properties[i].service,
				permissions: permissions,
				route: `/config/${properties[i].service}`,
				icon: <SettingsApplicationsIcon />,
			});
		}
		return services;
	} catch (error) {
		return services;
	}
};

const IS_DRAWER_OPEN_STORAGE_KEY = "SVC_IS_DRAWER_OPEN";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [open, setOpen] = React.useState<boolean>(
		JSON.parse(
			localStorage.getItem(IS_DRAWER_OPEN_STORAGE_KEY) ?? "null",
		) ?? window.innerWidth > 960
			? true
			: false,
	);

	const [menu, setMenu] = React.useState<IMenuItem[]>([]);

	const classes = useStyles();

	React.useEffect(() => {
		let isSubed = true;
		const loadMenu = async () => {
			for (let i = 0; i < MenuItems.length; i++) {
				const tempItem = MenuItems[i];
				if (await checkAvailability(tempItem)) {
					if (tempItem.name === "Configuration") {
						const arrOfServices = await getServicesAsSubItems(
							tempItem.permissions,
						);
						tempItem.subItems = [];
						for (let j = 0; j < arrOfServices.length; j++) {
							tempItem.subItems.push(arrOfServices[j]);
						}
						if (isSubed && tempItem.subItems.length > 0) {
							setMenu((menu) => [...menu, tempItem]);
						}
					} else {
						if (isSubed) {
							setMenu((menu) => [...menu, tempItem]);
						}
					}
				}
			}
		};
		if (Authentication.isAuthenticated()) {
			loadMenu();
		}
		return () => {
			isSubed = false;
		};
	}, []);

	const handleDrawerChange = (drawerStatus: boolean) => {
		const newStatus = drawerStatus === false ? false : !open;
		localStorage.setItem(IS_DRAWER_OPEN_STORAGE_KEY, newStatus.toString());
		setOpen(newStatus);
	};

	const disabledPadding = window.location.pathname === "/events";

	return (
		<ThemeProvider theme={THEME}>
			<div
				className={classes.root}
				style={{
					paddingLeft: open ? DRAWER_WIDTH : DRAWER_WIDTH_COLLAPSED,
					transition: THEME.transitions.create("padding-left", {
						easing: THEME.transitions.easing.sharp,
						duration: THEME.transitions.duration.leavingScreen,
					}),
				}}
			>
				<CssBaseline />
				{menu.length > 0 ? (
					<Navigation
						isDrawerOpen={open}
						onDrawerChange={handleDrawerChange}
						menu={menu}
						logo={logo}
						largerLogo={neurotechnologyRetina}
						name="SentiVeillance"
						redirectLink="/vms"
					/>
				) : null}
				<Container
					maxWidth={false}
					disableGutters={disabledPadding}
					style={{ width: "100%" }}
				>
					<main className={classes.content}>{children}</main>
				</Container>
			</div>
		</ThemeProvider>
	);
};

export default Layout;
