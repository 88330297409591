import React from "react";
import { Divider, Snackbar, Tooltip, TextField } from "@material-ui/core";
import { HttpError } from "../../config/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import Alert from "./Alert";
import { Autocomplete } from "@material-ui/lab";
import { getVMSAction } from "../../store/VMS/action";
import { IVMSDataContainer, IgetVMSRequest, IVMS } from "../../store/VMS/types";

interface IVMSViewPropsToState {
	vms: IVMSDataContainer;
	error?: HttpError;
}

interface IVMSViewPropsToDispatch {
	loadVMS: (request: IgetVMSRequest) => void;
}

interface IVMSFormSelectOwnProps {
	value: Partial<IVMS> | undefined;
	tooltipTitle?: string;
	onVMS(vms?: Partial<IVMS>): void;
}

interface IVMSFormSelectProps
	extends IVMSViewPropsToState,
		IVMSFormSelectOwnProps,
		IVMSViewPropsToDispatch {
	className?: string;
}

const _VMSFormSelect: React.FC<IVMSFormSelectProps> = ({
	vms,
	value,
	error,
	loadVMS,
	tooltipTitle,
	onVMS,
	className,
}) => {
	React.useEffect(() => {
		loadVMS({});
	}, [loadVMS]);

	const handleVMSInput = (
		_event: React.ChangeEvent<{}>,
		value: Partial<IVMS> | null,
	) => {
		if (value !== null) {
			onVMS({
				name: value.name,
			});
		} else {
			onVMS(undefined);
		}
	};

	return (
		<>
			<Autocomplete
				noOptionsText={"No VMS found."}
				options={vms.content}
				getOptionSelected={(option, value) =>
					option.name === value.name
				}
				getOptionLabel={(option) => option.name ?? ""}
				renderOption={(s) => (
					<div
						style={{
							display: "inline",
							maxHeight: 300,
						}}
					>
						<b>{s.name}</b>
						<br />
						<Divider />
					</div>
				)}
				onChange={handleVMSInput}
				value={value ?? null}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						name="vms"
						id="vms-search"
						className={className}
						label={
							tooltipTitle ? (
								<Tooltip
									title={tooltipTitle}
									placement="bottom-start"
								>
									<span>VMS</span>
								</Tooltip>
							) : (
								"VMS"
							)
						}
					/>
				)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state: AppState): IVMSViewPropsToState => {
	return {
		error: state.vms.erorr,
		vms: state.vms.data,
	};
};
const mapDispatchToProps = (dispatch: any): IVMSViewPropsToDispatch => {
	return {
		loadVMS: (request: IgetVMSRequest) => getVMSAction(request)(dispatch),
	};
};

const VMSFormSelect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(_VMSFormSelect);
export default VMSFormSelect;
