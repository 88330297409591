import React from "react";
import {
	Grid,
	Typography,
	makeStyles,
	Divider,
	Hidden,
} from "@material-ui/core";
import { ISubject } from "../../../store/Subjects/types";
import LicensePlateMetadata from "./LicensePlateMetadata";

const useStyles = (selected: number, index: number) =>
	makeStyles(() => ({
		responsivePhoto: {
			objectFit: "contain",
			width: "100%",
			height: "100%",
			maxHeight: 240,
			padding: 5,
		},
		container: {
			"&:hover": {
				background: "rgba(214, 214, 214, 1)",
			},
			background: selected === index ? "rgba(224, 224, 224, 1)" : "white",
			border: "1px solid",
			borderRadius: 2,
			borderColor: selected === index ? "#099e76" : "transparent",
		},
	}));

const sliceLicensePlateNumber = (lp: string, length: number) => {
	if (lp.length > length && lp.indexOf(" ") === -1)
		return lp.slice(0, length - 3).toUpperCase() + "...";
	return lp.toUpperCase();
};

interface ILPItem {
	subject: ISubject;
	index: number;
	hasThumb: boolean;
	selected: number;
	onSubject(index: number): void;
	onDblClick(option: string): void;
}

const LicensePlateItem: React.FC<ILPItem> = ({
	subject,
	index,
	hasThumb,
	selected,
	onSubject,
	onDblClick,
}) => {
	const classes = useStyles(selected, index)();

	const handleDoubleClick = () => {
		onDblClick("edit");
	};

	return (
		<Grid item xs={12} className={classes.container}>
			<div
				onClick={() => onSubject(index)}
				onDoubleClick={handleDoubleClick}
			>
				<Grid container alignItems="center" spacing={2}>
					{hasThumb ? (
						<Grid item xs={3}>
							{subject.thumb ? (
								<img
									className={classes.responsivePhoto}
									alt="License Plate"
									src={`data:image/jpeg;base64,${subject.thumb}`}
								/>
							) : null}
						</Grid>
					) : null}
					<Hidden mdDown>
						<Grid item xs={1}>
							<Typography
								variant="h5"
								style={{ marginLeft: hasThumb ? 0 : 15 }}
							>
								{subject.origin
									? subject.origin.toUpperCase()
									: null}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Hidden xlUp>
								<Typography variant="h5">
									{sliceLicensePlateNumber(subject.name, 19)}
								</Typography>
							</Hidden>
							<Hidden lgDown>
								<Typography variant="h4">
									{sliceLicensePlateNumber(subject.name, 19)}
								</Typography>
							</Hidden>
						</Grid>
					</Hidden>
					<Hidden lgUp>
						<Grid item xs={2}>
							<Hidden only={["xs", "sm"]}>
								<Typography variant="h6">
									{subject.origin
										? subject.origin.toUpperCase()
										: null}
								</Typography>
								<Typography
									variant="h6"
									style={{ fontSize: 18 }}
								>
									{sliceLicensePlateNumber(subject.name, 12)}
								</Typography>
							</Hidden>
							<Hidden only={["md", "xs"]}>
								<Typography variant="subtitle2">
									{subject.origin
										? subject.origin.toUpperCase()
										: null}
								</Typography>
								<Typography variant="subtitle2">
									{sliceLicensePlateNumber(subject.name, 13)}
								</Typography>
							</Hidden>
							<Hidden only={["md", "sm"]}>
								<Typography variant="body2">
									{subject.origin
										? subject.origin.toUpperCase()
										: null}
								</Typography>
								<Typography variant="body2">
									{sliceLicensePlateNumber(subject.name, 12)}
								</Typography>
							</Hidden>
						</Grid>
					</Hidden>
					<Hidden mdDown>
						<Grid item xs={hasThumb ? 4 : 7}>
							<LicensePlateMetadata
								parsedMeta={JSON.parse(subject.metadata)}
							/>
						</Grid>
					</Hidden>
					<Hidden lgUp>
						<Grid item xs={hasThumb ? 7 : 9}>
							<LicensePlateMetadata
								parsedMeta={JSON.parse(subject.metadata)}
							/>
						</Grid>
					</Hidden>
				</Grid>
				<Divider />
			</div>
		</Grid>
	);
};

export default LicensePlateItem;
