import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { bg, TRect } from "./RegionsOverlay";
import moment from "moment";
import { TGridSize } from "./AreaDialog";

type Props = {
	gridSize: TGridSize;
	reset: number | undefined;
	undo: number | undefined;
	redo: number | undefined;
	items: TRect[];
	setItems: React.Dispatch<React.SetStateAction<TRect[]>>;
	hidden: boolean;
	history: IAreasHistory<TRect[]>;
	setHistory: React.Dispatch<React.SetStateAction<IAreasHistory<TRect[]>>>;
	canvasSize: { width: number; height: number };
};

export interface IAreasHistory<T> {
	undoList: T[];
	redoList: T[];
}

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "100%",
		zIndex: 1,
	},
	wrapper: {
		border: "1px solid black",
		position: "relative",
		width: "100%",
		height: "100%",
		display: "flex",
	},
	cursorItem: {
		position: "absolute",
		backgroundColor: "black",
		pointerEvents: "none",
		zIndex: 2,
	},
	canvas: {
		width: "100%",
		height: "100%",
	},
}));

const GridOverlay = ({
	gridSize,
	reset,
	undo,
	redo,
	items,
	setItems,
	hidden,
	history,
	setHistory,
	canvasSize,
}: Props) => {
	const classes = useStyles();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const gridRef = useRef<HTMLDivElement>(null);

	const [holdingCTRL, setHoldingCTRL] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

	const drawRect = useCallback(
		(rect: TRect) => {
			const canvas = canvasRef.current;
			if (!canvas) return;

			const ctx = canvas.getContext("2d");
			if (!ctx) return;

			const color =
				rect.currentlyDragging && rect.isInclude
					? "rgb(0, 210, 0)"
					: rect.currentlyDragging && !rect.isInclude
					? "rgb(255, 0, 0)"
					: rect.isInclude
					? bg["green"]
					: bg["red"];

			const x = rect.start.X * canvas.width;
			const y = rect.start.Y * canvas.height;
			const width = (rect.end.X - rect.start.X) * canvas.width;
			const height = (rect.end.Y - rect.start.Y) * canvas.height;
			const scaledCursorX =
				cursorPosition.x / canvas.getBoundingClientRect().width;
			const scaledCursorY =
				cursorPosition.y / canvas.getBoundingClientRect().height;

			const isHovered =
				scaledCursorX > rect.start.X &&
				scaledCursorX < rect.end.X &&
				scaledCursorY > rect.start.Y &&
				scaledCursorY < rect.end.Y;

			ctx.fillStyle = color;
			ctx.globalAlpha = isHovered ? 0.7 : 0.5;
			ctx.fillRect(x, y, width, height);

			ctx.lineWidth = Math.max(1, Math.round(canvas.width / 1000));
			ctx.globalAlpha = 1;
			ctx.strokeRect(x, y, width, height);
		},
		[cursorPosition],
	);

	const drawRects = useCallback(
		(rects: TRect[]) => {
			const canvas = canvasRef.current;
			if (!canvas) return;

			const ctx = canvas.getContext("2d");
			if (!ctx) return;

			canvas.width = canvasSize.width;
			canvas.height = canvasSize.height;

			ctx.clearRect(0, 0, canvas.width, canvas.height);
			rects.forEach(drawRect);
		},
		[drawRect, canvasSize],
	);

	const populate = useCallback(() => {
		const { Rows, Cols } = gridSize;
		if (!Cols || !Rows) return;
		const newItems: TRect[] = Array.from(
			{ length: Cols * Rows },
			(_, i) => {
				return {
					name: "rect",
					isInclude: true,
					timestamp: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
					currentlyDragging: false,
					start: {
						X: (i % Number(gridSize.Cols)) / Number(gridSize.Cols),
						Y:
							Math.floor(i / Number(gridSize.Cols)) /
							Number(gridSize.Rows),
					},
					end: {
						X:
							((i % Number(gridSize.Cols)) + 1) /
							Number(gridSize.Cols),
						Y:
							Math.floor(i / Number(gridSize.Cols) + 1) /
							Number(gridSize.Rows),
					},
				};
			},
		);
		drawRects(newItems);
		setHistory({ undoList: [], redoList: [] });
		setItems(newItems);
	}, [gridSize, setItems, drawRects, setHistory]);

	const handleRedo = useCallback(() => {
		const lastHistory = history.redoList.at(-1);
		if (!lastHistory) return;

		const newHistory = structuredClone(history);
		newHistory.redoList.pop();
		newHistory.undoList.push(items);
		setItems(lastHistory);
		setHistory(newHistory);
	}, [items, history, setItems, setHistory]);

	const handleUndo = useCallback(() => {
		const lastHistory = history.undoList.at(-1);
		if (!lastHistory) return;

		const newHistory = structuredClone(history);
		newHistory.undoList.pop();
		newHistory.redoList.push(items);
		setItems(lastHistory);
		setHistory(newHistory);
	}, [items, history, setItems, setHistory]);

	const addUndoCommand = (items: TRect[]) => {
		setHistory((prev) => ({
			undoList: [...prev.undoList, items],
			redoList: [],
		}));
	};

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const rect = canvas.getBoundingClientRect();
		const clickedX = e.clientX - rect.left;
		const clickedY = e.clientY - rect.top;
		const scaledX = clickedX / rect.width;
		const scaledY = clickedY / rect.height;

		const clickedRectIndex = items.findIndex((r) => {
			return (
				r.start.X < scaledX &&
				r.end.X > scaledX &&
				r.start.Y < scaledY &&
				r.end.Y > scaledY
			);
		});

		addUndoCommand(JSON.parse(JSON.stringify(items)));

		if (clickedRectIndex > -1)
			if (holdingCTRL) {
				const clickedRect = items[clickedRectIndex];
				const newRects = splitRect(clickedRect, scaledX, scaledY);
				setItems((prev) => {
					const newItems = [...prev];
					newItems.splice(clickedRectIndex, 1, ...newRects);
					return newItems;
				});
			} else {
				setItems((prev) => {
					const newItems = [...prev];
					newItems[clickedRectIndex].isInclude =
						!newItems[clickedRectIndex].isInclude;
					return newItems;
				});
			}
	};

	useEffect(() => {
		const undoAction = (e: KeyboardEvent) => {
			if (e.key === "z" && e.ctrlKey) handleUndo();
		};

		const redoAction = (e: KeyboardEvent) => {
			if (e.key === "Z" && e.ctrlKey && e.shiftKey) handleRedo();
		};

		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Control") setHoldingCTRL(true);
		};

		const handleKeyUp = (e: KeyboardEvent) => {
			if (e.key === "Control") setHoldingCTRL(false);
		};

		const onResize = () => drawRects(items);

		window.addEventListener("keydown", handleKeyDown);
		window.addEventListener("keyup", handleKeyUp);
		window.addEventListener("keydown", undoAction);
		window.addEventListener("keydown", redoAction);
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			window.removeEventListener("keyup", handleKeyUp);
			window.removeEventListener("keydown", undoAction);
			window.removeEventListener("keydown", redoAction);
			window.removeEventListener("resize", onResize);
		};
	}, [holdingCTRL, handleUndo, handleRedo, drawRects, items]);

	useEffect(() => {
		if (hidden) return;

		if (items.length === 0) populate();
		else drawRects(items);
	}, [populate, items, drawRects, hidden]);

	useEffect(() => {
		if (reset) populate();
		if (undo) handleUndo();
		if (redo) handleRedo();
	}, [undo, reset, redo, handleUndo, handleRedo, populate]);

	return (
		<div
			style={{ visibility: hidden ? "hidden" : "visible" }}
			className={classes.root}
		>
			<div
				className={classes.wrapper}
				ref={gridRef}
				onClick={handleClick}
				onMouseMove={(e) => {
					setCursorPosition({
						x:
							e.clientX -
							e.currentTarget.getBoundingClientRect().left,
						y:
							e.clientY -
							e.currentTarget.getBoundingClientRect().top,
					});
				}}
				onMouseLeave={() => setCursorPosition({ x: -1, y: -1 })}
			>
				{holdingCTRL && (
					<>
						<span
							className={classes.cursorItem}
							style={{
								width: "100%",
								height: 2,
								top: cursorPosition.y - 2,
								left: 0,
							}}
						/>
						<span
							className={classes.cursorItem}
							style={{
								height: "100%",
								width: 2,
								top: 0,
								left: cursorPosition.x - 2,
							}}
						/>
					</>
				)}

				<canvas ref={canvasRef} className={classes.canvas} />
			</div>
		</div>
	);
};

export default GridOverlay;

const splitRect = (rect: TRect, X: number, Y: number): TRect[] => {
	return [
		{ ...rect, end: { X, Y } },
		{
			...rect,
			start: { X, Y: rect.start.Y },
			end: { X: rect.end.X, Y },
		},
		{
			...rect,
			start: { X, Y },
		},
		{
			...rect,
			start: { X: rect.start.X, Y },
			end: { X, Y: rect.end.Y },
		},
	];
};
