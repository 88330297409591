import { PagedContent, IResponse, IRequest } from "../types";
import AsyncState from "../AsyncState";

export interface ISourceinfo {
	url: string;
	displayName: string;
	user: string;
	password: string;
}

export interface ISource {
	id: string;
	displayName: string;
	url: string | null;
	tasks: ISourceTask[];
	user: string;
	vmsName?: string;
}

export type TaskStatus =
	| "RUNNING"
	| "STOPPED"
	| "STOPPING"
	| "STARTING"
	| "ERROR"
	| "COMPLETED"
	| "WAITING"
	| "SCHEDULED";

export interface ISourceTask {
	taskId: string;
	error: string | null;
	warning: string | null;
	status: TaskStatus;
	engine: string[];
}

export interface IRunSourceTaskQuery {
	engineTypes: string[];
}

export interface ISourceRequestInfo extends IRequest {
	name?: string;
	status?: string | null;
}

export type SourceDataContainer = PagedContent<ISource[]>;

export interface ISourceTaskUpdate {
	vmsName: string;
	sourceName: string;
	task: IResponse;
}

export interface ISourceState extends AsyncState {
	keys: { [key: string]: SourceDataContainer };
	paging: { [key: string]: ISourceRequestInfo };
}

export type EngineState = {
	id: string;
	engines: string[];
	touched: boolean;
};

export const SourceStatuses = [
	"STOPPED",
	"STOPPING",
	"STARTING",
	"RUNNING",
	"ERROR",
	"COMPLETED",
	"WAITING",
];
