import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { IResponse, ISourceResponse } from "../types";
import { ISourceTaskState } from "./types";
import {
	startSourceTask,
	stopSourceTask,
	updateSourceAction,
	SourceAction,
} from "../Sources/action";
import { IRunSourceTaskQuery } from "../Sources/types";
import { delay } from "../../helpers/Utils";

export type SOURCE_TASK = "TASK_UPDATE";

export type ISourceTaskType = IResponse | ISourceResponse;

export interface ISourceTaskAction {
	type: SOURCE_TASK;
	target: keyof ISourceTaskState;
	payload: ISourceTaskType;
}

export async function getMostRecentTask(taskId: string) {
	try {
		const result = await Axios.get<IResponse>(
			API_BASE_PREFIX + `tasks/${taskId}`,
		);
		return result.data;
	} catch (error) {
		throw error;
	}
}

export function stopSourceTaskAction(
	vmsName: string,
	sourceId: string,
	taskId: string = "live",
) {
	return async (
		dispatch: (action: ISourceTaskAction | SourceAction) => void,
	) => {
		const taskResult = await stopSourceTask(vmsName, sourceId, taskId);
		dispatch({
			payload: taskResult,
			target: "source",
			type: "TASK_UPDATE",
		});
		await updateSourceTaskAction(taskResult.correlationId)(dispatch);
		await updateSourceAction(vmsName, sourceId)(dispatch);
	};
}

export function startSourceTaskAction(
	vmsName: string,
	sourceId: string,
	query: IRunSourceTaskQuery,
	taskId: string = "live",
) {
	return async (
		dispatch: (action: ISourceTaskAction | SourceAction) => void,
	) => {
		const taskResult = await startSourceTask(
			vmsName,
			sourceId,
			query,
			taskId,
		);
		dispatch({
			payload: taskResult,
			target: "source",
			type: "TASK_UPDATE",
		});
		await updateSourceTaskAction(taskResult.correlationId)(dispatch);
		await updateSourceAction(vmsName, sourceId)(dispatch);
	};
}

export function updateSourceTaskAction(taskId: string) {
	return async (dispatch: (action: ISourceTaskAction) => void) => {
		for (let i = 0; i < 10; i++) {
			const taskResult = await getMostRecentTask(taskId);
			dispatch({
				payload: taskResult,
				target: "source",
				type: "TASK_UPDATE",
			});
			if (
				taskResult.status === "Complete" ||
				taskResult.status === "BadRequest" ||
				taskResult.status === "Error" ||
				taskResult.status === "NotFound" ||
				taskResult.status === "NotAuthorized"
			) {
				break;
			}
			await delay(1000);
		}
	};
}
