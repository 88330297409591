import React from "react";
import ReactDOM from "react-dom";
import { InputLabel, makeStyles } from "@material-ui/core";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";

const useStyles = makeStyles(() => ({
	root: {
		position: "relative",
	},
	content: {
		padding: "18.5px 14px",
	},
	inputLabel: {
		position: "absolute",
		left: 0,
		top: 0,
		fontSize: 22,
		transform: "translate(0, 24px) scale(1)",
	},
}));

interface ILabelledOutline {
	id: string;
	label: string | React.ReactNode;
	children: React.ReactNode;
}

const LabelledOutline: React.FC<ILabelledOutline> = ({
	id,
	label,
	children,
}) => {
	const [labelWidth, setLabelWidth] = React.useState(0);
	const labelRef = React.useRef(null);
	const classes = useStyles();
	React.useEffect(() => {
		const labelNode = ReactDOM.findDOMNode(labelRef.current) as HTMLElement;
		setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
	}, [label]);

	return (
		<div style={{ position: "relative", marginTop: "8px" }}>
			<InputLabel
				ref={labelRef}
				htmlFor={id}
				variant="outlined"
				className={classes.inputLabel}
				shrink
			>
				{label}
			</InputLabel>
			<div className={classes.root}>
				<div id={id} className={classes.content}>
					{children}
					<NotchedOutline notched labelWidth={labelWidth} />
				</div>
			</div>
		</div>
	);
};
export default LabelledOutline;
