import { Box, Typography, makeStyles, Divider } from "@material-ui/core";
import { THEME } from "../../config";
import { ISubject } from "../../store/Subjects/types";
import { MetadataItem } from "../Events/Event/MatchDetails";
import { IMetadata } from "../../store/Schemas/types";
import { IWatchlist } from "../../store/Watchlists/types";
import useMetadata from "../../hooks/useMetadata";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
		overflow: "hidden",
		padding: "8px 0",
	},
	collapse: {
		overflowY: "auto",
	},
	infoBtn: {
		display: "flex",
		justifyContent: "flex-start",
		pointerEvents: "none",
	},
	title: {
		fontWeight: "bold",
		color: THEME.palette.secondary.main,
	},
}));

type Props = {
	subject?: Omit<ISubject, "metadata"> & { metadata: IMetadata };
	watchlist: IWatchlist;
};

const Information = ({ subject, watchlist }: Props) => {
	const classes = useStyles();
	const metadata = useMetadata({
		metadata: subject?.metadata,
		watchlistId: watchlist?.id,
		secretId: watchlist?.secret,
	});

	return (
		<Box className={classes.root}>
			<Typography variant="h5" className={classes.title}>
				Information
			</Typography>
			<Divider />
			<Box className={classes.collapse}>
				<MetadataItem metadata={metadata} />
			</Box>
		</Box>
	);
};

export default Information;
