import React, { useEffect } from "react";
import {
	Box,
	ListItemText,
	makeStyles,
	Typography,
	Checkbox,
	Button,
	Select,
	MenuItem,
	FormControl,
	Input,
	Tooltip,
	Switch,
} from "@material-ui/core";
import { IconsObj } from ".././constants";
import { THEME } from "../../../config";
import Slider from "../../UI/Slider";

const useStyles = makeStyles(() => ({
	lessBtn: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		padding: "0 8px",
		minWidth: "fit-content",
	},
	confidenceText: {
		justifyContent: "center",
		display: "flex",
		paddingRight: 6,
		minWidth: "34px",
	},
	itemControls: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		width: "100%",
		gap: 4,
		padding: "2px 0",
	},
	textBox: {
		display: "flex",
		gap: "4px",
		alignItems: "center",
	},
	confidenceBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		gap: "8px",
		padding: "0 8px",
	},
	selectBox: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		gap: "12px",
	},
	itemSwitchAndLabel: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	label: {
		padding: "0 8px",
		height: "100%",
	},
	iconsBox: {
		display: "flex",
		flexWrap: "wrap",
		width: "fit-content",
		gap: 8,
		padding: "8px 0",
	},
	colorsBox: {
		display: "flex",
		width: "100%",
		justifyContent: "space-evenly",
	},
	directionsBox: {
		display: "flex",
		width: "100%",
		gap: 2,
	},
}));

export const FilterItem: React.FC<{
	name: string;
	label: string;
	selected:
		| {
				label: string;
				name: string;
				less: boolean;
				confidence: number;
				values: string[];
		  }
		| undefined;
	onChange(
		changed: {
			label: string;
			name: string;
			less: boolean;
			confidence: number;
			values: string[];
		} | null,
	): void;
	switchEl?: React.ReactNode;
	splitSlider?: boolean;
	selectValues?: string[] | undefined;
	icons?: IconsObj;
	onSwitchChange: (item: string) => void;
	noEmpty?: boolean;
}> = ({
	selectValues,
	selected,
	onChange,
	name,
	icons,
	switchEl,
	splitSlider,
	label,
	onSwitchChange,
	noEmpty,
}) => {
	const classes = useStyles();
	const [confidence, setConfidence] = React.useState<number | null>(null);
	const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		if (
			noEmpty &&
			Array.isArray(event.target.value) &&
			event.target.value.length < 1
		)
			return;
		if (!selected) return;
		const changedTr = { ...selected };
		let values = [...(event.target.value as string[])];
		if (selected.values[0] === "Any" && selected.values.length === 1) {
			if (values.length > 0) {
				values.shift();
			} else {
				values = ["Any"];
			}
		} else if (values[values.length - 1] === "Any") {
			values = ["Any"];
		} else if (values.length === 0) {
			values = ["Any"];
		}
		changedTr.values = values;
		onChange(changedTr);
	};

	const handleIconClick = (value: string) => {
		const object = selected ? selected : getDefaultSelectedWithValues(name);
		const values = [...object.values];
		if (values.includes(value)) {
			values.splice(values.indexOf(value), 1);
		} else {
			values.push(value);
		}
		if (
			selectValues &&
			values.length === Object.keys(selectValues).length
		) {
			values.includes("Any")
				? values.splice(values.indexOf("Any"), 1)
				: values.unshift("Any");
		} else if (values.includes("Any")) {
			values.splice(values.indexOf("Any"), 1);
		}

		if (values.length === 0) {
			setConfidence(null);
			onChange(null);
		} else {
			onChange({
				...object,
				values,
			});
		}
	};

	const handleLess = () => {
		if (!selected) return;
		const changedTr = { ...selected };
		changedTr.less = !changedTr.less;
		onChange(changedTr);
	};

	const handleSlider = (_: any, newValue: number | number[]) => {
		if (!selected) return;
		const changedTr = { ...selected };
		changedTr.confidence = newValue as number;
		onChange(changedTr);
	};

	const renderColor = (color: string) => {
		return (
			<Tooltip title={color} key={color}>
				<div
					style={{
						width: 28,
						height: 28,
						cursor: "pointer",
						padding: 3,
						borderRadius: 4,
						backgroundColor: selected?.values.includes(color)
							? THEME.palette.success.light
							: "transparent",
					}}
					onClick={() => handleIconClick(color)}
				>
					<div
						style={{
							width: "100%",
							height: "100%",
							backgroundColor: color,
							borderRadius: 14,
							border: "1px solid black",
						}}
					></div>
				</div>
			</Tooltip>
		);
	};

	const renderIcon = (icon: string) => {
		if (!icons) return;
		return (
			<Tooltip title={icon.replace(/([A-Z])/g, " $1")} key={icon}>
				<img
					src={icons[icon.toUpperCase()]}
					alt={icon}
					style={{
						width: 28,
						height: 28,
						cursor: "pointer",
						padding: 4,
						borderRadius: 4,
						backgroundColor: selected?.values.includes(icon)
							? THEME.palette.success.light
							: "transparent",
					}}
					onClick={() => handleIconClick(icon)}
				/>
			</Tooltip>
		);
	};

	useEffect(() => {
		if (
			noEmpty &&
			Array.isArray(selected?.values) &&
			selected?.values.length === 0
		) {
			onSwitchChange(name);
		}
	}, [selected, name, noEmpty, onSwitchChange]);

	return (
		<Box className={classes.itemControls}>
			<Box className={classes.selectBox}>
				<Box className={classes.itemSwitchAndLabel}>
					{switchEl && (
						<Switch
							onChange={() => {
								setConfidence(null);
								onSwitchChange(name);
							}}
							checked={selected ? true : false}
						/>
					)}
					<Typography className={!switchEl ? classes.label : ""}>
						{label}
					</Typography>
				</Box>
				{selectValues && switchEl ? (
					<FormControl style={{ width: "100%" }}>
						<Tooltip title={selected?.values.join(", ") ?? ""}>
							<Select
								multiple
								value={selected?.values || []}
								onChange={handleSelect}
								input={<Input />}
								MenuProps={{ variant: "menu" }}
								renderValue={(selected) =>
									(selected as string[]).join(", ")
								}
								disabled={selected || !switchEl ? false : true}
							>
								{selectValues.map((item) => (
									<MenuItem key={item} value={item} dense>
										<Checkbox
											checked={selected?.values.includes(
												item,
											)}
										/>
										<ListItemText
											primary={
												<span
													className={classes.textBox}
												>
													{item}
													{name === "colors" && (
														<div
															style={{
																width: 20,
																height: 20,
																backgroundColor:
																	item,
																borderRadius: 10,
																border: "1px solid black",
															}}
														></div>
													)}
													{icons && (
														<img
															src={
																icons[
																	item.toUpperCase()
																]
															}
															alt={item}
															style={{
																width: 15,
																height: 15,
																marginLeft: 5,
															}}
														/>
													)}
												</span>
											}
										/>
									</MenuItem>
								))}
							</Select>
						</Tooltip>
					</FormControl>
				) : !icons ? (
					<Box className={classes.iconsBox}>
						<div className={classes.colorsBox}>
							{selectValues
								?.slice(0, 5)
								.map((color) => renderColor(color))}
						</div>
						<div className={classes.colorsBox}>
							{selectValues
								?.slice(5)
								.map((color) => renderColor(color))}
						</div>
					</Box>
				) : icons && selectValues && name === "directions" ? (
					<Box className={classes.iconsBox}>
						<div className={classes.directionsBox}>
							{renderIcon(selectValues[7])}
							{renderIcon(selectValues[0])}
							{renderIcon(selectValues[1])}
						</div>
						<div
							className={classes.directionsBox}
							style={{ gap: 30 }}
						>
							{renderIcon(selectValues[6])}
							{renderIcon(selectValues[2])}
						</div>
						<div className={classes.directionsBox}>
							{renderIcon(selectValues[5])}
							{renderIcon(selectValues[4])}
							{renderIcon(selectValues[3])}
						</div>
					</Box>
				) : (
					icons &&
					selectValues && (
						<Box className={classes.iconsBox}>
							{selectValues.map((icon) => renderIcon(icon))}
						</Box>
					)
				)}
			</Box>
			<Box className={classes.confidenceBox}>
				<Box style={{ display: "flex", gap: 8 }}>
					<Typography>Confidence</Typography>
					<Button
						onClick={handleLess}
						className={classes.lessBtn}
						disabled={selected ? false : true}
					>
						{selected?.less ? "≤" : "≥"}
					</Button>
				</Box>
				<Typography className={classes.confidenceText}>
					{confidence !== null
						? String(confidence)
						: selected?.confidence ?? ""}
				</Typography>
				<Slider
					value={
						confidence !== null
							? confidence
							: selected?.confidence ?? 50
					}
					splitSlider={Boolean(splitSlider)}
					selected={Boolean(selected)}
					onChange={(_, val) => setConfidence(val as number)}
					onChangeCommitted={handleSlider}
					reverse={selected?.less}
				/>
			</Box>
		</Box>
	);
};

export const getDefaultSelectedWithValues = (
	type: string,
	values?: string[],
) => ({
	label: type,
	name: QUERY_NAMES[type],
	confidence: CONFIDENCE[type],
	less: false,
	values: values ? values : ["Any"],
});

type tConfidence = {
	[key: string]: number;
};
type tQueryNames = {
	[key: string]: string;
};

export const CONFIDENCE: tConfidence = {
	gender: 10,
	beard: 50,
	mask: 50,
	darkGlasses: 50,
	mustache: 50,
	glasses: 50,
	directions: 30,
	objects: 80,
	colors: 50,
	emotions: 50,
	ethnicity: 50,
	ageGroup: 50,
};

const QUERY_NAMES: tQueryNames = {
	directions: "best.vehicleHuman.directionConfidences",
	objects: "best.vehicleHuman.objectTypeConfidences",
	colors: "best.vehicleHuman.colorConfidences",
	gender: "best.faces.attributes.gender",
	beard: "best.faces.attributes.beard",
	mask: "best.faces.attributes.mask",
	darkGlasses: "best.faces.attributes.darkGlasses",
	mustache: "best.faces.attributes.mustache",
	glasses: "best.faces.attributes.glasses",
	ageGroup: "best.vehicleHuman.ageGroupDetails",
};
