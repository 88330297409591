import React from "react";
import {
	makeStyles,
	Grid,
	Button,
	Typography,
	Popover,
	TextField,
	Theme,
} from "@material-ui/core";
import { RuleHttpAction } from "../../../../../store/Rules/types";
import ActionsCheckboxes from "./ActionsCheckboxes";

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		width: "50%",
	},
	popoverContainer: {
		paddingTop: 10,
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	error: {
		paddingLeft: 90,
		color: theme.palette.error.main,
	},
	address: {
		paddingTop: 5,
	},
	popoverRowAddress: {
		marginBottom: 10,
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	addressInput: {
		paddingLeft: 10,
	},
}));

const defaultAction: RuleHttpAction = {
	type: "HttpAction",
	address: "",
	sendWithTrackEvent: true,
	sendWithDisappearedEvent: true,
};

interface IHttpPopoverProps {
	onUpdate(action: RuleHttpAction): void;
	onCancel: () => void;
	httpAction?: RuleHttpAction;
	anchorEl: null | HTMLElement;
}

const HttpPopover: React.FC<IHttpPopoverProps> = ({
	onUpdate,
	onCancel,
	httpAction,
	anchorEl,
}) => {
	const classes = useStyles();

	const [action, setAction] = React.useState<RuleHttpAction>(
		httpAction ? httpAction : defaultAction,
	);
	const [confirmError, setConfirmError] = React.useState<boolean>(false);

	React.useEffect(() => {
		setAction(httpAction ? httpAction : defaultAction);
	}, [httpAction, anchorEl]);

	const handleConfirm = () => {
		if (
			!action.address.startsWith("http://") &&
			!action.address.startsWith("https://")
		) {
			setConfirmError(true);
		} else {
			onUpdate(action);
			handleCancel();
		}
	};

	const handleCancel = () => {
		setConfirmError(false);
		onCancel();
	};

	const handleAddress = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const changedAction = { ...action };
		changedAction.address = event.target.value as string;
		setAction(changedAction);
	};

	const handleTrackChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithTrackEvent = value;
		setAction(changedAction);
	};

	const handleDisappearedChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithDisappearedEvent = value;
		setAction(changedAction);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			className={classes.popover}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item container className={classes.popoverRowAddress}>
					<Grid item>
						<Typography className={classes.address}>
							Address
						</Typography>
					</Grid>
					<Grid item>
						<TextField
							name="address"
							className={classes.addressInput}
							value={action.address}
							placeholder="http://enter-address"
							onChange={handleAddress}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{confirmError ? (
						<Typography className={classes.error}>
							Address must start with "http://"!
						</Typography>
					) : null}
				</Grid>
				<ActionsCheckboxes
					onTrack={handleTrackChange}
					onDisappeared={handleDisappearedChange}
					trackCheck={action.sendWithTrackEvent}
					disCheck={action.sendWithDisappearedEvent}
				/>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								action.address === "" ||
								(!action.sendWithTrackEvent &&
									!action.sendWithDisappearedEvent)
							}
						>
							{httpAction ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default HttpPopover;
