import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { IVMS, IgetVMSRequest, IVMSInfo } from "./types";
import { PagedContent, IResponse } from "../types";
import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { getMostRecentTask } from "../SourceTasks/action";
import { delay } from "../../helpers/Utils";

export const VMS = "VMS";
export type VMSAction = AsyncAction<
	typeof VMS,
	PagedContent<IVMS[]> | IVMS | { vmsName: string; isLost: boolean },
	string
>;

export const getVMS = async (requestParams: IgetVMSRequest) => {
	try {
		const response = await Axios.get<PagedContent<IVMS[]>>(
			API_BASE_PREFIX + "vms",
			{
				params: requestParams,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getVMSInfo = async (vmsName: string) => {
	try {
		const response = await Axios.get(API_BASE_PREFIX + `vms/${vmsName}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const RegisterVMS = async (vms: IVMSInfo) => {
	try {
		const response = await Axios.post<IResponse>(
			API_BASE_PREFIX + `vms/${vms.name}`,
			vms,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const UnregisterVMS = async (vmsName: string) => {
	try {
		const response = await Axios.delete<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const EditVMS = async (vms: IVMSInfo) => {
	try {
		const response = await Axios.put<IResponse>(
			API_BASE_PREFIX + `vms/${vms.name}`,
			vms,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getVMSAction(requestParams: IgetVMSRequest) {
	return async(VMS, getVMS, "", requestParams);
}

export function getVMSInfoAction(vmsName: string) {
	return async(VMS, getVMSInfo, vmsName, vmsName);
}

export function editVMSAction(vms: IVMSInfo) {
	return async(VMS, EditVMS, vms.name, vms);
}

export function addVMSAction(vms: IVMS) {
	return {
		type: VMS,
		meta: vms.name,
		payload: vms,
		status: AsyncActionStatus.SUCCEEDED,
	};
}

export function removeVMSAction(vmsName: string) {
	return {
		type: VMS,
		meta: "Remove",
		payload: vmsName,
		status: AsyncActionStatus.SUCCEEDED,
	};
}

export function setVMSConnectionIsLostAction(vmsName: string, isLost: boolean) {
	return {
		type: VMS,
		meta: "SetConnectionLost",
		payload: { vmsName, isLost },
		status: AsyncActionStatus.SUCCEEDED,
	};
}

export const GetTaskStatus = async (taskId: string) => {
	for (let i = 0; i < 10; i++) {
		const task = await getMostRecentTask(taskId);
		if (
			task.status === "Complete" ||
			task.status === "BadRequest" ||
			task.status === "Error" ||
			task.status === "NotFound" ||
			task.status === "NotAuthorized"
		) {
			return task;
		}
		await delay(1000);
	}
};
