import { ISource, SourceStatuses } from "../store/Sources/types";
import { IResponse } from "../store/types";
import { GetTaskStatus } from "../store/VMS/action";

export const handleTaskAction = async (
	action: (...args: any[]) => Promise<IResponse>,
	completionCallback: () => void,
	errorCallback: (msg: string) => void,
	...args: any[]
) => {
	try {
		const response = await action(...args);
		const finalTask = await GetTaskStatus(response.correlationId);
		if (finalTask && finalTask.status === "Complete") {
			completionCallback();
		} else {
			throw new Error(
				finalTask ? finalTask.message : "Please check results later.",
			);
		}
	} catch (e) {
		errorCallback(e ? (e as Error).message : "Server error.");
	}
};

export const getVMSTasks = (sources: ISource[]) => {
	const tasksStatus = SourceStatuses.reduce((acc, status) => {
		acc[status] = 0;
		return acc;
	}, {} as { [key: string]: number });

	sources.forEach((source) => {
		const status = source.tasks[source.tasks.length - 1]?.status;
		if (status) {
			tasksStatus[status] = (tasksStatus[status] || 0) + 1;
		}
	});

	return tasksStatus;
};
