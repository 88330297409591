import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import AreaDialogTitle from "./AreaDialogTitle";
import AreaDialogContent from "./AreaDialogContent";
import { THEME } from "../../../config";
import { ISource } from "../../../store/Sources/types";
import { TPolygon, TRect } from "./RegionsOverlay";
import {
	changeConfiguration,
	getChangedPropertiesAction,
} from "../../../store/Properties/action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { handleTaskAction } from "../../../helpers/VMSCrud";
import moment from "moment";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	source: ISource;
	isConfigLoading: boolean;
	setNotification: React.Dispatch<
		React.SetStateAction<{ message: string; variant: "success" | "error" }>
	>;
};

export enum State {
	grid = 1,
	incRect = 2,
	excRect = 3,
	incPoly = 4,
	excPoly = 5,
}

export type TApiArea = {
	IsPolygon: boolean;
	IsInclude: boolean;
	Points: { X: number; Y: number }[];
};

export type TGridSize = Record<"Rows" | "Cols", number>;
export const initialGridSize = {
	Rows: 6,
	Cols: 8,
};

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: "100%",
		width: "100%",
		height: "100%",
		backgroundColor: THEME.palette.background.default,
		userSelect: "none",
	},
}));

const AreaDialog = ({
	source,
	isOpen,
	onClose,
	isConfigLoading,
	setNotification,
}: Props) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const savedProperties = useSelector(
		(state: AppState) =>
			state.properties.changedProperties[source.vmsName ?? ""]
				?.filter((p) => p.scope?.sourceTarget === source.id)
				?.find((p) => p.scope?.sourceTarget === source.id)?.values,
	);

	const [gridSize, setGridSize] = useState<TGridSize>(initialGridSize);
	const [state, setState] = useState(State.grid);
	const [searchByCenter, setSearchByCenter] = useState(true);
	const [gridItems, setGridItems] = useState<TRect[]>([]);
	const [rects, setRects] = useState<TRect[]>([]);
	const [polygons, setPolygons] = useState<TPolygon[]>([]);

	const [isSaving, setIsSaving] = useState(false);

	const isGridSizeValid = (gridSize: TGridSize) => {
		let message = "";
		if (
			gridSize.Cols < 1 ||
			String(gridSize.Cols).length > 2 ||
			!/^[0-9][0-9]*$/.test(String(gridSize.Cols))
		) {
			message = "Invalid number of columns";
		} else if (
			gridSize.Rows < 1 ||
			String(gridSize.Rows).length > 2 ||
			!/^[0-9][0-9]*$/.test(String(gridSize.Rows))
		) {
			message = "Invalid number of rows";
		}
		if (message) {
			setNotification({ message, variant: "error" });
			setIsSaving(false);
		}
		return !message;
	};

	const onConfirm = async () => {
		setIsSaving(true);
		if (!isGridSizeValid(gridSize)) return;

		const currentRects = state === State.grid ? gridItems : rects;
		const currentPolygons = polygons.filter((p) => !p.currentlyDragging);
		const allFigures = [...currentRects, ...currentPolygons].sort((a, b) =>
			moment(a.timestamp).diff(b.timestamp),
		);

		setPolygons(currentPolygons);
		const areas = [
			...(state !== State.grid
				? allFigures.map((f) => {
						if (f.name === "polygon")
							return {
								isPolygon: true,
								isInclude: f.isInclude,
								points: f.points.map((point) => ({
									x: Number(point.X.toFixed(6)),
									y: Number(point.Y.toFixed(6)),
								})),
							};
						else {
							const start = {
								x: Math.min(f.start.X, f.end.X),
								y: Math.min(f.start.Y, f.end.Y),
							};
							const end = {
								x: Math.max(f.start.X, f.end.X),
								y: Math.max(f.start.Y, f.end.Y),
							};
							return {
								isPolygon: false,
								isInclude: f.isInclude,
								points: [
									{
										x: Number(start.x.toFixed(6)),
										y: Number(start.y.toFixed(6)),
									},
									{
										x: Number(end.x.toFixed(6)),
										y: Number(end.y.toFixed(6)),
									},
								],
							};
						}
				  })
				: currentRects.map((r) => {
						const start = {
							x: Math.min(r.start.X, r.end.X),
							y: Math.min(r.start.Y, r.end.Y),
						};
						const end = {
							x: Math.max(r.start.X, r.end.X),
							y: Math.max(r.start.Y, r.end.Y),
						};
						return {
							isPolygon: false,
							isInclude: r.isInclude,
							points: [
								{
									x: Number(start.x.toFixed(6)),
									y: Number(start.y.toFixed(6)),
								},
								{
									x: Number(end.x.toFixed(6)),
									y: Number(end.y.toFixed(6)),
								},
							],
						};
				  })),
		];

		const onComplete = () => {
			const vmsName = source.vmsName;
			if (vmsName) getChangedPropertiesAction(vmsName).then(dispatch);
			setNotification({
				message: "Saved successfully",
				variant: "success",
			});
			setIsSaving(false);
			onClose();
		};

		const onError = (msg: string) => {
			setNotification({
				message: msg,
				variant: "error",
			});
			setIsSaving(false);
		};

		await handleTaskAction(changeConfiguration, onComplete, onError, [
			{
				service: "Stream Processor",
				scope: {
					vmsTarget: source.vmsName ?? "",
					sourceTarget: source.id,
				},
				values: [
					{
						key: "SearchArea",
						value: JSON.stringify({
							columns:
								state === State.grid ? gridSize.Cols || 0 : 0,
							rows: state === State.grid ? gridSize.Rows || 0 : 0,
							searchAreas: areas,
						}),
					},
					{
						key: "SearchByCenter",
						value: searchByCenter ? "True" : "False",
					},
				],
			},
		]);
	};

	useEffect(() => {
		const isSearchByCenter = savedProperties?.find(
			(p) => p.key === "SearchByCenter",
		)?.value;

		setSearchByCenter(
			isSearchByCenter === "True" || isSearchByCenter === undefined,
		);
		const props = JSON.parse(
			savedProperties?.find((p) => p.key === "SearchArea")?.value ?? "{}",
		);

		if (Object.keys(props).length === 0) return;

		const hasColumns = Boolean(props.Columns || undefined);
		const hasRows = Boolean(props.Rows || undefined);
		const isGrid = props ? hasColumns && hasRows : true;
		setState((prev) => {
			if (prev === State.grid) return isGrid ? State.grid : State.incRect;
			else return prev;
		});
		if (isGrid && hasColumns && hasRows)
			setGridSize({
				Rows: Number(props.Rows),
				Cols: Number(props.Columns),
			});
		const areas: TApiArea[] | undefined = props.SearchAreas;
		if (areas) {
			const savedRects = areas
				.filter((a) => a.IsPolygon === false)
				.map((a) => ({
					isInclude: a.IsInclude,
					start: {
						X: a.Points[0].X,
						Y: a.Points[0].Y,
					},
					end: {
						X: a.Points[1].X,
						Y: a.Points[1].Y,
					},
					name: "rect",
					timestamp: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
				})) as TRect[];
			if (isGrid) setGridItems(savedRects);
			else {
				setRects(savedRects);
				setPolygons(
					areas
						.filter((a) => a.IsPolygon === true)
						.map((a) => ({
							isInclude: a.IsInclude,
							points: a.Points,
							name: "polygon",
							timestamp: moment().format(
								"YYYY-MM-DD HH:mm:ss:SSS",
							),
						})),
				);
			}
		}
	}, [savedProperties]);

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			classes={{
				paper: classes.root,
			}}
			disableEscapeKeyDown
		>
			<AreaDialogTitle state={state} />
			<AreaDialogContent
				state={state}
				setState={setState}
				source={source}
				searchByCenter={searchByCenter}
				setSearchByCenter={setSearchByCenter}
				items={gridItems}
				setItems={setGridItems}
				rects={rects}
				setRects={setRects}
				polygons={polygons}
				setPolygons={setPolygons}
				isConfigLoading={isConfigLoading}
				gridSize={gridSize}
				setGridSize={setGridSize}
			/>
			<DialogActions>
				<Button variant="text" onClick={onConfirm} disabled={isSaving}>
					{isSaving ? <CircularProgress size={24} /> : "Save"}
				</Button>
				<Button onClick={onClose} variant="text">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AreaDialog;
