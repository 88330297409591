import React from "react";
import {
	VpnKey,
	Videocam,
	Camera,
	Assignment,
	Lock,
	RemoveRedEye,
	List,
	Settings,
	ExitToApp,
	Dashboard,
	ViewQuilt,
	Search,
	PieChart,
	YoutubeSearchedForRounded,
} from "@material-ui/icons";

export interface IMenuItem {
	icon?: React.ReactNode;
	name: string;
	subItems?: IMenuItem[];
	route?: string;
	abstract?: boolean;
	isPrivate?: boolean;
	permissions: string[];
}

export const UsersItem: IMenuItem = {
	icon: <VpnKey />,
	name: "Users",
	route: "/users",
	permissions: ["ROLE_ADMINISTRATOR"],
};

export const VMSItem: IMenuItem = {
	icon: <Videocam />,
	name: "VMS",
	route: "/vms",
	permissions: [
		"ROLE_ADMINISTRATOR",
		"ROLE_VMS_OPERATOR",
		"ROLE_RULE_OPERATOR",
		"ROLE_ANALYST",
	],
};

const EventsList: IMenuItem = {
	icon: <Camera />,
	name: "Events",
	route: "/events",
	permissions: [],
};

const BackwardSearch: IMenuItem = {
	icon: <YoutubeSearchedForRounded />,
	name: "Backward Search",
	route: "/backwardSearch",
	permissions: [],
};

const LiveEvents: IMenuItem = {
	icon: <ViewQuilt />,
	name: "Live",
	route: "/live",
	permissions: [],
};

const TasksItem: IMenuItem = {
	icon: <Assignment />,
	name: "Tasks",
	route: "/tasks",
	permissions: [],
};

export const SecretsItem: IMenuItem = {
	icon: <Lock />,
	name: "Secrets",
	route: "/secrets",
	permissions: ["ROLE_ADMINISTRATOR", "ROLE_SECRETS_OPERATOR"],
};

export const WatchlistItem: IMenuItem = {
	icon: <RemoveRedEye />,
	name: "Watchlists",
	route: "/watchlists",
	permissions: [
		"ROLE_WATCHLIST_OPERATOR",
		"ROLE_WATCHLIST_ADMINISTRATOR",
		"ROLE_ANALYST",
		"ROLE_ADMINISTRATOR",
	],
	subItems: [
		{
			icon: <Dashboard />,
			name: "Management",
			route: "/watchlists",
			permissions: [],
		},
		{
			icon: <Search />,
			name: "Image Search",
			route: "/watchlists/search",
			permissions: [],
		},
	],
};

export const LogsItem: IMenuItem = {
	icon: <List />,
	name: "Logs",
	route: "/logs",
	permissions: ["ROLE_ADMINISTRATOR", "ROLE_VMS_OPERATOR"],
};

export const ConfigurationItem: IMenuItem = {
	icon: <Settings />,
	name: "Configuration",
	route: "/config",
	permissions: ["ROLE_ADMINISTRATOR", "ROLE_VMS_OPERATOR"],
};

export const LogoutItem: IMenuItem = {
	icon: <ExitToApp />,
	name: "Logout",
	route: "/logout",
	permissions: [],
};

export const ReportsItem: IMenuItem = {
	name: "Reports",
	permissions: ["ROLE_ANALYST", "ROLE_ADMINISTRATOR"],
	route: "/reports",
	icon: <PieChart />,
};

const MenuItems: IMenuItem[] = [
	VMSItem,
	EventsList,
	LiveEvents,
	BackwardSearch,
	WatchlistItem,
	ReportsItem,
	SecretsItem,
	ConfigurationItem,
	TasksItem,
	UsersItem,
	LogsItem,
	LogoutItem,
];

export default MenuItems;
