import {
	Box,
	Checkbox,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import React from "react";
import { THEME } from "../../config";
import { SearchOutlined, ClearOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
	root: {
		width: "100%",
		height: "100%",
		borderRadius: 5,
		display: "flex",
		flexDirection: "column",
	},
	list: {
		overflowY: "auto",
	},
	header: {
		borderBottom: "1px solid #ccc",
	},
	checkbox: {
		width: "100%",
		padding: "4px",
		margin: 0,
	},
	headerLabel: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "4px",
		justifyContent: "center",
	},
	title: {
		lineHeight: "1.3",
	},
	subTitle: {
		lineHeight: "1.3",
		color: THEME.palette.grey[600],
	},
	inputIcon: {
		margin: "0 4px 2px 0",
		cursor: "pointer",
		transition: "all 0.1s ease-in-out",
		"&:hover": {
			color: THEME.palette.primary.light,
		},
	},
}));

type Props = {
	countries: string[];
	checked: string[];
	handleToogle: (country: string) => void;
	handleToogleAll: () => void;
	draggable?: boolean;
	sortCountries?: (countries: string[]) => void;
	handleSearch?: (value: string) => void;
	searchValue?: string;
	listName: string;
	totalLength: number;
};

const List = ({
	countries,
	handleToogle,
	handleToogleAll,
	draggable,
	sortCountries,
	handleSearch,
	searchValue,
	checked,
	totalLength,
	listName,
}: Props) => {
	const classes = useStyles();

	const handleDrop = (result: DropResult) => {
		if (!result.destination || !sortCountries) return;
		const items = Array.from(countries);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		sortCountries(items);
	};

	return (
		<Paper className={classes.root}>
			<Box className={classes.header}>
				{handleSearch && (
					<TextField
						variant="standard"
						size="small"
						placeholder="Search"
						color="secondary"
						fullWidth
						onChange={(e) => handleSearch(e.target.value)}
						value={searchValue}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchOutlined
										color="primary"
										style={{ margin: "0px 0px 2px 4px" }}
									/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									{searchValue && (
										<ClearOutlined
											onClick={() => handleSearch("")}
											color="primary"
											className={classes.inputIcon}
										/>
									)}
								</InputAdornment>
							),
						}}
						style={{ marginTop: 4 }}
					/>
				)}
				<FormControlLabel
					className={classes.checkbox}
					onChange={handleToogleAll}
					control={
						<Checkbox
							checked={
								checked.length === totalLength &&
								totalLength > 0
							}
							disabled={totalLength === 0}
						/>
					}
					label={
						<Box className={classes.headerLabel}>
							<Typography
								variant="body2"
								className={classes.title}
							>
								{listName}
							</Typography>
							<Typography
								variant="caption"
								className={classes.subTitle}
							>
								{checked.length}/{totalLength} selected
							</Typography>
						</Box>
					}
				/>
			</Box>
			<Box className={classes.list}>
				{draggable ? (
					<DragDropContext onDragEnd={handleDrop}>
						<Droppable droppableId="list-container">
							{(provided) => (
								<div
									className="list-container"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{countries.map((country, index) => {
										return (
											<Draggable
												key={`${country}-${index}`}
												draggableId={country}
												index={index}
											>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
													>
														<div
															{...provided.dragHandleProps}
														>
															<FormControlLabel
																className={
																	classes.checkbox
																}
																style={{
																	cursor: "grab",
																}}
																key={country}
																onChange={() =>
																	handleToogle(
																		country,
																	)
																}
																control={
																	<Checkbox
																		checked={checked.includes(
																			country,
																		)}
																	/>
																}
																label={country}
															/>
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				) : (
					<>
						{countries.map((country) => (
							<FormControlLabel
								className={classes.checkbox}
								key={country}
								onChange={() => handleToogle(country)}
								control={
									<Checkbox
										checked={checked.includes(country)}
									/>
								}
								label={country}
							/>
						))}
					</>
				)}
			</Box>
		</Paper>
	);
};

export default List;
