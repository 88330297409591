import React, {
	SyntheticEvent,
	useEffect,
	useState,
	useRef,
	useCallback,
} from "react";
import {
	IVMSDataContainer,
	IgetVMSRequest,
	IVMSInfo,
	IVMSState,
} from "../../store/VMS/types";
import { Grid, Button, Snackbar } from "@material-ui/core";
import SearchHeader from "../../components/SearchHeader/SearchHeader";
import VMS from "../../components/VMS/VMS";
import VMSDialog from "../../components/Dialogs/VMSDialog";
import {
	addVMSAction,
	RegisterVMS,
	removeVMSAction,
} from "../../store/VMS/action";
import { HttpError } from "../../config/types";
import Alert, { IAlert } from "../../components/UI/Alert";
import { renameTypesForQuery } from "../../components/Sources/SourceItem";
import { checkPermissions } from "../Layout/Layout";
import { getVMSTasks, handleTaskAction } from "../../helpers/VMSCrud";
import { handleOpen, vmsSocketUrl } from "../../services/wsConnection";
import Authentication from "../../store/Authentication/AuthenticationStore";
import { AppState } from "../../store";
import { ISourceState } from "../../store/Sources/types";
import { useSelector, useDispatch } from "react-redux";
import { updateSourceAction } from "../../store/Sources/action";

export interface IVMSViewPropsToDispatch {
	loadVMS: (request: IgetVMSRequest) => void;
}

export interface IVMSViewPropsToState {
	vms: IVMSDataContainer;
	error?: HttpError;
	rulesError?: HttpError;
}

type IVMSViewProps = IVMSViewPropsToDispatch & IVMSViewPropsToState;

const VMSView: React.FC<IVMSViewProps> = ({
	vms,
	loadVMS,
	error,
	rulesError,
}) => {
	const ws = useRef<WebSocket | null>(null);

	const isAuthenticated = Authentication.isAuthenticated();
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [filter, setFilter] = useState({
		currentPage: 0,
		itemsPerPage: 10,
		like: "",
	});
	const [alert, setAlert] = useState<IAlert | undefined>(undefined);
	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [filteredVMS, setFilteredVMS] = useState<IVMSDataContainer>(vms);

	const allSources = useSelector<AppState, ISourceState>(
		(state) => state.sources,
	);
	const allVMS = useSelector<AppState, IVMSState>((state) => state.vms);

	const areSourcesAuthorized = checkPermissions([
		"ROLE_ADMINISTRATOR",
		"ROLE_VMS_OPERATOR",
	]);

	const areRulesAuthorized = checkPermissions([
		"ROLE_ADMINISTRATOR",
		"ROLE_RULE_OPERATOR",
	]);

	const handleSocketMessage = useCallback(
		(e: MessageEvent) => {
			const msg = JSON.parse(e.data);

			if (msg.messageType === "VmsMessage") {
				if (msg.Type === "Remove") {
					return dispatch(removeVMSAction(msg.VmsId));
				}
				const vmsSources = allSources.keys[msg.Vms.name]?.content;
				if (vmsSources) {
					msg.Vms.tasksSources = getVMSTasks(vmsSources);
				}
				dispatch(addVMSAction(msg.Vms));
			}

			if (msg.messageType === "SourcesMessage") {
				dispatch(updateSourceAction(msg.VmsId, msg.VmsId, msg.Sources));
				for (let i = 0; i < msg.Sources.length; i++) {
					const src = msg.Sources[i];
					dispatch({
						type: "Load",
						engines: src.tasks[src.tasks.length - 1]?.engine || [],
						id: msg.Sources[i].id,
					});
				}

				if (msg.Sources) {
					const tasksStatus = getVMSTasks(msg.Sources);
					const newVMS = {
						...allVMS.keys[msg.VmsId],
						tasksStatus,
					};
					dispatch(addVMSAction(newVMS));
				}
			}
			if (msg.messageType === "SourceMessage") {
				const src = msg.Source;
				if (msg.Type === "Remove") {
					dispatch(
						updateSourceAction(
							msg.VmsId,
							msg.SourceId,
							msg.Source,
							"Remove",
						),
					);
					return;
				}
				dispatch(updateSourceAction(msg.VmsId, msg.SourceId, src));
				dispatch({
					type: "Load",
					engines: src.tasks[src.tasks.length - 1]?.engine || [],
					id: src.id,
				});

				const sources = allSources.keys[msg.VmsId]?.content;
				if (sources) {
					const tasksStatus = getVMSTasks(sources);
					const newVMS = {
						...allVMS.keys[msg.VmsId],
						tasksStatus,
					};
					dispatch(addVMSAction(newVMS));
				}
			}
		},
		[allSources.keys, allVMS.keys, dispatch],
	);

	useEffect(() => {
		if (Authentication.isAuthenticated()) {
			ws.current = new WebSocket(vmsSocketUrl);
			const socket = ws.current;
			if (socket) {
				socket.onopen = () => handleOpen(socket);
				socket.onmessage = handleSocketMessage;
			}
		}
		return () => {
			if (ws.current) {
				ws.current.close();
			}
		};
	}, [isAuthenticated, handleSocketMessage]);

	useEffect(() => {
		loadVMS({
			like: "",
			page: 0,
			size: 500,
		});
	}, [loadVMS]);

	useEffect(() => {
		if (vms && vms.content && vms.content.length > 0) {
			const filteredByLike = vms.content.filter((vms) =>
				filter.like
					? vms.name
							.toLowerCase()
							.includes(filter.like.toLowerCase() ?? "")
					: true,
			);
			const filteredByPage = filteredByLike.slice(
				filter.currentPage * filter.itemsPerPage,
				(filter.currentPage + 1) * filter.itemsPerPage,
			);

			setFilteredVMS({
				...vms,
				totalElements: filteredByLike.length,
				content: filteredByPage,
			});
		}
	}, [vms, filter, vms.content]);

	const onSearchUpdate = (value: string) => {
		setFilter((prev) => ({ ...prev, like: value }));
		if (value) {
			loadVMS({
				like: filter.like,
				page: 0,
				size: 500,
			});
		}
	};

	const closeModal = () => setIsModalOpen(false);

	const openModal = () => setIsModalOpen(true);

	const handleOpenAlert = React.useCallback(
		(alert: IAlert) => {
			setAlert(alert);
			if (!openSnackbar) setOpenSnackbar(true);
		},
		[openSnackbar],
	);

	const handleSubmitSuccess = () => {
		handleOpenAlert({
			message: "Successfully registered VMS.",
			variant: "success",
		});
		closeModal();
		setLoading(false);
	};

	const handleSubmitError = (msg: string) => {
		handleOpenAlert({
			message: "Failed to register VMS: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const submitVMS = async (info: IVMSInfo) => {
		const nInfo = { ...info };
		nInfo.defaultEngineType = renameTypesForQuery(info.defaultEngineType);
		await handleTaskAction(
			RegisterVMS,
			handleSubmitSuccess,
			handleSubmitError,
			nInfo,
		);
	};

	const handleChangePage = (_: unknown, newPage: number) => {
		if (
			vms.content.length < vms.numberOfElements * vms.totalPages &&
			newPage ===
				Math.ceil(filteredVMS.content.length / filter.itemsPerPage)
		) {
			loadVMS({
				like: "",
				page: Math.ceil(vms.content.length / vms.size),
				size: 500,
			});
		}
		setFilter({ ...filter, currentPage: newPage });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFilter({
			...filter,
			currentPage: 0,
			itemsPerPage: parseInt(event.target.value, 10),
		});
	};

	const handleCloseSnackBar = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={error !== undefined || rulesError !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error
								? error.message
								: rulesError
								? rulesError.message
								: "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<Grid
				container
				spacing={2}
				alignItems="stretch"
				style={{ marginTop: 2 }}
			>
				<Grid item xs={12} md={6}>
					<SearchHeader
						onSubmit={onSearchUpdate}
						totalItems={filteredVMS.totalElements}
						currentPage={filter.currentPage}
						itemsPerPage={filter.itemsPerPage}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
					{areSourcesAuthorized ? (
						<Button onClick={openModal}>Register</Button>
					) : null}
				</Grid>
				<Grid item xs={12} />

				{filteredVMS.content.length > 0 ? (
					filteredVMS.content.map((vm) => (
						<Grid item xs={12} md={12} key={vm.name}>
							<VMS
								name={vm.name}
								globalFilter={filter}
								onAlert={handleOpenAlert}
								areSourcesAuthorized={areSourcesAuthorized}
								areRulesAuthorized={areRulesAuthorized}
							/>
						</Grid>
					))
				) : (
					<Grid item xs={12}>
						<h3
							style={{
								color: "rgba(0, 0, 0, 0.54)",
								textAlign: "center",
							}}
						>
							No VMS found
						</h3>
					</Grid>
				)}
			</Grid>
			<VMSDialog
				open={isModalOpen}
				close={closeModal}
				onSubmit={submitVMS}
				loading={loading}
				onLoading={() => setLoading(true)}
			/>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackBar}
			>
				<div>
					<Alert
						onClose={handleCloseSnackBar}
						alert={alert as IAlert}
					/>
				</div>
			</Snackbar>
		</div>
	);
};

export default VMSView;
