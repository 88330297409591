import React from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { IRecord } from "../../store/Records/types";
import { IWatchlist } from "../../store/Watchlists/types";
import { getRecord } from "../../store/Records/action";
import CenteredLoading from "../UI/CenteredLoading";
import Title from "./Common/Title";

const useStyles = makeStyles(() => ({
	responsivePhoto: {
		objectFit: "contain",
		width: "100%",
		height: "100%",
	},
}));

interface IViewRecordDialogProps {
	open: boolean;
	onClose(): void;
	watchlist: IWatchlist;
	subjectId: string;
	index: number;
	onLoadError(message: string): void;
}

const ViewRecordDialog: React.FC<IViewRecordDialogProps> = ({
	open,
	onClose,
	watchlist,
	subjectId,
	index,
	onLoadError,
}) => {
	const [record, setRecord] = React.useState<IRecord>();

	const classes = useStyles();

	React.useEffect(() => {
		const loadRecord = async (
			id: string,
			sId: string,
			i: number,
			s?: string,
		) => {
			try {
				setRecord(await getRecord(id, sId, i, { secret: s }));
			} catch (err) {
				onLoadError((err as Error).message);
			}
		};
		loadRecord(watchlist.id, subjectId, index, watchlist.secret);
	}, [open, index, subjectId, watchlist, onLoadError]);

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog fullWidth open={open} onClose={handleClose} maxWidth="md">
			{record ? (
				<>
					<Title
						onClose={handleClose}
						title={`${watchlist.id} - ${subjectId} Image #${record.index}`}
					/>
					<DialogContent>
						<img
							className={classes.responsivePhoto}
							alt="Record view"
							src={`data:image/jpeg;base64,${record.image}`}
						/>
					</DialogContent>
				</>
			) : (
				<DialogContent>
					<CenteredLoading />
				</DialogContent>
			)}
		</Dialog>
	);
};

export default ViewRecordDialog;
