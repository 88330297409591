import { ISecretsState, ISecret } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { SecretsAction, Secrets } from "./action";
import { PagedContent } from "../types";

export const initialSecretsState: ISecretsState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	requestStatus: {},
};

export const secretsReducer: Reducer<ISecretsState, any> = (
	state = initialSecretsState,
	action: SecretsAction,
) => {
	if (action.type !== Secrets) {
		return state;
	}
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				const payload = action.payload as ISecret;
				nState.keys[action.meta] = payload;
			} else {
				const payload = action.payload as PagedContent<ISecret[]>;
				payload.content.forEach((secret) => {
					nState.keys[secret.id] = secret;
				});
			}
			return nState;
	}
	return state;
};
