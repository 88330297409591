import React from "react";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableBody,
} from "@material-ui/core";

export type Order = "asc" | "desc";

export interface IColumn {
	key: string;
	name: string;
	sortable?: boolean;
}

interface IEnhancedTableProps {
	columns: IColumn[];
	onChange?(column: IColumn | null, order: Order): void;
	children?: React.ReactNode;
}

interface IEnhancedTableState {
	activeColumn: IColumn;
	order: Order;
}

class EnhancedTable extends React.Component<
	IEnhancedTableProps,
	IEnhancedTableState
> {
	constructor(props: IEnhancedTableProps) {
		super(props);
		const activeColumn = props.columns[0];
		this.state = {
			activeColumn,
			order: "asc" as Order,
		};
	}

	handleSortEvent = (column: IColumn) => () => {
		if (!column.sortable) {
			return;
		}
		if (this.state.activeColumn.key === column.key) {
			let order: Order = "asc";
			if (this.state.order === "asc") {
				order = "desc";
			}
			this.setState({
				order,
				activeColumn: column,
			});
		} else {
			const order: Order = "asc";
			this.setState({ order, activeColumn: column });
		}

		this.setState((state, props) => {
			if (props.onChange) {
				props.onChange(state.activeColumn, state.order);
			}
		});
	};

	render() {
		const entries = this.props.columns;
		const { activeColumn, order } = this.state;
		return (
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{entries.map((column) => (
							<TableCell
								key={column.key}
								onClick={this.handleSortEvent(column)}
							>
								{" "}
								{column.sortable ? (
									<TableSortLabel
										active={activeColumn.key === column.key}
										direction={
											activeColumn.key === column.key
												? order
												: "asc"
										}
									>
										{column.name}
									</TableSortLabel>
								) : (
									<div>{column.name}</div>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>{this.props.children}</TableBody>
			</Table>
		);
	}
}

export default EnhancedTable;
