import { IElasticLogs, ILogsState } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { LogsAction } from "./action";

export const initialLogsState: ILogsState = {
	data: null,
	isAuthenticated: false,
	authorization: "",
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleAsync = (
	state: ILogsState = initialLogsState,
	action: LogsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			nState.data = action.payload as IElasticLogs;
			return nState;
		}
	}
	return state;
};

const handleLogin = (
	state: ILogsState = initialLogsState,
	action: LogsAction,
) => {
	if (action.type === "LOGS_LOGIN") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			nState.authorization = action.payload as string;
			nState.isAuthenticated = true;
			return nState;
		}
	}
	return state;
};

const handleLogout = (
	state: ILogsState = initialLogsState,
	action: LogsAction,
) => {
	if (action.type === "LOGS_LOGOUT") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			nState.data = null;
			nState.isAuthenticated = action.payload as boolean;
			nState.authorization = "";
			nState.erorr = undefined;
			return nState;
		}
	}
	return state;
};

const handleScroll = (
	state: ILogsState = initialLogsState,
	action: LogsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			const nLogs = action.payload as IElasticLogs;
			if (nState.data) {
				nState.data.hits.hits = nState.data.hits.hits.concat(
					nLogs.hits.hits,
				);
				if (nState.data.hits.max_score < nLogs.hits.max_score) {
					nState.data.hits.max_score = nLogs.hits.max_score;
				}
				nState.data.hits.total.value += nLogs.hits.total.value;
				nState.data.timed_out = nLogs.timed_out;
				nState.data.took += nLogs.took;
				nState.data._shards.total += nLogs._shards.total;
				nState.data._shards.successful += nLogs._shards.successful;
				nState.data._shards.skipped += nLogs._shards.skipped;
				nState.data._shards.failed += nLogs._shards.failed;
				return nState;
			}
		}
	}
	return state;
};

export const LogsReducer: Reducer<ILogsState, LogsAction> = (
	state = initialLogsState,
	action: LogsAction,
) => {
	switch (action.type) {
		case "LOGS":
			return handleAsync(state, action);
		case "LOGS_LOGOUT":
			return handleLogout(state, action);
		case "LOGS_LOGIN":
			return handleLogin(state, action);
		case "LOGS_SCROLL":
			return handleScroll(state, action);
		default:
			return state;
	}
};
