import { IDropdownGroup } from "../types";
import AsyncState from "../AsyncState";
import { PagedContent, IRequest } from "../types";

export interface ITask {
	action: string;
	correlationId: string;
	status: string;
	error: string;
	message: string;
	rule: string;
	secret: string;
	sourceId: string;
	subject: string;
	timestamp: string;
	user: string;
	vms: string;
	watchlist: string;
}

export interface IgetTasksRequest extends IRequest {
	secret?: string;
	sortBy?: keyof ITask;
	asc?: boolean;
	action?: string;
	status?: string;
	fromTime?: string;
	toTime?: string;
	vms?: string;
	phrase?: string;
}

export type ITasksContainer = PagedContent<ITask[]>;

export interface ITasksState extends AsyncState {
	data: ITasksContainer;
	keys: { [key: string]: ITask };
	paging: IgetTasksRequest;
}

export const actions: IDropdownGroup[] = [
	{
		name: "Rules",
		items: [
			{ name: "Create Rule" },
			{ name: "Edit Rule" },
			{ name: "Delete Rule" },
		],
	},
	{
		name: "Secrets",
		items: [
			{ name: "Create Secret" },
			{ name: "Edit Secret" },
			{ name: "Delete Secret" },
		],
	},
	{
		name: "Watchlists",
		items: [
			{ name: "Create Watchlist" },
			{ name: "Edit Watchlist" },
			{ name: "Delete Watchlist" },
			{ name: "Clear Watchlist" },
		],
	},
	{
		name: "Subjects",
		items: [
			{ name: "Subject Search" },
			{ name: "Create Subject" },
			{ name: "Edit Subject" },
			{ name: "Delete Subject" },
			{ name: "Add Subject Record" },
			{ name: "Remove Subject Record" },
		],
	},
	{
		name: "Vms",
		items: [
			{ name: "Register Vms" },
			{ name: "Unregister Vms" },
			{ name: "Edit Vms" },
		],
	},
	{
		name: "Sources",
		items: [
			{ name: "Connect Source" },
			{ name: "Edit Source" },
			{ name: "Disconnect Source" },
			{ name: "Start Task" },
			{ name: "Stop Task" },
		],
	},
	{ name: "Configuration", items: [{ name: "Change Configuration" }] },
	{
		name: "Backward",
		items: [{ name: "Backward Search" }],
	},
];

export const statuses = [
	"Scheduled",
	"Processing",
	"Preparing Results",
	"Complete",
	"Bad Request",
	"Error",
	"Not Found",
	"Not Authorized",
];
