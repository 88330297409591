import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import {
	BackwardSearchContainer,
	BackwardSearchState,
	IBackwardSearchResult,
	IBackwardSearchResultImage,
	IBackwardSocketMessage,
	IGetBackwardSearchResultsRequest,
	IHitsResponse,
} from "./types";
import { BackwardSearchAction } from "./action";

export const initialBackwardSearchState: BackwardSearchState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	results: [],
	keys: {},
	paging: {
		page: 0,
		size: 50,
		mine: false,
	},
	scrolledY: 0,
	requestStatus: {},
};

const handleScroll = (
	state: BackwardSearchState = initialBackwardSearchState,
	scrolledY: number,
) => {
	const nState = { ...state };
	nState.scrolledY = scrolledY;
	return nState;
};

const handleBackwardSearches = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Searches") {
		const nState = { ...state };
		switch (action.status) {
			case AsyncActionStatus.SUCCEEDED:
				const payload = action.payload as BackwardSearchContainer;
				nState.data = payload;
		}
		return nState;
	}
	return state;
};

const handleBackwardSearchResult = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Search_Result") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as IBackwardSearchResult;
			if (payload.id) {
				const existing = nState.results.find(
					(r) => r.id === payload.id,
				);
				if (existing)
					nState.results = nState.results.map((r) =>
						r.id === payload.id
							? {
									...r,
									...payload,
									events: [
										...(r.events ?? []),
										...(payload.events ?? []),
									].filter(
										(e, i, a) =>
											a.findIndex(
												(ee) => ee._id === e._id,
											) === i,
									),
							  }
							: r,
					);
				else nState.results = [...nState.results, payload];
			}
		}
		return nState;
	}
	return state;
};

const handleFilter = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Searches_Filter") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload =
				action.payload as unknown as IGetBackwardSearchResultsRequest;
			nState.paging = {
				...nState.paging,
				...payload,
				mine: payload.mine ?? false,
			};
		}
		return nState;
	}
	return state;
};

const handleImage = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Search_Image") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const id = action.meta;
			const searchImage = action.payload as IBackwardSearchResultImage;
			const existing = nState.results.find((r) => r.id === id);
			if (existing)
				nState.results = nState.results.map((r) =>
					r.id === id ? { ...r, searchImage } : r,
				);
		}
		return nState;
	}
	return state;
};

const handleSearchHits = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Search_Hits") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const { id, index, resetHits, ...payload } =
				action.payload as IHitsResponse;

			const existing = nState.results.find((r) => r.id === id);
			if (existing)
				nState.results = nState.results.map((r) =>
					r.id === id
						? {
								...r,
								subjects: r.subjects?.map((result, i) =>
									i === index
										? {
												...result,
												matches: resetHits
													? payload
													: {
															...payload,
															content: [
																...(result
																	.matches
																	?.content ??
																	[]),
																...payload.content,
															],
													  },
										  }
										: result,
								),
						  }
						: r,
				);
		}
		return nState;
	}
	return state;
};

const handleSocket = (
	state: BackwardSearchState = initialBackwardSearchState,
	action: BackwardSearchAction,
) => {
	if (action.type === "Backward_Searches_Socket") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as IBackwardSocketMessage;
			if (payload.correlationId) {
				const existing = nState.data.content.find(
					(r) =>
						r.id === payload.correlationId ||
						r.correlationId === payload.correlationId,
				);

				if (existing) {
					nState.data.content = [
						...nState.data.content.map((r) =>
							r.id === payload.correlationId ||
							r.correlationId === payload.correlationId
								? { ...r, ...payload }
								: r,
						),
					];
				} else if (nState.paging.page === 0)
					nState.data.content = [payload, ...nState.data.content];
			}
		}

		return nState;
	}
	return state;
};

export const backwardSearchReducer: Reducer<
	BackwardSearchState,
	BackwardSearchAction
> = (state = initialBackwardSearchState, action: BackwardSearchAction) => {
	switch (action.type) {
		case "Backward_Search_Image":
			return handleImage(state, action);
		case "Backward_Searches":
			return handleBackwardSearches(state, action);
		case "Backward_Search_Result":
			return handleBackwardSearchResult(state, action);
		case "Backward_Searches_Filter":
			return handleFilter(state, action);
		case "Backward_Search_Hits":
			return handleSearchHits(state, action);
		case "Backward_Searches_Socket":
			return handleSocket(state, action);
		case "Scroll":
			return handleScroll(state, Number(action.meta));
		default:
			return state;
	}
};
