import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

interface ICenteredLoadingProps {
	height?: number;
	column?: boolean;
}

function getMinHeight(windowHeight?: number) {
	if (windowHeight) {
		if (windowHeight > 100) {
			return windowHeight;
		}
		return `${windowHeight}vh`;
	}
	return 0;
}

const CenteredLoading: React.FC<ICenteredLoadingProps> = ({
	height,
	column,
}) => (
	<Grid
		container
		direction={column ? "column" : "row"}
		alignItems="center"
		justifyContent="center"
		style={{ minHeight: getMinHeight(height) }}
	>
		<Grid item>
			<CircularProgress />
		</Grid>
	</Grid>
);

export default CenteredLoading;
