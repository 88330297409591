import { connect } from "react-redux";
import VMSView, {
	IVMSViewPropsToDispatch,
	IVMSViewPropsToState,
} from "./VMSView";
import { AppState } from "../../store";
import { IgetVMSRequest } from "../../store/VMS/types";
import { getVMSAction } from "../../store/VMS/action";

const mapStateToProps = (state: AppState): IVMSViewPropsToState => {
	return {
		error: state.vms.erorr,
		vms: state.vms.data,
		rulesError: state.rules.erorr,
	};
};

const mapDispatchToProps = (dispatch: any): IVMSViewPropsToDispatch => {
	return {
		loadVMS: (request: IgetVMSRequest) => getVMSAction(request)(dispatch),
	};
};

const VMSContainer = connect(mapStateToProps, mapDispatchToProps)(VMSView);
export default VMSContainer;
