import React from "react";
import {
	makeStyles,
	Chip,
	Popover,
	Grid,
	Button,
	Typography,
	Select,
	MenuItem,
	FormControl,
} from "@material-ui/core";
import { GenderTrigger } from "../../../../../store/Rules/types";
import { THEME } from "../../../../../config/index";
import Slider from "../../../../UI/Slider";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		alignItems: "center",
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
	},
	lessBtn: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
	confidenceText: {
		justifyContent: "center",
		display: "flex",
		width: "50px",
	},
}));

interface IGenderTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate: (trigger: GenderTrigger) => void;
	onCancel: () => void;
	genderTrigger?: GenderTrigger;
}

interface IGenderTriggerComponentProps {
	trigger: GenderTrigger;
	updateTrigger(trigger?: GenderTrigger): void;
	isReadOnly: boolean;
	showOnly?: boolean;
}

export const getGenderChipLabel = (trigger: GenderTrigger) => {
	return (
		<span>
			Gender '{trigger.value}' and confidence{" "}
			{trigger.less ? <span>&#60;</span> : <span>&#8805;</span>}{" "}
			{trigger.confidence}{" "}
		</span>
	);
};

const GenderTriggerComponent: React.FC<IGenderTriggerComponentProps> = ({
	trigger,
	updateTrigger,
	isReadOnly,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: GenderTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				label={getGenderChipLabel(trigger)}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<GenderTriggerPopover
				genderTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
			/>
		</>
	);
};

const defaultTrigger: GenderTrigger = {
	type: "GenderTrigger",
	confidence: 10,
	less: false,
	value: "Male",
};

export const GenderTriggerPopover: React.FC<IGenderTriggerPopoverProps> = ({
	anchorEl,
	onUpdate,
	onCancel,
	genderTrigger,
}) => {
	const classes = useStyles();
	const [trigger, setTrigger] = React.useState<GenderTrigger>(
		genderTrigger ? genderTrigger : defaultTrigger,
	);

	React.useEffect(() => {
		setTrigger(genderTrigger ? genderTrigger : defaultTrigger);
	}, [genderTrigger, anchorEl]);

	const handleConfirm = () => {
		onUpdate(trigger);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleSelect = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const changedTrigger = { ...trigger };
		changedTrigger.value = event.target.value as string;
		setTrigger(changedTrigger);
	};

	const handleLess = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.less = !changedTrigger.less;
		setTrigger(changedTrigger);
	};

	const handleSlider = (event: any, newValue: number | number[]) => {
		const changedTrigger = { ...trigger };
		changedTrigger.confidence = newValue as number;
		setTrigger(changedTrigger);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item xs={3}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={6}>
							<Typography>Gender</Typography>
						</Grid>
						<Grid item xs={6}>
							<FormControl style={{ minWidth: 80 }}>
								<Select
									value={trigger.value}
									onChange={handleSelect}
								>
									<MenuItem value="Any">Any</MenuItem>
									<MenuItem value="Male">Male</MenuItem>
									<MenuItem value="Female">Female</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={9}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={4}>
							<Typography>when confidence</Typography>
						</Grid>
						<Grid item xs={2}>
							<Button
								onClick={handleLess}
								size="small"
								className={classes.lessBtn}
							>
								{trigger.less ? "<" : "≥"}
							</Button>
						</Grid>
						<Grid item xs={2} className={classes.confidenceText}>
							<Typography>{trigger.confidence}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Slider
								value={trigger.confidence}
								onChange={handleSlider}
								reverse={trigger.less}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button onClick={handleConfirm}>
							{genderTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default GenderTriggerComponent;
