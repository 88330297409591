import React from "react";
import { ISubject } from "../../../store/Subjects/types";
import {
	Grid,
	makeStyles,
	Typography,
	Box,
	Hidden,
	Tooltip,
} from "@material-ui/core";
import FaceMetadata from "./FaceMetadata";
import { IWatchlist } from "../../../store/Watchlists/types";
import { IMetadata } from "../../../store/Schemas/types";

// MUI bug
declare module "@material-ui/core/Hidden" {
	export interface HiddenProps {
		children?: React.ReactNode;
	}
}

const useStyles = (selected: number, index: number) =>
	makeStyles(() => ({
		container: {
			padding: 2,
		},
		photo: {
			display: "flex",
			alignItems: "center",
			maxWidth: "100%",
			width: 230.4,
			height: 307.2,
		},
		responsivePhoto: {
			width: "100%",
			height: "auto",
			paddingRight: 2.5,
		},
		divBox: {
			borderStyle: "solid",
			borderWidth: 1,
			borderRadius: 4,
			borderColor: "#4495a9",
		},
		box: {
			background: selected === index ? "rgba(224, 224, 224, 1)" : "white",
			"&:hover": {
				background: "rgba(214, 214, 214, 1)",
			},
			border: "1px solid",
			borderRadius: selected === index ? 2 : 4,
			padding: "2px 4px",
			borderColor: selected === index ? "#099e76" : "transparent",
		},
		metadataBox: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		metadata: {
			overflowY: "auto",
			maxHeight: "15rem",
			padding: 1,
		},
		name: {
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			width: "90%",
		},
	}));

interface IFaceItemProps {
	subject: ISubject;
	selected: number;
	index: number;
	onSubClick(index: number): void;
	onDoubleClick(option: string): void;
	hasLongText: boolean;
	watchlist: IWatchlist;
	metadata: IMetadata;
}

const FaceItem: React.FC<IFaceItemProps> = ({
	subject,
	selected,
	index,
	onSubClick,
	onDoubleClick,
	hasLongText,
	metadata,
}) => {
	const classes = useStyles(selected, index)();

	const handleDoubleClick = () => {
		onDoubleClick("edit");
	};

	return (
		<>
			<Grid
				item
				xs={12}
				md={12}
				lg={hasLongText ? 12 : 6}
				className={classes.container}
			>
				<div
					onClick={() => onSubClick(index)}
					onDoubleClick={handleDoubleClick}
					className={classes.divBox}
				>
					<Box className={classes.box}>
						<Grid container>
							<Hidden mdDown>
								<Grid item xs={hasLongText ? 4 : 6}>
									<Grid container direction="column">
										<div className={classes.photo}>
											<img
												className={
													classes.responsivePhoto
												}
												alt="Face"
												src={`data:image/jpeg;base64,${subject.thumb}`}
											/>
										</div>
										<Tooltip title={subject.name}>
											<Typography
												className={classes.name}
											>
												<b>{subject.name}</b>
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid
									item
									xs={hasLongText ? 8 : 6}
									className={classes.metadataBox}
								>
									<div className={classes.metadata}>
										<FaceMetadata
											hasLongText={hasLongText}
											parsedMeta={metadata}
										/>
									</div>
								</Grid>
							</Hidden>
							<Hidden lgUp>
								<Grid item xs={6}>
									<Grid
										container
										alignItems="center"
										direction="column"
									>
										<div className={classes.photo}>
											<img
												className={
													classes.responsivePhoto
												}
												alt="Face"
												src={`data:image/jpeg;base64,${subject.thumb}`}
											/>
										</div>
										<Tooltip title={subject.name}>
											<Typography
												className={classes.name}
											>
												<b>{subject.name}</b>
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid
									item
									xs={6}
									className={classes.metadataBox}
								>
									<div className={classes.metadata}>
										<FaceMetadata
											hasLongText={hasLongText}
											parsedMeta={metadata}
										/>
									</div>
								</Grid>
							</Hidden>
						</Grid>
					</Box>
				</div>
			</Grid>
		</>
	);
};

export default FaceItem;
