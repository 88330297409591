import React, { useRef } from "react";
import {
	Grid,
	Paper,
	Button,
	TextField,
	makeStyles,
	Snackbar,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { IgetTasksRequest, statuses } from "../../../store/Tasks/types";
import {
	IgetSecretsRequest,
	ISecretsContainer,
} from "../../../store/Secrets/types";
import { getSecretsAction } from "../../../store/Secrets/action";
import { LINE_LIMIT } from "../../../config/index";
import { connect } from "react-redux";
import { HttpError } from "../../../config/types";
import Alert from "../../UI/Alert";
import { AppState } from "../../../store";
import ActionSelect from "./ActionSelect";
import useCanSeeSecrets from "../../../hooks/useCanSeeSecrets";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IFilteringOwnProps {
	onFilter: (filter: IgetTasksRequest) => void;
	filter: IgetTasksRequest;
}

interface IFilteringStateProps {
	secretOptions: ISecretsContainer;
	error?: HttpError;
}

interface IFilteringDispatchProps {
	loadSecretOptions: (request: IgetSecretsRequest) => void;
}

interface ITaskFilteringProps
	extends IFilteringOwnProps,
		IFilteringStateProps,
		IFilteringDispatchProps {}

const useStyles = makeStyles(() => ({
	paper: {
		width: 515,
	},
	listbox: {
		maxHeight: 225,
	},
}));

export const limitDescription = (desc: string) => {
	if (!desc) return null;
	const descArr = desc.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|");
	if (descArr) {
		return (
			<>
				{descArr.slice(0, LINE_LIMIT).map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
				))}
			</>
		);
	} else return desc;
};

const clearAutoComplete = (ref: React.MutableRefObject<HTMLDivElement>) => {
	if (ref && ref.current) {
		const clearElement = ref.current.getElementsByClassName(
			"MuiAutocomplete-clearIndicator",
		)[0] as HTMLElement;
		if (clearElement) clearElement.click();
		const inputElement = ref.current.getElementsByClassName(
			"MuiAutocomplete-input",
		)[0] as HTMLElement;
		if (inputElement) inputElement.blur();
	}
};

const TaskFiltering: React.FC<ITaskFilteringProps> = ({
	onFilter,
	filter,
	secretOptions,
	error,
	loadSecretOptions,
}) => {
	const canSeeSecrets = useCanSeeSecrets();
	const [filterObject, setFilterObject] =
		React.useState<IgetTasksRequest>(filter);

	const secretAutoC = useRef(document.createElement("div"));
	const statusAutoC = useRef(document.createElement("div"));

	const classes = useStyles();

	React.useEffect(() => {
		loadSecretOptions({
			page: undefined,
			size: undefined,
			search: undefined,
		});
	}, [loadSecretOptions]);

	const handleFromTimeChange = (time: MaterialUiPickersDate | null) => {
		setFilterObject({
			...filterObject,
			fromTime: time ? time.toString() : "",
		});
	};

	const handleToTimeChange = (time: MaterialUiPickersDate | null) => {
		setFilterObject({
			...filterObject,
			toTime: time ? time.toString() : "",
		});
	};

	const handleAction = (choice: string) =>
		setFilterObject({ ...filterObject, action: choice });

	const handlePhraseInput = (
		event: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		setFilterObject({
			...filterObject,
			phrase: event.target.value as string,
		});
	};

	const handleStatusInput = (event: React.ChangeEvent<{}>, value: any) => {
		if (value !== null) {
			setFilterObject({
				...filterObject,
				status: value,
			});
		} else {
			setFilterObject({
				...filterObject,
				status: undefined,
			});
		}
	};

	const handleSecretInput = (event: React.ChangeEvent<{}>, value: any) => {
		if (value !== null) {
			setFilterObject({
				...filterObject,
				secret: value.id,
			});
		} else {
			setFilterObject({
				...filterObject,
				secret: undefined,
			});
		}
	};

	const handleResetClick = () => {
		clearAutoComplete(statusAutoC);
		clearAutoComplete(secretAutoC);
		setFilterObject({
			action: undefined,
			status: undefined,
			phrase: undefined,
			secret: undefined,
			fromTime: undefined,
			toTime: undefined,
		});
		onFilter({
			...filter,
			action: undefined,
			status: undefined,
			phrase: undefined,
			secret: undefined,
			fromTime: undefined,
			toTime: undefined,
		});
	};

	const handleFilterButton = () => {
		onFilter({
			...filter,
			...filterObject,
			action: filterObject.action
				? filterObject.action.replace(/\s/g, "")
				: undefined,
			status: filterObject.status
				? filterObject.status.replace(/\s/g, "")
				: undefined,
			page: 0,
		});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<Grid
				item
				xs={10}
				sm={11}
				md={9}
				lg={8}
				style={{ flexBasis: "auto" }}
			>
				<Paper>
					<Grid
						container
						spacing={2}
						style={{
							width: "100%",
							margin: 0,
						}}
					>
						<Grid
							item
							xs={3}
							md={2}
							style={{
								textAlign: "right",
								marginBottom: "0px",
							}}
						>
							<h3>Action</h3>
						</Grid>
						<Grid item xs={7} md={4}>
							<ActionSelect
								onSelect={handleAction}
								value={filterObject.action}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							md={1}
							style={{
								textAlign: "right",
								marginBottom: "0px",
							}}
						>
							<h3>Status</h3>
						</Grid>
						<Grid item xs={7} md={4}>
							<Autocomplete
								ref={statusAutoC}
								id="status-combo-box"
								options={statuses}
								onChange={handleStatusInput}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										name="secret"
										id="secret-search"
										margin="normal"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2} md={1} />
						<Grid
							item
							xs={3}
							md={2}
							style={{
								textAlign: "right",
							}}
						>
							<h3>Phrase</h3>
						</Grid>
						<Grid item xs={7} md={4}>
							<TextField
								fullWidth
								name="phrase"
								id="phrase-search"
								margin="normal"
								value={filterObject.phrase || ""}
								onChange={handlePhraseInput}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							md={1}
							style={{
								textAlign: "right",
							}}
						>
							{canSeeSecrets && <h3>Secret</h3>}
						</Grid>
						<Grid item xs={7} md={4}>
							{canSeeSecrets && (
								<Autocomplete
									ref={secretAutoC}
									id="secret-combo-box"
									noOptionsText={"No secrets found."}
									options={
										secretOptions
											? secretOptions.content
											: []
									}
									classes={{
										listbox: classes.listbox,
										paper: classes.paper,
									}}
									getOptionSelected={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(secretOption) =>
										secretOption.name
									}
									renderOption={(option) => (
										<div style={{ display: "inline" }}>
											<b>{option.name}</b>
											<br />
											{limitDescription(
												option.description,
											)}
										</div>
									)}
									onChange={handleSecretInput}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											name="secret"
											id="secret-search"
											margin="normal"
										/>
									)}
								/>
							)}
						</Grid>
						<Grid item xs={2} md={1} />
						<Grid
							item
							xs={3}
							md={2}
							style={{
								textAlign: "right",
							}}
						>
							<h3>Timestamp</h3>
						</Grid>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid item xs={7} md={4}>
								<KeyboardDateTimePicker
									ampm={false}
									variant="inline"
									format="YYYY-MM-DD   HH:mm"
									id="fromTime-date-picker"
									label="From"
									value={filterObject.fromTime || null}
									onChange={handleFromTimeChange}
								/>
							</Grid>
							<Grid item xs={3} md={1} />
							<Grid item xs={7} md={4}>
								<KeyboardDateTimePicker
									ampm={false}
									variant="inline"
									format="YYYY-MM-DD   HH:mm"
									id="toTime-date-picker"
									label="To"
									value={filterObject.toTime || null}
									onChange={handleToTimeChange}
								/>
							</Grid>
						</MuiPickersUtilsProvider>

						<Grid item xs={12} style={{ marginTop: "0px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<Button
									style={{ marginRight: "5px" }}
									onClick={handleFilterButton}
								>
									Filter
								</Button>
								<Button
									style={{ marginRight: "5px" }}
									onClick={handleResetClick}
								>
									Reset
								</Button>
							</div>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</>
	);
};

const mapStateToProps = (state: AppState): IFilteringStateProps => {
	return {
		error: state.secrets.erorr,
		secretOptions: state.secrets.data,
	};
};

const mapDispatchToProps = (dispatch: any): IFilteringDispatchProps => {
	return {
		loadSecretOptions: (request: IgetSecretsRequest) =>
			getSecretsAction(request)(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskFiltering);
