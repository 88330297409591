import React, { useState } from "react";
import { IVMS, IVMSInfo } from "../../store/VMS/types";
import {
	Paper,
	Divider,
	Menu,
	MenuItem,
	IconButton,
	Grid,
	Tooltip,
	TablePagination,
	makeStyles,
	Tabs,
	Tab,
	TextField,
	InputAdornment,
	Button,
} from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import BounceTextField from "../UI/BounceTextField";
import VMSDialog from "../Dialogs/VMSDialog";
import { EditVMS, UnregisterVMS } from "../../store/VMS/action";
import { AppState } from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import SourceTable from "../Sources/Sources";
import TaskStatus from "./TaskStatus";
import VMSStatus from "./VMSStatus";
import { setSourcesFilter, connectSource } from "../../store/Sources/action";
import {
	ISourceRequestInfo,
	SourceDataContainer,
	ISourceinfo,
	EngineState,
} from "../../store/Sources/types";
import EditIcon from "@material-ui/icons/Edit";
import SettingsInputHdmiIcon from "@material-ui/icons/SettingsInputHdmi";
import SourceDialog from "../Dialogs/SourceDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../UI/ConfirmDialog";
import theme from "../../config/Theme";
import { IAlert } from "../UI/Alert";
import Rules from "../Rules/Rules";
import { IgetRulesRequest, RulesDataContainer } from "../../store/Rules/types";
import { setRulesQuery } from "../../store/Rules/action";
import SearchIcon from "@material-ui/icons/Search";
import { handleTaskAction } from "../../helpers/VMSCrud";
import TabPanel, { a11yProps } from "../UI/TabPanel";
import { checkPermissions } from "../../containers/Layout/Layout";

interface IVMSStateProps {
	vms: IVMS;
	sources: SourceDataContainer;
	sourcesFilter: ISourceRequestInfo;
	rules: RulesDataContainer;
	rulesFilter: IgetRulesRequest;
}

interface IVMSOwnProps {
	name: string;
	globalFilter: {
		currentPage: number;
		itemsPerPage: number;
		like: string;
	};
	onAlert(alert: IAlert): void;
	areSourcesAuthorized: boolean;
	areRulesAuthorized: boolean;
}

interface IVMSDispatchProps {
	setSourcesFilter: (vmsName: string, filter: ISourceRequestInfo) => void;
	setRulesFilter: (vmsName: string, filter: IgetRulesRequest) => void;
}

interface IVMSProps extends IVMSStateProps, IVMSOwnProps, IVMSDispatchProps {}

const useStyles = (auth: boolean) =>
	makeStyles(() => ({
		menuItem: {
			color: theme.palette.primary.light,
		},
		button: {
			marginRight: 15,
			whiteSpace: "nowrap",
			textTransform: "none",
			width: 150,
		},
		paper: {
			padding: "15px",
			paddingBottom: "5px",
			color: "rgba(0,0,0,0.54)",
		},
		vmsStatus: {
			display: "flex",
			alignItems: "center",
			verticalAlign: "middle",
		},
		menuIcon: {
			display: "flex",
			justifyContent: "flex-end",
		},
		search: {
			display: "flex",
			marginLeft: "auto",
		},
		placeholder: {
			"&::placeholder": {
				fontSize: auth ? 16 : 14,
			},
		},
	}));

const DriverContext = React.createContext("");

const _VMS: React.FC<IVMSProps> = ({
	vms,
	sources,
	sourcesFilter,
	name,
	onAlert,
	setSourcesFilter,
	rules,
	rulesFilter,
	setRulesFilter,
	areSourcesAuthorized,
	areRulesAuthorized,
}) => {
	const tabQueryParam = new URLSearchParams(window.location.search).get(
		"tab",
	);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isVMSModalOpen, setIsVMSModalOpen] = useState<boolean>(false);
	const [isSourceModalOpen, setIsSourceModalOpen] = useState<boolean>(false);
	const [isConfirmDialogOpen, setConfirmDialogOpen] =
		useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [tabValue, setTabValue] = useState<number>(
		Number(tabQueryParam) || 0,
	);
	const [openRuleDialog, setOpenRuleDialog] = useState<boolean>(false);

	const dispatch = useDispatch();

	const engines = useSelector<AppState, EngineState[]>(
		(state) => state.engines,
	);

	const classes = useStyles(areRulesAuthorized)();

	const showRules = checkPermissions([
		"ROLE_ADMINISTRATOR",
		"ROLE_RULE_OPERATOR",
		"ROLE_ANALYST",
	]);

	const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
	};

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleSourcesSearchInput = (value: string) => {
		setSourcesFilter(vms.name, { page: 0, name: value });
	};

	const handleRulesSearchInput = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRulesFilter(vms.name, { page: 0, phrase: event.target.value });
	};

	const closeVMSModal = () => setIsVMSModalOpen(false);

	const closeSourceModal = () => setIsSourceModalOpen(false);

	const handleSubmitSuccess = () => {
		onAlert({
			message: "Successfully updated VMS.",
			variant: "success",
		});
		closeVMSModal();
		setLoading(false);
	};

	const handleSubmitError = (msg: string) => {
		onAlert({
			message: "Failed to update VMS: " + msg,
			variant: "error",
		});
		setLoading(false);
	};

	const submitVMS = async (info: IVMSInfo) => {
		await handleTaskAction(
			EditVMS,
			() => handleSubmitSuccess(),
			handleSubmitError,
			info,
		);
		handleMenuClose();
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		switch (tabValue) {
			case 0: {
				setSourcesFilter(name, { page: newPage });
				break;
			}
			case 1: {
				setRulesFilter(name, { page: newPage });
				break;
			}
		}
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		switch (tabValue) {
			case 0: {
				setSourcesFilter(name, {
					page: 0,
					size: parseInt(event.target.value, 10),
				});
				break;
			}
			case 1: {
				setRulesFilter(name, {
					page: 0,
					size: parseInt(event.target.value, 10),
				});
				break;
			}
		}
	};

	const handleRegisterSuccess = async () => {
		onAlert({
			message: "Successfully connected source.",
			variant: "success",
		});
		setSourcesFilter(name, { page: 0 });
		closeSourceModal();
	};

	const handleRegisterError = (msg: string) => {
		onAlert({
			message: "Failed to connect source: " + msg,
			variant: "error",
		});
	};

	const handleRegisterSource = async (values: ISourceinfo) => {
		await handleTaskAction(
			connectSource,
			handleRegisterSuccess,
			handleRegisterError,
			name,
			values,
		);
	};

	const handleUnregisterSuccess = () => {
		onAlert({
			message: "Successfully unregistered VMS.",
			variant: "success",
		});
		setLoading(false);
		setConfirmDialogOpen(false);
	};

	const handleUnregisterError = (msg: string) => {
		setLoading(false);
		onAlert({
			message: "Failed to unregister VMS: " + msg,
			variant: "error",
		});
	};

	const handleUnregister = async () => {
		await handleTaskAction(
			UnregisterVMS,
			handleUnregisterSuccess,
			handleUnregisterError,
			name,
		);
	};

	const handleTabSelect = (
		sourcesVal: React.ReactNode,
		rulesVal: React.ReactNode,
		defaultVal?: React.ReactNode,
	) => {
		switch (tabValue) {
			case 0:
				return sourcesVal;
			case 1:
				return rulesVal;
			default:
				return defaultVal;
		}
	};

	const handleEnginesUpdate = (newEngines: string[], id: string) => {
		dispatch({ type: "Update", engines: newEngines, id });
	};

	return vms ? (
		<>
			<Paper>
				<div className={classes.paper}>
					<Grid container>
						<Grid item xs={5}>
							<Grid container>
								<Grid
									item
									xs={12}
									className={classes.vmsStatus}
								>
									<Tooltip title={"Status: " + vms.status}>
										<div>
											<VMSStatus status={vms.status} />
										</div>
									</Tooltip>
									<h3 style={{ margin: 0 }}>{vms.name}</h3>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={areSourcesAuthorized ? 6 : 7}>
							<TaskStatus name={name} />
						</Grid>
						{areSourcesAuthorized ? (
							<Grid item xs={1}>
								<div
									style={{ display: "flex", float: "right" }}
								>
									{vms.canConnectSource ||
									vms.driver === "GENERIC" ? (
										<div className={classes.menuIcon}>
											<IconButton
												size="small"
												onClick={() =>
													setIsSourceModalOpen(true)
												}
												style={{ paddingTop: "0px" }}
											>
												<SettingsInputHdmiIcon />
											</IconButton>
										</div>
									) : null}
									<div className={classes.menuIcon}>
										<IconButton
											size="small"
											onClick={handleMenuClick}
											style={{ paddingTop: "0px" }}
										>
											<TuneIcon />
										</IconButton>
									</div>
								</div>
							</Grid>
						) : null}
					</Grid>
				</div>
				<Divider />
				<div style={{ padding: "5px 15px" }}>
					<div style={{ display: "flex" }}>
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							aria-label="vms-tabs"
							variant="fullWidth"
						>
							<Tab label="Sources" {...a11yProps(0)} />
							{showRules && (
								<Tab
									label={
										<Tooltip title="Rules allow to filter events and perform actions if it matches selected criteria">
											<span>Rules</span>
										</Tooltip>
									}
									{...a11yProps(1)}
								/>
							)}
						</Tabs>
						<div className={classes.search}>
							{handleTabSelect(
								null,
								areRulesAuthorized ? (
									<Button
										className={classes.button}
										onClick={() => setOpenRuleDialog(true)}
									>
										Create New
									</Button>
								) : null,
							)}
							{handleTabSelect(
								<BounceTextField
									disabled={vms.sourceCount === 0}
									onChange={handleSourcesSearchInput}
								/>,
								<TextField
									onChange={handleRulesSearchInput}
									value={
										rulesFilter && rulesFilter.phrase
											? rulesFilter.phrase
											: ""
									}
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										classes: { input: classes.placeholder },
									}}
									placeholder="Find by Name or Description"
								/>,
							)}
						</div>
					</div>
				</div>
				<TabPanel value={tabValue} index={0}>
					{vms && (
						<SourceTable
							name={vms.name}
							onAlert={onAlert}
							areSourcesAuthorized={areSourcesAuthorized}
							engines={engines}
							onEngines={handleEnginesUpdate}
						/>
					)}
				</TabPanel>
				{showRules && (
					<TabPanel value={tabValue} index={1}>
						<DriverContext.Provider value={vms.driver}>
							<Rules
								vms={vms.name}
								onAlert={onAlert}
								rules={rules}
								filter={rulesFilter}
								openDialog={openRuleDialog}
								closeDialog={() => setOpenRuleDialog(false)}
								onDialog={() => setOpenRuleDialog(true)}
								areRulesAuthorized={areRulesAuthorized}
							/>
						</DriverContext.Provider>
					</TabPanel>
				)}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					count={
						handleTabSelect(
							sourcesFilter && sourcesFilter.itemsCount
								? sourcesFilter.itemsCount ||
										sources.totalElements
								: 0,
							rules ? rules.totalElements : 0,
							0,
						) as number
					}
					page={
						handleTabSelect(
							sourcesFilter ? sourcesFilter.page || 0 : 0,
							rulesFilter ? rulesFilter.page || 0 : 0,
							0,
						) as number
					}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPage={
						handleTabSelect(
							sourcesFilter ? sourcesFilter.size || 10 : 10,
							rulesFilter ? rulesFilter.size || 10 : 10,
							10,
						) as number
					}
					component="div"
				/>
			</Paper>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={() => setIsVMSModalOpen(true)}>
					<EditIcon className={classes.menuItem} /> Edit
				</MenuItem>
				<MenuItem onClick={() => setConfirmDialogOpen(true)}>
					<DeleteIcon className={classes.menuItem} /> Unregister
				</MenuItem>
			</Menu>
			<VMSDialog
				open={isVMSModalOpen}
				close={closeVMSModal}
				onSubmit={submitVMS}
				data={vms}
				edit
				onLoading={() => setLoading(true)}
				loading={loading}
			/>
			<SourceDialog
				open={isSourceModalOpen}
				close={closeSourceModal}
				onSubmit={handleRegisterSource}
			/>
			<ConfirmDialog
				open={isConfirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				onConfirm={handleUnregister}
				loading={loading}
				onLoading={() => setLoading(true)}
			>
				Are you sure you want to unregister {name}?
			</ConfirmDialog>
		</>
	) : (
		<></>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IVMSOwnProps,
): IVMSStateProps => {
	return {
		vms: state.vms.keys[ownProps.name],
		sources: state.sources.keys[ownProps.name],
		sourcesFilter: state.sources.paging[ownProps.name],
		rules: state.rules.keys[ownProps.name],
		rulesFilter: state.rules.paging[ownProps.name],
	};
};

const mapDispatchToProps = (dispatch: any): IVMSDispatchProps => {
	return {
		setSourcesFilter: (vmsName: string, filter: ISourceRequestInfo) =>
			dispatch(setSourcesFilter(vmsName, filter)),
		setRulesFilter: (vmsName: string, filter: IgetRulesRequest) =>
			dispatch(setRulesQuery(vmsName, filter)),
	};
};

const VMS = connect(mapStateToProps, mapDispatchToProps)(_VMS);

export const useDriverContext = () => React.useContext(DriverContext);

export default VMS;
