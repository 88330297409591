import React, { useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { getRecordsAction } from "../../store/Records/action";
import {
	IAddRecordBody,
	IRecordsDataContainer,
	IRecordsRequest,
} from "../../store/Records/types";
import { IWatchlist } from "../../store/Watchlists/types";
import { Grid, Snackbar, SnackbarCloseReason } from "@material-ui/core";
import { addRecord, deleteRecord } from "../../store/Records/action";
import ConfirmDialog from "../UI/ConfirmDialog";
import ViewRecordDialog from "../Dialogs/ViewRecordDialog";
import RecordsButtons from "./RecordsButtons";
import RecordsList from "./RecordsList";
import Alert, { IAlert } from "../UI/Alert";
import { handleTaskAction } from "../../helpers/VMSCrud";

interface IRecordsOwnProps {
	watchlist: IWatchlist;
	subjectId: string;
	onImage(nImage: string): void;
	mainImage: string;
}

interface IRecordsStateProps {
	records: IRecordsDataContainer;
}

interface IRecordsDispatchProps {
	loadRecords(
		watchlistId: string,
		subjectId: string,
		request: IRecordsRequest,
	): void;
}

interface IRecordsProps
	extends IRecordsOwnProps,
		IRecordsStateProps,
		IRecordsDispatchProps {}

const _Records: React.FC<IRecordsProps> = ({
	watchlist,
	subjectId,
	onImage,
	records,
	loadRecords,
	mainImage,
}) => {
	const [filter, setFilter] = useState<IRecordsRequest>({
		secret: watchlist.secret,
	});
	const [selected, setSelected] = useState<number | undefined>();
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
	const [openRecord, setOpenRecord] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [alert, setAlert] = useState<IAlert>();

	React.useEffect(() => {
		loadRecords(watchlist.id, subjectId, filter);
	}, [loadRecords, watchlist.id, subjectId, filter]);

	const handlePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const file: File = (target.files as FileList)[0];

		if (file) {
			setLoading(true);
			const reader = new FileReader();
			reader.onload = () => {
				const res = reader.result as string;
				handleAddRecord(btoa(res));
			};
			reader.readAsBinaryString(file);
		}
	};

	const handleImageSwitch = () => {
		const record = records.content.find((r) => r.index === selected);
		if (record) onImage(record.image);
	};

	const handleRecordClick = (option: number) => setSelected(option);

	const handleViewClick = () => setOpenRecord(true);

	const handleAddSuccess = () => {
		loadRecords(watchlist.id, subjectId, filter);
		setLoading(false);
	};

	const handleAddError = (msg: string) => {
		setAlert({
			message: msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleAddRecord = async (nImage: string) => {
		const body: IAddRecordBody = { image: nImage, acceptLowQuality: false };
		await handleTaskAction(
			addRecord,
			handleAddSuccess,
			handleAddError,
			watchlist.id,
			subjectId,
			body,
			{
				secret: watchlist.secret,
			},
		);
	};

	const handleRemoveSuccess = () => {
		if (records.content.length === 1) setFilter({ ...filter });
		else loadRecords(watchlist.id, subjectId, filter);
		setSelected(undefined);
		setLoading(false);
	};

	const handleRemoveError = (msg: string) => {
		setAlert({
			message: msg,
			variant: "error",
		});
		setLoading(false);
	};

	const handleRemoveRecord = async () => {
		if (selected || selected === 0) {
			await handleTaskAction(
				deleteRecord,
				handleRemoveSuccess,
				handleRemoveError,
				watchlist.id,
				subjectId,
				selected,
				{
					secret: watchlist.secret,
				},
			);
		}
		setOpenConfirmDialog(false);
	};

	const isThumbDisabled = () => {
		if (selected === undefined) {
			return true;
		} else if (
			mainImage ===
			records.content.find((r) => r.index === selected)?.image
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleViewRecordError = React.useCallback((errorMsg: string) => {
		setAlert({
			message: errorMsg,
			variant: "error",
		});
		setOpenRecord(false);
	}, []);

	const handleCloseSnackBar = (_?: unknown, reason?: SnackbarCloseReason) => {
		if (reason === "clickaway") return;
		setAlert(undefined);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<RecordsButtons
						onPhoto={handlePhoto}
						onRemove={() => setOpenConfirmDialog(true)}
						onImage={handleImageSwitch}
						isRemoveDisabled={records?.content?.length < 2}
						isThumbDisabled={isThumbDisabled()}
						onView={handleViewClick}
						selected={selected}
					/>
				</Grid>
				<Grid item xs={12}>
					<RecordsList
						records={records}
						loading={loading}
						onClick={handleRecordClick}
						onDoubleClick={handleViewClick}
						selected={selected}
					/>
				</Grid>
			</Grid>
			<ConfirmDialog
				open={openConfirmDialog}
				onConfirm={handleRemoveRecord}
				onClose={() => setOpenConfirmDialog(false)}
				loading={loading}
				onLoading={() => setLoading(true)}
			>
				Do you want to remove this record?
			</ConfirmDialog>
			{records && selected !== undefined ? (
				<ViewRecordDialog
					open={openRecord}
					onClose={() => setOpenRecord(false)}
					watchlist={watchlist}
					subjectId={subjectId}
					index={selected}
					onLoadError={handleViewRecordError}
				/>
			) : null}
			{alert && (
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
					open={Boolean(alert?.message)}
					autoHideDuration={6000}
					onClose={handleCloseSnackBar}
				>
					<div>
						<Alert
							onClose={handleCloseSnackBar}
							alert={{
								message: alert.message,
								variant: "error",
							}}
						/>
					</div>
				</Snackbar>
			)}
		</>
	);
};

const mapStateToProps = (
	state: AppState,
	ownProps: IRecordsOwnProps,
): IRecordsStateProps => ({
	records:
		state.records.keys[`${ownProps.subjectId}-${ownProps.watchlist.id}`],
});

const mapDispatchToProps = (dispatch: any): IRecordsDispatchProps => {
	return {
		loadRecords: (
			watchlistId: string,
			subjectId: string,
			request: IRecordsRequest,
		) => getRecordsAction(watchlistId, subjectId, request)(dispatch),
	};
};

const RecordsView = connect(mapStateToProps, mapDispatchToProps)(_Records);

export default RecordsView;
