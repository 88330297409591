import React from "react";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ISourceinfo } from "../../store/Sources/types";
import { TextField, makeValidate } from "mui-rff";
import { Form } from "react-final-form";
import {
	Button,
	InputAdornment,
	IconButton,
	CircularProgress,
} from "@material-ui/core";

interface ISourceForm {
	data?: ISourceinfo;
	onSubmit: (values: ISourceinfo) => void;
	discard?: () => void;
	edit?: boolean;
}

const schema = Yup.object<ISourceinfo>().shape({
	displayName: Yup.string().required(),
	password: Yup.string().nullable(),
	url: Yup.string().required(),
	user: Yup.string().nullable(),
});

const SourceForm: React.FC<ISourceForm> = (props) => {
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const validate = makeValidate(schema);

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			initialValues={props.data}
			render={({ handleSubmit, pristine, submitting }) => (
				<form onSubmit={handleSubmit}>
					<TextField name="displayName" label="Name" required />
					<TextField name="url" label="RTSP URL" required />
					<TextField
						name="user"
						label="User"
						autoComplete="new-password"
					/>

					<TextField
						name="password"
						label="Password"
						placeholder={
							props.edit
								? "Leave blank if you don't want to change password"
								: ""
						}
						autoComplete="new-password"
						type={!showPassword ? "password" : "text"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<br />
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{submitting ? <CircularProgress /> : null}
						<Button type="submit" disabled={pristine || submitting}>
							Submit
						</Button>
						{props.discard ? (
							<Button onClick={props.discard}>Discard</Button>
						) : (
							<div />
						)}
					</div>
				</form>
			)}
		/>
	);
};

export default SourceForm;
