import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { State } from "./AreaDialog";
import {
	getIntersectionPoint,
	isPolygonValid,
	isRectOutsideOfCanvas,
} from "../../../helpers/Utils";
import { IAreasHistory } from "./GridOverlay";
import React from "react";
import useForwardRef from "../../../hooks/useForwardRef";

export type TPoint = { X: number; Y: number };

export type TRect = {
	start: TPoint;
	end: TPoint;
	isInclude: boolean;
	currentlyDragging?: boolean;
	timestamp: string;
	name: "rect";
};

export type TPolygon = {
	points: TPoint[];
	isInclude: boolean;
	currentlyDragging?: boolean;
	timestamp: string;
	name: "polygon";
};

type Props = {
	state: State;
	reset: number | undefined;
	undo: number | undefined;
	redo: number | undefined;
	rects: TRect[];
	setRects: React.Dispatch<React.SetStateAction<TRect[]>>;
	polygons: TPolygon[];
	setPolygons: React.Dispatch<React.SetStateAction<TPolygon[]>>;
	hidden: boolean;
	history: IAreasHistory<{ rects: TRect[]; polygons: TPolygon[] }>;
	setHistory: React.Dispatch<React.SetStateAction<Props["history"]>>;
	canvasSize: { width: number; height: number };
	setState: React.Dispatch<React.SetStateAction<State>>;
};

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "100%",
		zIndex: 1,
	},
	wrapper: {
		border: "1px solid black",
		position: "relative",
		width: "100%",
		height: "100%",
		display: "flex",
		opacity: 0.4,
	},
	rect: {
		position: "absolute",
		userSelect: "none",
	},
	polygon: {
		position: "absolute",
		userSelect: "none",
	},
	point: {
		position: "absolute",
		userSelect: "none",
		width: 10,
		height: 10,
		borderRadius: "50%",
		backgroundColor: "black",
	},
	canvas: {
		width: "100%",
		height: "100%",
	},
}));

export const bg = {
	red: "rgb(255, 75, 75)",
	green: "rgb(0, 150, 0)",
};

const RegionsOverlay = React.forwardRef(
	(
		{
			state,
			reset,
			undo,
			redo,
			rects,
			polygons,
			setRects,
			setPolygons,
			hidden,
			history,
			setHistory,
			canvasSize,
			setState,
		}: Props,
		ref: React.ForwardedRef<HTMLCanvasElement>,
	) => {
		const classes = useStyles();
		const wrapperRef = useRef<HTMLDivElement>(null);
		const canvasRef = useRef<HTMLCanvasElement>(null);
		const outsideCanvasRef = useForwardRef(ref);
		const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
		const [dragging, setDragging] = useState(false);

		const addUndoCommand = useCallback(
			(items: { rects: TRect[]; polygons: TPolygon[] }) => {
				setHistory((prev) => ({
					undoList: [...prev.undoList, items],
					redoList: [],
				}));
			},
			[setHistory],
		);

		const onMouseDown = useCallback(
			(e: MouseEvent) => {
				const clickedOnCanvasOrDiv =
					e.composedPath()?.[0] instanceof HTMLCanvasElement ||
					e.composedPath()?.[0] instanceof HTMLDivElement;
				if (!clickedOnCanvasOrDiv) return;

				setDragging(true);
				const isInclude = state % 2 === 0;
				switch (state) {
					case State.incRect:
					case State.excRect:
						setRects((prev) => [
							...prev.map((p) => {
								if (p.currentlyDragging)
									delete p.currentlyDragging;
								return p;
							}),
							{
								start: {
									X: cursorPosition.x,
									Y: cursorPosition.y,
								},
								end: {
									X: cursorPosition.x,
									Y: cursorPosition.y,
								},
								isInclude,
								currentlyDragging: true,
								timestamp: moment().format(
									"YYYY-MM-DD HH:mm:ss:SSS",
								),
								name: "rect",
							},
						]);
						break;
					case State.incPoly:
					case State.excPoly:
						if (polygons[polygons.length - 1]?.currentlyDragging) {
							const lastPolygon = polygons[polygons.length - 1];

							const lastPoint =
								lastPolygon.points[
									lastPolygon.points.length - 1
								];

							const point = {
								X: cursorPosition.x,
								Y: cursorPosition.y,
							};

							const intersectionPoint = getIntersectionPoint(
								point,
								lastPoint,
							);

							const newPoint =
								intersectionPoint.X === lastPoint.X &&
								intersectionPoint.Y === lastPoint.Y
									? point
									: intersectionPoint;

							const newPoly = {
								...lastPolygon,
								points: [
									...lastPolygon.points,
									{
										X: Math.min(1, Math.max(0, newPoint.X)),
										Y: Math.min(1, Math.max(0, newPoint.Y)),
									},
								],
							};
							if (
								newPoly.points.length > 2 &&
								!isPolygonValid(newPoly.points)
							)
								return;
							addUndoCommand({ rects, polygons });
							setPolygons((prev) => [
								...prev.slice(0, -1),
								newPoly,
							]);
						} else {
							addUndoCommand({ rects, polygons });
							setPolygons((prev) => {
								const newPoly = {
									points: [
										{
											X: Math.min(
												1,
												Math.max(0, cursorPosition.x),
											),
											Y: Math.min(
												1,
												Math.max(0, cursorPosition.y),
											),
										},
									],
									isInclude,
									currentlyDragging: true,
									timestamp: moment().format(
										"YYYY-MM-DD HH:mm:ss:SSS",
									),
									name: "polygon",
								} as TPolygon;

								return [...prev, newPoly];
							});
						}
				}
			},
			[
				addUndoCommand,
				cursorPosition,
				polygons,
				rects,
				setPolygons,
				setRects,
				state,
			],
		);

		const onMouseUp = useCallback(() => {
			setDragging(false);
			switch (state) {
				case State.incRect:
				case State.excRect:
					let isLastRectValid = false;
					const lastRect = rects[rects.length - 1];
					isLastRectValid =
						lastRect &&
						lastRect.start.X !== lastRect.end.X &&
						lastRect.start.Y !== lastRect.end.Y &&
						!isRectOutsideOfCanvas(lastRect);

					if (dragging && isLastRectValid)
						addUndoCommand({
							rects: rects.filter((r) => !r.currentlyDragging),
							polygons,
						});
					const newRects = rects.map((p) => {
						if (p.currentlyDragging) {
							delete p.currentlyDragging;
							return {
								...p,
								start: {
									X: Math.min(1, Math.max(0, p.start.X)),
									Y: Math.min(1, Math.max(0, p.start.Y)),
								},
								end: {
									X: Math.min(1, Math.max(0, p.end.X)),
									Y: Math.min(1, Math.max(0, p.end.Y)),
								},
							};
						}
						return p;
					});

					if (!isLastRectValid) newRects.pop();
					if (newRects.length === 1 && polygons.length === 0) {
						setRects([
							{
								start: { X: 0, Y: 0 },
								end: { X: 1, Y: 1 },
								isInclude: !newRects[0].isInclude,
								currentlyDragging: false,
								timestamp: moment(newRects[0].timestamp)
									.subtract(1, "s")
									.format("YYYY-MM-DD HH:mm:ss:SSS"),
								name: "rect",
							},
							...newRects,
						]);
					} else setRects(newRects);
					break;
			}
		}, [addUndoCommand, dragging, polygons, rects, setRects, state]);

		const onMouseMove = useCallback(
			(e: MouseEvent) => {
				const { clientY, clientX } = e;
				const { left, top, width, height } =
					wrapperRef.current!.getBoundingClientRect();

				const x = (clientX - left) / width;
				const y = (clientY - top) / height;

				setCursorPosition({ x, y });
				if (dragging) {
					switch (state) {
						case State.incRect:
						case State.excRect:
							setRects((prev) =>
								prev.map((p, i) => {
									if (i === prev.length - 1)
										p.end = { X: x, Y: y };
									return p;
								}),
							);
							break;
					}
				}
			},
			[dragging, setRects, state],
		);

		const drawPolygon = useCallback(
			(polygon: TPolygon) => {
				const canvas = canvasRef.current;
				const outsideCanvas = outsideCanvasRef.current;
				if (!canvas || !outsideCanvas) return;
				const ctx = outsideCanvas.getContext("2d");
				if (!ctx) return;
				const color =
					polygon.currentlyDragging && polygon.isInclude
						? "rgb(0, 210, 0)"
						: polygon.currentlyDragging && !polygon.isInclude
						? "rgb(255, 20, 20)"
						: polygon.isInclude
						? bg["green"]
						: bg["red"];
				ctx.fillStyle = color;
				ctx.strokeStyle = color;
				ctx.lineWidth = 2;
				ctx.beginPath();

				const widthDiff = (outsideCanvas.width - canvas.width) / 2;
				const heightDiff = (outsideCanvas.height - canvas.height) / 2;

				const points = polygon.points.map((point) => ({
					X: point.X * canvas.width + widthDiff,
					Y: point.Y * canvas.height + heightDiff,
				}));

				points.forEach((point, i) => {
					const { X, Y } = point;
					if (i === 0) ctx.moveTo(X, Y);
					else ctx.lineTo(X, Y);
				});

				if (polygon.currentlyDragging) {
					ctx.lineTo(
						cursorPosition.x * canvas.width + widthDiff,
						cursorPosition.y * canvas.height + heightDiff,
					);
				}
				ctx.closePath();
				ctx.fill();

				if (points.length < 3 && polygon.currentlyDragging) {
					const { X, Y } = points[0];
					ctx.beginPath();
					ctx.moveTo(X, Y);
					ctx.lineTo(
						cursorPosition.x * canvas.width + widthDiff,
						cursorPosition.y * canvas.height + heightDiff,
					);
					ctx.stroke();
				}

				if (points.length >= 3 && polygon.currentlyDragging) {
					const intersectionPoint = getIntersectionPoint(
						{ X: cursorPosition.x, Y: cursorPosition.y },
						polygon.points[polygon.points.length - 1],
					);
					const isValid =
						isPolygonValid([
							...polygon.points,
							{
								X: Math.min(1, Math.max(0, cursorPosition.x)),
								Y: Math.min(1, Math.max(0, cursorPosition.y)),
							},
						]) &&
						isPolygonValid([
							...polygon.points,
							{
								X: Math.min(
									1,
									Math.max(0, intersectionPoint.X),
								),
								Y: Math.min(
									1,
									Math.max(0, intersectionPoint.Y),
								),
							},
						]);

					if (!isValid) {
						ctx.strokeStyle = "rgb(255, 0, 0)";
						ctx.stroke();
					}
				}
			},
			[cursorPosition, outsideCanvasRef],
		);

		const drawRect = useCallback(
			(rect: TRect) => {
				const canvas = canvasRef.current;
				const outsideCanvas = outsideCanvasRef.current;
				if (!canvas || !outsideCanvas) return;
				const ctx = outsideCanvas.getContext("2d");
				if (!ctx) return;
				const color =
					rect.currentlyDragging && rect.isInclude
						? "rgb(0, 210, 0)"
						: rect.currentlyDragging && !rect.isInclude
						? "rgb(255, 0, 0)"
						: rect.isInclude
						? bg["green"]
						: bg["red"];
				ctx.fillStyle = color;

				const widthDiff = (outsideCanvas.width - canvas.width) / 2;
				const heightDiff = (outsideCanvas.height - canvas.height) / 2;

				const startX = rect.start.X * canvas.width + widthDiff;
				const startY = rect.start.Y * canvas.height + heightDiff;
				const endX = rect.end.X * canvas.width + widthDiff;
				const endY = rect.end.Y * canvas.height + heightDiff;
				ctx.fillRect(startX, startY, endX - startX, endY - startY);
			},
			[outsideCanvasRef],
		);

		const setStateByLastItem = useCallback(
			(rects: TRect[], polygons: TPolygon[]) => {
				const lastRect = rects.at(-1);
				const lastPolygon = polygons.at(-1);
				if (!lastRect && !lastPolygon) return;
				const lastIsPolygon = !lastRect
					? true
					: !lastPolygon
					? false
					: lastPolygon.timestamp > lastRect.timestamp;

				if (lastIsPolygon && lastPolygon?.currentlyDragging)
					setState(
						lastPolygon?.isInclude ? State.incPoly : State.excPoly,
					);
			},
			[setState],
		);

		const handleRedo = useCallback(() => {
			const lastHistory = history.redoList.at(-1);
			if (!lastHistory) return;

			const newHistory = structuredClone(history);
			newHistory.redoList.pop();
			newHistory.undoList.push({ rects, polygons });
			setRects(lastHistory.rects);
			setPolygons(lastHistory.polygons);
			setHistory(newHistory);
			setStateByLastItem(lastHistory.rects, lastHistory.polygons);
		}, [
			history,
			setHistory,
			setPolygons,
			setRects,
			polygons,
			rects,
			setStateByLastItem,
		]);

		const handleUndo = useCallback(() => {
			const lastHistory = history.undoList.at(-1);
			if (!lastHistory) return;
			const newHistory = structuredClone(history);
			newHistory.undoList.pop();
			newHistory.redoList.push({ rects, polygons });
			setRects(lastHistory.rects);
			setPolygons(lastHistory.polygons);
			setHistory(newHistory);
			setStateByLastItem(lastHistory.rects, lastHistory.polygons);
		}, [
			history,
			setHistory,
			setPolygons,
			setRects,
			polygons,
			rects,
			setStateByLastItem,
		]);

		const completePolygon = useCallback(() => {
			const last = polygons[polygons.length - 1];
			if (last && !isPolygonValid(last.points)) return;
			const curr = polygons.map((p) => {
				if (p.currentlyDragging) {
					delete p.currentlyDragging;
					p.points = p.points.reduce((acc: TPoint[], curr) => {
						if (!acc.find((a) => a.X === curr.X && a.Y === curr.Y))
							acc.push({
								X: Math.min(1, Math.max(0, curr.X)),
								Y: Math.min(1, Math.max(0, curr.Y)),
							});
						return acc;
					}, []);
				}
				return p;
			});

			if (curr[curr.length - 1]?.points.length < 3)
				return curr.slice(0, -1);
			if (curr.length === 1 && rects.length === 0)
				setRects([
					{
						start: { X: 0, Y: 0 },
						end: { X: 1, Y: 1 },
						isInclude: !curr[0].isInclude,
						currentlyDragging: false,
						timestamp: moment(curr[0].timestamp)
							.subtract(1, "s")
							.format("YYYY-MM-DD HH:mm:ss:SSS"),
						name: "rect",
					},
				]);
			setPolygons(curr);
		}, [rects, setRects, polygons, setPolygons]);

		const handleEscape = useCallback(() => {
			setDragging(false);
			const lastPolygon = polygons[polygons.length - 1];
			if (lastPolygon?.currentlyDragging) {
				setPolygons((prev) => prev.slice(0, -1));
				return;
			}

			const lastRect = rects[rects.length - 1];
			if (lastRect?.currentlyDragging) {
				setRects((prev) => prev.slice(0, -1));
				return;
			}
		}, [polygons, setPolygons, rects, setRects]);

		const drawAll = useCallback(() => {
			const canvas = canvasRef.current;
			const outsideCanvas = outsideCanvasRef.current;
			if (
				!canvas ||
				!outsideCanvas ||
				!canvasSize.width ||
				!canvasSize.height
			)
				return;
			const ctx = canvas.getContext("2d");
			const outsideCtx = outsideCanvas.getContext("2d");
			if (!ctx || !outsideCtx) return;

			const widthRatio =
				outsideCanvas.getBoundingClientRect().width /
					canvas.getBoundingClientRect().width || 1;
			const heightRatio =
				outsideCanvas.getBoundingClientRect().height /
					canvas.getBoundingClientRect().height || 1;

			[outsideCanvas.width, canvas.width] = [
				canvasSize.width * widthRatio,
				canvasSize.width,
			];
			[outsideCanvas.height, canvas.height] = [
				canvasSize.height * heightRatio,
				canvasSize.height,
			];
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.fillRect(0, 0, canvas.width, canvas.height);
			outsideCtx.clearRect(
				0,
				0,
				outsideCanvas.width,
				outsideCanvas.height,
			);

			const allFigures = [...rects, ...polygons].sort((a, b) =>
				moment(a.timestamp).diff(b.timestamp),
			);

			allFigures.forEach((r) => {
				if (r.name === "rect") drawRect(r);
				if (r.name === "polygon") drawPolygon(r);
			});
		}, [
			canvasSize,
			drawPolygon,
			drawRect,
			outsideCanvasRef,
			polygons,
			rects,
		]);

		useEffect(() => {
			const undoAction = (e: KeyboardEvent) => {
				if (e.key === "z" && e.ctrlKey) handleUndo();
			};

			const redoAction = (e: KeyboardEvent) => {
				if (e.key === "Z" && e.ctrlKey && e.shiftKey) handleRedo();
			};

			const enterAction = (e: KeyboardEvent) => {
				if (e.key === "Enter") completePolygon();
			};

			const dblClickAction = (e: MouseEvent) => {
				const clickedOnCanvasOrDiv =
					e.composedPath()?.[0] instanceof HTMLCanvasElement ||
					e.composedPath()?.[0] instanceof HTMLDivElement;
				if (!clickedOnCanvasOrDiv) return;
				completePolygon();
			};

			const escapeAction = (e: KeyboardEvent) => {
				if (e.key === "Escape") handleEscape();
			};

			window.addEventListener("keydown", undoAction);
			window.addEventListener("keydown", redoAction);
			window.addEventListener("keydown", enterAction);
			window.addEventListener("keydown", escapeAction);
			window.addEventListener("dblclick", dblClickAction);
			window.addEventListener("mousemove", onMouseMove);
			window.addEventListener("mousedown", onMouseDown);
			window.addEventListener("mouseleave", onMouseUp);
			window.addEventListener("mouseup", onMouseUp);
			return () => {
				window.removeEventListener("keydown", undoAction);
				window.removeEventListener("keydown", redoAction);
				window.removeEventListener("keydown", enterAction);
				window.removeEventListener("keydown", escapeAction);
				window.removeEventListener("dblclick", dblClickAction);
				window.removeEventListener("mousemove", onMouseMove);
				window.removeEventListener("mousedown", onMouseDown);
				window.removeEventListener("mouseleave", onMouseUp);
				window.removeEventListener("mouseup", onMouseUp);
			};
		}, [
			handleUndo,
			handleRedo,
			completePolygon,
			handleEscape,
			onMouseMove,
			onMouseDown,
			onMouseUp,
		]);

		useEffect(() => {
			drawAll();
		}, [drawAll]);

		useEffect(() => {
			if (reset) {
				setRects([]);
				setPolygons([]);
				setHistory({ redoList: [], undoList: [] });
			}
			if (undo) handleUndo();
			if (redo) handleRedo();
		}, [
			reset,
			undo,
			redo,
			setRects,
			setPolygons,
			setHistory,
			handleRedo,
			handleUndo,
		]);

		useEffect(() => {
			const onResize = () => drawAll();
			window.addEventListener("resize", onResize);

			return () => window.removeEventListener("resize", onResize);
		}, [drawAll]);

		return (
			<div
				style={{ visibility: hidden ? "hidden" : "visible" }}
				className={classes.root}
			>
				<div className={classes.wrapper} ref={wrapperRef}>
					<canvas
						ref={canvasRef}
						className={classes.canvas}
						width={
							canvasSize.width === 0
								? 1200
								: canvasSize.width ?? 1200
						}
						height={
							canvasSize.height === 0
								? 600
								: canvasSize.height ?? 600
						}
					/>
				</div>
			</div>
		);
	},
);

export default RegionsOverlay;
