import React from "react";
import {
	makeStyles,
	Grid,
	Button,
	Divider,
	Checkbox,
	Tooltip,
	FormControlLabel,
} from "@material-ui/core";
import {
	RuleAction,
	RuleHttpAction,
	RuleVmsAction,
	RuleMqttAction,
} from "../../../../store/Rules/types";
import AnalyticsPopover from "./Popovers/VmsPopover";
import HttpPopover from "./Popovers/HttpPopover";
import MqttPopover from "./Popovers/MqttPopover";
import ActionItem from "./ActionItem";
import HelpIcon from "@material-ui/icons/Help";
import { IAlert } from "../../../UI/Alert";
import { useWindowWidth } from "../../../../helpers/Hooks";
import { useDriverContext } from "../../../VMS/VMS";

const calcHelpMargin = (width: number, isReadOnly: boolean) => {
	if (isReadOnly) {
		if (width < 960) {
			return 13;
		}
		return 24.1;
	}
	return 0;
};

const calcAnalytics = (width: number) => {
	if (width < 960) {
		return 18;
	}
	return 48;
};

const useStyles = (analyticsWidth: number, helpWidth: number) =>
	makeStyles(() => ({
		button: {
			textTransform: "none",
			paddingTop: 10,
		},
		help: {
			paddingTop: 8,
			marginLeft: helpWidth,
			flex: 1,
		},
		analytics: {
			paddingRight: analyticsWidth,
			textTransform: "none",
			paddingTop: 10,
		},
		generic: {
			marginRight: analyticsWidth === 18 ? 41 : 24,
		},
	}));

interface IActionsProps {
	onActions(newActions: RuleAction[]): void;
	actions: RuleAction[];
	enabled: boolean;
	onEnabled(check: boolean): void;
	isReadOnly: boolean;
	isSecretSet: boolean;
	vmsName: string;
	onAlert(alert: IAlert): void;
}

const Actions: React.FC<IActionsProps> = ({
	onActions,
	actions,
	enabled,
	onEnabled,
	isReadOnly,
	isSecretSet,
	vmsName,
	onAlert,
}) => {
	const [width] = useWindowWidth();
	const classes = useStyles(
		calcAnalytics(width),
		calcHelpMargin(width, isReadOnly),
	)();

	const [vmsAnchor, setVmsAnchor] = React.useState<null | HTMLElement>(null);
	const [httpAnchor, setHttpAnchor] = React.useState<null | HTMLElement>(
		null,
	);
	const [mqttAnchor, setMqttAnchor] = React.useState<null | HTMLElement>(
		null,
	);
	const [selectedAction, setSelectedAction] =
		React.useState<null | RuleAction>(null);
	const [selectedIndex, setSelectedIndex] = React.useState<null | number>(
		null,
	);
	const driver = useDriverContext();

	const handleAnalyticsClick = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setVmsAnchor(event.currentTarget);
	};

	const handleHttpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setHttpAnchor(event.currentTarget);
	};

	const handleMqttClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMqttAnchor(event.currentTarget);
	};

	const handleEditClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		action: RuleAction,
		index: number,
	) => {
		setSelectedIndex(index);
		setSelectedAction(action);
		if (action.type === "VmsAction") {
			setVmsAnchor(event.currentTarget);
		} else if (action.type === "HttpAction") {
			setHttpAnchor(event.currentTarget);
		} else {
			setMqttAnchor(event.currentTarget);
		}
	};

	const handleDeleteClick = (index: number) => {
		const actionsCopy = [...actions];
		actionsCopy.splice(index, 1);
		onActions(actionsCopy);
	};

	const handleEdit = (updatedAction: RuleAction) => {
		const actionsCopy = [...actions];
		actionsCopy[selectedIndex as number] = updatedAction;
		onActions(actionsCopy);
	};

	const handleAdd = (newAction: RuleAction) => {
		const actionsCopy = [...actions];
		actionsCopy.push(newAction);
		onActions(actionsCopy);
	};

	const handleCancel = () => {
		setVmsAnchor(null);
		setHttpAnchor(null);
		setMqttAnchor(null);
		setSelectedAction(null);
		setSelectedIndex(null);
	};

	const handleEnabledChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		onEnabled(event.target.checked);
	};

	return (
		<>
			<Grid container>
				<Grid item>
					<Tooltip title="If unchecked, rules will not perform any actions">
						<FormControlLabel
							label="Enabled"
							labelPlacement="end"
							style={{ paddingLeft: 8 }}
							control={
								<Checkbox
									checked={enabled}
									onChange={handleEnabledChange}
									disabled={isReadOnly}
								/>
							}
						/>
					</Tooltip>
				</Grid>
				<Grid
					container
					item
					xs={12}
					direction="row-reverse"
					justifyContent="flex-end"
				>
					<Grid
						item
						xs={isReadOnly ? 10 : 1}
						md={isReadOnly ? 11 : 5}
						className={classes.help}
					>
						<Grid item container justifyContent="flex-end">
							<Tooltip title="Actions allow to send selected events as analytic event or specified address">
								<HelpIcon />
							</Tooltip>
						</Grid>
					</Grid>
					{isReadOnly ? null : driver !== "GENERIC" ? (
						<>
							<Grid item>
								<Button
									onClick={handleMqttClick}
									className={classes.analytics}
								>
									Add MQTT Event
								</Button>
							</Grid>
							<Grid item>
								<h3
									style={{
										color: "rgba(0, 0, 0, 0.54)",
										textAlign: "center",
										marginTop: 9,
									}}
								>
									|
								</h3>
							</Grid>
							<Grid item>
								<Button
									onClick={handleAnalyticsClick}
									className={classes.button}
									disabled={isSecretSet}
								>
									Add Analytics Event
								</Button>
							</Grid>
							<Grid item>
								<h3
									style={{
										color: "rgba(0, 0, 0, 0.54)",
										textAlign: "center",
										marginTop: 9,
									}}
								>
									|
								</h3>
							</Grid>
							<Grid item>
								<Button
									onClick={handleHttpClick}
									className={classes.button}
								>
									Add Http Event
								</Button>
							</Grid>
						</>
					) : (
						<>
							<Grid item>
								<Button
									onClick={handleMqttClick}
									className={classes.button}
								>
									Add MQTT Event
								</Button>
							</Grid>
							<Grid item>
								<h3
									style={{
										color: "rgba(0, 0, 0, 0.54)",
										textAlign: "center",
										marginTop: 9,
									}}
								>
									|
								</h3>
							</Grid>
							<Grid item>
								<Button
									onClick={handleHttpClick}
									className={classes.button}
								>
									Add Http Event
								</Button>
							</Grid>
						</>
					)}
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					{actions.length > 0 ? (
						<Grid container>
							{actions.map((action, i) => (
								<ActionItem
									key={i}
									action={action}
									index={i}
									onEdit={handleEditClick}
									onDelete={handleDeleteClick}
									isReadOnly={isReadOnly}
								/>
							))}
						</Grid>
					) : (
						<h3
							style={{
								color: "rgba(0, 0, 0, 0.54)",
								textAlign: "center",
								height: 15,
							}}
						>
							No actions.
						</h3>
					)}
				</Grid>
			</Grid>
			{driver !== "GENERIC" ? (
				<AnalyticsPopover
					isReadOnly={isReadOnly}
					onUpdate={selectedAction ? handleEdit : handleAdd}
					onCancel={handleCancel}
					anchorEl={vmsAnchor}
					vmsAction={
						selectedAction
							? (selectedAction as RuleVmsAction)
							: undefined
					}
					vmsName={vmsName}
					onAlert={onAlert}
				/>
			) : null}
			<HttpPopover
				onUpdate={selectedAction ? handleEdit : handleAdd}
				onCancel={handleCancel}
				anchorEl={httpAnchor}
				httpAction={
					selectedAction
						? (selectedAction as RuleHttpAction)
						: undefined
				}
			/>
			<MqttPopover
				onUpdate={selectedAction ? handleEdit : handleAdd}
				onCancel={handleCancel}
				anchorEl={mqttAnchor}
				mqttAction={
					selectedAction
						? (selectedAction as RuleMqttAction)
						: undefined
				}
			/>
		</>
	);
};

export default Actions;
