import React, { useEffect, useState, SyntheticEvent } from "react";
import { HttpError } from "../../config/types";
import Alert, { IAlert } from "../../components/UI/Alert";
import { Button, CircularProgress, Grid, Snackbar } from "@material-ui/core";
import { IConfigurationProperty } from "../../store/Properties/types";
import { IDefinition } from "../../store/Definitions/types";
import Property from "../../components/Properties/Property";
import { changeConfiguration } from "../../store/Properties/action";
import { Redirect, useLocation } from "react-router";
import { handleTaskAction } from "../../helpers/VMSCrud";

const findService = (
	pathName: string,
	properties: IConfigurationProperty[],
) => {
	if (pathName === "/config") {
		return 0;
	}
	const cutPath = pathName.substring(8);
	for (let i = 0; i < properties.length; i++) {
		if (properties[i].service === cutPath) {
			return i;
		}
	}
	return -1;
};

type IConfigurationViewProps = IConfigurationViewPropsToDispatch &
	IConfigurationViewPropsToState;

const ConfigurationView: React.FC<IConfigurationViewProps> = ({
	loadProperties,
	loadDefinitions,
	properties,
	definitions,
	error,
}) => {
	const selectedService = findService(useLocation().pathname, properties);
	const [config, setConfig] = useState<IConfigurationProperty | null>(
		selectedService > -1 ? properties[selectedService] : null,
	);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
	const [alert, setAlert] = React.useState<IAlert | undefined>(undefined);
	const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	useEffect(() => {
		setConfig({ ...properties[selectedService] });
		setIsDisabled(true);
	}, [properties, selectedService]);

	useEffect(() => {
		loadProperties();
		loadDefinitions();
	}, [loadProperties, loadDefinitions, selectedService]);

	const handleOpenSnackBar = (alert: IAlert) => {
		if (openSnackbar) {
			handleCloseSnackbar();
		}
		setAlert(alert);
		setOpenSnackbar(true);
	};

	const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	const getUpdatedConfig = () => {
		const newProperties = [...properties];
		if (config) newProperties[selectedService] = config;
		return newProperties;
	};

	const handleApplySuccess = () => {
		handleOpenSnackBar({
			message: "Configuration changed successfully.",
			variant: "success",
		});
		loadProperties();
		setIsDisabled(true);
		setLoading(false);
	};

	const handleApplyError = (msg: string) => {
		handleOpenSnackBar({
			message: "Failed to change configuration: " + msg,
			variant: "error",
		});
		setLoading(false);
		setIsDisabled(false);
	};

	const handleApply = async () => {
		setLoading(true);
		await handleTaskAction(
			changeConfiguration,
			handleApplySuccess,
			handleApplyError,
			getUpdatedConfig(),
		);
	};

	const handleDefault = async () => {
		const defaultConfig = definitions[selectedService].properties.map(
			(value) => {
				return { key: value.key, value: value.defaultValue };
			},
		);

		if (config) {
			const other = config;
			setConfig({
				...other,
				values: defaultConfig,
			});
		}
		setIsDisabled(false);
		setSaveDisabled(false);
	};

	const handleCancel = () => {
		loadProperties();
		setIsDisabled(true);
		setSaveDisabled(false);
	};

	const handleConfigChange = (changedConfig: IConfigurationProperty) => {
		setConfig(changedConfig);
		setIsDisabled(false);
	};

	const isDefaultDisabled = () => {
		if (config && config.values && definitions[selectedService]) {
			if (
				config.values.length !==
				definitions[selectedService].properties.length
			) {
				return false;
			}
			for (let i = 0; i < config.values.length; i++) {
				const defProps = definitions[selectedService].properties[i];
				if (
					defProps &&
					defProps.defaultValue !== config.values[i].value
				) {
					return false;
				}
			}
		}
		return true;
	};

	if (config?.service !== properties[selectedService]?.service) return null;

	return error === undefined ? (
		<Grid container item>
			{config && config.values ? (
				<Property
					config={config}
					onConfig={handleConfigChange}
					definition={definitions[selectedService]}
					setDisabled={setSaveDisabled}
					saveDisabled={saveDisabled}
				/>
			) : null}
			<Grid container>
				<Grid item>
					<Button
						onClick={handleApply}
						disabled={saveDisabled || isDisabled || loading}
					>
						Apply
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={handleDefault}
						disabled={isDefaultDisabled() || loading}
					>
						Default
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={handleCancel}
						disabled={isDisabled || loading}
					>
						Cancel
					</Button>
				</Grid>
				{loading ? (
					<Grid item>
						<CircularProgress />
					</Grid>
				) : null}
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<div>
					<Alert
						onClose={handleCloseSnackbar}
						alert={alert as IAlert}
					/>
				</div>
			</Snackbar>
		</Grid>
	) : (
		<Redirect
			to={{
				pathname: "/vms",
			}}
		/>
	);
};

export default ConfigurationView;

export interface IConfigurationViewPropsToDispatch {
	loadProperties: () => void;
	loadDefinitions: () => void;
}

export interface IConfigurationViewPropsToState {
	properties: IConfigurationProperty[];
	definitions: IDefinition[];
	error?: HttpError;
}
