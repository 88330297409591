import {
	makeStyles,
	Box,
	Typography,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { THEME } from "../../../config";
import { IFavorite } from "./Favorites";
import _ from "lodash";
import { hiddenFilterKeys } from "./FiltersBar";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { EventsFilter } from "../../../store/Events/types";
import Chips from "./Chips";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		width: "100%",
		gap: 4,
		padding: 5,
		borderRadius: 4,
		border: `2px solid ${THEME.palette.secondary.light}`,
		backgroundColor: THEME.palette.grey[300],
		color: THEME.palette.primary.dark,
	},
	paragraph: {
		margin: 0,
	},
	controls: {
		display: "flex",
		gap: 4,
	},
	saveBtn: {
		backgroundColor: THEME.palette.secondary.light,
		color: THEME.palette.grey[200],
		padding: "2px 10px",
		minWidth: "unset",
		"&:hover": {
			backgroundColor: THEME.palette.primary.light,
		},
		fontSize: 12,
	},
	deleteIcon: {
		transition: "all 0.1s linear",
		color: "#B60000",
		"&:hover": {
			transform: "scale(1.2)",
		},
	},
	editIcon: {
		color: THEME.palette.primary.contrastText,
		transition: "all 0.1s linear",
		"&:hover": {
			transform: "scale(1.2)",
		},
	},
	main: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: 30,
		minWidth: 400,
	},
	selected: {
		border: `2px solid ${THEME.palette.success.main}`,
	},
	tooltip: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: 4,
	},
	chips: {
		paddingTop: 8,
		borderTop: `1px solid ${THEME.palette.primary.main}`,
		display: "flex",
		justifyContent: "center",
		gap: 4,
		flexWrap: "wrap",
		width: "100%",
	},
}));

type Props = {
	history: IFavorite;
	deleteHistory: (history: IFavorite) => void;
	addToFavorites: (history: IFavorite) => void;
	selected: boolean;
	onFilter: (filter: EventsFilter) => void;
};

const HistoryCard = ({
	history,
	deleteHistory,
	addToFavorites,
	selected,
	onFilter,
}: Props) => {
	const classes = useStyles();
	const userConfig = useSelector((state: AppState) => state.userConfig.data);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState(history.name);

	const ref = useRef<HTMLSpanElement | null>(null);

	const openDialog = () => {
		setIsDialogOpen(true);
	};

	const closeDialog = () => {
		setIsDialogOpen(false);
	};

	const handleSave = useCallback(() => {
		if (name.length < 1) return setError("Name is required");
		if (
			userConfig.favorite.find(
				(fav) => fav.name.toLowerCase() === name.toLowerCase(),
			)
		) {
			return setError("Name already exists");
		}
		addToFavorites({
			...history,
			name,
		});
		closeDialog();
	}, [name, userConfig?.favorite, history, addToFavorites]);

	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (e.key === "Enter" && isDialogOpen) {
				e.preventDefault();
				handleSave();
			}
		};
		window.addEventListener("keydown", listener);
		return () => window.removeEventListener("keydown", listener);
	}, [handleSave, isDialogOpen]);

	return (
		<Box className={`${classes.root} ${selected ? classes.selected : ""}`}>
			<Box className={classes.main}>
				<Typography
					ref={ref}
					style={{ whiteSpace: "pre-wrap", fontSize: 14 }}
				>
					{history.name}
				</Typography>
				<Box className={classes.controls}>
					<Button
						size="small"
						className={classes.saveBtn}
						onClick={() => onFilter(history.filter)}
					>
						Use
					</Button>
					<Button
						size="small"
						className={classes.saveBtn}
						onClick={openDialog}
					>
						Save
					</Button>
					<IconButton
						size="small"
						className={classes.deleteIcon}
						onClick={() => deleteHistory(history)}
					>
						<DeleteRounded />
					</IconButton>
				</Box>
			</Box>
			<Box className={classes.chips}>
				{Object.keys(_.omit(history.filter, hiddenFilterKeys))
					.length === 0 ? (
					<Typography variant="body1">No filters applied</Typography>
				) : (
					<Chips
						filter={history.filter}
						chipStyles={{
							width: "48%",
						}}
						disablePortal
					/>
				)}
			</Box>
			<Dialog open={isDialogOpen} onClose={closeDialog}>
				<DialogTitle>Save as favorite</DialogTitle>
				<DialogContent className={classes.content}>
					<TextField
						value={name}
						variant="outlined"
						label="Name"
						onFocus={(e) => {
							e.target.select();
							e.target.setSelectionRange(0, 9999);
						}}
						autoFocus
						error={Boolean(error)}
						helperText={error}
						onChange={(e) => {
							if (error) setError("");
							setName(e.target.value);
						}}
					/>
					<Box>
						<Typography variant="h6">Filters</Typography>
						<Box className={classes.chips}>
							{Object.keys(
								_.omit(history.filter, hiddenFilterKeys),
							).length === 0 ? (
								<Typography variant="body1">
									No filters applied
								</Typography>
							) : (
								<Chips filter={history.filter} />
							)}
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleSave}>Save</Button>
					<Button onClick={() => closeDialog()}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default HistoryCard;
