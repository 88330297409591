import React from "react";
import { makeStyles, Grid, Button, Popover } from "@material-ui/core";
import { RuleMqttAction } from "../../../../../store/Rules/types";
import ActionsCheckboxes from "./ActionsCheckboxes";

const useStyles = makeStyles(() => ({
	popover: {
		width: "60%",
	},
	popoverContainer: {
		paddingTop: 10,
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
}));

const defaultAction: RuleMqttAction = {
	type: "MqttAction",
	sendWithTrackEvent: true,
	sendWithDisappearedEvent: true,
};

interface IMqttPopoverProps {
	onUpdate(action: RuleMqttAction): void;
	onCancel: () => void;
	mqttAction?: RuleMqttAction;
	anchorEl: null | HTMLElement;
}

const MqttPopover: React.FC<IMqttPopoverProps> = ({
	onUpdate,
	onCancel,
	mqttAction,
	anchorEl,
}) => {
	const classes = useStyles();

	const [action, setAction] = React.useState<RuleMqttAction>(
		mqttAction ? mqttAction : defaultAction,
	);

	React.useEffect(() => {
		setAction(mqttAction ? mqttAction : defaultAction);
	}, [mqttAction, anchorEl]);

	const handleConfirm = () => {
		onUpdate(action);
		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleTrackChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithTrackEvent = value;
		setAction(changedAction);
	};

	const handleDisappearedChange = (value: boolean) => {
		const changedAction = { ...action };
		changedAction.sendWithDisappearedEvent = value;
		setAction(changedAction);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			className={classes.popover}
		>
			<Grid container className={classes.popoverContainer}>
				<ActionsCheckboxes
					onTrack={handleTrackChange}
					onDisappeared={handleDisappearedChange}
					trackCheck={action.sendWithTrackEvent}
					disCheck={action.sendWithDisappearedEvent}
				/>
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button
							onClick={handleConfirm}
							disabled={
								!action.sendWithTrackEvent &&
								!action.sendWithDisappearedEvent
							}
						>
							{mqttAction ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default MqttPopover;
