import theme from "./Theme";

export const DRAWER_WIDTH = 250;
export const DRAWER_WIDTH_COLLAPSED = 57;
export const THEME = theme;
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_WITH_MS = "YYYY-MM-DD HH:mm:ss.SSS";
export const DATE_FORMAT = "YYYY-MM-DD";
export const LINE_LIMIT = 5;
export const SYMBOL_LIMIT = 15;
export const WATCHLIST_DESCRIPTION_LIMIT = 450;
