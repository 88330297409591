import React from "react";
import { Paper, Grid, makeStyles } from "@material-ui/core";
import { Trigger } from "../../../../store/Rules/types";
import { IWatchlist } from "../../../../store/Watchlists/types";
import { ISource } from "../../../../store/Sources/types";
import AddTriggerChip from "./AddTriggerChip";

const useStyles = makeStyles(() => ({
	paper: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
	},
}));

interface IEmptyTriggerProps {
	addTrigger(trigger: Trigger): void;
	watchlists: { [key: string]: IWatchlist };
	sources: ISource[];
}

const EmptyTrigger: React.FC<IEmptyTriggerProps> = ({
	addTrigger,
	watchlists,
	sources,
}) => {
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Paper square className={classes.paper}>
					<AddTriggerChip
						addTrigger={addTrigger}
						watchlists={watchlists}
						sources={sources}
						multiOp="Or"
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default EmptyTrigger;
