import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	IElasticHit,
	EventsFilter,
	MatchProps,
} from "../../store/Events/types";
import { HttpError } from "../../config/types";
import { Box, makeStyles } from "@material-ui/core";
import { EventsList } from "../../components/Events/EventsList";
import { EventStream } from "../../components/Events/Stream/EventStream";
import "../../components/Events/resizableStyles.css";
import EventDetails from "../../components/Events/Event/EventDetails";
import FiltersDrawer from "../../components/Events/Filter/FiltersDrawer";
import { IConfig, IConfigState } from "../../store/UserConfig/types";
import moment from "moment";
import FiltersBar, {
	hiddenFilterKeys,
} from "../../components/Events/Filter/FiltersBar";
import { IFavorite } from "../../components/Events/Filter/Favorites";
import _ from "lodash";
import CenteredLoading from "../../components/UI/CenteredLoading";
import { getItemAction } from "../../store/Subjects/action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateEventAction } from "../../store/Events/action";
import { VirtuosoHandle } from "react-virtuoso";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: 15,
		paddingRight: 0,
		minHeight: "100vh",
	},
	main: {
		display: "flex",
		flex: 5,
		maxHeight: "100vh",
		flexDirection: "column",
		gap: 6,
		padding: "16px 0",
		minWidth: "33%",
	},
}));

export interface IEventsViewDispatchProps {
	loadEvents: (filter: EventsFilter, excludes?: string[]) => Promise<void>;
	loadConfig: () => Promise<void>;
	addConfig: (config: IConfig) => Promise<void>;
	deleteConfig: (config: IConfig) => Promise<void>;
	updateConfig: (config: IConfig, updateName?: boolean) => Promise<void>;
	scrollEvents: (
		filter: EventsFilter,
		from: number,
		size?: number,
	) => Promise<void>;
}

export interface IEventsViewStateProps {
	events: IElasticHit[] | undefined;
	config: IConfigState;
	error?: HttpError;
	status: string;
}

type IEventsViewProps = IEventsViewDispatchProps & IEventsViewStateProps;

const EventsView: React.FC<IEventsViewProps> = ({
	config,
	events,
	loadEvents,
	loadConfig,
	addConfig,
	deleteConfig,
	scrollEvents,
	updateConfig,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [filter, setFilter] = useState<EventsFilter>(
		config.data.history[0]?.filter ?? {},
	);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingMatches, setIsLoadingMatches] = useState<boolean>(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

	const subjects = useSelector((state: AppState) => state.subjects);

	const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
	const [streamingEvent, setStreamingEvent] = useState<IElasticHit | null>(
		null,
	);
	const [backwardEvents, setBackwardEvents] = useState<IElasticHit[]>([]);
	const list = useRef<VirtuosoHandle>(null);

	const handleHistoryUpdate = useCallback(
		(filter: EventsFilter) => {
			if (
				_.isEqual(
					_.omit(config.data.history[0]?.filter, hiddenFilterKeys),
					_.omit(filter, hiddenFilterKeys),
				)
			)
				return;
			if (_.isEqual(_.omit(filter, hiddenFilterKeys), {})) {
				const emptyFilter = config.data.history.find(
					(historyItem: IFavorite) =>
						_.isEqual(
							_.omit(historyItem.filter, hiddenFilterKeys),
							{},
						),
				);

				if (emptyFilter) {
					updateConfig(
						{
							variant: "history",
							config: emptyFilter,
						},
						true,
					);
				} else {
					addConfig({
						variant: "history",
						config: {
							name: moment().format("DD.MM.YYYY HH:mm:ss"),
							id: moment().format("DDMMYYYYHHmmss"),
							filter,
						},
					});
				}
				return;
			}
			if (
				config.data.history.some((historyItem) =>
					_.isEqual(
						_.omit(historyItem.filter, hiddenFilterKeys),
						_.omit(filter, hiddenFilterKeys),
					),
				)
			) {
				const oldHistory = config.data.history.find((historyItem) =>
					_.isEqual(
						_.omit(historyItem.filter, hiddenFilterKeys),
						_.omit(filter, hiddenFilterKeys),
					),
				);

				if (oldHistory) {
					return updateConfig(
						{
							variant: "history",
							config: oldHistory,
						},
						true,
					);
				}
			}
			addConfig({
				variant: "history",
				config: {
					name: moment().format("DD.MM.YYYY HH:mm:ss"),
					id: moment().format("DDMMYYYYHHmmss"),
					filter,
				},
			});
		},
		[config, addConfig, updateConfig],
	);

	const handleLoadEvents = useCallback(
		(filter: EventsFilter) => {
			setIsLoading(true);
			loadEvents(filter).finally(() => {
				setIsLoading(false);
			});
		},
		[loadEvents],
	);

	const onFilter = (newFilter: EventsFilter) => {
		const cleanFilter = newFilter as { [key: string]: any };
		Object.keys(cleanFilter).forEach((key) => {
			if (!cleanFilter[key] && cleanFilter[key] !== 0)
				delete cleanFilter[key];
			if (
				typeof cleanFilter[key] === "object" &&
				Object.keys(cleanFilter[key]).length === 0
			)
				delete cleanFilter[key];
			if (
				Array.isArray(cleanFilter[key]) &&
				cleanFilter[key].length === 0
			)
				delete cleanFilter[key];
			if (
				!newFilter?.licensePlateValue &&
				!newFilter?.matchId &&
				newFilter?.exactId
			)
				delete cleanFilter.exactId;
		});
		setIsLoading(true);
		if (!_.isEqual(cleanFilter, filter)) {
			handleHistoryUpdate(cleanFilter);
			if (config.data.history?.length >= 10) {
				deleteConfig({
					variant: "history",
					config: config.data.history[config.data.history.length - 1],
				});
			}
		}
		setFilter(cleanFilter);
		scrollToTop();
		loadEvents(cleanFilter).finally(() => {
			setIsLoading(false);
		});
	};

	const scrollToTop = () => {
		if (list.current)
			list.current.scrollTo({
				top: 0,
			});
	};

	const onScrollHandler = async (from: number) => {
		await scrollEvents(filter, from);
	};

	const loadMatch = useCallback(
		async (match: MatchProps) =>
			getItemAction(
				match.watchlist,
				match.id,
				match.secret ?? "",
			)(dispatch),
		[dispatch],
	);

	useEffect(() => {
		if (isLoadingMatches || !events) return;
		const matchesToLoad: MatchProps[] = [];

		const eventsWithMatches = events.filter(
			(event) => event._source.best?.matches?.length > 0,
		);

		if (eventsWithMatches.length === 0) return;

		eventsWithMatches.forEach((event) => {
			const matches: MatchProps[] = event._source.best.matches.slice(
				0,
				3,
			);
			if (!matches) return;

			matches.forEach((match) => {
				const matchIsLoading = matchesToLoad.find(
					(m) => m.id === match.id && m.watchlist === match.watchlist,
				);
				const matchIsLoaded = subjects.keys[
					match.watchlist
				]?.content.find((s) => s.name === match.id);
				if (matchIsLoading || matchIsLoaded) return;

				matchesToLoad.push(match);
			});
		});

		if (matchesToLoad.length === 0) return;

		setIsLoadingMatches(true);
		Promise.all(matchesToLoad.map(loadMatch)).finally(() =>
			setIsLoadingMatches(false),
		);
	}, [events, loadMatch, subjects.keys, isLoadingMatches]);

	useEffect(() => {
		if (!config.loaded) loadConfig();
		else if (isFirstLoad) {
			handleLoadEvents(config.data.history[0]?.filter ?? {});
			setFilter(config.data.history[0]?.filter ?? {});
			setIsFirstLoad(false);
		}
	}, [loadConfig, config, isFirstLoad, handleLoadEvents]);

	if (isFirstLoad) return <CenteredLoading height={100} column />;

	return (
		<div
			className={classes.root}
			style={{ paddingRight: isDrawerOpen ? 0 : 16 }}
		>
			<EventsList
				events={events ?? []}
				setBackwardEvents={setBackwardEvents}
				onScrollHandler={onScrollHandler}
				selectedEvent={
					[...(events ?? []), ...backwardEvents].find(
						(event) => event._id === selectedEventId,
					) ?? null
				}
				setSelectedEventId={(value) => {
					if (value !== selectedEventId) {
						setStreamingEvent(
							[...(events ?? []), ...backwardEvents].find(
								(event) => event._id === value,
							) ?? null,
						);
						setSelectedEventId(value);
					}
				}}
				innerRef={list}
				isLoading={isLoading}
				isLoadingMatches={isLoadingMatches}
				loadEvents={handleLoadEvents}
				filter={filter}
				loadMatch={loadMatch}
			/>
			<Box className={classes.main}>
				<FiltersBar
					filter={filter}
					onFilter={onFilter}
					setIsOpen={setIsDrawerOpen}
					isDrawerOpen={isDrawerOpen}
				/>
				<EventStream event={streamingEvent} />
				<EventDetails
					event={
						[...(events ?? []), ...backwardEvents].find(
							(event) => event._id === selectedEventId,
						)?._source ?? null
					}
					filter={filter}
					loadMatch={loadMatch}
					updateEvent={(eventSource) => {
						const event = events?.find(
							(event) => event._id === selectedEventId,
						);
						if (!event) return;

						dispatch(
							updateEventAction({
								...event,
								_source: eventSource,
							}),
						);
					}}
				/>
			</Box>
			<FiltersDrawer
				onFilter={onFilter}
				scrollToTop={scrollToTop}
				filter={filter}
				isOpen={isDrawerOpen}
				setIsOpen={setIsDrawerOpen}
			/>
		</div>
	);
};

export default EventsView;
