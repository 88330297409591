import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { IRulesState, RulesDataContainer } from "./types";
import { RulesAction } from "./action";

export const initialRulesState: IRulesState = {
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	paging: {},
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleAsync = (
	state: IRulesState = initialRulesState,
	action: RulesAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				const payload = action.payload as RulesDataContainer;
				nState.data.content = [
					...nState.data.content,
					...payload.content,
				].filter(
					(v, i, a) =>
						a.findIndex(
							(t) => t.name === v.name && v.vms === t.vms,
						) === i,
				);

				nState.keys[action.meta] = {
					...payload,
					content: payload.content.filter(
						(v, i, a) =>
							a.findIndex(
								(t) => t.name === v.name && v.vms === t.vms,
							) === i,
					),
				};
				if (!nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						secret: undefined,
						phrase: undefined,
						page: 0,
						size: 10,
					};
				}
			}
			return nState;
	}
	return state;
};

const handleFilter = (
	state: IRulesState = initialRulesState,
	action: RulesAction,
) => {
	if (action.type === "RULES_FILTER") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			if (action.meta) {
				if (nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						...nState.paging[action.meta],
						...action.payload,
					};
				} else {
					nState.paging[action.meta] = {
						secret: undefined,
						phrase: "",
						page: 0,
						size: 10,
						...action.payload,
					};
				}
				return nState;
			}
		}
	}
	return state;
};

export const RulesReducer: Reducer<IRulesState, RulesAction> = (
	state = initialRulesState,
	action: RulesAction,
) => {
	switch (action.type) {
		case "RULES":
			return handleAsync(state, action);
		case "RULES_FILTER":
			return handleFilter(state, action);
		default:
			return state;
	}
};
