import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { ISubject, ISubjectsState, SubjectsDataContainer } from "./types";
import { SubjectsAction } from "./action";

export const initialSubjectsState: ISubjectsState = {
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
	},
	keys: {},
	paging: {},
	requestStatus: {},
	status: AsyncActionStatus.UNSTARTED,
};

const handleSubjects = (
	state: ISubjectsState = initialSubjectsState,
	action: SubjectsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				nState.keys[action.meta] =
					action.payload as SubjectsDataContainer;
				if (!nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						find: undefined,
						page: 0,
						size: 20,
					};
				}
			}
			return nState;
	}
	return state;
};

const hanldeFilter = (
	state: ISubjectsState = initialSubjectsState,
	action: SubjectsAction,
) => {
	if (action.type === "SUBJECT_UPDATE_FILTER") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			if (action.meta) {
				if (nState.paging[action.meta]) {
					nState.paging[action.meta] = {
						...nState.paging[action.meta],
						...action.payload,
					};
				} else {
					nState.paging[action.meta] = {
						find: undefined,
						page: 0,
						size: 20,
						...action.payload,
					};
				}
				return nState;
			}
		}
	}
	return state;
};

const handleUpdate = (
	state: ISubjectsState = initialSubjectsState,
	action: SubjectsAction,
) => {
	if (action.type === "SUBJECT_UPDATE") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as ISubject;
			if (action.meta) {
				const container = nState.keys[action.meta];
				if (container) {
					const idx = container.content.findIndex(
						(i) => i.name === payload.name,
					);
					if (idx > -1) {
						container.content[idx] = payload;
					}
					nState.keys[action.meta] = container;
				}
				return nState;
			}
		}
	}
	return state;
};

const handleSubject = (
	state: ISubjectsState = initialSubjectsState,
	action: SubjectsAction,
) => {
	if (action.type === "SUBJECT") {
		const nState = { ...state };
		if (action.status === "SUCCEEDED") {
			const payload = action.payload as ISubject;
			if (action.meta) {
				const container = nState.keys[action.meta];
				if (container) {
					const subjectIdx = container.content.findIndex(
						(s) => s.name === payload.name,
					);
					if (subjectIdx > -1) {
						container.content[subjectIdx] = payload;
					} else {
						container.content.push(payload);
					}
					nState.keys[action.meta] = container;
				} else {
					nState.keys[action.meta] = {
						content: [payload],
						number: 1,
						numberOfElements: 1,
						size: 1,
						totalElements: 1,
						totalPages: 1,
					};
				}
				nState.data.content = [...nState.data.content, payload].filter(
					(v, i, a) => a.findIndex((t) => t.name === v.name) === i,
				);
				return nState;
			}
		}
	}
	return state;
};

export const SubjectsReducer: Reducer<ISubjectsState, SubjectsAction> = (
	state = initialSubjectsState,
	action: SubjectsAction,
) => {
	switch (action.type) {
		case "SUBJECTS":
			return handleSubjects(state, action);
		case "SUBJECT_UPDATE_FILTER":
			return hanldeFilter(state, action);
		case "SUBJECT_UPDATE":
			return handleUpdate(state, action);
		case "SUBJECT":
			return handleSubject(state, action);
		default:
			return state;
	}
};
