import React from "react";
import {
	createTheme,
	Slider as MUISlider,
	makeStyles,
	ThemeProvider,
} from "@material-ui/core";
type Props = {
	value: number | number[];
	onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
	onChangeCommitted?: (
		event: React.ChangeEvent<{}>,
		value: number | number[],
	) => void;
	splitSlider?: boolean;
	selected?: boolean;
	reverse?: boolean;
	max?: number;
};

const useStyles = makeStyles(() => ({
	disabledRail: {
		backgroundColor: "#BFBFBF",
		color: "#BFBFBF",
	},
	disabledRoot: {
		pointerEvents: "none",
	},
	sliderIndicator: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	splitRail: {
		"&::before": {
			content: '"Yes"',
			position: "absolute",
			left: "20%",
			top: "-20px",
		},
		"&::after": {
			content: '"No"',
			position: "absolute",
			right: "25%",
			top: "-20px",
			color: "red",
		},
	},
	reverseSplitRail: {
		"&::before": {
			content: '"Yes"',
			position: "absolute",
			left: "20%",
			top: "-20px",
		},
		"&::after": {
			content: '"No"',
			position: "absolute",
			right: "25%",
			top: "-20px",
			color: "red",
		},
		backgroundColor: "red",
	},
	reverseRail: {
		backgroundColor: "red",
	},
	reverseTrack: {
		backgroundColor: "green",
	},
	splitRoot: {
		"&::before": {
			backgroundColor: "#BFBFBF",
			content: '""',
			height: "80%",
			width: 1,
			position: "absolute",
			left: "50%",
			bottom: "50%",
			transform: "translate(-50%, 50%)",
		},
	},
}));

const theme = createTheme({
	direction: "rtl",
	overrides: {
		MuiSlider: {
			root: { width: "100%" },
			thumb: {
				height: 15,
				width: 15,
				backgroundColor: "white",
				transform: "translate(7px, -1px)",
				boxShadow:
					"0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			},
			valueLabel: {
				display: "none",
			},
			track: {
				backgroundColor: "red",
				borderRadius: 10,
				border: "none",
				height: "4px",
			},
			rail: {
				color: "green",
				borderRadius: 10,
				opacity: 1,
				height: "4px",
				fontSize: "12px",
			},
		},
	},
});

const Slider = ({
	value,
	onChange,
	onChangeCommitted,
	splitSlider,
	selected = true,
	reverse,
	max,
}: Props) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={theme}>
			<MUISlider
				max={max}
				value={value}
				onChange={onChange}
				onChangeCommitted={onChangeCommitted}
				valueLabelDisplay="auto"
				classes={{
					...(splitSlider && {
						rail: classes.splitRail,
						root: classes.splitRoot,
					}),
					...(!selected && {
						root: classes.disabledRoot,
						rail: classes.disabledRail,
						track: classes.disabledRail,
					}),
					...(reverse && {
						rail: splitSlider
							? classes.reverseSplitRail
							: classes.reverseRail,
						track: classes.reverseTrack,
					}),
				}}
			/>
		</ThemeProvider>
	);
};

export default Slider;
