import React from "react";
import { Box, Typography, Divider, makeStyles } from "@material-ui/core";
import { ISecret } from "../../store/Secrets/types";

const useStyles = (isClicked: boolean) =>
	makeStyles(() => ({
		description: {
			overflow: "auto",
			paddingRight: 10,
		},
		name: {
			marginLeft: 15,
			marginRight: 15,
			marginTop: 10,
			marginBottom: 5,
		},
		nameText: {
			marginLeft: 5,
			marginBottom: 0,
		},
		box: {
			paddingTop: 2,
			backgroundColor: isClicked ? "rgba(224, 224, 224, 1)" : "white",
			"&:hover": {
				backgroundColor: isClicked
					? "rgba(224, 224, 224, 1)"
					: "#f3f3f4",
			},
		},
		descBox: {
			marginLeft: 15,
			marginRight: 15,
		},
		divider: {
			marginTop: 5,
			marginBottom: 5,
		},
	}));

interface IDisplaySecretProps {
	secret: ISecret;
	onSecretClick(secret: ISecret): void;
	onDblClick(id: string): void;
	border: string | undefined;
}

const DisplaySecret: React.FC<IDisplaySecretProps> = ({
	secret,
	onSecretClick,
	onDblClick,
	border,
}) => {
	const classes = useStyles(border === secret.id)();

	const getHeight = (desc: string) => {
		if (desc.length > 255) return 90;
		const lineAmount = desc.split(/\r\n|\r|\n/);
		if (lineAmount.length >= 15) {
			return 180;
		}
		return lineAmount.length * 20;
	};

	const formatDescription = (desc: string) => {
		const descArr = desc.split("\n");
		return (
			<>
				{descArr.map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
				))}
			</>
		);
	};

	return (
		<Box className={classes.box}>
			<div onClick={() => onSecretClick(secret)}>
				<div onDoubleClick={() => onDblClick(secret.id)}>
					<Box className={classes.name}>
						<Typography
							variant="h5"
							gutterBottom
							className={classes.nameText}
						>
							{secret.name}
						</Typography>
					</Box>
					<Box className={classes.descBox}>
						<Typography
							className={classes.description}
							variant="body2"
							gutterBottom
							style={{
								maxHeight:
									secret.description !== null
										? getHeight(secret.description)
										: 90,
							}}
						>
							{secret.description !== null
								? formatDescription(secret.description)
								: null}
						</Typography>
					</Box>
					<Divider className={classes.divider} variant="middle" />
				</div>
			</div>
		</Box>
	);
};

export default DisplaySecret;
