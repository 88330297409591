import React from "react";
import {
	makeStyles,
	Chip,
	Popover,
	Grid,
	Button,
	Typography,
	Switch,
	Input,
	MenuItem,
	Select,
	Checkbox,
	Divider,
	Tooltip,
	FormControl,
} from "@material-ui/core";
import {
	FacesWatchlistTrigger,
	IWatchlistTriggerValue,
	WatchlistTrigger,
} from "../../../../../store/Rules/types";
import { THEME } from "../../../../../config/index";
import { IWatchlist } from "../../../../../store/Watchlists/types";
import { limitDescription } from "../../../../Tasks/Filter/TaskFiltering";
import { SupervisorAccount } from "@material-ui/icons";
import { LicensePlateIcon } from "../../../../Icons/Icons";
import Slider from "../../../../UI/Slider";

const useStyles = makeStyles(() => ({
	chip: {
		margin: THEME.spacing(0.5),
		height: "fit-content",
		width: "fit-content",
		padding: "6px 4px",
	},
	popoverRow: {
		paddingLeft: 17.5,
		paddingRight: 17.5,
	},
	popoverContainer: {
		paddingTop: 10,
		maxWidth: 500,
	},
	inButton: {
		border: "1px solid",
	},
	tag: {
		display: "flex",
		alignItems: "center",
		gap: 2,
		borderBottom: `1px solid ${THEME.palette.grey[600]}`,
		paddingTop: 1,
	},
	row: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		gap: 6,
	},
}));

function addWatchlists(values: IWatchlistTriggerValue[], tagClass?: string) {
	return values.map((value, index) => (
		<span className={tagClass} key={index}>
			{value.id}
		</span>
	));
}

export const getWatchlistChipLabel = (
	trigger: WatchlistTrigger,
	tagClass?: string,
	rowClass?: string,
) => {
	return (
		<span className={rowClass}>
			{!trigger.in && trigger.values.length === 0 && "In Any Watchlist"}
			{trigger.in &&
				trigger.values.length === 0 &&
				"Not In Any Watchlist"}
			{trigger.in &&
				trigger.values.length > 0 &&
				"In (" + trigger.values.length + ") watchlists: "}
			{!trigger.in &&
				trigger.values.length > 0 &&
				"Not In (" + trigger.values.length + ") watchlists: "}
			{addWatchlists(trigger.values, tagClass)}
			{trigger.type === "FacesWatchlistTrigger" &&
				trigger.score &&
				"and Score ≥ " + trigger.score}
		</span>
	);
};

const getWatchlistsTooltip = (trigger: WatchlistTrigger) => {
	const tempArr: string[] = [];
	for (let i = 0; i < trigger.values.length; i++) {
		tempArr.push(trigger.values[i].id);
	}
	return tempArr.join(", ");
};

interface IWatchlistTriggerComponentProps {
	trigger: WatchlistTrigger;
	updateTrigger(trigger?: WatchlistTrigger): void;
	watchlists: { [key: string]: IWatchlist };
	isReadOnly: boolean;
	option: "Faces" | "License Plates";
	showOnly?: boolean;
}

interface IWatchlistTriggerPopoverProps {
	anchorEl: null | HTMLElement;
	onUpdate(trigger: WatchlistTrigger): void;
	onCancel(): void;
	watchlistTrigger?: WatchlistTrigger;
	watchlists: { [key: string]: IWatchlist };
	option: "Faces" | "License Plates";
}

const WatchlistTriggerComponent: React.FC<IWatchlistTriggerComponentProps> = ({
	trigger,
	updateTrigger,
	watchlists,
	isReadOnly,
	option,
	showOnly,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const classes = useStyles();

	const handleDelete = () => {
		updateTrigger(undefined);
	};

	const handleOpenPopover = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => setAnchorEl(event.currentTarget);

	const handleUpdate = (trigger: WatchlistTrigger) => {
		updateTrigger(trigger);
	};

	return (
		<>
			<Chip
				icon={
					trigger.type === "FacesWatchlistTrigger" ? (
						<SupervisorAccount />
					) : (
						<LicensePlateIcon />
					)
				}
				label={
					<Tooltip title={getWatchlistsTooltip(trigger)}>
						<div>
							{getWatchlistChipLabel(
								trigger,
								classes.tag,
								classes.row,
							)}
						</div>
					</Tooltip>
				}
				onClick={handleOpenPopover}
				onDelete={showOnly || isReadOnly ? undefined : handleDelete}
				className={classes.chip}
				disabled={isReadOnly}
			/>
			<WatchlistTriggerPopover
				watchlistTrigger={trigger}
				anchorEl={anchorEl}
				onUpdate={handleUpdate}
				onCancel={() => setAnchorEl(null)}
				watchlists={watchlists}
				option={option}
			/>
		</>
	);
};

const getWatchlistArray = (
	watchlists: {
		[key: string]: IWatchlist;
	},
	type: "Faces" | "License Plates",
): IWatchlist[] => {
	const wlType: "Faces" | "LicensePlates" =
		type === "Faces" ? type : "LicensePlates";

	const arr = Object.values(watchlists).filter((wl) => wl.type === wlType);

	arr.unshift({
		id: "(Any Watchlist)",
		description: "",
		type: wlType,
	});
	return arr;
};

const getWatchlistsFromTriggerValues = (
	values: IWatchlistTriggerValue[],
): string[] => {
	const newArr: string[] = [];
	if (values.length === 0) {
		newArr.push("(Any Watchlist)");
	}
	for (let i = 0; i < values.length; i++) {
		newArr.push(values[i].id);
	}
	return newArr;
};

const findSecret = (id: string, watchlists: IWatchlist[]): string | null => {
	let secret = null;
	for (let i = 0; i < watchlists.length; i++) {
		if (watchlists[i].secret && id === watchlists[i].id) {
			secret = watchlists[i].secret!;
		}
	}
	return secret;
};

const getWatchlistTriggerValues = (
	selectedWatchlists: string[],
	allWatchlists: IWatchlist[],
): IWatchlistTriggerValue[] => {
	if (selectedWatchlists.includes("(Any Watchlist)")) {
		return [];
	}
	const newArr: IWatchlistTriggerValue[] = [];
	for (let i = 0; i < selectedWatchlists.length; i++) {
		const secretFinder = findSecret(selectedWatchlists[i], allWatchlists);
		newArr.push({
			id: selectedWatchlists[i],
			secret: secretFinder,
		});
	}
	return newArr;
};

const getDefaultTrigger = (
	option: "Faces" | "License Plates",
): WatchlistTrigger => {
	return {
		type:
			option === "Faces"
				? "FacesWatchlistTrigger"
				: "LicensePlateWatchlistTrigger",
		score: null,
		in: true,
		values: [],
	};
};

export const WatchlistTriggerPopover: React.FC<
	IWatchlistTriggerPopoverProps
> = ({
	anchorEl,
	onUpdate,
	onCancel,
	watchlistTrigger,
	watchlists,
	option,
}) => {
	const classes = useStyles();
	const [trigger, setTrigger] = React.useState<WatchlistTrigger>(
		watchlistTrigger
			? {
					...watchlistTrigger,
					in:
						watchlistTrigger.values.length > 0
							? watchlistTrigger.in
							: !watchlistTrigger.in,
			  }
			: getDefaultTrigger(option),
	);
	const [selectedWatchlists, setSelectedWatchlists] = React.useState<
		string[]
	>(
		watchlistTrigger
			? getWatchlistsFromTriggerValues(watchlistTrigger.values)
			: ["(Any Watchlist)"],
	);
	const [selectTooltipOpen, setSelectTooltipOpen] = React.useState(false);

	React.useEffect(() => {
		setTrigger(
			watchlistTrigger
				? {
						...watchlistTrigger,
						in:
							watchlistTrigger.values.length > 0
								? watchlistTrigger.in
								: !watchlistTrigger.in,
				  }
				: getDefaultTrigger(option),
		);
		setSelectedWatchlists(
			watchlistTrigger
				? getWatchlistsFromTriggerValues(watchlistTrigger.values)
				: ["(Any Watchlist)"],
		);
	}, [anchorEl, watchlistTrigger, option]);

	const convertedWatchlists = getWatchlistArray(watchlists, option);

	const handleConfirm = () => {
		const triggerValues = getWatchlistTriggerValues(
			selectedWatchlists,
			convertedWatchlists,
		);

		const inWatchlist =
			triggerValues.length === 0 ? !trigger.in : trigger.in;

		if (trigger.type === "FacesWatchlistTrigger") {
			onUpdate({
				type: trigger.type,
				score: trigger.score,
				in: inWatchlist,
				values: triggerValues,
			});
		} else {
			onUpdate({
				type: trigger.type,
				in: inWatchlist,
				values: triggerValues,
			});
		}

		handleCancel();
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleSwitch = () => {
		const changedTrigger = { ...(trigger as FacesWatchlistTrigger) };
		changedTrigger.score =
			changedTrigger.score !== null
				? null
				: watchlistTrigger &&
				  (watchlistTrigger as FacesWatchlistTrigger).score
				? (watchlistTrigger as FacesWatchlistTrigger).score
				: 48;
		setTrigger(changedTrigger);
	};

	const handleInPress = () => {
		const changedTrigger = { ...trigger };
		changedTrigger.in = !changedTrigger.in;
		setTrigger(changedTrigger);
	};

	const handleSlider = (event: any, newValue: number | number[]) => {
		const changedTrigger = { ...(trigger as FacesWatchlistTrigger) };
		changedTrigger.score = newValue as number;
		setTrigger(changedTrigger);
	};

	const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		let values = [...(event.target.value as string[])];
		if (
			selectedWatchlists[0] === "(Any Watchlist)" &&
			selectedWatchlists.length === 1
		) {
			if (values.length > 0) {
				values.shift();
			} else {
				values = ["(Any Watchlist)"];
			}
		} else if (values[values.length - 1] === "(Any Watchlist)") {
			values = ["(Any Watchlist)"];
		} else if (values.length === 0) {
			values = ["(Any Watchlist)"];
		}
		setSelectedWatchlists(values);
	};

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleCancel}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<Grid container className={classes.popoverContainer}>
				<Grid item xs={12}>
					<Grid container className={classes.popoverRow}>
						<Grid item xs={3}>
							<Button
								onClick={handleInPress}
								size="small"
								className={classes.inButton}
							>
								{trigger.in ? "In" : "Not In"}
							</Button>
						</Grid>
						<Grid item xs={9}>
							<FormControl
								style={{ minWidth: 200, maxWidth: 200 }}
							>
								<Tooltip
									title={selectedWatchlists.join(", ")}
									open={selectTooltipOpen}
								>
									<Select
										multiple
										value={selectedWatchlists}
										onChange={handleSelect}
										input={<Input />}
										renderValue={(selected) =>
											(selected as string[]).join(", ")
										}
										MenuProps={{ variant: "menu" }}
										onMouseEnter={() =>
											setSelectTooltipOpen(true)
										}
										onMouseLeave={() =>
											setSelectTooltipOpen(false)
										}
										onOpen={() =>
											setSelectTooltipOpen(false)
										}
									>
										{convertedWatchlists.map(
											(watchlist, i) => (
												<MenuItem
													key={i}
													value={watchlist.id}
													dense
												>
													<Checkbox
														checked={selectedWatchlists.includes(
															watchlist.id,
														)}
													/>
													<div
														style={{
															display: "inline",
															maxHeight: 300,
														}}
													>
														<b>{watchlist.id}</b>
														<br />
														{watchlist.description ? (
															<>
																Description:
																{limitDescription(
																	watchlist.description,
																)}
															</>
														) : null}
														<Divider />
													</div>
												</MenuItem>
											),
										)}
									</Select>
								</Tooltip>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				{trigger.type === "FacesWatchlistTrigger" ? (
					<Grid item xs={12}>
						<Grid container className={classes.popoverRow}>
							<Grid item xs={2}>
								<Typography>and score</Typography>
							</Grid>
							<Grid item xs={1}>
								<Typography>
									{trigger.score !== null ? "≥" : ""}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Switch
									size="small"
									checked={trigger.score !== null}
									onChange={handleSwitch}
								/>
							</Grid>
							{trigger.score !== null ? (
								<>
									<Grid item xs={1}>
										<Typography>{trigger.score}</Typography>
									</Grid>
									<Grid item xs={2}>
										<Slider
											value={trigger.score}
											onChange={handleSlider}
											max={150}
										/>
									</Grid>
								</>
							) : null}
						</Grid>
					</Grid>
				) : null}
				<Grid
					item
					container
					justifyContent="flex-end"
					className={classes.popoverRow}
				>
					<Grid item>
						<Button onClick={handleConfirm}>
							{watchlistTrigger ? "Update" : "Add"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleCancel}>Cancel</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

export default WatchlistTriggerComponent;
