import moment from "moment";

export const RELATIVE_OPTIONS = [
	{ text: "Seconds ago", value: "second" },
	{ text: "Minutes ago", value: "minute" },
	{ text: "Hours ago", value: "hour" },
	{ text: "Days ago", value: "day" },
	{ text: "Weeks ago", value: "week" },
	{ text: "Months ago", value: "month" },
	{ text: "Years ago", value: "year" },
];

export const SERVICES = [
	"Vms Service Web",
	"Vms Service",
	"Watchlist Service Web",
	"Watchlist Prepare Service",
	"Watchlist Service",
	"Backward Search Web Service",
	"Backward Search",
	"Devices Monitor",
	"Task Scheduler",
	"Stream Processor",
	"Matching Service",
	"Event Processor",
	"Event Dispatcher",
	"Event Mqtt Dispatcher",
	"Tasks Service",
	"Search Service",
	"Authorization Service",
	"ElasticSearch",
	"Elastic Reverse Proxy",
];

export enum SPECIALDATE {
	TODAY = "Today",
	YESTERDAY = "Yesterday",
	FIVEDAYS = "Five days",
	THISWEEK = "This week",
	PREVIOUSWEEK = "Previous week",
	LASTHOUR = "Last hour",
	LAST6HOUR = "Last 6 hour",
	LAST12HOUR = "Last 12 hour",
	LAST24HOUR = "Last 24 hour",
	LASTWEEK = "Last week",
	LASTTWOWEEKS = "Last two weeks",
}

export interface ITimeButtonHelper {
	label: SPECIALDATE;
	from?: { value: number; text: moment.unitOfTime.Base };
	until?: { value: number; text: moment.unitOfTime.Base };
}

export const getSpecialDateAbsolute = (
	key: SPECIALDATE,
): {
	from: string;
	until: string;
} => {
	switch (key) {
		case SPECIALDATE.LASTHOUR:
			return {
				from: moment().subtract(1, "hour").toISOString(),
				until: moment().toISOString(),
			};
		case SPECIALDATE.LAST6HOUR:
			return {
				from: moment().subtract(6, "hour").toISOString(),
				until: moment().toISOString(),
			};
		case SPECIALDATE.LAST12HOUR:
			return {
				from: moment().subtract(12, "hour").toISOString(),
				until: moment().toISOString(),
			};
		case SPECIALDATE.LAST24HOUR:
			return {
				from: moment().subtract(24, "hour").toISOString(),
				until: moment().toISOString(),
			};

		case SPECIALDATE.TODAY:
			return {
				from: moment().startOf("day").toISOString(),
				until: moment().toISOString(),
			};
		case SPECIALDATE.YESTERDAY:
			return {
				from: moment().subtract(1, "day").startOf("day").toISOString(),
				until: moment().subtract(1, "day").endOf("day").toISOString(),
			};
		case SPECIALDATE.THISWEEK:
			return {
				from: moment().startOf("isoWeek").toISOString(),
				until: moment().endOf("isoWeek").toISOString(),
			};
		case SPECIALDATE.PREVIOUSWEEK:
			return {
				from: moment()
					.subtract(1, "week")
					.startOf("isoWeek")
					.toISOString(),
				until: moment()
					.subtract(1, "week")
					.endOf("isoWeek")
					.toISOString(),
			};
		default:
			return {
				from: "",
				until: "",
			};
	}
};
export const TIMEBUTTONHELPERS: ITimeButtonHelper[] = [
	{
		from: { value: 1, text: "hour" },
		until: { value: 0, text: "hour" },
		label: SPECIALDATE.LASTHOUR,
	},
	{
		from: { value: 6, text: "hour" },
		until: { value: 0, text: "hour" },
		label: SPECIALDATE.LAST6HOUR,
	},
	{
		from: { value: 12, text: "hour" },
		until: { value: 0, text: "hour" },
		label: SPECIALDATE.LAST12HOUR,
	},
	{
		from: { value: 24, text: "hour" },
		until: { value: 0, text: "hour" },
		label: SPECIALDATE.LAST24HOUR,
	},
	{
		label: SPECIALDATE.TODAY,
	},
	{
		label: SPECIALDATE.YESTERDAY,
	},
	{
		label: SPECIALDATE.THISWEEK,
	},
	{
		label: SPECIALDATE.PREVIOUSWEEK,
	},
];

export const EXPORT_TIME_HELPERS: ITimeButtonHelper[] = [
	{
		label: SPECIALDATE.TODAY,
	},
	{
		label: SPECIALDATE.FIVEDAYS,
	},
	{
		label: SPECIALDATE.LASTWEEK,
	},
	{
		label: SPECIALDATE.LASTTWOWEEKS,
	},
];
