import {
	IconButton,
	TableCell,
	TableRow,
	Typography,
	Switch,
} from "@material-ui/core";
import React from "react";
import DisplayAuthorities from "../../config/Authorities";
import { IUser } from "../../store/Users/types";
import EditIcon from "@material-ui/icons/Edit";

interface IUsersItemProps {
	user: IUser;
	onSelect(user: IUser): void;
	handleEnableDisable(
		id: number,
		name: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	): void;
	onDisable(
		id: number,
		name: string,
		email: string,
		authorities: string[],
		enabled: boolean,
	): void;
}

const UsersItem: React.FC<IUsersItemProps> = ({
	user,
	onSelect,
	handleEnableDisable,
	onDisable,
}) => (
	<TableRow
		key={user.id}
		hover
		role="checkbox"
		onDoubleClick={() => onSelect(user)}
	>
		<TableCell style={{ width: "5%" }}>
			<b>{user.id}</b>.
		</TableCell>
		<TableCell style={{ width: "15%" }}>{user.userName}</TableCell>
		<TableCell style={{ width: "30%" }}>{user.email}</TableCell>
		<TableCell style={{ width: "30%" }}>
			{user.authorities.map((authority) => (
				<Typography
					key={authority}
					variant="caption"
					display="block"
					gutterBottom
				>
					{DisplayAuthorities.has(authority)
						? DisplayAuthorities.get(authority)
						: authority}
				</Typography>
			))}
		</TableCell>
		<TableCell
			style={{
				width: "10%",
			}}
		>
			<Typography
				style={{
					color: user.enabled ? "green" : "red",
					fontSize: "0.9rem",
				}}
				variant="caption"
				display="block"
				gutterBottom
			>
				{user.enabled ? "Active" : "Disabled"}
			</Typography>
		</TableCell>
		<TableCell
			style={{
				width: "10%",
			}}
		>
			<Switch
				checked={user.enabled}
				onChange={() => {
					if (!user.id) return;
					if (user.enabled) {
						onDisable(
							user.id,
							user.userName,
							user.email,
							user.authorities,
							!user.enabled,
						);
					} else {
						handleEnableDisable(
							user.id,
							user.userName,
							user.email,
							user.authorities,
							!user.enabled,
						);
					}
				}}
			/>
		</TableCell>
		<TableCell style={{ width: "10%" }}>
			<IconButton
				size="small"
				color="secondary"
				aria-label="edit"
				onClick={() => onSelect(user)}
			>
				<EditIcon />
			</IconButton>
		</TableCell>
	</TableRow>
);

export default UsersItem;
