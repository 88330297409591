import { ITaskHistoryState } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { HistoryAction, History } from "./action";
import { ITask } from "../Tasks/types";

export const initialTaskHistoryState: ITaskHistoryState = {
	status: AsyncActionStatus.UNSTARTED,
	keys: {},
	requestStatus: {},
};

export const historyReducer: Reducer<ITaskHistoryState, any> = (
	state = initialTaskHistoryState,
	action: HistoryAction,
) => {
	if (action.type !== History) {
		return state;
	}
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			if (action.meta) {
				nState.keys[action.meta] = action.payload as ITask[];
			}
			return nState;
	}
	return state;
};
