import { Box, Button, Snackbar, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import Alert from "../../components/UI/Alert";
import BackwardSearches from "../../components/BackwardSearch/BackwardSearches";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
	getBackwardSearchRequestsAction,
	setBackwardSearchRequestFilterAction,
} from "../../store/BackwardSearch/action";
import BackwardSearchDialog from "../../components/Dialogs/BackwardSearchDialog";

import { useHistory } from "react-router";
import useBackwardSearch from "../../hooks/useBackwardSearch";
import { getConfigAction } from "../../store/UserConfig/action";

const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 8,
		padding: "10px 0",
	},
}));

const BackwardSearchView = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { data: backwardSearches, paging } = useSelector(
		(state: AppState) => state.backwardSearch,
	);
	const userConfig = useSelector((state: AppState) => state.userConfig);

	const backwardSearchesController = useRef<AbortController | null>(null);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>();
	const [draggedImages, setDraggedImages] = useState<string[]>([]);

	const handleDrag = (e: React.DragEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setDraggedImages([]);
		setOpenDialog(true);

		const files = e.dataTransfer.files;
		if (files) {
			for (const image of files) {
				const reader = new FileReader();
				reader.onload = () => {
					const res = reader.result as string;
					setDraggedImages((prev) => [...prev, btoa(res)]);
				};
				reader.readAsBinaryString(image);
			}
		}
	};

	const loadBackwardSearches = useCallback(() => {
		setIsLoading(true);
		const controller = new AbortController();
		backwardSearchesController.current = controller;
		getBackwardSearchRequestsAction(
			paging,
			controller,
		)(dispatch).finally(() => setIsLoading(false));
	}, [paging, dispatch]);

	useEffect(() => {
		if (!openDialog && draggedImages.length > 0) setDraggedImages([]);
	}, [openDialog, draggedImages]);

	useEffect(() => {
		loadBackwardSearches();
		return () => backwardSearchesController.current?.abort();
	}, [loadBackwardSearches]);

	useEffect(() => {
		if (!userConfig.loaded) dispatch(getConfigAction());
	}, [userConfig.loaded, dispatch]);

	const { uploadProgress, canSeeSecrets, handleSearchRequest } =
		useBackwardSearch({
			isDialogOpen: openDialog,
			loadBackwardSearches,
			onComplete: (id, completedUnderTimeThreshold) => {
				if (completedUnderTimeThreshold)
					history.push(`/backwardSearch/${id}?index=0`);
			},
			setError,
			setOpenDialog,
		});

	return (
		<>
			<Box
				className={classes.container}
				onDragOver={handleDrag}
				onDragLeave={handleDrag}
				onDragEnd={handleDrag}
				onDragExit={handleDrag}
				onDrop={handleDrop}
			>
				<Button onClick={() => setOpenDialog(true)}>New search</Button>
				<BackwardSearches
					backwardSearches={{
						...backwardSearches,
						content: backwardSearches.content?.slice(
							0,
							paging.size,
						),
					}}
					loading={isLoading}
					filter={paging}
					onCheckbox={() =>
						dispatch(
							setBackwardSearchRequestFilterAction({
								...paging,
								mine: !paging.mine,
							}),
						)
					}
					onChangePage={(_, newPage) =>
						dispatch(
							setBackwardSearchRequestFilterAction({
								...paging,
								page: newPage,
							}),
						)
					}
					onChangeRows={(e) =>
						dispatch(
							setBackwardSearchRequestFilterAction({
								...paging,
								size: parseInt(e.target.value, 10),
							}),
						)
					}
					canSeeSecrets={canSeeSecrets}
					openDialog={() => setOpenDialog(true)}
					uploadProgress={uploadProgress}
				/>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={error !== undefined}
			>
				<div>
					<Alert
						alert={{
							message: error ? error.message : "",
							variant: "error",
						}}
					/>
				</div>
			</Snackbar>
			<BackwardSearchDialog
				open={openDialog}
				close={() => setOpenDialog(false)}
				onSubmit={handleSearchRequest}
				loading={uploadProgress.total > uploadProgress.loaded}
				canSeeSecrets={canSeeSecrets}
				draggedImages={draggedImages}
				uploadProgress={uploadProgress}
			/>
		</>
	);
};

export default BackwardSearchView;
