import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { base64toBlob, downloadBlob } from "../../helpers/Utils";
import { IBackwardSearchResult } from "../../store/BackwardSearch/types";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	img: {
		maxWidth: "100%",
		maxHeight: "100%",
		objectFit: "contain",
	},
	imgWrapper: {
		display: "flex",
		alignItems: "center",
		position: "relative",
	},
	canvas: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%",
		zIndex: 10,
	},
	flex: {
		flex: 1,
		display: "flex",
	},
}));

interface Props {
	setIsOpen?: (isOpen: boolean) => void;
	result: IBackwardSearchResult | undefined;
	thumbIndex: number;
	setIndex?: (index: number) => void;
	imgClassName?: string;
	rootClassName?: string;
	onImgError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
	onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
	drawSelectedOnly?: boolean;
}

const CanvasImage = ({
	result,
	setIndex,
	setIsOpen,
	thumbIndex,
	imgClassName,
	rootClassName,
	drawSelectedOnly = false,
	onImgError,
	onClick,
}: Props) => {
	const classes = useStyles();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const mainImgRef = useRef<HTMLImageElement>(null);
	const [isHorizontal, setIsHorizontal] = useState<boolean | undefined>(
		undefined,
	);
	const [maxWidth, setMaxWidth] =
		useState<React.CSSProperties["maxWidth"]>(undefined);
	const [maxHeight, setMaxHeight] =
		useState<React.CSSProperties["maxHeight"]>(undefined);

	const [, setFirstDraw] = useState(true);
	const searchImage = result?.searchImage;

	const drawRects = useCallback(
		(hoveredIndex?: number) => {
			const attrs = searchImage?.imageAttributes;
			if (!canvasRef.current || !mainImgRef.current) return;
			const canvas = canvasRef.current;
			const mainImg = mainImgRef.current;
			setMaxWidth(undefined);
			setMaxHeight(undefined);

			const horizontal = mainImg.naturalHeight < mainImg.naturalWidth;
			setIsHorizontal((prev) => {
				if (prev !== horizontal) return horizontal;
				return prev;
			});

			const newWidth =
				(mainImg.naturalWidth / mainImg.naturalHeight) * mainImg.height;
			const newHeight =
				(mainImg.naturalHeight / mainImg.naturalWidth) * mainImg.width;

			if (!Number.isNaN(newWidth)) setMaxWidth(newWidth);
			if (!Number.isNaN(newHeight)) setMaxHeight(newHeight);

			const ctx = canvas.getContext("2d");
			if (!ctx) return;
			const { width: cWidth, height: cHeight } =
				canvas.getBoundingClientRect();

			canvas.width = cWidth;
			canvas.height = cHeight;

			const offset = cHeight / 2 - mainImg.height / 2;

			const widthRatio = mainImg.width / mainImg.naturalWidth;
			const heightRatio = mainImg.height / mainImg.naturalHeight;

			attrs?.forEach((attr, i) => {
				const rect = attr.rect;
				const isSelected = thumbIndex === i;
				if (drawSelectedOnly && !isSelected) return;
				const x = rect.x * widthRatio;
				const y = rect.y * heightRatio + offset;
				const w = rect.width * widthRatio;
				const h = rect.height * heightRatio;
				const roll = attr.roll;

				ctx.strokeStyle = isSelected ? "green" : "red";
				ctx.lineWidth = isSelected ? 4 : 2;
				ctx.translate(x + w / 2, y + h / 2);
				ctx.rotate((roll * Math.PI) / 180);
				ctx.strokeRect(-w / 2, -h / 2, w, h);
				if (i === hoveredIndex) {
					ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
					ctx.fillRect(-w / 2, -h / 2, w, h);
				}
				ctx.rotate((-roll * Math.PI) / 180);
				ctx.translate(-(x + w / 2), -(y + h / 2));
			});
			setFirstDraw((prev) => {
				if (prev) setTimeout(() => drawRects(hoveredIndex), 1);
				return false;
			});
		},
		[searchImage, drawSelectedOnly, thumbIndex],
	);

	useEffect(() => {
		drawRects();

		window.addEventListener("resize", () => drawRects());
		return () => {
			window.removeEventListener("resize", () => drawRects());
		};
	}, [drawRects]);

	const isMouseOnRect = (e: React.MouseEvent<HTMLCanvasElement>) => {
		if (!searchImage?.imageAttributes || !mainImgRef.current || !setIndex)
			return -1;
		const mainImg = mainImgRef.current;
		const attrs = searchImage.imageAttributes;
		const { height: cHeight } =
			canvasRef.current?.getBoundingClientRect() || {
				width: 0,
				height: 0,
			};

		const widthRatio = mainImg.width / mainImg.naturalWidth;
		const heightRatio = mainImg.height / mainImg.naturalHeight;
		const offset = cHeight / 2 - mainImg.height / 2;

		const x = e.nativeEvent.offsetX / widthRatio;
		const y = (e.nativeEvent.offsetY - offset) / heightRatio;

		return attrs.findIndex((attr) => {
			const rect = attr.rect;
			const x1 = rect.x;
			const y1 = rect.y;
			const x2 = rect.x + rect.width;
			const y2 = rect.y + rect.height;

			return x1 <= x && x <= x2 && y1 <= y && y <= y2;
		});
	};

	const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
		if (!canvasRef.current || !setIndex) return;
		const index = isMouseOnRect(e);
		canvasRef.current.style.cursor = index > -1 ? "pointer" : "default";
		drawRects(index);
	};

	const handleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const index = isMouseOnRect(e);
		if (index > -1) {
			setIndex?.(index);
			setIsOpen?.(false);
		}
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === "s") {
				e.preventDefault();
				if (searchImage && searchImage.image) {
					const blob = base64toBlob(searchImage.image, "image/jpg");
					downloadBlob(
						blob,
						result?.id ? `${result.id}.jpg` : "searchImage.jpg",
					);
				}
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [searchImage, result]);

	return (
		<div
			className={clsx(classes.flex, rootClassName)}
			style={{
				justifyContent: "flex-start",
			}}
			onClick={onClick}
		>
			<div
				className={classes.imgWrapper}
				style={{
					maxWidth: !searchImage ? "100%" : maxWidth,
					width: "100%",
					height: isHorizontal ? "unset" : "100%",
					visibility:
						isHorizontal === undefined ? "hidden" : "visible",
				}}
			>
				{searchImage?.image || result?.thumb ? (
					<>
						<img
							src={`data:image/jpeg;base64,${
								searchImage?.image ?? result?.thumb
							}`}
							className={imgClassName ?? classes.img}
							style={{
								width: "100%",
								height: isHorizontal ? "auto" : "100%",
								maxWidth,
								maxHeight,
							}}
							alt="search"
							ref={mainImgRef}
							onLoad={() => drawRects()}
							onError={onImgError}
						/>
						<canvas
							ref={canvasRef}
							className={classes.canvas}
							onClick={handleClick}
							onMouseMove={handleMouseMove}
							style={{
								display:
									isHorizontal === undefined
										? "none"
										: "unset",
							}}
						/>
					</>
				) : (
					<Skeleton
						variant="rect"
						height="100%"
						style={{ flex: 1 }}
						className={classes.img}
					/>
				)}
			</div>
		</div>
	);
};

export default CanvasImage;
