import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { async, AsyncAction } from "../AsyncState";

export const Authorities = "Authorities";
export type AuthoritiesAction = AsyncAction<
	typeof Authorities,
	string[] | string,
	string
>;

const getAvailableAuthorities = async () => {
	try {
		const user = await Axios.get<string[]>(
			API_BASE_PREFIX + "authorization/authorities",
		);
		return user.data;
	} catch (error) {
		throw error;
	}
};

export function getAuthoritiesAction() {
	return async(Authorities, getAvailableAuthorities, "");
}
