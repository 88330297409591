import {
	Box,
	Button,
	Checkbox,
	DialogContent,
	Divider,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { initialGridSize, State, TGridSize } from "./AreaDialog";
import AreaStream from "../../VideoPlayers/AreaStream";
import { ISource } from "../../../store/Sources/types";
import clsx from "clsx";
import { THEME } from "../../../config";
import { BorderAllRounded, Redo, Undo } from "@material-ui/icons";
import { ReactComponent as PolygonIcon } from "../../../assets/Svgs/polygon.svg";
import { TRect, TPolygon } from "./RegionsOverlay";
import { IAreasHistory } from "./GridOverlay";

type Props = {
	state: State;
	setState: React.Dispatch<React.SetStateAction<State>>;
	source: ISource;
	searchByCenter: boolean;
	setSearchByCenter: React.Dispatch<React.SetStateAction<boolean>>;
	items: TRect[];
	setItems: React.Dispatch<React.SetStateAction<TRect[]>>;
	rects: TRect[];
	setRects: React.Dispatch<React.SetStateAction<TRect[]>>;
	polygons: TPolygon[];
	setPolygons: React.Dispatch<React.SetStateAction<TPolygon[]>>;
	isConfigLoading: boolean;
	gridSize: TGridSize;
	setGridSize: React.Dispatch<React.SetStateAction<TGridSize>>;
};

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		gap: "1rem",
	},
	paper: {
		padding: "1rem",
	},
	form: {
		paddingLeft: "0.5rem",
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
	},
	btn: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		fontSize: "0.9rem",
		padding: "4px 8px",
	},
	green: {
		color: THEME.palette.success.dark,
		fill: THEME.palette.success.dark,
	},
	red: {
		color: THEME.palette.error.dark,
		fill: THEME.palette.error.dark,
	},
	polygon: {
		width: "1.25rem",
		height: "1rem",
	},
}));

const AreaDialogContent = ({
	source,
	state,
	setState,
	searchByCenter,
	setSearchByCenter,
	items,
	setItems,
	rects,
	setRects,
	polygons,
	setPolygons,
	isConfigLoading,
	gridSize,
	setGridSize,
}: Props) => {
	const classes = useStyles();

	const [reset, setReset] = useState<undefined | number>(undefined);
	const [undo, setUndo] = useState<undefined | number>(undefined);
	const [redo, setRedo] = useState<undefined | number>(undefined);
	const [menuRadio, setMenuRadio] = useState(state);
	const [gridHistory, setGridHistory] = useState<IAreasHistory<TRect[]>>({
		undoList: [],
		redoList: [],
	});
	const [regionsHistory, setRegionsHistory] = useState<
		IAreasHistory<{ rects: TRect[]; polygons: TPolygon[] }>
	>({
		undoList: [],
		redoList: [],
	});

	const handleGridChange = (key: keyof typeof gridSize, value: string) => {
		const number = parseInt(value) || 1;
		if (
			number < 1 ||
			String(number).length > 2 ||
			!/^[0-9][0-9]*$/.test(String(number))
		)
			return;
		setGridSize({
			...gridSize,
			[key]: number,
		});
		setItems([]);
	};

	const handleMenuChange = (value: number) => {
		setState(value);
		setPolygons((prev) =>
			prev.filter((p) => p.points.length > 2 && !p.currentlyDragging),
		);
	};

	const onReset = () => {
		setReset((reset ?? 0) + 1);
		setGridSize(initialGridSize);
		setSearchByCenter(true);
	};

	useEffect(() => {
		setMenuRadio(state === State.grid ? 1 : 2);
	}, [state]);

	useEffect(() => {
		setReset(undefined);
		setUndo(undefined);
		setRedo(undefined);
	}, [reset, undo, redo]);

	return (
		<DialogContent className={classes.root}>
			<Paper
				className={classes.paper}
				style={{ minWidth: "fit-content" }}
			>
				<RadioGroup
					defaultValue={State.grid}
					value={menuRadio}
					onChange={(e) => handleMenuChange(parseInt(e.target.value))}
				>
					<FormControlLabel
						value={1}
						control={<Radio />}
						label="Grid"
					/>
					<Box className={classes.form}>
						<TextField
							disabled={menuRadio !== 1}
							label="Rows"
							type="number"
							value={String(gridSize.Rows)}
							fullWidth
							onChange={(e) =>
								handleGridChange("Rows", e.target.value)
							}
						/>
						<TextField
							disabled={menuRadio !== 1}
							label="Cols"
							type="number"
							value={String(gridSize.Cols)}
							fullWidth
							onChange={(e) =>
								handleGridChange("Cols", e.target.value)
							}
						/>
					</Box>
					<FormControlLabel
						style={{ marginTop: 8 }}
						value={2}
						control={<Radio />}
						label="Regions"
					/>
					<Box className={classes.form} width="fit-content">
						<Button
							className={clsx(
								classes.btn,
								state === State.incRect && classes.green,
							)}
							disabled={state === State.grid}
							onClick={() => handleMenuChange(State.incRect)}
							startIcon={
								<BorderAllRounded className={classes.green} />
							}
						>
							Include rectangle
						</Button>
						<Button
							className={clsx(
								classes.btn,
								state === State.excRect && classes.red,
							)}
							disabled={state === State.grid}
							onClick={() => handleMenuChange(State.excRect)}
							startIcon={
								<BorderAllRounded className={classes.red} />
							}
						>
							Exclude rectangle
						</Button>
						<Button
							className={clsx(
								classes.btn,
								state === State.incPoly && classes.green,
							)}
							disabled={state === State.grid}
							onClick={() => handleMenuChange(State.incPoly)}
							startIcon={
								<PolygonIcon
									className={clsx(
										classes.polygon,
										classes.green,
									)}
								/>
							}
						>
							Include polygon
						</Button>
						<Button
							className={clsx(
								classes.btn,
								state === State.excPoly && classes.red,
							)}
							disabled={state === State.grid}
							onClick={() => handleMenuChange(State.excPoly)}
							startIcon={
								<PolygonIcon
									className={clsx(
										classes.polygon,
										classes.red,
									)}
								/>
							}
						>
							Exclude polygon
						</Button>
					</Box>
				</RadioGroup>
				<Divider style={{ marginTop: 16 }} />
				<FormControlLabel
					style={{ padding: "0.3rem 0" }}
					control={
						<Checkbox
							checked={searchByCenter}
							onChange={(e) =>
								setSearchByCenter(e.target.checked)
							}
							name="searchByCenter"
							color="secondary"
						/>
					}
					label="Check search area by object center"
				/>
				<Divider />
				<Box
					pt={3}
					display="flex"
					justifyContent="space-between"
					gridGap={8}
				>
					<Box display="flex" gridGap={12}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setUndo((undo ?? 0) + 1)}
							size="small"
							style={{ height: 32 }}
							startIcon={<Undo fontSize="small" />}
						>
							Undo
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setRedo((redo ?? 0) + 1)}
							size="small"
							style={{ height: 32 }}
							endIcon={<Redo />}
						>
							Redo
						</Button>
					</Box>
					<Button
						variant="contained"
						color="primary"
						onClick={onReset}
						size="small"
						style={{ height: 32, marginLeft: 8 }}
					>
						Reset
					</Button>
				</Box>
			</Paper>
			<Paper
				className={classes.paper}
				style={{
					flex: 3,
					padding: 32,
					overflow: "hidden",
					display: "grid",
					placeItems: "center",
					position: "relative",
				}}
			>
				<AreaStream
					source={source}
					state={state}
					gridSize={gridSize}
					reset={reset}
					undo={undo}
					redo={redo}
					items={items}
					setItems={setItems}
					rects={rects}
					setRects={setRects}
					polygons={polygons}
					setPolygons={setPolygons}
					isConfigLoading={isConfigLoading}
					gridHistory={gridHistory}
					setGridHistory={setGridHistory}
					regionsHistory={regionsHistory}
					setRegionsHistory={setRegionsHistory}
					setState={setState}
				/>
			</Paper>
		</DialogContent>
	);
};

export default AreaDialogContent;
