import { Box, Tooltip, Typography, TypographyProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { THEME } from "../../config";
import Close from "@material-ui/icons/Close";
import { TaskStatus } from "../../store/Sources/types";
import {
	PlayCircleFilled,
	PauseCircleFilled,
	Error,
	Info,
} from "@material-ui/icons";
import { yellow } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: THEME.palette.primary.dark,
		color: THEME.palette.grey[200],
		width: "100%",
		textAlign: "center",
		position: "relative",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "2px 4px",
	},
	darkTitle: {
		color: THEME.palette.grey[900],
		"& svg": {
			color: THEME.palette.grey[900],
			"&:hover": {
				color: THEME.palette.grey[700],
			},
		},
	},
	tempTitle: {
		backgroundColor: `${yellow[800]} !important`,
		cursor: "auto !important",
	},
	closeIcon: {
		color: THEME.palette.grey[200],
		transition: "all 0.1s ease-in-out",
		cursor: "pointer",
		"&:hover": {
			color: THEME.palette.grey[400],
		},
	},
	tooltip: {
		display: "flex",
		"& svg": {
			fontSize: "1.4rem",
		},
	},
}));

interface Props extends TypographyProps {
	title: string;
	onClose?: () => void;
	status?: TaskStatus;
	isTempCam?: boolean;
}

const CamTitle = React.forwardRef<HTMLDivElement, Props>(
	(
		{ title, onClose, className, status, isTempCam, style, ...props },
		ref,
	) => {
		const classes = useStyles();

		const backgroundType: "dark" | "light" = Boolean(isTempCam)
			? "light"
			: "dark";

		const iconsColorType: "light" | "dark" =
			backgroundType === "light" ? "dark" : "light";

		const bg =
			status === "ERROR"
				? THEME.palette.error.dark
				: status === "RUNNING"
				? ""
				: status
				? THEME.palette.grey[700]
				: "";

		return (
			<Box
				ref={ref}
				className={[
					classes.title,
					className,
					isTempCam ? classes.tempTitle : "",
					backgroundType === "light" ? classes.darkTitle : "",
				].join(" ")}
				style={{
					backgroundColor: bg,
					...style,
				}}
				{...props}
			>
				<Tooltip title={status ? `Camera status: ${status}` : ""}>
					<Box className={classes.tooltip}>
						{status === "RUNNING" ? (
							<PlayCircleFilled
								style={{
									color: THEME.palette.success[
										iconsColorType
									],
								}}
							/>
						) : status === "STOPPED" ? (
							<PauseCircleFilled
								style={{
									color: THEME.palette.warning[
										iconsColorType
									],
								}}
							/>
						) : status === "ERROR" ? (
							<Error
								style={{
									color: THEME.palette.grey[200],
								}}
							/>
						) : (
							<Info
								style={{
									visibility: status ? "visible" : "hidden",
									color: THEME.palette.info[iconsColorType],
								}}
							/>
						)}
					</Box>
				</Tooltip>
				<Box
					display="flex"
					alignItems="center"
					gridGap={4}
					overflow="hidden"
				>
					<Typography
						variant="h6"
						noWrap
						style={{ fontSize: "inherit" }}
					>
						{title}
					</Typography>
					{isTempCam && (
						<Typography variant="subtitle2">
							(Temporary view)
						</Typography>
					)}
				</Box>
				<Close
					style={{ visibility: onClose ? "visible" : "hidden" }}
					onClick={onClose}
					fontSize="small"
					className={classes.closeIcon}
				/>
			</Box>
		);
	},
);

export default CamTitle;
