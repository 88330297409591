import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISource } from "../../store/Sources/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { Box, CircularProgress } from "@material-ui/core";
import Axios from "axios";
import { API_BASE_PREFIX } from "../../config/axios";
import { State, TGridSize } from "../Sources/AreaDialog/AreaDialog";
import GridOverlay, { IAreasHistory } from "../Sources/AreaDialog/GridOverlay";
import RegionsOverlay, {
	TPolygon,
	TRect,
} from "../Sources/AreaDialog/RegionsOverlay";
import { vmsConnectionIds } from "../../services/vmsConnectionIds";
import useIsOnline from "../../hooks/useIsOnline";
import { setVMSConnectionIsLostAction } from "../../store/VMS/action";
import VideoConnectionLost from "./VideoConnectionLost";

type Props = {
	source: ISource;
	state: State;
	gridSize: TGridSize;
	reset: undefined | number;
	undo: undefined | number;
	redo: undefined | number;
	items: TRect[];
	setItems: React.Dispatch<React.SetStateAction<TRect[]>>;
	rects: TRect[];
	setRects: React.Dispatch<React.SetStateAction<TRect[]>>;
	polygons: TPolygon[];
	setPolygons: React.Dispatch<React.SetStateAction<TPolygon[]>>;
	isConfigLoading: boolean;
	gridHistory: IAreasHistory<TRect[]>;
	setGridHistory: React.Dispatch<React.SetStateAction<Props["gridHistory"]>>;
	regionsHistory: IAreasHistory<{ rects: TRect[]; polygons: TPolygon[] }>;
	setRegionsHistory: React.Dispatch<
		React.SetStateAction<Props["regionsHistory"]>
	>;
	setState: React.Dispatch<React.SetStateAction<State>>;
};

export const STREAM_PADDING = 16;

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		maxWidth: "100%",
		maxHeight: "100%",
		margin: "0 auto",
		position: "relative",
		overflow: "hidden",
		height: "100%",
		alignItems: "center",
	},
	canvas: {
		flex: 1,
		maxWidth: "100%",
		maxHeight: "100%",
	},
	outsideCanvas: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 10,
		opacity: 0.4,
	},
	loader: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
	},
}));

const AreaStream = ({
	source,
	state,
	gridSize,
	reset,
	undo,
	redo,
	items,
	setItems,
	rects,
	setRects,
	polygons,
	setPolygons,
	isConfigLoading,
	gridHistory,
	setGridHistory,
	regionsHistory,
	setRegionsHistory,
	setState,
}: Props) => {
	const classes = useStyles();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const outsideCanvasRef = useRef<HTMLCanvasElement>(null);
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const sdkIsLoaded = useSelector(
		(state: AppState) => state.live.sdkIsLoaded,
	);
	const dispatch = useDispatch();
	const isOnline = useIsOnline();
	const vms = useSelector((state: AppState) => state.vms.keys);
	const isConnectionLost = useSelector(
		(state: AppState) => state.vms.connections[source.vmsName ?? ""],
	);
	const connectionWasLost = useRef(false);
	const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>("");
	const url = useMemo(() => {
		const vm = vms[source.vmsName ?? ""];
		if (
			!vm ||
			!vm.ip ||
			vm.driver !== "MILESTONE" ||
			vm.useMobileServer === false
		) {
			return undefined;
		}
		const ssl = vm.mobileServerSSL ? "https" : "http";
		return `${ssl}://${vm.ip}:${vm.mobilServerPort}`;
	}, [vms, source]);

	const videoController = useRef<undefined | { [key: string]: any }>(
		undefined,
	);

	const drawError = useCallback((error: string) => {
		setError(error);
		setIsLoading(false);
		const canvas = canvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext("2d");
			if (ctx) {
				ctx.fillStyle = "black";
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				ctx.font = "30px Arial";
				ctx.fillStyle = "white";
				ctx.textAlign = "center";
				ctx.fillText(error, canvas.width / 2, canvas.height / 2);
			}
		}
	}, []);

	const drawImage = useCallback(
		(image: HTMLImageElement) => {
			image.onload = () => {
				const canvas = canvasRef.current;
				if (canvas) {
					const ctx = canvas.getContext("2d");
					if (ctx) {
						if (canvasSize.width !== image.width) {
							setCanvasSize({
								width: image.width,
								height: image.height,
							});
						}
						ctx.drawImage(image, 0, 0);
						URL.revokeObjectURL(image.src);
					}
				}
			};
		},
		[canvasSize],
	);

	const drawThumbnail = useCallback(async () => {
		try {
			const { data }: { data: { image: string; time: string } } =
				await Axios.get(
					`${API_BASE_PREFIX}vms/${source.vmsName}/extensions/sources/${source.id}/image`,
				);

			const image = new Image();
			image.src = `data:image/png;base64,${data.image}`;
			image.onerror = () => {
				setCanvasSize({ width: 1200, height: 600 });
				drawError("Error loading thumbnail");
			};

			drawImage(image);
			setIsLoading(false);
		} catch (error) {
			drawError("Error loading thumbnail");
		}
	}, [source, drawImage, drawError]);

	const onFrameReceived = useCallback(
		(frame: any) => {
			if (connectionWasLost.current) connectionWasLost.current = false;
			if (frame.frameNumber === 0)
				timeout.current = setTimeout(() => drawThumbnail(), 2000);
			if (!frame.blob) return;
			if (timeout.current) clearTimeout(timeout.current);
			if (isLoading) setIsLoading(false);
			if (canvasRef.current) {
				const ctx = canvasRef.current.getContext("2d");
				if (ctx) {
					const image = new Image();
					image.src = URL.createObjectURL(frame.blob);
					drawImage(image);
				}
			}
		},
		[drawImage, isLoading, drawThumbnail],
	);

	const onConnection = useCallback(() => {
		if (!source.vmsName) return;
		vmsConnectionIds
			.getVmsConnectionId(source.vmsName)
			.then(({ id, isAlreadyConnected }) => {
				if (isAlreadyConnected) return;
				XPMobileSDK.connectWithId(url, id);
			});
	}, [url, source.vmsName]);

	const onLogin = useCallback(() => {
		if (!localStorage.getItem(source.vmsName ?? "")) return drawThumbnail();

		if (connectionWasLost.current) {
			connectionWasLost.current = false;
			return;
		}

		XPMobileSDK.RequestStream(
			{
				ConnectionId: localStorage.getItem(source.vmsName ?? ""),
				CameraId: source.id,
				DestWidth: 1200,
				DestHeight: 600,
				Fps: 18,
				ComprLevel: 90,
				SignalType: "Live",
				MethodType: "Push",
			},
			(videoConnection: any) => {
				if (!videoConnection?.videoId) return;
				XPMobileSDK.playbackSpeed(videoConnection, 1);
				if (videoConnection?.observers?.length === 0) {
					videoConnection.addObserver({
						videoConnectionReceivedFrame: onFrameReceived,
					});
				}
				videoConnection.open();
				videoController.current = videoConnection;
			},
		);
	}, [source, onFrameReceived, , drawThumbnail]);

	const connect = useCallback(() => {
		if (connectionWasLost.current) {
			setIsLoading(true);
			return onConnection();
		}
		XPMobileSDKSettings.MobileServerURL = url;
		XPMobileSDKSettings.supportsCHAP = false;
		XPMobileSDK.features = {
			SupportTimeBetweenFrames: false,
		};
		const observer = {
			connectionDidLogIn: () => {
				if (source.vmsName)
					vmsConnectionIds.setIsVmsConnected(source.vmsName, true);
				onLogin();
			},
			connectionFailedToConnectWithId: () => {
				vmsConnectionIds.removeVms(source.vmsName ?? "");
				onConnection();
			},
			connectionFailedToConnect: () => {
				drawThumbnail();
			},
			connectionLostConnection: () => {
				if (!source.vmsName) return;
				vmsConnectionIds.setIsVmsConnected(source.vmsName, false, true);
				dispatch(setVMSConnectionIsLostAction(source.vmsName, true));
				setError("Connection lost");
			},
		};
		XPMobileSDK.addObserver(observer);
		onConnection();
	}, [url, onConnection, onLogin, source.vmsName, drawThumbnail, dispatch]);

	useEffect(() => {
		if (
			videoController.current &&
			videoController.current.observers[0]?.videoConnectionReceivedFrame
		) {
			videoController.current.observers[0].videoConnectionReceivedFrame =
				onFrameReceived;
		}
	}, [onFrameReceived]);

	useEffect(() => {
		setIsLoading(true);
		if (!sdkIsLoaded) return;
		if (!url) {
			drawThumbnail();
			return;
		}
		if (source.vmsName && vmsConnectionIds.isVmsConnected(source.vmsName))
			onLogin();
		else connect();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdkIsLoaded]);

	useEffect(() => {
		if (connectionWasLost.current) setError("");
		if (isConnectionLost) connectionWasLost.current = true;
		return () => {
			if (
				videoController.current &&
				!connectionWasLost.current &&
				!isConnectionLost
			) {
				videoController.current.close();
			}
		};
	}, [isConnectionLost]);

	const widthRatio =
		outsideCanvasRef.current && canvasRef.current
			? outsideCanvasRef.current?.getBoundingClientRect().width /
			  canvasRef.current.getBoundingClientRect().width
			: 1;
	const heightRatio =
		outsideCanvasRef.current && canvasRef.current
			? outsideCanvasRef.current?.getBoundingClientRect().height /
			  canvasRef.current.getBoundingClientRect().height
			: 1;

	const loadingStream =
		isConfigLoading ||
		isLoading ||
		canvasSize.height === 0 ||
		canvasSize.width === 0;

	return (
		<>
			<Box
				className={classes.root}
				style={{
					aspectRatio:
						canvasSize.width !== 0
							? `${canvasSize.width}/${canvasSize.height}`
							: "unset",
				}}
			>
				{isConnectionLost || !isOnline ? (
					<VideoConnectionLost
						vmsName={source.vmsName ?? ""}
						isOnline={isOnline}
					/>
				) : (
					<>
						<canvas
							style={{
								visibility: loadingStream
									? "hidden"
									: "visible",
							}}
							width={
								canvasSize.width === 0
									? 1200
									: canvasSize.width ?? 1200
							}
							height={
								canvasSize.height === 0
									? 600
									: canvasSize.height ?? 600
							}
							className={classes.canvas}
							ref={canvasRef}
						/>
						{loadingStream && (
							<Box className={classes.loader}>
								<CircularProgress />
							</Box>
						)}
						{state === State.grid ? (
							<GridOverlay
								gridSize={gridSize}
								reset={reset}
								undo={undo}
								redo={redo}
								items={items}
								setItems={setItems}
								hidden={Boolean(loadingStream || error)}
								history={gridHistory}
								setHistory={setGridHistory}
								canvasSize={canvasSize}
							/>
						) : (
							<RegionsOverlay
								state={state}
								reset={reset}
								undo={undo}
								redo={redo}
								rects={rects}
								setRects={setRects}
								polygons={polygons}
								setPolygons={setPolygons}
								hidden={Boolean(loadingStream || error)}
								history={regionsHistory}
								setHistory={setRegionsHistory}
								canvasSize={canvasSize}
								setState={setState}
								ref={outsideCanvasRef}
							/>
						)}
					</>
				)}
			</Box>
			{state !== State.grid && (
				<canvas
					ref={outsideCanvasRef}
					className={classes.outsideCanvas}
					style={{
						visibility: Boolean(loadingStream || error)
							? "hidden"
							: "visible",
					}}
					width={
						canvasSize.width === 0
							? 1200
							: canvasSize.width ?? 1200 * widthRatio
					}
					height={
						canvasSize.height === 0
							? 600
							: canvasSize.height ?? 600 * heightRatio
					}
				/>
			)}
		</>
	);
};

export default AreaStream;
