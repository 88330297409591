import {
	Popover,
	PopoverProps,
	Box,
	Typography,
	Button,
	Snackbar,
	List,
	TextField,
	Switch,
	Checkbox,
	FormControlLabel,
	Divider,
	Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
	deleteConfigAction,
	deleteConfigLocallyAction,
	updateConfigAction,
	updateConfigLocallyAction,
} from "../../store/UserConfig/action";
import Alert from "../UI/Alert";
import theme from "../../config/Theme";
import SearchInput from "./SearchInput";
import { getVMSAction } from "../../store/VMS/action";
import _, { debounce } from "lodash";
import { getSourceAction } from "../../store/Sources/action";
import { getRulesAction } from "../../store/Rules/action";
import { DEFAULT_MAX_EVENTS_LIMIT } from "../../store/LiveCameras/reducer";
import { HelpSharp, Videocam } from "@material-ui/icons";
import React from "react";
import { IEventsViewConfig } from "../../store/UserConfig/types";
import { DEFAULT_EVENT_VIEW_NAME } from "../../store/UserConfig/reducer";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		padding: 8,
		height: "100%",
	},
	paper: {
		width: "100%",
		maxWidth: 700,
		maxHeight: "min(90%, 700px)",
		marginLeft: 16,
		height: "100%",
		overflow: "hidden",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		flex: 1,
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		gap: 4,
	},
	listsWrapper: {
		display: "flex",
		flexWrap: "wrap",
		gap: 8,
		paddingBottom: 20,
		height: "100%",
		overflow: "hidden",
	},
	listTitle: {
		fontWeight: "bold",
		fontSize: "1.1rem",
		color: theme.palette.primary.main,
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	listItem: {
		display: "flex",
		justifyContent: "space-between",
		padding: 0,
	},
	list: {
		minWidth: 200,
		backgroundColor: theme.palette.background.default,
		flex: 1,
		padding: 8,
		borderRadius: 4,
		height: "100%",
		overflow: "hidden",
		display: "flex",
		flexDirection: "column",
	},
	label: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	switchBox: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		overflowY: "auto",
	},
	switch: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 4,
	},
	receiveAll: {
		marginTop: "8px",
		backgroundColor: theme.palette.background.default,
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		display: "flex",
		alignItems: "center",
		borderRadius: "4px 4px 0 0",
		"& > p": {
			fontWeight: "bold",
			fontSize: "0.9rem",
		},
	},
	subtitle: {
		borderRadius: 4,
		padding: "8px 0 8px 12px",
		marginBottom: 8,
		backgroundColor: theme.palette.background.default,
	},
	groupTitle: {
		display: "flex",
		alignItems: "center",
		gap: 6,
		padding: "4px 0 2px 0",
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		color: theme.palette.secondary.main,
	},
	divider: {
		backgroundColor: theme.palette.grey[400],
	},
	errorText: {
		display: "flex",
		alignItems: "center",
		gap: 4,
		color: theme.palette.error.main,
		fontWeight: "bold",
	},
}));

interface ILiveSettingsPopoverProps {
	onClose: () => void;
	anchorEl: PopoverProps["anchorEl"];
	eventsView: IEventsViewConfig;
	isLastView: boolean;
}

const LiveSettingsPopover = ({
	onClose,
	anchorEl,
	eventsView,
	isLastView,
}: ILiveSettingsPopoverProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [originalView, setOriginalView] = useState(eventsView);

	const [title, setTitle] = useState(eventsView.listTitle ?? "");
	const [eventsLimit, setEventsLimit] = useState<number | undefined>(
		eventsView.maxEventsLimit ?? DEFAULT_MAX_EVENTS_LIMIT,
	);
	const [searchValues, setSearchValues] = useState({
		vms: "",
		sources: "",
		rules: "",
	});

	const rules = useSelector((state: AppState) =>
		Object.values(state.rules.keys)
			.map((k) => k.content)
			.flat(),
	);
	const vms = useSelector((state: AppState) => state.vms);
	const sourcesData = useSelector((state: AppState) => state.sources);
	const sources = Object.values(sourcesData.keys)
		.flat()
		.map((d) => d.content)
		.flat();

	const filteredVms = vms.data.content.filter((v) =>
		v.name
			.toLocaleLowerCase()
			.includes(searchValues.vms.toLocaleLowerCase()),
	);
	const filteredSources = sources.filter(
		(s) =>
			s.displayName
				.toLocaleLowerCase()
				.includes(searchValues.sources.toLocaleLowerCase()) &&
			(eventsView.enabledVms?.includes(s.vmsName ?? "") ||
				eventsView.enabledAll),
	);
	const filteredRules = rules.filter(
		(r) =>
			r.name
				.toLocaleLowerCase()
				.includes(searchValues.rules.toLocaleLowerCase()) &&
			(eventsView.enabledVms?.includes(r.vms) || eventsView.enabledAll),
	);

	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const handleSave = () => {
		setSnackbarOpen(true);
		if (!eventsView) return;
		const newConfig = {
			...eventsView,
			listTitle: title,
			maxEventsLimit: eventsLimit || DEFAULT_MAX_EVENTS_LIMIT,
		};

		dispatch(
			updateConfigLocallyAction({
				variant: "eventsViews",
				config: newConfig,
			}),
		);

		if (eventsView.name !== DEFAULT_EVENT_VIEW_NAME)
			dispatch(
				updateConfigAction({
					variant: "eventsViews",
					config: newConfig,
				}),
			);

		setOriginalView(newConfig);
		setTitle(newConfig.listTitle);
		setEventsLimit(newConfig.maxEventsLimit);
		handleClose(false);
	};

	const fetchData = (
		value: string,
		actionToDispatch: "vms" | "sources" | "rules",
	) => {
		if (actionToDispatch === "vms") {
			dispatch(getVMSAction({ like: value, size: 500 }));
		} else if (actionToDispatch === "sources") {
			Object.keys(vms.keys).forEach((vms) => {
				dispatch(getSourceAction(vms, { name: value }));
			});
		} else if (actionToDispatch === "rules") {
			Object.keys(vms.keys).forEach((vms) => {
				dispatch(getRulesAction(vms, { phrase: value }));
			});
		}
	};

	const debouncedSearch = useRef(debounce(fetchData, 500));

	const onSearch = useCallback(
		(value: string, actionToDispatch: "vms" | "sources" | "rules") => {
			debouncedSearch.current(value, actionToDispatch);
			setSearchValues((prev) => ({ ...prev, [actionToDispatch]: value }));
		},
		[],
	);

	const handleClose = (reset = true) => {
		if (originalView && reset) {
			dispatch(
				updateConfigLocallyAction({
					variant: "eventsViews",
					config: originalView,
				}),
			);
			setTitle(originalView?.listTitle ?? "");
			setEventsLimit(
				originalView?.maxEventsLimit ?? DEFAULT_MAX_EVENTS_LIMIT,
			);
			if (searchValues.rules) onSearch("", "rules");
			setSearchValues({
				vms: "",
				sources: "",
				rules: "",
			});
		}

		onClose();
	};

	const handleDeleteView = () => {
		if (eventsView.name === DEFAULT_EVENT_VIEW_NAME)
			dispatch(
				deleteConfigLocallyAction({
					variant: "eventsViews",
					config: eventsView,
				}),
			);
		else
			dispatch(
				deleteConfigAction({
					variant: "eventsViews",
					config: eventsView,
				}),
			);
	};

	useEffect(() => {
		if (!eventsView.enabledAll) return;
		const newVms = vms.data.content.map((v) => v.name);
		const newSources = sources.map((s) => `${s.vmsName}/${s.id}`);
		const newRules = rules.map((r) => `${r.vms}/${r.name}`);

		if (
			_.isEqual(newVms, eventsView.enabledVms) &&
			_.isEqual(newSources, eventsView.enabledSources) &&
			_.isEqual(newRules, eventsView.enabledRules)
		)
			return;

		dispatch(
			updateConfigLocallyAction({
				variant: "eventsViews",
				config: {
					...eventsView,
					enabledVms: newVms,
					enabledSources: newSources,
					enabledRules: newRules,
				},
			}),
		);
	}, [dispatch, eventsView, rules, sources, vms.data.content]);

	return (
		<>
			<Popover
				id="settingsButton"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => handleClose()}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				classes={{ paper: classes.paper }}
			>
				<Box className={classes.root}>
					<Typography variant="h6">Settings</Typography>
					<Box className={classes.form}>
						<Box display="flex" gridGap={16}>
							<TextField
								name="listTitle"
								fullWidth
								label="Title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								inputProps={{
									spellCheck: false,
									maxLength: 32,
								}}
							/>
							<TextField
								name="maxEventsLimit"
								fullWidth
								label="Events count limit"
								value={eventsLimit}
								type="number"
								onChange={(e) =>
									setEventsLimit(
										Number(e.target.value) || undefined,
									)
								}
							/>
						</Box>
						<Box pl={0.5}>
							<Tooltip
								title={
									<Box display="flex" flexDirection="column">
										<Typography variant="inherit">
											Events sent by multiple rules are
											shown as one event.
										</Typography>
										<Typography variant="inherit">
											Select to show separate for each
											event.
										</Typography>
									</Box>
								}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												eventsView.separateEvents ??
												false
											}
											onChange={(_, checked) => {
												if (!eventsView) return;
												dispatch(
													updateConfigLocallyAction({
														variant: "eventsViews",
														config: {
															...eventsView,
															separateEvents:
																checked,
														},
													}),
												);
											}}
										/>
									}
									label="Separate event for each rule"
								/>
							</Tooltip>
						</Box>
						<Box className={classes.receiveAll}>
							<Switch
								color="primary"
								checked={eventsView.enabledAll ?? false}
								onChange={(_, checked) => {
									if (!eventsView) return;
									dispatch(
										updateConfigLocallyAction({
											variant: "eventsViews",
											config: {
												...eventsView,
												enabledAll: checked,
											},
										}),
									);
								}}
							/>
							<Typography variant="body1">
								Receive all events
							</Typography>
						</Box>
						<Typography
							variant="subtitle2"
							className={classes.subtitle}
						>
							Or configure what events to receive
						</Typography>
						<Box className={classes.listsWrapper}>
							<List className={classes.list}>
								<Box className={classes.listTitle}>
									Vms
									<Switch
										color="primary"
										disabled={filteredVms.length === 0}
										checked={
											eventsView.enabledAll
												? true
												: (filteredVms.length > 0 &&
														filteredVms.every((v) =>
															eventsView.enabledVms?.includes(
																v.name,
															),
														)) ??
												  false
										}
										onChange={(_, checked) => {
											if (!eventsView) return;
											dispatch(
												updateConfigLocallyAction({
													variant: "eventsViews",
													config: {
														...eventsView,
														enabledAll: false,
														enabledVms: checked
															? filteredVms.map(
																	(v) =>
																		v.name,
															  )
															: eventsView.enabledVms?.filter(
																	(v) =>
																		!filteredVms.some(
																			(
																				f,
																			) =>
																				f.name ===
																				v,
																		),
															  ),
													},
												}),
											);
										}}
									/>
								</Box>
								<SearchInput
									placeholder="Vms name..."
									onChange={(value) => onSearch(value, "vms")}
									value={searchValues.vms}
								/>
								<Box className={classes.switchBox}>
									{filteredVms.length > 0 ? (
										filteredVms.map((vms) => {
											const tooltipTitle =
												!eventsView.enabledVms?.includes(
													vms.name,
												)
													? ""
													: !eventsView.enabledSources?.some(
															(s) =>
																s.includes(
																	vms.name,
																) &&
																sources.some(
																	(src) =>
																		`${src.vmsName}/${src.id}` ===
																		s,
																),
													  )
													? "No sources are enabled for this VMS. Enable them to receive events"
													: !eventsView.enabledRules?.some(
															(r) =>
																r.includes(
																	vms.name,
																) &&
																rules.some(
																	(rule) =>
																		`${rule.vms}/${rule.name}` ===
																		r,
																),
													  )
													? "No rules are enabled for this VMS. Enable them to receive events"
													: "";

											return (
												<Box
													className={classes.switch}
													key={vms.name}
												>
													<Tooltip
														title={tooltipTitle}
													>
														<Box
															className={
																tooltipTitle
																	? classes.errorText
																	: ""
															}
														>
															{vms.name}
															{tooltipTitle && (
																<HelpSharp fontSize="small" />
															)}
														</Box>
													</Tooltip>
													<Switch
														color="primary"
														checked={
															eventsView.enabledAll
																? true
																: eventsView.enabledVms?.includes(
																		vms.name,
																  ) ?? false
														}
														onChange={(
															_,
															checked,
														) => {
															if (!eventsView)
																return;
															dispatch(
																updateConfigLocallyAction(
																	{
																		variant:
																			"eventsViews",
																		config: {
																			...eventsView,
																			enabledAll:
																				false,
																			enabledVms:
																				checked
																					? [
																							...(eventsView.enabledVms ??
																								[]),
																							vms.name,
																					  ]
																					: eventsView.enabledVms?.filter(
																							(
																								v,
																							) =>
																								v !==
																								vms.name,
																					  ),
																		},
																	},
																),
															);
														}}
													/>
												</Box>
											);
										})
									) : (
										<Typography
											align="center"
											variant="body2"
											style={{ marginTop: 8 }}
										>
											No vms found
										</Typography>
									)}
								</Box>
							</List>
							<List className={classes.list}>
								<Box className={classes.listTitle}>
									Sources
									<Switch
										color="primary"
										disabled={filteredSources.length === 0}
										checked={
											eventsView.enabledAll
												? true
												: (filteredSources.length > 0 &&
														filteredSources.every(
															(s) =>
																eventsView.enabledSources?.includes(
																	`${s.vmsName}/${s.id}`,
																),
														)) ??
												  false
										}
										onChange={(_, checked) => {
											if (!eventsView) return;
											dispatch(
												updateConfigLocallyAction({
													variant: "eventsViews",
													config: {
														...eventsView,
														enabledAll: false,
														enabledSources: checked
															? filteredSources.map(
																	(s) =>
																		`${s.vmsName}/${s.id}`,
															  )
															: eventsView.enabledSources?.filter(
																	(s) =>
																		!filteredSources.some(
																			(
																				f,
																			) =>
																				`${f.vmsName}/${f.id}` ===
																				s,
																		),
															  ),
													},
												}),
											);
										}}
									/>
								</Box>
								<SearchInput
									placeholder="Source name..."
									onChange={(value) =>
										onSearch(value, "sources")
									}
									value={searchValues.sources}
								/>
								<Box className={classes.switchBox}>
									{filteredSources.length > 0 ? (
										filteredVms
											.filter((vms) =>
												filteredSources.some(
													(s) =>
														s.vmsName === vms.name,
												),
											)
											.map((vms) => (
												<React.Fragment key={vms.name}>
													<Box
														className={
															classes.groupTitle
														}
													>
														<Videocam fontSize="small" />
														<Divider
															orientation="vertical"
															flexItem
															classes={{
																root: classes.divider,
															}}
														/>

														<Typography
															variant="subtitle2"
															noWrap
														>
															{vms.name}
														</Typography>
													</Box>
													{filteredSources
														.filter(
															(s) =>
																s.vmsName ===
																vms.name,
														)
														.map((source) => (
															<Box
																className={
																	classes.switch
																}
																key={source.id}
															>
																{
																	source.displayName
																}
																<Switch
																	color="primary"
																	checked={
																		eventsView.enabledAll
																			? true
																			: eventsView.enabledSources?.includes(
																					`${source.vmsName}/${source.id}`,
																			  ) ??
																			  false
																	}
																	onChange={(
																		_,
																		checked,
																	) => {
																		if (
																			!eventsView
																		)
																			return;
																		dispatch(
																			updateConfigLocallyAction(
																				{
																					variant:
																						"eventsViews",
																					config: {
																						...eventsView,
																						enabledAll:
																							false,
																						enabledSources:
																							checked
																								? [
																										...(eventsView.enabledSources ??
																											[]),
																										`${source.vmsName}/${source.id}`,
																								  ]
																								: eventsView.enabledSources?.filter(
																										(
																											s,
																										) =>
																											s !==
																											`${source.vmsName}/${source.id}`,
																								  ),
																					},
																				},
																			),
																		);
																	}}
																/>
															</Box>
														))}
												</React.Fragment>
											))
									) : (
										<Typography
											align="center"
											variant="body2"
											style={{ marginTop: 8 }}
										>
											No sources found
										</Typography>
									)}
								</Box>
							</List>
							<List className={classes.list}>
								<Box className={classes.listTitle}>
									Rules
									<Switch
										color="primary"
										disabled={filteredRules.length === 0}
										checked={
											eventsView.enabledAll
												? true
												: (filteredRules.length > 0 &&
														filteredRules.every(
															(s) =>
																eventsView.enabledRules?.includes(
																	`${s.vms}/${s.name}`,
																),
														)) ??
												  false
										}
										onChange={(_, checked) => {
											if (!eventsView) return;
											dispatch(
												updateConfigLocallyAction({
													variant: "eventsViews",
													config: {
														...eventsView,
														enabledAll: false,
														enabledRules: checked
															? filteredRules.map(
																	(s) =>
																		`${s.vms}/${s.name}`,
															  )
															: eventsView.enabledRules?.filter(
																	(s) =>
																		!filteredRules.some(
																			(
																				f,
																			) =>
																				`${f.vms}/${f.name}` ===
																				s,
																		),
															  ),
													},
												}),
											);
										}}
									/>
								</Box>
								<SearchInput
									placeholder="Rule name..."
									onChange={(value) =>
										onSearch(value, "rules")
									}
									value={searchValues.rules}
								/>
								<Box className={classes.switchBox}>
									{filteredRules.length > 0 ? (
										filteredVms
											.filter((vms) =>
												filteredRules.some(
													(r) => r.vms === vms.name,
												),
											)
											.map((vms) => (
												<React.Fragment key={vms.name}>
													<Box
														className={
															classes.groupTitle
														}
													>
														<Videocam fontSize="small" />
														<Divider
															orientation="vertical"
															flexItem
															classes={{
																root: classes.divider,
															}}
														/>

														<Typography
															variant="subtitle2"
															noWrap
														>
															{vms.name}
														</Typography>
													</Box>
													{filteredRules
														.filter(
															(r) =>
																r.vms ===
																vms.name,
														)
														.map((rule) => {
															const tooltipTitle =
																!eventsView.enabledRules?.includes(
																	`${rule.vms}/${rule.name}`,
																)
																	? ""
																	: !rule.enabled
																	? "Rule is disabled. Enable it to receive events"
																	: !rule.actions.some(
																			(
																				a,
																			) =>
																				a.type ===
																				"MqttAction",
																	  )
																	? "Rule has no MQTT action. Add it to receive events"
																	: "";
															return (
																<Box
																	key={
																		rule.name
																	}
																	className={
																		classes.switch
																	}
																>
																	<Tooltip
																		title={
																			tooltipTitle
																		}
																	>
																		<Box
																			className={
																				tooltipTitle
																					? classes.errorText
																					: ""
																			}
																		>
																			{
																				rule.name
																			}
																			{tooltipTitle && (
																				<HelpSharp fontSize="small" />
																			)}
																		</Box>
																	</Tooltip>
																	<Switch
																		color="primary"
																		checked={
																			eventsView.enabledAll
																				? true
																				: eventsView.enabledRules?.includes(
																						`${rule.vms}/${rule.name}`,
																				  ) ??
																				  false
																		}
																		onChange={(
																			_,
																			checked,
																		) => {
																			if (
																				!eventsView
																			)
																				return;
																			dispatch(
																				updateConfigLocallyAction(
																					{
																						variant:
																							"eventsViews",
																						config: {
																							...eventsView,
																							enabledAll:
																								false,
																							enabledRules:
																								checked
																									? [
																											...(eventsView.enabledRules ??
																												[]),
																											`${rule.vms}/${rule.name}`,
																									  ]
																									: eventsView.enabledRules?.filter(
																											(
																												s,
																											) =>
																												s !==
																												`${rule.vms}/${rule.name}`,
																									  ),
																						},
																					},
																				),
																			);
																		}}
																	/>
																</Box>
															);
														})}
												</React.Fragment>
											))
									) : (
										<Typography
											align="center"
											variant="body2"
											style={{ marginTop: 8 }}
										>
											No rules found
										</Typography>
									)}
								</Box>
							</List>
						</Box>
						<Box className={classes.actions}>
							<Button
								onClick={handleDeleteView}
								disabled={isLastView}
							>
								Delete view
							</Button>
							<Box display="flex" gridGap={4}>
								<Button onClick={handleSave}>Save</Button>
								<Button onClick={() => handleClose()}>
									Close
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Popover>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={(_, reason) => {
					if (reason === "clickaway") return;
					setSnackbarOpen(false);
				}}
			>
				<div>
					<Alert
						onClose={() => setSnackbarOpen(false)}
						alert={{
							variant: "success",
							message: "Settings saved!",
						}}
					/>
				</div>
			</Snackbar>
		</>
	);
};

export default LiveSettingsPopover;
