export const calcDialogSize = (
	width: number,
	threshold: number,
	largeSize: number,
	smallSize: number,
) => (width >= threshold ? largeSize : smallSize);

export const getSubItems = (x: number, y: number) => {
	return [
		{
			width: x,
			height: y,
			top: 0,
			left: 0,
			selected: false,
		},
		{
			width: 1 - x,
			height: y,
			top: 0,
			left: x,
			selected: false,
		},
		{
			width: x,
			height: 1 - y,
			top: y,
			left: 0,
			selected: false,
		},
		{
			width: 1 - x,
			height: 1 - y,
			top: y,
			left: x,
			selected: false,
		},
	];
};
