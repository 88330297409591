import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { getSchemaAction } from "../store/Schemas/action";
import {
	SCHEMAS,
	IMetadata,
	IMetadataProperties,
} from "../store/Schemas/types";
import { getWatchlistAction } from "../store/Watchlists/action";

type Props = {
	metadata: string | IMetadata | undefined;
	watchlistId: string | undefined;
	secretId: string | undefined;
};

const useMetadata = ({ watchlistId, secretId, metadata }: Props) => {
	const dispatch = useDispatch();
	const watchlistState = useSelector((state: AppState) => state.watchlists);
	const watchlist = watchlistState.keys[watchlistId ?? ""];
	const schema = useSelector((state: AppState) =>
		watchlist
			? state.schemas[
					watchlist?.type === "Faces" ? SCHEMAS.PERSON : SCHEMAS.PLATE
			  ]
			: undefined,
	);

	useEffect(() => {
		if (!watchlistId) return;

		const watchlist = watchlistState.keys[watchlistId];

		if (watchlist) {
			if (!schema)
				getSchemaAction(
					watchlist.type === "Faces" ? SCHEMAS.PERSON : SCHEMAS.PLATE,
				)(dispatch);
		} else getWatchlistAction(watchlistId, secretId ?? "")(dispatch);
	}, [dispatch, watchlistState, schema, watchlistId, secretId]);

	const unfilteredMetadata: IMetadata | undefined = metadata
		? typeof metadata === "string"
			? JSON.parse(metadata || "{}")
			: metadata
		: undefined;

	const filterMetadata = (
		meta: IMetadata | undefined,
		properties: IMetadataProperties | undefined,
	) => {
		if (!meta || !properties) return undefined;

		const filteredMeta: IMetadata = {};
		for (const key in properties) {
			if (meta[key]) {
				if (properties[key].properties) {
					filteredMeta[key] = filterMetadata(
						meta[key] as IMetadata,
						properties[key].properties,
					);
				} else {
					filteredMeta[key] = meta[key];
				}
			}
		}
		return filteredMeta;
	};

	return filterMetadata(unfilteredMetadata, schema?.properties);
};

export default useMetadata;
