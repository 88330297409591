import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { ISource, ISourceinfo } from "../../store/Sources/types";
import SourceForm from "../Forms/SourceForm";
import Title from "./Common/Title";

interface ISourceDialogProps {
	open: boolean;
	close: () => void;
	onSubmit: (source: ISourceinfo) => void;
	data?: ISource | null;
	edit?: boolean;
}

const SourceDialog: React.FC<ISourceDialogProps> = (props) => {
	const idata = props.data
		? {
				displayName: props.data.displayName || "",
				url: props.data.url || "",
				password: "",
				user: props.data.user || "",
		  }
		: undefined;

	return (
		<Dialog open={props.open} onClose={props.close}>
			<Title
				onClose={props.close}
				title={
					props.data
						? `Edit ${props.data.displayName} Source`
						: "Connect Source"
				}
			/>
			<DialogContent>
				<SourceForm
					onSubmit={props.onSubmit}
					data={idata as ISourceinfo}
					discard={props.close}
					edit={props.edit}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default SourceDialog;
