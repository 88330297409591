import React, { useState, KeyboardEvent } from "react";
import {
	Grid,
	Paper,
	TextField,
	Select,
	Button,
	MenuItem,
} from "@material-ui/core";
import { IUserRequest } from "../../store/Users/types";

interface IUsersFilteringProps {
	onFilter(newFilter: IUserRequest): void;
	filter: IUserRequest;
}

type SearchFilter = {
	userName?: string;
	email?: string;
	state?: boolean;
};

const UsersFiltering: React.FC<IUsersFilteringProps> = ({
	onFilter,
	filter,
}) => {
	const [searchFilter, setSearchFilter] = useState<SearchFilter>({});

	const getFilterEnabledValue = () => {
		switch (searchFilter.state) {
			case undefined: {
				return "both";
			}
			case true: {
				return "active";
			}
			case false: {
				return "disabled";
			}
		}
	};

	const handleSearchInput = (
		e: React.ChangeEvent<
			| {
					name?: string;
					value: unknown;
			  }
			| HTMLInputElement
		>,
	) => {
		const value = e.target.value ? e.target.value : undefined;
		switch (e.target.name) {
			case "username-search": {
				const userName = value as string;
				setSearchFilter({ ...searchFilter, userName });
				break;
			}
			case "email-search": {
				const email = value as string;
				setSearchFilter({ ...searchFilter, email });
				break;
			}
			case "enabled-search": {
				const enabledString = value as string;
				switch (enabledString) {
					case "active": {
						setSearchFilter({
							...searchFilter,
							state: true,
						});
						break;
					}
					case "disabled": {
						setSearchFilter({
							...searchFilter,
							state: false,
						});
						break;
					}
					case "both":
					default: {
						setSearchFilter({
							...searchFilter,
							state: undefined,
						});
						break;
					}
				}
				break;
			}
		}
	};

	const handleSearchReset = () => {
		setSearchFilter({});
		onFilter({
			page: 0,
			size: 15,
			userName: undefined,
			email: undefined,
			enabled: undefined,
			sort: undefined,
			reverse: undefined,
		});
	};

	const handleFilter = () => {
		onFilter({
			...filter,
			userName: searchFilter.userName,
			email: searchFilter.email,
			enabled: searchFilter.state,
		});
	};

	const onEnter = (e: KeyboardEvent<HTMLImageElement>) => {
		if (e.key === "Enter") {
			handleFilter();
			e.preventDefault();
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={10} sm={11} lg={8} style={{ flexBasis: "auto" }}>
				<Paper>
					<Grid
						container
						spacing={2}
						style={{
							width: "100%",
							margin: 0,
						}}
					>
						<Grid
							item
							xs={3}
							md={2}
							style={{
								marginBottom: "0px",
								textAlign: "right",
							}}
						>
							<h3>Username</h3>
						</Grid>
						<Grid item xs={7} md={4}>
							<TextField
								autoFocus
								fullWidth
								name="username-search"
								id="username-search"
								margin="dense"
								value={searchFilter.userName || ""}
								onChange={handleSearchInput}
								onKeyPress={onEnter}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							md={1}
							style={{
								marginBottom: "0px",
								textAlign: "right",
							}}
						>
							<h3>Email</h3>
						</Grid>
						<Grid item xs={7} md={4} style={{ marginRight: "5px" }}>
							<TextField
								fullWidth
								name="email-search"
								id="email-search"
								margin="normal"
								value={searchFilter.email || ""}
								onChange={handleSearchInput}
								onKeyPress={onEnter}
							/>
						</Grid>
						<Grid
							item
							xs={3}
							md={2}
							style={{
								textAlign: "right",
							}}
						>
							<h3>State</h3>
						</Grid>
						<Grid item xs={7} md={4}>
							<Select
								value={getFilterEnabledValue()}
								fullWidth
								onChange={handleSearchInput}
								inputProps={{
									name: "enabled-search",
								}}
								style={{ marginTop: "15px" }}
							>
								<MenuItem value={"both"}>---</MenuItem>
								<MenuItem value={"active"}>Active</MenuItem>
								<MenuItem value={"disabled"}>Disabled</MenuItem>
							</Select>
						</Grid>

						<Grid item xs={12} style={{ marginTop: "0px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<Button
									style={{ marginRight: "5px" }}
									onClick={handleFilter}
								>
									Filter
								</Button>
								<Button onClick={handleSearchReset}>
									Reset
								</Button>
							</div>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default UsersFiltering;
