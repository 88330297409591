import React from "react";
import { IVMSInfo, defaultEngineTypes } from "../../store/VMS/types";
import { Form, Field } from "react-final-form";
import { TextField, Checkboxes, makeValidate, Select, Radios } from "mui-rff";
import {
	Button,
	MenuItem,
	InputAdornment,
	IconButton,
	CircularProgress,
	Chip,
	Box,
	Typography,
	Link,
} from "@material-ui/core";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
	renameTypesForDisplay,
	renameTypesForQuery,
} from "../Sources/SourceItem";
import arrayMutators from "final-form-arrays";
import { getEngineSelectionStyles } from "../../helpers/Styles";
import LabelledOutline from "../UI/LabelledOutline";

const makeSchema = (edit: boolean = false) => {
	const schema = Yup.object<IVMSInfo>().shape({
		name: Yup.string().required(),
		defaultEngineType: Yup.array().of(Yup.string()).required(),
		driver: Yup.string().required(),
		ip: Yup.string().when("driver", {
			is: "MILESTONE",
			then: Yup.string().required(),
			otherwise: Yup.string().notRequired().nullable(),
		}),
		eventPort: Yup.number().when("driver", {
			is: "MILESTONE",
			then: Yup.number().max(65535).min(0).required(),
			otherwise: Yup.number().notRequired().nullable(),
		}),
		password: Yup.string().when("driver", {
			is: "MILESTONE",
			then: edit ? Yup.string().nullable() : Yup.string().required(),
			otherwise: Yup.string().notRequired().nullable(),
		}),
		user: Yup.string().when("driver", {
			is: "MILESTONE",
			then: Yup.string().required(),
			otherwise: Yup.string().notRequired().nullable(),
		}),
		startAutomatically: Yup.boolean(),
		useMobileServer: Yup.boolean(),
		mobileServerSSL: Yup.boolean().when("useMobileServer", {
			is: true,
			then: Yup.boolean().required(),
			otherwise: Yup.boolean().notRequired(),
		}),
		mobilServerPort: Yup.number().when("useMobileServer", {
			is: true,
			then: Yup.number().required("port is a required field"),
			otherwise: Yup.number().notRequired(),
		}),
	});
	return schema;
};

interface IVMSForm {
	data?: IVMSInfo;
	edit?: boolean;
	onSubmit: (values: IVMSInfo) => void;
	discard?: () => void;
	loading: boolean;
	onLoading(): void;
}

const VMSForm: React.FC<IVMSForm> = (props) => {
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const handleSubmit = (data: IVMSInfo) => {
		props.onLoading();
		const dataToSubmit = { ...data };
		dataToSubmit.defaultEngineType = renameTypesForQuery(
			data.defaultEngineType,
		);
		props.onSubmit(dataToSubmit);
	};

	const validate = makeValidate(makeSchema(props.edit));

	const calculatePortValue = (port: number, ssl: boolean) => {
		if (port === 8081 && ssl) return 8082;
		else if (port === 8082 && !ssl) return 8081;
		return port;
	};

	const Condition: React.FC<any> = ({ when, is, children }) => (
		<Field name={when} subscription={{ value: true }}>
			{({ input: { value } }) => (value === is ? children : null)}
		</Field>
	);

	return (
		<Form
			keepDirtyOnReinitialize
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={
				props.data || {
					driver: "MILESTONE",
					defaultEngineType: ["Faces"],
					eventPort: 9090,
					useMobileServer: true,
					mobileServerSSL: false,
					mobilServerPort: "8081",
				}
			}
			mutators={{ ...arrayMutators }}
			render={({
				handleSubmit,
				values,
				pristine,
				invalid,
				form: {
					mutators: { remove },
					change: changeValue,
				},
			}) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<TextField
						name="name"
						label="Name"
						disabled={props.data !== undefined}
						required
						margin="none"
						inputProps={{
							spellCheck: false,
							maxLength: 32,
						}}
						autoFocus
					/>
					<Select
						name="defaultEngineType"
						label="Select default engine"
						value={values.defaultEngineType ?? []}
						required
						multiple
						MenuProps={{
							variant: "menu",
							getContentAnchorEl: null,
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "center",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "center",
							},
						}}
						renderValue={() => (
							<div>
								{(values.defaultEngineType ?? []).map(
									(value, index) => (
										<Chip
											key={value}
											label={value}
											onDelete={() =>
												remove(
													"defaultEngineType",
													index,
												)
											}
											onMouseDown={(event) => {
												event.stopPropagation();
											}}
											style={{ marginRight: 2 }}
										/>
									),
								)}
							</div>
						)}
					>
						{renameTypesForDisplay(defaultEngineTypes).map(
							(type) => (
								<MenuItem
									key={type}
									value={type}
									style={getEngineSelectionStyles(
										type,
										values.defaultEngineType,
									)}
								>
									{type}
								</MenuItem>
							),
						)}
					</Select>
					<Select
						name="driver"
						label="Select driver"
						required
						disabled={!!props.edit}
					>
						<MenuItem value="MILESTONE">Milestone</MenuItem>
						<MenuItem value="GENERIC">Generic</MenuItem>
					</Select>

					<Condition when="driver" is="MILESTONE">
						<TextField
							name="ip"
							label="IP"
							margin="none"
							required
						/>
						<TextField
							name="eventPort"
							label="Event Port"
							type="number"
							margin="dense"
							required
						/>
						<TextField
							name="user"
							label="User"
							required
							autoComplete="new-password"
							margin="none"
						/>
						<TextField
							name="password"
							label="Password"
							margin="dense"
							placeholder={
								props.edit
									? "Leave blank if you dont want to change password"
									: ""
							}
							type={!showPassword ? "password" : "text"}
							autoComplete="new-password"
							required={!!!props.edit}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
										>
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Box pt={2}>
							<LabelledOutline
								label={
									<Typography
										variant="inherit"
										style={{
											display: "flex",
											marginTop: -4,
										}}
									>
										XProtect Mobile server
									</Typography>
								}
								id="vms-form-mobile-server-label"
							>
								<Link
									target="_blank"
									rel="noreferrer"
									underline="always"
									href="https://supportcommunity.milestonesys.com/s/article/XProtect-Mobile-Server-app-on-different-server-CORS?language=en_US"
								>
									Required configuration
								</Link>
								<Checkboxes
									name="useMobileServer"
									data={{
										label: "Use XProtect Mobile Server",
										value: "useMobileServer",
									}}
									formControlProps={{
										margin: "dense",
										fullWidth: true,
									}}
								/>
								<Radios
									radioGroupProps={{ row: true }}
									name="mobileServerSSL"
									formControlProps={{ margin: "none" }}
									inputProps={{
										onChange: (e) => {
											const bool =
												e.target.value === "true";
											changeValue(
												"mobileServerSSL",
												bool,
											);
											const port = Number(
												values.mobilServerPort,
											);
											if (isNaN(port)) return;
											changeValue(
												"mobilServerPort",
												calculatePortValue(port, bool),
											);
										},
									}}
									data={[
										{
											label: "Https",
											value: true as any,
										},
										{
											label: "Http",
											value: false as any,
										},
									]}
									disabled={!values.useMobileServer}
									required
								/>
								<TextField
									name="mobilServerPort"
									label="Port"
									type="number"
									margin="dense"
									disabled={!values.useMobileServer}
									required
								/>
							</LabelledOutline>
						</Box>
					</Condition>

					<Checkboxes
						label="Sources"
						name="startAutomatically"
						data={{
							label: "Start Automatically",
							value: "startAutomatically",
						}}
					/>
					<br />
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{props.loading ? <CircularProgress /> : null}
						<Button
							type="submit"
							disabled={pristine || props.loading || invalid}
						>
							{props.edit ? "Submit" : "Register"}
						</Button>
						{props.discard ? (
							<Button onClick={props.discard}>Cancel</Button>
						) : (
							<div />
						)}
					</div>
				</form>
			)}
		/>
	);
};

export default VMSForm;
