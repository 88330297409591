import Axios from "axios";
import { AsyncAction, async, AsyncActionStatus } from "../AsyncState";
import { API_BASE_PREFIX } from "../../config/axios";
import { IResponse, PagedContent } from "../types";
import {
	IRule,
	IgetRulesRequest,
	RulesDataContainer,
	ICreateRule,
	IEditRule,
} from "./types";

export type RULES = "RULES" | "RULES_FILTER";

export type RulesAction = AsyncAction<
	RULES,
	PagedContent<IRule[]> | IRule | IgetRulesRequest,
	string
>;

const getRules = async (vmsName: string, query: IgetRulesRequest) => {
	try {
		const response = await Axios.get<RulesDataContainer>(
			API_BASE_PREFIX + `vms/${vmsName}/rules`,
			{ params: query },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getEvents = async (vmsName: string) => {
	try {
		const response = await Axios.get(
			API_BASE_PREFIX + `vms/${vmsName}/extensions/analyticsEvents`,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getRule = async (
	vmsName: string,
	ruleName: string,
	secret?: string,
) => {
	try {
		const response = await Axios.get<IRule>(
			API_BASE_PREFIX + `vms/${vmsName}/rules/${ruleName}`,
			{ params: { secret: secret } },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deleteRule = async (vmsName: string, rule: IRule) => {
	try {
		const response = await Axios.delete<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/rules/${rule.name}`,
			{ params: { secret: rule.secret } },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createRule = async (vmsName: string, rule: ICreateRule) => {
	try {
		const response = await Axios.post<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/rules`,
			rule,
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const editRule = async (
	vmsName: string,
	ruleName: string,
	rule: IEditRule,
	secret?: string,
) => {
	try {
		const response = await Axios.put<IResponse>(
			API_BASE_PREFIX + `vms/${vmsName}/rules/${ruleName}`,
			rule,
			{
				params: {
					secret: secret,
				},
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export function getRulesAction(vmsName: string, query: IgetRulesRequest) {
	return async("RULES", getRules, vmsName, vmsName, query);
}

export function setRulesQuery(
	vmsName: string,
	query: IgetRulesRequest,
): RulesAction {
	return {
		meta: vmsName,
		payload: query,
		status: AsyncActionStatus.SUCCEEDED,
		type: "RULES_FILTER",
	};
}
