import React from "react";
import { ISubject } from "../../../store/Subjects/types";
import { Grid } from "@material-ui/core";
import FaceItem from "./FaceItem";
import SubjectsAreEmpty from "../SubjectsAreEmpty";
import { IWatchlist } from "../../../store/Watchlists/types";
import _ from "lodash";
import { IMetadata } from "../../../store/Schemas/types";

const checkMetadata = (subs: ISubject[]) => {
	let result = false;
	for (let i = 0; i < subs.length; i++) {
		const objArr = Object.values(JSON.parse(subs[i].metadata));
		let temp = false;
		for (let j = 0; j < objArr.length; j++) {
			const field = objArr[j] as string;
			if (field && field.length > 450) {
				result = true;
				temp = true;
				break;
			}
		}
		if (temp) break;
	}
	return result;
};

interface IFaceSubjectsListProps {
	subjects: ISubject[];
	selected: number;
	onSelected: (index: number) => void;
	onDoubleClick(option: string): void;
	watchlist: IWatchlist;
	extractedSchema: any;
}

const FaceSubjectsList: React.FC<IFaceSubjectsListProps> = ({
	subjects,
	selected,
	onSelected,
	onDoubleClick,
	watchlist,
	extractedSchema,
}) => {
	return (
		<Grid container style={{ padding: 4 }}>
			{subjects.length > 0 ? (
				subjects.map((sub, i) => (
					<FaceItem
						key={i}
						subject={sub}
						selected={selected}
						index={i}
						onSubClick={onSelected}
						onDoubleClick={onDoubleClick}
						hasLongText={checkMetadata(subjects)}
						watchlist={watchlist}
						metadata={
							extractedSchema
								? ({
										..._.clone(extractedSchema),
										...JSON.parse(sub.metadata),
								  } as IMetadata)
								: JSON.parse(sub.metadata)
						}
					/>
				))
			) : (
				<SubjectsAreEmpty msg="Watchlist is empty" />
			)}
		</Grid>
	);
};

export default FaceSubjectsList;
