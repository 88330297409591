import { IUser, IUserRequest, IUsersState, UsersPagedContent } from "./types";
import { Reducer } from "redux";
import { AsyncActionStatus } from "../AsyncState";
import { UsersAction } from "./action";

export const initialUsersState: IUsersState = {
	status: AsyncActionStatus.UNSTARTED,
	data: {
		content: [],
		number: 0,
		numberOfElements: 0,
		size: 0,
		totalElements: 0,
		totalPages: 0,
		empty: true,
		first: true,
		last: true,
		sort: {
			empty: true,
			sorted: false,
			unsorted: true,
		},
		pageable: {
			offset: 0,
			pageNumber: 0,
			pageSize: 0,
			paged: true,
			unpaged: false,
			sort: {
				empty: true,
				sorted: false,
				unsorted: true,
			},
		},
	},
	keys: {},
	requestStatus: {},
	paging: {
		page: 0,
		size: 15,
	},
};

const handleAsync = (
	state: IUsersState = initialUsersState,
	action: UsersAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED: {
			const nState = { ...state };
			if (action.meta) {
				nState.keys[action.meta] = action.payload as IUser;
			} else {
				const payload = action.payload as UsersPagedContent;
				payload.content.forEach((user) => {
					nState.keys[user.userName] = user;
				});
			}
			return nState;
		}
	}
	return state;
};

const handleFilter = (
	state: IUsersState = initialUsersState,
	action: UsersAction,
) => {
	const nState = { ...state };
	if (action.status === "SUCCEEDED") {
		nState.paging = {
			...nState.paging,
			...(action.payload as IUserRequest),
		};
	}
	return nState;
};

export const usersReducer: Reducer<IUsersState, any> = (
	state = initialUsersState,
	action: UsersAction,
) => {
	switch (action.type) {
		case "Users":
			return handleAsync(state, action);
		case "Users_Filter":
			return handleFilter(state, action);
		default:
			return state;
	}
};
