import { connect } from "react-redux";
import TasksView, {
	ITasksViewPropsToDispatch,
	ITasksViewPropsToState,
} from "./TasksView";
import { AppState } from "../../store";
import { IgetTasksRequest } from "../../store/Tasks/types";
import { getTasksAction, setTasksFilterAction } from "../../store/Tasks/action";

const mapStateToProps = (state: AppState): ITasksViewPropsToState => {
	return {
		error: state.tasks.erorr,
		tasks: state.tasks.data,
		filter: state.tasks.paging,
	};
};

const mapDispatchToProps = (dispatch: any): ITasksViewPropsToDispatch => {
	return {
		loadTasks: (request: IgetTasksRequest) =>
			getTasksAction(request)(dispatch),
		setTasksFilter: (filter: IgetTasksRequest) =>
			dispatch(setTasksFilterAction(filter)),
	};
};

const TasksContainer = connect(mapStateToProps, mapDispatchToProps)(TasksView);
export default TasksContainer;
