import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
	handleBackwardSocketDataAction,
	searchBackward,
} from "../store/BackwardSearch/action";
import {
	BackwardSearchRequest,
	IBackwardSocketMessage,
	RequestTaskStatus,
} from "../store/BackwardSearch/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { AppState } from "../store";
import { taskSocketUrl, handleOpen } from "../services/wsConnection";
import Authentication from "../store/Authentication/AuthenticationStore";
import useCanSeeSecrets from "./useCanSeeSecrets";

interface Props {
	onComplete?: (id: string, completedUnderTimeThreshold: boolean) => void;
	loadBackwardSearches?: () => void;
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setError?: React.Dispatch<React.SetStateAction<Error | undefined>>;
	isDialogOpen: boolean;
}

const useBackwardSearch = ({
	onComplete,
	loadBackwardSearches,
	setOpenDialog,
	setError,
	isDialogOpen,
}: Props) => {
	const dispatch = useDispatch();
	const ws = useRef<WebSocket | null>(null);
	const canSeeSecrets = useCanSeeSecrets();
	const { data: backwardSearches } = useSelector(
		(state: AppState) => state.backwardSearch,
	);
	const [uploadProgress, setUploadProgress] = useState({
		loaded: 0,
		total: 0,
	});
	const [lastSearch, setLastSearch] = useState({
		id: "",
		time: moment(),
	});

	const handleSearchRequest = async ({
		images,
		...request
	}: BackwardSearchRequest & { images: string[] | undefined }) => {
		try {
			if (images && images.length > 0) {
				setUploadProgress((prev) => ({
					...prev,
					total: prev.total + images!.length,
				}));

				let isOpen = isDialogOpen;
				const batchSize = 5;

				const reversedImages = images.reverse();

				for (let i = 0; i < reversedImages.length; i += batchSize) {
					const batchImages = reversedImages.slice(i, i + batchSize);

					const searchPromises = batchImages.map((image) =>
						searchBackward({ ...request, image }).then((res) => {
							if (res.correlationId)
								dispatch(
									handleBackwardSocketDataAction({
										...res,
										...request,
										status: RequestTaskStatus.scheduled,
										thumb: image,
									} as IBackwardSocketMessage),
								);
							if (reversedImages!.length < 2) {
								const time = moment(res.timestamp);
								setLastSearch({
									id: res.correlationId ?? "",
									time,
								});
							}
						}),
					);

					setOpenDialog((prev) => {
						if (!prev) isOpen = false;
						return prev;
					});

					if (!isOpen) {
						setUploadProgress({ loaded: 0, total: 0 });
						break;
					}

					await Promise.all(searchPromises);

					setUploadProgress({
						loaded: i + batchImages.length,
						total: images.length,
					});
				}

				setUploadProgress({
					loaded: 0,
					total: 0,
				});
			} else {
				const task = await searchBackward(request);

				const time = moment(task.timestamp);
				setLastSearch({
					id: task.correlationId ?? "",
					time,
				});
				if (task.correlationId)
					dispatch(
						handleBackwardSocketDataAction({
							...task,
							...request,
						} as IBackwardSocketMessage),
					);
			}

			setOpenDialog(false);
		} catch (error) {
			if (error instanceof Error) setError?.(error);
			setOpenDialog(false);
		}
	};

	const handleSocketMsg = useCallback(
		(event: MessageEvent) => {
			const data = JSON.parse(event?.data);

			if (Array.isArray(data) && data[0]?.action === "BackwardSearch") {
				const time = moment(data[0]?.timestamp);
				dispatch(handleBackwardSocketDataAction(data[0]));

				if (
					data[0]?.status === "Complete" ||
					data[0]?.status === "BadRequest"
				) {
					if (data[0]?.correlationId === lastSearch.id) {
						if (
							lastSearch.time &&
							moment(time).isBefore(
								lastSearch.time.add(5, "seconds"),
							)
						)
							onComplete?.(data[0]?.correlationId, true);
						else onComplete?.(data[0]?.correlationId, false);
					}

					const isLoadingOtherSearches =
						backwardSearches.content?.some(
							(search) =>
								search.correlationId !==
									data[0]?.correlationId &&
								(search.status ===
									RequestTaskStatus.scheduled ||
									search.status ===
										RequestTaskStatus.processing ||
									search.status ===
										RequestTaskStatus.preparingResults),
						);
					if (!isLoadingOtherSearches) loadBackwardSearches?.();
				}
			}
		},
		[
			lastSearch,
			backwardSearches.content,
			dispatch,
			loadBackwardSearches,
			onComplete,
		],
	);

	useEffect(() => {
		if (Authentication.isAuthenticated()) {
			const socket = ws.current
				? ws.current
				: new WebSocket(taskSocketUrl);
			socket.onopen = () => handleOpen(socket);
			socket.onmessage = handleSocketMsg;
			ws.current = socket;
		}
	}, [handleSocketMsg]);

	useEffect(() => {
		return () => {
			if (ws.current) ws.current.close();
		};
	}, []);

	return {
		handleSearchRequest,
		uploadProgress,
		canSeeSecrets,
		lastSearchId: lastSearch.id,
	};
};

export default useBackwardSearch;
