import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	BoxProps,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { THEME } from "../../config";
import { Skeleton } from "@material-ui/lab";
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getSecretAction } from "../../store/Secrets/action";
const useStyles = makeStyles(() => ({
	tooltip: {
		display: "flex",
		flexDirection: "column",
	},
	secretText: {
		color: THEME.palette.error.contrastText,
		backgroundColor: THEME.palette.error.dark,
		fontSize: "0.8rem",
		padding: "2px 4px",
		borderRadius: "3px",
		width: "fit-content",
	},
}));

type Props = {
	secretID?: string;
	enableTooltip?: boolean;
	secretName?: string;
	secretDescription?: string;
	textAlign?: BoxProps["justifyContent"];
	visibility?: React.CSSProperties["visibility"];
};

const SecretText = ({
	secretID,
	enableTooltip,
	secretName,
	secretDescription,
	textAlign,
	visibility,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const secret = useSelector(
		(state: AppState) =>
			state.secrets.keys[secretID ?? ""] ??
			state.secrets.data.content.find((s) => s.id === secretID),
	);

	const secretExist = useMemo(
		() => Boolean(secretName && secretDescription) || Boolean(secret),
		[secretName, secretDescription, secret],
	);

	useEffect(() => {
		if (!secretExist && secretID && !loading) {
			setLoading(true);
			getSecretAction(secretID)(dispatch).finally(() =>
				setLoading(false),
			);
		}
	}, [dispatch, secretID, secretExist, loading]);

	return (
		<Tooltip
			title={
				((secret?.description || secretDescription) &&
					enableTooltip && (
						<div className={classes.tooltip}>
							<Typography variant="caption">
								Secret description:{" "}
								{secret?.description ?? secretDescription}
							</Typography>
						</div>
					)) ??
				""
			}
		>
			<Box display="flex" justifyContent={textAlign}>
				{!loading ? (
					<Typography
						className={classes.secretText}
						align={textAlign}
						style={{
							visibility: visibility
								? visibility
								: secretID
								? "visible"
								: "hidden",
						}}
					>
						<b>Secret:</b> {secret?.name ?? secretName}
					</Typography>
				) : (
					<Skeleton>
						<Typography>{genText(30)}</Typography>
					</Skeleton>
				)}
			</Box>
		</Tooltip>
	);
};

const genText = (length: number) => "t".repeat(length);

export default SecretText;
