import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import {
	IEditSubject,
	IEditSubjectBody,
	ISubject,
} from "../../../store/Subjects/types";
import PlateEditForm from "../../Forms/SubjectForms/PlateEditForm";
import Title from "../Common/Title";
import { IMetadataSchema, IMetadata } from "../../../store/Schemas/types";

interface IEditPlate {
	open: boolean;
	onConfirm(subjectId: string, body: IEditSubjectBody): void;
	onClose(): void;
	subject: ISubject;
	watchlistId: string;
	schema: IMetadataSchema;
	metadata: IMetadata;
	onLoading(): void;
	loading: boolean;
}

const SubjectPlateEditDialog: React.FC<IEditPlate> = ({
	open,
	onConfirm,
	onClose,
	subject,
	watchlistId,
	schema,
	metadata,
	onLoading,
	loading,
}) => {
	const handleConfirm = (plateInfo: IEditSubject) => {
		let isThumbNew = false;
		let isMetadataNew = false;
		let isOriginNew = false;
		if (subject.thumb !== plateInfo.image) isThumbNew = true;
		if (subject.metadata !== plateInfo.metadata) isMetadataNew = true;
		if (subject.origin !== plateInfo.origin) isOriginNew = true;
		const body: IEditSubjectBody = {
			metadata: plateInfo.metadata,
			origin: plateInfo.origin,
			thumbnail: plateInfo.image,
			changeThumbnail: isThumbNew,
			changeMetadata: isMetadataNew,
			changeOrigin: isOriginNew,
		};
		onConfirm(subject.name, body);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
			<Title
				title={`${watchlistId} - ${subject.name}`}
				onClose={onClose}
			/>
			<DialogContent>
				<PlateEditForm
					onConfirm={handleConfirm}
					onClose={onClose}
					schema={schema}
					initialMetadata={metadata}
					subject={subject}
					onLoading={onLoading}
					loading={loading}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default SubjectPlateEditDialog;
