import {
	makeStyles,
	IconButton,
	Box,
	Tooltip,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from "@material-ui/core";
import { DoubleArrow, Star } from "@material-ui/icons";
import _, { DebouncedFunc, isEqual } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { THEME } from "../../../config";
import { AppState } from "../../../store";
import { EventsFilter } from "../../../store/Events/types";
import Chips from "./Chips";
import {
	addConfigAction,
	deleteConfigAction,
	updateConfigAction,
} from "../../../store/UserConfig/action";
import { IFavorite } from "./History";

const useStyles = makeStyles(() => ({
	wrapper: {
		transition: "all 0.5s ease-in-out",
		padding: "6px",
		display: "flex",
		alignItems: "center",
		height: "fit-content",
		justifyContent: "space-between",
		backgroundColor: "white",
		gap: 6,
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "12px",
	},
	buttonBox: {
		display: "flex",
		alignSelf: "flex-start",
		height: "100%",
		paddingLeft: "4px",
		borderLeft: `1px solid ${THEME.palette.grey[400]}`,
	},
	button: {
		color: "black",
		width: 34,
		height: 34,
		borderRadius: 10,
	},
	deleteButton: {
		backgroundColor: "#890000",
		color: "white",
		"&:hover": {
			backgroundColor: "#B60000",
		},
		width: 40,
		height: 40,
		borderRadius: 10,
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: 30,
		minWidth: 400,
	},
	chips: {
		paddingTop: 8,
		borderTop: `1px solid ${THEME.palette.primary.main}`,
		display: "flex",
		justifyContent: "center",
		gap: 4,
		flexWrap: "wrap",
		width: "100%",
	},
}));

export const hiddenFilterKeys = [
	"showAbsoluteStart",
	"showAbsoluteEnd",
	"relativeFrom",
	"relativeUntil",
];

type Props = {
	onFilter(filter: EventsFilter): void;
	filter: EventsFilter;
	setIsOpen: (isOpen: boolean) => void;
	isDrawerOpen: boolean;
};

const FiltersBar = ({ onFilter, filter, setIsOpen, isDrawerOpen }: Props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const userConfig = useSelector((state: AppState) => state.userConfig.data);
	const [formattedFilter, setFormattedFilter] = React.useState(filter);
	const [error, setError] = React.useState("");
	const [isDialogOpen, setIsDialogOpen] = React.useState({
		add: false,
		delete: false,
	});
	const [favorite, setFavorite] = React.useState<IFavorite | undefined>(
		userConfig.favorite.find((f) => isEqual(f.filter, filter)),
	);
	const [name, setName] = React.useState(favorite?.name ?? "");
	const lastHistory = userConfig.history[0];
	const lastFavorite = userConfig.favorite[0];
	useEffect(() => {
		const favoriteSelected = userConfig.favorite.find((f) =>
			isEqual(f.filter, filter),
		);
		if (favoriteSelected) {
			setName(favoriteSelected.name);
			setFavorite(favoriteSelected);
		} else {
			setName(
				userConfig.history.find((h) => isEqual(h.filter, filter))
					?.name ?? "",
			);
			setFavorite(undefined);
		}
	}, [lastHistory, filter, userConfig, lastFavorite]);

	React.useEffect(() => {
		setFormattedFilter(filter);
	}, [filter]);

	const toggleDrawer = (open: boolean) => () => {
		setIsOpen(open);
	};

	const [debouncedFunction, setDebouncedFunction] =
		React.useState<DebouncedFunc<() => void> | null>(null);

	const handleChipDelete = (key: string, value?: string) => {
		let newFilter: EventsFilter;
		if (value && formattedFilter?.moreFilters?.[key]) {
			newFilter = {
				...formattedFilter,
				moreFilters: {
					...formattedFilter?.moreFilters,
					[key]: {
						...formattedFilter?.moreFilters?.[key],
						values: formattedFilter?.moreFilters?.[
							key
						].values.filter((v) => v !== value),
					},
				},
			};
		} else {
			newFilter = {
				..._.omit(formattedFilter, key),
				moreFilters: _.omit(formattedFilter?.moreFilters ?? {}, key),
			};
			if (newFilter.moreFilters && _.isEmpty(newFilter.moreFilters))
				delete newFilter.moreFilters;
		}
		setFormattedFilter(newFilter);

		if (debouncedFunction !== null) {
			debouncedFunction.cancel();
		}
		const newDebouncedFunction = _.debounce(() => {
			onFilter(newFilter);
		}, 1000);

		setDebouncedFunction(() => newDebouncedFunction);
		newDebouncedFunction();
	};

	const handleFavoriteSave = useCallback(() => {
		if (name.length < 1) return setError("Name is required");
		if (
			userConfig.favorite.find(
				(fav) => fav.name.toLowerCase() === name.toLowerCase(),
			)
		) {
			return setError("Name already exists");
		}
		const favoriteConfig = {
			...userConfig.history[0],
			name,
			id: moment().format("YYYYMMDDHHmmss"),
		};
		dispatch(
			addConfigAction({
				variant: "favorite",
				config: favoriteConfig,
			}),
		);
		dispatch(
			updateConfigAction({
				variant: "preference",
				config: {
					...userConfig.preference,
					favorites: [
						...userConfig.preference.favorites,
						{
							id: favoriteConfig.id,
						},
					],
				},
			}),
		);
		setIsDialogOpen({
			...isDialogOpen,
			add: false,
		});
	}, [dispatch, isDialogOpen, name, userConfig]);

	const handleFavoriteDelete = useCallback(() => {
		setIsDialogOpen({
			...isDialogOpen,
			delete: false,
		});

		if (favorite) {
			dispatch(
				deleteConfigAction({
					variant: "favorite",
					config: favorite,
				}),
			);
		}
	}, [dispatch, favorite, isDialogOpen]);

	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (
				e.key === "Enter" &&
				(isDialogOpen.add || isDialogOpen.delete)
			) {
				e.preventDefault();
				if (isDialogOpen.add) handleFavoriteSave();
				else if (isDialogOpen.delete) handleFavoriteDelete();
			}
		};
		window.addEventListener("keydown", listener);
		return () => window.removeEventListener("keydown", listener);
	}, [handleFavoriteDelete, handleFavoriteSave, isDialogOpen]);

	return (
		<Box className={classes.wrapper}>
			{_.isEmpty(_.omit(formattedFilter, hiddenFilterKeys)) && (
				<Typography>
					Showing all results. Select criteria on the right to filter
					results.
				</Typography>
			)}
			<Chips filter={formattedFilter} onClear={handleChipDelete} />
			<Box className={classes.buttonBox}>
				<Tooltip title={favorite ? "Unfavor filter" : "Favor filter"}>
					<IconButton
						onClick={() => {
							if (favorite) {
								setIsDialogOpen({
									...isDialogOpen,
									delete: true,
								});
							} else {
								setIsDialogOpen({ ...isDialogOpen, add: true });
							}
						}}
						style={{
							visibility: _.isEmpty(
								_.omit(formattedFilter, hiddenFilterKeys),
							)
								? "hidden"
								: "visible",
						}}
						className={classes.button}
					>
						<Star
							style={{
								fill: favorite
									? "#D4A017"
									: THEME.palette.grey[600],
							}}
						/>
					</IconButton>
				</Tooltip>
				{!isDrawerOpen && (
					<Tooltip title="Open filter">
						<IconButton
							onClick={toggleDrawer(!isDrawerOpen)}
							className={classes.button}
						>
							<DoubleArrow
								style={{
									rotate: "180deg",
								}}
							/>
						</IconButton>
					</Tooltip>
				)}
			</Box>
			<Dialog
				open={isDialogOpen.delete}
				onClose={() =>
					setIsDialogOpen({ ...isDialogOpen, delete: false })
				}
			>
				<DialogTitle style={{ minWidth: 300 }}>
					Unfavor{" "}
					<span style={{ color: THEME.palette.text.secondary }}>
						{name}
					</span>
					?
				</DialogTitle>
				<DialogActions>
					<Button
						onClick={handleFavoriteDelete}
						style={{ color: THEME.palette.error.main }}
					>
						Delete
					</Button>
					<Button
						onClick={() =>
							setIsDialogOpen({ ...isDialogOpen, delete: false })
						}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={isDialogOpen.add}
				onClose={() => setIsDialogOpen({ ...isDialogOpen, add: false })}
			>
				<DialogTitle>Save as favorite</DialogTitle>
				<DialogContent className={classes.content}>
					<TextField
						value={name}
						variant="outlined"
						label="Name"
						onFocus={(e) => {
							e.target.select();
							e.target.setSelectionRange(0, 9999);
						}}
						autoFocus
						error={Boolean(error)}
						helperText={error}
						onChange={(e) => {
							if (error) setError("");
							setName(e.target.value);
						}}
					/>
					<Box>
						<Typography variant="h6">Filters</Typography>
						<Box className={classes.chips}>
							{Object.keys(
								_.omit(
									userConfig.history[0]?.filter,
									hiddenFilterKeys,
								),
							).length === 0 ? (
								<Typography variant="body1">
									No filters applied
								</Typography>
							) : (
								<Chips filter={userConfig.history[0]?.filter} />
							)}
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleFavoriteSave}>Save</Button>
					<Button
						onClick={() =>
							setIsDialogOpen({ ...isDialogOpen, add: false })
						}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default FiltersBar;
