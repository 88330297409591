import React from "react";
import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import PropertyControls from "./PropertyControls";
import { IValuedProperty } from "../../store/Properties/types";
import CountriesControl from "./CountriesControl";

const useStyles = makeStyles(() => ({
	controller: {
		paddingRight: 5,
		display: "flex",
		gap: 5,
	},
	value: {
		paddingLeft: 5,
	},
	friendlyName: {
		marginTop: 5.5,
	},
}));

interface IPropertyGroupItemProps {
	property: IValuedProperty;
	properties: IValuedProperty[];
	onUpdate(value: string, key: string): void;
	setDisabled(disabled: boolean): void;
	saveDisabled: boolean;
}

export type TList = Record<"selected" | "available", string[]>;

const PropertyGroupItem = ({
	property,
	properties,
	onUpdate,
	setDisabled,
	saveDisabled,
}: IPropertyGroupItemProps) => {
	const classes = useStyles();
	const isCountriesProperty =
		property.key === "ObjectTracker.LicensePlatePriorityCountries";

	return (
		<Tooltip title={property.description || ""}>
			<Grid container className={classes.value}>
				<Grid item xs={8} sm={9} md={7} xl={7}>
					<Typography className={classes.friendlyName}>
						{property.friendlyName}:
					</Typography>
				</Grid>
				<Grid
					item
					xs={4}
					sm={3}
					md={4}
					lg={5}
					className={classes.controller}
				>
					{isCountriesProperty ? (
						<CountriesControl
							property={property}
							onUpdate={onUpdate}
							setDisabled={setDisabled}
							saveDisabled={saveDisabled}
							inputDisabled={
								properties.find(
									({ key }) =>
										key ===
										"ObjectTracker.LicensePlateEnableTemplateMatching",
								)?.value === "False"
							}
						/>
					) : (
						<PropertyControls
							property={property}
							value={property.value}
							onChange={(value) => onUpdate(value, property.key)}
						/>
					)}
				</Grid>
			</Grid>
		</Tooltip>
	);
};

export default PropertyGroupItem;
