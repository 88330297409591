import { connect } from "react-redux";
import EventsView, {
	IEventsViewDispatchProps,
	IEventsViewStateProps,
} from "./EventsView";
import { AppState } from "../../store";
import {
	getEventsAction,
	getEventsScrollAction,
} from "../../store/Events/action";
import { EventsFilter } from "../../store/Events/types";
import {
	addConfigAction,
	deleteConfigAction,
	getConfigAction,
	updateConfigAction,
} from "../../store/UserConfig/action";
import { IConfig } from "../../store/UserConfig/types";

const mapStateToProps = (state: AppState): IEventsViewStateProps => {
	return {
		error: state.events.erorr,
		events: state.events.data?.hits?.hits,
		config: state.userConfig,
		status: state.events.status,
	};
};

const mapDispatchToProps = (dispatch: any): IEventsViewDispatchProps => {
	return {
		loadEvents: (filter: EventsFilter, excludes?: string[]) =>
			getEventsAction(filter, excludes)(dispatch),
		loadConfig: () => getConfigAction()(dispatch),
		addConfig: (config: IConfig) => {
			return addConfigAction(config)(dispatch);
		},
		deleteConfig: (config: IConfig) => {
			return deleteConfigAction(config)(dispatch);
		},
		updateConfig: (config: IConfig, updateName?: boolean) =>
			updateConfigAction(config, updateName)(dispatch),
		scrollEvents: (filter: EventsFilter, from: number, size?: number) => {
			return getEventsScrollAction(filter, from, size)(dispatch);
		},
	};
};

const LogsContainer = connect(mapStateToProps, mapDispatchToProps)(EventsView);
export default LogsContainer;
