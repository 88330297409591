import { IDefinitionsState, IDefinition } from "./types";
import { AsyncActionStatus } from "../AsyncState";
import { Reducer } from "redux";
import { DefinitionsAction } from "./action";

export const initialDefinitionsState: IDefinitionsState = {
	status: AsyncActionStatus.UNSTARTED,
	data: [],
	keys: {},
	requestStatus: {},
};

export const definitionsReducer: Reducer<IDefinitionsState, any> = (
	state = initialDefinitionsState,
	action: DefinitionsAction,
) => {
	switch (action.type) {
		case "Definitions": {
			return handleDefinitions(state, action);
		}
		default:
			return state;
	}
};

const handleDefinitions = (
	state: IDefinitionsState = initialDefinitionsState,
	action: DefinitionsAction,
) => {
	switch (action.status) {
		case AsyncActionStatus.SUCCEEDED:
			const nState = { ...state };
			const payload = action.payload as IDefinition[];
			nState.data = payload;
			return nState;
	}
	return state;
};
