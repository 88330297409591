import { makeStyles, Box, Snackbar, Typography } from "@material-ui/core";
import { EventsFilter } from "../../../store/Events/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import {
	addConfigAction,
	deleteConfigAction,
	updateConfigAction,
} from "../../../store/UserConfig/action";
import _, { isEqual } from "lodash";
import moment from "moment";
import HistoryCard from "./HistoryCard";
import Alert from "../../UI/Alert";
import { useState } from "react";
import { hiddenFilterKeys } from "./FiltersBar";

const useStyles = makeStyles(() => ({
	history: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: 18,
		margin: 0,
		padding: "6px 0",
	},
}));

type Props = {
	currentFilter: EventsFilter | undefined;
	onFilter: (filter: EventsFilter) => void;
};

export interface IFavorite {
	id: string;
	name: string;
	filter: EventsFilter;
}

const History = ({ currentFilter, onFilter }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const userConfig = useSelector((state: AppState) => state.userConfig.data);

	const deleteHistory = (history: IFavorite) => {
		dispatch(
			deleteConfigAction({
				variant: "history",
				config: history,
			}),
		);
	};

	const addToFavorites = (history: IFavorite) => {
		const historyConfig = {
			...history,
			id: moment().format("YYYYMMDDHHmmss"),
		};
		dispatch(
			addConfigAction({
				variant: "favorite",
				config: historyConfig,
			}),
		);
		dispatch(
			updateConfigAction({
				variant: "preference",
				config: {
					...userConfig.preference,
					favorites: [
						...userConfig.preference.favorites,
						{
							id: historyConfig.id,
						},
					],
				},
			}),
		);
		setSnackbarOpen(true);
	};

	const history = userConfig.history?.filter(
		(v) =>
			JSON.stringify(_.omit(v.filter, hiddenFilterKeys)) !==
			JSON.stringify({}),
	);

	const selectedCards = {
		favorites: userConfig.favorite.findIndex((f) =>
			isEqual(f.filter, currentFilter),
		),
		history: history.findIndex((f) => isEqual(f.filter, currentFilter)),
	};

	return (
		<Box className={classes.history}>
			{userConfig.history.filter(
				(v) => !_.isEqual(_.omit(v.filter, hiddenFilterKeys), {}),
			)?.length === 0 && (
				<Typography variant="body1">No history yet</Typography>
			)}
			{history.map((history, i) => (
				<HistoryCard
					key={i}
					history={history}
					deleteHistory={deleteHistory}
					addToFavorites={addToFavorites}
					selected={selectedCards.history === i}
					onFilter={onFilter}
				/>
			))}
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={(_, reason) => {
					if (reason === "clickaway") return;
					setSnackbarOpen(false);
				}}
			>
				<div>
					<Alert
						onClose={() => setSnackbarOpen(false)}
						alert={{
							variant: "success",
							message: "History saved!",
						}}
					/>
				</div>
			</Snackbar>
		</Box>
	);
};

export default History;
