import React from "react";
import { Route, Redirect } from "react-router-dom";
import Authentication from "../../store/Authentication/AuthenticationStore";
import { IProps } from "./PrivateRoute";
import { checkPermissions } from "../../containers/Layout/Layout";
import { SecretsItem } from "../../config/MenuItems";
import { checkSecrets } from "../../store/Secrets/action";
const { History } = require("history");
interface ISecretsRoute extends IProps {
	history: typeof History;
}

const SecretsRoute: React.FC<ISecretsRoute> = ({
	component: Component,
	...otherProps
}: ISecretsRoute) => {
	const [load, setLoad] = React.useState(true);

	React.useEffect(() => {
		const loadSecrets = async () => {
			if (checkPermissions(SecretsItem.permissions)) {
				setLoad(false);
			} else {
				if (await checkSecrets()) {
					setLoad(false);
				} else {
					otherProps.history.push("/vms");
				}
			}
		};
		loadSecrets();
	}, [otherProps]);

	return (
		<>
			{load ? null : (
				<Route
					{...otherProps}
					render={(props) =>
						Authentication.isAuthenticated() ? (
							<Component {...props} />
						) : (
							<Redirect
								to={{
									pathname: "/login",
									state: { from: props.location },
								}}
							/>
						)
					}
				/>
			)}
		</>
	);
};

export default SecretsRoute;
